import { ChangeEvent, useState, useRef } from 'react';
import { useTranslate } from 'react-admin';
import { IconButton, TextField, Tooltip } from '@mui/material';
import { Close, Check } from '@mui/icons-material';

import { Flex } from '@UI';

import { PickingPriorityTableCell } from './styled';
import { PickingPriorityEditingTableCellProps } from './interfaces';

const PickingPriorityEditingTableCell = (
  props: PickingPriorityEditingTableCellProps
) => {
  const {
    label,
    type = 'text',
    fieldValue,
    onSave,
    disabled,
    validators,
  } = props;

  const t = useTranslate();

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [value, setValue] = useState<string>(fieldValue);
  const [errorMsg, setErrorMsg] = useState<string | undefined>();
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const clearRefTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  };

  const handleValidate = (val: unknown) => {
    if (!validators) {
      setErrorMsg(undefined);

      return;
    }

    const validatorsList = Array.isArray(validators)
      ? validators
      : [validators];

    for (const validate of validatorsList) {
      const msg = validate(val, {});

      if (msg === undefined) {
        continue;
      }

      setErrorMsg(typeof msg === 'string' ? t(msg) : t(msg.message, msg.args));

      return;
    }

    setErrorMsg(undefined);
  };

  const validateDebounced = (val: unknown) => {
    clearRefTimeout();

    timeoutRef.current = setTimeout(() => handleValidate(val), 300);
  };

  const handleFieldChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setValue(e.target.value);
    validateDebounced(e.target.value);
  };

  const handleBlur = () => {
    const val = value.trim();

    setValue(val);
    validateDebounced(val);
  };

  const handleSave = () => {
    onSave?.(value);
  };

  return (
    <PickingPriorityTableCell
      sx={!isEditing ? { cursor: 'text' } : { py: 0, paddingInline: '0 8px' }}
      onClick={!isEditing ? () => setIsEditing(true) : undefined}
    >
      {isEditing && !disabled ? (
        <Flex
          asColumn={false}
          justifyContent="stretch"
          alignItems="center"
          fullWidth
          gap={1}
        >
          <TextField
            label={t(label)}
            value={value}
            onKeyUp={(e) => e.key === 'Enter' && handleSave()}
            onChange={handleFieldChange}
            onBlur={handleBlur}
            onFocus={(e) => handleValidate(e.target.value)}
            fullWidth
            autoFocus
            disabled={disabled}
            error={Boolean(errorMsg)}
            helperText={errorMsg}
            inputProps={{
              type,
            }}
            InputProps={{
              sx: { paddingInline: 0 },
              endAdornment: (
                <Flex
                  asColumn={false}
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  {value && value !== fieldValue && (
                    <Tooltip
                      placement="top"
                      title={t('save')}
                      onClick={handleSave}
                    >
                      <IconButton
                        color="success"
                        disabled={Boolean(errorMsg) || disabled}
                      >
                        <Check />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip placement="top" title={t('close')}>
                    <IconButton
                      color="error"
                      onClick={() => setIsEditing(false)}
                    >
                      <Close />
                    </IconButton>
                  </Tooltip>
                </Flex>
              ),
            }}
          />
        </Flex>
      ) : (
        fieldValue
      )}
    </PickingPriorityTableCell>
  );
};

export default PickingPriorityEditingTableCell;
