export const sortByAdditionalId = (
  a: CoreProductAdditionalField,
  b: CoreProductAdditionalField
): number => Number(b.additionalPropertyId) - Number(a.additionalPropertyId);

interface OrderIndexInstance {
  orderIndex: number;
}

export const sortByOrderIndex = (
  a: OrderIndexInstance,
  b: OrderIndexInstance
): number => Number(a.orderIndex) - Number(b.orderIndex);
