import styled from 'styled-components';
import { Drawer } from '@mui/material';
import { ResourceList } from '@Widgets/ResourceList/ResourceList';

export const WarehousesBlacklistWrapper = styled.div`
  width: 100%;
`;

export const ResourceListStyled = styled(ResourceList)`
  .RaBulkActionsToolbar-toolbar {
    display: none;
  }
`;

export const AddBlacklistDrawerStyled = styled(Drawer)`
  width: 80%;
`;
