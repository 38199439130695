import { PluginImplementation, WeekDayItem } from '@PluginManager/interface';

export const DEFAULT_TRANSLATION: PluginImplementation['i18n'] = {
  en: {},
  ar: {},
};

export const WEEK_OPTIONS: WeekDayItem[] = [
  { id: 1, name: 'week.monday', disabled: false },
  { id: 2, name: 'week.tuesday', disabled: false },
  { id: 3, name: 'week.wednesday', disabled: false },
  { id: 4, name: 'week.thursday', disabled: false },
  { id: 5, name: 'week.friday', disabled: false },
  { id: 6, name: 'week.saturday', disabled: false },
  { id: 7, name: 'week.sunday', disabled: false },
];

export const WEEK_OPTIONS_ABBREVIATED: WeekDayItem[] = [
  { id: 0, name: 'weekAbbreviated.sunday', disabled: false },
  { id: 1, name: 'weekAbbreviated.monday', disabled: false },
  { id: 2, name: 'weekAbbreviated.tuesday', disabled: false },
  { id: 3, name: 'weekAbbreviated.wednesday', disabled: false },
  { id: 4, name: 'weekAbbreviated.thursday', disabled: false },
  { id: 5, name: 'weekAbbreviated.friday', disabled: false },
  { id: 6, name: 'weekAbbreviated.saturday', disabled: false },
];
