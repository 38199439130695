import { useRecordContext, useTranslate } from 'react-admin';
import { FeedsContainedStyled, FeedsPointStyled } from './styles';
import { useTheme } from '@mui/material/styles';
import { Flex } from '@UI';
import { Link, Typography } from '@mui/material';
import { DateFormatter } from '@Helpers';
import { ResourceRoutes } from '@PluginManager/plugins/resourceRoutes';

export const Feeds = () => {
  const record = useRecordContext<CoreOrder>();
  const theme = useTheme();
  const translate = useTranslate();

  return (
    <FeedsContainedStyled
      $dir={theme.direction}
      color={theme.palette.text.primary}
    >
      {record.feed?.map(
        ({
          date_time,
          old_value,
          new_value,
          type,
          field,
          id,
          meta,
          user_message,
        }) => {
          let caption;
          let description = '';
          let cancelReason = '';

          switch (type) {
            case 'STATUS':
              caption = translate('orders.pages.labels.feed.statusChangedTo', {
                status: translate(`orders.statuses.${new_value}`),
              });

              if (old_value) {
                description = translate(
                  'orders.pages.labels.feed.statusChangedFromTo',
                  {
                    status: translate(`orders.statuses.${old_value}`),
                    newStatus: translate(`orders.statuses.${new_value}`),
                  }
                );
              }

              if (new_value === 'cancelled') {
                cancelReason = user_message
                  ? translate('orders.pages.labels.feed.cancelReason', {
                      reason: user_message,
                    })
                  : cancelReason;
              }

              break;
            case 'PARTIAL_REFUND':
              caption = translate('orders.pages.labels.feed.partialRefund');

              break;
            case 'REFUND':
              caption = translate('orders.pages.labels.feed.refund');

              break;
            default:
              caption = translate('orders.pages.labels.feed.fieldChanged', {
                field,
                newValue: new_value,
                oldValue: old_value,
              });
          }

          const userName =
            meta?.user_id &&
            meta?.user_name &&
            (meta.user_id !== '0' ? (
              <Link href={`${ResourceRoutes.users.routePath}/${meta.user_id}`}>
                {meta.user_name}
              </Link>
            ) : (
              <Typography variant="body2">{meta.user_name}</Typography>
            ));

          return (
            <Flex key={id} padding={2}>
              <FeedsPointStyled
                $dir={theme.direction}
                color={theme.palette.text.primary}
              />

              <Typography variant="body2" mr={1}>
                {new DateFormatter(date_time).fullDateWithTime()}
              </Typography>

              <Flex asColumn textAlign="right" flexGrow={1}>
                <Typography variant="body1">{caption}</Typography>

                <Typography variant="body2">{description}</Typography>

                {userName}

                {cancelReason && (
                  <Typography variant="body2">{cancelReason}</Typography>
                )}
              </Flex>
            </Flex>
          );
        }
      )}
    </FeedsContainedStyled>
  );
};
