import { CommonCrud } from '@PluginBase';

import { RawGift, SubmitReturnType } from './interfaces';
import { unitsToPoints } from "@Helpers";

export const GIFTING_API_URL = '/promocodes-service/v1/admin/gifts';

export const giftingCRUD = new CommonCrud(GIFTING_API_URL, {
  isNewSorting: true,
});

export const submitRawOffersListValues = (
  item: CoreGiftingOffer
): Pick<CoreGiftingOffer, 'id' | 'priority' | 'quantity'> => {
  const { id, priority, quantity } = item;

  return { id, priority, quantity };
};

export const submitRawGiftValues = (item: RawGift): SubmitReturnType => {
  const {
    id,
    name,
    offersList,
    isActive,
    warehouseBlacklist,
    minimumPurchaseAmount,
    orderNumberRange,
    usageLimitCustomer,
    usageLimitAll,
    usageCurrent,
    priority,
    createdAt,
    updatedAt,
    dateRange,
  } = item;

  return {
    id,
    name,
    isActive,
    warehouseBlacklist,
    offersList: offersList.map(submitRawOffersListValues),
    usageCurrent,
    priority,
    createdAt,
    updatedAt,
    dateRange,
    minimumPurchaseAmount: unitsToPoints(minimumPurchaseAmount),
    orderNumberRange: orderNumberRange.map(Number) as [number, number],
    usageLimitAll: Number(usageLimitAll),
    usageLimitCustomer: Number(usageLimitCustomer),
  };
};
