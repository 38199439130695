import {
  Datagrid,
  DatagridProps,
  ListContextProvider,
  RaRecord,
  useList,
} from 'react-admin';

import { ComponentFactory } from '@Widgets/ResourceList/ComponentFactory/ComponentFactory';
import { ListColumn } from '@Widgets/ResourceList/interface';

type StaticDataGridProps<RecordType extends RaRecord = RaRecord> =
  DatagridProps & {
    sort?: {
      field: keyof RecordType;
      order: string;
    };
    cols: ListColumn<RecordType>[];
    data?: RecordType[];
  };

export const StaticDataGrid = <RecordType extends RaRecord = RaRecord>(
  props: StaticDataGridProps<RecordType>
) => {
  const { cols, data } = props;
  const listContext = useList({ data });

  return (
    <ListContextProvider value={listContext}>
      <Datagrid {...props}>
        {cols.map((column) => {
          const sortable = !!column.sortable;

          return (
            <ComponentFactory
              sortBy={sortable ? column.source : undefined}
              key={column.source}
              column={column}
              label={column.label}
            />
          );
        })}
      </Datagrid>
    </ListContextProvider>
  );
};
