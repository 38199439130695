import { ResourceInputNumber } from '@UI';
import { BooleanInput, TextInput } from 'react-admin';

export const PageForm = () => {
  return (
    <>
      <TextInput source="cluster" required />

      <BooleanInput source="cluster_zero_stock" />

      <ResourceInputNumber
        source="cluster_rule_package"
        min={0}
        max={1}
        isRequired
        label="Rule package"
      />
    </>
  );
};
