import { useState, useEffect } from 'react';
import { useParams } from 'react-router';

export function useTabs() {
  const [selectedTab, setSelectedTab] = useState(1);
  const params = useParams();

  useEffect(() => {
    setSelectedTab(parseInt(params['*'] ?? '1'));
  }, [params['*']]);

  return {
    selectedTab,
  };
}
