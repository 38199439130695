import { CREATE_PAGE_DEFAULT_FIELD_VALUES } from '../../constants';
import { FormTab, TabbedForm, TabbedFormTabs } from 'react-admin';
import { General } from './PageForm/tabs/General/General';
import { WarehouseLocation } from './PageForm/tabs/WarehouseLocation/WarehouseLocation';
import { Schedule } from './PageForm/tabs/Schedule/Schedule';
import { CreateFromContainerStyled } from '@Widgets/styles';
import { useBackendValidation } from '../../../hooks/useBackendValidation';
import { ResourceGoToListButton } from '@Widgets/ResourceItemPage/ResourceGoToListButton/ResourceGoToListButton';
import { ResourceRoutes } from '@Plugins/resourceRoutes';
import { ResourceTab } from '@UI';

export const CreatePage = () => {
  const { errors, onErrorHandler } = useBackendValidation();

  return (
    <>
      <ResourceGoToListButton resource={ResourceRoutes.warehouse.routePath} />

      <CreateFromContainerStyled mutationOptions={{ onError: onErrorHandler }}>
        <TabbedForm
          defaultValues={CREATE_PAGE_DEFAULT_FIELD_VALUES}
          tabs={<TabbedFormTabs variant="scrollable" scrollButtons="auto" />}
        >
          <FormTab label="stores.warehouses.pages.tabs.general.caption">
            <General errors={errors} />
          </FormTab>

          <ResourceTab
            subscribedFields={['location']}
            label="stores.warehouses.pages.tabs.warehouseLocation.caption"
          >
            <WarehouseLocation />
          </ResourceTab>

          <ResourceTab
            label="stores.warehouses.pages.tabs.schedule.caption"
            subscribedFields={['schedule']}
          >
            <Schedule />
          </ResourceTab>
        </TabbedForm>
      </CreateFromContainerStyled>
    </>
  );
};
