import { AdditionalPropertyItemProps } from '../interface';
import { useState, useEffect } from 'react';
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  CheckboxGroupInput,
  RadioButtonGroupInput,
  TextInput,
  DateTimeInput,
  TimeInput,
  DateInput,
  FileInput,
  FormDataConsumer,
  FileField,
  useLocaleState,
} from 'react-admin';
import {
  Flex,
  ResourceSwitcher,
  ResourceColorInput,
  ResourceImageInput,
  ResourceInputNumber,
} from '@UI';
import { Typography, Link } from '@mui/material';
import { get } from '@Helpers';

export const InheritedProperty = ({
  propertiesList,
  id,
  source,
}: Omit<AdditionalPropertyItemProps, 'markAsForOffer'>) => {
  const [lang] = useLocaleState();
  const [field, setField] = useState<CoreProperty>();
  const { type, name = '', choices = [], locales } = field || {};

  useEffect(() => {
    if (field) return;
    setField(propertiesList?.find((property) => id === property.id));
  }, [id, JSON.stringify(propertiesList)]);

  if (!type) return null;

  const getArabicLocale = () => locales?.find((item) => item.language === 'ar');

  const getLocalizedName = () => {
    if (lang === 'en') {
      return name;
    }

    const arabic = getArabicLocale();

    return arabic?.name || name;
  };

  const getLocalizedChoiceLabel = (choiceValue: string): string => {
    const targetChoice = choices.find((item) => item.value === choiceValue);

    if (lang === 'en') {
      return targetChoice?.label || choiceValue;
    }

    const arabic = getArabicLocale();

    if (!arabic) {
      return targetChoice?.label || choiceValue;
    }

    const localizedChoice = arabic.choices.find(
      (item) => item.value === choiceValue
    );

    return localizedChoice?.label || targetChoice?.label || choiceValue;
  };

  const optionsList = {
    name: `${source}.values`,
    source: `${source}.values`,
    label: getLocalizedName(),
    choices: choices?.map(({ value }) => ({
      id: value,
      name: getLocalizedChoiceLabel(value),
    })),
    translate: 'no' as const,
    fullWidth: true,
    disabled: true,
  };

  const optionsSingle = {
    name: `${source}.value`,
    source: `${source}.value`,
    label: getLocalizedName(),
    translate: 'no' as const,
    fullWidth: true,
    disabled: true,
  };

  // eslint-disable-next-line
  console.log({ optionsList, optionsSingle });

  switch (type) {
    case 'sizeList':
    case 'tagList':
      return <AutocompleteArrayInput {...optionsList} defaultValue={[]} />;
    case 'colorList':
      return (
        <AutocompleteArrayInput
          {...optionsList}
          defaultValue={[]}
          matchSuggestion={(filter, suggestion) =>
            suggestion.name.includes(filter)
          }
          optionText={(option: SelectOption) => (
            <Flex alignItems="center" gap={1}>
              <Flex
                bgcolor={option.id.toString()}
                padding={1}
                borderRadius={100}
              />

              <Typography>{option.name}</Typography>
            </Flex>
          )}
        />
      );
    case 'selector':
      return <AutocompleteInput {...optionsList} defaultValue={[]} />;
    case 'checkboxGroup':
      return <CheckboxGroupInput {...optionsList} defaultValue={[]} />;
    case 'radioGroup':
      return <RadioButtonGroupInput {...optionsList} defaultValue={[]} />;
    case 'text':
      return <TextInput {...optionsSingle} />;
    case 'textarea':
      return <TextInput multiline rows={3} {...optionsSingle} />;
    case 'toggle':
      return <ResourceSwitcher labelKey={name} {...optionsSingle} />;
    case 'dateTime':
      return <DateTimeInput {...optionsSingle} />;
    case 'time':
      return <TimeInput {...optionsSingle} />;
    case 'date':
      return <DateInput {...optionsSingle} />;
    case 'color':
      return <ResourceColorInput labelKey={name} {...optionsSingle} />;
    case 'file':
      return (
        <FileInput {...optionsSingle}>
          <FormDataConsumer>
            {({ formData }) => {
              const data = get(formData, source);

              if (typeof data === 'string') {
                return (
                  <Link href={data} target="_blank" download variant="body2">
                    {data}
                  </Link>
                );
              }

              return (
                <FileField
                  download
                  target="_blank"
                  source="src"
                  title="title"
                />
              );
            }}
          </FormDataConsumer>
        </FileInput>
      );
    case 'image':
      return <ResourceImageInput {...optionsSingle} />;
    case 'number':
      return <ResourceInputNumber {...optionsSingle} />;
    default:
      return null;
  }
};
