import { GetManyReferenceParams, GetManyReferenceResult } from 'react-admin';
import { CommonCrud } from '@PluginBase';

export class InStockInformationCRUD extends CommonCrud {
  async list(params: GetManyReferenceParams): Promise<GetManyReferenceResult> {
    const filter = {
      search: params.id,
    };

    return super.list({ ...params, ...{ filter } });
  }
}
