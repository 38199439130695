import { InputAdornment } from '@mui/material';
import { ResourceInputNumberProps } from '../interface';
import { TextInput } from 'react-admin';
import { BaseSyntheticEvent, KeyboardEvent } from 'react';

export const ResourceInputNumber = ({
  prefixSymbol,
  postfixSymbol,
  isInteger = false,
  InputProps,
  ...props
}: ResourceInputNumberProps) => {
  const handleKeyDown = (e: KeyboardEvent & BaseSyntheticEvent) => {
    if (e.metaKey) return;

    if (/^-$/.test(e.key) && e.target.value.includes('-')) {
      e.preventDefault();

      return;
    }

    if (/^\.$/.test(e.key) && e.target.value.includes('.')) {
      e.preventDefault();

      return;
    }

    if (/^[\d.-]$/.test(e.key) && e.target.value.length >= 15) {
      e.preventDefault();

      return;
    }

    if (isInteger) {
      if (/^[^\d-]$/.test(e.key)) e.preventDefault();
    } else {
      if (/^[^\d.-]$/.test(e.key)) e.preventDefault();
    }
  };

  return (
    <TextInput
      {...props}
      InputProps={{
        ...InputProps,
        onKeyDown: handleKeyDown,
        startAdornment: !!prefixSymbol && (
          <InputAdornment position="start">{prefixSymbol}</InputAdornment>
        ),
        endAdornment: !!postfixSymbol && (
          <InputAdornment position="end">{postfixSymbol}</InputAdornment>
        ),
      }}
    />
  );
};
