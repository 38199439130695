const loadLocale = () => {
  const LS_KEY = 'RaStore.locale';
  const locale = localStorage.getItem(LS_KEY);

  if (!locale) {
    return 'en';
  }

  return locale.replace(/['"]/gi, '');
};

export default loadLocale;
