import { PluginResource, PluginMenuSubItem, CustomRoute } from '../../interface';
import { ResourceProps } from 'ra-core';
import { DataProvider } from 'react-admin';

export class PluginResourceController implements PluginResource {
  readonly menuItem: Nullable<PluginMenuSubItem>;
  readonly customRoutes?: CustomRoute[];
  readonly resourceRoute: Nullable<ResourceProps>;
  readonly dataProvider: Nullable<Partial<DataProvider>>;

  constructor({ resourceRoute, menuItem, customRoutes, dataProvider }: PluginResource) {
    this.menuItem = menuItem;
    this.customRoutes = customRoutes;
    this.resourceRoute = resourceRoute;
    this.dataProvider = dataProvider ?? null;
  }
}
