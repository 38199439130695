import {
  cleanPhone,
  emailValidator,
  onlyLatinValidator,
  passwordValidator,
  pointsToUnits,
  unitsToPoints,
} from '@Helpers';
import { notContainSpaceValidator } from '../RegExpValidator/RegExpValidator';
import { regex } from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { REQUIRED_WITH_IMAGE_DISPLAY_TYPE } from '@Plugins/Catalogue/resources/Categories/constants';

export const isEmail =
  (message = 'ra.validation.email') =>
  (value: string) => {
    return value && !emailValidator(value) ? message : undefined;
  };

export const isPassword =
  (min: number, message = 'ra.validation.password') =>
  (value: string) => {
    return value && !passwordValidator(value, min)
      ? { message, args: { min } }
      : undefined;
  };

export const isPasswordConfirm = (
  message = 'ra.validation.passwordConfirm'
) => {
  return (value: string, { password }: { password: string }) =>
    value === password ? undefined : message;
};

export const isPhone =
  (message = 'ra.validation.phone') =>
  (value: string | null) => {
    const cleanPhoneLength = cleanPhone(value || '').length;

    return value && (cleanPhoneLength < 10 || cleanPhoneLength > 15)
      ? message
      : undefined;
  };

export const onlyLatin =
  (message = 'ra.validation.onlyLatin') =>
  (value: string) => {
    return value && !onlyLatinValidator(value) ? message : undefined;
  };

export const onlyLettersAndDigits = (
  message = 'ra.validation.onlyLettersAndDigits'
) => regex(/^[-\w\d]+$/, message);

export const withoutSpaces =
  (message = 'ra.validation.withoutSpaces') =>
  (value: string) => {
    return value && !notContainSpaceValidator(value) ? message : undefined;
  };

export const minLengthExt =
  (min: number, message = 'ra.validation.minLengthExt') =>
  (value: string) => {
    const valueLength = value?.trim?.().length || 0;

    return value && valueLength < min
      ? { message, args: { min, cur: valueLength } }
      : undefined;
  };

export const maxLengthExt =
  (max: number, message = 'ra.validation.maxLengthExt') =>
  (value = '') => {
    const valueLength = value?.trim()?.length ?? 0;

    return valueLength > max
      ? { message, args: { max, cur: valueLength } }
      : undefined;
  };

export const isEmptyAfterTrimmed =
  (message = 'ra.validation.required') =>
  (value: string) => {
    return value && typeof value?.trim === 'function'
      ? value.trim().length
        ? undefined
        : message
      : undefined;
  };

export const isInteger =
  (message = 'ra.validation.integer') =>
  (value: string) => {
    return value && new RegExp(/[.,]/).test(value) ? message : undefined;
  };

export const isNotZero =
  (message = 'ra.validation.notZero') =>
  (value: string | number) => {
    return value !== '' && parseFloat(value as string) === 0
      ? message
      : undefined;
  };

export const minNum =
  (min: number, message = 'ra.validation.minValue') =>
  (value: string) => {
    let numVal = parseFloat(value);

    if (isNaN(numVal)) numVal = min - 1;

    return value && numVal < min ? { message, args: { min } } : undefined;
  };

export const maxNum =
  (max: number, message = 'ra.validation.maxValue') =>
  (value: string) => {
    let numVal = parseFloat(value);

    if (isNaN(numVal)) numVal = max + 1;

    return value && numVal > max ? { message, args: { max } } : undefined;
  };

export const isDiscountPriceSatisfactory =
  (priceInGeneratedOffer?: string, message = 'ra.validation.maxValue') =>
  (value: string, { price }: { price: string }) => {
    if (priceInGeneratedOffer) price = priceInGeneratedOffer;
    if (
      value === undefined ||
      value === '' ||
      price === undefined ||
      price === ''
    )
      return undefined;
    if (isNaN(parseFloat(price))) return undefined;
    const priceInteger = unitsToPoints(price);

    if (priceInteger <= 0) return undefined;

    const division = parseInt(
      '1' +
        Array(
          parseInt(
            window.localStorage.getItem('RaStore.digitsAfterComma') || '2',
            10
          )
        )
          .fill(0)
          .join(''),
      10
    );
    const discountPriceThreshold =
      priceInteger - Math.ceil(priceInteger / division);

    if (discountPriceThreshold <= 0) {
      return {
        message: 'ra.validation.priceTooLow',
        args: {
          min: pointsToUnits(
            priceInteger + Math.abs(discountPriceThreshold || priceInteger)
          ),
        },
      };
    }

    const discountPriceInteger = unitsToPoints(value);

    if (discountPriceInteger > discountPriceThreshold) {
      return { message, args: { max: pointsToUnits(discountPriceThreshold) } };
    }

    return undefined;
  };

export const validateMoneyField = (
  key: string,
  value: string,
  isInteger = false
): boolean => {
  if (/^-$/.test(key) && value.includes('-')) {
    return false;
  }

  if (/^\.$/.test(key) && value.includes('.')) {
    return false;
  }

  if (/^[\d.-]$/.test(key) && value.length >= 15) {
    return false;
  }

  if (isInteger) {
    if (/^[^\d-]$/.test(key)) return false;
  } else {
    if (/^[^\d.-]$/.test(key)) return false;
  }

  return true;
};

export const shouldBeEmptyRelatedToField =
  (field: string, message = 'ra.validation.shouldBeEmptyRelatedToField') =>
  (value: string, values: Record<string, string>) => {
    const targetField = values[field];

    if (!targetField) {
      return;
    }

    if (targetField && value) {
      return {
        message,
        args: {
          targetField: field,
        },
      };
    }
  };

export const cannotBeActivated =
  (message = 'ra.validation.required') =>
  (
    value: boolean,
    { displayType, previewImage }: FieldValues
  ): undefined | string => {
    if (
      Object.values(REQUIRED_WITH_IMAGE_DISPLAY_TYPE).includes(
        `${displayType}`
      ) &&
      value &&
      !previewImage?.url
    ) {
      return message;
    }
  };
