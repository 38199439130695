import { ReactElement, useState } from 'react';
import { ResourceList } from '@Widgets/ResourceList/ResourceList';
import { Button, useRefresh, useTranslate, useUpdate } from 'react-admin';
import { LIST_COLUMNS } from './constants';
import { SimplyReplenish } from './interface';
import { FileImport, Flex, SUPPORTED_FORMATS } from '@UI';
import { ExportXLSXButton } from '@Helpers';
import { ReplenishmentApiResource } from '@Plugins/Replenishment/apiRoutes';
import { FILTERS } from '@Plugins/Replenishment/resources/Simply_Replenish/constants';
import { ResourceRoutes } from '@Plugins/resourceRoutes';
import { useCheckMutationAnswer } from '@ROOT/hooks';
import axios from 'axios';

export const ListPage = (): ReactElement => {
  const translate = useTranslate();
  const [update, { isLoading, error, isSuccess }] = useUpdate();
  const refresh = useRefresh();
  const [StateIsLoading, setIsLoading] = useState(false);

  useCheckMutationAnswer({
    successMessage: 'File loaded',
    errorMessage: 'Error uploading file',
    error: error as Error,
    isSuccess,
    shouldUpdateList: true,
  });

  async function onFileSelectHandler(file: File) {
    await update(ResourceRoutes.replenishment.simply_replenish.resourcePath, {
      data: { file },
      meta: 'load',
    });
  }

  async function Update_Calculation() {
    const url = ReplenishmentApiResource.simplyRecalculate;

    setIsLoading(true);
    await axios
      .get(url)
      .then(() => {
        refresh();
      })
      .catch(() => {})
      .finally(() => setIsLoading(false));
  }

  return (
    <ResourceList<SimplyReplenish>
      empty={false}
      exporter={false}
      bulkActionButtons={false}
      filters={FILTERS}
      columns={LIST_COLUMNS}
      showDeleteButton={false}
      actions={
        <Flex alignItems="center">
          <Button
            label="Recalculate"
            variant="text"
            onClick={Update_Calculation}
            disabled={StateIsLoading}
          />
          <FileImport
            isLoading={isLoading}
            isSuccess={isSuccess}
            accept={SUPPORTED_FORMATS.excel.toString()}
            onFileSubmitted={onFileSelectHandler}
          />
          <ExportXLSXButton
            url={ReplenishmentApiResource.simplyExport}
            filename="simply_replenish"
          />
        </Flex>
      }
      getItemCardCaption={(record) =>
        translate(
          'replenishment.pages.simply_replenish.labels.mobileCardCaption',
          {
            id: record.id,
          }
        )
      }
    />
  );
};
