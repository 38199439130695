import { useState } from 'react';
import {
  useGetOne,
  SimpleForm,
  useUpdate,
  useDelete,
  useRecordContext,
  Toolbar,
  SaveButton,
} from 'react-admin';
import { useQuery } from 'react-query';
import { FieldValues } from 'react-hook-form';

import { Flex, Loader, UIDeleteButtonWithConfirm } from '@UI';
import { ResourceRoutes } from '@PluginManager/plugins/resourceRoutes';
import { accessPermission } from '@Helpers';
import { useCheckMutationAnswer } from '@Hooks/useCheckMutationAnswer';
import { ResourceMeta } from '@Plugins/Stores/resources/Warehouses/constants';

import { DeliveryAreaForm } from './DeliveryAreaForm/DeliveryAreaForm';
import { TabName } from './DeliveryAreaForm/interface';
import {
  getScheduleByZoneFactory,
  normalizeDeliveryScheduleInstance,
} from './utils';

interface DeliveryUpdateFormProps {
  id: string;
  onRemove: () => void;
  existingAreas: CoreDeliveryAreaEntity[];
}

export const DeliveryUpdateForm = ({
  id,
  onRemove,
  existingAreas,
}: DeliveryUpdateFormProps) => {
  existingAreas = existingAreas.filter((area) => area.id !== id);

  const [currentTab, setCurrentTab] = useState<TabName | undefined>(undefined);

  const record = useRecordContext<CoreWarehouse>();

  const { isLoading, data } = useGetOne<CoreDeliveryAreaEntity>(
    ResourceRoutes.warehouse.resourcePath,
    {
      id: {
        id,
        timezone: record.timezone,
      } as unknown as string,
      meta: ResourceMeta.DeliveryArea,
    },
    {
      cacheTime: 0,
      retry: false,
      refetchOnMount: false,
      retryOnMount: false,
    }
  );

  const { data: deliveryScheduleData } = useQuery(
    'deliverySchedule',
    getScheduleByZoneFactory(id)
  );

  const [update, { isLoading: IsUpdateLoading, isSuccess, error }] =
    useUpdate();

  const [deleteAction] = useDelete();

  const onFormSubmit = (values: FieldValues) => {
    delete values.existingAreas;
    delete values.deliverySchedule;
    delete values.updated_at;
    delete values.created_at;
    delete values.deleted_at;

    if (!Array.isArray(values.schedule) || !values.use_custom_schedule) {
      values.schedule = [];
    }

    if (!Array.isArray(values.delivery_price_multipliers)) {
      values.delivery_price_multipliers = [];
    }

    if (!Array.isArray(values.delivery_price_threshold_multipliers)) {
      values.delivery_price_threshold_multipliers = [];
    }

    update(ResourceRoutes.warehouse.resourcePath, {
      meta: ResourceMeta.DeliveryArea,
      data: values,
      previousData: data,
      id,
    }).catch(() => undefined);
  };
  const onDeleteItem = async () => {
    await deleteAction(ResourceRoutes.warehouse.resourcePath, {
      id: data?.id,
      meta: ResourceMeta.DeliveryArea,
      previousData: data,
    });

    onRemove();
  };

  useCheckMutationAnswer({
    isSuccess,
    error: error as Error,
    successMessage: 'ra.notification.updated',
    translationArgs: { smart_count: 1 },
    errorMessage: 'ra.message.error',
  });

  if (isLoading) return <Loader />;

  return (
    <SimpleForm
      record={{
        ...data,
        existingAreas,
        deliverySchedule:
          deliveryScheduleData?.data
            .filter((item) => !item.deleted_at)
            .map(normalizeDeliveryScheduleInstance) || [],
      }}
      onSubmit={onFormSubmit}
      toolbar={
        currentTab !== TabName.DeliverySchedule && (
          <Toolbar>
            <SaveButton disabled={IsUpdateLoading} />

            {accessPermission.checkPermission('Stores', 'D') && (
              <Flex ml="auto">
                <UIDeleteButtonWithConfirm onConfirm={onDeleteItem} />
              </Flex>
            )}
          </Toolbar>
        )
      }
    >
      <DeliveryAreaForm
        location={record.location}
        zoneId={id}
        timezone={record.timezone}
        onTabChange={setCurrentTab}
      />
    </SimpleForm>
  );
};
