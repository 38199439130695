import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import {
  AppBar,
  AppBarProps,
  useStoreContext,
  useTranslate,
  LoadingIndicator,
} from 'react-admin';
import { useLocales } from 'ra-core';
import { useTheme } from '@mui/material/styles';

import { Flex, TruncatedText } from '@UI';
import pluginManager from '@PluginManager/PluginManager';
import { ResourceRoutes } from '@PluginManager/plugins/resourceRoutes';
import { accessPermission } from '@Helpers';
import { COMPANY_LOGOS } from '@Helpers/AccessPermissions/constants';
import { useLocale } from '@ROOT/layout/localeProvider';

import { ReactComponent as Logo } from '../../../assets/company-logos/cloudretail.svg';
import { ReactComponent as LogoDark } from '../../../assets/company-logos/cloudretail-black.svg';

import { CustomUserMenu } from './CustomUserMenu/CustomUserMenu';
import { LocalesMenuButton } from './CustomLocaleMenu';

const CustomToolbar = () => {
  const locales = useLocales();
  const { setLocale } = useLocale();
  const { subscribe } = useStoreContext();

  useEffect(() => {
    const unsubscribe = subscribe('locale', (locale) => {
      setLocale(locale.replace(/['"]/gi, ''));
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const isLocaleMenuEnabled =
    accessPermission.company?.languageChangingAvailable &&
    locales &&
    locales.length > 1;

  return (
    <>
      <LoadingIndicator />
      {isLocaleMenuEnabled ? <LocalesMenuButton /> : null}
    </>
  );
};

const CustomAppBar = (props: AppBarProps) => {
  const { pathname } = useLocation();
  const translate = useTranslate();
  const theme = useTheme();
  const [title, setTitle] = useState('');
  const [menuTranslations, setMenuTranslations] = useState<Record<
    string,
    string
  > | null>(null);

  useEffect(() => {
    const translation: Record<string, string> = {};

    for (const menuItem of pluginManager.pluginsMenu) {
      const { rootMenu, items } = menuItem;
      let rootName = '';

      if (rootMenu?.caption) {
        rootName = translate(
          rootMenu.caption.translationKey,
          rootMenu.caption.options
        );
        rootName = rootName ? rootName + ' → ' : '';

        if (rootMenu.caption.translationKey === 'catalogue.caption') {
          translation[ResourceRoutes.offer.routePath] =
            rootName + translate('catalogue.pages.offers.caption');
        }
      }

      for (const subMenu of items) {
        const { route, caption } = subMenu;
        const { translationKey, options } = caption;

        const path = typeof route === 'function' ? route() : route;

        translation[path] = rootName + translate(translationKey, options);
      }
    }

    setMenuTranslations(translation);
  }, []);

  useEffect(() => {
    if (!menuTranslations) return;

    if (pathname === '/') {
      setTitle(translate('ra.page.dashboard'));

      return;
    }

    if (menuTranslations[pathname]) {
      setTitle(menuTranslations[pathname]);

      return;
    }

    const pathArray = pathname.split('/');
    let title = '';

    while (pathArray.length) {
      const entity = pathArray.pop();
      const path = pathArray.join('/');
      const action = path.includes('/dc/') ? 'show' : 'edit';

      if (entity === 'create') {
        title = `${menuTranslations[path]} → ${translate('ra.action.create')}`;

        break;
      }

      if (menuTranslations[path]) {
        title = `${menuTranslations[path]} → ${translate(
          `ra.action.${action}`
        )}`;

        break;
      }
    }

    setTitle(title);
  }, [pathname, menuTranslations]);

  const getCompanyLogo = () => {
    const defaultLogo =
      theme.palette.mode === 'dark' ? (
        <Logo width={150} height={27} />
      ) : (
        <LogoDark width={60} height={27} />
      );

    if (!accessPermission.companyName) {
      return defaultLogo;
    }

    const logo = COMPANY_LOGOS[accessPermission.companyName];

    if (!logo) {
      return defaultLogo;
    }

    return logo;
  };

  return (
    <AppBar
      {...props}
      toolbar={<CustomToolbar />}
      userMenu={<CustomUserMenu />}
    >
      <Flex
        fullWidth
        justifyContent="space-between"
        maxHeight="60px"
        alignItems="center"
      >
        <Flex>{getCompanyLogo()}</Flex>

        <Flex flexGrow={1} justifyContent="center">
          <TruncatedText lines={1} variant="body1" ml={2} title={title}>
            {title}
          </TruncatedText>
        </Flex>
      </Flex>
    </AppBar>
  );
};

export default CustomAppBar;
