import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { useTranslate, useTheme, Title } from 'react-admin';
import { darkTheme, lightTheme } from '../../layout/themes';
import { Flex } from '@UI';
import { Typography } from '@mui/material';

const Configuration = () => {
  const translate = useTranslate();
  const [theme, setTheme] = useTheme();

  return (
    <Card>
      <Title title={translate('pos.configuration')} />

      <CardContent>
        <Flex alignItems="center">
          <Typography>{translate('pos.theme.name')}</Typography>

          <Button
            variant="contained"
            sx={{ margin: '1em' }}
            color={theme?.palette?.mode === 'light' ? 'primary' : 'secondary'}
            onClick={() => setTheme(lightTheme)}
          >
            {translate('pos.theme.light')}
          </Button>

          <Button
            variant="contained"
            sx={{ margin: '1em' }}
            color={theme?.palette?.mode === 'dark' ? 'primary' : 'secondary'}
            onClick={() => setTheme(darkTheme)}
          >
            {translate('pos.theme.dark')}
          </Button>
        </Flex>
      </CardContent>

      {/* <CardContent> */}
      {/*   <Flex alignItems='center'> */}
      {/*     <Typography>{translate('pos.language')}</Typography> */}

      {/*     <Button */}
      {/*       variant='contained' */}
      {/*       sx={{ margin: '1em' }} */}
      {/*       color={locale === 'en' ? 'primary' : 'secondary'} */}
      {/*       onClick={() => setLocale('en')} */}
      {/*     > */}
      {/*       en */}
      {/*     </Button> */}
      {/*   </Flex> */}
      {/* </CardContent> */}
    </Card>
  );
};

export default Configuration;
