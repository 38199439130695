import { RequestAPI } from '@RestApi';
import { AbstractApi, CommonCrud } from '@PluginBase';
import { OrderApiProps, OrderApiResponse } from './interface';
import { GetListParams } from 'ra-core';
import { GetListResult } from 'react-admin';

export class OrdersCRUD extends CommonCrud {
  constructor(url: string) {
    super(url, {
      arrayFormatter: (key: string, values: string[]) => {
        return values.map((value) => `${key}=${value}`).join('&');
      },
    });
  }

  async list(
    params: GetListParams,
    headers?: Record<string, string>
  ): Promise<GetListResult> {
    const { filter } = params;
    const newFilter: Record<string, string | string[]> = {};

    Object.keys(filter).forEach((key) => {
      let value: string | string[] = filter[key] as string;

      if (key.includes('From')) {
        value = new Date(value + 'T00:00:00.000').toISOString();
      }

      if (key.includes('To')) {
        value = new Date(value + 'T23:59:59.999').toISOString();
      }

      newFilter[
        key.replace(/(From|To)$/, (str) => `[${str === 'From' ? '0' : '1'}]`)
      ] = value;
    });

    if (newFilter['created_at[1]'] && !newFilter['created_at[0]']) {
      newFilter['created_at[0]'] = new Date(0).toISOString();
    }

    if (newFilter['created_at[0]'] && !newFilter['created_at[1]']) {
      newFilter['created_at[1]'] = new Date().toISOString();
    }

    if (newFilter['updated_at[1]'] && !newFilter['updated_at[0]']) {
      newFilter['updated_at[0]'] = new Date(0).toISOString();
    }

    if (newFilter['updated_at[0]'] && !newFilter['updated_at[1]']) {
      newFilter['updated_at[1]'] = new Date().toISOString();
    }

    params.filter = newFilter;

    return super.list(params, headers);
  }
}

export class OrderApi extends AbstractApi<OrderApiProps, OrderApiResponse> {
  constructor(apiUrl: string, orderId: string) {
    super(apiUrl, { orderId });
  }

  async execute(): Promise<OrderApiResponse> {
    const axiosResponse = await RequestAPI.get(
      `${this.apiUrl}/${this.params.orderId}`
    );

    return {
      data: axiosResponse.order,
    };
  }
}
