import { RaRecord } from 'react-admin';
import { ListColumn } from '@Widgets/ResourceList/interface';
import { StatusBadgeResource } from '../share/StatusBadgeResource/StatusBadgeResource';
import { Flex } from '@UI';
import { Link } from 'react-router-dom';
import { ResourceRoutes } from '@Plugins/resourceRoutes';

export const apiUrl = '/dc/v1/picking-cages';

export enum PICKING_CAGE_STATUS {
  PICKING = 'picking',
  READY_TO_SHIP = 'ready_to_ship',
}

export interface CagesCrudGetManyResponseDataItem extends RaRecord {
  warehouse: string;
  cages: CorePickingOrderCageActual[];
}

export interface CAGES_DATA_LIST_COLUMN extends RaRecord {
  cageBarcode: string;
  status: 'ready_to_ship' | 'picking';
  rawItem: CorePickingOrderCageActual;
  warehouseName: string;
}

export interface CAGES_PO_LIST_COLUMN extends RaRecord {
  displayTitle: {
    outboudOrderName: string;
    outboudOrderId: string;
    pickingOrderName: string;
    pickingOrderId: string;
  };
  finishedAt: string;
}

export const CAGES_DATA_LIST_COLUMNS: ListColumn<CAGES_DATA_LIST_COLUMN>[] = [
  {
    source: 'cageBarcode',
    label: '',
    type: 'text',
    sortable: false,
  },
  {
    source: 'status',
    label: '',
    type: 'custom',
    customComponent: <StatusBadgeResource key="status" />,
    sortable: true,
  },
];

export const CAGES_PO_LIST_COLUMNS: ListColumn<CAGES_PO_LIST_COLUMN>[] = [
  {
    source: 'displayTitle',
    label: '',
    type: 'function',
    function: (record) => (
      <Flex asColumn>
        <Link
          to={`${ResourceRoutes.outboundOrders.resourcePath}/${record.displayTitle.outboudOrderId}`}
          style={{ color: '#fff' }}
        >
          {record.displayTitle.outboudOrderName}
        </Link>
        <Link
          to={`${ResourceRoutes.pickingOrders.resourcePath}/${record.displayTitle.pickingOrderId}`}
          style={{ color: '#fff' }}
        >
          {record.displayTitle.pickingOrderName}
        </Link>
      </Flex>
    ),
    sortable: false,
  },
  {
    source: 'finishedAt',
    label: '',
    type: 'date',
    sortable: false,
  },
];
