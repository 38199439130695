import { Button } from '@mui/material';
import { Link as LinkIcon } from '@mui/icons-material';
import { useRecordContext, useTranslate } from 'react-admin';

const ExportLinkColumn = () => {
  const { file } = useRecordContext<CoreOfferExport>();

  const translate = useTranslate();

  if (!file) {
    return <></>;
  }

  return (
    <Button
      variant="text"
      href={file}
      download={file}
      target="_blank"
      endIcon={<LinkIcon />}
      size="small"
    >
      {translate(
        'catalogue.pages.offerOperations.offerExports.labels.exportLink'
      )}
    </Button>
  );
};

export default ExportLinkColumn;
