import { PropsWithChildren } from 'react';
import { Datagrid } from 'react-admin';
import { useSort } from './useSort';
import { ResourceDesktopGridProps } from './interface';

export const ResourceDesktopGrid = ({
  children,
  defaultSort,
  isInteractive = true,
  ...rest
}: PropsWithChildren<ResourceDesktopGridProps>) => {
  const { sort, onSortHandler } = useSort(defaultSort);

  return (
    <Datagrid
      {...rest}
      hover={isInteractive}
      optimized
      sort={rest.sort ?? sort}
      setSort={rest.setSort ?? onSortHandler}
      rowClick={isInteractive ? rest.rowClick ?? 'edit' : undefined}
    >
      {children}
    </Datagrid>
  );
};
