import { Fragment } from 'react';
import { useLocaleState } from 'react-admin';
import sortBy from 'lodash/sortBy';

import { ListProperty } from '@Plugins/Catalogue/resources/share/Tabs/AdditionalProperties/ListProperty/ListProperty';
import { SingleProperty } from '@Plugins/Catalogue/resources/share/Tabs/AdditionalProperties/ListProperty/SingleProperty';

import { PropertiesFieldsProps } from './interfaces';

export const PropertiesFields = ({
  propertyList = [],
  additionalProperties,
  source,
}: PropertiesFieldsProps) => {
  const [lang] = useLocaleState();

  const originalIndexes = new Map<string, number>(
    additionalProperties.map(({ id }, idx) => [id, idx])
  );

  const getLocalizedNameOfProperty = (property: CoreProperty): string => {
    if (lang === 'en') {
      return property.name;
    }

    const arabicLocale = property.locales?.find(
      (locale) => locale.language === 'ar'
    );

    return arabicLocale?.name || property.name;
  };

  const getLocalizedChoicesOfProperty = (
    property: CoreProperty
  ): ChoiceDto[] => {
    if (lang === 'en') {
      return property.choices || [];
    }

    const arabicLocale = property.locales?.find(
      (locale) => locale.language === 'ar'
    );

    return arabicLocale?.choices || property.choices || [];
  };

  return (
    <>
      {sortBy(additionalProperties, 'referenceId').map((selectedProperty) => {
        const targetProperty = propertyList?.find(
          (prop) => prop.id === selectedProperty.additionalPropertyId
        );

        if (!targetProperty) {
          return;
        }

        const { code, type } = targetProperty;

        const originalIndex = originalIndexes.get(selectedProperty.id);
        const fieldSource = `${source}.${originalIndex}`;

        return (
          <Fragment key={selectedProperty.id}>
            <ListProperty
              choices={getLocalizedChoicesOfProperty(targetProperty).map(
                ({ value, label }) => ({
                  id: value,
                  name: label,
                })
              )}
              label={getLocalizedNameOfProperty(targetProperty)}
              source={`${fieldSource}.values`}
              type={type}
              code={code}
            />

            <SingleProperty
              label={getLocalizedNameOfProperty(targetProperty)}
              source={`${fieldSource}.value`}
              type={type}
              code={code}
            />
          </Fragment>
        );
      })}
    </>
  );
};
