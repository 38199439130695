import { ImageUploaderProps, ImageDropItem } from './interface';
import { Box, Typography } from '@mui/material';
import { useDrag, useDrop } from 'react-dnd';
import { useRef } from 'react';
import { ImageUploaderView } from './ImageUploaderView';
import { useTranslate } from 'react-admin';
import { MB } from '@ROOT/constants';
import { S3Uploader } from '@Helpers';
import { useTheme } from '@mui/material/styles';

export const ImageUploaderDndWrapper = ({
  source,
  onUpload,
  value,
  onRemove,
  isDraggable,
  onDrop,
  isLoading,
  accept,
  maxSizeMb = 2,
}: ImageUploaderProps) => {
  const translate = useTranslate();
  const theme = useTheme();

  const [{ isDragging }, drag] = useDrag({
    type: 'IMAGE',
    collect: (monitor: { isDragging: () => boolean }) => ({
      isDragging: monitor.isDragging(),
    }),
    item: {
      value,
      source,
    },
  });
  const BoxRef = useRef();

  const [{ handlerId }, drop] = useDrop({
    accept: 'IMAGE',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop: (item) => {
      onDrop?.(
        {
          value,
          source,
        },
        item as ImageDropItem,
      );
    },
  });

  drag(drop(BoxRef));

  const rejectMessage = translate('imageGallery.allowedImage', {
    types: accept?.join(', '),
    size: maxSizeMb,
  });

  return (
    <>
      <Box
        data-handler-id={handlerId}
        ref={isDraggable ? BoxRef : undefined}
        sx={{
          opacity: isDragging ? 0.5 : 1,
          margin: 'auto',
        }}
      >
        <ImageUploaderView
          rejectFileMessage={rejectMessage}
          maxSizeMb={maxSizeMb ? maxSizeMb * MB : undefined}
          accept={S3Uploader.getAllowedTypes(accept)}
          onUpload={onUpload}
          source={source}
          value={value}
          isLoading={isLoading}
          onRemove={onRemove}
        />
      </Box>

      {accept !== undefined && (
        <Typography variant='caption' color={theme.palette.text.disabled}>
          {rejectMessage}
        </Typography>
      )}
    </>
  );
};
