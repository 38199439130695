import { Plugin } from '@PluginBase';
import Translation from './i18n.json';
import { Icon } from '@UI';
import WMSPickingOrdersPages from './resources/PickingOrders/controller';
import WMSPickingPickersPages from './resources/Pickers/controller';
import WMSClickAndCollectPages from './resources/ClickAndCollect/controller';
import WMSMonitorPages from './resources/Monitor/controller';
import WMSPickingPriority from './resources/PickingPriority/controller';

const WMSPicking = new Plugin({
  i18n: Translation,
  resources: [
    WMSPickingOrdersPages,
    WMSPickingPickersPages,
    WMSClickAndCollectPages,
    WMSMonitorPages,
    WMSPickingPriority,
  ],
  name: 'WMSPicking',
  rootMenu: {
    caption: {
      translationKey: 'wms-picking.caption',
    },
    icon: <Icon type="inventory" />,
  },
});

export default WMSPicking;
