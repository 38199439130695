import { useState } from 'react';
import { useNotify, useAuthProvider, useLogin } from 'react-admin';
import { PhoneStep } from './PhoneStep/PhoneStep';
import { CodeStep } from './CodeStep/CodeStep';
import { Box } from '@mui/material';
import { AuthProviderExtended } from "@ROOT/providers/authProvider/interface";
import { AxiosError } from 'axios';

const PhoneFlow = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [phone, setPhone] = useState('');
  const [rawPhone, setRawPhone] = useState('');
  const [isCodeStep, setIsCodeStep] = useState(false);

  const authProvider = useAuthProvider() as AuthProviderExtended;
  const notify = useNotify();
  const login = useLogin();

  const handleLogin = (code: string) => {
    if (!code) return;

    setIsLoading(true);
    login({
      phone: rawPhone,
      code,
    }).finally(() => setIsLoading(false)).catch((error: AxiosError<CoreRestApiErrorResponse>) => {
      let message = error.response?.data?.error || 'ra.auth.sign_in_error';

      if (error.response?.data?.message && typeof error.response?.data?.message === 'string') {
        message = error.response?.data?.message;
      }

      notify(message, {
        type: 'error',
        messageArgs: {
          _: message,
        },
      });
    });
  };

  const goOtpStep = (phone: string, rawPhone: string) => {
    setPhone(phone);
    setRawPhone(rawPhone);
    requestOtpCode(rawPhone);
  };

  const goPhoneStep = () => {
    setIsCodeStep(false);
  };

  const requestOtpCode = (phone?: string) => {
    phone = phone || rawPhone;
    if (!phone) return;

    setIsLoading(true);
    authProvider.requestOtpCode({ phone }).then(() => {
      setIsCodeStep(true);
    }).finally(() => setIsLoading(false)).catch((error: AxiosError<CoreRestApiErrorResponse>) => {
      let message = error.response?.data?.error || 'pages.login.error.failedSendOtpCode';

      if (error.response?.data?.message && typeof error.response?.data?.message === 'string') {
        message = error.response?.data?.message;
      }

      notify(message, {
        type: 'error',
        messageArgs: {
          _: message,
        },
      });
    });
  };

  return (
    <Box>
      {!isCodeStep ? (
        <PhoneStep
          defaultPhoneValue={phone}
          isLoading={isLoading}
          onPhoneFilled={goOtpStep}
        />
      ) : (
        <CodeStep
          phone={phone}
          isLoading={isLoading}
          goPhoneStep={goPhoneStep}
          onRequestOtpCode={requestOtpCode}
          onCodeFilled={handleLogin}
        />
      )}
    </Box>
  );
};

export default PhoneFlow;
