import { S3Uploader } from '@Helpers';

interface RawValue {
  rawFile: File;
}

export default async function uploadImages(
  properties: CoreProductAdditionalField[]
): Promise<CoreProductAdditionalField[]> {
  const files: { id: number; value: File }[] = properties
    .filter(
      ({ value }) => (value as unknown as RawValue)?.rawFile instanceof File
    )
    .map(({ value, additionalPropertyId }: CoreProductAdditionalField) => ({
      id: additionalPropertyId,
      value: (value as unknown as RawValue)?.rawFile,
    }));
  const uploader = new S3Uploader(
    files.map(({ value }) => value),
    'products'
  );
  const urls = await uploader.send();

  files.forEach(({ id }, idx) => {
    const property = properties.find(
      ({ additionalPropertyId }) => additionalPropertyId === id
    );

    if (!property) return;

    property.value = urls[idx];
  });

  return properties;
}
