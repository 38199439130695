import { TableCell, IconButton, useTheme } from '@mui/material';
import { NestedListRowProps } from '../../interface';
import { Icon, Loader, Flex } from '@UI';
import { TableRowStyled, IndexTableCellStyled } from './styles';
import { ComponentFactory } from '../../../ResourceList/ComponentFactory/ComponentFactory';
import { MouseEvent } from 'react';

export const NestedListRow = (props: NestedListRowProps) => {
  const {
    record,
    shiftedColumns,
    unShiftedColumns,
    isRowExpanded,
    onRowExpanded,
    isLoading,
    isHidden,
    onRowClick,
  } = props;

  const theme = useTheme();

  function onExpandRowHandler(e: MouseEvent<HTMLButtonElement>) {
    onRowExpanded?.(record.id);

    e.stopPropagation();
    e.preventDefault();

    return false;
  }

  function onRowClickHandler() {
    onRowClick?.(record.id);
  }

  if (isHidden) {
    return null;
  }

  return (
    <TableRowStyled onClick={onRowClickHandler} $isActive={record.active} hover>
      <IndexTableCellStyled
        padding="none"
        $level={record.level}
        $indexInGroup={record.indexInGroup}
        $isExpandable={record.isExpandable}
        $dir={theme.direction}
      >
        <Flex alignItems="center">
          {record.isExpandable ? (
            <>
              {isLoading ? (
                <Flex width={30} justifyContent="center">
                  <Loader fullHeight={false} size={10} />
                </Flex>
              ) : (
                <IconButton size="small" onClick={onExpandRowHandler}>
                  <Icon
                    size="small"
                    type={isRowExpanded ? 'removeCircle' : 'addCircle'}
                  />
                </IconButton>
              )}
            </>
          ) : (
            <Flex width={record.level > 0 ? 31 : 8} />
          )}

          {shiftedColumns.map((column) => (
            <Flex key={column.source}>
              <ComponentFactory record={record} column={column} />
            </Flex>
          ))}
        </Flex>
      </IndexTableCellStyled>

      {unShiftedColumns.map((column) => (
        <TableCell key={`${record.id}-${column.source}`}>
          <ComponentFactory record={record} column={column} />
        </TableCell>
      ))}
    </TableRowStyled>
  );
};
