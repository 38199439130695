import { UserMenu, Logout, useLocaleState, useLogout } from 'react-admin';
import { ConfigurationMenu } from './ConfigurationMenu/ConfigurationMenu';

export const CustomUserMenu = () => {
  const [locale, setLocale] = useLocaleState();
  const logout = useLogout();

  const handleLogout = async () => {
    if (locale !== 'en') {
      setLocale('en');
    }

    await logout();
  };

  return (
    <UserMenu>
      <ConfigurationMenu />

      <Logout onClick={handleLogout} />
    </UserMenu>
  );
};
