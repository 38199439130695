import { FormTab, TextInput, useRecordContext } from 'react-admin';
import { Flex, ResourceTab, ResourceTabbedForm, RowInfoBlock } from '@UI';

import { General } from './components/General';
import { Images } from './components/Images';
import { Blacklist } from './components/Blacklist';
import { AdditionalLanguage } from './components/AdditionalLanguage';
import { AttachedFilters } from './components/AttachedFilters';
import { DEFAULT_PAGE_FORM_VALUES } from './constants';

export const PageForm = () => {
  const category = useRecordContext<CoreCategory>();
  const isEdit = category?.id !== undefined;

  return (
    <ResourceTabbedForm
      record={category}
      defaultValues={DEFAULT_PAGE_FORM_VALUES}
      mode="onChange"
    >
      <FormTab label="catalogue.pages.categories.tabs.general">
        <General />
      </FormTab>

      <ResourceTab
        subscribedFields={['metadataTitle', 'metadataDescription']}
        label="catalogue.pages.categories.tabs.seo"
      >
        <Flex asColumn fullWidth>
          <RowInfoBlock flex={1}>
            <TextInput
              fullWidth
              name="metadataTitle"
              source="metadataTitle"
              label="catalogue.pages.products.labels.metadataTitle"
            />
            <TextInput
              fullWidth
              name="metadataDescription"
              source="metadataDescription"
              multiline
              rows={5}
              label="catalogue.pages.products.labels.metadataDescription"
            />
          </RowInfoBlock>
        </Flex>
      </ResourceTab>

      <FormTab label="catalogue.pages.categories.tabs.additionalLanguages">
        <AdditionalLanguage />
      </FormTab>

      <FormTab label="catalogue.pages.categories.tabs.images">
        <Images />
      </FormTab>

      {Array.isArray(category?.warehouseBlacklist) && (
        <FormTab label="catalogue.pages.categories.tabs.blackList">
          <Blacklist />
        </FormTab>
      )}

      {isEdit && (
        <FormTab label="catalogue.pages.categories.tabs.attachedFilters">
          <AttachedFilters />
        </FormTab>
      )}
    </ResourceTabbedForm>
  );
};
