import { ListColumn } from '@Widgets/ResourceList/interface';
import { Stock } from '@Plugins/Replenishment/resources/Stocks/interface';

export const LIST_COLUMNS: ListColumn<Stock & any>[] = [
  {
    source: 'store.name',
    label: 'replenishment.pages.stocks.labels.store',
    type: 'text',
  },
  {
    source: 'product.sku',
    label: 'replenishment.pages.stocks.labels.product',
    type: 'text',
  },
  {
    source: 'bop_unit',
    label: 'replenishment.pages.stocks.labels.bop_unit',
    type: 'text',
  },
  {
    source: 'eop_unit',
    label: 'replenishment.pages.stocks.labels.eop_unit',
    type: 'text',
  },
  {
    source: 'date',
    label: 'replenishment.pages.stocks.labels.date',
    type: 'date',
  },
];
