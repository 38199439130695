import { useController } from 'react-hook-form';
import { RESOURCES_SCHEMA } from '../../../schemas/resourcesSchema';
import { StoryPage } from './StoryPage/StoryPage';
import { Flex, Icon, UITabWithError } from '@UI';
import { Button, Tabs } from '@mui/material';
import { useTranslate } from 'react-admin';
import { useState, useEffect, SyntheticEvent } from 'react';
import { storiesTranslationNamespace } from '../../../constants';
import { uniqueId } from '@Helpers';

export const StoryPages = () => {
  const translate = useTranslate();
  const itemsValidator = (values: CoreStoryItem[]) => {
    const notValidItems = values.reduce<number[]>(
      (items, item, index) => (!item.image ? [...items, index] : items),
      []
    );

    if (!values.length) return '[]';

    return notValidItems.length ? JSON.stringify(notValidItems) : undefined;
  };
  const {
    field: { value, onChange },
    fieldState,
  } = useController({
    name: RESOURCES_SCHEMA.items.all,
    rules: { validate: itemsValidator },
  });
  const [currentTab, setCurrentTab] = useState<number>();

  useEffect(() => {
    if (currentTab) {
      return;
    }

    setCurrentTab(value.length > 0 ? 1 : undefined);
  }, [JSON.stringify(value)]);

  function onChangeItemHandler(item: CoreStoryItem) {
    const itemIndex = value.findIndex(
      ({ id }: CoreStoryItem) => id === item.id
    );

    if (itemIndex > -1) {
      value[itemIndex] = item;
      onChange([...value]);
    }
  }

  function onDeleteItemHandler(item: CoreStoryItem) {
    const newItems = value.filter(({ id }: CoreStoryItem) => id !== item.id);

    onChange([...newItems]);
    setCurrentTab(1);
  }

  function onAddItemHandler() {
    const newItem: CoreStoryItem = {
      buttons: [],
      id: uniqueId(),
      image: '',
      order: 0,
    };

    onChange([...value, newItem]);
    setCurrentTab(value.length + 1);
  }

  function onChangeTabHandler(event: SyntheticEvent, value: number) {
    setCurrentTab(value);
  }

  const itemNumber = currentTab ? currentTab - 1 : 0;

  const notValidItems: number[] = JSON.parse(fieldState.error?.message || '[]');

  return (
    <Flex asColumn fullWidth>
      <Flex justifyContent="flex-end">
        <Button
          size="small"
          startIcon={<Icon type="add" />}
          onClick={onAddItemHandler}
        >
          {translate('ra.action.create')}
        </Button>
      </Flex>

      {!!currentTab && (
        <Flex maxWidth="90vw">
          <Tabs
            onChange={onChangeTabHandler}
            value={currentTab}
            variant="scrollable"
            scrollButtons={false}
          >
            {value.map((item: CoreStoryItem, index: number) => (
              <UITabWithError
                isError={notValidItems.includes(index)}
                value={index + 1}
                label={translate(storiesTranslationNamespace.labels.story, {
                  id: index,
                })}
                key={item.id}
              />
            ))}
          </Tabs>
        </Flex>
      )}

      {!!currentTab && !!value[itemNumber] && (
        <StoryPage
          source={`${RESOURCES_SCHEMA.items.all}.${itemNumber}`}
          value={value[itemNumber]}
          isError={notValidItems.includes(itemNumber)}
          onPageDelete={onDeleteItemHandler}
          onUpdateItem={onChangeItemHandler}
        />
      )}
    </Flex>
  );
};
