import { ShowFromContainerStyled } from '@Widgets/styles';
import { ListItemDrawer } from '@Widgets/ListItemDrawer/ListItemDrawer';
import { ResourceRoutes } from "@Plugins/resourceRoutes";
import { DetailsPage } from './DetailsPage';
import { TextInput, required, useTranslate, SimpleForm } from 'react-admin';
import {
  clickAndCollectTranslationSchema,
} from '../constants';
import { isEmptyAfterTrimmed } from '@Helpers';
import { useState, BaseSyntheticEvent } from 'react';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { useNavigate } from 'react-router-dom';
// @ts-ignore
import BarcodeReader from 'react-barcode-reader';

const validateInput = [required(), isEmptyAfterTrimmed()];

export const InputPage = () => {
  const translate = useTranslate();
  const navigate = useNavigate();
  const [record, setRecord] = useState({ orderId: '' });
  const handleChangeInput = (e: BaseSyntheticEvent) => setRecord({ orderId: e?.target?.value || '' });
  const handleQrCodeScan = (value: string) => handlerSubmit({ orderId: value || '' });
  const handlerSubmit = ({ orderId = '' }: FieldValues) => {
    setRecord({ orderId: '' });
    if (!orderId) return;
    navigate(`${ResourceRoutes.wmsClickAndCollect.resourcePath}/${orderId}`);
  };

  return (
    <>
      <SimpleForm onSubmit={handlerSubmit} toolbar={false} record={record}>
        <TextInput
          fullWidth
          name='orderId'
          source='orderId'
          label={translate(clickAndCollectTranslationSchema.labels.input)}
          onChange={handleChangeInput}
          validate={validateInput}
          sx={{ mt: 5 }}
          resettable
          autoFocus
        />
      </SimpleForm>

      <BarcodeReader onScan={handleQrCodeScan} />

      <ListItemDrawer route={ResourceRoutes.wmsClickAndCollect.resourcePath}>
        {({ entity }) =>
          entity ? (
            <ShowFromContainerStyled id={entity}>
              <DetailsPage />
            </ShowFromContainerStyled>
          ) : (
            <div />
          )
        }
      </ListItemDrawer>
    </>
  );
};
