import React, { useState, useMemo, useRef, useEffect } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
} from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import { useRecordContext, useTranslate } from 'react-admin';
import uniqBy from 'lodash/uniqBy';
import orderBy from 'lodash/orderBy';
import format from 'date-fns/format';
import parse from 'date-fns/parse';

import MessageItem from './MessageItem';
import { LoadPreviousMessagesButton, DateBadge } from './styled';
import { GROUP_DATE_FORMAT } from './constants';
import { CommentGroup, MessageListProps } from './interfaces';

const MessageList = ({
  hasMore,
  groupedComments,
  setPage,
  isLoading = false,
  lastScrollHeightRef,
  onDelete,
  onEdit,
  nominatedToEdit,
  onSetFilterByOrder,
  filterByOrder,
  isActionsEnabled,
}: MessageListProps) => {
  const translate = useTranslate();
  const record = useRecordContext<CoreOrder>();

  const [nominatedToDelete, setNominatedToDelete] =
    useState<Nullable<CoreComment>>(null);
  const [isAutoScrolling, setIsAutoScrolling] = useState(false);

  const commentsListRef = useRef<HTMLDivElement>(null);
  const prevGroupedComments = useRef(groupedComments);

  const { commentsByThisOrder, commentsByOtherOrdersMerged } = useMemo(() => {
    const otherOrdersMerged = Object.entries<CommentGroup>(
      groupedComments
    ).reduce((acc: CommentGroup, [orderId, group]) => {
      if (orderId === record.id) {
        return acc;
      }

      for (const [date, comments] of Object.entries(group)) {
        if (!acc[date]) {
          acc[date] = comments;
          continue;
        }

        acc[date] = uniqBy([...acc[date], ...comments], 'id');
      }

      return { ...acc };
    }, {});

    return {
      commentsByThisOrder: sortGroupedComments(groupedComments[record.id]),
      commentsByOtherOrdersMerged: sortGroupedComments(otherOrdersMerged),
    };
  }, [record, groupedComments]);

  const allCommentsLength = Object.values(groupedComments)
    .map((item) => Object.values(item).flat())
    .flat().length;

  useEffect(() => {
    if (
      commentsListRef.current &&
      prevGroupedComments.current !== groupedComments
    ) {
      if (lastScrollHeightRef?.current) {
        commentsListRef.current.scrollTop =
          commentsListRef.current.scrollHeight - lastScrollHeightRef.current;
        lastScrollHeightRef.current = 0;
      } else {
        commentsListRef.current.scrollTop =
          commentsListRef.current.scrollHeight;
      }

      prevGroupedComments.current = groupedComments;
    }
  }, [allCommentsLength]);

  const handleScroll = () => {
    if (isAutoScrolling) {
      return;
    }

    if (commentsListRef.current) {
      const { scrollTop, scrollHeight } = commentsListRef.current;

      if (scrollTop <= 140 && hasMore && !isLoading) {
        if (lastScrollHeightRef) {
          lastScrollHeightRef.current = scrollHeight;
        }

        setPage?.((prev) => prev + 1);
      }
    }
  };

  const handleDeleteNominatedComment = () => {
    if (!nominatedToDelete || !onDelete) {
      return;
    }

    onDelete(nominatedToDelete);
    setNominatedToDelete(null);
  };

  const handleSetFilterByOrder = () => {
    setIsAutoScrolling(true);
    onSetFilterByOrder?.((prev) => !prev);
    setTimeout(() => {
      if (commentsListRef.current) {
        commentsListRef.current.scrollTop = 0;
      }

      setIsAutoScrolling(false);
    }, 0);
  };

  const mapCommentGroup = ([date, messages]: [string, CoreComment[]]) => {
    const isToday = date === today;
    const dateStr = isToday ? translate('orders.pages.labels.today') : date;

    return (
      <Box key={date}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="row"
          py={1}
          sx={{ width: '100%' }}
        >
          <DateBadge>{dateStr}</DateBadge>
        </Box>
        {orderBy(messages, 'createdAt', 'asc').map((message) => (
          <MessageItem
            key={message.id}
            comment={message}
            onDelete={() => setNominatedToDelete(message)}
            onEdit={() => onEdit?.(message)}
            isActionsEnabled={isActionsEnabled}
          />
        ))}
      </Box>
    );
  };

  const today = format(new Date(), GROUP_DATE_FORMAT);

  const chatHeight = !isActionsEnabled ? 145 : nominatedToEdit ? 255 : 200;

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        height={`calc(100vh - ${chatHeight}px)`}
      >
        <LoadPreviousMessagesButton
          variant="contained"
          color="secondary"
          size="small"
          endIcon={
            <ChevronLeft
              sx={{ transform: `rotate(${filterByOrder ? -90 : 90}deg)` }}
            />
          }
          fullWidth
          onClick={handleSetFilterByOrder}
        >
          {translate(
            `orders.pages.buttons.${
              filterByOrder ? 'commentsOnPreviousOrders' : 'onlyThisOrder'
            }`
          )}
        </LoadPreviousMessagesButton>
        <Box
          ref={commentsListRef}
          onScroll={handleScroll}
          sx={{ overflowY: 'auto', flex: 1 }}
        >
          {!filterByOrder && commentsByOtherOrdersMerged.map(mapCommentGroup)}
          {!filterByOrder &&
            commentsByThisOrder.length > 0 &&
            commentsByOtherOrdersMerged.length > 0 && (
              <Box>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="row"
                  py={1}
                  sx={{ width: '100%' }}
                >
                  <DateBadge>{record.order_id}</DateBadge>
                </Box>
              </Box>
            )}
          {commentsByThisOrder.map(mapCommentGroup)}
        </Box>
      </Box>

      <Dialog
        open={!!nominatedToDelete}
        onClose={() => setNominatedToDelete(null)}
      >
        <DialogContent>
          {translate('orders.pages.alerts.deleteConfirmation')}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setNominatedToDelete(null)}
          >
            {translate('orders.pages.buttons.no')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDeleteNominatedComment}
          >
            {translate('orders.pages.buttons.yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const sortGroupedComments = (
  comments?: CommentGroup
): Array<[string, CoreComment[]]> =>
  Object.entries(comments ?? {}).sort((a, b) => {
    const [dateA] = a;
    const [dateB] = b;

    const dateAInstance = parse(dateA, GROUP_DATE_FORMAT, new Date());
    const dateBInstance = parse(dateB, GROUP_DATE_FORMAT, new Date());

    if (dateAInstance === dateBInstance) {
      return 0;
    }

    if (dateAInstance < dateBInstance) {
      return -1;
    }

    return 1;
  });

export default MessageList;
