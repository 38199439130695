import { FilterButton, CreateButton } from 'react-admin';
import { FILTERS, LIST_COLUMNS } from '../../constants';
import { ResourceList } from '@Widgets/ResourceList/ResourceList';
import { Flex } from '@UI';

export const ListPage = () => {
  return (
    <ResourceList<CoreWarehouse>
      columns={LIST_COLUMNS}
      defaultSort={{
        // FIXME: 'createdAt' doesn't exist in the CoreWarehouse
        // @ts-ignore
        field: 'createdAt',
        order: 'DESC',
      }}
      showDeleteButton={false}
      actions={
        <Flex alignItems="center">
          <FilterButton />

          <CreateButton />
        </Flex>
      }
      getItemCardCaption={() => ''}
      bulkActionButtons={false}
      filters={FILTERS}
    />
  );
};
