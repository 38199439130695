import {
  PropsWithChildren,
  Children,
  isValidElement,
  cloneElement,
  CSSProperties,
} from 'react';

export interface TabContentProps {
  currentTab: string;
}

export const TabContent = (props: PropsWithChildren<TabContentProps>) => {
  const { currentTab, children } = props;

  return (
    <>
      {Children.map(children, (child) => {
        if (!isValidElement<{ style: CSSProperties }>(child)) {
          return null;
        }

        if (child.key !== currentTab) {
          return cloneElement<{ style: CSSProperties }>(child, {
            style: { display: 'none' },
          });
        }

        return child;
      })}
    </>
  );
};
