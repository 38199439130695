import { useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslate } from 'react-admin';
import { Search } from '@mui/icons-material';
import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';

import { Flex, RowInfoBlock } from '@UI';

import { DEBOUNCE_TIMEOUT, SORT_ITEMS } from './constants';

const PickingPriorityFilters = () => {
  const translate = useTranslate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, _setFilters] = useState<Record<string, string>>(() => ({
    sorting: searchParams.get('sorting') ?? 'ASC',
    search: searchParams.get('search') ?? '',
  }));

  const setNewFiltersTimeoutRef =
    useRef<Nullable<ReturnType<typeof setTimeout>>>(null);

  const timeoutCleanUp = () => {
    if (setNewFiltersTimeoutRef.current) {
      clearTimeout(setNewFiltersTimeoutRef.current);
      setNewFiltersTimeoutRef.current = null;
    }
  };

  const setFilter = (key: string, value: string): void => {
    _setFilters((prevState) => {
      const result = {
        ...prevState,
        [key]: value,
      };

      timeoutCleanUp();

      setNewFiltersTimeoutRef.current = setTimeout(() => {
        setNewFiltersToSearchParams(result);
      }, DEBOUNCE_TIMEOUT);

      return result;
    });
  };

  const setNewFiltersToSearchParams = (filters: Record<string, string>) => {
    const { sorting, search } = filters;

    let isChanged = false;

    if (sorting !== searchParams.get('sorting')) {
      searchParams.set('sorting', sorting);
      isChanged = true;
    }

    if (search !== searchParams.get('search')) {
      searchParams.set('search', search);
      isChanged = true;
    }

    if (isChanged) {
      setSearchParams(searchParams);
    }
  };

  return (
    <RowInfoBlock sx={{ my: 3 }}>
      <Flex
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        asColumn={false}
        fullWidth
        gap={2}
      >
        <FormControl variant="outlined">
          <InputLabel id="sorting-label">
            {translate('wms-picking.pages.picking-priority.labels.sorting')}
          </InputLabel>
          <Select
            labelId="sorting-label"
            label="Sorting"
            value={filters.sorting}
            onChange={(e) => setFilter('sorting', e.target.value)}
          >
            {SORT_ITEMS.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {translate(item.label)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="Search"
          variant="outlined"
          value={filters.search}
          onChange={(e) => setFilter('search', e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </Flex>
    </RowInfoBlock>
  );
};

export default PickingPriorityFilters;
