import { styled, Theme } from '@mui/system';
import { Flex } from '@UI';
import { StatusBadgeResourceProps } from './interface';

const pickersStatusColors = (theme: Theme): Record<CorePickerStatus, string> => ({
  offline: theme.palette.grey['800'],
  picking: theme.palette.success.main,
  waiting: theme.palette.grey['400'],
  idle: theme.palette.info.main,
});

export const StatusBadgeStyled = styled(Flex)<StatusBadgeResourceProps>(({ theme, status }) => ({
  color: status !== 'waiting' ? theme.palette.primary.contrastText : theme.palette.grey['800'],
  padding: 4,
  backgroundColor: pickersStatusColors(theme)[status],
  borderRadius: 8,
}));
