/**
 * Show React errors which start with 'Warning:' as warnings
 */
export const treatReactWarningsAsWarnings = () => {
  const oldConsError = console.error;
  const errTemplatesToWarn: string[] = ['Warning:'];

  console.error = (...args: any) => {
    const firstArg = args[0];
    const toWarn =
      typeof firstArg === 'string'
        ? errTemplatesToWarn.some((errText) => firstArg.startsWith(errText))
        : false;

    toWarn ? console.warn(...args) : oldConsError(...args);
  };
};
