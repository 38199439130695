import { TabContentProps } from './interface';
import { PropsWithChildren } from 'react';
import { Box } from '@mui/material';

export const TabContent = (props: PropsWithChildren<TabContentProps>) => {
  const {
    children,
    curTabIndex,
    tabIndex,
    destroyContentOnInactive = false,
    ...other
  } = props;

  return (
    <Box
      hidden={curTabIndex !== tabIndex}
      {...other}
    >
      {destroyContentOnInactive ? curTabIndex === tabIndex && children : children}
    </Box>
  );
};
