import { useEffect, useState, useMemo } from 'react';
import {
  useRecordContext,
  useTranslate,
  DateField,
  SimpleForm,
  SaveButton,
  Toolbar,
  required,
  useUpdate,
  useNotify,
} from 'react-admin';
import { Typography, Box, CircularProgress } from '@mui/material';
import { FieldValues } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Flex, Loader, RowInfoBlock } from '@UI';
import { ResourceRoutes } from '@PluginManager/plugins/resourceRoutes';
import { useSetErrorToErrorsContext } from '@Helpers';

import { RESOURCES_SCHEMA } from '../schemas/resourcesSchema';
import { ClickCollectOrder } from '../interfaces';
import { clickAndCollectTranslationSchema } from '../constants';
import { PackageDeliveryType, PackageHandOverStatus } from './interface';
import { DEFAULT_DELIVERY_TYPES } from './constants';
import { RadioButtonGroupInputStyled } from './styles';

export const DetailsPage = () => {
  const record = useRecordContext<ClickCollectOrder>();
  const translate = useTranslate();
  const notify = useNotify();
  const navigate = useNavigate();
  const { errors, onErrorHandler } = useSetErrorToErrorsContext();

  const [updateDelivery, { isSuccess, isLoading }] = useUpdate();
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const labels = clickAndCollectTranslationSchema.labels;

  const bagTypes = useMemo<PackageDeliveryType[]>(() => {
    if (!record?.bag_types || !record.bag_types.length)
      return DEFAULT_DELIVERY_TYPES;

    if (
      (record.bag_types as unknown as PackageDeliveryType[]).includes(
        PackageDeliveryType.Frozen
      ) &&
      !(record.bag_types as unknown as PackageDeliveryType[]).includes(
        PackageDeliveryType.Grocery
      )
    ) {
      return [
        PackageDeliveryType.Grocery,
        ...record.bag_types,
      ] as PackageDeliveryType[];
    }

    return record.bag_types as unknown as PackageDeliveryType[];
  }, [record?.bag_types]);

  const getDeliveryTypeName = (type: PackageDeliveryType) =>
    translate(
      clickAndCollectTranslationSchema.lists.deliveryTypeName[
        PackageDeliveryType[type] as keyof typeof PackageDeliveryType
      ] || labels.unknown
    );
  const handlerSubmit = ({ status = {} }: FieldValues) => {
    const packages = Object.entries(status).reduce<PackageDeliveryType[]>(
      (packages, [name, status]) => {
        const packageName = parseInt(name, 10);

        if (
          parseInt(status as string, 10) ===
          PackageHandOverStatus.ConfirmHandOver
        ) {
          packages.push(packageName);

          if (
            packageName === PackageDeliveryType.Grocery &&
            bagTypes.includes(PackageDeliveryType.Frozen)
          ) {
            packages.push(PackageDeliveryType.Frozen);
          }
        }

        return packages;
      },
      []
    );

    updateDelivery(
      ResourceRoutes.wmsClickAndCollect.resourcePath,
      {
        id: record.uuid || record.id,
        data: packages,
        previousData: { id: record.uuid || record.id },
      },
      {
        onSuccess: () => {
          if (!packages.length) {
            notify(
              translate(
                clickAndCollectTranslationSchema.messages.successfullyCanceled
              ),
              { type: 'success', autoHideDuration: 10000 }
            );

            return;
          }

          notify(
            translate(
              clickAndCollectTranslationSchema.messages.updateDelivery,
              {
                orderId: record.order_id,
              }
            ),
            { type: 'success', autoHideDuration: 10000 }
          );
        },
        onError: onErrorHandler,
      }
    );
  };

  useEffect(() => {
    if (!record) return;

    const isCanceled = ['cancelled', 'failed'].includes(record.public_status);
    const isDelivered = record.public_status === 'delivered';
    const errors: string[] = [];

    if (isCanceled) {
      errors.push(translate(clickAndCollectTranslationSchema.errors.canceled));
    }

    if (isDelivered) {
      errors.push(
        translate(clickAndCollectTranslationSchema.errors.alreadyPicked)
      );
    }

    if (
      !isCanceled &&
      !isDelivered &&
      record.public_status !== 'ready_for_pickup'
    ) {
      errors.push(
        translate(clickAndCollectTranslationSchema.errors.incorrectStatus)
      );
    }

    if (record.delivery_method !== 'CLICK_AND_COLLECT') {
      errors.push(
        translate(
          clickAndCollectTranslationSchema.errors.incorrectDeliveryMethod
        )
      );
    }

    setErrorMessages(errors);
  }, [record?.status, record?.delivery_method]);

  useEffect(() => {
    if (!isSuccess) return;
    navigate(ResourceRoutes.wmsClickAndCollect.resourcePath);
  }, [isSuccess]);

  useEffect(() => {
    const resultErrors = Object.values(errors).flat();

    if (resultErrors.length) {
      const errorsText = [
        translate(clickAndCollectTranslationSchema.errors.cantUpdateDelivery),
        ...resultErrors,
      ].join('\n');

      notify(errorsText, {
        type: 'error',
        autoHideDuration: 10000,
        multiLine: true,
      });
    }
  }, [JSON.stringify(errors)]);

  if (!record) return <Loader />;

  return (
    <Flex asColumn maxWidth={500} width="95vw">
      <Box padding={1}>
        <Typography variant="h5">
          {translate(labels.order, { orderId: record.order_id })}
        </Typography>

        <Typography variant="subtitle2">
          {`${translate(labels.createdAt)}: `}

          <DateField source={RESOURCES_SCHEMA.createdAt} showTime />
        </Typography>
      </Box>

      {errorMessages.length > 0 ? (
        <Typography
          bgcolor="rgba(244, 67, 54, 0.08)"
          color="error"
          fontWeight="bold"
          align="center"
          padding={2}
          fontSize={14}
          marginTop={2}
        >
          {errorMessages.map((error, index) => (
            <Box key={index} marginY={1}>
              {error}
            </Box>
          ))}
        </Typography>
      ) : (
        <>
          <RowInfoBlock titleKey={labels.buyerName} margin={1}>
            <Typography fontSize={14}>
              {record.recipient?.full_name || translate(labels.unknown)}
            </Typography>
          </RowInfoBlock>

          <SimpleForm
            onSubmit={handlerSubmit}
            toolbar={
              <Toolbar>
                <SaveButton
                  label={labels.finish}
                  disabled={isLoading}
                  icon={isLoading ? <CircularProgress size={16} /> : undefined}
                />
              </Toolbar>
            }
            sx={{ m: 0, py: 0, px: 1 }}
            record={{ status: {} }}
          >
            {bagTypes
              .filter((type) => type !== PackageDeliveryType.Frozen)
              .map((type) => (
                <RowInfoBlock
                  title={getDeliveryTypeName(
                    type as unknown as PackageDeliveryType
                  )}
                  key={type}
                  fullWidth
                  sx={{ boxSizing: 'border-box' }}
                >
                  <RadioButtonGroupInputStyled
                    label={false}
                    name={`status[${type}]`}
                    source={`status[${type}]`}
                    choices={[
                      {
                        id: PackageHandOverStatus.ConfirmHandOver,
                        name: labels.confirm,
                      },
                      {
                        id: PackageHandOverStatus.RejectedOrMissing,
                        name: labels.rejected,
                      },
                    ]}
                    validate={required()}
                  />
                </RowInfoBlock>
              ))}
          </SimpleForm>
        </>
      )}
    </Flex>
  );
};
