import styled from 'styled-components';
import { TextField } from '@mui/material';

export const ColorFieldStyled = styled(TextField)<{ value: string }>`
  width: 30px;
  margin: auto;
  margin-top: 0 !important;
  margin-bottom: 0 !important;

  input {
    padding: 0;
    opacity: ${({ value }) => value ? 1 : 0} !important;
  }
`;
