import { ResourceList } from '@Widgets/ResourceList/ResourceList';

import { LIST_COLUMNS, FILTERS } from '../../constants';

const ListPage = () => {
  return (
    <ResourceList
      actions={false}
      showDeleteButton={false}
      columns={LIST_COLUMNS}
      filters={FILTERS}
      getItemCardCaption={() => ''}
      defaultSort={{
        field: 'createdAt',
        order: 'ASC',
      }}
      bulkActionButtons={false}
      disableSyncWithLocation={false}
      rowClick={false}
    />
  );
};

export default ListPage;
