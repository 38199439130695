import { useState, useEffect } from 'react';
import { REQUEST_NEW_CODE_TIMEOUT } from './constants';
import { useTranslate } from 'react-admin';
import { Typography } from '@mui/material';
import { SmsRequestTimerProps } from './interface';

export const Timer = ({ onTimerEnd }: SmsRequestTimerProps) => {
  const t = useTranslate();
  const [seconds, setSeconds] = useState(REQUEST_NEW_CODE_TIMEOUT);

  useEffect(() => {
    if (seconds <= 0) return;
    const timeout = setTimeout(() => {
      const secondsLeft = seconds - 1;

      if (secondsLeft <= 0) {
        onTimerEnd();

        return;
      }

      setSeconds(secondsLeft);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [seconds, onTimerEnd]);

  return (
    <Typography textAlign="center" fontSize="13px" color="#7b868a">
      {t('pages.login.resendCodeCountdown', { time: seconds.toString() })}
    </Typography>
  );
};
