import { SyntheticEvent, useState } from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import { Tab, Tabs } from '@mui/material';

import { WEEK_OPTIONS_ABBREVIATED } from '@PluginManager/constants';
import { WeekDayItem } from '@PluginManager/interface';

import { ScheduleIntervalAccordion } from './components/ScheduleIntervalAccordion';
import { DeliveryScheduleProps } from './interfaces';

const DeliverySchedule = (props: DeliveryScheduleProps) => {
  const { zoneId = '', timezone, localization } = props;
  const translate = useTranslate();
  const [currentTabIdx, setCurrentTabIdx] = useState(0);
  const { deliverySchedule = [] } = useRecordContext<{
    deliverySchedule: CoreDeliveryScheduleSlotEntity[];
  }>();

  const onChangeTabHandler = (event: SyntheticEvent, newValue: number) =>
    setCurrentTabIdx(newValue);

  const scheduleForWeekDay = (
    weekDay: number
  ): CoreDeliveryScheduleSlotEntity[] =>
    deliverySchedule.filter((item) => item.day_of_week === weekDay);

  const slots = WEEK_OPTIONS_ABBREVIATED.map(
    (weekDay: WeekDayItem, idx: number) => {
      if (currentTabIdx !== idx) {
        return;
      }

      return (
        <ScheduleIntervalAccordion
          key={weekDay.id}
          scheduleSlots={scheduleForWeekDay(weekDay.id)}
          weekDay={weekDay.id}
          zoneId={zoneId}
          timezone={timezone}
          localization={localization}
        />
      );
    }
  );

  return (
    <>
      <Tabs
        value={currentTabIdx}
        onChange={onChangeTabHandler}
        variant="scrollable"
      >
        {WEEK_OPTIONS_ABBREVIATED.map(({ id, name }, idx) => (
          <Tab
            key={id}
            label={translate(name)}
            value={idx}
            sx={{ minWidth: '1px' }}
          />
        ))}
      </Tabs>

      {slots}
    </>
  );
};

export default DeliverySchedule;
