import { PluginResourceController, CommonCrud } from '@PluginBase';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { ListPage } from './pages/ListPage';
import {
  GetListResult,
  GetOneResult,
  CreateResult,
  UpdateResult,
} from 'react-admin';
import { ResourceRoutes } from '../../../resourceRoutes';

const shippingMethodsCRUD = new CommonCrud('/delivery/v1/shipping-methods');

const DeliveryMethodsPages = new PluginResourceController({
  menuItem: {
    route: ResourceRoutes.shippingMethod.routePath,
    caption: {
      translationKey: 'settings.pages.shippingMethods.caption',
    },
    icon: <LocalShippingIcon />,
  },
  resourceRoute: {
    name: ResourceRoutes.shippingMethod.resourcePath,
    list: ListPage,
    create: ListPage,
  },
  dataProvider: {
    getList: (resource, params): Promise<GetListResult> =>
      shippingMethodsCRUD.list(params),
    getOne: async (resource, params): Promise<GetOneResult> => {
      const result = await shippingMethodsCRUD.getOne(params);

      return {
        data: result,
      };
    },
    create: async (resource, params): Promise<CreateResult> => {
      const result = await shippingMethodsCRUD.create<{
        data: CoreShippingMethod;
      }>(params);

      return {
        data: result?.data,
      };
    },
    update: async (resource, params): Promise<UpdateResult> => {
      await shippingMethodsCRUD.update(params);

      return {
        data: params.data,
      };
    },
  },
});

export default DeliveryMethodsPages;
