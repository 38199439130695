import { Box } from '@mui/material';
import { useRef, useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import { GetListResult, useNotify, useRecordContext } from 'react-admin';
import uniqBy from 'lodash/uniqBy';
import format from 'date-fns/format';
import formatISO from 'date-fns/formatISO';

import { accessPermission } from '@Helpers';

import { commentsCRUD } from './api';
import { GroupedComments } from './interfaces';
import MessageInput from './MessageInput';
import MessageList from './MessageList';
import { GROUP_DATE_FORMAT, TOTAL_COMMENTS_PER_PAGE } from './constants';

const Comments = () => {
  const [groupedComments, setGroupedComments] = useState<GroupedComments>(
    () => ({})
  );
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const lastScrollHeightRef = useRef<number>(0);
  const [filterByOrder, setFilterByOrder] = useState<boolean>(true);

  const [nominatedToEdit, setNominatedToEdit] =
    useState<Nullable<CoreComment>>(null);

  const notify = useNotify();
  const record = useRecordContext<CoreOrder>();

  const isActionsEnabled = accessPermission.checkRolesExists(
    'admin',
    'customer_support_specialist'
  );

  const { isLoading: isListFetching } = useQuery({
    queryKey: ['commentsList', page, filterByOrder],
    queryFn: async () => {
      const filter: Record<string, string> = {
        customerIds: record.customer_id,
      };

      if (filterByOrder) {
        filter.orderId = record.id;
      }

      const response: GetListResult<CoreComment> = await commentsCRUD.list({
        pagination: {
          page,
          perPage: TOTAL_COMMENTS_PER_PAGE,
        },
        sort: {
          field: 'createdAt',
          order: 'DESC',
        },
        filter,
      });

      const { data = [], total, pageInfo } = response;

      return {
        data: data.reduce<GroupedComments>((acc, message) => {
          if (!message.metadata.orderId) {
            return acc;
          }

          const [, orderId] = message.metadata.orderId;

          if (!acc[orderId]) {
            acc[orderId] = {};
          }

          const dateKey = format(
            new Date(message.createdAt),
            GROUP_DATE_FORMAT
          );

          if (!acc[orderId][dateKey]) {
            acc[orderId][dateKey] = [];
          }

          acc[orderId][dateKey].push(message);

          return acc;
        }, {}),
        total,
        pageInfo,
      };
    },
    onSuccess: (
      response: { data: GroupedComments } & Omit<GetListResult, 'data'>
    ) => {
      const { data, total = 0 } = response;

      setHasMore(page * TOTAL_COMMENTS_PER_PAGE < total);

      setGroupedComments((prevGroupedComments) => {
        if (
          !Object.values(data)
            .map((item) => Object.values(item).flat())
            .flat().length
        ) {
          return {};
        }

        for (const [orderId, group] of Object.entries(data)) {
          if (!(orderId in prevGroupedComments)) {
            prevGroupedComments[orderId] = {};
          }

          for (const [key, values] of Object.entries(group)) {
            const existingComments = prevGroupedComments[orderId][key];

            if (Array.isArray(existingComments) && page > 1) {
              prevGroupedComments[orderId][key] = uniqBy(
                [...values, ...existingComments],
                'id'
              );
              continue;
            }

            prevGroupedComments[orderId][key] = values;
          }
        }

        return { ...prevGroupedComments };
      });
    },
    cacheTime: 0,
    retry: false,
    refetchOnMount: false,
    retryOnMount: false,
    refetchOnWindowFocus: false,
  });

  const { mutate: deleteComment, isLoading: isDeleting } = useMutation({
    mutationKey: 'deleteComment',
    mutationFn: async (id: string) => {
      const result = await commentsCRUD.delete({ id });

      return { id, result };
    },
    onSuccess: ({ id }) => {
      setGroupedComments((prevState) => {
        const targetMessage = Object.values(prevState)
          .map((group) => Object.values(group).flat())
          .flat()
          .find((item) => item.id === id);

        if (!targetMessage) {
          return prevState;
        }

        const [, orderId] = targetMessage.metadata.orderId || [];

        if (!orderId || !(orderId in prevState)) {
          return prevState;
        }

        const date = format(
          new Date(targetMessage.createdAt),
          GROUP_DATE_FORMAT
        );
        const group = prevState[orderId];

        if (!(date in group)) {
          return prevState;
        }

        return {
          ...prevState,
          [orderId]: {
            ...group,
            [date]: group[date].filter((item) => item.id !== id),
          },
        };
      });
      notify('orders.pages.alerts.deleteSuccess', { type: 'success' });
    },
    onError: (error) => {
      notify('orders.pages.errors.deleteFailed', { type: 'error' });
      // eslint-disable-next-line
      console.log(error);
    },
    retry: false,
  });

  const handleNewMessageAdded = (messageData: CoreComment) => {
    const today = format(new Date(), GROUP_DATE_FORMAT);

    setGroupedComments((prevState) => {
      const currentOrderMessages = prevState[record.id];
      const prevMessages: CoreComment[] | undefined =
        currentOrderMessages?.[today];

      return {
        ...prevState,
        [record.id]: {
          ...currentOrderMessages,
          [today]: Array.isArray(prevMessages)
            ? [...prevMessages, messageData]
            : [messageData],
        },
      };
    });
  };

  const handleMessageUpdated = (id: string, content: string) => {
    setGroupedComments((prevState) => {
      const targetComment = Object.values(prevState)
        .map((item) => Object.values(item).flat())
        .flat()
        .find((item) => item.id === id);

      if (!targetComment) {
        return prevState;
      }

      const commentDate = format(
        new Date(targetComment.createdAt),
        GROUP_DATE_FORMAT
      );

      for (const [orderId, group] of Object.entries(prevState)) {
        const messagesByTheDay = group[commentDate];

        if (
          !Array.isArray(messagesByTheDay) ||
          !messagesByTheDay.some((item) => item.id === id)
        ) {
          continue;
        }

        return {
          ...prevState,
          [orderId]: {
            ...group,
            [commentDate]: messagesByTheDay.map((item) => {
              if (item.id !== targetComment.id) {
                return item;
              }

              return {
                ...item,
                content: content,
                updatedAt: formatISO(new Date()),
              };
            }),
          },
        };
      }

      return prevState;
    });
  };

  const handleDeleteComment = (comment: CoreComment) => {
    deleteComment(comment.id);
  };

  return (
    <Box display="flex" flexDirection="column">
      <MessageList
        hasMore={hasMore}
        setPage={setPage}
        groupedComments={groupedComments}
        lastScrollHeightRef={lastScrollHeightRef}
        onDelete={handleDeleteComment}
        onEdit={setNominatedToEdit}
        isLoading={isDeleting || isListFetching}
        nominatedToEdit={nominatedToEdit}
        onSetFilterByOrder={(val) => {
          setPage(1);
          setFilterByOrder(val);
        }}
        filterByOrder={filterByOrder}
        isActionsEnabled={isActionsEnabled}
      />
      {isActionsEnabled && (
        <MessageInput
          nominatedToEdit={nominatedToEdit}
          onEditClose={() => setNominatedToEdit(null)}
          onNewMessageAdded={handleNewMessageAdded}
          onMessageUpdated={handleMessageUpdated}
        />
      )}
    </Box>
  );
};

export default Comments;
