import { ListColumn } from '@Widgets/ResourceList/interface';
import { Lot } from './interface';

export const LIST_COLUMNS: ListColumn<Lot & any>[] = [
  {
    source: 'store.name',
    label: 'replenishment.pages.lots.labels.store',
    type: 'text',
  },
  {
    source: 'product.sku',
    label: 'replenishment.pages.lots.labels.product',
    type: 'text',
  },
  {
    source: 'started_date',
    label: 'replenishment.pages.lots.labels.started_date',
    type: 'date',
  },
  {
    source: 'expiration_date',
    label: 'replenishment.pages.lots.labels.expiration_date',
    type: 'date',
  },
  {
    source: 'bop_unit',
    label: 'replenishment.pages.lots.labels.bop_unit',
    type: 'text',
  },
];
