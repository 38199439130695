import { ListColumn } from '@Widgets/ResourceList/interface';
import { CoreCommissionLimitType } from '@Plugins/Promotions/resources/Tapfilliate/components/AffiliateForm';
import { MoneyFormatter, pointsToUnits } from '@Helpers';

export const AFFILIATE_DEFAULT_VALUES: Omit<
  CoreTapfilliateTemplate,
  'id' | 'dateRange'
> = {
  applyToDiscountedItems: false,
  code: 'Affiliate Promocode Template',
  companyId: '1eb53a13-5f9e-4deb-92d7-090a4b53fd21',
  createdAt: '2023-09-27T14:10:36.246Z',
  customerId: null,
  deletedAt: null,
  discountTargets: null,
  filters: {},
  isActive: true,
  minimumPurchase: 0,
  minimumQuantity: 0,
  orderNumberRange: [0, 100],
  parentId: null,
  partnerAffiliateId: null,
  productDiscountQuantity: 0,
  refereeId: null,
  sourceType: 'affiliate_promocode_template',
  type: 'PERCENTAGE',
  updatedAt: '2023-09-27T14:10:36.246Z',
  usageCurrent: 0,
  usageLimitAll: 0,
  usageLimitCustomer: 100,
  validationRules: null,
  value: 10,
};

export const AFFILIATES_COLUMNS: ListColumn<CoreAffiliate>[] = [
  {
    source: 'promocode',
    type: 'text',
    label: 'Promocode',
  },
  {
    source: 'firstname',
    type: 'function',
    function: ({ firstname, lastname }) =>
      [firstname, lastname].filter(Boolean).join(' '),
    label: 'Name',
  },
  {
    source: 'email',
    type: 'text',
    label: 'Email',
  },
  {
    source: 'commisionLimitType',
    type: 'text',
    label: 'Limit Type',
  },
  {
    source: 'commisionLimit',
    type: 'function',
    function: ({ commisionLimit, commisionLimitType }) => {
      if (commisionLimitType === CoreCommissionLimitType.ORDER_COUNT) {
        return commisionLimit;
      }

      return MoneyFormatter.addCurrencySymbol(pointsToUnits(commisionLimit));
    },
    label: 'Limit',
  },
  {
    source: 'createdAt',
    type: 'date',
    label: 'Created at',
  },
];
