import { Plugin } from '@PluginBase';
import Translations from './i18n.json';
import { Icon } from '@UI';
import CategoriesController from './resources/Categories/controller';
import ProductController from './resources/Products/controller';
import OffersController from './resources/Offers/controller';
import OfferOperationsController from '@Plugins/Catalogue/resources/OfferOperations/controller';
import OfferExportController from '@Plugins/Catalogue/resources/OfferOperations/offerExportController';
import BundlesController from './resources/Bundles/controller';

const Catalogue = new Plugin({
  name: 'Catalogue',
  i18n: Translations,
  resources: [
    CategoriesController,
    ProductController,
    OffersController,
    OfferOperationsController,
    OfferExportController,
    BundlesController,
  ],
  rootMenu: {
    caption: {
      translationKey: 'catalogue.caption',
    },
    icon: <Icon type="catalog" />,
  },
});

export default Catalogue;
