import { Confirm, useLocaleState, useTranslate } from 'react-admin';
import { Fragment, useState } from 'react';
import Button from '@mui/material/Button';
import Add from '@mui/icons-material/Add';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';

import { MoneyFormatter } from '@Helpers';

import { Offer } from '@Widgets/Bundles/Offer';
import { Header } from '@Widgets/Bundles/Header/Header';
import { SelectOffersPopupContent } from '@Widgets/Bundles/SelectOffersPopupContent';

import { aggregateTotals } from './utils';
import { PlaceLoadingType, PlacesProps } from './interfaces';

const Bundles = (props: PlacesProps): JSX.Element => {
  const {
    value,
    onAddPlace,
    onRemovePlace,
    onAddOfferToPlace,
    onRemoveOffer,
    localization,
  } = props;
  const {
    addPlace: addPlaceText = '',
    addOffer: addOfferText = '',
    confirmDialogTitle: confirmDialogTitleText = '',
    addingPlaceMessage = '',
    deletingPlaceMessage = '',
  } = localization || {};

  const translate = useTranslate();
  const [locale] = useLocaleState();

  const [placeToAddOffers, setPlaceToAddOffers] = useState<
    number | undefined
  >();
  const [selectedOffers, setSelectedOffers] = useState<CoreOffer[]>([]);
  const [placeProcessType, setPlaceProcessType] = useState<
    PlaceLoadingType | undefined
  >();

  const getSnackbarMessage = (): string | undefined => {
    switch (placeProcessType) {
      case PlaceLoadingType.CREATION:
        return addingPlaceMessage;
      case PlaceLoadingType.DELETION:
        return deletingPlaceMessage;
      default:
    }
  };

  const getIsRowSelectable = (offerId: number): boolean => {
    if (placeToAddOffers === undefined) return true;

    const place = value.find((item) => item.id === placeToAddOffers);

    if (!place) return true;

    const { bundlePlaceOffers } = place;

    return !bundlePlaceOffers.map((item) => item.offerId).includes(offerId);
  };

  const onSelect = (selectedIds: Record<number, CoreOffer>) => {
    setSelectedOffers(Object.values(selectedIds));
  };

  const onConfirm = async () => {
    handleCloseOffersPopup();

    if (!onAddOfferToPlace || placeToAddOffers === undefined) return;

    await onAddOfferToPlace(
      selectedOffers.map((item) => item.id),
      placeToAddOffers
    );
    setSelectedOffers([]);
  };

  const handleAddPlace = async () => {
    if (!onAddPlace) {
      return;
    }

    setPlaceProcessType(PlaceLoadingType.CREATION);

    await onAddPlace();

    setPlaceProcessType(undefined);
  };

  const handleCloseOffersPopup = (): void => {
    setSelectedOffers([]);
    setPlaceToAddOffers(undefined);
  };

  const removePlaceHandlerFactory = (id: number) => async () => {
    if (!onRemovePlace) {
      return;
    }

    setPlaceProcessType(PlaceLoadingType.DELETION);

    await onRemovePlace(id);

    setPlaceProcessType(undefined);
  };

  const openOfferSelectionHandlerFactory = (id: number) => (): void => {
    setPlaceToAddOffers(id);
  };

  const removeOfferHandlerFactory = (id: number) => async (offerId: number) => {
    if (!onRemoveOffer) {
      return;
    }

    await onRemoveOffer(offerId, id);
  };

  const addPlaceButton = (
    <>
      {value.length < 1 && (
        <Button
          variant="contained"
          color="primary"
          startIcon={<Add />}
          onClick={handleAddPlace}
          disabled={!!placeProcessType}
        >
          {addPlaceText}
        </Button>
      )}
    </>
  );

  const { original, bundle } = aggregateTotals(value);

  return (
    <>
      {addPlaceButton}

      {value.map((place, idx) => (
        <Fragment key={place.id}>
          <Card sx={{ width: '100%', marginTop: idx ? '24px' : undefined }}>
            <Header
              id={place.id}
              index={idx}
              onRemove={removePlaceHandlerFactory(place.id)}
            />
            <CardContent>
              {place.bundlePlaceOffers.map(({ id, offer, offerId }, index) => {
                const localizedName =
                  locale === 'ar' && offer?.nameAr ? offer.nameAr : offer?.name;

                return (
                  <Offer
                    key={id}
                    placeIndex={idx}
                    index={index}
                    id={id}
                    offerId={offerId}
                    name={localizedName}
                    onRemove={removeOfferHandlerFactory(place.id)}
                    localization={localization}
                  />
                );
              })}

              <Box sx={{ marginTop: 2 }}>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<Add />}
                  onClick={openOfferSelectionHandlerFactory(place.id)}
                >
                  {addOfferText}
                </Button>
              </Box>
            </CardContent>
          </Card>

          {idx === value.length - 1 && value.length > 3 && (
            <Box sx={{ my: 2 }}>{addPlaceButton}</Box>
          )}

          {idx === value.length - 1 && (
            <Box sx={{ px: 2, pt: 2, width: '100%' }}>
              <Grid container spacing={2}>
                <Grid item xs width="100%">
                  {translate('catalogue.pages.bundles.labels.total')}
                </Grid>
                <Grid item xs={4}>
                  <Box sx={{ pl: 2 }}>
                    {original !== undefined &&
                      MoneyFormatter.addCurrencySymbol(original)}
                  </Box>
                </Grid>
                <Grid item xs={5}>
                  <Box sx={{ pl: 3 }}>
                    {bundle !== undefined &&
                      MoneyFormatter.addCurrencySymbol(bundle)}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          )}
        </Fragment>
      ))}

      <Confirm
        isOpen={Boolean(placeToAddOffers)}
        title={confirmDialogTitleText}
        fullWidth
        maxWidth="xl"
        keepMounted={false}
        content={
          <SelectOffersPopupContent
            selectedOffers={selectedOffers}
            onSelect={onSelect}
            isRowSelectable={getIsRowSelectable}
          />
        }
        onConfirm={onConfirm}
        onClose={handleCloseOffersPopup}
      />

      <Snackbar open={!!placeProcessType} message={getSnackbarMessage()} />
    </>
  );
};

export default Bundles;
