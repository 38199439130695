export const isClickAndCollect = (order: CoreOrder | CorePickingOrderDto) => {
  const deliveryMethod =
    'deliveryMethod' in order ? order.deliveryMethod : order.delivery_method;

  return (
    deliveryMethod &&
    (deliveryMethod === 'click_and_collect' ||
      deliveryMethod === 'CLICK_AND_COLLECT')
  );
};
