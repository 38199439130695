export const sanitizePayload = <T extends object>(
  instance: Partial<T>
): Partial<T> =>
  Object.entries(instance).reduce<Partial<T>>((memo, [key, value]) => {
    if (!['number', 'boolean'].includes(typeof value) && !value) {
      return {
        ...memo,
        [key]: null,
      };
    }

    return {
      ...memo,
      [key]: value,
    };
  }, {});
