import restApi from './rest/rest';
import { RequestAPI } from '@RestApi';
import { AppDataProviderInterface } from './interface';

const dataProvider: AppDataProviderInterface = {
  create: (resource, params) => RequestAPI.post(resource, params.data),
  update: (resource, params) => RequestAPI.post(resource, params.data),
  delete: (resource, params) => RequestAPI.post(resource, { ...params }),
  deleteMany: (resource, params) => RequestAPI.post(resource, { ...params }),
  getList: async (resource, params) => {
    const axiosResponse = await RequestAPI.post(resource, { ...params });

    return axiosResponse.data;
  },
  getMany: async (resource, params) => {
    const axiosResponse = await RequestAPI.post(resource, { ...params });

    return axiosResponse.data;
  },
  getManyReference: async (resource, params) => {
    const axiosResponse = await RequestAPI.post(resource, { ...params });

    return axiosResponse.data;
  },
  getOne: async (resource, params) => {
    const axiosResponse = await RequestAPI.get(resource);

    return {
      data: axiosResponse.data.data.find(({ id }: Record<string, string>) => id === params.id),
    };
  },
  updateMany: (resource, params) => RequestAPI.post(resource, { ...params }),
  ...restApi.GoogleMapGeocode,
  ...restApi.ETARequests,
};

export default dataProvider;
