import { Schema } from "type-fest";

// TODO: remove all @ts-ignore
export const RESOURCES_SCHEMA = {
  id: 'id',
  firstName: 'firstName',
  lastName: 'lastName',
  email: 'email',
  login: 'login',
  // @ts-ignore
  warehouse: 'warehouseCode',
  status: 'status',
  employment: 'employment',
  createdAt: 'createdAt',
  createdAtFrom: 'createdAtFrom',
  createdAtTo: 'createdAtTo',
  updatedAt: 'updatedAt',
  updatedAtFrom: 'updatedAtFrom',
  updatedAtTo: 'updatedAtTo',
  search: 'search',
} satisfies Schema<CorePickerDto, keyof CorePickerDto>;
