import { isValid, formatRelative, format, formatDistanceToNow, formatDuration, intervalToDuration } from 'date-fns';

export const FORMAT_WITH_TIME = 'yyyy/MM/dd HH:mm:ss';

export class DateFormatter {
  private readonly date: string;

  constructor(date: string, isEpoch = false) {
    this.date = isEpoch ? this.fromEpoch(parseInt(date.toString())) : date;
  }

  get result(): string {
    return this.date;
  }

  relativeNow() {
    return formatRelative(new Date(this.date), Date.now());
  }

  fullDateWithTime() {
    return format(new Date(this.date), FORMAT_WITH_TIME);
  }

  relativeNowMin() {
    return formatDistanceToNow(new Date(this.date));
  }

  duration(to?: string): string {
    return formatDuration(
      intervalToDuration({
        start: new Date(this.date),
        end: to ? new Date(to) : Date.now(),
      }),
    );
  }

  private fromEpoch(epochTime: number) {
    const parsedDate = new Date(epochTime * 1000);

    if (isValid(parsedDate)) {
      return parsedDate.toISOString();
    } else {
      throw new Error(`${this.date} is invalid date`);
    }
  }
}
