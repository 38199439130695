const deliveryTypeName: Record<keyof typeof PackageDeliveryType, string> = {
  Grocery: 'Grocery',
  Alcohol: 'Alcohol',
  Tobacco: 'Tobacco',
  Pharma: 'Pharma',
  Chemical: 'Chemical',
  Frozen: 'Frozen',
  OwnKitchen: 'OwnKitchen',
};

export const TRANSLATION_SCHEMA = {
  captions: {
    desktop: 'desktop',
    mobile: 'mobileCaption',
  },
  labels: {
    input: 'input',
    order: 'order',
    createdAt: 'createdAt',
    buyerName: 'buyerName',
    unknown: 'unknown',
    finish: 'finish',
    confirm: 'confirm',
    rejected: 'rejected',
  },
  lists: {
    deliveryTypeName,
  },
  messages: {
    updateDelivery: 'updateDelivery',
    successfullyCanceled: 'successfullyCanceled',
    successfullyFinished: 'successfullyFinished',
  },
  errors: {
    incorrectStatus: 'incorrectStatus',
    incorrectDeliveryMethod: 'incorrectDeliveryMethod',
    cantUpdateDelivery: 'cantUpdateDelivery',
    canceled: 'canceled',
    alreadyPicked: 'alreadyPicked',
  },
};
