import styled from 'styled-components';
import { Button } from '@mui/material';

export const StyledLocaleButton = styled(Button)<{ $isRTL: boolean }>`
  .MuiButton-startIcon {
    ${({ $isRTL }) =>
      $isRTL
        ? 'margin-left: 8px; margin-right: -4px;'
        : 'margin-right: 8px; margin-left: -7px;'}
  }

  .MuiButton-endIcon {
    ${({ $isRTL }) =>
      $isRTL
        ? 'margin-left: -4px; margin-right: 8px;'
        : 'margin-right: -4px; margin-left: 8px;'}
  }
`;
