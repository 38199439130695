import { useTranslate } from 'react-admin';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import {
  Card,
  CardContent,
  Button,
  Box,
  CardHeader,
  Skeleton,
} from '@mui/material';
import { ArrowDownward } from '@mui/icons-material';
import { FieldValues } from 'react-hook-form';

import { Flex, RowInfoBlock } from '@UI';
import { RequestAPI } from '@RestApi';
import { ResourceRoutes } from '@Plugins/resourceRoutes';
import { AffiliatesTabs } from '@Plugins/Promotions/resources/Tapfilliate/interfaces';

import { API_URL } from '../../controller';
import {
  AffiliateForm,
  CoreCommissionLimitType,
} from '../../components/AffiliateForm';
import { AffiliatePromocodeList } from '../../components/AffiliatePromocodeList';
import { unitsToPoints } from '@Helpers';

const AffiliateEditPage = () => {
  const translate = useTranslate();
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    data,
    isLoading,
    refetch: affiliateDetailRefresh,
  } = useQuery({
    queryKey: 'affiliateDetail',
    queryFn: async (): Promise<CoreAffiliateDetail> =>
      RequestAPI.get(`${API_URL}/list/${id}`),
    cacheTime: 0,
    retry: false,
    refetchOnMount: false,
    retryOnMount: false,
  });
  const { mutate, isLoading: isAffiliateUpdateLoading } = useMutation({
    mutationKey: 'affiliateUpdate',
    mutationFn: async (values: FieldValues) =>
      RequestAPI.patch(`${API_URL}/list/${id}`, values),
    onSuccess: () => {
      affiliateDetailRefresh();
    },
  });

  const submitValues = (values: FieldValues): FieldValues => {
    const { commisionLimitType, commisionLimit } = values;

    if (commisionLimitType === CoreCommissionLimitType.SPEND_CAP) {
      values.commisionLimit = unitsToPoints(commisionLimit);
    }

    return values;
  };

  const handleSave = (values: FieldValues) => {
    mutate(submitValues(values));
  };

  const handleNavigateToAffiliateList = () => {
    navigate(
      `${ResourceRoutes.promotions.tapfilliate.resourcePath}?tab=${AffiliatesTabs.AFFILIATES}`
    );
  };

  return (
    <>
      <Box sx={{ pt: 1, pb: 2 }}>
        <Button
          variant="text"
          color="secondary"
          onClick={handleNavigateToAffiliateList}
        >
          <ArrowDownward sx={{ transform: 'rotate(90deg)', mr: 1 }} />{' '}
          {translate('promotions.pages.tapfilliate.backToAffiliates')}
        </Button>
      </Box>

      {isLoading && (
        <Card>
          <CardHeader title={<Skeleton variant="text" sx={{ width: 250 }} />} />
          <CardContent>
            <Flex fullWidth asColumn justifyContent="stretch" gap={2}>
              {new Array(3).fill(null).map((_, i) => (
                <RowInfoBlock
                  key={i}
                  asColumn={false}
                  justifyContent="space-between"
                  alignContent="flex-start"
                  fullWidth
                  gap={4}
                  sx={{ p: 2 }}
                >
                  <Flex flex={1}>
                    <Skeleton
                      variant="rectangular"
                      sx={{ width: '100%', height: 40 }}
                    />
                  </Flex>
                  <Flex flex={1}>
                    <Skeleton
                      variant="rectangular"
                      sx={{ width: '100%', height: 40 }}
                    />
                  </Flex>
                </RowInfoBlock>
              ))}
            </Flex>
          </CardContent>
        </Card>
      )}
      {data && !isLoading && (
        <Card>
          <CardHeader
            title={
              <>
                {translate('promotions.pages.tapfilliate.affiliate')}
                {` #${data.data.id} `}
                {[data.data.firstname, data.data.lastname]
                  .filter(Boolean)
                  .join(' ')}
                {` (${data.data.email})`}
              </>
            }
          />
          <CardContent>
            <AffiliateForm
              data={data}
              onSubmit={handleSave}
              isLoading={isLoading || isAffiliateUpdateLoading}
            />
          </CardContent>
        </Card>
      )}

      {data && !isLoading && (
        <Card sx={{ mt: 3 }}>
          <CardContent>
            <AffiliatePromocodeList affiliate={data} />
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default AffiliateEditPage;
