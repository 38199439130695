import { PluginResourceController, CommonCrud } from '@PluginBase';
import { GetListResult } from 'react-admin';
import { ResourceRoutes } from '../../../resourceRoutes';

const commodityGroupCrud = new CommonCrud('/catalog/v1/commodity_groups');

commodityGroupCrud.isNewSorting = true;

const CommodityGroupPages = new PluginResourceController({
  menuItem: null,
  resourceRoute: {
    name: ResourceRoutes.commodityGroup.resourcePath,
  },
  dataProvider: {
    getList: (resource, params): Promise<GetListResult> =>
      commodityGroupCrud.list(params),
  },
});

export default CommodityGroupPages;
