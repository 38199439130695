import {
  UIDeleteButtonWithConfirm,
  Flex,
  ResourceColorInput,
  RowInfoBlock,
  UIErrorTypography,
} from '@UI';
import { StoryPageProps } from './interface';
import { ImageGalleryWidget } from '@Widgets/ImageGalleryWidget/ImageGalleryWidget';
import { RESOURCES_SCHEMA } from '../../../../schemas/resourcesSchema';
import { storiesTranslationNamespace } from '../../../../constants';
import { TextInput, useTranslate } from 'react-admin';
import { Button } from '@mui/material';
import { maxLengthExt } from '@Helpers';

const validateBtnTitle = [maxLengthExt(15)];

export const StoryPage = (props: StoryPageProps) => {
  const { value, onPageDelete, source, onUpdateItem, isError } = props;
  const translate = useTranslate();
  const buttonSource = `${source}.${RESOURCES_SCHEMA.items.buttons.all}.0`;

  function onImageUploaded(imageUrl?: string) {
    onUpdateItem({
      ...value,
      image: imageUrl ?? '',
    });
  }

  function onDeletePage() {
    onPageDelete(value);
  }

  function toggleButton() {
    const newButtons: CoreStoryButton[] = [];

    if (!value.buttons.length) {
      newButtons.push({
        action: '',
        backgroundColor: '',
        borderColor: '',
        borderRadius: 0,
        color: '',
        fontSize: 0,
        id: '',
        paddingX: 0,
        paddingY: 0,
        text: '',
        x: 0,
        y: 0,
      });
    }

    onUpdateItem({
      ...value,
      buttons: newButtons,
    });
  }

  return (
    <Flex gap={1} alignItems="flex-start">
      <Flex asColumn>
        {isError && (
          <Flex py={2}>
            <UIErrorTypography my={2}>
              {translate(storiesTranslationNamespace.errors.imageIsRequired)}
            </UIErrorTypography>
          </Flex>
        )}

        <ImageGalleryWidget
          type="offers"
          mainImageUrl={value.image}
          hideAdditionalImage
          updateMainImage={onImageUploaded}
        />

        {/* <ResourceInputNumber
            label={storiesTranslationNamespace.labels.sorting}
            source={`${source}.${RESOURCES_SCHEMA.items.order}`}
            name={`${source}.${RESOURCES_SCHEMA.items.order}`}
            min={0}
          />*/}

        <UIDeleteButtonWithConfirm
          onConfirm={onDeletePage}
          label={storiesTranslationNamespace.actions.removeStory}
        />
      </Flex>

      {!value.buttons.length ? (
        <RowInfoBlock flex={1} flexGrow={1}>
          <Button onClick={toggleButton}>
            {translate(storiesTranslationNamespace.actions.addStoryButton)}
          </Button>
        </RowInfoBlock>
      ) : (
        <RowInfoBlock flex={1} flexGrow={1}>
          <UIDeleteButtonWithConfirm
            onConfirm={toggleButton}
            label={storiesTranslationNamespace.actions.removeStoryButton}
          />

          <TextInput
            source={`${buttonSource}.${RESOURCES_SCHEMA.items.buttons.text}`}
            name={`${buttonSource}.${RESOURCES_SCHEMA.items.buttons.text}`}
            label={storiesTranslationNamespace.labels.storyTitle}
            fullWidth
            validate={validateBtnTitle}
          />

          <TextInput
            source={`${buttonSource}.${RESOURCES_SCHEMA.items.buttons.action}`}
            name={`${buttonSource}.${RESOURCES_SCHEMA.items.buttons.action}`}
            label={storiesTranslationNamespace.labels.navigationLink}
            fullWidth
          />

          {/* <ResourceInputNumber
              fullWidth
              label={storiesTranslationNamespace.labels.buttonFontSize}
              source={`${buttonSource}.${RESOURCES_SCHEMA.items.buttons.fontSize}`}
              name={`${buttonSource}.${RESOURCES_SCHEMA.items.buttons.fontSize}`}
            />

            <ResourceInputNumber
              fullWidth
              label={storiesTranslationNamespace.labels.borderRadius}
              source={`${buttonSource}.${RESOURCES_SCHEMA.items.buttons.borderRadius}`}
              name={`${buttonSource}.${RESOURCES_SCHEMA.items.buttons.borderRadius}`}
            />

            <ResourceInputNumber
              fullWidth
              label={translate(
                storiesTranslationNamespace.labels.buttonPadding,
                { axis: 'X' }
              )}
              source={`${buttonSource}.${RESOURCES_SCHEMA.items.buttons.paddingX}`}
              name={`${buttonSource}.${RESOURCES_SCHEMA.items.buttons.paddingX}`}
            />

            <ResourceInputNumber
              fullWidth
              label={translate(
                storiesTranslationNamespace.labels.buttonPadding,
                { axis: 'Y' }
              )}
              source={`${buttonSource}.${RESOURCES_SCHEMA.items.buttons.paddingY}`}
              name={`${buttonSource}.${RESOURCES_SCHEMA.items.buttons.paddingY}`}
            />

            <ResourceInputNumber
              fullWidth
              label={translate(
                storiesTranslationNamespace.labels.buttonCoordinate,
                { axis: 'X' }
              )}
              source={`${buttonSource}.${RESOURCES_SCHEMA.items.buttons.x}`}
              name={`${buttonSource}.${RESOURCES_SCHEMA.items.buttons.x}`}
            />

            <ResourceInputNumber
              fullWidth
              label={translate(
                storiesTranslationNamespace.labels.buttonCoordinate,
                { axis: 'Y' }
              )}
              source={`${buttonSource}.${RESOURCES_SCHEMA.items.buttons.y}`}
              name={`${buttonSource}.${RESOURCES_SCHEMA.items.buttons.y}`}
            />*/}

          <ResourceColorInput
            labelKey={storiesTranslationNamespace.labels.buttonColor}
            name={`${buttonSource}.${RESOURCES_SCHEMA.items.buttons.color}`}
          />

          <ResourceColorInput
            labelKey={storiesTranslationNamespace.labels.buttonBackgroundColor}
            name={`${buttonSource}.${RESOURCES_SCHEMA.items.buttons.backgroundColor}`}
          />

          {/* <ResourceColorInput
              labelKey={storiesTranslationNamespace.labels.buttonBorderColor}
              name={`${buttonSource}.${RESOURCES_SCHEMA.items.buttons.borderColor}`}
            />*/}
        </RowInfoBlock>
      )}
    </Flex>
  );
};
