import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
  AccordionActions,
} from '@mui/material';
import { useRecordContext, useTranslate } from 'react-admin';
import { MoneyFormatter, pointsToUnits } from '@Helpers';
import { Preview, Flex, Icon, TruncatedText, RowInfoBlock } from '@UI';
import { useTheme } from '@mui/material/styles';
import { ResourceRoutes } from '@PluginManager/plugins/resourceRoutes';
import { PartialPickingStatuses } from '../../../constants';

export const OrderItems = () => {
  const record = useRecordContext<CoreOrder>();
  const translate = useTranslate();
  const currencySymbol = MoneyFormatter.getCurrencySymbol(record.currency);
  const theme = useTheme();

  return (
    <>
      {record.items.map((row: CoreProductItem) => {
        const {
          name,
          image,
          requested_quantity,
          base_price,
          paid_price,
          picked_quantity,
          sku,
          paid_bonuses,
          discount_amount,
          actual_quantity,
          refunded_quantity,
          product_id,
        } = row;
        const parsedQuantity = parseInt(requested_quantity);
        const isPartial =
          PartialPickingStatuses.includes(record.status) &&
          (parsedQuantity !== picked_quantity ||
            parsedQuantity !== actual_quantity);

        return (
          <Accordion
            TransitionProps={{ unmountOnExit: true }}
            key={name}
            sx={{
              border: isPartial
                ? `1px solid ${theme.palette.warning.main}`
                : undefined,
              boxShadow: 'none',
            }}
          >
            <AccordionActions>
              <Flex fullWidth justifyContent="space-between">
                <Flex alignItems="center">
                  <Preview src={image} size={40} />

                  <Flex
                    asColumn
                    ml={theme.direction === 'ltr' ? 1 : undefined}
                    mr={theme.direction === 'rtl' ? 1 : undefined}
                  >
                    <Link
                      target="_blank"
                      href={`/#${ResourceRoutes.product.routePath}/${product_id}/4`}
                    >
                      <TruncatedText variant="subtitle2">{`${name} (${sku})`}</TruncatedText>
                    </Link>

                    <Typography variant="subtitle2">
                      {translate('orders.pages.labels.item.price', {
                        currency: currencySymbol,
                      })}

                      {` ${pointsToUnits(base_price, false)} / ${pointsToUnits(
                        paid_price,
                        false
                      )}`}
                    </Typography>
                  </Flex>
                </Flex>

                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  expandIcon={<Icon type="expand" />}
                />
              </Flex>
            </AccordionActions>

            <AccordionDetails>
              <RowInfoBlock
                isInline
                titleKey="orders.pages.labels.item.requestedQuantity"
              >
                {requested_quantity}
              </RowInfoBlock>

              <RowInfoBlock
                isInline
                titleKey="orders.pages.labels.item.pickedQuantity"
              >
                {picked_quantity}
              </RowInfoBlock>

              <RowInfoBlock
                isInline
                titleKey="orders.pages.labels.item.actualQuantity"
              >
                {actual_quantity}
              </RowInfoBlock>

              <RowInfoBlock
                isInline
                titleKey="orders.pages.labels.item.refundedQuantity"
              >
                {refunded_quantity ?? 0}
              </RowInfoBlock>

              <RowInfoBlock
                isInline
                titleKey="orders.pages.labels.item.discount"
                titleTranslateOptions={{ currency: currencySymbol }}
              >
                {pointsToUnits(discount_amount, false)}
              </RowInfoBlock>

              <RowInfoBlock
                isInline
                titleKey="orders.pages.labels.item.points"
                titleTranslateOptions={{ currency: currencySymbol }}
              >
                {pointsToUnits(paid_bonuses, false)}
              </RowInfoBlock>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
};
