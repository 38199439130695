import { Preview } from '@UI';
import { useRecordContext } from 'react-admin';
import { ResourcePreviewProps } from './inteface';
import { get } from '@Helpers';

export const ResourcePreview = (props: ResourcePreviewProps) => {
  const { source, size, record: recordData } = props;
  const record = useRecordContext();

  return <Preview src={get(recordData ?? record, source) as string} size={size} />;
};

ResourcePreview.defaultProps = {
  label: 'Preview',
};
