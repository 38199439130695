import { Filter, ListColumn } from '@Widgets/ResourceList/interface';
import { pickersTranslationSchema, PICKER_STATUS_FILTER } from '../constants';
import { RESOURCES_SCHEMA } from '../schemas/resourcesSchema';
import { StatusBadgeResource } from './components/StatusBadgeResource/StatusBadgeResource';
import { EmploymentField } from './components/EmploymentField';

export const DEFAULT_SORTING = {
  field: RESOURCES_SCHEMA.createdAt,
  order: 'DESC',
};

export const DEFAULT_PER_PAGE = 10;

export const FILTERS: Filter[] = [
  {
    source: RESOURCES_SCHEMA.search,
    type: 'search',
  },
  {
    source: RESOURCES_SCHEMA.status,
    type: 'choose-multi',
    choices: PICKER_STATUS_FILTER,
    label: pickersTranslationSchema.labels.status,
  },
  {
    source: RESOURCES_SCHEMA.warehouse,
    type: 'text',
    label: pickersTranslationSchema.labels.warehouses,
  },
  {
    source: RESOURCES_SCHEMA.id,
    type: 'text',
    label: pickersTranslationSchema.labels.ids,
  },
  {
    source: RESOURCES_SCHEMA.createdAtFrom,
    type: 'date',
    label: pickersTranslationSchema.labels.createdAtFrom,
  },
  {
    source: RESOURCES_SCHEMA.createdAtTo,
    type: 'date',
    label: pickersTranslationSchema.labels.createdAtTo,
  },
  {
    source: RESOURCES_SCHEMA.updatedAtFrom,
    type: 'date',
    label: pickersTranslationSchema.labels.updatedAtFrom,
  },
  {
    source: RESOURCES_SCHEMA.updatedAtTo,
    type: 'date',
    label: pickersTranslationSchema.labels.updatedAtTo,
  },
];

// TODO: remove "any" from generic
export const LIST_COLUMNS: ListColumn<typeof RESOURCES_SCHEMA & any>[] = [
  {
    source: RESOURCES_SCHEMA.firstName,
    label: pickersTranslationSchema.labels.firstName,
    type: 'text',
  },
  {
    source: RESOURCES_SCHEMA.lastName,
    label: pickersTranslationSchema.labels.lastName,
    type: 'text',
  },
  {
    source: RESOURCES_SCHEMA.email,
    label: pickersTranslationSchema.labels.email,
    type: 'text',
  },
  {
    source: RESOURCES_SCHEMA.login,
    label: pickersTranslationSchema.labels.login,
    type: 'text',
  },
  {
    source: RESOURCES_SCHEMA.warehouse,
    label: pickersTranslationSchema.labels.warehouse,
    type: 'text',
  },
  {
    source: RESOURCES_SCHEMA.employment,
    label: pickersTranslationSchema.labels.employment,
    type: 'custom',
    customComponent: <EmploymentField />,
  },
  {
    source: RESOURCES_SCHEMA.status,
    label: pickersTranslationSchema.labels.status,
    type: 'custom',
    customComponent: (
      <StatusBadgeResource key={pickersTranslationSchema.labels.status} />
    ),
  },
  {
    source: RESOURCES_SCHEMA.createdAt,
    label: pickersTranslationSchema.labels.createdAt,
    type: 'date',
    showTime: true,
  },
  {
    source: RESOURCES_SCHEMA.updatedAt,
    label: pickersTranslationSchema.labels.updatedAt,
    type: 'date',
    showTime: true,
    sortable: false,
  },
];
