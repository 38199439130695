import { TextInput } from 'react-admin';

export const PageForm = () => {
  return (
    <>
      <TextInput source="name" required />

      <TextInput source="profiles" />
    </>
  );
};
