export const SCHEDULE_ACCORDION_DEFAULT_VALUES = {
  cut_off_time: '',
  delivery_capacity: '',
  delivery_end_time: '',
  delivery_lead_duration_sec: '',
  delivery_price: '',
  delivery_start_time: '',
  free_delivery_threshold: '',
  max_future_date: 14,
  name: '',
  wh_processing_duration_sec: '',
  order_amount_threshold: null,
};
