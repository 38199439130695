import { useState, useRef } from 'react';
import { Empty, Loading, useTranslate, useRefresh } from 'react-admin';
import { useMutation } from 'react-query';
import {
  DragDropContext,
  DragDropContextProps,
  Droppable,
} from 'react-beautiful-dnd';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
} from '@mui/material';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { ResourceRoutes } from '@Plugins/resourceRoutes';
import { RequestAPI } from '@RestApi';

import { WMS_PICKING_PRIORITY_LIST_API } from '../../constants';
import { PickingPriorityTableBody } from '../PickingPriorityTableBody';
import { PickingPriorityTableCell } from '../PickingPriorityEditingTableCell/styled';
import {
  FormValues,
  PickingPriorityRemoteItem,
  ReorderResourceTableProps,
  CategoryMutationData,
} from './interfaces';

const ReorderResourceTable = ({
  loading = false,
  total,
}: ReorderResourceTableProps) => {
  const translate = useTranslate();

  const { control } = useFormContext<FormValues>();

  const tableRef = useRef<Nullable<HTMLTableElement>>(null);

  const { fields, replace } = useFieldArray<FormValues>({
    name: 'data',
    control,
  });

  const rows = fields as PickingPriorityRemoteItem[];

  const refresh = useRefresh();
  const { mutate: updatePickingPriority } = useMutation({
    mutationKey: 'updatePriority',
    mutationFn: async (data: CategoryMutationData) =>
      RequestAPI.patch(WMS_PICKING_PRIORITY_LIST_API, data),
    onSuccess: () => {
      refresh();
    },
    onError: (error) => {
      // TODO: Handle error
      // eslint-disable-next-line
      console.log(error);
    },
  });

  const [draggingRowWidth, setDraggingRowWidth] =
    useState<Nullable<number>>(null);

  const handleDragEnd: DragDropContextProps['onDragEnd'] = (result) => {
    setDraggingRowWidth(null);

    const { source, destination } = result;

    const sourceTarget = rows[source.index];

    if (!sourceTarget || !destination) {
      return;
    }

    const destinationTarget = rows[destination.index];

    if (!destinationTarget) {
      return;
    }

    const items = Array.from(rows);
    const [reorderedItem] = items.splice(source.index, 1);

    items.splice(destination.index, 0, reorderedItem);

    const scopedPriority =
      (rows.length &&
        Math.min(...rows.map((row) => row.pickingPriority ?? 1))) ||
      1;

    replace(
      items.map((item, idx) => ({
        ...item,
        pickingPriority: scopedPriority + idx,
      }))
    );
  };

  const handleOnDragStart: DragDropContextProps['onDragStart'] = (start) => {
    const draggingRow = document.querySelector(
      `[data-rbd-draggable-id="${start.draggableId}"]`
    );

    if (!draggingRow) {
      return;
    }

    setDraggingRowWidth((draggingRow as HTMLElement).offsetWidth);
  };

  const handleRowDataEdit = (
    id: number,
    data: Partial<
      Pick<PickingPriorityRemoteItem, 'pickingPriority' | 'pickingCode'>
    >
  ) => {
    const instance = rows.find((row) => `${row.id}` === `${id}`);

    if (!instance) {
      return;
    }

    if (!data.pickingPriority && data.pickingCode) {
      updatePickingPriority({
        batch: [
          {
            id: instance.categoryId,
            pickingPriority: instance.pickingPriority,
            pickingCode: data.pickingCode,
          },
        ],
      });

      return;
    }

    if (!data.pickingCode && data.pickingPriority) {
      updatePickingPriority({
        category: {
          id: instance.categoryId,
          pickingPriority: data.pickingPriority,
        },
      });
    }
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleOnDragStart}>
      <Droppable isDropDisabled={loading} droppableId="table">
        {(provided) => (
          <TableContainer
            component={Paper}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            <Table ref={tableRef}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {translate(
                      'wms-picking.pages.picking-priority.labels.categoryName'
                    )}
                  </TableCell>
                  <TableCell>
                    {translate(
                      'wms-picking.pages.picking-priority.labels.mainCategories'
                    )}
                  </TableCell>
                  <PickingPriorityTableCell>
                    {translate(
                      'wms-picking.pages.picking-priority.labels.pickingCode'
                    )}
                  </PickingPriorityTableCell>
                  <PickingPriorityTableCell>
                    {translate(
                      'wms-picking.pages.picking-priority.labels.pickingPriority'
                    )}
                  </PickingPriorityTableCell>
                </TableRow>
              </TableHead>
              {loading && !rows.length && (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Loading
                        loadingSecondary="wms-picking.pages.picking-priority.labels.tableLoading"
                        sx={{
                          maxHeight: tableRef.current
                            ? tableRef.current.offsetHeight - 56
                            : 350,
                        }}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
              {!loading && !rows.length && (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Empty
                        hasCreate={false}
                        resource={
                          ResourceRoutes.wmsPickingPriority.resourcePath
                        }
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
              {!loading && (
                <PickingPriorityTableBody
                  rows={rows as PickingPriorityRemoteItem[]}
                  draggingRowWidth={draggingRowWidth}
                  droppableProvided={provided}
                  onRowDataEdit={handleRowDataEdit}
                  loading={loading}
                  total={total}
                />
              )}
            </Table>
          </TableContainer>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default ReorderResourceTable;
