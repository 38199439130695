import React from 'react';
import { PluginResourceController } from '@PluginBase';
import { ResourceRoutes } from '../../../resourceRoutes';
import { GetListResult, GetOneResult } from 'react-admin';
import { ListPage } from './ListPage';
import { Icon } from '@UI';
import { apiUrl } from './constants';
import { DCCrud } from '../share/DCCrud';
import { ResourceItemPage } from '@Widgets/ResourceItemPage/ResourceItemPage';
import { ShipmentPage } from '@Plugins/DistributionCenter/resources/Shipments/ShipmentPage';

const crud = new DCCrud(apiUrl);

const ShipmentController = new PluginResourceController({
  menuItem: {
    caption: {
      translationKey: 'distributionCenter.pages.shipments.caption',
    },
    route: ResourceRoutes.shipments.routePath,
    icon: <Icon type="shipments" />,
  },
  resourceRoute: {
    name: ResourceRoutes.shipments.resourcePath,
    list: ListPage,
    edit: (
      <ResourceItemPage type="edit" includeForm={false}>
        <ShipmentPage />
      </ResourceItemPage>
    ),
  },

  dataProvider: {
    getList: async (resource, params): Promise<GetListResult> => {
      return crud.list(params);
    },
    getOne: async (resource, params): Promise<GetOneResult> => {
      if (params.id === undefined || params.id === null)
        return { data: { id: null } };

      const result = await crud.getOne<{ data: CoreShipment }>(params);

      return {
        data: result?.data,
      };
    },
    updateMany: async (resource, params) => {
      await crud.cancelMany(params, `${apiUrl}/canceled`);

      return {
        data: params.ids,
      };
    },
  },
});

export default ShipmentController;
