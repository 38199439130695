import { RaRecord } from 'react-admin';

import { PromocodeFormProps } from '@Plugins/Promotions/resources/Promocodes/interface';

export interface AffiliateResult extends RaRecord {
  exists: boolean;
  instance: PromocodeFormProps & {
    usageLimit: string[];
  };
}

export enum AffiliatesTabs {
  TEMPLATE = 'template',
  AFFILIATES = 'affiliates'
}
