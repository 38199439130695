import { ReactElement } from 'react';
import { ResourceList } from '@Widgets/ResourceList/ResourceList';
import { useTranslate } from 'react-admin';
import { ListColumn } from '@Widgets/ResourceList/interface';

interface Category {
  id: string;
  name: string;
  profiles: number[];
}

const LIST_COLUMNS: ListColumn<Category>[] = [
  {
    source: 'name',
    label: 'replenishment.pages.categories.labels.name',
    type: 'text',
  },
  {
    source: 'profiles',
    label: 'replenishment.pages.categories.labels.profiles',
    type: 'text',
  },
];

export const ListPage = (): ReactElement => {
  const translate = useTranslate();

  return (
    <ResourceList<Category>
      empty={false}
      exporter={false}
      columns={LIST_COLUMNS}
      showDeleteButton={false}
      getItemCardCaption={(record) =>
        translate('replenishment.pages.categories.labels.mobileCardCaption', {
          id: record.id,
        })
      }
    />
  );
};
