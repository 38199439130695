import { useRefresh, RaRecord, UseCreateMutateParams } from 'react-admin';
import { LIST_COLUMNS } from '../constants';
import { ListItemDrawer } from '@Widgets/ListItemDrawer/ListItemDrawer';
import { ResourceRoutes } from '@Plugins/resourceRoutes';
import { ResourceList } from '@Widgets/ResourceList/ResourceList';
import { ResourceItemPage } from '@Widgets/ResourceItemPage/ResourceItemPage';
import { FormPage } from './FormPage';

export const ListPage = () => {
  const refresh = useRefresh();
  const handleMutate = (variables: UseCreateMutateParams<RaRecord>) => {
    if (variables.data) {
      variables.data.name = variables.data.name?.trim();
      variables.data.code = variables.data.code?.trim();
    }

    return variables;
  };

  return (
    <>
      <ResourceList<CoreShippingMethod>
        showDeleteButton={false}
        columns={LIST_COLUMNS}
        actions={false}
        getItemCardCaption={() => ''}
      />

      <ListItemDrawer route={ResourceRoutes.shippingMethod.routePath}>
        {({ isCreate, entity }) =>
          !isCreate && entity ? (
            <ResourceItemPage
              id={entity}
              showBackToListBtn={false}
              redirect="list"
              type="edit"
              includeForm={false}
              mutationOptions={{
                onSettled: () => refresh(),
                onMutate: handleMutate,
              }}
            >
              <FormPage />
            </ResourceItemPage>
          ) : (
            <ResourceItemPage
              redirect="list"
              showBackToListBtn={false}
              type="create"
              includeForm={false}
              mutationOptions={{
                onSettled: () => refresh(),
                onMutate: handleMutate,
              }}
            >
              <FormPage />
            </ResourceItemPage>
          )
        }
      </ListItemDrawer>
    </>
  );
};
