import { useState, SyntheticEvent } from 'react';
import { useUpdate } from 'react-admin';
import { Switch } from '@mui/material';

import { ResourceRoutes } from '@Plugins/resourceRoutes';

import { ReferenceListCustomColumnProps } from '../AdditionalCategoriesColumn/interfaces';

export const IsDefaultColumn = (props: ReferenceListCustomColumnProps) => {
  const { record } = props;

  const [checked, setChecked] = useState(record?.isDefault);

  const [update, { isLoading }] = useUpdate(
    ResourceRoutes.filters.resourcePath
  );

  const updateFilter = (isDefault: boolean): void => {
    update(ResourceRoutes.filters.routePath, {
      id: record?.id,
      previousData: record,
      data: { isDefault },
    });
  };

  const handleChange = (e: SyntheticEvent, checked: boolean) => {
    setChecked(checked);
    updateFilter(checked);
  };

  return (
    <Switch
      onChange={handleChange}
      checked={checked}
      disabled={isLoading}
      onClick={(e) => e.stopPropagation()}
    />
  );
};
