import {
  useEffect,
  useState,
  BaseSyntheticEvent,
  ClipboardEvent,
  ClipboardEventHandler,
} from 'react';
import { TextField } from '@mui/material';
import { Flex } from '@UI';
import { CodeFieldProps } from './interface';

const idCode = 'codeDigit';

export const CodeField = (props: CodeFieldProps) => {
  const { fields, onChange } = props;

  const [code, setCode] = useState<string[]>(Array(fields).fill(''));

  const setFocus = (index: number) => {
    const nextElement = document.getElementById(
      `${idCode}-${index}`
    ) as HTMLInputElement | null;

    nextElement?.focus();
    nextElement?.select();
  };

  const handleInput = (
    e: BaseSyntheticEvent<KeyboardEvent, HTMLInputElement, HTMLInputElement>,
    index: number
  ) => {
    if (e.nativeEvent.key === 'Enter' || e.nativeEvent.metaKey) return;

    const idxOfEmpty = code.indexOf('');
    const newCode = code.slice();
    const newIndex = idxOfEmpty < 0 || idxOfEmpty > index ? index : idxOfEmpty;

    if (e.type === 'keydown') {
      if (e.nativeEvent.key === 'Backspace') {
        e.preventDefault();

        if (!e.target.value) {
          if (index) {
            newCode[index - 1] = '';
            setCode(newCode);
            setFocus(index - 1);
          }
        } else {
          newCode[index] = '';
          setCode(newCode);
        }

        onChange(newCode.join(''));

        return;
      }

      if (e.nativeEvent.key === 'ArrowRight') {
        e.preventDefault();
        setFocus(index + 1);

        return;
      }

      if (e.nativeEvent.key === 'ArrowLeft') {
        e.preventDefault();
        setFocus(index - 1);

        return;
      }

      if (!/\d/.test(e.nativeEvent.key)) {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
      newCode[newIndex] = e.target.value[0] || '';
      setCode(newCode);
      setFocus(newIndex + 1);
      onChange(newCode.join(''));
    }
  };

  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const value = e.clipboardData.getData('text').replace(/\D/g, '');

    if (value) {
      setCode(value.split('').concat(Array(fields).fill('')).slice(0, fields));
    }
  };

  useEffect(() => {
    setCode(Array(fields).fill(''));
    onChange('');
  }, []);

  return (
    <Flex justifyContent="space-between" gap={1}>
      {code.map((val, index) => (
        <TextField
          key={index}
          value={val}
          type="tel"
          autoComplete="off"
          variant="outlined"
          inputProps={{
            autoFocus: !index,
            sx: {
              fontSize: '24px',
              p: '8px',
              textAlign: 'center',
            },
          }}
          id={`${idCode}-${index}`}
          onKeyDown={(e) =>
            handleInput(
              e as unknown as BaseSyntheticEvent<
                KeyboardEvent,
                HTMLInputElement,
                HTMLInputElement
              >,
              index
            )
          }
          onInput={(e) =>
            handleInput(
              e as unknown as BaseSyntheticEvent<
                KeyboardEvent,
                HTMLInputElement,
                HTMLInputElement
              >,
              index
            )
          }
          onPaste={
            handlePaste as unknown as ClipboardEventHandler<HTMLDivElement>
          }
        />
      ))}
    </Flex>
  );
};
