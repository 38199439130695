export interface TResourceRoute {
  resourcePath: string;
  routePath: string;
  customPath?: string;
}

export type TResourceRoutes<TData = any> = Record<
  string,
  TData | Record<string, TData>
>;

export const ResourceRoutes = {
  warehouse: {
    resourcePath: '/warehouses',
    routePath: '/warehouses',
  },
  shippingMethod: {
    resourcePath: '/shipping-methods',
    routePath: '/shipping-methods',
  },
  commodityGroup: {
    resourcePath: '/commodityGroup',
    routePath: '/commodityGroup',
  },
  promotions: {
    promocodes: {
      resourcePath: '/promocodes',
      routePath: '/promocodes',
    },
    referral: {
      resourcePath: '/referral',
      routePath: '/referral',
    },
    tapfilliate: {
      resourcePath: '/affiliates-program',
      routePath: '/affiliates-program',
    },
    gifting: {
      resourcePath: '/gifting',
      routePath: '/gifting',
    },
  },
  stories: {
    resourcePath: '/stories',
    routePath: '/stories',
  },
  loyalty: {
    resourcePath: '/loyalty',
    routePath: '/loyalty',
  },
  orders: {
    resourcePath: '/orders/status',
    routePath: '/orders/status',
  },
  reports: {
    resourcePath: '/reports',
    routePath: '/reports',
  },
  users: {
    resourcePath: '/users',
    routePath: '/users',
  },
  roles: {
    resourcePath: '/roles',
    routePath: '/roles',
  },
  properties: {
    resourcePath: '/properties',
    routePath: '/properties',
  },
  filters: {
    resourcePath: '/filters',
    routePath: '/filters',
  },
  catalog: {
    resourcePath: '/catalog',
    routePath: '/catalog',
  },
  customer: {
    resourcePath: '/customers',
    routePath: '/customers',
  },
  product: {
    resourcePath: '/product',
    routePath: '/product',
  },
  offer: {
    resourcePath: '/offers',
    routePath: '/offers',
  },
  offerOperations: {
    resourcePath: '/offer-operations',
    routePath: '/offer-operations',
  },
  offerExports: {
    resourcePath: '/offer-exports',
    routePath: '/offer-exports',
  },
  bundles: {
    resourcePath: '/bundles',
    routePath: '/bundles',
  },
  outboundOrders: {
    resourcePath: '/dc/outbound-orders',
    routePath: '/dc/outbound-orders',
  },
  pickingOrders: {
    resourcePath: '/dc/picking-orders',
    routePath: '/dc/picking-orders',
  },
  cages: {
    resourcePath: '/dc/cages',
    routePath: '/dc/cages',
  },
  shipments: {
    resourcePath: '/dc/shipments',
    routePath: '/dc/shipments',
  },
  stocks: {
    resourcePath: '/stocks',
    routePath: '/stocks',
  },
  stockImports: {
    resourcePath: '/stocks-imports',
    routePath: '/stocks-imports',
  },
  wmsPickingPriority: {
    resourcePath: '/picking-priority',
    routePath: '/picking-priority',
  },
  wmsPickingOrders: {
    resourcePath: '/wms/picking-orders',
    routePath: '/wms/picking-orders',
  },
  wmsPickers: {
    resourcePath: '/wms/pickers',
    routePath: '/wms/pickers',
  },
  wmsClickAndCollect: {
    resourcePath: '/wms/click-collect',
    routePath: '/wms/click-collect',
  },
  wmsMonitor: {
    resourcePath: '/wms/monitor',
    routePath: '/wms/monitor',
    customPath: '/store-monitor',
  },
  // TODO: Remove dispatcher url here. The url was moved to DeliveryDispatcher/controller.tsx
  deliveryDispatcher: {
    resourcePath: '',
    routePath:
      process.env.REACT_APP_DELIVERY_DISPATCHER_URL ??
      (process.env.REACT_APP_ENV === 'production' ||
      process.env.NODE_ENV === 'development'
        ? 'https://frontend-delivery.jiffygrocery.co.uk/'
        : window.location.origin.replace('cms', 'delivery')),
  },
  replenishment: {
    calculate: {
      resourcePath: '/r/calculate',
      routePath: '/r/calculate',
    },
    categories: {
      resourcePath: '/r/categories',
      routePath: '/r/categories',
    },
    products: {
      resourcePath: '/r/products',
      routePath: '/r/products',
    },
    stores: {
      resourcePath: '/r/stores',
      routePath: '/r/stores',
    },
    productMatrix: {
      resourcePath: '/r/product-matrix',
      routePath: '/r/product-matrix',
    },
    suppliers: {
      resourcePath: '/r/suppliers',
      routePath: '/r/suppliers',
    },
    supplier_schedules: {
      resourcePath: '/r/schedules',
      routePath: '/r/schedules',
    },
    sales: {
      resourcePath: '/r/sales',
      routePath: '/r/sales',
    },
    clusterRules: {
      resourcePath: '/r/cluster-rules',
      routePath: '/r/cluster-rules',
    },
    stocks: {
      resourcePath: '/r/stocks',
      routePath: '/r/stocks',
    },
    futureDeliveries: {
      resourcePath: '/r/future-deliveries',
      routePath: '/r/future-deliveries',
    },
    lots: {
      resourcePath: '/r/lots',
      routePath: '/r/lots',
    },
    final_order_alert: {
      resourcePath: '/r/final_order',
      routePath: '/r/final_order',
    },
    alert: {
      resourcePath: '/r/replenishment',
      routePath: '/r/replenishment',
    },
    simply_replenish: {
      resourcePath: '/r/simply_replenish',
      routePath: '/r/simply_replenish',
    },
  },
} satisfies TResourceRoutes<TResourceRoute>;
