import { Identifier, RaRecord, useTranslate } from 'react-admin';
import { useNavigate } from 'react-router-dom';

import { FILTERS_COLUMNS } from '@Plugins/Settings/resources/Properties/pages/constants';
import { ResourceList } from '@Widgets/ResourceList/ResourceList';
import { ResourceRoutes } from '@Plugins/resourceRoutes';

export const Filters = () => {
  const translate = useTranslate();
  const navigate = useNavigate();

  const handleNavigateToPropertiesEdit = (
    id: Identifier,
    resource: string,
    record: RaRecord
  ) => {
    const filter = record as CoreFilterProperty;

    navigate(
      `${ResourceRoutes.properties.resourcePath}/${filter.additionalPropertyId}`,
      {
        replace: true,
      }
    );

    return false;
  };

  return (
    <ResourceList<CoreFilterProperty>
      resource={ResourceRoutes.filters.resourcePath}
      emptyWhileLoading
      actions={false}
      bulkActionButtons={false}
      columns={FILTERS_COLUMNS}
      defaultSort={{
        field: 'additionalPropertyId',
        order: 'DESC',
      }}
      getItemCardCaption={(record) =>
        translate('settings.pages.properties.labels.mobileCardCaption', {
          id: record.id,
        })
      }
      showDeleteButton={false}
      rowClick={handleNavigateToPropertiesEdit}
    />
  );
};
