import { FormControlLabel, Switch } from '@mui/material';
import { useTranslate } from 'react-admin';
import { useEffect, useState, useRef } from 'react';
import { useController, UseFormReturn } from 'react-hook-form';
import { useQuery } from 'react-query';

import { RowInfoBlock } from '@UI';
import { getDeliveryMechanics } from '@Plugins/Stores/resources/Warehouses/restApi/DeliveryArea/DeliveryAreaCRUD';
import {
  DeliveryAreaDetailedEntity,
  ShippingMechanicCode,
} from '@Plugins/Stores/resources/Warehouses/interface';

import { DeliveryMechanicAccordion } from '../DeliveryMechanicAccordion';
import {
  DeliveryMechanicFormValues,
  SubmitDeliveryMechanicArg,
} from '../DeliveryMechanicAccordion/interfaces';
import { submitZoneShippingMechanic } from '../DeliveryMechanicAccordion/submit';
import { normalizeScheduleDefaultValues } from '../DeliveryMechanicAccordion/normalize';

import { LoaderSkeleton } from './LoaderSkeleton';

const DELIVERY_MECHANIC_WHITELIST: string[] = [ShippingMechanicCode.ON_DEMAND];

export interface DeliveryMechanicSettingsAccordionsProps {
  shippingMethodId: string;
}

const DeliveryMechanicSettingsAccordions = (
  props: DeliveryMechanicSettingsAccordionsProps
) => {
  const translate = useTranslate();
  const { shippingMethodId } = props;

  const deliveryMechanicFormRef = useRef<
    UseFormReturn<DeliveryMechanicFormValues> | undefined
  >();

  const {
    field: {
      value: zoneShippingMethods = [],
      onChange: zoneShippingMethodsChange,
    },
  } = useController<DeliveryAreaDetailedEntity, 'zoneShippingMethods'>({
    name: 'zoneShippingMethods',
    rules: {
      validate: async () => {
        const formMethods = deliveryMechanicFormRef.current;

        if (!formMethods) {
          return undefined;
        }

        const { isDirty } = formMethods.getFieldState('order_amount_threshold');

        if (!isDirty) {
          const { order_amount_threshold } = formMethods.getValues();

          formMethods.setValue(
            'order_amount_threshold',
            order_amount_threshold,
            { shouldDirty: true }
          );
        }

        const result = await formMethods.trigger('order_amount_threshold');

        if (!result) {
          return translate('ra.validation.minValue', { min: 0 });
        }

        return undefined;
      },
    },
  });

  const [activeDeliveryMechanics, setActiveDeliveryMechanics] = useState<
    string[]
  >([]);

  const {
    data: deliveryMechanics = {},
    isLoading: isDeliveryMechanicsLoading,
  } = useQuery({
    queryKey: 'deliveryMechanics',
    queryFn: getDeliveryMechanics,
    onSuccess: (deliveryMechanicData) => {
      const activeMechanics = zoneShippingMethods.map(
        (item) => item.deliveryMechanicId
      );
      const slotDeliveryMechanic =
        deliveryMechanicData[ShippingMechanicCode.SLOT];

      if (slotDeliveryMechanic) {
        if (!activeMechanics.includes(slotDeliveryMechanic.id)) {
          activeMechanics.push(slotDeliveryMechanic.id);
        }

        if (
          !zoneShippingMethods.some(
            ({ deliveryMechanicId }) =>
              deliveryMechanicId === slotDeliveryMechanic.id
          )
        ) {
          handleDeliveryMechanicChange(
            submitZoneShippingMechanic(
              generateDeliveryMechanic(
                slotDeliveryMechanic.id,
                shippingMethodId
              )
            )
          );
        }
      }

      setActiveDeliveryMechanics(activeMechanics);
    },
    cacheTime: 0,
    retry: false,
    refetchOnMount: false,
    retryOnMount: false,
  });

  const handleDeliveryMechanicChange = (zone: CoreZoneShippingMethod) => {
    if (typeof zone.order_amount_threshold !== 'number') {
      zone.order_amount_threshold = zone.order_amount_threshold
        ? Number(zone.order_amount_threshold)
        : null;
    }

    const value = zoneShippingMethods.filter(
      (item) => item.deliveryMechanicId !== zone.deliveryMechanicId
    );

    const slotDeliveryMechanic = deliveryMechanics[ShippingMechanicCode.SLOT];

    if (
      slotDeliveryMechanic &&
      zone.deliveryMechanicId !== slotDeliveryMechanic.id &&
      !value.some((item) => item.deliveryMechanicId === slotDeliveryMechanic.id)
    ) {
      value.push(
        submitZoneShippingMechanic(
          generateDeliveryMechanic(slotDeliveryMechanic.id, shippingMethodId)
        )
      );
    }

    zoneShippingMethodsChange([...value, zone]);
  };

  const handleDeliveryMechanicSwitchChange =
    (mechanic: CoreShippingMechanic) => () => {
      const contains = activeDeliveryMechanics.includes(mechanic.id);

      if (contains) {
        zoneShippingMethodsChange(
          zoneShippingMethods.filter(
            (item) => item.deliveryMechanicId !== mechanic.id
          )
        );

        setActiveDeliveryMechanics((prevState) =>
          prevState.filter((prev) => prev !== mechanic.id)
        );

        return;
      }

      setActiveDeliveryMechanics((prevState) => [...prevState, mechanic.id]);
    };

  useEffect(() => {
    zoneShippingMethodsChange(
      zoneShippingMethods.map((item) => ({
        ...item,
        shippingMethodId,
      }))
    );
  }, [shippingMethodId]);

  return (
    <>
      {isDeliveryMechanicsLoading && <LoaderSkeleton />}
      {!isDeliveryMechanicsLoading &&
        Object.values(deliveryMechanics)
          .filter(({ code }) => DELIVERY_MECHANIC_WHITELIST.includes(code))
          .map((item) => {
            const isActive = activeDeliveryMechanics.includes(item.id);
            const targetZoneShippingMethod = zoneShippingMethods.find(
              (zoneShippingMethod) =>
                zoneShippingMethod.deliveryMechanicId === item.id
            );

            return (
              <RowInfoBlock asColumn key={item.id}>
                <FormControlLabel
                  checked={isActive}
                  onChange={handleDeliveryMechanicSwitchChange(item)}
                  control={<Switch />}
                  label={translate(
                    `stores.warehouses.pages.tabs.general.deliveryMechanics.${item.code}`
                  )}
                />

                {isActive && (
                  <DeliveryMechanicAccordion
                    targetZoneShippingMethod={targetZoneShippingMethod}
                    deliveryMechanicId={item.id}
                    shippingMethodId={shippingMethodId}
                    onChange={handleDeliveryMechanicChange}
                    formRef={deliveryMechanicFormRef}
                  />
                )}
              </RowInfoBlock>
            );
          })}
    </>
  );
};

const generateDeliveryMechanic = (
  deliveryMechanicId: string,
  shippingMethodId: string
): SubmitDeliveryMechanicArg => ({
  values: {
    schedule: normalizeScheduleDefaultValues(),
    order_amount_threshold: '0',
  },
  deliveryMechanicId,
  shippingMethodId,
});

export default DeliveryMechanicSettingsAccordions;
