import { useTranslate } from 'react-admin';
import { Box, Typography } from '@mui/material';
import { useController } from 'react-hook-form';

import { useIsMobile } from '@ROOT/hooks';
import { Flex, ResourceSwitcher, RowInfoBlock } from '@UI';
import { ActiveDates } from '@Plugins/Promotions/resources/Promocodes/pages/PromocodeForm/components/ActiveDates';
import { Type } from '@Plugins/Promotions/resources/Promocodes/pages/PromocodeForm/components/Type';
import { CustomerBuys } from '@Plugins/Promotions/resources/Promocodes/pages/PromocodeForm/components/CustomerBuys';
import { CustomerGet } from '@Plugins/Promotions/resources/Promocodes/pages/PromocodeForm/components/CustomerGet';
import { CustomerEligibility } from '@Plugins/Promotions/resources/Promocodes/pages/PromocodeForm/components/CustomerEligibility';
import { UsageLimits } from '@Plugins/Promotions/resources/Promocodes/pages/PromocodeForm/components/UsageLimits';
import { MinRequirementsBlock } from '@Plugins/Promotions/resources/Promocodes/pages/PromocodeForm/components/MinRequirementsBlock';

export const ReferralProgramForm = (): JSX.Element => {
  const t = useTranslate();
  const isMobile = useIsMobile();
  const {
    field: { value: fieldTypeVal = '' },
  } = useController({ name: 'referral.type' });

  return (
    <>
      <Flex>
        <RowInfoBlock>
          <ResourceSwitcher
            name="referral.isActive"
            labelKey="promotions.pages.referral.labels.isActive"
          />
        </RowInfoBlock>
      </Flex>

      <Flex asColumn={isMobile} gap={1} fullWidth>
        <RowInfoBlock
          asColumn
          flex={1}
          justifyContent="flex-start"
          title={t('promotions.pages.referral.labels.validationRules')}
        >
          {/* TODO: 23.08.2023 Uncomment when it's needed */}
          {/* <TextInput */}
          {/*   source="referral.validationRules.requiredFinishedOrdersCount" */}
          {/*   name="referral.validationRules.requiredFinishedOrdersCount" */}
          {/*   fullWidth */}
          {/*   label="promotions.pages.referral.labels.requiredFinishedOrdersCount" */}
          {/*   type="number" */}
          {/* /> */}

          <Box sx={{ mt: 2 }}>
            <ResourceSwitcher
              name="referral.validationRules.isRequiredVerifiedCustomer"
              labelKey="promotions.pages.referral.labels.isRequiredVerifiedCustomer"
            />
          </Box>
        </RowInfoBlock>

        <ActiveDates sourcePrefix="referral" />
      </Flex>

      <Typography variant="caption" sx={{ mb: 1 }}>
        {t('promotions.pages.referral.labels.refereeProgramSection')}
      </Typography>

      <Flex asColumn fullWidth>
        <Type sourcePrefix="referral" />

        {fieldTypeVal === 'BUY_X_GET_Y' && (
          <>
            <CustomerBuys sourcePrefix="referral" />

            <CustomerGet sourcePrefix="referral" />
          </>
        )}

        {['FREE_SHIPPING', 'BUY_X_GET_Y'].includes(fieldTypeVal) && (
          <CustomerEligibility sourcePrefix="referral" />
        )}

        <MinRequirementsBlock sourcePrefix="referral" />

        <UsageLimits sourcePrefix="referral" />
      </Flex>

      <Typography variant="caption" sx={{ mb: 1 }}>
        {t('promotions.pages.referral.labels.referrerRewardSection')}
      </Typography>

      <Flex asColumn fullWidth>
        <Type sourcePrefix="referee" />

        {fieldTypeVal === 'BUY_X_GET_Y' && (
          <>
            <CustomerBuys sourcePrefix="referee" />

            <CustomerGet sourcePrefix="referee" />
          </>
        )}

        {['FREE_SHIPPING', 'BUY_X_GET_Y'].includes(fieldTypeVal) && (
          <CustomerEligibility sourcePrefix="referee" />
        )}

        <MinRequirementsBlock sourcePrefix="referee" />

        <UsageLimits sourcePrefix="referee" />
      </Flex>
    </>
  );
};
