import {
  Button,
  useGetList,
  useListContext,
  useRecordContext,
  useTranslate,
  Form,
  CheckboxGroupInput,
  SaveButton,
  required,
  useRefresh,
  useNotify,
} from 'react-admin';
import { ResourceRoutes } from '@Plugins/resourceRoutes';
import {
  CAGES_DATA_LIST_COLUMN,
  CAGES_DATA_LIST_COLUMNS,
  CAGES_PO_LIST_COLUMN,
  CAGES_PO_LIST_COLUMNS,
  CagesCrudGetManyResponseDataItem,
  PICKING_CAGE_STATUS,
} from './constants';
import { useEffect, useState, SyntheticEvent } from 'react';
import { StaticDataGrid } from '@Widgets/StaticDataGrid/StaticDataGrid';
import { getCagesPODataList, getPreparedCagesDataList } from './dataGenerators';
import { Flex } from '@UI';
import {
  Box,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { RequestAPI } from '@RestApi';
import { apiUrl } from '../Shipments/constants';
import { BasicModal } from '@Widgets/BasicModal/BasicModal';
import { useResourceContext, useUnselectAll } from 'ra-core';

interface CheckboxesFormProps {
  warehouseList: string[];
  onSubmitResult: (val: string[]) => void;
}

interface Shipment {
  warehouse: string;
  cageId: string;
  pickingOrders: {
    pickingOrderId: string;
    pickingCageId: string;
  }[];
}

const InternalPanel = () => {
  const record: CAGES_DATA_LIST_COLUMN =
    useRecordContext<CAGES_DATA_LIST_COLUMN>();
  // TODO: recalculate only when it is changed
  const tableData = getCagesPODataList(record.rawItem.pickingOrders);

  return (
    <StaticDataGrid<CAGES_PO_LIST_COLUMN>
      sx={{
        margin: '-7px -16px -7px -16px',
      }}
      header={() => <></>}
      cols={CAGES_PO_LIST_COLUMNS}
      data={tableData}
      bulkActionButtons={false}
    />
  );
};

const CheckboxesForm = ({
  warehouseList,
  onSubmitResult,
}: CheckboxesFormProps) => {
  const translate = useTranslate();
  const onSubmit = (val: unknown) =>
    onSubmitResult((val as { warehouses: string[] }).warehouses);

  return (
    <Form record={{ warehouses: warehouseList }} onSubmit={onSubmit}>
      <CheckboxGroupInput
        source="warehouses"
        row={false}
        choices={warehouseList.map((name) => ({
          name,
          id: name,
        }))}
        validate={required()}
      />
      <Flex justifyContent="center" mt={2}>
        <SaveButton
          label={translate(
            'distributionCenter.pages.shipments.labels.createShipments'
          )}
          icon={<></>}
          alwaysEnable
        />
      </Flex>
    </Form>
  );
};

export const ListPage = () => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const notify = useNotify();
  const [shipments, setShipments] = useState<Shipment[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [expandedBlockList, setExpandedBlockList] = useState<string[]>([]);
  const [prepareWarehouseList, setPrepareWarehouseList] = useState<string[]>(
    []
  );
  const resource = useResourceContext();
  const unselectAll = useUnselectAll(resource);
  const { data: warehousesList = [] } =
    useGetList<CagesCrudGetManyResponseDataItem>(
      ResourceRoutes.cages.resourcePath
    );
  const allCagesDataPrepared = warehousesList
    .flatMap((warehouseData) =>
      getPreparedCagesDataList(warehouseData.warehouse, warehouseData.cages)
    )
    .map((cage) => ({
      id: cage.id,
      warehouse: cage.warehouseName,
      cageId: cage.rawItem.cageId,
      pickingOrders: cage.rawItem.pickingOrders.map((order) => ({
        pickingOrderId: order.id,
        pickingCageId: order.pickingCage.id,
      })),
    }));

  const CustomSelectButton = () => {
    const { selectedIds } = useListContext<CAGES_DATA_LIST_COLUMN>();

    useEffect(() => {
      const foundSelectedCages = Object.values(allCagesDataPrepared).filter(
        (el) => selectedIds.includes(el.id)
      );

      if (shipments.length === foundSelectedCages.length) return;

      setShipments(foundSelectedCages);
    }, [selectedIds.length]);

    return <></>;
  };

  const WarehouseBlock = ({
    warehouseData,
  }: {
    warehouseData: CagesCrudGetManyResponseDataItem;
  }) => {
    const cagesDataPrepared = getPreparedCagesDataList(
      warehouseData.warehouse,
      warehouseData.cages
    );
    const totalReadyToShip = cagesDataPrepared.filter(
      ({ status }) => status === PICKING_CAGE_STATUS.READY_TO_SHIP
    ).length;
    const handleChange = (event: SyntheticEvent, isExpanded: boolean) => {
      if (isExpanded) {
        setExpandedBlockList((prev) => [...prev, warehouseData.warehouse]);
      } else {
        setExpandedBlockList((prev) =>
          prev.filter((el) => el !== warehouseData.warehouse)
        );
      }
    };

    return (
      <>
        <Accordion
          expanded={expandedBlockList.includes(warehouseData.warehouse)}
          onChange={handleChange}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{ fontWeight: '600' }}
          >
            {warehouseData.warehouse} (
            <span style={{ color: '#0db275' }}>{totalReadyToShip}</span>/
            {warehouseData.cages.length})
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            <StaticDataGrid<CAGES_DATA_LIST_COLUMN>
              sx={{
                '& .RaBulkActionsToolbar-toolbar': {
                  display: 'none',
                },
              }}
              expand={<InternalPanel />}
              cols={CAGES_DATA_LIST_COLUMNS}
              data={cagesDataPrepared}
              header={() => <></>}
              isRowSelectable={(record) => record.status === 'ready_to_ship'}
              bulkActionButtons={<CustomSelectButton />}
            />
          </AccordionDetails>
        </Accordion>
      </>
    );
  };

  const WarehouseBlocks = (props: {
    warehousesList: CagesCrudGetManyResponseDataItem[];
  }) => (
    <Grid container spacing={4}>
      {props.warehousesList.map((warehouse) => (
        <Grid item sm={12} md={6} lg={4} key={warehouse.warehouse}>
          <WarehouseBlock warehouseData={warehouse} key={warehouse.warehouse} />
        </Grid>
      ))}
    </Grid>
  );

  const closeModal = () => setIsModalOpen(false);
  const onSubmitResult = async (result: string[]) => {
    closeModal();
    setIsLoading(true);

    try {
      await RequestAPI.post(apiUrl, {
        cages: shipments
          .filter(({ warehouse }) => result.includes(warehouse))
          .map(({ cageId, pickingOrders }) => ({
            cageId,
            pickingOrders,
          })),
      });

      unselectAll();

      notify('distributionCenter.pages.shipments.messages.createdSuccessful', {
        type: 'success',
      });
    } catch (error) {
      notify('distributionCenter.pages.shipments.errors.createdFailed', {
        type: 'error',
      });
    } finally {
      setIsLoading(false);
      refresh();
    }
  };

  const ModalWarehouses = () => (
    <BasicModal isOpen={isModalOpen} onClose={closeModal}>
      <CheckboxesForm
        warehouseList={prepareWarehouseList}
        onSubmitResult={onSubmitResult}
      />
    </BasicModal>
  );

  useEffect(() => {
    if (!shipments.length) {
      setPrepareWarehouseList([]);

      return;
    }

    setPrepareWarehouseList(
      Array.from(new Set(shipments.map(({ warehouse }) => warehouse)))
    );
  }, [shipments.length]);

  return (
    <>
      <Box py={4}>
        <Flex justifyContent="flex-end">
          <Button
            label={translate(
              'distributionCenter.pages.shipments.labels.createShipments'
            )}
            variant="contained"
            color="primary"
            disabled={!shipments.length || isLoading}
            size="medium"
            onClick={() => setIsModalOpen(true)}
            sx={{ mb: 4 }}
            startIcon={isLoading ? <CircularProgress size={16} /> : <></>}
          />
        </Flex>

        <WarehouseBlocks warehousesList={warehousesList} />
      </Box>

      <ModalWarehouses />
    </>
  );
};
