import { CommonCrud, PluginResourceController } from '@PluginBase';
import { Icon } from '@UI';
import { ListPage } from './pages/ListPage';
import {
  CreateResult,
  DeleteResult,
  GetListResult,
  GetOneResult,
  HttpError,
  UpdateResult,
} from 'react-admin';
import { ResourceRoutes } from '../../../resourceRoutes';
import { ResourceItemPage } from '@Widgets/ResourceItemPage/ResourceItemPage';
import { PromocodeForm } from './pages/PromocodeForm/PromocodeForm';
import {
  DEFAULT_FORM_VALUE,
  DEFAULT_USAGE_LIMIT_ALL_VAL,
  DEFAULT_USAGE_LIMIT_CUSTOMER_VAL,
  promocodesTranslationNamespace,
} from './constants';
import { PromocodeFormProps } from './interface';
import { BackendCodeError } from '../../../../base/AbstractApi/BackendCodeError';
import { generatePromocodeInput, generatePromocodeFromValues } from './utils';
import { RequestAPI } from '@RestApi';
import pluginManager from '@PluginManager/PluginManager';

const API_URL = '/promocodes-service/v1/promocodes';
const promocodesCrud = new CommonCrud(API_URL);

const Promocodes = new PluginResourceController({
  resourceRoute: {
    name: ResourceRoutes.promotions.promocodes.resourcePath,
    list: ListPage,
    create: (
      <ResourceItemPage defaultFormValues={DEFAULT_FORM_VALUE} type="create">
        <PromocodeForm />
      </ResourceItemPage>
    ),
    edit: (
      <ResourceItemPage<CorePromocode>
        modifyRecord={(record: CorePromocode) => ({
          ...record,
          usageLimitAll: record.usageLimitAll || DEFAULT_USAGE_LIMIT_ALL_VAL,
          usageLimitCustomer:
            record.usageLimitCustomer || DEFAULT_USAGE_LIMIT_CUSTOMER_VAL,
        })}
        type="edit"
      >
        <PromocodeForm />
      </ResourceItemPage>
    ),
  },
  menuItem: {
    route: ResourceRoutes.promotions.promocodes.routePath,
    caption: {
      translationKey: promocodesTranslationNamespace.captions.desktop,
    },
    icon: <Icon type="chart" />,
  },
  dataProvider: {
    getList: (resource, params): Promise<GetListResult> =>
      promocodesCrud.list(params),
    getOne: async (resource, params): Promise<GetOneResult> => {
      const promocode: unknown = await promocodesCrud.getOne(params);
      const corePromocode = promocode as CorePromocode;
      const promocodeValues = generatePromocodeFromValues(corePromocode);

      if (promocodeValues && corePromocode.partnerAffiliateId) {
        const affiliate: CoreAffiliateDetail = await RequestAPI.get(
          `${API_URL}/affiliate/list/${corePromocode.partnerAffiliateId}`
        );

        promocodeValues.name = `${affiliate.data.firstname} ${affiliate.data.lastname}`;
        promocodeValues.email = affiliate.data.email;
      }

      return {
        data: promocodeValues,
      };
    },
    create: async (resource, params): Promise<CreateResult> => {
      try {
        params.data = generatePromocodeInput(params.data);

        const promocode = await promocodesCrud.create<CorePromocode>(params);

        return {
          data: promocode,
        };
      } catch (error) {
        if (error instanceof BackendCodeError) {
          const translatedText = pluginManager.i18nProvider.translate(
            error.translationKey
          );

          throw new HttpError(translatedText, error.status, {
            errors: {
              [error.field]: translatedText,
            },
          });
        }

        throw error;
      }
    },
    update: async (resource, params): Promise<UpdateResult> => {
      const previousData = Object.assign(
        params.previousData || {},
        params.data || {}
      );

      params.data = generatePromocodeInput(params.data as PromocodeFormProps);

      try {
        await promocodesCrud.update(params);

        return {
          data: previousData,
        };
      } catch (error) {
        if (error instanceof BackendCodeError) {
          const translatedText = pluginManager.i18nProvider.translate(
            error.translationKey
          );

          throw new HttpError(translatedText, error.status, {
            errors: {
              [error.field]: translatedText,
            },
          });
        }

        throw error;
      }
    },
    delete: async (resource, params): Promise<DeleteResult> => {
      await promocodesCrud.delete(params);

      return {
        data: params.previousData,
      };
    },
  },
});

export default Promocodes;
