/** @jsxRuntime classic */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// IE11 needs "jsxRuntime classic" for this initial file which means that "React" needs to be in scope
// https://github.com/facebook/create-react-app/issues/9906
import React from 'react';
// @ts-ignore
import { createRoot } from 'react-dom/client';
import App from './App';
import { treatReactWarningsAsWarnings } from '@ROOT/environmentSetup';

treatReactWarningsAsWarnings();

const container = document.getElementById('root');

container && createRoot(container).render(<App />);
