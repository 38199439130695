import { PLUGIN_LIST, ROLES_RULES, COMPANY } from './constants';
import { PluginName } from '@PluginManager/interface';
import { AccessPermission, RoleName, PluginsRules } from './interface';
import { ROUTES } from '@CoreRoutes';
import Company from './company';

export const accessPermission: AccessPermission = {
  accessToken: '',
  userId: null,
  rolesList: [],
  disallowPages: [],
  allowedPages: [],
  isSuperadmin: false,
  isAdmin: false,
  companyId: null,
  companyName: null,
  company: null,

  parseAccessToken: function (): void {
    const accessToken = window.localStorage.getItem('auth.accessToken') || '';

    if (this.accessToken === accessToken) return;

    const clearingRolesList = () => {
      this.rolesList = this.rolesList.filter(
        (role) => ROLES_RULES[role as Exclude<RoleName, 'superadmin'>]
      );
    };

    const collectDisallowPages = () => {
      this.disallowPages = COMPANY[this.companyId || '']?.disallowPages || [];

      if (this.isSuperadmin) return;

      if (this.isAdmin) {
        Object.values(ROLES_RULES.admin).forEach((rule) => {
          if (!rule.permission || !rule.disallowPages.length) return;

          this.disallowPages.push(...rule.disallowPages);
        });
      } else {
        this.disallowPages = Array.from(
          new Set(
            Object.values(
              this.rolesList
                .map(
                  (role) =>
                    ROLES_RULES[
                      role as Exclude<RoleName, 'superadmin' | 'admin'>
                    ] || {}
                )
                .reduce<Partial<Record<PluginName, string[]>>>(
                  (rules, rule, index) => {
                    const result: Partial<Record<PluginName, string[]>> = {};

                    if (!index) {
                      PLUGIN_LIST.forEach((plugin) => {
                        if (rule[plugin]?.disallowPages.length) {
                          result[plugin] = rule[plugin]?.disallowPages;
                        }
                      });
                    } else {
                      PLUGIN_LIST.forEach((plugin) => {
                        if (rules[plugin]?.length === 0) return;
                        if (rule[plugin]?.disallowPages.length === 0) return;

                        result[plugin] = [
                          ...(rules[plugin] || []),
                          ...(rule[plugin]?.disallowPages || []),
                        ];
                      });
                    }

                    return result;
                  },
                  {}
                )
            ).reduce((list, pages) => [...list, ...pages], [])
          )
        );
      }
    };

    try {
      const parsedAccessToken = JSON.parse(
        atob(accessToken.split('.')[1] || '{}')
      );

      this.accessToken = accessToken;
      this.userId = parsedAccessToken.userId;
      this.rolesList = parsedAccessToken.roles || [];
      this.companyId = parsedAccessToken.companyId || null;

      if (this.companyId) {
        this.companyName = COMPANY[this.companyId]?.name || null;
      }

      if (this.companyName) {
        this.company = new Company(this.companyName);
      }

      this.isSuperadmin = this.rolesList.includes('superadmin');
      this.isAdmin = this.rolesList.includes('admin');
      if (!this.isSuperadmin && !this.isAdmin) clearingRolesList();
      collectDisallowPages();
    } catch (error) {
      return undefined;
    }
  },

  getPluginList: function (): PluginName[] {
    const pluginList: PluginName[] = [];

    this.parseAccessToken();

    if (!this.rolesList.length) return pluginList;

    let filteredPluginList = PLUGIN_LIST;

    if (
      this.companyId &&
      this.companyName &&
      COMPANY[this.companyId].disallowPlugins.length
    ) {
      filteredPluginList = filteredPluginList.filter(
        (pluginName) =>
          !COMPANY[this.companyId || ''].disallowPlugins.includes(pluginName)
      );
    }

    if (this.isSuperadmin) return filteredPluginList;

    const getPluginList = (pluginsRules: PluginsRules) => {
      filteredPluginList.forEach((pluginName) => {
        if (!pluginsRules[pluginName]?.permission) return;

        pluginList.push(pluginName);
      });
    };

    if (this.isAdmin) {
      getPluginList(ROLES_RULES.admin);

      return pluginList;
    }

    this.rolesList.forEach((role) => {
      const pluginsRules =
        ROLES_RULES[role as Exclude<RoleName, 'superadmin' | 'admin'>];

      if (!pluginsRules) return;

      getPluginList(pluginsRules);
    });

    return filteredPluginList.filter((plugin) => pluginList.includes(plugin));
  },

  checkPages: function (menuList) {
    const getAllowedPages = () => {
      this.allowedPages = menuList.reduce<string[]>(
        (list, menu) => {
          return [
            ...list,
            ...menu.items.map(({ route }) => {
              if (typeof route === 'function') {
                return route();
              }

              return route;
            }),
          ];
        },
        [ROUTES.configuration.route]
      );
    };

    if (!this.accessToken || this.isSuperadmin || !this.disallowPages.length) {
      getAllowedPages();

      return menuList;
    }

    menuList = menuList.map((menu) => {
      menu.items = menu.items.filter(({ route }) => {
        if (typeof route === 'function') {
          return !this.disallowPages.includes(route());
        }

        return !this.disallowPages.includes(route);
      });

      return menu;
    });

    getAllowedPages();

    return menuList;
  },

  checkAccess: function (resource) {
    if (!this.accessToken || this.isSuperadmin || !resource || resource === '/')
      return true;

    return this.allowedPages.some((page) => resource.includes(page));
  },

  checkPermission: function (plugin, action) {
    if (!this.accessToken) return false;

    if (this.isSuperadmin) return true;

    return this.rolesList
      .map(
        (role) =>
          ROLES_RULES[role as Exclude<RoleName, 'superadmin' | 'admin'>] || {}
      )
      .some((rule) => rule[plugin]?.permission.includes(action.toUpperCase()));
  },

  checkRolesExists: function (...roles: RoleName[]): boolean {
    if (!this.accessToken) return false;

    if (this.isSuperadmin) return true;

    return roles.some((role) => this.rolesList.includes(role));
  },
};
