import Masked from 'imask/masked/base';
import { FactoryOpts } from 'imask/esm/masked/factory';

export const MAX_LENGTH = 15 + 1;

export const maskData: ((Masked | FactoryOpts) & { startsWith: string })[] = [
  {
    mask: '+00 (0000) 000-000',
    startsWith: '44',
    lazy: false,
  },
  {
    mask: '+0 (000) 000-00-00',
    startsWith: '7',
    lazy: false,
  },
  {
    mask: '+000 (00) 000 0000',
    startsWith: '971',
    lazy: false,
  },
  {
    mask: '+0000000000[00000]',
    startsWith: '',
  },
];
