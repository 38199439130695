import { createTheme } from '@mui/material/styles';
import { defaultTheme } from 'react-admin';
import { Property } from 'csstype';

// palette doc https://mui.com/material-ui/customization/default-theme/
export const darkTheme = {
  palette: {
    primary: {
      main: '#08b375',
      contrastText: '#fff',
    },
    secondary: {
      main: '#596370',
      contrastText: '#fff',
    },
    background: {
      paper: '#19222d',
      default: '#19222d',
    },
    mode: 'dark' as const, // Switching the dark mode on is a single property value change.
  },
  typography: {
    button: {
      textTransform: 'none' as Property.TextTransform,
    },
  },
  components: {
    ...defaultTheme.components,
    MuiAppBar: {
      styleOverrides: {
        colorSecondary: {
          color: '#ffffffb3',
          backgroundColor: '#616161e6',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: 0,
          span: {
            display: 'none',
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined' as const,
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'outlined' as const,
      },
    },
    MuiInputBase: {
      styleOverrides: {
        multiline: {
          alignItems: 'flex-start',
          paddingTop: '12px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#19222d',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',

          form: {
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
          },
          'form::-webkit-scrollbar': {
            display: 'none',
          },
        },
        paper: {
          maxWidth: '95%',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          span: {
            userSelect: 'none',
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        head: {
          verticalAlign: 'bottom',
        },
      },
    },
    RaAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#19222d',
          height: '48px',
        },
      },
    },
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          borderLeft: 'none !important',
          '&.RaMenuItemLink-active': {
            boxShadow: '3px 0 0 0 #08b375 inset',
          },
        },
      },
    },
    RaDeleteWithConfirmButton: {
      styleOverrides: {
        root: {
          '&:focus::after': {
            display: 'none',
          },
        },
      },
    },
    RaUserMenu: {
      styleOverrides: {
        root: {
          '.RaUserMenu-userButton': {
            fontSize: '12px',
            lineHeight: '1.2',
            textAlign: 'left',
          },
        },
      },
    },
    RaLayout: {
      styleOverrides: {
        root: {
          '.RaLayout-content': {
            overflow: 'hidden',
          },
          '.RaLayout-contentWithSidebar': {
            maxWidth: '100vw',
          },
        },
      },
    },
    RaDatagrid: {
      styleOverrides: {
        root: {
          '.RaDatagrid-tableWrapper': {
            overflow: 'auto',
          },
          '.RaDatagrid-headerCell': {
            verticalAlign: 'bottom',
          },
        },
      },
    },
    RaList: {
      styleOverrides: {
        root: {
          '.RaList-main': {
            width: '100%',
          },
        },
      },
    },
    RaSidebar: {
      styleOverrides: {
        root: {
          '.RaSidebar-fixed': {
            width: '220px',
          },
        },
      },
    },
  },
};

export const lightTheme = {
  palette: {
    primary: {
      main: '#4f3cc9',
    },
    secondary: {
      light: '#5f5fc4',
      main: '#283593',
      dark: '#001064',
      contrastText: '#fff',
    },
    background: {
      default: '#fcfcfe',
    },
    mode: 'light' as const,
  },
  shape: {
    borderRadius: 4,
  },
  typography: {
    button: {
      textTransform: 'none' as Property.TextTransform,
    },
  },
  components: {
    ...defaultTheme.components,
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          borderLeft: 'none !important',
          '&.RaMenuItemLink-active': {
            borderLeft: '3px solid #90caf9',
          },
        },
      },
    },
    RaDeleteWithConfirmButton: {
      styleOverrides: {
        root: {
          '&:focus::after': {
            display: 'none',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorSecondary: {
          color: '#808080',
          backgroundColor: '#fff',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: 0,
          span: {
            display: 'none',
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined' as const,
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'outlined' as const,
      },
    },
    MuiInputBase: {
      styleOverrides: {
        multiline: {
          alignItems: 'flex-start',
          paddingTop: '12px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: 'none',
        },
        root: {
          border: '1px solid #e0e0e3',
          backgroundClip: 'padding-box',
        },
      },
    },
    RaAppBar: {
      styleOverrides: {
        root: {
          height: '48px',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          form: {
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
          },
          'form::-webkit-scrollbar': {
            display: 'none',
          },
        },
        paper: {
          maxWidth: '95%',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          span: {
            userSelect: 'none',
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#f5f5f5',
        },
        barColorPrimary: {
          backgroundColor: '#d7d7d7',
        },
      },
    },
    RaUserMenu: {
      styleOverrides: {
        root: {
          '.RaUserMenu-userButton': {
            fontSize: '12px',
            lineHeight: '1.2',
            textAlign: 'left',
          },
        },
      },
    },
  },
};

export const getTheme = (defaults: any, direction?: 'rtl' | 'ltr') =>
  createTheme({
    ...defaults,
    direction,
  });
