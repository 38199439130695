import { CompanyName, ICompany } from '@Helpers/AccessPermissions/interface';

class Company implements ICompany {
  private readonly _companyName: CompanyName;

  constructor(companyName: CompanyName) {
    this._companyName = companyName;
  }

  get isWarehouseWithoutOdoo() {
    return this.isIncludesIn(CompanyName.CircleK, CompanyName.ViloGelato);
  }

  get isPropertyCreationAvailable() {
    return this.isIncludesIn(
      CompanyName.TheCloudRetail,
      CompanyName.CityDrinks,
      CompanyName.Localee,
      CompanyName.CircleK,
      CompanyName.ViloGelato,
      CompanyName.OurKids
    );
  }

  get isServiceFeeDisabled() {
    return this.isIncludesIn(CompanyName.CityDrinks);
  }

  get isServiceFeeThresholdDisabled() {
    return this.isIncludesIn(CompanyName.CityDrinks);
  }

  get isAdvancedCategory() {
    return this.isIncludesIn(
      CompanyName.TheCloudRetail,
      CompanyName.CityDrinks,
    );
  }

  get isMultiBuyEnabled() {
    return this.isIncludesIn(
      CompanyName.TheCloudRetail,
      CompanyName.Jiffy,
      CompanyName.CityDrinks,
      CompanyName.Localee,
      CompanyName.CircleK,
      CompanyName.ViloGelato,
      CompanyName.OurKids
    );
  }

  get isBackorderEnabled() {
    return this.isIncludesIn(
      CompanyName.TheCloudRetail,
      CompanyName.Jiffy,
      CompanyName.CityDrinks,
      CompanyName.Localee,
      CompanyName.ViloGelato,
      CompanyName.Swifft,
      CompanyName.Baqal,
      CompanyName.OurKids
    );
  }

  get hasExpressDeliverySettings() {
    return this.isIncludesIn(
      CompanyName.TheCloudRetail,
      CompanyName.Jiffy,
      CompanyName.CityDrinks,
      CompanyName.Localee,
      CompanyName.CircleK,
      CompanyName.ViloGelato,
      CompanyName.OurKids
    );
  }

  get hasGTINField() {
    return this.isIncludesIn(
      CompanyName.TheCloudRetail,
      CompanyName.Jiffy,
      CompanyName.Baqal,
    );
  }

  get hasBadgePropertyType() {
    return this.isIncludesIn(
      CompanyName.TheCloudRetail,
      CompanyName.Jiffy,
      CompanyName.ViloGelato,
      CompanyName.CircleK,
      CompanyName.Localee,
    );
  }

  get languageChangingAvailable() {
    return this.isIncludesIn(
      CompanyName.TheCloudRetail,
      CompanyName.CircleK,
      CompanyName.OurKids,
    );
  }

  get categoryCannotBeWithoutImage() {
    return this.isIncludesIn(CompanyName.CityDrinks);
  }

  get isManualStatusChangeEnabled() {
    return this.isIncludesIn(CompanyName.ViloGelato, CompanyName.Swifft);
  }

  get isOrderDetailsShownWhileManualStatusChanging() {
    return this.isIncludesIn(CompanyName.Swifft);
  }

  get availableStatusesForManualChange() {
    if (this.isIncludesIn(CompanyName.ViloGelato)) {
      return ['wh_created', 'picking'];
    }

    return ['wh_created', 'picking', 'ready_to_ship', 'in_delivery'];
  }

  get isCustomerOrderIdHidden() {
    return this.isIncludesIn(CompanyName.CircleK);
  }

  isIncludesIn(...companyNames: CompanyName[]): boolean {
    return companyNames.includes(this._companyName);
  }
}

export default Company;
