import { ColorFieldStyled } from './styles';
import { useRecordContext } from 'react-admin';
import { CommonColumn } from '@Widgets/ResourceList/interface';

export const ResourceColorField = ({ source }: CommonColumn) => {
  const record = useRecordContext();

  return <ColorFieldStyled value={record[source]} disabled type='color' variant='outlined' />;
};

ResourceColorField.defaultProps = {
  label: 'Color',
};
