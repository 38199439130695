export const TRANSLATION_SCHEMA = {
  captions: {
    desktop: 'desktop',
    mobile: 'mobileCaption',
  },
  labels: {
    newOrders: 'newOrders',
    pickingOrders: 'pickingOrders',
    readyToShip: 'readyToShip',
    inDelivery: 'inDelivery',
    min: 'min',
    warehouse: 'warehouse',
  },
};
