interface AuthorizationErrorProps {
  error: string;
  message: string;
  status: number;
}

export class AuthorizationError extends Error {
  static readonly ERROR_CODE = 'AUTHORIZATION_ERROR';
  code = '';
  serverMessage?: string;
  status?: number;

  constructor(error: AuthorizationErrorProps) {
    super(AuthorizationError.ERROR_CODE);

    this.decodeError(error);
  }

  private decodeError(errorProps: AuthorizationErrorProps) {
    const { error, message, status } = errorProps;

    this.code = error;
    this.status = status;
    this.serverMessage = message;
  }
}
