import { ScheduleProvider } from './context/Provider';
import { ScheduleMultiplier } from './ScheduleMultiplier/ScheduleMultiplier';
import { ScheduleViewProps } from './interface';

export const ScheduleView = ({
  value = [],
  onUpdateValue,
  alwaysMobile,
  intervalsQuantity,
  schedulesPath = 'schedules',
}: ScheduleViewProps) => (
  <ScheduleProvider initialValue={value}>
    <ScheduleMultiplier
      onUpdateValue={onUpdateValue}
      maxIntervalLength={intervalsQuantity}
      schedulesPath={schedulesPath}
      alwaysMobile={alwaysMobile}
    />
  </ScheduleProvider>
);
