import { useRecordContext } from 'react-admin';
import { Link } from '@mui/material';

export const WarehouseLinkedField = () => {
  const record = useRecordContext();

  return <Link fontSize={14} href={`#/warehouses/${record.warehouse_id}`}>{record.warehouse_code}</Link>;
};

WarehouseLinkedField.defaultProps = {
  label: 'orders.pages.labels.warehouseCode',
};
