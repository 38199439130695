import { ZonePriority } from './interface';

export const MAP_ZOOM = 12;
export const MAP_CENTER = { lat: 51.51375815831753, lng: -0.15098046054686165 };

export const DELIVERY_ZONES_STYLE: Record<ZonePriority, Record<'fillOpacity' | 'strokeOpacity', number>> = {
  1: {
    fillOpacity: 0.1,
    strokeOpacity: 0.45,
  },
  2: {
    fillOpacity: 0.15,
    strokeOpacity: 0.72,
  },
  3: {
    fillOpacity: 0.1,
    strokeOpacity: 0.38,
  },
  100: {
    fillOpacity: 0.1,
    strokeOpacity: 0.38,
  },
};
