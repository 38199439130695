import { useState, FormEvent } from 'react';
import { useTranslate } from 'react-admin';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Button, FormControl, Typography } from '@mui/material';

import { UIPhoneInput } from '@UI';
import { cleanPhone } from '@Helpers';
import CircularProgress from '@mui/material/CircularProgress';

import { PhoneStepProps } from './interface';

export const PhoneStep = (props: PhoneStepProps) => {
  const { onPhoneFilled, defaultPhoneValue, isLoading } = props;

  const translate = useTranslate();
  const [phone, setPhone] = useState(defaultPhoneValue);
  const [rawPhone, setRawPhone] = useState(cleanPhone(defaultPhoneValue));
  const [isValid, setIsValid] = useState(false);

  const handleChange = (phone: string) => {
    setPhone(phone);
    setRawPhone(phone);
  };

  const handleValidate = (flag: boolean): void => {
    setIsValid(flag);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (!isValid) return;

    onPhoneFilled(phone, rawPhone);
  };

  return (
    <>
      <Typography textAlign="center" fontSize="16px">
        {translate('pages.login.enterPhone')}
      </Typography>

      <form onSubmit={handleSubmit}>
        <FormControl fullWidth>
          <UIPhoneInput
            value={phone}
            onChange={handleChange}
            onValidate={handleValidate}
            autoFocus
            placeholder="+971"
            label={translate('pages.login.phoneNumber')}
          />
        </FormControl>

        <Button
          variant="contained"
          type="submit"
          color="primary"
          sx={{ mt: 1 }}
          fullWidth
          disabled={!isValid || isLoading}
        >
          {translate('pages.login.getCode')}

          {isLoading ? (
            <CircularProgress
              color="primary"
              size="16px"
              sx={{ ml: 1 }}
              thickness={6}
            />
          ) : (
            <ArrowForwardIosIcon fontSize="small" sx={{ ml: '5px' }} />
          )}
        </Button>
      </form>
    </>
  );
};
