import { useState, FormEvent } from 'react';
import { Timer } from './Timer/Timer';
import { useTranslate } from 'react-admin';
import { Typography, Button, CircularProgress, Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Flex } from '@UI';
import { CodeStepProps } from './interface';
import { CodeField } from './CodeField/CodeField';

export const CodeStep = (props: CodeStepProps) => {
  const { phone, isLoading, goPhoneStep, onRequestOtpCode, onCodeFilled } =
    props;

  const [canResendCode, setCanResendCode] = useState(false);
  const [code, setCode] = useState('');
  const [attempt, setAttempt] = useState(0);
  const translate = useTranslate();

  const onSmsTimerEndHandler = () => {
    setCanResendCode(true);
  };

  const handleRequestOtpCode = () => {
    onRequestOtpCode();
    setCanResendCode(false);
    setAttempt((prevState) => prevState + 1);
  };

  function handleSendCode(e: FormEvent) {
    e.preventDefault();
    onCodeFilled(code);
    setAttempt((prevState) => prevState + 1);
  }

  return (
    <>
      <Typography textAlign="center" fontSize="16px">
        {translate('pages.login.enterCode')}
      </Typography>

      <Typography textAlign="center" fontSize="13px" marginTop={1}>
        {translate('pages.login.enterCodeDescription1')}

        <Typography
          fontSize="13px"
          component="span"
          sx={{ color: 'primary.main' }}
        >
          {phone}
        </Typography>

        {translate('pages.login.enterCodeDescription2')}
      </Typography>

      <form onSubmit={handleSendCode}>
        <Box my={2}>
          <CodeField key={attempt} fields={4} onChange={setCode} />
        </Box>

        <Button
          variant="contained"
          type="submit"
          color="primary"
          fullWidth
          disabled={code.length < 3 || isLoading}
        >
          {translate('pages.login.signin')}

          {isLoading ? (
            <CircularProgress
              color="primary"
              size="16px"
              sx={{ ml: 1 }}
              thickness={6}
            />
          ) : (
            <ArrowForwardIosIcon fontSize="small" sx={{ ml: '5px' }} />
          )}
        </Button>
      </form>

      {!canResendCode && (
        <Box mt={3} mb={-1}>
          <Timer onTimerEnd={onSmsTimerEndHandler} />
        </Box>
      )}

      <Flex justifyContent="center" alignItems="center" mt={3} gap={1}>
        <Button
          onClick={goPhoneStep}
          color="secondary"
          fullWidth
          sx={{ color: '#fff' }}
        >
          <ArrowBackIcon fontSize="small" sx={{ mr: '5px' }} />

          {translate('pages.login.changeNumber')}
        </Button>

        {canResendCode && (
          <Button
            color="secondary"
            variant="contained"
            onClick={handleRequestOtpCode}
            fullWidth
          >
            {translate('pages.login.resendCode')}
          </Button>
        )}
      </Flex>
    </>
  );
};
