import { PluginResourceController } from '@PluginBase';
import { ResourceRoutes } from '../../../resourceRoutes';
import { GetListResult } from 'react-admin';
import { CommonCrud } from '@PluginManager/base/AbstractApi/CommonCrud';

const rolesCrud = new CommonCrud('/auth/v1/roles');

rolesCrud.isNewSorting = true;

const RolesController = new PluginResourceController({
  menuItem: null,
  resourceRoute: {
    name: ResourceRoutes.roles.resourcePath,
  },
  dataProvider: {
    getList: (resource, params): Promise<GetListResult> =>
      rolesCrud.list(params),
  },
});

export default RolesController;
