import {
  ListColumn,
  ResourceListPropsSort,
  Filter,
} from '@Widgets/ResourceList/interface';
import { formatEntityName } from '@ROOT/helpers/FormatEntityName/FormatEntityName';
import { StatusBadgeResource } from '../share/StatusBadgeResource/StatusBadgeResource';
import { ShipmentProcessingToolbar } from '@Plugins/DistributionCenter/resources/share/ShipmentProcessingToolbar';
import { Flex } from '@UI';

export interface ShipmentTableItem {
  id: number;
  origin: string;
  destination: string;
  cageName: string;
  isShipped: boolean;
}

export const apiUrl = '/dc/v1/shipments';

export const MAX_SHIPMENT_PROCESSING_MINUTES = 10;

export const DEFAULT_SORTING: ResourceListPropsSort = {
  field: 'createdAt',
  order: 'DESC',
};

export const FILTERS: Filter[] = [
  {
    source: 'displayId',
    label: 'distributionCenter.pages.shipments.labels.shipment',
    type: 'text',
  },
  {
    source: 'status',
    type: 'choose-multi',
    choices: [
      { id: 'loading', name: 'distributionCenter.statuses.loading' },
      { id: 'processing', name: 'distributionCenter.statuses.processing' },
      {
        id: 'ready_to_ship',
        name: 'distributionCenter.statuses.ready_to_ship',
      },
      { id: 'shipped', name: 'distributionCenter.statuses.shipped' },
      { id: 'canceled', name: 'distributionCenter.statuses.canceled' },
    ],
    label: 'distributionCenter.pages.shipments.labels.status',
  },
  {
    source: 'origin',
    type: 'text',
    label: 'distributionCenter.pages.shipments.labels.originWarehouses',
  },
  {
    source: 'destination',
    type: 'text',
    label: 'distributionCenter.pages.shipments.labels.destinationWarehouses',
  },
  {
    source: 'createdAtFrom',
    type: 'date',
    label: 'distributionCenter.pages.shipments.labels.createdAtFrom',
  },
  {
    source: 'createdAtTo',
    type: 'date',
    label: 'distributionCenter.pages.shipments.labels.createdAtTo',
  },
  {
    source: 'shippedAtFrom',
    type: 'date',
    label: 'distributionCenter.pages.shipments.labels.shippedAtFrom',
  },
  {
    source: 'shippedAtTo',
    type: 'date',
    label: 'distributionCenter.pages.shipments.labels.shippedAtTo',
  },
];

export const SHIPMENTS_LIST_COLUMNS: ListColumn<CoreShipments>[] = [
  {
    source: 'displayId',
    label: 'distributionCenter.pages.shipments.labels.shipment',
    type: 'function',
    function: (record) => formatEntityName('OUT', 8, record.displayId),
    sortable: false,
  },
  {
    source: 'origin',
    label: 'distributionCenter.pages.shipments.labels.origin',
    type: 'text',
    sortable: false,
  },
  {
    source: 'destinations',
    label: 'distributionCenter.pages.shipments.labels.destinations',
    type: 'function',
    function: (record) =>
      Array.from(new Set(record.destinations || [])).join(', '),
    sortable: false,
  },
  {
    source: 'status',
    label: 'distributionCenter.pages.shipments.labels.status',
    type: 'function',
    function: (record) => (
      <Flex asColumn>
        <StatusBadgeResource key="status" />
        {record.status === 'processing' && <ShipmentProcessingToolbar />}
      </Flex>
    ),
    sortable: false,
  },
  {
    source: 'cagesDemand',
    label: 'distributionCenter.pages.shipments.labels.cagesDemand',
    type: 'text',
    sortable: false,
  },
  {
    source: 'cagesShipped',
    label: 'distributionCenter.pages.shipments.labels.cagesShipped',
    type: 'text',
    sortable: false,
  },
  {
    source: 'createdAt',
    label: 'distributionCenter.pages.shipments.labels.createdAt',
    type: 'date',
    showTime: true,
  },
  {
    source: 'shippedAt',
    label: 'distributionCenter.pages.shipments.labels.shippedAt',
    type: 'date',
    sortable: false,
    showTime: true,
  },
];

export const CAGES_LIST_COLUMNS: ListColumn<ShipmentTableItem>[] = [
  {
    source: 'origin',
    label: 'distributionCenter.pages.shipments.labels.origin',
    type: 'text',
    sortable: false,
  },
  {
    source: 'destination',
    label: 'distributionCenter.pages.shipments.labels.destination',
    type: 'text',
    sortable: false,
  },
  {
    source: 'cageName',
    label: 'distributionCenter.pages.shipments.labels.cages',
    type: 'text',
    sortable: false,
  },
  {
    source: 'isShipped',
    label: 'distributionCenter.pages.shipments.labels.shipped',
    type: 'boolean',
    sortable: false,
  },
];
