import { useTranslate, SimpleForm, useRecordContext, SaveButton, Toolbar, required } from 'react-admin';
import { Flex, ResourceAutocompleteInput, RowInfoBlock } from '@UI';
import { RESOURCES_SCHEMA } from '../schemas/resourcesSchema';
import { pickersTranslationSchema, PICKER_EMPLOYMENT_FILTER, PICKER_STATUS_FILTER } from '../constants';
import { useIsMobile } from '@Hooks/useIsMobile';

export const PageForm = () => {
  const record = useRecordContext<CorePickerDto>();
  const translate = useTranslate();
  const isMobile = useIsMobile();

  return (
    <SimpleForm record={record} toolbar={<Toolbar><SaveButton /></Toolbar>}>
      <Flex asColumn={isMobile} gap={1} fullWidth>
        <RowInfoBlock asColumn flex={1}>
          <ResourceAutocompleteInput
            choices={PICKER_EMPLOYMENT_FILTER}
            name={RESOURCES_SCHEMA.employment}
            source={RESOURCES_SCHEMA.employment}
            label={translate(pickersTranslationSchema.labels.employment)}
            validate={required()}
            translateChoice
            fullWidth
          />
        </RowInfoBlock>

        <RowInfoBlock asColumn flex={1}>
          <ResourceAutocompleteInput
            choices={PICKER_STATUS_FILTER}
            name={RESOURCES_SCHEMA.status}
            source={RESOURCES_SCHEMA.status}
            label={translate(pickersTranslationSchema.labels.status)}
            validate={required()}
            translateChoice
            fullWidth
          />
        </RowInfoBlock>
      </Flex>
    </SimpleForm>
  );
};
