// class deprecated, please use CommonCrud instead
export abstract class AbstractApi<ParamsProps, Response> {
  protected readonly apiUrl: string;
  protected readonly params: ParamsProps;
  protected currentFiltersQuery = '';

  constructor(apiUrl: string, params: ParamsProps) {
    this.apiUrl = apiUrl;
    this.params = params;
  }

  protected convertToGetQuery(props?: Record<string, unknown>): string {
    if (!props || !Object.keys(props).length) {
      return '';
    }

    const result: string[] = [];

    Object.keys(props).forEach((key: string) => {
      const value = props[key];

      if (!value) {
        return;
      }

      result.push(`${key}=${value}`);
    });

    return result.join('&');
  }

  protected _addToQuery(attr: string) {
    if (this.currentFiltersQuery.length === 0) {
      this.currentFiltersQuery += `?${attr}`;

      return;
    }

    this.currentFiltersQuery += `&${attr}`;
  }

  abstract execute(): Promise<Response>;
}
