import { SourceCodeError } from '@Plugins/Stores/resources/hooks/interface';

interface BackendCodeErrorProps {
  payload: {
    message: string;
    status: number;
  };
}

export const ResourceErrorCode: Record<string, SourceCodeError> = {
  WAREHOUSE_CODE_SHOULD_BE_UNIQUE: {
    translationKey:
      'stores.warehouses.pages.errors.WAREHOUSE_CODE_SHOULD_BE_UNIQUE',
    source: 'code',
  },
  CODE_SHOULD_BE_UNIQUE: {
    translationKey:
      'settings.pages.shippingMethods.errors.CODE_SHOULD_BE_UNIQUE',
    source: 'code',
  },
  OFFER_GTIN_SHOULD_BE_UNIQUE: {
    translationKey: 'catalogue.pages.offers.errors.gtinShouldBeUnique',
    source: 'gtin',
  },
  PROMOCODE_SHOULD_HAVE_DIFFERENT_CODE: {
    translationKey:
      'promotions.pages.promocodes.errors.promocodeShouldHaveDifferentCode',
    source: 'code',
  },
  'Fields uniqueness violation': {
    translationKey:
      'stores.warehouses.pages.tabs.deliveryArea.errors.priorityDuplicate',
    source: 'priority',
  },
};

export class BackendCodeError extends Error {
  static readonly ERROR_CODE = 'BACKEND_CODE_VALIDATION_ERROR';
  translationKey = '';
  field = '';
  originalMessage = '';
  status = 0;

  constructor(error: BackendCodeErrorProps) {
    super(BackendCodeError.ERROR_CODE);
    this.decodeError(error);
  }

  private decodeError(error: BackendCodeErrorProps) {
    const { translationKey, source } =
      ResourceErrorCode[error.payload.message] || {};

    this.translationKey = translationKey;
    this.field = source;
    this.message = translationKey;
    this.originalMessage = error.payload.message || '';
    this.status = error.payload.status;
  }
}
