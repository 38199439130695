import { useState, useEffect } from 'react';

export function useTruncate(wrapperWidth?: number, customTruncate?: number) {
  const [currentTruncate, setCurrentTruncate] = useState<number>();

  useEffect(() => {
    if (!currentTruncate && wrapperWidth) {
      setCurrentTruncate(customTruncate ?? wrapperWidth ?? 350);
    }
  }, [wrapperWidth]);

  return currentTruncate;
}
