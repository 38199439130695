import { Typography } from '@mui/material';
import { useRecordContext, useTranslate } from 'react-admin';

export const ResourceProductStatus = () => {
  const record = useRecordContext<CoreProduct>();
  const translate = useTranslate();

  return (
    <Typography fontSize={14}>
      {translate(`catalogue.pages.products.filters.statuses.${record.status}`)}
    </Typography>
  );
};

ResourceProductStatus.defaultProps = {
  label: 'catalogue.pages.products.labels.status',
  sortBy: 'status',
};
