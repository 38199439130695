import { RowInfoBlock, Flex } from '@UI';
import { useRecordContext } from 'react-admin';
import { UserInfo } from '../../components/UserInfo/UserInfo';
import { Typography, Link } from '@mui/material';
import { WarehouseLinkedField } from '../../components/WarehouseLinkedField/WarehouseLinkedField';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PersonIcon from '@mui/icons-material/Person';

export const Delivery = () => {
  const record = useRecordContext<CoreOrder>();

  return (
    <>
      {record.address && (
        <RowInfoBlock titleKey="orders.pages.labels.deliveryAddress">
          <UserInfo type="address" />
        </RowInfoBlock>
      )}

      <RowInfoBlock titleKey="orders.pages.labels.store">
        <WarehouseLinkedField />
      </RowInfoBlock>

      <RowInfoBlock titleKey="orders.pages.labels.seller">
        <Typography>{record.seller}</Typography>
      </RowInfoBlock>

      {/* {record.delivery?.tracking && (
        <RowInfoBlock titleKey="orders.pages.labels.deliveryTasks.caption">
          {record.delivery.tracking && (
            <>
              <Typography mt={1}>
                {translate('orders.pages.labels.deliveryTasks.courierTracking')}
              </Typography>

              <Link href={record.delivery.tracking.dropoff}>
                {translate('orders.pages.labels.deliveryTasks.dropOff')}
              </Link>

              <Link href={record.delivery.tracking.pickup}>
                {translate('orders.pages.labels.deliveryTasks.pickup')}
              </Link>
            </>
          )}
        </RowInfoBlock>
      )} */}

      {/* {record.picking?.id && (
        <RowInfoBlock titleKey="orders.pages.labels.pickingOrder">
          <Link
            href={`${process.env.REACT_APP_PICKING_URL}orders/${record.picking.id}`}
            target="_blank"
          >
            {record.picking.id}
          </Link>
        </RowInfoBlock>
      )} */}

      {record?.picking?.picker && (
        <RowInfoBlock titleKey="orders.pages.labels.picker">
          <Typography>{`${record.picking.picker.first_name} ${record.picking.picker.last_name}`}</Typography>
        </RowInfoBlock>
      )}

      {record?.courier && (
        <RowInfoBlock titleKey="orders.pages.labels.courier">
          <Flex alignItems="center">
            <PersonIcon />

            <Typography ml={1}>{record.courier.name}</Typography>
          </Flex>

          <Link href={`tel:${record.courier.phone}`}>
            <Flex alignItems="center">
              <LocalPhoneIcon />

              <Typography ml={1}>{record.courier.phone}</Typography>
            </Flex>
          </Link>
        </RowInfoBlock>
      )}
    </>
  );
};
