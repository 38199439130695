import { GetOneResult, UpdateParams, UpdateResult } from 'react-admin';

import { Icon } from '@UI';
import { PluginResourceController } from '@PluginBase';
import { ResourceRoutes } from '@Plugins/resourceRoutes';
import {
  generatePromocodeInput,
  generatePromocodeFromValues,
} from '@Plugins/Promotions/resources/Promocodes/utils';

import { ListPage } from './pages/ListPage';
import { ReferralConfig, ReferralProgramFormProps } from './interfaces';
import { transformValidationRules, stringifyValidationRules } from './utils';
import { referralProgramService } from './api';
import { PromocodeFormProps } from '@Plugins/Promotions/resources/Promocodes/interface';

const referralProgramController = new PluginResourceController({
  resourceRoute: {
    name: ResourceRoutes.promotions.referral.resourcePath,
    list: ListPage,
  },
  menuItem: {
    route: ResourceRoutes.promotions.referral.routePath,
    caption: {
      translationKey: 'promotions.pages.referral.captions.desktop',
    },
    icon: <Icon type="chart" />,
  },
  dataProvider: {
    getOne: async (): Promise<GetOneResult> => {
      const response: ReferralConfig<ReferralProgramFormProps> = {
        id: 'unknown',
        referral: null,
        referee: null,
      };

      try {
        const { data } = await referralProgramService.getReferralProgram();

        if (data) {
          response.referral = {
            ...generatePromocodeFromValues(
              transformValidationRules(data) as CorePromocode
            ),
            id: data.id,
          } as ReferralProgramFormProps;
        }
      } catch (error) {
        console.error('referral', error);
      }

      try {
        const { data } = await referralProgramService.getReferralProgram(
          'return_template'
        );

        if (data) {
          response.referee = {
            ...generatePromocodeFromValues(
              transformValidationRules(data) as CorePromocode
            ),
            id: data.id,
          } as ReferralProgramFormProps;
        }
      } catch (error) {
        console.error('referee', error);
      }

      return {
        data: response,
      };
    },
    update: async (
      resource,
      params: UpdateParams<ReferralConfig<ReferralProgramFormProps>>
    ): Promise<UpdateResult> => {
      const { data } = params;

      const responseResult: ReferralConfig<CoreReferralProgramTemplate> = {
        id: 'unknown',
        referral: null,
        referee: null,
      };

      const referral = stringifyValidationRules(
        data.referral
      ) as CoreReferralProgramRemoteTemplate;

      if (referral.id !== undefined) {
        const { data: updatedReferral } =
          await referralProgramService.updateReferralProgram(
            referral.id.toString(),
            generatePromocodeInput(referral as unknown as PromocodeFormProps)
          );

        responseResult.referral = transformValidationRules(updatedReferral);
      } else {
        const { data: createdReferral } =
          await referralProgramService.enableReferralProgram(
            generatePromocodeInput(referral as unknown as PromocodeFormProps)
          );

        responseResult.referral = transformValidationRules(createdReferral);
      }

      const referee = stringifyValidationRules(
        data.referee
      ) as CoreReferralProgramRemoteTemplate;
      const refereeInput = generatePromocodeInput({
        ...referee,
        applyToDiscountedItems: referral.applyToDiscountedItems,
        dateRange: referral.dateRange,
        isActive: referral.isActive,
        code: '',
      } as unknown as PromocodeFormProps);

      if (referee.id !== undefined) {
        const { data: updatedReferee } =
          await referralProgramService.updateReferralProgram(
            referee.id.toString(),
            refereeInput,
            'return_template'
          );

        responseResult.referee = transformValidationRules(updatedReferee);
      } else {
        const { data: createdReferee } =
          await referralProgramService.enableReferralProgram(
            refereeInput,
            'return_template'
          );

        responseResult.referee = transformValidationRules(createdReferee);
      }

      return {
        data: {
          id: 'unknown',
          ...data,
        },
      };
    },
  },
});

export default referralProgramController;
