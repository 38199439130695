import { ListColumn, Filter } from '@Widgets/ResourceList/interface';
import { StatusBadgeResource } from '../components/StatusBadgeResource/StatusBadgeResource';
import { UserInfo } from '../components/UserInfo/UserInfo';
import { WarehouseLinkedField } from '../components/WarehouseLinkedField/WarehouseLinkedField';
import { OrderStatuses } from '../../constants';

export const FILTERS: Filter[] = [
  {
    source: 'search',
    type: 'search',
  },
  {
    source: 'status',
    type: 'choose',
    choices: OrderStatuses,
    label: 'orders.pages.labels.status',
    emptyText: 'orders.pages.filters.allStatus',
  },
  {
    source: 'created_atFrom',
    type: 'date',
    label: 'orders.pages.labels.createdAtFrom',
  },
  {
    source: 'created_atTo',
    type: 'date',
    label: 'orders.pages.labels.createdAtTo',
  },
  {
    source: 'updated_atFrom',
    type: 'date',
    label: 'orders.pages.labels.updatedAtFrom',
  },
  {
    source: 'updated_atTo',
    type: 'date',
    label: 'orders.pages.labels.updatedAtTo',
  },
];

export const LIST_COLUMNS: ListColumn<CoreOrder>[] = [
  {
    label: 'orders.pages.labels.customerOrderId',
    source: 'multiple_order_id',
    type: 'text',
  },
  {
    label: 'orders.pages.labels.orderId',
    source: 'order_id',
    type: 'text',
  },
  {
    label: 'orders.pages.labels.warehouseCode',
    // TODO: fix this "source" value in type definition
    // @ts-ignore
    source: 'code',
    type: 'custom',
    customComponent: <WarehouseLinkedField key="code" />,
    sortable: false,
  },
  {
    type: 'custom',
    label: 'orders.pages.labels.status',
    source: 'status',
    customComponent: <StatusBadgeResource key="status" />,
  },
  {
    label: 'orders.pages.labels.buyerInfo',
    // TODO: fix this "source" value in type definition
    // @ts-ignore
    source: 'buyerInfo',
    type: 'custom',
    customComponent: (
      <UserInfo
        type="namePhone"
        key="status"
        label="orders.pages.labels.buyerInfo"
      />
    ),
    sortable: false,
  },
  {
    label: 'orders.pages.labels.deliveryAddress',
    source: 'address',
    type: 'custom',
    customComponent: (
      <UserInfo
        type="address"
        key="address"
        label="orders.pages.labels.deliveryAddress"
      />
    ),
    sortable: false,
  },
  {
    label: 'orders.pages.labels.deliveryType',
    source: 'delivery_method',
    type: 'text',
  },
  {
    label: 'orders.pages.labels.seller',
    source: 'seller',
    type: 'text',
  },
  {
    label: 'orders.pages.labels.createdAt',
    source: 'created_at',
    type: 'date',
  },
];
