import React from 'react';

import { ImageGallery } from '../share/Tabs/ImageGallary/ImageGallery';

export const ImagesTab = () => (
  <ImageGallery
    type="products"
    mainImageSource="previewImage"
    imagesSource="images"
    isRequired
  />
);
