import { Link as MuiLink } from '@mui/material';
import { Link } from '@mui/icons-material';
import { useRecordContext, useTranslate } from 'react-admin';
import { Flex } from '@UI';

import { VERIFF_API_ROOT } from './constants';

const VerificationLink = () => {
  const record = useRecordContext<CoreCustomer>();
  const translate = useTranslate();

  if (!record.kycVerificationLogs?.length) {
    return null;
  }

  const first = record.kycVerificationLogs[0];

  return (
    <MuiLink
      href={`${VERIFF_API_ROOT}/${first.verificationId}`}
      rel="noopener nofollow"
      target="_blank"
    >
      <Flex asColumn={false} alignItems="center" gap={1}>
        <div style={{ minWidth: 60 }}>
          {translate('customers.pages.customers.labels.openLink')}
        </div>
        <Link />
      </Flex>
    </MuiLink>
  );
};

export default VerificationLink;
