import { ReactElement, useEffect, useState } from 'react';

import { useGetList, useUpdate } from 'react-admin';
import { FileImport, Flex, SUPPORTED_FORMATS } from '@UI';
import {
  DataGridPremium,
  GRID_TREE_DATA_GROUPING_FIELD,
  GridColDef,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import CustomGridRow from './CustomGridRow';
import { ResourceRoutes } from '@PluginManager/plugins/resourceRoutes';
import { Item } from './constants';
import { ReplenishmentApiResource } from '../../apiRoutes';
import { RequestAPI } from '@RestApi';

export const columns: GridColDef<Item>[] = [
  {
    field: 'label',
    flex: 1,
  },
  {
    editable: true,
    field: 'A',
    flex: 1,
  },
  {
    editable: true,
    field: 'B',
    flex: 1,
  },
  {
    editable: true,
    field: 'C',
    flex: 1,
  },
];

export const getRowById = (rows: Item[], id: string): Item | undefined => {
  return rows.find((row) => row.id === id);
};

export const getParentIds = (rows: Item[], row: Item): string[] => {
  const parent = getRowById(rows, row.parentId ?? '');

  if (!parent) {
    return [];
  }

  if (!parent.parentId) {
    return [parent.id];
  }

  const parentIds = getParentIds(rows, parent);

  return [...parentIds, parent.id];
};

const getTreeDataPath = (rows: Item[], row: Item) => [
  ...getParentIds(rows, row),
  row.id,
];

const updateForCategory = async (oldRow: Item, newRow: Item): Promise<Item> => {
  const cluster =
    oldRow.A !== newRow.A
      ? 'cluster_a'
      : oldRow.B !== newRow.B
      ? 'cluster_b'
      : oldRow.C !== newRow.C
      ? 'cluster_c'
      : null;
  const thr =
    oldRow.A !== newRow.A
      ? newRow.A
      : oldRow.B !== newRow.B
      ? newRow.B
      : oldRow.C !== newRow.C
      ? newRow.C
      : null;

  if (cluster == null || thr == null) {
    console.error('Invalid data: ', cluster, thr);

    return newRow;
  }

  try {
    const response = await RequestAPI.patch(
      `${ReplenishmentApiResource.finalOrderAlert}/?category=${newRow.id}`,
      {
        threshold: thr,
        cluster: cluster,
      }
    );

    // eslint-disable-next-line no-console
    console.log(response);

    return newRow;
  } catch (e) {
    console.error(e);

    return oldRow;
  }
};

const updateForProduct = async (oldRow: Item, newRow: Item): Promise<Item> => {
  const cluster =
    oldRow.A !== newRow.A
      ? 'cluster_a'
      : oldRow.B !== newRow.B
      ? 'cluster_b'
      : oldRow.C !== newRow.C
      ? 'cluster_c'
      : null;
  const thr =
    oldRow.A !== newRow.A
      ? newRow.A
      : oldRow.B !== newRow.B
      ? newRow.B
      : oldRow.C !== newRow.C
      ? newRow.C
      : null;

  if (cluster == null || thr == null) {
    console.error('Invalid data: ', cluster, thr);

    return newRow;
  }

  try {
    const response = await RequestAPI.patch(
      `${ReplenishmentApiResource.finalOrderAlert}`,
      {
        product_id: newRow.id,
        threshold: thr,
        cluster: cluster,
      }
    );

    // eslint-disable-next-line no-console
    console.log(response);

    return newRow;
  } catch (e) {
    console.error(e);

    return oldRow;
  }
};

const handleRowUpdate = async (oldRow: Item, newRow: Item) => {
  if (oldRow.parentId == null) {
    return updateForCategory(oldRow, newRow);
  } else {
    return updateForProduct(oldRow, newRow);
  }
};

const transformData = (data: any[] | undefined) => {
  const categories: any[] = [];
  const products: any[] = [];

  if (data === undefined) {
    return [];
  }

  for (const entry of data) {
    categories.push({
      id: entry.id,
      parentId: null,
      label: entry.category.name,
      A: entry.category.cluster_a,
      B: entry.category.cluster_b,
      C: entry.category.cluster_c,
    });

    for (const product of entry.products) {
      products.push({
        id: product.id,
        parentId: entry.id,
        label: product.sku,
        A: product.cluster_a,
        B: product.cluster_b,
        C: product.cluster_c,
      });
    }
  }

  return products.concat(categories);
};

export const ListPage = (): ReactElement => {
  const apiRef = useGridApiRef();
  const [update, { isSuccess }] = useUpdate();

  async function onFileSelectHandler(file: File) {
    await update(ResourceRoutes.replenishment.final_order_alert.resourcePath, {
      data: { file },
      meta: 'load',
    });
  }

  // const [paginationModel, setPaginationModel] = useState({
  //   page: 1,
  //   pageSize: 50,
  // });

  const { data, total, isLoading, error } = useGetList(
    ResourceRoutes.replenishment.final_order_alert.resourcePath,
    {
      // pagination: {
      //   page: paginationModel.page,
      //   perPage: paginationModel.pageSize,
      // },
    }
  );

  const [rowCountState, setRowCountState] = useState(total || 0);

  useEffect(() => {
    setRowCountState((prevRowCountState: number) =>
      total !== undefined ? total : prevRowCountState
    );
  }, [total, setRowCountState]);

  if (error) {
    return <p>ERROR</p>;
  }

  const dataTransformed = transformData(data);
  const rows = data ? dataTransformed : [];

  return (
    <div style={{ paddingTop: '0.5em' }}>
      <Flex alignItems="center">
        <FileImport
          isLoading={isLoading}
          isSuccess={isSuccess}
          accept={SUPPORTED_FORMATS.csv.toString()}
          buttonCaptionKey="replenishment.pages.finalOrderAlert.actions.import"
          onFileSubmitted={onFileSelectHandler}
        />
      </Flex>

      <div>
        <DataGridPremium
          apiRef={apiRef}
          autoHeight
          columns={columns}
          loading={isLoading}
          disableSelectionOnClick
          pagination
          rowCount={rowCountState}
          paginationMode="server"
          // paginationModel={paginationModel}
          // onPaginationModelChange={setPaginationModel}
          // pageSizeOptions={[5]}
          components={{ Row: CustomGridRow }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                [GRID_TREE_DATA_GROUPING_FIELD]: false,
              },
            },
          }}
          experimentalFeatures={{ newEditingApi: true }}
          groupingColDef={{
            hide: true,
          }}
          getTreeDataPath={(row) => getTreeDataPath(rows, row)}
          hideFooter
          isCellEditable={() => true}
          processRowUpdate={async (newRow, oldRow) => {
            return handleRowUpdate(oldRow, newRow);
          }}
          rows={rows}
          treeData
        />
      </div>
    </div>
  );
};
