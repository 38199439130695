import { useController, useFormContext } from 'react-hook-form';
import { useTranslate } from 'react-admin';
import { useEffect } from 'react';

import { ImageGalleryWidget } from '@Widgets/ImageGalleryWidget/ImageGalleryWidget';
import { UIErrorTypography } from '@UI';

import { ImageGalleryProps } from '../../../interface';

export const ImageGallery = ({
  type,
  mainImageSource,
  imagesSource,
  isRequired = false,
  isAdditionalRequired,
}: ImageGalleryProps) => {
  const translate = useTranslate();
  const mainImageSourceValidator = (value: { url: string } | undefined) => {
    return !value?.url
      ? translate('catalogue.pages.products.errors.mainImageIsRequired')
      : undefined;
  };

  const additionalSourceValidator = (value: { url: string }[] | undefined) =>
    !value?.length
      ? translate('ra.validation.minValue', { min: 1 })
      : undefined;

  const source = imagesSource ?? '';
  const {
    trigger,
    formState: { errors },
  } = useFormContext();
  const image = useController({
    name: mainImageSource ?? '',
    rules: { validate: isRequired ? mainImageSourceValidator : undefined },
  });
  const images = useController({
    name: source,
    rules: {
      validate: isAdditionalRequired ? additionalSourceValidator : undefined,
    },
  });

  function onUpdateMainImage(value?: string) {
    image.field.onChange({
      url: value,
    });
  }

  function onUpdateAdditionalImages(urls: string[]) {
    images.field.onChange(
      urls.map((url) => ({
        url,
      }))
    );
  }

  const errorMessage =
    image.fieldState.error?.message ||
    (errors[source]?.message as string | undefined);

  useEffect(() => {
    trigger(source);
  }, [JSON.stringify(images.field.value)]);

  return (
    <>
      {!!errorMessage && <UIErrorTypography>{errorMessage}</UIErrorTypography>}

      <ImageGalleryWidget
        type={type}
        updateMainImage={onUpdateMainImage}
        mainImageUrl={image.field.value?.url}
        updateAdditionalImages={onUpdateAdditionalImages}
        additionalImagesUrls={images.field.value?.map(
          ({ url }: { url: string }) => url
        )}
        hideAdditionalImage={!imagesSource}
        hideMainImage={!mainImageSource}
      />
    </>
  );
};
