import { ListColumn } from "@Widgets/ResourceList/interface";

import { StatusColumn } from './components/StatusColumn';
import { ExportLinkColumn } from './components/ExportLinkColumn';
import { ResourceRoutes } from "@Plugins/resourceRoutes";

export const OFFER_EXPORTS_COLUMNS: ListColumn<CoreOfferExport>[] = [
  {
    source: 'status',
    label: 'catalogue.pages.offerOperations.offerExports.labels.status',
    type: 'custom',
    customComponent: <StatusColumn />,
    sortable: false,
  },
  {
    source: 'file',
    type: 'custom',
    customComponent: <ExportLinkColumn />,
    label: 'catalogue.pages.offerOperations.offerExports.labels.file',
    sortable: false,
  },
  {
    source: 'userId',
    type: 'referenceId',
    resource: ResourceRoutes.users.resourcePath,
    label: 'catalogue.pages.offerOperations.offerExports.labels.user',
    sortable: false,
  },
  {
    source: 'createdAt',
    label: 'catalogue.pages.offerOperations.offerExports.labels.createdAt',
    type: 'date',
    sortable: true,
  },
];
