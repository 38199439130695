import { Plugin } from '@PluginBase';
import WarehousesController from './resources/Warehouses/controller';
import Translation from './i18n.json';

const Stores = new Plugin({
  name: 'Stores',
  i18n: Translation,
  resources: [WarehousesController],
});

export default Stores;
