import styled from 'styled-components';
import ReactQuill from 'react-quill';

export const ReactQuillStyled = styled(ReactQuill)<{
  $toolbarTextColor: string;
  $dir: 'ltr' | 'rtl';
}>`
  .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
    ${({ $dir }) =>
      $dir === 'rtl' ? 'left: 0; right: auto;' : 'right: 0; left: auto;'}
  }

  .ql-toolbar {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    .ql-picker-label {
      color: ${({ $toolbarTextColor }) => $toolbarTextColor};
    }

    svg {
      path,
      polygon,
      line,
      circle,
      polyline,
      rect {
        stroke: ${({ $toolbarTextColor }) => $toolbarTextColor};
      }
    }

    .ql-strike {
      svg {
        path {
          fill: ${({ $toolbarTextColor }) => $toolbarTextColor};
        }
      }
    }
  }

  .ql-container {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;
