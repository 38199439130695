import { ReactElement } from 'react';
import { ResourceList } from '@Widgets/ResourceList/ResourceList';
import { LIST_COLUMNS, LIST_FILTERS } from './constants';
import { useTranslate, FilterButton, CreateButton } from 'react-admin';
import { Flex } from '@UI';

export const ListPage = (): ReactElement => {
  const translate = useTranslate();
  const getItemCardCaption = (record: CoreUser) => {
    let caption = translate('settings.pages.users.labels.mobileCaption');

    if (!record.firstName && !record.lastName) return caption;

    if (!record.firstName) {
      caption += ' ' + record.lastName;
    } else {
      caption = record.firstName;

      if (record.lastName) {
        caption += ' ' + record.lastName;
      }
    }

    return caption;
  };

  return (
    <ResourceList<CoreUser>
      columns={LIST_COLUMNS}
      showDeleteButton
      confirmDeleteDialogContent="ra.message.delete_account"
      defaultSort={{
        field: 'createdAt',
        order: 'DESC',
      }}
      actions={
        <Flex alignItems="center">
          <FilterButton />

          <CreateButton />
        </Flex>
      }
      filters={LIST_FILTERS}
      getItemCardCaption={getItemCardCaption}
    />
  );
};
