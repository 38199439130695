import { Filter, ListColumn } from '@Widgets/ResourceList/interface';
import { Sale } from '@Plugins/Replenishment/resources/Sales/interface';

export const LIST_COLUMNS: ListColumn<Sale & any>[] = [
  {
    source: 'store.name',
    label: 'replenishment.pages.sales.labels.store',
    type: 'text',
  },
  {
    source: 'product.sku',
    label: 'replenishment.pages.sales.labels.product',
    type: 'text',
  },
  {
    source: 'sales_unit',
    label: 'replenishment.pages.sales.labels.sales_unit',
    type: 'text',
  },
  {
    source: 'date',
    label: 'replenishment.pages.sales.labels.date',
    type: 'date',
  },
];

export const FILTERS: Filter[] = [{ source: 'store', type: 'search' }];
