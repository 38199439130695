import { useEffect } from 'react';
import { useNotify, useRefresh, useTranslate } from 'react-admin';
import { useSetErrorToErrorsContext } from '@Helpers';

interface UseCheckMutationAnswerProps {
  successMessage: string;
  errorMessage: string;
  translationArgs?: Record<string, unknown>;
  isSuccess: boolean;
  error: Error;
  shouldUpdateList?: boolean;
}

export function useCheckMutationAnswer({
  errorMessage,
  successMessage,
  isSuccess,
  error,
  shouldUpdateList,
  translationArgs,
}: UseCheckMutationAnswerProps) {
  const notify = useNotify();
  const refresh = useRefresh();
  const translate = useTranslate();
  const { errors, onErrorHandler } = useSetErrorToErrorsContext();

  useEffect(() => {
    if (isSuccess) {
      shouldUpdateList && refresh();
      notify(successMessage, { type: 'success', messageArgs: translationArgs, autoHideDuration: 10000 });

      return;
    }

    if (error) {
      onErrorHandler(error);
    }
  }, [isSuccess, error]);

  useEffect(() => {
    const resultErrors = Object.values(errors).flat();

    if (resultErrors.length) {
      const errorsText = [translate(errorMessage), ...resultErrors].join('\n');

      notify(errorsText, { type: 'error', autoHideDuration: 10000, multiLine: true });
    }
  }, [JSON.stringify(errors)]);
}
