import { MouseEvent, useState } from 'react';
import { useLocales, useLocaleState } from 'ra-core';
import { Box, Menu, MenuItem, styled } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Translate';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { RTL_SUPPORT_LANGUAGES } from '@ROOT/layout/themeProvider';

import { StyledLocaleButton } from './styled';

const LocalesMenuButton = (props: LocalesMenuButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const languages = useLocales({ locales: props.languages });
  const [locale, setLocale] = useLocaleState();

  const isRTL = RTL_SUPPORT_LANGUAGES.includes(locale);

  const getNameForLocale = (locale: string): string => {
    const language = languages.find((language) => language.locale === locale);

    return language ? language.name : '';
  };

  const changeLocale = (locale: string) => (): void => {
    setLocale(locale);
    setAnchorEl(null);
  };

  const handleLanguageClick = (event: MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <Root component="span">
      <StyledLocaleButton
        $isRTL={isRTL}
        color="inherit"
        aria-controls="simple-menu"
        aria-label=""
        aria-haspopup="true"
        onClick={handleLanguageClick}
        startIcon={<LanguageIcon />}
        endIcon={<ExpandMoreIcon fontSize="small" />}
      >
        {getNameForLocale(locale)}
      </StyledLocaleButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {languages.map((language) => (
          <MenuItem
            key={language.locale}
            onClick={changeLocale(language.locale)}
            selected={language.locale === locale}
          >
            {language.name}
          </MenuItem>
        ))}
      </Menu>
    </Root>
  );
};

const PREFIX = 'RaLocalesMenuButton';

const Root = styled(Box, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})({});

export interface LocalesMenuButtonProps {
  languages?: { locale: string; name: string }[];
}

export default LocalesMenuButton;
