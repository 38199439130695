import { BaseSyntheticEvent, FormEvent } from 'react';
import { useTranslate, TextInput, TimeInput } from 'react-admin';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { Button, Tooltip } from '@mui/material';
import moment from 'moment';

import { useIsMobile } from '@ROOT/hooks';
import { CreateModeToolbarStyled } from '@Widgets/DeliverySchedule/components/ScheduleIntervalAccordion/styled';
import { Flex, ResourceMoneyInput, RowInfoBlock } from '@UI';
import { TIME_DISPLAY_FORMAT } from '@Widgets/Schedule/DayWorkInterval/constants';

import { validationSchema } from './validation';
import { ScheduleIntervalFormProps } from './interfaces';
import { Help } from '@mui/icons-material';

const sxWhiteClock = {
  '& input[type="time"]::-webkit-calendar-picker-indicator': {
    filter: 'invert(100%)',
  },
};

const ScheduleIntervalForm = (
  props: ScheduleIntervalFormProps
): JSX.Element => {
  const {
    onSubmit,
    onClose,
    record,
    closeButtonText = 'ra.action.cancel',
    isLoading = false,
    localization = {},
    timeInputFormat = TIME_DISPLAY_FORMAT,
  } = props;
  const isMobile = useIsMobile();
  const t = useTranslate();
  const {
    name = 'name',
    leadTime = 'leadTime',
    startTime = 'startTime',
    deliveryPrice = 'deliveryPrice',
    maxFutureDate = 'maxFutureDate',
    cutOffTime = 'cutOffTime',
    whProcessingDuration = 'whProcessingDuration',
    endTime = 'endTime',
    deliveryCapacity = 'deliveryCapacity',
    freeDeliveryThreshold = 'freeDeliveryThreshold',
    slotDeliveryMinOrderValue = 'slotDeliveryMinOrderValue',
    slotDeliveryMinOrderHint = 'slotDeliveryMinOrderHint',
  } = localization;

  const formMethods = useForm({
    values: record,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const { isDirty } = formMethods.formState;

  const onValidSubmit = (values: FieldValues, e?: BaseSyntheticEvent) => {
    e?.preventDefault();

    onSubmit?.(values);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    formMethods.handleSubmit(onValidSubmit)();
  };

  const handleParse = (value: string): string =>
    moment(value, TIME_DISPLAY_FORMAT, true).format(timeInputFormat);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit}>
        <Flex asColumn={isMobile} gap={1} fullWidth>
          <RowInfoBlock asColumn flex={1} justifyContent="flex-start">
            <TextInput
              name="name"
              source="name"
              fullWidth
              disabled={isLoading}
              label={t(name)}
              validate={validationSchema.name}
            />

            <TextInput
              name="delivery_lead_duration_sec"
              source="delivery_lead_duration_sec"
              fullWidth
              disabled={isLoading}
              label={t(leadTime)}
              validate={validationSchema.delivery_lead_duration_sec}
              onBlur={() => {
                formMethods.setFocus('cut_off_time');
                formMethods.trigger(['cut_off_time']);
              }}
            />

            <TimeInput
              name="delivery_start_time"
              source="delivery_start_time"
              validate={validationSchema.delivery_start_time}
              label={t(startTime)}
              fullWidth
              InputLabelProps={{ shrink: true }}
              sx={sxWhiteClock}
              disabled={isLoading}
              parse={handleParse}
              inputProps={{
                pattern: '[0-2][0-9]:[0-5][0-9]',
              }}
              onBlur={() => {
                formMethods.trigger(['delivery_end_time', 'cut_off_time']);
              }}
            />

            <ResourceMoneyInput
              name="delivery_price"
              source="delivery_price"
              label={t(deliveryPrice)}
              fullWidth
              disabled={isLoading}
              validate={validationSchema.delivery_price}
            />

            <TextInput
              name="max_future_date"
              source="max_future_date"
              label={t(maxFutureDate)}
              fullWidth
              disabled
            />

            <ResourceMoneyInput
              name="order_amount_threshold"
              source="order_amount_threshold"
              label={t(slotDeliveryMinOrderValue)}
              fullWidth
              validate={validationSchema.order_amount_threshold}
              disabled={isLoading}
              postfixSymbol={
                <Tooltip placement="top" title={t(slotDeliveryMinOrderHint)}>
                  <Help fontSize="small" sx={{ cursor: 'pointer' }} />
                </Tooltip>
              }
            />
          </RowInfoBlock>

          <RowInfoBlock asColumn flex={1} justifyContent="flex-start">
            <TimeInput
              name="cut_off_time"
              source="cut_off_time"
              InputLabelProps={{ shrink: true }}
              sx={sxWhiteClock}
              disabled={isLoading}
              label={t(cutOffTime)}
              validate={validationSchema.cut_off_time}
              fullWidth
              parse={handleParse}
              inputProps={{
                pattern: '[0-2][0-9]:[0-5][0-9]',
              }}
            />

            <TextInput
              name="wh_processing_duration_sec"
              source="wh_processing_duration_sec"
              fullWidth
              disabled={isLoading}
              label={t(whProcessingDuration)}
              validate={validationSchema.wh_processing_duration_sec}
              onBlur={() => {
                formMethods.setFocus('cut_off_time');
                formMethods.trigger(['cut_off_time']);
              }}
            />

            <TimeInput
              name="delivery_end_time"
              source="delivery_end_time"
              validate={validationSchema.delivery_end_time}
              label={t(endTime)}
              fullWidth
              InputLabelProps={{ shrink: true }}
              sx={sxWhiteClock}
              disabled={isLoading}
              parse={handleParse}
              inputProps={{
                pattern: '[0-2][0-9]:[0-5][0-9]',
              }}
              onBlur={() => {
                formMethods.trigger(['delivery_start_time']);
              }}
            />

            <TextInput
              name="delivery_capacity"
              source="delivery_capacity"
              label={t(deliveryCapacity)}
              fullWidth
              disabled={isLoading}
              validate={validationSchema.delivery_capacity}
            />

            <ResourceMoneyInput
              name="free_delivery_threshold"
              source="free_delivery_threshold"
              label={t(freeDeliveryThreshold)}
              fullWidth
              disabled={isLoading}
              validate={validationSchema.free_delivery_threshold}
            />
          </RowInfoBlock>
        </Flex>

        <CreateModeToolbarStyled>
          <Button
            type="button"
            variant="contained"
            size="medium"
            color="success"
            disabled={isLoading || !isDirty}
            onClick={handleSubmit}
          >
            {t('ra.action.save')}
          </Button>
          <Button
            type="button"
            variant="text"
            color="error"
            size="medium"
            onClick={onClose}
          >
            {t(closeButtonText)}
          </Button>
        </CreateModeToolbarStyled>
      </form>
    </FormProvider>
  );
};

export default ScheduleIntervalForm;
