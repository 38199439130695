import { RequestAPI } from '@RestApi';
import { UPLOADER_ERROR_CODES, BACKEND_API_URLS } from './constants';
import { PreSignedUrlResponse } from './interface';
import { MEDIA_REQUEST_TIMEOUT } from '../../providers/dataProvider/rest/config';

export class S3Uploader {
  static types: Record<CoreAllowedImageType, string[]> = {
    png: ['image/png'],
    jpeg: ['image/jpeg', 'image/jpg'],
  };

  private readonly type: CoreUploadFileApiType;
  private readonly files: File[];

  constructor(files: File[], apiType: CoreUploadFileApiType) {
    this.type = apiType;
    this.files = files;
  }

  static getAllowedTypes(types?: CoreAllowedImageType[]): string[] | undefined {
    const result: string[] = (types || [])
      .map((type) => S3Uploader.types[type])
      .filter(Boolean)
      .flat();

    return result.length ? result : undefined;
  }

  async send(): Promise<string[]> {
    const signedUrls = await this.signFiles();

    await this.uploadFiles(signedUrls);

    return signedUrls.map(this.formatUploadedUrl);
  }

  private async signFiles(): Promise<PreSignedUrlResponse[]> {
    try {
      const requests = this.files.map((file) =>
        RequestAPI.post(`${BACKEND_API_URLS}`, {
          name: file.name,
          type: this.type,
        })
      );

      return await Promise.all(requests);
    } catch (e) {
      throw new Error(
        UPLOADER_ERROR_CODES.CAN_NOT_SIGN_FILE_FOR_UPLOADING_ERROR
      );
    }
  }

  private async uploadFiles(signedUrls: PreSignedUrlResponse[]): Promise<void> {
    try {
      const requests = signedUrls.map((signedUrl, i) =>
        this.uploadToS3(signedUrl.data.preSignedLink, this.files[i])
      );

      await Promise.all(requests);
    } catch (e) {
      throw new Error(
        UPLOADER_ERROR_CODES.CAN_NOT_GET_ACCESS_TO_S3_SERVICE_ERROR
      );
    }
  }

  private async uploadToS3(signedUrl: string, file: File) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      xhr.onreadystatechange = (): void => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(signedUrl);

            return;
          }

          reject(new Error(xhr.responseText));
        }
      };

      if (!signedUrl) {
        const error = new Error(
          UPLOADER_ERROR_CODES.CAN_NOT_GET_ACCESS_TO_S3_SERVICE_ERROR
        );

        reject(error);

        return;
      }

      xhr.timeout = MEDIA_REQUEST_TIMEOUT;
      xhr.open('PUT', signedUrl);
      xhr.setRequestHeader('Content-Type', file.type);
      xhr.send(file);
    });
  }

  private formatUploadedUrl(url: PreSignedUrlResponse) {
    return url.data.preSignedLink.split('?')[0];
  }
}
