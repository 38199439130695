import { PropsWithChildren } from 'react';
import { DialogContent, DialogTitle, DialogActions, Dialog } from '@mui/material';
import { UIDialogProps } from './interface';

export const UIDialog = (props: PropsWithChildren<UIDialogProps>) => {
  const { children, open, onClose, controls, title } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="body"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>

      {children && <DialogContent dividers={false}>{children}</DialogContent>}

      {controls && <DialogActions>{controls}</DialogActions>}
    </Dialog>
  );
};
