import { CommonCrud } from '@PluginManager/base/AbstractApi/CommonCrud';
import { RequestAPI } from '@RestApi';
import { GetListParams } from 'ra-core';
import { GetListResult } from 'react-admin';
import { RESOURCES_SCHEMA } from '../schemas/resourcesSchema';

export class PickersCRUD extends CommonCrud {
  constructor(url: string) {
    super(url, {
      arrayFormatter: (key: string, values: string[]) => {
        return values.map((value) => `${key}=${value}`).join('&');
      },
      isNewSorting: true,
    });
  }

  async list(
    params: GetListParams,
    headers?: Record<string, string>
  ): Promise<GetListResult> {
    const { filter } = params;
    const newFilter: Record<string, string | string[]> = {};

    Object.keys(filter).forEach((key) => {
      let value: string | string[] = filter[key] as string;

      if (key === RESOURCES_SCHEMA.warehouse || key === RESOURCES_SCHEMA.id) {
        value = value
          .split(',')
          .map((val) => val.trim())
          .filter((val) => val);
      }

      if (key.includes('From')) {
        value = new Date(value + 'T00:00:00.000').toISOString();
      }

      if (key.includes('To')) {
        value = new Date(value + 'T23:59:59.999').toISOString();
      }

      newFilter[key.replace(/(From|To)$/, (str) => `[${str.toLowerCase()}]`)] =
        value;
    });
    params.filter = newFilter;

    return super.list(params, headers);
  }

  async generateBadges(ids: string[]) {
    return RequestAPI.post(
      `${this.apiUrl}/badges`,
      { ids },
      {
        responseType: 'blob',
      }
    );
  }
}
