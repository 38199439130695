import {
  GridRenderCellParams,
  GridRow,
  GridRowProps,
} from '@mui/x-data-grid-pro';
import type { HTMLAttributes } from 'react';
import { memo, useMemo } from 'react';
import CustomGridTreeDataGroupingCell from './CustomGridTreeDataGroupingCell';
import { Item } from './constants';

interface CustomGridRowProps
  extends HTMLAttributes<HTMLDivElement>,
    GridRowProps {}

function CustomGridRow({
  renderedColumns,
  ...props
}: CustomGridRowProps): JSX.Element {
  const parsedRenderedColums = useMemo(() => {
    return renderedColumns.map((column) => {
      if (column.field === 'label') {
        return {
          ...column,
          renderCell: (params: GridRenderCellParams<string, Item>) => {
            return (
              <CustomGridTreeDataGroupingCell {...params}>
                {params.value}
              </CustomGridTreeDataGroupingCell>
            );
          },
        };
      }

      return column;
    });
  }, [renderedColumns]);

  return <GridRow {...props} renderedColumns={parsedRenderedColums} />;
}

export default memo(CustomGridRow);
