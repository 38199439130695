import {
  ChangeEvent,
  useState,
  KeyboardEvent,
  ClipboardEvent,
  useEffect,
  useRef,
} from 'react';
import { useTranslate } from 'react-admin';
import IMask from 'imask';
import { TextField, InputAdornment } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import debounce from 'lodash.debounce';

import { cleanPhone } from '@Helpers';

import { UIPhoneInputProps } from './interface';
import { maskData, MAX_LENGTH } from './constants';
import {
  maskDispatch,
  validatePhoneValue,
  validateCountryCodePlus,
} from './utils';

export const UIPhoneInput = (props: UIPhoneInputProps) => {
  const {
    onChange,
    onBlur,
    value,
    label,
    placeholder,
    autoFocus,
    className,
    onValidate,
  } = props;

  const t = useTranslate();
  const [prodValidationError, setProdValidationError] = useState('');
  const [isValid, setIsValid] = useState(false);

  const handleValidateDebounced = useRef(
    debounce((phone: string) => {
      const isDevEnv =
        !process.env.REACT_APP_ENV ||
        process.env.REACT_APP_ENV === 'development';

      if (!phone) {
        onValidate?.(false);
        setIsValid(false);
        setProdValidationError('');

        return;
      }

      if (!isDevEnv && (phone.startsWith('0') || phone.startsWith('+0'))) {
        onValidate?.(false);
        setIsValid(false);
        setProdValidationError(t('pages.login.prodPhoneValidationError'));

        return;
      }

      setProdValidationError('');

      const masked = IMask.createMask({
        mask: maskData,
        dispatch: maskDispatch,
      });

      masked.resolve(phone);
      onValidate?.(masked.isComplete);
      setIsValid(masked.isComplete);
    }, 300)
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (!onChange) {
      return;
    }

    const value = e.target.value.replace(/\D/gi, '');

    onChange(validateCountryCodePlus(value));
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const ASCIICode = e.key.charCodeAt(0);

    if (!validatePhoneValue(ASCIICode, target.value)) {
      e.preventDefault();

      return false;
    }
  };

  const handlePaste = (
    e: ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const clipboardData = e.clipboardData?.getData('text/plain') ?? '';

    e.preventDefault();

    const value = cleanPhone(clipboardData);

    onChange?.(validateCountryCodePlus(value));
  };

  useEffect(() => {
    handleValidateDebounced.current(value);
  }, [value]);

  return (
    <TextField
      className={className}
      value={value}
      onChange={handleChange}
      onBlur={onBlur}
      placeholder={placeholder}
      label={label}
      autoFocus={autoFocus}
      error={Boolean(!isValid && value)}
      helperText={
        prodValidationError ||
        (!isValid && value && t('pages.login.error.invalidPhoneNumber'))
      }
      margin="normal"
      InputProps={{
        inputProps: {
          maxLength: MAX_LENGTH,
          onKeyUp: handleKeyPress,
          onPaste: handlePaste,
        },
        endAdornment: isValid && (
          <InputAdornment position="end">
            <CheckIcon color="primary" />
          </InputAdornment>
        ),
      }}
    />
  );
};
