export const emailValidator = (email: string): boolean => {
  email = email.trim();
  if (!email) return false;
  const emailParts = email.split('@');

  if (emailParts.length !== 2) return false;
  if (emailParts[0].length > 64) {
    return false;
  } else if (emailParts[1].length > 255) return false;
  if (emailParts[1].split('.').some((part) => part.length > 63)) return false;

  return new RegExp(
    /^([^<>()[\]\\.,;:\s@"ЁёА-я]+(\.[^<>()[\]\\.,;:\s@"ЁёА-я]+)*)@(([a-zA-Z\-0-9]{2,}\.)+[a-zA-Z]{2,4})$/,
  ).test(email);
};

export const passwordValidator = (password: string, minLength: number): boolean => {
  return !(password.length < minLength ||
    !new RegExp(/\d/).test(password) ||
    !new RegExp(/[A-ZА-ЯЁ]/).test(password) ||
    !new RegExp(/[a-zа-яё]/).test(password) ||
    !new RegExp(/[*?/\\|<>,.()[\]{};:!@#'$"%^&`~_+=-]/).test(password));
};

export const onlyLatinValidator = (text: string): boolean => {
  return !new RegExp(/[^\w\s.,&!@#$%^*(){}[\]?"';:\\/<>|_+=-]/).test(text);
};

export const notContainSpaceValidator = (text: string): boolean => {
  return !new RegExp(/\s/).test(text);
};

export const barcodeValidator = (text: string): boolean => {
  return new RegExp(/^[A-Za-z0-9](-?[A-Za-z0-9]){0,13}[A-Za-z0-9]?$/).test(text);
};
