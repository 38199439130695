import { maxLength, TextInput, useTranslate } from 'react-admin';

import { Flex, RowInfoBlock } from '@UI';
import { Tooltip } from '@mui/material';
import { InfoRounded } from '@mui/icons-material';

export const Description = () => {
  const translate = useTranslate();

  return (
    <Flex asColumn fullWidth sx={{ paddingBlock: 2 }}>
      <RowInfoBlock
        flex={1}
        titleKey="catalogue.pages.categories.labels.description"
        fullWidth
        sx={{ paddingInline: 0, paddingBlock: 0 }}
      >
        <TextInput
          multiline
          fullWidth
          name="description"
          source="description"
          rows={5}
        />
      </RowInfoBlock>

      <RowInfoBlock
        flex={1}
        fullWidth
        sx={{ paddingInline: 0, paddingBlock: 0 }}
      >
        <TextInput
          multiline
          source="previewText"
          rows={5}
          name="previewText"
          validate={maxLength(
            205,
            'catalogue.pages.categories.validation.maxLength'
          )}
          fullWidth
          label={
            <Flex gap={1} alignItems="center">
              <span>
                {translate('catalogue.pages.categories.labels.previewText')}
              </span>
              <Tooltip
                placement="top"
                title={translate(
                  'catalogue.pages.categories.labels.maxLength',
                  {
                    length: 205,
                  }
                )}
              >
                <InfoRounded fontSize="small" />
              </Tooltip>
            </Flex>
          }
        />
      </RowInfoBlock>
    </Flex>
  );
};
