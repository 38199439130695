import { PluginResourceController } from '@PluginBase';
import { ListPage } from './ListPage';
import {
  GetListParams,
  GetListResult,
  UpdateParams,
  UpdateResult,
} from 'react-admin';
import { ResourceRoutes } from '../../../resourceRoutes';
import { ReplenishmentApiResource } from '../../apiRoutes';
import { ReplenishmentCrud } from '../../crud';

const productMatrixCrud = new ReplenishmentCrud(
  ReplenishmentApiResource.productMatrix
);

const ProductMatrixController = new PluginResourceController({
  resourceRoute: {
    name: ResourceRoutes.replenishment.productMatrix.resourcePath,
    list: ListPage,
  },
  menuItem: {
    route: ResourceRoutes.replenishment.productMatrix.routePath,
    caption: {
      translationKey: 'replenishment.pages.productMatrix.caption',
    },
  },
  dataProvider: {
    getList: (
      resource: string,
      params: GetListParams
    ): Promise<GetListResult> => {
      return productMatrixCrud.list(params);
    },
    update: async (
      resource: string,
      params: UpdateParams
    ): Promise<UpdateResult> => {
      if (params.meta === 'load') {
        await productMatrixCrud.uploadFile_(
          ReplenishmentApiResource.productMatrixLoad,
          params.data.file
        );

        return { data: { id: 1 } };
      }

      return { data: 'error' };
    },
  },
});

export default ProductMatrixController;
