import {
  SaveButton,
  Toolbar,
  useGetList,
  Pagination,
  useTranslate,
  useNotify,
} from 'react-admin';
import { useSearchParams } from 'react-router-dom';
import { MouseEvent, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import { ResourceRoutes } from '@Plugins/resourceRoutes';

import {
  PickingPriorityRemoteItem,
  ReorderResourceTable,
} from '../../components/ReorderResourceTable';
import { FormValues } from '../../components/ReorderResourceTable/interfaces';
import { PickingPriorityFilters } from '../../components/PickingPriorityFilters';

import { PER_PAGE_ITEMS } from './constants';
import { mutatePickingPriority, mapDataToArrayField } from './utils';

const PickingPriority = () => {
  const translate = useTranslate();
  const notify = useNotify();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const page = Number(searchParams.get('page') ?? 1);
  const perPage = Number(searchParams.get('perPage') ?? PER_PAGE_ITEMS[0]);
  const search = searchParams.get('search');
  const sorting = searchParams.get('sorting');

  const {
    data = [],
    total,
    refetch,
    isLoading,
  } = useGetList<PickingPriorityRemoteItem>(
    ResourceRoutes.wmsPickingPriority.resourcePath,
    {
      filter: search ? { search: search.trim() } : undefined,
      sort: {
        field: 'pickingPriority',
        order: sorting ? sorting.toUpperCase() : 'ASC',
      },
      pagination: {
        page,
        perPage,
      },
    },
    {
      queryKey: 'listKey',
      onSuccess: (response) => {
        const { data } = response;

        formMethods.reset({ data: data.map(mapDataToArrayField) });
      },
      cacheTime: 0,
      retry: false,
      refetchOnMount: false,
      retryOnMount: false,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
    }
  );

  const formMethods = useForm<FormValues>({
    values: { data: data.map(mapDataToArrayField) },
  });

  const setPage = (page: number) => {
    searchParams.set('page', `${page}`);
    setSearchParams(searchParams);
  };

  const setPerPage = (val: number) => {
    searchParams.set('perPage', `${val}`);
    setSearchParams(searchParams);
  };

  const handleSave = async (e: MouseEvent) => {
    e.preventDefault();

    const { data } = formMethods.getValues();

    setIsSaving(true);

    try {
      await mutatePickingPriority(data);
      notify('wms-picking.pages.picking-priority.labels.successfullyUpdated', {
        type: 'success',
      });
    } catch (e) {
      notify('wms-picking.pages.picking-priority.labels.updateFailed', {
        type: 'error',
      });
    } finally {
      setIsSaving(false);
    }

    await refetch();
  };

  return (
    <FormProvider {...formMethods}>
      <PickingPriorityFilters />

      <ReorderResourceTable
        loading={isLoading}
        saving={isSaving}
        total={total}
      />

      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <SaveButton
          disabled={!formMethods.formState.isDirty || isSaving || isLoading}
          type="button"
          onClick={handleSave}
          label={
            isSaving
              ? translate('wms-picking.pages.picking-priority.labels.saving')
              : undefined
          }
        />

        <Pagination
          rowsPerPageOptions={PER_PAGE_ITEMS}
          perPage={perPage}
          setPage={setPage}
          setPerPage={setPerPage}
          page={page}
          total={total}
        />
      </Toolbar>
    </FormProvider>
  );
};

export default PickingPriority;
