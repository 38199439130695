import {
  Children,
  isValidElement,
  PropsWithChildren,
  SyntheticEvent,
  useState,
  cloneElement,
} from 'react';
import { useTranslate } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { Tab, Tabs } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { Flex } from '@UI';
import { DeliverySchedule } from '@Widgets/DeliverySchedule';

import { DeliveryAreaFormProps, TabName, StyleProps } from './interface';
import { DeliveryAreaMap } from './DeliveryAreaMap';
import { GeneralTab } from './GeneralTab';
import { DELIVERY_AREA_TABS } from './constants';
import { getDeliveryScheduleLocalization } from './localization';
import { accessPermission } from '@Helpers';

export const DeliveryAreaForm = ({
  location,
  zoneId,
  timezone,
  onTabChange,
  excludedTabs,
}: DeliveryAreaFormProps) => {
  const translate = useTranslate();
  const [currentTab, setCurrentTab] = useState(0);
  const theme = useTheme();
  const errorColor = { color: theme.palette.error.main };
  const {
    formState: { errors },
  } = useFormContext();
  const onChangeTabHandler = (event: SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);

    onTabChange?.(newValue as TabName);
  };
  const checkErrors = (tabName: TabName): boolean => {
    switch (tabName) {
      case TabName.General:
        return !!(
          errors.name ||
          errors.shipping_method_id ||
          errors.delivery_price ||
          errors.delivery_price_threshold ||
          errors.service_fee ||
          errors.service_fee_threshold
        );
      case TabName.DeliveryArea:
        return !!(errors.color || errors.priority || errors.points);
      default:
        return false;
    }
  };

  const displayedTabs = excludedTabs
    ? DELIVERY_AREA_TABS.filter((item) => !excludedTabs.includes(item.value))
    : DELIVERY_AREA_TABS;

  return (
    <Flex asColumn width="100%">
      <Tabs
        value={currentTab}
        onChange={onChangeTabHandler}
        variant="scrollable"
      >
        {displayedTabs
          .filter((item) => {
            if (accessPermission.company?.hasExpressDeliverySettings) {
              return true;
            }

            return item.value !== TabName.DeliverySchedule;
          })
          .map((item) => (
            <Tab
              key={item.value}
              style={checkErrors(item.value) ? errorColor : undefined}
              label={translate(item.label)}
              value={item.value}
            />
          ))}
      </Tabs>

      <TabContent value={currentTab}>
        <div key={TabName.General}>
          <GeneralTab />
        </div>
        <div key={TabName.DeliveryArea}>
          <DeliveryAreaMap
            location={location}
            isTabActive={currentTab === TabName.DeliveryArea}
          />
        </div>
        {accessPermission.company?.hasExpressDeliverySettings &&
          currentTab === TabName.DeliverySchedule && (
            <div key={TabName.DeliverySchedule}>
              <DeliverySchedule
                zoneId={zoneId}
                timezone={timezone}
                localization={getDeliveryScheduleLocalization()}
              />
            </div>
          )}
      </TabContent>
    </Flex>
  );
};

export const TabContent = (
  props: PropsWithChildren<{ value: TabName }>
): JSX.Element => {
  const { value, children } = props;

  return (
    <>
      {Children.map(children, (child) => {
        if (
          isValidElement<StyleProps>(child) &&
          child.key !== value.toString()
        ) {
          return cloneElement<StyleProps>(child, {
            style: { display: 'none' },
          });
        }

        return child;
      })}
    </>
  );
};
