import { ResourceList } from '@Widgets/ResourceList/ResourceList';
import { ResourceRoutes } from '@Plugins/resourceRoutes';

import { GIFTING_LIST_COLUMNS, GIFT_FILTERS } from '../../constants';

const ListPage = () => {
  return (
    <ResourceList
      resource={ResourceRoutes.promotions.gifting.resourcePath}
      columns={GIFTING_LIST_COLUMNS}
      filters={GIFT_FILTERS}
      getItemCardCaption={(item) => `${item.id}`}
      showDeleteButton
    />
  );
};

export default ListPage;
