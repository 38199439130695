import { ListColumn } from '@Widgets/ResourceList/interface';
import { ResourceProductStatus } from '../share/components/ResourceProductStatus';
import { ResourceProductMainCategory } from '../share/components/ResourceProductMainCategory';

import { ProductName } from './components/columns/ProductName';

export const productsApiUrl = `/catalog/v1/products`;

export const PRODUCT_LIST_COLUMNS: ListColumn<CoreProduct>[] = [
  {
    source: 'id',
    label: 'catalogue.pages.products.labels.id',
    type: 'text',
  },
  {
    // TODO: Use "function" type for getting data from deep paths
    // @ts-ignore
    source: 'previewImage.url',
    label: 'catalogue.pages.products.labels.image',
    type: 'image',
    showInLine: false,
    sortable: false,
  },
  {
    source: 'name',
    label: 'catalogue.pages.products.labels.name',
    type: 'custom',
    customComponent: <ProductName key="name" />,
    sortable: false,
  },
  {
    source: 'status',
    label: 'catalogue.pages.products.labels.status',
    type: 'custom',
    customComponent: <ResourceProductStatus key="status" />,
  },
  {
    source: 'categoryId',
    label: 'catalogue.pages.products.labels.categoryName',
    type: 'custom',
    customComponent: <ResourceProductMainCategory key="categoryId" />,
    sortable: false,
  },
  {
    source: 'createdAt',
    label: 'catalogue.pages.products.labels.createdAt',
    type: 'date',
    showTime: true,
  },
  {
    source: 'updatedAt',
    label: 'catalogue.pages.products.labels.updatedAt',
    type: 'date',
    showTime: true,
  },
];

export const OFFER_LIST_IN_PRODUCT_COLUMNS: ListColumn[] = [
  {
    source: 'id',
    label: 'catalogue.pages.products.labels.id',
    type: 'text',
  },
  {
    source: 'images[0].url',
    label: 'catalogue.pages.products.labels.firstImage',
    type: 'image',
    showInLine: false,
  },
  {
    source: 'name',
    label: 'catalogue.pages.products.labels.name',
    type: 'text',
  },
  {
    source: 'sorting',
    label: 'catalogue.pages.products.labels.offerSorting',
    type: 'number',
  },
  {
    source: 'status',
    label: 'catalogue.pages.products.labels.status',
    type: 'custom',
    customComponent: <ResourceProductStatus key="status" />,
  },
  {
    source: 'code',
    label: 'catalogue.pages.products.labels.code',
    type: 'text',
  },
  {
    source: 'price',
    label: 'catalogue.pages.products.labels.price',
    type: 'text',
  },
  {
    source: 'discountPrice',
    label: 'catalogue.pages.products.labels.discountPrice',
    type: 'text',
  },
];

export const DEFAULT_PRODUCT_FORM_VALUES: Partial<CoreProduct> = {
  commodityGroupId: 1,
  additionalCategoryIds: [],
  offerProperties: [],
  images: [],
  description: '',
  sorting: 0,
  status: 'draft',
};
