import { InheritedProperty } from './InheritedProperty';
import { ProductPropertyListProps } from '../interface';
import { useForm, FormProvider } from 'react-hook-form';

export const InheritedPropertyList = ({
  selectedFields,
  propertiesList,
}: Omit<ProductPropertyListProps, 'onFieldsChanged' | 'source'>) => {
  const methods = useForm({ defaultValues: { additionalFields: selectedFields } });

  return (
    <FormProvider {...methods}>
      {selectedFields?.map(({ additionalPropertyId }, idx) => (
        <InheritedProperty
          source={`additionalFields[${idx}]`}
          key={additionalPropertyId}
          id={additionalPropertyId}
          propertiesList={propertiesList}
        />
      ))}
    </FormProvider>
  );
};
