import { RequestAPI } from '@RestApi';
import { AbstractApi } from '@PluginBase';
import {
  PartialRefundRequest,
  PartialOrderRefundApiProps,
  OrderApiResponse,
  PartialRefundRequestItem,
} from './interface';
import { PartialRefundItem } from '../pages/interface';

export class PartialOrderRefundApi extends AbstractApi<
  PartialOrderRefundApiProps,
  OrderApiResponse
> {
  static partialRefundApiUrl = '/orders/v1/orders/refund/partial';

  constructor(order: CoreOrder, items: PartialRefundItem[]) {
    super(PartialOrderRefundApi.partialRefundApiUrl, {
      orderId: order.id,
      items,
      order,
    });
  }

  async execute(): Promise<OrderApiResponse> {
    const changedItems: Record<string, PartialRefundRequestItem> = {};

    this.params.items.forEach(
      ({
        sku,
        selectedProblemId,
        typedComment,
        paidPricePerUnit,
        resultRefund,
        selectedQuantity,
      }: PartialRefundItem) => {
        changedItems[sku] = {
          comment: typedComment,
          sku,
          price: paidPricePerUnit,
          problem_subtype: selectedProblemId,
          problem_type: 'other',
          qty: selectedQuantity,
          refund_amount: resultRefund,
        };
      }
    );

    const request: PartialRefundRequest = {
      isValid: true,
      items: Object.values(changedItems),
      order_id: this.params.orderId,
    };

    const response = await RequestAPI.post(this.apiUrl, { ...request });

    const newItemsList = this.params.order.items.map(
      (item: CoreProductItem) => {
        const { sku, refunded_quantity } = item;
        const refundedQuantity = refunded_quantity ?? 0;

        return {
          ...item,
          refunded_quantity: changedItems[sku]
            ? refundedQuantity + changedItems[sku].qty
            : refundedQuantity,
        };
      }
    );

    return {
      data: {
        ...this.params.order,
        items: newItemsList,
        payment: {
          ...this.params.order.payment,
          status: response.payment_status,
        },
      },
    };
  }
}
