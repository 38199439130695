import { Flex, RowInfoBlock } from '@UI';
import { Skeleton } from '@mui/material';

export const LoaderSkeleton = () => {
  const daysArray = new Array(7).fill(null).map((_, idx) => idx);

  return (
    <RowInfoBlock asColumn justifyContent="center" alignItems="center">
      <Flex
        asColumn={false}
        justifyContent="flex-start"
        alignItems="center"
        gap={2}
        sx={{ width: '100%', mb: 1 }}
      >
        <Skeleton variant="circular" sx={{ width: 30, height: 30 }} />
        <Skeleton variant="rounded" sx={{ width: 150, height: 30 }} />
      </Flex>
      {daysArray.map((idx) => (
        <Skeleton
          variant="rounded"
          sx={{ width: '100%', height: 54, mb: idx < 6 ? 0.5 : 0 }}
          key={idx}
        />
      ))}
    </RowInfoBlock>
  );
};
