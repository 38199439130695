import { ListColumn } from '@Widgets/ResourceList/interface';

export const STOCKS_LIST_COLUMNS: ListColumn<CoreStock>[] = [
  {
    source: 'sku',
    label: 'inventory.pages.stocks.labels.sku',
    type: 'text',
    sortable: false,
  },
  {
    source: 'name',
    label: 'inventory.pages.stocks.labels.name',
    type: 'text',
    sortable: false,
  },
  {
    source: 'warehouseCode',
    label: 'inventory.pages.stocks.labels.warehouseCode',
    type: 'text',
    sortable: false,
  },
  {
    source: 'availableQuantity',
    label: 'inventory.pages.stocks.labels.availableQuantity',
    type: 'number',
    sortable: false,
  },
  {
    source: 'reservedQuantity',
    label: 'inventory.pages.stocks.labels.reservedQuantity',
    type: 'number',
    sortable: false,
  },
  {
    source: 'sellableQuantity',
    label: 'inventory.pages.stocks.labels.sellableQuantity',
    type: 'number',
    sortable: false,
  },
  {
    source: 'updatedAt',
    label: 'inventory.pages.stocks.labels.updatedAt',
    type: 'date',
  },
];

export const STOCK_IMPORTS_LIST_COLUMNS: ListColumn<CoreStockImport>[] = [
  {
    source: 'id',
    label: 'inventory.pages.stockImports.labels.id',
    type: 'text',
    sortable: false,
  },
  {
    source: 'createdAt',
    label: 'inventory.pages.stockImports.labels.createdAt',
    type: 'date',
  },
  {
    // TODO: fix source in type definition
    // @ts-ignore
    source: 'rowsTotalCount',
    type: 'number',
    label: 'inventory.pages.stockImports.labels.rowsTotalCount',
    sortable: false,
  },
  {
    // TODO: fix source in type definition
    // @ts-ignore
    source: 'rowsErrorsCount',
    label: 'inventory.pages.stockImports.labels.errors',
    type: 'number',
    sortable: false,
  },
];
