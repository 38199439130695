import React, { ChangeEvent, useMemo } from 'react';
import { TimeInput, useTranslate } from 'react-admin';
import moment from 'moment';

import { Flex } from '@UI';
import { ScheduleDayWorkIntervalProps } from '@PluginManager/plugins/Stores/resources/Warehouses/interface';

import { TIME_DISPLAY_FORMAT } from './constants';

export const DayWorkInterval = (props: ScheduleDayWorkIntervalProps) => {
  const translate = useTranslate();
  const {
    onDayIntervalChanged,
    intervalIndex,
    dayId,
    timeInputFormat = TIME_DISPLAY_FORMAT,
    schedulesPath = 'schedules',
  } = props;

  const { startTimePath, endTimePath } = useMemo(() => {
    const index = dayId - 1;
    const basePath = `${schedulesPath}[${index}].intervals[${intervalIndex}]`;

    return {
      startTimePath: `${basePath}.start`,
      endTimePath: `${basePath}.end`,
    };
  }, [schedulesPath, intervalIndex, dayId]);

  const onChangeHandlerFactory =
    (intervalFieldName: 'start' | 'end') =>
    (e: ChangeEvent<HTMLInputElement>): void => {
      onDayIntervalChanged(
        dayId,
        intervalIndex,
        intervalFieldName,
        e.target.value
      );
    };

  const handleFormat = (value: string): string =>
    moment(value, timeInputFormat, true).format(TIME_DISPLAY_FORMAT);

  const handleParse = (value: string): string =>
    moment(value, TIME_DISPLAY_FORMAT, true).format(timeInputFormat);

  return (
    <React.Fragment key={dayId}>
      <Flex mr={2} flexGrow={1}>
        <TimeInput
          source={startTimePath}
          label={translate(
            'stores.warehouses.pages.tabs.schedule.labels.startTime'
          )}
          onChange={onChangeHandlerFactory('start')}
          format={handleFormat}
          parse={handleParse}
          fullWidth
          inputProps={{
            pattern: '[0-2][0-9]:[0-5][0-9]',
          }}
          required
        />
      </Flex>

      <Flex flexGrow={1}>
        <TimeInput
          source={endTimePath}
          label={translate(
            'stores.warehouses.pages.tabs.schedule.labels.endTime'
          )}
          onChange={onChangeHandlerFactory('end')}
          format={handleFormat}
          parse={handleParse}
          fullWidth
          inputProps={{
            pattern: '[0-2][0-9]:[0-5][0-9]',
          }}
          required
        />
      </Flex>
    </React.Fragment>
  );
};
