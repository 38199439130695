import { PluginImplementation } from '../../interface';
import { DEFAULT_TRANSLATION } from '../../constants';
import { SupportedLanguage } from "@ROOT/interface";

export class TranslationMerger {
  private _i18n: PluginImplementation['i18n'] = { ...DEFAULT_TRANSLATION };

  get i18n(): PluginImplementation['i18n'] {
    return this._i18n;
  }

  mergeI18N(pluginTranslation?: PluginImplementation['i18n']): void {
    if (!pluginTranslation) {
      return;
    }

    Object.keys(pluginTranslation).forEach((lang: string) => {
      const language = lang as SupportedLanguage;

      this._i18n[language] = { ...(this._i18n?.[language] ?? {}), ...pluginTranslation[language] };
    });
  }

  reset(): void {
    this._i18n = { ...DEFAULT_TRANSLATION };
  }
}
