import { D } from '@mobily/ts-belt';

import { RequestAPI } from '@RestApi';
import { CommonCrudOptions } from '@PluginManager/base/AbstractApi/CommonCrud';
import { errorHandler } from '@Helpers/ErrorHandler';

import { REFERRAL_PROGRAM_API } from './constants';
import { ResponseData } from './interfaces';

class ReferralProgramService {
  protected apiBase: string;
  protected errorHandler?: (error: Error) => void;

  constructor(apiBase: string, options?: CommonCrudOptions) {
    this.apiBase = apiBase;
    this.errorHandler = options?.errorHandler;
  }

  enableReferralProgram = async (
    data: Partial<Omit<CoreReferralProgramRemoteTemplate, 'id'>>,
    urlPart = 'template'
  ): Promise<ResponseData<CoreReferralProgramRemoteTemplate>> => {
    const url = this._buildUrl(urlPart);

    try {
      const response = await RequestAPI.post(url, this._sanitizeData(data));

      return {
        data: response,
      };
    } catch (error: unknown) {
      this._handleError(error);

      return {
        data: null,
      };
    }
  };

  getReferralProgram = async (
    urlPart = 'template'
  ): Promise<ResponseData<CoreReferralProgramRemoteTemplate>> => {
    const url = this._buildUrl(urlPart);

    try {
      const response = await RequestAPI.get(url);

      return {
        data: response,
      };
    } catch (error: unknown) {
      this._handleError(error);

      return {
        data: null,
      };
    }
  };

  updateReferralProgram = async (
    id: string,
    data: Partial<CoreReferralProgramRemoteTemplate>,
    urlPart = 'template'
  ): Promise<ResponseData<CoreReferralProgramRemoteTemplate>> => {
    const url = this._buildUrl(
      urlPart === 'template' ? id : `${urlPart}/${id}`
    );
    const instance = D.deleteKey(data, 'id');

    try {
      const response = await RequestAPI.patch(
        url,
        this._sanitizeData(instance)
      );

      return {
        data: response,
      };
    } catch (error: unknown) {
      this._handleError(error);

      return {
        data: null,
      };
    }
  };

  private readonly _buildUrl = (part: string): string => {
    if (this.apiBase.endsWith('/')) {
      this.apiBase = this.apiBase.substring(0, this.apiBase.length - 1);
    }

    return `${this.apiBase}/${part}`;
  };

  private readonly _handleError = (error: unknown) => {
    if (!this.errorHandler) {
      errorHandler(error);

      return;
    }

    this.errorHandler(error as Error);
  };

  private readonly _sanitizeData = (
    data: Partial<CoreReferralProgramRemoteTemplate>
  ): Partial<CoreReferralProgramRemoteTemplate> =>
    Object.entries(data).reduce<Partial<CoreReferralProgramRemoteTemplate>>(
      (memo, [key, value]) => {
        if (typeof value !== 'number' && typeof value !== 'boolean' && !value) {
          return memo;
        }

        return { ...memo, [key]: value };
      },
      {}
    );
}

export const referralProgramService = new ReferralProgramService(
  REFERRAL_PROGRAM_API
);
