import {
  useRecordContext,
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer,
} from 'react-admin';
import { Typography } from '@mui/material';

import { ResourceInputNumber } from '@UI';

import { SimpleFormIteratorStyled } from './styles';

export const AttachedFilters = () => {
  const record = useRecordContext<CoreCategory>();

  return (
    <>
      <ArrayInput name="filters" source="filters">
        <SimpleFormIteratorStyled<typeof SimpleFormIterator>
          inline
          disableAdd
          disableRemove
          disableClear
          disableReordering
          getItemLabel={(idx) =>
            `${record.filters[idx]?.filter?.additionalProperty?.name}`
          }
        >
          <FormDataConsumer>
            {({ getSource }) => {
              const source = getSource?.('sorting');

              if (!source) {
                return null;
              }

              return (
                <ResourceInputNumber isInteger name={source} source={source} />
              );
            }}
          </FormDataConsumer>
        </SimpleFormIteratorStyled>
      </ArrayInput>

      {!record?.filters?.length && (
        <Typography variant="body1">
          There are no filters selected in this category yet
        </Typography>
      )}
    </>
  );
};
