import { useContext } from 'react';

import { StateContext } from './Provider';

export const useScheduleDispatch = () => {
  const { dispatch } = useContext(StateContext);

  return dispatch;
};

export const useScheduleState = () => {
  const { state } = useContext(StateContext);

  return state;
};
