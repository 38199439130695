import {
  AutocompleteInput,
  Confirm,
  DateField,
  useGetManyReference,
  useRecordContext,
  useTranslate,
  useUpdate,
} from 'react-admin';
import { Flex, Loader, UIDialog } from '@UI';
import { Button, Tab, Tabs, Typography } from '@mui/material';
import { ApiActionCall, PickingOrderTab } from './constants';
import { useCheckMutationAnswer } from '@Hooks/useCheckMutationAnswer';
import { pickingOrdersTranslationSchema } from '../../constants';
import { General } from './Tabs/General';
import { Items } from './Tabs/Items';
import React, { MouseEventHandler, SyntheticEvent, useState } from 'react';
import { RESOURCES_SCHEMA } from '../../schemas/resourcesSchema';
import { useResourceContext } from 'ra-core';
import { GetManyReferenceMeta } from '../../interface';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { getPickerSelectOption } from '../../dataGenerator';
import { accessPermission } from '@Helpers';

interface EditDialogProps {
  onClose: MouseEventHandler;
  onConfirm: (val: string) => void;
  isOpen?: boolean;
}

const pickerId = 'picker';

const EditDialog = ({
  isOpen = false,
  onConfirm,
  onClose,
}: EditDialogProps) => {
  const translate = useTranslate();
  const { setValue, getValues } = useFormContext();

  const onDialogConfirm = () => {
    const chosenPicker = getValues(pickerId);

    if (!chosenPicker) {
      return;
    }

    onConfirm(chosenPicker);
  };

  const EditDialogContent = () => {
    const resource = useResourceContext();
    const { id, picker: currentPicker } =
      useRecordContext<CorePickingOrderDto>();
    const currentPickerOption = currentPicker
      ? getPickerSelectOption(
          currentPicker.id,
          currentPicker.firstName,
          currentPicker.lastName
        )
      : null;
    const meta: GetManyReferenceMeta = {
      type: 'getPickers',
      currentPickerOption,
    };
    const { isLoading, data: pickers = [currentPickerOption] } =
      useGetManyReference<PickerDto>(resource, {
        id: id,
        target: 'id',
        meta,
      });
    const currentPickerVal = getValues(pickerId) ?? currentPicker?.id;

    setValue(pickerId, currentPickerVal);

    return (
      <>
        <AutocompleteInput
          label={translate(pickingOrdersTranslationSchema.labels.picker)}
          id={pickerId}
          name={pickerId}
          translateChoice
          fullWidth
          isRequired
          isLoading={isLoading}
          choices={pickers}
        />
      </>
    );
  };

  return (
    <Confirm
      isOpen={isOpen}
      title={translate(pickingOrdersTranslationSchema.labels.editOrder)}
      content={<EditDialogContent />}
      onConfirm={onDialogConfirm}
      onClose={onClose}
      fullWidth
    />
  );
};

export const PickingOrderDetails = () => {
  const resource = useResourceContext();
  const record = useRecordContext<CorePickingOrderDto>();
  const formMethods = useForm();
  const translate = useTranslate();
  const [
    updateByFinishOrder,
    {
      isLoading: isLoadingFinishOrder,
      isSuccess: isSuccessFinishOrder,
      error: errorFinishOrder,
    },
  ] = useUpdate();
  const [
    updateByChangePicker,
    { isSuccess: isSuccessChangePicker, error: errorChangePicker },
  ] = useUpdate();
  const [currentTab, setCurrentTab] = useState(0);
  const [isFinishOrderDialogOpen, setIsFinishOrderDialogOpen] = useState(false);
  const [isEditOrderDialogOpen, setIsEditOrderDialogOpen] = useState(false);
  const onChangeTabHandler = (event: SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  useCheckMutationAnswer({
    successMessage: pickingOrdersTranslationSchema.messages.finishOrderSuccess,
    errorMessage: pickingOrdersTranslationSchema.errors.finishOrderError,
    error: errorFinishOrder as Error,
    isSuccess: isSuccessFinishOrder,
    shouldUpdateList: true,
  });

  useCheckMutationAnswer({
    successMessage: pickingOrdersTranslationSchema.messages.pickerChangeSuccess,
    errorMessage: pickingOrdersTranslationSchema.errors.pickerChangeError,
    error: errorChangePicker as Error,
    isSuccess: isSuccessChangePicker,
    shouldUpdateList: true,
  });

  if (!record || isLoadingFinishOrder) return <Loader />;

  const onConfirmEditOrderDialogHandler = (val: string) => {
    setIsEditOrderDialogOpen(false);

    updateByChangePicker(resource, {
      id: record?.id,
      meta: ApiActionCall.ChangePicker,
      previousData: record,
      data: {
        pickerId: val,
      },
    }).catch(console.error);
  };

  const onCloseEditOrderDialogHandler = () => {
    setIsEditOrderDialogOpen(false);
  };

  const onFinishOrderClickHandler = () => {
    setIsFinishOrderDialogOpen(true);
  };

  const onEditOrderClickHandler = () => {
    setIsEditOrderDialogOpen(true);
  };

  const onCloseFinishOrderDialogHandler = () => {
    setIsFinishOrderDialogOpen(false);
  };

  const onConfirmFinishOrderDialogHandler = () => {
    setIsFinishOrderDialogOpen(false);

    updateByFinishOrder(resource, {
      id: record?.id,
      meta: ApiActionCall.FinishOrder,
      previousData: record,
    }).catch(console.error);
  };

  const editOrderButton = accessPermission.checkPermission(
    'WMSPicking',
    'U'
  ) ? (
    <Flex mt={1}>
      <Button
        size="small"
        variant="contained"
        color="primary"
        disabled={record.status === 'finished'}
        onClick={onEditOrderClickHandler}
      >
        {translate(pickingOrdersTranslationSchema.actions.editOrder)}
      </Button>
    </Flex>
  ) : null;

  return (
    <Flex asColumn maxWidth={900} width="95vw">
      <FormProvider {...formMethods}>
        <Flex
          padding={1}
          pt={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex asColumn>
            <Typography variant="h5">
              {translate(pickingOrdersTranslationSchema.labels.order, {
                orderId: record.orderId,
              })}
            </Typography>

            <Typography variant="subtitle2">
              {`${translate(
                pickingOrdersTranslationSchema.labels.createdAt
              )}: `}

              <DateField source={RESOURCES_SCHEMA.createdAt} />
            </Typography>
          </Flex>

          <Flex asColumn alignItems="end">
            <Button
              size="small"
              variant="contained"
              color="error"
              disabled={record.status === 'finished'}
              onClick={onFinishOrderClickHandler}
            >
              {translate(pickingOrdersTranslationSchema.actions.finishOrder)}
            </Button>

            {editOrderButton}
          </Flex>
        </Flex>

        <Tabs
          value={currentTab}
          onChange={onChangeTabHandler}
          variant="scrollable"
        >
          <Tab
            label={translate(pickingOrdersTranslationSchema.lists.tabs.general)}
          />

          <Tab
            label={translate(pickingOrdersTranslationSchema.lists.tabs.items)}
          />
        </Tabs>

        <Flex asColumn padding={1}>
          {currentTab === PickingOrderTab.General && <General />}

          {currentTab === PickingOrderTab.Items && <Items />}
        </Flex>

        <UIDialog
          open={isFinishOrderDialogOpen}
          onClose={onCloseFinishOrderDialogHandler}
          title={translate(pickingOrdersTranslationSchema.actions.finishOrder)}
          controls={
            <Button onClick={onConfirmFinishOrderDialogHandler}>
              {translate(
                pickingOrdersTranslationSchema.actions.finishOrderButton
              )}
            </Button>
          }
        >
          {translate(
            pickingOrdersTranslationSchema.actions.finishOrderDescription,
            {
              id: record.orderId,
            }
          )}
        </UIDialog>

        <EditDialog
          isOpen={isEditOrderDialogOpen}
          onConfirm={onConfirmEditOrderDialogHandler}
          onClose={onCloseEditOrderDialogHandler}
        />
      </FormProvider>
    </Flex>
  );
};
