import { useTranslate } from 'react-admin';

import { ResourceList } from '@Widgets/ResourceList/ResourceList';
import { ResourceRoutes } from '@Plugins/resourceRoutes';

import { OFFER_EXPORTS_COLUMNS } from './constants';
import { OfferExportStatus } from '@Plugins/Catalogue/resources/OfferOperations/tabs/OfferExports/interfaces';

const OfferExports = () => {
  const translate = useTranslate();

  return (
    <ResourceList<CoreOfferExport>
      resource={ResourceRoutes.offerExports.resourcePath}
      columns={OFFER_EXPORTS_COLUMNS}
      actions={false}
      showDeleteButton={false}
      getItemCardCaption={(record) =>
        translate('catalogue.pages.offerOperations.labels.mobileCardCaption', {
          id: record.id,
        })
      }
      defaultSort={{
        field: 'createdAt',
        order: 'DESC',
      }}
      rowClick={() => false}
      perPage={10}
      isInteractive={false}
      queryOptions={{
        refetchIntervalInBackground: false,
        refetchInterval: (data) => {
          const processingItems = data?.data.some((item) =>
            [OfferExportStatus.IN_PROGRESS, OfferExportStatus.CREATED].includes(
              item.status
            )
          );

          if (!processingItems) {
            return false;
          }

          return 5000;
        },
      }}
    />
  );
};

export default OfferExports;
