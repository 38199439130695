import { ActionPayload, ChangeHandler } from './interfaces';

export const combineChanges = <State, Payload = void>(
  changes: ChangeHandler<State, Payload>[],
  curState: State,
  action: ActionPayload<Payload>
): State =>
  changes.reduce(
    (state: State, reducer: ChangeHandler<State, Payload>): State =>
      reducer(state, action),
    curState
  );

export default combineChanges;
