import { CommonCrud, PluginResourceController } from '@PluginBase';
import { ResourceRoutes } from '../../../resourceRoutes';
import { GetListResult, UpdateManyResult } from 'react-admin';
import { ListPage } from './ListPage';
import { Icon } from '@UI';
import { apiUrl } from './constants';
import { UPDATE_LIST_BY_FILE_UPLOAD } from '@ROOT/constants';

const stocksCrud = new CommonCrud(apiUrl);

stocksCrud.isNewSorting = true;

const StocksController = new PluginResourceController({
  menuItem: {
    caption: {
      translationKey: 'inventory.pages.stocks.caption',
    },
    route: ResourceRoutes.stocks.routePath,
    icon: <Icon type="stocks" />,
  },
  resourceRoute: {
    name: ResourceRoutes.stocks.resourcePath,
    list: ListPage,
  },

  dataProvider: {
    getList: async (resource, params): Promise<GetListResult> => {
      return stocksCrud.list(params);
    },
    updateMany: async (resource, params): Promise<UpdateManyResult> => {
      if (params.meta === UPDATE_LIST_BY_FILE_UPLOAD) {
        await stocksCrud.uploadFile(params);

        return {
          data: [],
        };
      }

      return {
        data: [],
      };
    },
  },
});

export default StocksController;
