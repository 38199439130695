import { number, required, Validator } from 'react-admin';
import { isInteger, minNum } from '@Helpers';

export const getValidation = (
  type: CorePropertyType,
  code: string
): Validator[] => {
  const validators: Validator[] = [required()];

  if (type === 'toggle') return [];

  if (type === 'number') {
    validators.push(number(), minNum(0));
  }

  if (code === 'items_per_outer_retail_pack') {
    validators.push(isInteger());
  }

  return validators;
};

export const getDefaultValueByType = (type: CorePropertyType) => {
  switch (type) {
    case 'number':
      return '';
    case 'file':
    case 'image':
      return null;
    case 'sizeList':
    case 'tagList':
    case 'radioGroup':
    case 'checkboxGroup':
    case 'colorList':
      return [];
    case 'toggle':
      return true;
    default:
      return '';
  }
};
