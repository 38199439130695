import { Multiplier } from '@Widgets/Multiplier/Multiplier';
import { useTranslate } from 'react-admin';
import { useController } from 'react-hook-form';
import { useState, ChangeEvent } from 'react';
import { TextField, Typography, Box } from '@mui/material';
import { Flex } from '@UI';
import { ShippingMultiplierProps } from '../../../../../../interface';

export const ShippingMultiplier = (props: ShippingMultiplierProps) => {
  const { name, addButtonTitleKey, titleKey } = props;
  const translate = useTranslate();
  const controller = useController({ name });
  const { value } = controller.field;
  const shippingMultiplierList = Array.isArray(value) ? value : [];
  const [fieldValue, setFieldValue] = useState<DeliveryMultiplierItem[]>(
    shippingMultiplierList || []
  );

  function onAddNewMultiplierHandler() {
    const newElement: DeliveryMultiplierItem = {
      load_ratio: 0,
      multiplier: 0,
      id: (fieldValue.length + 1).toString(),
    };
    const newMultiplier = [...fieldValue, newElement];

    controller.field.onChange(newMultiplier);
    setFieldValue(newMultiplier);
  }

  function onRemoveMultiplierHandler(blockId: string) {
    const newMultiplier = fieldValue.filter((field) => field.id !== blockId);

    controller.field.onChange(newMultiplier);
    setFieldValue(newMultiplier);
  }

  function onUpdateMultiplierHandler(event: ChangeEvent<HTMLInputElement>) {
    const { id: blockId, name, value } = event.currentTarget;

    const newMultiplierIndex = fieldValue.findIndex(({ id }) => id === blockId);
    const newMultiplier = [...fieldValue];

    newMultiplier[newMultiplierIndex] = {
      ...newMultiplier[newMultiplierIndex],
      [name]: value,
    };

    controller.field.onChange(newMultiplier);
    setFieldValue(newMultiplier);
  }

  return (
    <>
      <Typography>{translate(titleKey)}</Typography>

      <Multiplier
        addBlockTitle={translate(addButtonTitleKey)}
        onRemoveBlock={onRemoveMultiplierHandler}
        onAddBlock={onAddNewMultiplierHandler}
        value={fieldValue.map(({ id, multiplier, load_ratio }) => ({
          id,
          element: (
            <Flex key={id}>
              <TextField
                label={translate(
                  'stores.warehouses.pages.tabs.deliveryArea.drawer.label.warehouseLoadRatio'
                )}
                value={load_ratio}
                id={id}
                name="load_ratio"
                onChange={onUpdateMultiplierHandler}
              />

              <Box px={1} />

              <TextField
                label={translate(
                  'stores.warehouses.pages.tabs.deliveryArea.drawer.label.multiplier'
                )}
                value={multiplier}
                id={id}
                name="multiplier"
                onChange={onUpdateMultiplierHandler}
              />
            </Flex>
          ),
        }))}
      />
    </>
  );
};
