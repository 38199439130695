import styled from 'styled-components';
import { TableRow, TableCell } from '@mui/material';

export const TableRowStyled = styled(TableRow)<{ $isActive: boolean }>`
  cursor: pointer;

  ${({ $isActive }) => `background-color: ${$isActive ? '#263534' : '#242d37'}`}
`;

export const IndexTableCellStyled = styled(TableCell)<{
  $level: number;
  $indexInGroup: number;
  $isExpandable: boolean;
  $dir: 'ltr' | 'rtl';
}>`
  position: relative;
  ${({ $dir }) => ($dir === 'ltr' ? 'padding-left' : 'padding-right')}: ${({
    $level,
  }) => ($level ? 15 * $level + 10 : 10)}px !important;

  ${({ $level, $indexInGroup, $isExpandable, $dir }) =>
    $level
      ? `
        &:before {
          content: '';
          border-${$dir === 'ltr' ? 'left' : 'right'}: 1px solid;
          border-bottom: 1px solid;
          display: block;
          position: absolute;
          pointer-events: none;
          bottom: 50%;
          top: ${$indexInGroup * -32 - 12}px;
          width: ${$isExpandable ? 7 : 21}px;
          ${$dir === 'ltr' ? 'left' : 'right'}: ${
          $level === 1 ? 24 : $level * 15 + 9
        }px;
        }
      `
      : ''}
`;
