import { CommonCrud } from '@PluginManager/base/AbstractApi/CommonCrud';
import { RequestAPI } from '@RestApi';

export class ReplenishmentCrud extends CommonCrud {
  async uploadFile_(url: string, file: File) {
    const formData = new FormData();

    formData.append('file', file, 'file_name');

    try {
      const response = await RequestAPI.post(url, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      return response.data;
    } catch (e) {
      this.handleError(e as Error);
    }
  }
}
