import { useEffect, useState } from 'react';
import {
  Datagrid,
  ListContextProvider,
  Pagination,
  SaveButton,
  SimpleForm,
  Toolbar,
  useCreate,
  useGetManyReference,
  useList,
  useNotify,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import { FieldValues, useController, useFormContext } from 'react-hook-form';
import { Identifier, SortPayload } from 'ra-core';
import { useLocation } from 'react-router';
import { Button, CircularProgress } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { Flex, Loader } from '@UI';
import ThemedDrawer from '@UI/ThemedDrawer/ThemedDrawer';
import { ResourceRoutes } from '@PluginManager/plugins/resourceRoutes';
import { ResourceMeta } from '@Plugins/Stores/resources/Warehouses/constants';
import { useCheckMutationAnswer } from '@Hooks/useCheckMutationAnswer';
import { ComponentFactory } from '@Widgets/ResourceList/ComponentFactory/ComponentFactory';

import { DELIVERY_AREA_DEFAULT_FIELDS } from './DeliveryAreaForm/constants';
import { DeliveryAreaForm } from './DeliveryAreaForm/DeliveryAreaForm';
import { TabName } from './DeliveryAreaForm/interface';
import { DeliveryUpdateForm } from './DeliveryUpdateForm';
import { DELIVERY_AREA_COLUMNS, PER_PAGE } from './constants';
import { accessPermission } from '@Helpers';

export const DeliveryAreas = () => {
  const record = useRecordContext<CoreWarehouse>();
  const translate = useTranslate();
  const location = useLocation();
  const { clearErrors } = useFormContext();
  const [openDrawerType, setOpenDrawerType] =
    useState<Nullable<'create' | 'edit' | 'add'>>(null);
  const [editElement, setEditElement] = useState('');
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(PER_PAGE);
  const [sort, setSort] = useState<SortPayload>({
    field: 'shipping_method_id',
    order: 'ASC',
  });
  const [createDeliveryArea, { isLoading, isSuccess, error }] = useCreate();
  const {
    data = [],
    total = 0,
    isFetching,
    isLoading: isListLoading,
    refetch,
  } = useGetManyReference(ResourceRoutes.warehouse.resourcePath, {
    meta: ResourceMeta.DeliveryArea,
    target: 'deliveryArea',
    id: record.id,
    sort,
    pagination: {
      page,
      perPage,
    },
  });
  const listContext = useList({
    isFetching,
    isLoading: isListLoading,
    data,
    page,
    perPage,
  });
  const {
    field: { value: locationValue },
  } = useController({ name: 'location' });
  const notify = useNotify();
  const onRowClickHandler = (id: Identifier) => {
    setEditElement(id.toString());
    setOpenDrawerType('edit');

    return location.pathname;
  };
  const onCreateClickHandler = () => {
    if (isLocationEmpty && accessPermission.company?.isWarehouseWithoutOdoo) {
      notify('stores.warehouses.pages.errors.locationRequiredToCreateZones', {
        type: 'error',
      });

      return;
    }

    setOpenDrawerType('create');
  };
  const onCloseDrawerHandle = () => setOpenDrawerType(null);
  const onItemRemovedHandle = () => {
    onCloseDrawerHandle();

    refetch().catch(() => undefined);
  };
  const onCreateDeliveryArea = (data: FieldValues) => {
    delete data.existingAreas;
    delete data.deliverySchedule;

    onCloseDrawerHandle();

    if (!data.use_custom_schedule) data.schedule = [];

    createDeliveryArea(ResourceRoutes.warehouse.resourcePath, {
      data: {
        ...data,
        warehouse_id: record.id,
        timezone: record.timezone,
      },
      meta: ResourceMeta.DeliveryArea,
    })?.catch(() => undefined);
  };

  useCheckMutationAnswer({
    isSuccess,
    successMessage: 'ra.notification.created',
    error: error as Error,
    errorMessage: 'ra.message.error',
  });

  useEffect(() => {
    if (isSuccess) {
      refetch().catch(() => undefined);
      clearErrors('isActive');
    }
  }, [isSuccess]);

  const isLocationEmpty =
    !locationValue?.address ||
    !locationValue?.latitude ||
    !locationValue?.longitude;

  return (
    <Flex fullWidth asColumn>
      {!record || isLoading ? (
        <Flex justifyContent="center" fullWidth paddingY={2}>
          <CircularProgress data-testid="spinner" />
        </Flex>
      ) : (
        <>
          <Flex justifyContent="right">
            <Button
              onClick={onCreateClickHandler}
              size="small"
              startIcon={<AddIcon />}
            >
              {translate(
                'stores.warehouses.pages.tabs.deliveryArea.buttons.create'
              )}
            </Button>
          </Flex>

          <ListContextProvider
            value={{
              ...listContext,
              total,
              setPage,
              data,
              setSort,
              sort,
              setPerPage,
            }}
          >
            <Datagrid
              sx={{ width: '100%' }}
              rowClick={onRowClickHandler}
              bulkActionButtons={false}
            >
              {DELIVERY_AREA_COLUMNS.map((column) => {
                const sortable = column.sortable ?? true;

                return (
                  <ComponentFactory
                    sortBy={sortable ? column.source : undefined}
                    key={column.source}
                    column={column}
                    label={column.label}
                  />
                );
              })}
            </Datagrid>

            {listContext.data?.length > 0 && <Pagination page={page} />}
          </ListContextProvider>

          <ThemedDrawer
            open={!!openDrawerType}
            anchor="right"
            onClose={onCloseDrawerHandle}
            PaperProps={{
              sx: { width: '40%' },
            }}
          >
            {openDrawerType === 'create' && (
              <SimpleForm
                toolbar={
                  <Toolbar>
                    <SaveButton disabled={isLoading} />
                  </Toolbar>
                }
                record={{
                  ...DELIVERY_AREA_DEFAULT_FIELDS,
                  existingAreas: data,
                }}
                onSubmit={onCreateDeliveryArea}
              >
                {isListLoading ? (
                  <Loader />
                ) : (
                  <DeliveryAreaForm
                    location={record.location}
                    timezone={record.timezone}
                    excludedTabs={[TabName.DeliverySchedule]}
                  />
                )}
              </SimpleForm>
            )}

            {openDrawerType === 'edit' && editElement && (
              <>
                {isListLoading ? (
                  <Loader />
                ) : (
                  <DeliveryUpdateForm
                    id={editElement}
                    onRemove={onItemRemovedHandle}
                    existingAreas={data}
                  />
                )}
              </>
            )}
          </ThemedDrawer>
        </>
      )}
    </Flex>
  );
};
