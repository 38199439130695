import axios, {
  AxiosRequestConfig,
  RawAxiosRequestHeaders,
  AxiosError,
} from 'axios';
import { REQUEST_TIMEOUT, MEDIA_REQUEST_TIMEOUT } from './config';
import { RequestAPIMethods } from './interface';
import { REFRESH_TOKEN_URL } from '../../authProvider/constants';
import authProvider from '../../authProvider/authProvider';
import { AuthorizationError } from '@PluginManager/base/AbstractApi/AuthorizationError';
import { API_URL } from '@ROOT/constants';

const axiosInstance = axios.create({
  baseURL: API_URL,
  timeout: REQUEST_TIMEOUT,
});

export type { AxiosErrorResponse } from './interface';

export const mergeConfig = (
  config?: AxiosRequestConfig
): AxiosRequestConfig => {
  const headers: RawAxiosRequestHeaders = {};

  if (window.localStorage.getItem('auth.accessToken')) {
    headers.Authorization = `Bearer ${window.localStorage.getItem(
      'auth.accessToken'
    )}`;
  } else {
    delete config?.headers?.Authorization;
    delete config?.headers?.authorization;
  }

  if (
    config?.headers?.['Content-Type'] === 'multipart/form-data' ||
    config?.responseType === 'blob'
  ) {
    config.timeout = MEDIA_REQUEST_TIMEOUT;
  }

  return Object.assign(config ?? {}, {
    headers: {
      ...(config?.headers ?? {}),
      ...headers,
    },
  });
};

export const RequestAPI: RequestAPIMethods = {
  get: (url, config) => axiosInstance.get(url, mergeConfig(config)),

  post: (url, data, config) =>
    axiosInstance.post(url, data, mergeConfig(config)),

  put: (url, data, config) => axiosInstance.put(url, data, mergeConfig(config)),

  patch: (url, data, config) =>
    axiosInstance.patch(url, data, mergeConfig(config)),

  delete: (url, config) => axiosInstance.delete(url, mergeConfig(config)),
};

export const refreshTokens = {
  isRefreshing: false,
  lastUpdateTimestamp: 0,
  reset: function () {
    this.isRefreshing = false;
    this.lastUpdateTimestamp = 0;
  },
  refresh: async function (error: AxiosError<CoreRestApiErrorResponse>) {
    const { response } = error;

    if (!response?.data) {
      return Promise.reject(error);
    }

    const { config, status, request } = response;

    if (request.responseType === 'blob' && response.data instanceof Blob) {
      response.data = JSON.parse((await response.data.text()) || '{}');
    }

    const { code = '' } = response.data;

    if (!config.url || status !== 401) {
      return Promise.reject(error);
    }

    const lastUpdateTimestampDelta =
      (Date.now() - this.lastUpdateTimestamp) / 1000;

    if (
      config.url.includes(REFRESH_TOKEN_URL) ||
      lastUpdateTimestampDelta < 60
    ) {
      return Promise.reject(
        new AuthorizationError({
          error: code,
          message: code,
          status,
        })
      );
    }

    if (!this.isRefreshing) {
      this.isRefreshing = true;

      try {
        await authProvider.refreshTokens();

        this.isRefreshing = false;
        this.lastUpdateTimestamp = Date.now();
      } catch (e) {
        this.isRefreshing = false;

        return Promise.reject(
          new AuthorizationError({
            error: code,
            message: code,
            status,
          })
        );
      }
    } else {
      await new Promise<void>((resolve) => {
        const interval = setInterval(() => {
          if (!this.isRefreshing) {
            clearInterval(interval);
            resolve();
          }
        }, 1000);
      });
    }

    if (!window.localStorage.getItem('auth.accessToken')) {
      return Promise.reject(
        new AuthorizationError({
          error: code,
          message: code,
          status,
        })
      );
    }

    return axiosInstance.request(mergeConfig(config));
  },
};

axiosInstance.interceptors.response.use(
  ({ data }) => data,
  (error) => refreshTokens.refresh(error)
);
