import { PluginName } from '@PluginManager/interface';
import { Company, CompanyName, RolesRules, CompanyLogo } from './interface';
import { ResourceRoutes } from '@PluginManager/plugins/resourceRoutes';

import { ReactComponent as CityDrinksLogo } from '../../assets/company-logos/citydrinks.svg';
import { ReactComponent as LocaleeLogo } from '../../assets/company-logos/localee.svg';
import { ReactComponent as CircleK } from '../../assets/company-logos/circlek.svg';
import { ReactComponent as ViloGelato } from '../../assets/company-logos/vilo.svg';
import { ReactComponent as OurKids } from '../../assets/company-logos/ourkids.svg';

export const PLUGIN_LIST: PluginName[] = [
  'Reports',
  'Orders',
  'Catalogue',
  'Promotions',
  'Stores',
  'Inventory',
  'WMSPicking',
  'DeliveryDispatcher',
  'DistributionCenter',
  'Replenishment',
  'Customers',
  'Settings',
];

export const ROLES_RULES: RolesRules = {
  admin: {
    Reports: {
      permission: 'CRUD',
      disallowPages: [],
      disallowActions: [],
    },
    Orders: {
      permission: 'CRUD',
      disallowPages: [],
      disallowActions: [],
    },
    Stores: {
      permission: 'CRUD',
      disallowPages: [],
      disallowActions: [],
    },
    Settings: {
      permission: 'CRUD',
      disallowPages: [],
      disallowActions: [],
    },
    Customers: {
      permission: 'CRUD',
      disallowPages: [],
      disallowActions: [],
    },
    Catalogue: {
      permission: 'CRUD',
      disallowPages: [],
      disallowActions: [],
    },
    DistributionCenter: {
      permission: 'CRUD',
      disallowPages: [],
      disallowActions: [],
    },
    Inventory: {
      permission: 'CRUD',
      disallowPages: [],
      disallowActions: [],
    },
    Promotions: {
      permission: 'CRUD',
      disallowPages: [],
      disallowActions: [],
    },
    WMSPicking: {
      permission: 'CRUD',
      disallowPages: [],
      disallowActions: [],
    },
    DeliveryDispatcher: {
      permission: 'CRUD',
      disallowPages: [],
      disallowActions: [],
    },
    Replenishment: {
      permission: 'CRUD',
      disallowPages: [],
      disallowActions: [],
    },
  },
  customer_support_specialist: {
    Orders: {
      permission: 'CRUD',
      disallowPages: [],
      disallowActions: [],
    },
    Customers: {
      permission: 'R',
      disallowPages: [],
      disallowActions: [],
    },
    Catalogue: {
      permission: 'R',
      disallowPages: [
        ResourceRoutes.catalog.routePath,
        ResourceRoutes.offerOperations.resourcePath,
      ],
      disallowActions: [],
    },
  },
  warehouse_manager: {
    Stores: {
      permission: 'CRU',
      disallowPages: [],
      disallowActions: [],
    },
    DistributionCenter: {
      permission: 'CRUD',
      disallowPages: [],
      disallowActions: [],
    },
    WMSPicking: {
      permission: 'CRUD',
      disallowPages: [],
      disallowActions: [],
    },
  },
  procurement_specialist: {
    Catalogue: {
      permission: 'CRUD',
      disallowPages: [],
      disallowActions: [],
    },
    Inventory: {
      permission: 'CRUD',
      disallowPages: [],
      disallowActions: [],
    },
  },
  marketer: {
    Promotions: {
      permission: 'CRUD',
      disallowPages: [],
      disallowActions: [],
    },
  },
  replenishment_specialist: {
    Replenishment: {
      permission: 'CRUD',
      disallowPages: [],
      disallowActions: [],
    },
  },
};

export const COMPANY: Company = {
  '3257e6a2-87f1-46fe-b498-72d6b78b379b': {
    name: CompanyName.TheCloudRetail,
    disallowPages: [],
    disallowPlugins: [],
  },
  'bb111efb-a9d8-465d-b9ca-8d255f1d8e9d': {
    name: CompanyName.Jiffy,
    disallowPlugins: [],
    disallowPages: [],
  },
  '0e715914-1b33-4387-92db-fe3103f865bb': {
    name: CompanyName.Baqal,
    disallowPlugins: ['Replenishment'],
    disallowPages: [
      ResourceRoutes.loyalty.resourcePath,
      ResourceRoutes.stockImports.resourcePath,
      ResourceRoutes.promotions.referral.resourcePath,
      ResourceRoutes.promotions.tapfilliate.resourcePath,
      ResourceRoutes.wmsPickingPriority.resourcePath,
    ],
  },
  '49589d9f-753a-421f-bd36-cea6202c4ba7': {
    name: CompanyName.Swifft,
    disallowPlugins: [
      'Reports',
      'DistributionCenter',
      'WMSPicking',
      'Replenishment',
      'DeliveryDispatcher',
    ],
    disallowPages: [
      ResourceRoutes.promotions.referral.resourcePath,
      ResourceRoutes.promotions.tapfilliate.resourcePath,
      ResourceRoutes.wmsPickingPriority.resourcePath,
    ],
  },
  'd05f30ba-ceb5-4633-a3b6-aa0f9463954f': {
    name: CompanyName.Localee,
    disallowPlugins: ['Replenishment'],
    disallowPages: [
      ResourceRoutes.promotions.tapfilliate.resourcePath,
      ResourceRoutes.wmsPickingPriority.resourcePath,
    ],
  },
  '1eb53a13-5f9e-4deb-92d7-090a4b53fd21': {
    name: CompanyName.CityDrinks,
    disallowPlugins: ['Inventory'],
    disallowPages: [ResourceRoutes.wmsPickingPriority.resourcePath],
  },
  '844a9e71-ed42-4ea9-a2c2-5a58b1db0a13': {
    name: CompanyName.CircleK,
    disallowPlugins: [],
    disallowPages: [],
  },
  'a1afcf22-db07-4c78-8e40-4ada483e1805': {
    name: CompanyName.ViloGelato,
    disallowPlugins: ['Replenishment'],
    disallowPages: [ResourceRoutes.promotions.tapfilliate.resourcePath],
  },
  'fca60c82-0022-49e4-aee6-5ec196acd111': {
    name: CompanyName.OurKids,
    disallowPlugins: ['Replenishment', 'DistributionCenter'],
    disallowPages: [],
  },
};

export const COMPANY_LOGOS: CompanyLogo = {
  [CompanyName.CityDrinks]: <CityDrinksLogo width={150} height={27} />,
  [CompanyName.Localee]: <LocaleeLogo width={96} height={27} />,
  [CompanyName.CircleK]: <CircleK width={96} height={27} />,
  [CompanyName.ViloGelato]: <ViloGelato width={50} height={27} />,
  [CompanyName.OurKids]: <OurKids height={30} />,
};
