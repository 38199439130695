import { Button, useListContext } from 'react-admin';

interface RemoveFromBlacklistActionProps {
  onRemove?: (ids: string[]) => void;
  label?: string;
}

const RemoveFromBlacklistAction = ({
  onRemove,
  label,
}: RemoveFromBlacklistActionProps): JSX.Element => {
  const { selectedIds } = useListContext();

  const handleClickRemoveFromBlacklist = () => {
    onRemove?.(selectedIds);
  };

  return (
    <Button
      label={label}
      sx={{ color: '#fff' }}
      size="medium"
      onClick={handleClickRemoveFromBlacklist}
    />
  );
};

export default RemoveFromBlacklistAction;
