import { useEffect, useState } from 'react';
import { Tab, useTheme } from '@mui/material';
import { useFormContext } from 'react-hook-form';

import { LocalizationTabProps } from './interfaces';

const LocalizationTab = ({
  value,
  fields = [],
  label,
  onChange,
}: LocalizationTabProps) => {
  const {
    formState: { errors, isValid },
  } = useFormContext();
  const [errorColor, setErrorColor] = useState<string | undefined>();
  const theme = useTheme();

  useEffect(() => {
    if (isValid) {
      setErrorColor(undefined);

      return;
    }

    let color: string | undefined;
    const properties = Object.keys(errors);

    for (const field of fields) {
      if (properties.includes(field)) {
        color = theme.palette.error.main;

        break;
      }
    }

    setErrorColor(color);
  }, [isValid, JSON.stringify(errors)]);

  return (
    <Tab
      onClick={() => onChange?.(value)}
      color="primary"
      label={label}
      value={value}
      sx={errorColor ? { color: `${errorColor} !important` } : undefined}
    />
  );
};

export default LocalizationTab;
