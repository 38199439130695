import { StatusBadgeStyled } from './styles';
import { useRecordContext, useTranslate } from 'react-admin';

export const StatusBadgeResource = () => {
  const record = useRecordContext();
  const translate = useTranslate();

  return (
    <StatusBadgeStyled
      fontSize={14}
      orderStatus={record.status}
      justifyContent="center"
    >
      {translate(`distributionCenter.statuses.${record.status}`)}
    </StatusBadgeStyled>
  );
};
