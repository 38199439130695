import { useRecordContext } from 'react-admin';
import { Typography } from '@mui/material';

export const ItemsCount = () => {
  const record = useRecordContext<CorePickingOrderDto>();

  return (
    <Typography fontSize={14}>{record.items?.length}</Typography>
  );
};
