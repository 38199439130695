import { CAGES_LIST_COLUMNS, PRODUCT_LIST_COLUMNS } from './constants';
import { Flex, Loader } from '@UI';
import {
  ListContextProvider,
  Datagrid,
  Pagination,
  useList,
  useRecordContext,
  useGetList,
} from 'react-admin';
import { ComponentFactory } from '@Widgets/ResourceList/ComponentFactory/ComponentFactory';
import { useState } from 'react';
import { ResourceRoutes } from '@Plugins/resourceRoutes';
import {
  CoreOutboundDemandOrderModified,
  PER_PAGE,
} from '../OutboundOrders/constants';

const ProductLines = () => {
  const { productLines } = useRecordContext();

  return (
    <Datagrid
      data={productLines}
      bulkActionButtons={false}
      sx={{
        margin: '-7px -16px -7px 20px',
        '& .RaDatagrid-rowCell': {
          width: '50%',
        },
      }}
      header={() => <></>}
    >
      {PRODUCT_LIST_COLUMNS.map((column) => (
        <ComponentFactory
          sortBy={column.sortable ?? true ? column.source : undefined}
          key={column.source}
          column={column}
          label={column.label}
        />
      ))}
    </Datagrid>
  );
};

export const CagesTab = () => {
  const { id } = useRecordContext<CorePickingOrder>();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(PER_PAGE);
  const {
    data = [],
    total = 0,
    isFetching,
    isLoading: isListLoading,
  } = useGetList<CoreOutboundDemandOrderModified>(
    ResourceRoutes.pickingOrders.resourcePath,
    {
      meta: {
        subPath: `/${id}/cages`,
      },
      pagination: {
        page,
        perPage,
      },
    }
  );
  const listContext = useList({
    isFetching,
    isLoading: isListLoading,
    data,
    page,
    perPage,
  });

  return (
    <>
      {isListLoading ? (
        <Flex sx={{ height: '80px' }}>
          <Loader fullHeight={false} />
        </Flex>
      ) : (
        <ListContextProvider
          value={{ ...listContext, total, setPage, data, setPerPage }}
        >
          <Datagrid
            sx={{
              width: '100%',
              '& .RaDatagrid-rowCell': {
                width: '50%',
              },
            }}
            bulkActionButtons={false}
            expand={<ProductLines />}
          >
            {CAGES_LIST_COLUMNS.map((column) => {
              const sortable = column.sortable ?? true;

              return (
                <ComponentFactory
                  sortBy={sortable ? column.source : undefined}
                  key={column.source}
                  column={column}
                  label={column.label}
                />
              );
            })}
          </Datagrid>

          {listContext.data?.length > 0 ? <Pagination page={page} /> : null}
        </ListContextProvider>
      )}
    </>
  );
};
