import { required } from 'react-admin';
import {
  isEmptyAfterTrimmed,
  maxLengthExt,
  minLengthExt,
  onlyLatin,
} from '@Helpers';

export const validateName = [
  required(),
  isEmptyAfterTrimmed(),
  onlyLatin(),
  maxLengthExt(128),
];

export const validateNameWithoutOdoo = [
  required(),
  isEmptyAfterTrimmed(),
  maxLengthExt(255),
];

export const validateCode = [
  required(),
  isEmptyAfterTrimmed(),
  onlyLatin(),
  minLengthExt(4),
  maxLengthExt(5),
];

export const validateCodeWithoutOdoo = [
  required(),
  isEmptyAfterTrimmed(),
  maxLengthExt(255),
];

export const validateTimezone = [required()];
