export const TRANSLATION_SCHEMA = {
  labels: {
    cashbackAmount: 'cashbackAmount',
    firstClientCashbackAmount: 'firstClientCashbackAmount',
    percentsPriceCanBePaidAmount: 'percentsPriceCanBePaidAmount',
  },
  captions: {
    desktop: 'desktop',
    mobile: 'mobile',
  },
  errors: {
    cantUpdateLoyaltyProgram: 'cantUpdateLoyaltyProgram',
  },
  messages: {
    loyaltyProgramUpdated: 'loyaltyProgramUpdated',
  },
};
