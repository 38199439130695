import { AspectRatio } from './interfaces';

export const gcd = (...arr: number[]): number => {
  const _gcd = (x: number, y: number) => (!y ? x : gcd(y, x % y));

  return [...arr].reduce((a, b) => _gcd(a, b));
};

export const getAspectRatio = (
  naturalWidth: number,
  naturalHeight: number
): AspectRatio => {
  const gcdResult = gcd(naturalWidth, naturalHeight);

  return {
    width: naturalWidth / gcdResult,
    height: naturalHeight / gcdResult,
    ratio: Number((naturalWidth / naturalHeight).toFixed(2)),
  };
};

export const getImageNaturalWidthHeight = (
  src: string
): Promise<Omit<AspectRatio, 'ratio'>> =>
  new Promise<Omit<AspectRatio, 'ratio'>>((resolve, reject) => {
    const image = document.createElement('img');

    image.src = src;

    image.onload = () => {
      const { naturalWidth, naturalHeight } = image;

      resolve({ width: naturalWidth, height: naturalHeight });
    };

    image.onerror = reject;
  });
