import {
  ZonePriority,
  GmapControlOptions,
  OnDrawingCompleteSignature,
} from '../interface';
import pluginManager from '@PluginManager/PluginManager';
import { DELIVERY_ZONES_STYLE } from '../constants';
import { Coords } from 'google-map-react';

export class ETACoder {
  private readonly _maps: GMapApi;
  private readonly _map: Gmap;
  private _drawingManagerInstance: Nullable<DrawingManager> = null;
  private _currentDrownElement: Nullable<GmapOverlay> = null;
  private _currentEditPolygonOptions: Nullable<GmapPolygonOptions> = null;
  private _onDrawingComplete: Nullable<OnDrawingCompleteSignature> = null;

  constructor(maps: GMapApi, map: Gmap) {
    maps.Polygon.prototype.getBounds = function () {
      const bounds = new maps.LatLngBounds();

      this.getPaths().forEach((p: google.maps.MVCArray<google.maps.LatLng>) => {
        p.forEach((element: google.maps.LatLng) => bounds.extend(element));
      });

      return bounds;
    };

    this._maps = maps;
    this._map = map;
  }

  private static getZoneStyle(color: string, priority?: number) {
    const result = {
      strokeWeight: 1,
      fillColor: color,
      strokeColor: color,
      fillOpacity: 1,
      strokeOpacity: 1,
    };

    return {
      ...result,
      ...(DELIVERY_ZONES_STYLE[priority as ZonePriority] ||
        DELIVERY_ZONES_STYLE[100]),
    };
  }

  private static async getDeliveryZones(): Promise<
    Nullable<CoreCoverageZone[]>
  > {
    try {
      const { data } = await pluginManager.dataProvider?.fetchDeliveryZones(
        '?page[size]=200&page[current]=1'
      );

      return data;
    } catch (e) {
      console.error(e);
    }

    return null;
  }

  private static getPolygonPoints(polygon: GmapPolygon) {
    const points = polygon.getPath().getArray();

    if (!points.length) {
      return [];
    }

    return points.map((getter) => ({
      lat: getter.lat(),
      lng: getter.lng(),
    }));
  }

  async drawZones() {
    try {
      const res = await ETACoder.getDeliveryZones();

      if (!res?.length) {
        return;
      }

      new Map(
        res
          .sort((a, b) => a.priority - b.priority)
          .map((zone) => [zone.name, zone])
      ).forEach((zone) => {
        const convert = this.flatArray(
          zone.points.map(({ longitude, latitude }) => ({
            lat: parseFloat(latitude),
            lng: parseFloat(longitude),
          }))
        );

        const drawPolygons = new this._maps.Polygon({
          paths: convert,
          ...ETACoder.getZoneStyle(zone.color, zone.priority),
        });

        drawPolygons.setMap(this._map);
      });
    } catch (error) {
      error && console.error(error);
    }
  }

  drawControls(
    drawOptions: GmapControlOptions,
    onDrawingComplete: OnDrawingCompleteSignature
  ): void {
    if (!drawOptions) return;

    const { strokeColor, types, priority, polygonPath } = drawOptions;

    if (!types?.length) return;

    this._onDrawingComplete = onDrawingComplete;

    this._currentEditPolygonOptions = {
      ...ETACoder.getZoneStyle(strokeColor, priority),
      strokeWeight: 3,
      clickable: false,
      editable: true,
      zIndex: 1,
    };

    const options: GmapDrawingManagerOptions = {
      drawingControl: true,
      drawingControlOptions: {
        position: 1.0,
        drawingModes: types as GmapOverlayType,
      },
      markerOptions: {
        visible: false,
      },
      polygonOptions: this._currentEditPolygonOptions,
    };

    if (this._drawingManagerInstance) {
      this._drawingManagerInstance.setOptions(options);
    } else {
      this._drawingManagerInstance = new this._maps.drawing.DrawingManager(
        options
      );

      this._drawingManagerInstance?.setMap(this._map);

      if (types.includes('marker')) {
        this.subscribeMarkerChanges();
      }

      if (types.includes('polygon')) {
        this.subscribeDrawingManagerPolygonChanges();
      }

      if (types.length) {
        this.resetPreviousShapeOverlay();
      }

      if (polygonPath?.length) {
        this.drawEditablePolygon(polygonPath);
      }
    }
  }

  setCenter(center: Coords) {
    if (
      !this._map ||
      !center ||
      center.lat === undefined ||
      center.lng === undefined
    )
      return;
    this._map.setCenter(center);
  }

  private subscribeMarkerChanges() {
    this._drawingManagerInstance?.addListener(
      'markercomplete',
      (e: GmapMarker) => {
        this._onDrawingComplete?.({
          coords: [
            {
              lat: e.getPosition()?.lat() ?? 0,
              lng: e.getPosition()?.lng() ?? 0,
            },
          ],
          type: 'marker',
        });

        this._drawingManagerInstance?.setDrawingMode(null);
      }
    );
  }

  private subscribeDrawingManagerPolygonChanges() {
    this._drawingManagerInstance?.addListener(
      'polygoncomplete',
      (event: GmapPolygon) => {
        this.subscribePolygonUpdate(event);
        this._onDrawingComplete?.({
          coords: ETACoder.getPolygonPoints(event),
          type: 'polygon',
        });

        this._drawingManagerInstance?.setDrawingMode(null);
      }
    );
  }

  private resetPreviousShapeOverlay() {
    this._drawingManagerInstance?.addListener(
      'overlaycomplete',
      (event: GmapOverlayCompleteEvent) => {
        this._currentDrownElement?.setMap(null);
        this._currentDrownElement = event.overlay;
      }
    );
  }

  private drawEditablePolygon(points: Coords[]) {
    const drawPolygons = new this._maps.Polygon({
      paths: points,
      ...this._currentEditPolygonOptions,
    });

    this._currentDrownElement = drawPolygons;
    this.subscribePolygonUpdate(drawPolygons);

    drawPolygons.setMap(this._map);

    this._map.fitBounds(drawPolygons.getBounds());
  }

  private subscribePolygonUpdate(polygon: GmapPolygon) {
    const onPolygonChanged = () => {
      this._onDrawingComplete?.({
        coords: ETACoder.getPolygonPoints(polygon),
        type: 'polygon',
      });
    };

    this._maps.event.addListener(polygon.getPath(), 'set_at', onPolygonChanged);
    this._maps.event.addListener(
      polygon.getPath(),
      'insert_at',
      onPolygonChanged
    );
  }

  private flatArray<T>(arr: T[], depth = 1): T[] {
    return depth > 0
      ? arr.reduce(
          (acc, val) =>
            acc.concat(
              ...(Array.isArray(val) ? this.flatArray(val, depth - 1) : [val])
            ),
          []
        )
      : arr.slice();
  }
}
