import { Schema } from 'type-fest';

// TODO: remove all @ts-ignore
export const RESOURCES_SCHEMA = {
  id: 'id',
  code: 'code',
  name: 'name',
  email: 'email',
  value: 'value',
  default: 'default',
  // @ts-ignore
  dateRange: {
    rangeStart: 'dateRange.0',
    rangeEnd: 'dateRange.1',
  },
  isActive: 'isActive',
  minimumPurchase: 'minimumPurchase',
  minimumQuantity: 'minimumQuantity',
  // @ts-ignore
  orderNumberRange: {
    rangeStart: 'orderNumberRange.0',
    rangeEnd: 'orderNumberRange.1',
  },
  filters: {
    all: 'filters',
    // @ts-ignore
    inCategory: 'filters.inCategory',
    // @ts-ignore
    inProduct: 'filters.inProduct',
    // @ts-ignore
    forCustomers: 'filters.forCustomers',
    // @ts-ignore
    forSegments: 'filters.forSegments',
  },
  type: 'type',
  usageCurrent: 'usageCurrent',
  usageLimitAll: 'usageLimitAll',
  usageLimitCustomer: 'usageLimitCustomer',
  minRequirementsChoice: 'minRequirementsChoice',
  applyTo: 'applyTo',
  customerEligibility: 'customerEligibility',
  applyToDiscountedItems: 'applyToDiscountedItems',
  // @ts-ignore
  customerBuysAnyItemsFrom: 'customerBuysAnyItemsFrom',
  productDiscountQuantity: 'productDiscountQuantity',
  customerGetAnyItemsFrom: 'customerGetAnyItemsFrom',
  maximumUsageNumberPerOrder: 'maximumUsageNumberPerOrder',
  discountTargets: {
    all: 'discountTargets',
    // @ts-ignore
    inCategory: 'discountTargets.inCategory',
    // @ts-ignore
    inProduct: 'discountTargets.inProduct',
  },
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
} satisfies Schema<CorePromocode, keyof CorePromocode>;
