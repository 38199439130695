import { EditButton, DeleteWithConfirmButton } from 'react-admin';
import { PropsWithChildren } from 'react';
import { Card, CardHeader, CardContent } from '@mui/material';
import { Flex, TruncatedText } from '@UI';
import LaunchIcon from '@mui/icons-material/Launch';
import { MobileGridCardProps } from './interface';

export const MobileGridCard = (props: PropsWithChildren<MobileGridCardProps>) => {
  const {
    caption,
    children,
    showDeleteButton,
    isInteractive = true,
    rowClick,
    record,
    resource,
  } = props;

  const onClickHandler = () => {
    if (typeof rowClick === 'string' || typeof rowClick === 'boolean') {
      return;
    }

    rowClick?.(record.id, `${resource}`, record);
  }

  return (
    <Card
      sx={{
        marginBottom: '4px',
      }}
    >
      <CardHeader
        title={
          <Flex justifyContent='space-between' alignItems='center' pl={1}>
            <TruncatedText variant='body1' lines={2} width={10} flex={10} title={caption}>
              {caption}
            </TruncatedText>

            <Flex flexGrow={1} justifyContent='right'>
              {isInteractive && (
                <EditButton
                  onClick={onClickHandler}
                  variant='contained'
                  icon={<LaunchIcon />}
                  label='ra.action.open'
                />
              )}

              {showDeleteButton && <DeleteWithConfirmButton />}
            </Flex>
          </Flex>
        }
      />

      <CardContent sx={{ pt: 0 }}>{children}</CardContent>
    </Card>
  );
};
