import { Flex, RowInfoBlock } from '@UI';
import { required, TextInput, useTranslate } from 'react-admin';
import { Button } from '@mui/material';
import { promocodesTranslationNamespace } from '../../../constants';
import { generate } from 'voucher-code-generator';
import { useController } from 'react-hook-form';
import { RESOURCES_SCHEMA } from '../../../schemas/resourcesSchema';
import {
  isEmptyAfterTrimmed,
  maxLengthExt,
  minLengthExt,
  onlyLatin,
  withoutSpaces,
} from '@Helpers';

const validateCode = [
  required(),
  isEmptyAfterTrimmed(),
  onlyLatin(),
  withoutSpaces(),
  minLengthExt(2),
  maxLengthExt(20),
];

interface CodeTypeBlockProps {
  hasAffiliate?: boolean;
  showGenerateCode?: boolean;
}

export const CodeTypeBlock = (props: CodeTypeBlockProps) => {
  const { hasAffiliate = false, showGenerateCode = true } = props;
  const translate = useTranslate();
  const { field: fieldCode } = useController({ name: 'code' });
  const onGeneratePromocodeName = () =>
    fieldCode.onChange(generate({ length: 8, count: 5 })[0]);

  return (
    <RowInfoBlock
      asColumn
      flex={1}
      justifyContent="flex-start"
      title={`${translate(
        promocodesTranslationNamespace.lists.discountCode.caption
      )} (${translate(
        promocodesTranslationNamespace.lists.discountCode.description
      )})`}
    >
      <TextInput
        source={RESOURCES_SCHEMA.code}
        name={RESOURCES_SCHEMA.code}
        fullWidth
        label={promocodesTranslationNamespace.labels.name}
        validate={validateCode}
        disabled={hasAffiliate && !showGenerateCode}
      />

      {showGenerateCode && (
        <Flex justifyContent="right">
          <Button onClick={onGeneratePromocodeName} size="small">
            {translate(promocodesTranslationNamespace.actions.generateCode)}
          </Button>
        </Flex>
      )}

      {hasAffiliate && (
        <>
          <TextInput
            source={RESOURCES_SCHEMA.name}
            name={RESOURCES_SCHEMA.name}
            fullWidth
            label={promocodesTranslationNamespace.labels.nameActual}
            disabled
          />
          <TextInput
            source={RESOURCES_SCHEMA.email}
            name={RESOURCES_SCHEMA.email}
            fullWidth
            label={promocodesTranslationNamespace.labels.email}
            disabled
          />
        </>
      )}
    </RowInfoBlock>
  );
};
