import { useLocaleState, useRecordContext } from 'react-admin';

const PropertyNameColumn = () => {
  const [locale] = useLocaleState();
  const record = useRecordContext<CoreProperty>();

  const arabianLocale = record.locales?.find((item) => item.language === 'ar');

  return (
    <>
      {locale === 'ar' && arabianLocale?.name
        ? arabianLocale?.name
        : record.name}
    </>
  );
};

export default PropertyNameColumn;
