import { RowInfoBlock, Flex } from '@UI';
import { Typography } from '@mui/material';
import { DateFormatter } from '@Helpers';
import { useTranslate, useRecordContext } from 'react-admin';
import { pickingOrdersTranslationSchema } from '../../../constants';
import { Picker } from '../../components/Picker/Picker';
import { countPickingMetrics } from '../../utils';

export const General = () => {
  const translate = useTranslate();
  const record = useRecordContext<CorePickingOrderDto>();

  const labels = pickingOrdersTranslationSchema.labels;

  const {
    itemsPicked,
    itemsRequested,
    pickingTimePerItemInSeconds,
    fulfillmentRate,
  } = countPickingMetrics(record);

  return (
    <Flex asColumn>
      <Flex padding={1} alignItems='flex-start' justifyContent='space-between' />

      <RowInfoBlock titleKey={labels.detailsGeneral}>
        <Flex asColumn>
          <Picker />

          <Typography>
            {translate(labels.warehouseValue, {
              value: record.warehouseCode,
            })}
          </Typography>
        </Flex>
      </RowInfoBlock>

      <RowInfoBlock titleKey={labels.detailsSla}>
        <Flex asColumn>
          <Typography>
            {translate(labels.createdAtValue, {
              value: new DateFormatter(record.createdAt).fullDateWithTime(),
            })}
          </Typography>

          <Typography>
            {translate(labels.startAfterValue, {
              value: record.slaStartAfter
                ? new DateFormatter(record.slaStartAfter).fullDateWithTime()
                : '-',
            })}
          </Typography>

          <Typography>
            {translate(labels.startedAtValue, {
              value: record.startedAt
                ? new DateFormatter(record.startedAt).fullDateWithTime()
                : '-',
            })}
          </Typography>

          <Typography>
            {translate(labels.completedAtValue, {
              value: record.completedAt
                ? new DateFormatter(record.completedAt).fullDateWithTime()
                : '-',
            })}
          </Typography>

          <Typography>
            {translate(labels.shipByValue, {
              value: record.slaShipBy
                ? new DateFormatter(record.slaShipBy).fullDateWithTime()
                : '-',
            })}
          </Typography>
        </Flex>
      </RowInfoBlock>

      <RowInfoBlock titleKey={labels.detailsOther}>
        <Flex asColumn>
          <Typography>
            {translate(labels.itemsRequestedValue, {
              value: itemsRequested,
            })}
          </Typography>

          <Typography>
            {translate(labels.itemsPickedValue, {
              value: itemsPicked,
            })}
          </Typography>

          <Typography>
            {translate(labels.pickingTimeValue, {
              value: record.startedAt
                ? new DateFormatter(record.startedAt).duration(record.completedAt ?? undefined)
                : '-',
            })}
          </Typography>

          <Typography>
            {translate(labels.waitingForPickingValue, {
              value: new DateFormatter(record.createdAt).duration(record.startedAt ?? undefined),
            })}
          </Typography>

          <Typography>
            {translate(labels.pickingTimePerItemValue, {
              value: pickingTimePerItemInSeconds ?? '-',
            })}
          </Typography>

          <Typography>
            {translate(labels.fulfillmentRateValue, {
              value: fulfillmentRate,
            })}
          </Typography>
        </Flex>
      </RowInfoBlock>
    </Flex>
  );
};
