import { MouseEvent, useRef, useState } from 'react';
import { ColorState, SketchPicker } from 'react-color';
import { Flex, Icon } from '@UI';
import { IconButton, Popover, Typography } from '@mui/material';

import { DEFAULT_COLORS } from '@UI/ResourceUI/ResourceColorInput/constants';

import { ColorFieldStyled } from '../ResourceColorField/styles';
import { CirclePickerStyled } from './styles';
import { ColorInputProps } from './interface';

export const ColorInput = ({
  name,
  value = '',
  onChange,
  caption,
  isRequired,
  isError,
  defaultColors = DEFAULT_COLORS,
}: ColorInputProps) => {
  const [showCustomColorPicker, setShowCustomColorPicker] = useState(false);
  const anchorEl = useRef<HTMLDivElement>();
  const id = showCustomColorPicker ? 'simple-popover' : undefined;
  const currentValue = value || '';
  const onChangeColorHandler = (newColor: string) => onChange(newColor, name);
  const onResetColorHandler = () => onChange('', name);
  const onColorChangedHandler = (color: ColorState) =>
    onChangeColorHandler(color.hex);
  const onOpenCustomColorSelectorHandler = (e: MouseEvent<HTMLDivElement>) => {
    anchorEl.current = e.currentTarget;
    setShowCustomColorPicker(true);
  };
  const onCloseCustomColorSelectorHandler = () =>
    setShowCustomColorPicker(false);
  const onCustomColorSelectedHandler = (color: ColorState) => {
    onChangeColorHandler(color.hex);
    setShowCustomColorPicker(false);
  };

  return (
    <>
      <Flex gap={1} alignItems="center" minHeight={30}>
        <Typography variant="caption" color={isError ? 'error' : undefined}>
          {caption + (isRequired ? ' *' : '')}
        </Typography>

        <Flex onClick={onOpenCustomColorSelectorHandler}>
          <ColorFieldStyled
            value={currentValue}
            type="color"
            variant="outlined"
            error={isError}
            sx={{ pointerEvents: 'none' }}
          />
        </Flex>

        {!!currentValue && (
          <IconButton size="small" onClick={onResetColorHandler} color="error">
            <Icon type="delete" size="small" />
          </IconButton>
        )}
      </Flex>

      <Flex padding={1} fullWidth aria-describedby={id}>
        <CirclePickerStyled
          colors={defaultColors}
          circleSize={20}
          onChange={onColorChangedHandler}
        />

        <Popover
          id={id}
          open={showCustomColorPicker}
          anchorEl={anchorEl.current}
          onClose={onCloseCustomColorSelectorHandler}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <SketchPicker
            color={currentValue}
            onChangeComplete={onCustomColorSelectedHandler}
          />
        </Popover>
      </Flex>
    </>
  );
};
