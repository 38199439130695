import {
  NumberInput,
  RaRecord,
  required,
  SelectInput,
  TextInput,
  useRecordContext,
  useTranslate,
  BooleanInput,
} from 'react-admin';
import { useController, useFormContext } from 'react-hook-form';
import { InfoRounded } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import {
  Flex,
  ResourceAutocompleteInput,
  ResourceColorInput,
  RowInfoBlock,
} from '@UI';
import { useIsMobile } from '@Hooks/useIsMobile';
import { slugify } from '@Helpers/StringUtils';
import {
  DEEP_LINK_PREFIX,
  DISPLAY_TYPE,
  LANDING_PAGE,
  TILE_SIZE,
  REQUIRED_WITH_IMAGE_DISPLAY_TYPE,
} from '../constants';
import { ChangeEvent } from 'react';
import { useCategoryList } from '@Hooks/useCategoryList';
import { accessPermission, isEmptyAfterTrimmed, maxLengthExt } from '@Helpers';
import { cannotBeActivated } from '@Helpers/FormValidator/FormValidator';
import { Description } from './Description';

const validateName = [required(), isEmptyAfterTrimmed()];

const cdDefaultColors: string[] = [
  '#FDF3F3',
  '#FEF7EB',
  '#EAF6FF',
  '#F6F2FF',
  '#F7F6F6',
];

export const General = () => {
  const deepLink = useController({ name: 'deepLink' });
  const url = useController({ name: 'slug' });
  const previewImage = useController({ name: 'previewImage' });
  const { setValue } = useFormContext();
  const record = useRecordContext();
  const isMobile = useIsMobile();
  const categoryList = useCategoryList(record?.id);
  const translate = useTranslate();

  function onNameChangedHandler(e: ChangeEvent<HTMLInputElement>) {
    const value = e.currentTarget.value;
    const { id } = record || {};

    const formattedUrl = !id ? slugify(value) : `${slugify(value)}-${id}`;

    deepLink.field.onChange(`${DEEP_LINK_PREFIX}${formattedUrl}`);
    url.field.onChange(formattedUrl);
  }

  function onUrlChangedHandler(e: ChangeEvent<HTMLInputElement>) {
    const value = e.currentTarget.value;
    const formattedUrl = value.trim().toLowerCase().replace(/\s+/gi, '-');

    deepLink.field.onChange(`${DEEP_LINK_PREFIX}${formattedUrl}`);
  }

  const handleDisplayTypeChange = (
    e: RaRecord | ChangeEvent<HTMLInputElement>
  ) => {
    if (
      Object.values(REQUIRED_WITH_IMAGE_DISPLAY_TYPE).includes(
        `${e.target.value}`
      )
    ) {
      const { value } = previewImage.field;

      if (!value?.url) {
        setValue('active', false);
      }
    }
  };

  const getNameLabel = () => {
    if (!accessPermission.company?.isAdvancedCategory) {
      return 'catalogue.pages.categories.labels.name';
    }

    return (
      <Flex gap={1} alignItems="center">
        <span>{translate('catalogue.pages.categories.labels.name')}*</span>
        <Tooltip
          title={translate('catalogue.pages.categories.labels.maxLength', {
            length: 40,
          })}
          placement="top"
        >
          <InfoRounded fontSize="small" />
        </Tooltip>
      </Flex>
    );
  };

  return (
    <>
      <Flex asColumn={isMobile} fullWidth gap={1}>
        <RowInfoBlock>
          <BooleanInput
            name="active"
            source="active"
            label="catalogue.pages.categories.labels.active"
            validate={cannotBeActivated(
              'catalogue.pages.categories.validation.imageRequiredForActivate'
            )}
          />
        </RowInfoBlock>
      </Flex>

      <Flex asColumn={isMobile} fullWidth gap={1}>
        <RowInfoBlock flex={1} justifyContent="flex-start">
          <TextInput
            onChange={onNameChangedHandler}
            source="name"
            name="name"
            label={getNameLabel()}
            fullWidth
            validate={[
              ...validateName,
              maxLengthExt(
                accessPermission.company?.isAdvancedCategory ? 40 : 246,
                'catalogue.pages.categories.validation.maxLength'
              ),
            ]}
          />

          {accessPermission.company?.isAdvancedCategory && <Description />}

          <SelectInput
            choices={LANDING_PAGE}
            source="landingPage"
            name="landingPage"
            label="catalogue.pages.categories.labels.landingPage"
            fullWidth
          />

          <ResourceAutocompleteInput
            source="parentId"
            name="parentId"
            choices={categoryList}
            defaultValue={null}
            label="catalogue.pages.categories.labels.parentCategory"
            fullWidth
          />

          {accessPermission.company?.isAdvancedCategory && (
            <ResourceColorInput
              labelKey="catalogue.pages.categories.labels.tileBgColor"
              name="backgroundColor"
              defaultColors={cdDefaultColors}
            />
          )}

          <TextInput
            onChange={onUrlChangedHandler}
            source="slug"
            name="slug"
            label="catalogue.pages.categories.labels.url"
            fullWidth
            validate={required()}
          />
        </RowInfoBlock>

        <RowInfoBlock flex={1} justifyContent="flex-start">
          <NumberInput
            source="sorting"
            name="sorting"
            label="catalogue.pages.categories.labels.sorting"
            fullWidth
            validate={required()}
          />

          <SelectInput
            choices={DISPLAY_TYPE}
            source="displayType"
            name="displayType"
            label="catalogue.pages.categories.labels.displayType"
            fullWidth
            onChange={handleDisplayTypeChange}
          />

          <SelectInput
            choices={TILE_SIZE}
            source="tileSize"
            name="tileSize"
            label="catalogue.pages.categories.labels.tileSize"
            fullWidth
            validate={required()}
          />

          <TextInput
            disabled
            source="deepLink"
            name="deepLink"
            label="catalogue.pages.categories.labels.deepLink"
            fullWidth
          />
        </RowInfoBlock>
      </Flex>
    </>
  );
};
