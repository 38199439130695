import { Button, Toolbar } from 'react-admin';
import styled from 'styled-components';

export const ScheduleHeadStyled = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 16px;
`;

export const AddScheduleButtonStyled = styled(Button)`
  width: 100%;
  margin-top: 16px;
`;

export const FormContentStyled = styled.div`
  padding: 0;
`;

export const CreateModeToolbarStyled = styled(Toolbar)`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`;
