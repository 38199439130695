import { useState } from 'react';
import { Button } from 'react-admin';
import { RequestAPI } from '@RestApi';
import { saveAs } from '@Helpers';
import DownloadIcon from '@mui/icons-material/GetApp';
import { CircularProgress } from '@mui/material';
import { AuthorizationError } from '@PluginManager/base/AbstractApi/AuthorizationError';
import authProvider from '../../providers/authProvider/authProvider';
import { ROUTES } from '@CoreRoutes';
import { DownloadButtonProps } from './interface';
import { ResponseType } from 'axios';

interface FileParams {
  responseType: ResponseType;
  blobType: string;
}

const getFileParams = (
  extension: DownloadButtonProps['extension']
): FileParams => {
  return {
    xlsx: {
      responseType: 'blob' as ResponseType,
      blobType:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
  }[extension];
};

export const DownloadButton = ({
  url,
  filename,
  extension,
}: DownloadButtonProps) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const fileParams = getFileParams(extension);

  const onClick = async () => {
    setIsDownloading(true);

    try {
      const blob = await RequestAPI.get(url, {
        responseType: fileParams.responseType,
      });

      setIsSuccess(true);
      saveAs(blob, {
        type: fileParams.blobType,
        filename: `${filename}.${extension}`,
      });
    } catch (error) {
      setIsSuccess(false);

      if (error instanceof AuthorizationError) {
        authProvider
          .logout({})
          .then(() => {
            window.history.go(-1);
            window.history.pushState(null, '', `/#${ROUTES.login.route}`);
            window.history.go(1);
          })
          .catch(console.error);
      }
    } finally {
      setIsDownloading(false);
    }
  };

  const labelText = isDownloading
    ? 'ra.action.exampleIsLoading'
    : isSuccess
    ? 'ra.action.exampleLoaded'
    : 'ra.action.exampleDownload';

  return (
    <Button
      label={labelText}
      disabled={isDownloading}
      onClick={onClick}
      fullWidth
      size="medium"
      sx={{ mt: 2 }}
    >
      {isDownloading ? <CircularProgress size={16} /> : <DownloadIcon />}
    </Button>
  );
};
