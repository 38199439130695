import { GetOneResult, CreateResult, DeleteResult } from 'react-admin';

import { PluginResourceController } from '@PluginBase';
import { ResourceRoutes } from '@Plugins/resourceRoutes';

import SideMenuIcon from './components/icons/SideMenuIcon';
import { ListPage } from './pages/ListPage';
import { CreatePage } from './pages/CreatePage';
import { UpdatePage } from './pages/UpdatePage';
import { giftingCRUD, submitRawGiftValues } from './utils';
import { RawGift } from './interfaces';
import { pointsToUnits } from '@Helpers';

const GiftingPlugin = new PluginResourceController({
  resourceRoute: {
    name: ResourceRoutes.promotions.gifting.resourcePath,
    list: ListPage,
    create: CreatePage,
    edit: UpdatePage,
  },
  menuItem: {
    icon: <SideMenuIcon />,
    caption: {
      translationKey: 'promotions.pages.gifting.captions.desktop',
    },
    route: ResourceRoutes.promotions.gifting.routePath,
  },
  dataProvider: {
    getOne: async (resource, params): Promise<GetOneResult> => {
      const response = await giftingCRUD.getOne<{ data: CoreRemoteGifting }>(
        params
      );

      if (!response?.data) {
        throw new Error('404 not found');
      }

      if (response.data.minimumPurchaseAmount) {
        response.data.minimumPurchaseAmount = Number(
          pointsToUnits(response.data.minimumPurchaseAmount)
        );
      }

      return {
        data: response?.data,
      };
    },
    getList: async (resource, params) => giftingCRUD.list(params),
    create: async (resource, params) => {
      const { data, meta } = params;
      const response = await giftingCRUD.create<{ data: CoreRemoteGifting }>({
        meta,
        data: submitRawGiftValues(data),
      });

      if (!response) {
        throw new Error('failed');
      }

      return response as CreateResult;
    },
    update: async (resource, params) => {
      const { id, data, meta, previousData } = params;

      const dataToSend = submitRawGiftValues(data as unknown as RawGift);

      await giftingCRUD.update({
        id,
        data: dataToSend,
        meta,
      });

      return {
        data: {
          ...previousData,
          ...data,
        },
      };
    },
    delete: async (resource, params) => {
      await giftingCRUD.delete(params);

      return { data: params.previousData } as DeleteResult;
    },
  },
});

export default GiftingPlugin;
