import { PluginResourceController } from '@PluginBase';
import { Icon } from '@UI';
import { LoyaltyPage } from './pages/LoyaltyPage';
import { GetOneResult, UpdateParams, UpdateResult } from 'react-admin';
import { ResourceRoutes } from '../../../resourceRoutes';
import { CoreLoyaltyData, loyaltyTranslationNamespace } from './constants';
import { LoyaltyCRUD } from './LoyaltyCRUD';

const loyaltyCrud = new LoyaltyCRUD();

const Loyalty = new PluginResourceController({
  resourceRoute: {
    name: ResourceRoutes.loyalty.resourcePath,
    list: LoyaltyPage,
  },
  menuItem: {
    route: ResourceRoutes.loyalty.routePath,
    caption: {
      translationKey: loyaltyTranslationNamespace.captions.desktop,
    },
    icon: <Icon type="loyalty" />,
  },
  dataProvider: {
    getOne: async (): Promise<GetOneResult> => {
      const loyalty: GetOneResult<CoreLoyaltyData> = await loyaltyCrud.get();
      const {
        id,
        percents_of_price_can_be_paid_amount = 0,
        cashback_amount = 0,
        first_client_cashback_amount = 0,
      } = loyalty.data;

      return {
        data: {
          id,
          percents_of_price_can_be_paid_amount,
          cashback_amount: cashback_amount / 10,
          first_client_cashback_amount: first_client_cashback_amount / 10,
        },
      };
    },
    update: async (
      resource,
      params: UpdateParams<CoreLoyaltyData>
    ): Promise<UpdateResult> => {
      const {
        cashback_amount = 0,
        first_client_cashback_amount = 0,
        percents_of_price_can_be_paid_amount = 0,
      } = params.data;

      params.data.cashback_amount =
        parseInt(cashback_amount as unknown as string, 10) * 10;
      params.data.first_client_cashback_amount =
        parseInt(first_client_cashback_amount as unknown as string, 10) * 10;
      params.data.percents_of_price_can_be_paid_amount =
        parseInt(percents_of_price_can_be_paid_amount as unknown as string, 10);

      await loyaltyCrud.update(params);

      return {
        data: params.data,
      };
    },
  },
});

export default Loyalty;
