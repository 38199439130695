import { PluginResourceController } from '@PluginBase';
import { ListPage } from './ListPage';
import { ResourceRoutes } from '../../../resourceRoutes';
import {
  GetListResult,
  GetListParams,
  UpdateParams,
  UpdateResult,
  GetOneResult,
} from 'react-admin';
import { ReplenishmentApiResource } from '../../apiRoutes';
import { ReplenishmentCrud } from '../../crud';
import { ResourceItemPage } from '@Widgets/ResourceItemPage/ResourceItemPage';
import { PageForm } from '@Plugins/Replenishment/resources/Calculate/PageForm';
import { Predict } from '@Plugins/Replenishment/resources/Calculate/interface';

const calculateCrud = new ReplenishmentCrud(
  ReplenishmentApiResource.replenishment
);

const CalculateController = new PluginResourceController({
  resourceRoute: {
    name: ResourceRoutes.replenishment.calculate.resourcePath,
    list: ListPage,
    edit: (
      <ResourceItemPage redirect="list" type="edit">
        <PageForm />
      </ResourceItemPage>
    ),
  },
  menuItem: {
    route: ResourceRoutes.replenishment.calculate.routePath,
    caption: {
      translationKey: 'replenishment.pages.calculate.caption',
    },
  },
  dataProvider: {
    getList: (
      resource: string,
      params: GetListParams
    ): Promise<GetListResult> => {
      return calculateCrud.list(params);
    },
    getOne: async (resource, params): Promise<GetOneResult> => {
      const result = await calculateCrud.getOne<{ data: Predict }>(params);

      return {
        data: result?.data,
      };
    },
    update: async (
      resource: string,
      params: UpdateParams
    ): Promise<UpdateResult> => {
      params.data.packages = parseInt(params.data.packages);
      params.data.shelf_life = parseInt(params.data.shelf_life);
      params.data.sales_llw = parseInt(params.data.sales_llw);
      params.data.sales_lw = parseInt(params.data.sales_lw);
      params.data.sales_tw = parseInt(params.data.sales_tw);
      await calculateCrud.update(params);

      return {
        data: params.data,
      };
    },
    // update: async (
    //   resource: string,
    //   params: UpdateParams
    // ): Promise<UpdateResult> => {
    //   if (params.meta === 'calculate') {
    //     await fetch(ReplenishmentApiResource.replenishmentCalculate, {
    //       method: 'POST',
    //       headers: {
    //         'Content-Type': 'application/json',
    //       },
    //       body: JSON.stringify({
    //         // TODO: need to add the ability to select a supplier
    //         suppliers: ['list of suppliers', 'hardcoded on the backend'],
    //       }),
    //     }).then((response) => response.json());
    //
    //     return { data: { id: 1 } };
    //   }
    //
    //   return { data: 'error' };
    // },
  },
});

export default CalculateController;
