import { Plugin } from '@PluginBase';
import Translations from './i18n.json';
import { Icon } from '@UI';
import OutboundOrdersController from './resources/OutboundOrders/controller';
import PickingOrdersController from './resources/PickingOrders/controller';
import CagesController from './resources/Cages/controller';
import ShipmentsController from './resources/Shipments/controller';

const DistributionCenter = new Plugin({
  name: 'DistributionCenter',
  i18n: Translations,
  resources: [
    OutboundOrdersController,
    PickingOrdersController,
    CagesController,
    ShipmentsController,
  ],
  rootMenu: {
    caption: {
      translationKey: 'distributionCenter.caption',
    },
    icon: <Icon type="distributionCenter" />,
  },
});

export default DistributionCenter;
