import { Flex, RowInfoBlock, UIDeleteButtonWithConfirm } from '@UI';
import { useTranslate } from 'react-admin';
import sortBy from 'lodash/sortBy';
import {
  Typography,
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Drawer,
} from '@mui/material';
import { useController } from 'react-hook-form';
import { useState, MouseEvent } from 'react';
import { PROPERTIES_COLUMNS } from '../../constants';
import { ChoiceValueField } from './ChoiceValueField';
import { PropertyErrors, ChoiceTableProps } from './interface';
import { PropertyLanguageTabs } from '@Plugins/Settings/resources/Properties/pages/PropertyFormFields/interfaces';

export const ChoicesTable = ({ source }: ChoiceTableProps) => {
  const translate = useTranslate();

  const { field } = useController<CoreProperty>({
    name: source as keyof CoreProperty,
  });
  const { field: localeField } = useController<CoreProperty>({
    name: 'locales',
  });
  const typeFieldController = useController<CoreProperty>({ name: 'type' });

  const [selectedIds, setSelectedIds] = useState<
    CoreChoiceValueItemProps['value'][]
  >([]);
  const [openEditPopupData, setOpenEditPopupData] =
    useState<Nullable<CoreChoiceValueItemProps>>(null);
  const [editPopupType, setEditPopupType] = useState<'edit' | 'create'>();
  const [editPopupError, setPopupError] = useState<PropertyErrors>({});

  const choices = field.value as ChoiceDto[];
  const isLocalizationChoices = source.startsWith('locales');
  const locales = localeField.value as CorePropertyLocale[];

  function onClosePopupHandler() {
    setOpenEditPopupData(null);
    setEditPopupType(undefined);
  }

  function onChangePropertyField(value: string, name: string) {
    if (!openEditPopupData) {
      return;
    }

    setOpenEditPopupData({
      ...openEditPopupData,
      [name]: value,
    });
  }

  const getArabianLocale = () => {
    return locales.find(
      (locale) => locale.language === PropertyLanguageTabs.ARABIC
    );
  };

  function validProperty(): PropertyErrors {
    const { value, label, sorting } = openEditPopupData ?? {};
    const errors: PropertyErrors = {};

    if (!label) {
      errors.label = translate('ra.validation.required');
    }

    if (!value) {
      errors.value = translate('ra.validation.required');
    }

    if (!sorting) {
      errors.sorting = translate('ra.validation.required');
    }

    return errors;
  }

  const syncLocaleField = (
    newChoices: (ChoiceDto & { prevValue?: string })[]
  ) => {
    const arabicLocale = getArabianLocale();

    if (!arabicLocale) {
      return;
    }

    localeField.onChange(
      locales.map((item) => {
        if (item.language !== PropertyLanguageTabs.ARABIC) {
          return item;
        }

        const result = {
          ...arabicLocale,
          choices: newChoices.map((choice) => {
            const val = choice.prevValue || choice.value;
            const localeChoice = arabicLocale.choices.find(
              (item) => item.value === val
            );

            if (!localeChoice) {
              return choice;
            }

            return {
              ...choice,
              label: localeChoice.label,
            };
          }),
        };

        return result;
      })
    );
  };

  function onSavePopupHandler() {
    const errors = validProperty();

    setPopupError({});

    if (Object.keys(errors).length > 0) {
      setPopupError(errors);

      return;
    }

    if (editPopupType === 'create') {
      const newChoices = [
        ...choices.filter((item) => item.value !== openEditPopupData?.value),
        {
          label: openEditPopupData?.label ?? '',
          value: openEditPopupData?.value ?? '',
          sorting: openEditPopupData?.sorting ?? 1,
        },
      ];

      field.onChange(newChoices);

      if (!isLocalizationChoices) {
        syncLocaleField(newChoices);
      }
    }

    if (editPopupType === 'edit' && openEditPopupData) {
      const optionIndex = choices.findIndex(
        (choice) => choice.value === openEditPopupData?.prevValue
      );

      const updatedChoices = choices.map((choice, idx) => {
        if (idx === optionIndex) {
          return {
            label: openEditPopupData.label,
            value: openEditPopupData.value,
            sorting: openEditPopupData.sorting ?? 1,
            prevValue: openEditPopupData.prevValue,
          };
        }

        return choice;
      });

      field.onChange(updatedChoices);

      if (!isLocalizationChoices) {
        syncLocaleField(updatedChoices);
      }
    }

    onClosePopupHandler();
  }

  function onUpdatePropertyValueHandler(e: MouseEvent<HTMLTableRowElement>) {
    const value = e.currentTarget.id;
    const option = choices.find((choice) => choice.value === value);

    if (!option) {
      return;
    }

    setOpenEditPopupData({
      ...option,
      prevValue: option.value,
    });
    setEditPopupType('edit');
  }

  function onCreateNewPropertyValueHandler() {
    setOpenEditPopupData({
      value: '',
      label: '',
      prevValue: '',
      sorting: 0,
    });
    setEditPopupType('create');
  }

  function onSelectAllHandler() {
    if (selectedIds.length) {
      setSelectedIds([]);

      return;
    }

    setSelectedIds(choices.map(({ value }) => value));
  }

  function onSelectHandler(event: MouseEvent<HTMLButtonElement>) {
    // @ts-ignore
    const id = event.target.id;

    event.stopPropagation();
    event.preventDefault();

    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((value) => value !== id));

      return;
    }

    setSelectedIds([...selectedIds, id]);

    return false;
  }

  function onBulkDeleteActionHandler(): void {
    field.onChange(
      choices.filter((choice) => !selectedIds.includes(choice.value))
    );
    localeField.onChange(
      locales.map((locale) => {
        if (locale.language !== PropertyLanguageTabs.ARABIC) {
          return locale;
        }

        return {
          ...locale,
          choices: locale.choices.filter(
            (choice) => !selectedIds.includes(choice.value)
          ),
        };
      })
    );
    setSelectedIds([]);
  }

  return (
    <RowInfoBlock>
      <Flex flexGrow={0} justifyContent="space-between" alignItems="center">
        <Typography variant="caption">
          {translate('settings.pages.properties.labels.propertyValues.caption')}
        </Typography>

        {selectedIds.length ? (
          <UIDeleteButtonWithConfirm onConfirm={onBulkDeleteActionHandler} />
        ) : (
          <Button
            size="small"
            onClick={onCreateNewPropertyValueHandler}
            disabled={isLocalizationChoices}
          >
            {!isLocalizationChoices
              ? translate(
                  'settings.pages.properties.actions.createPropertyValue'
                )
              : translate(
                  'settings.pages.properties.actions.propertyValueCreationAvailableOnEnglish'
                )}
          </Button>
        )}
      </Flex>

      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell component="th">
                <Checkbox
                  checked={
                    !!selectedIds.length &&
                    selectedIds.length === choices.length
                  }
                  disabled={isLocalizationChoices}
                  onClick={onSelectAllHandler}
                />
              </TableCell>

              {PROPERTIES_COLUMNS.map(({ headerName, field }) => (
                <TableCell component="th" key={field}>
                  {translate(headerName)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {!!choices?.length &&
              sortBy(choices, 'sorting').map(({ value, label, sorting }) => (
                <TableRow
                  key={`${value}-${label}-${sorting}`}
                  id={value}
                  hover
                  onClick={onUpdatePropertyValueHandler}
                >
                  <TableCell>
                    <Checkbox
                      id={value}
                      checked={selectedIds.includes(value)}
                      onClick={onSelectHandler}
                      disabled={isLocalizationChoices}
                    />
                  </TableCell>

                  <TableCell>{value}</TableCell>

                  <TableCell>{label}</TableCell>

                  <TableCell>{sorting}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Drawer
        open={!!openEditPopupData}
        anchor="right"
        onClose={onClosePopupHandler}
      >
        <Flex asColumn padding={1} width={350}>
          {PROPERTIES_COLUMNS.map(({ headerName, field }) => {
            const isLocalizationValue =
              isLocalizationChoices && ['value', 'sorting'].includes(field);

            const hintMapper: Record<string, string> = {
              value:
                'settings.pages.properties.hints.valueCanBeUpdatedOnTheEnglishLanguage',
              sorting:
                'settings.pages.properties.hints.sortingCanBeUpdatedOnTheEnglishLanguage',
            };
            const hint = hintMapper[field];

            return (
              <>
                <ChoiceValueField
                  key={field}
                  id={field}
                  error={
                    editPopupError[field as keyof CoreChoiceValueItemProps]
                  }
                  onChange={onChangePropertyField}
                  value={
                    openEditPopupData?.[
                      field as keyof CoreChoiceValueItemProps
                    ] ?? ''
                  }
                  label={headerName}
                  disabled={isLocalizationValue}
                  helperText={isLocalizationValue ? translate(hint) : undefined}
                  fieldType={
                    typeFieldController.field.value as CorePropertyType
                  }
                />
              </>
            );
          })}

          <Flex mt={1} fullWidth>
            <Button fullWidth onClick={onSavePopupHandler} variant="outlined">
              {translate('ra.action.save')}
            </Button>
          </Flex>
        </Flex>
      </Drawer>
    </RowInfoBlock>
  );
};
