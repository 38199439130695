import { ListColumn } from '@Widgets/ResourceList/interface';
import { ResourcePaymentMethods } from './ResourcePaymentMethods';

export const PER_PAGE = 25;

export const DELIVERY_AREA_COLUMNS: ListColumn[] = [
  {
    source: 'name',
    label: 'stores.warehouses.pages.tabs.deliveryArea.labels.name',
    type: 'text',
  },
  {
    source: 'priority',
    label: 'stores.warehouses.pages.tabs.deliveryArea.labels.priority',
    type: 'text',
  },
  {
    source: 'color',
    label: 'stores.warehouses.pages.tabs.deliveryArea.labels.color',
    type: 'color',
    sortable: false,
  },
  {
    source: 'active',
    label: 'stores.warehouses.pages.tabs.deliveryArea.labels.active',
    type: 'boolean',
  },
  {
    source: 'shippingMethod.code',
    label: 'stores.warehouses.pages.tabs.deliveryArea.labels.shippingMethod',
    type: 'text',
    sortable: false,
  },
  {
    source: 'payment_methods',
    label: 'stores.warehouses.pages.tabs.deliveryArea.labels.paymentMethods',
    type: 'custom',
    customComponent: <ResourcePaymentMethods key="payment_methods" />,
    sortable: false,
  },
  {
    source: 'delivery_price',
    label: 'stores.warehouses.pages.tabs.deliveryArea.labels.deliveryPrice',
    type: 'text',
  },
  {
    source: 'delivery_price_max',
    label: 'stores.warehouses.pages.tabs.deliveryArea.labels.deliveryPriceMax',
    type: 'text',
  },
  {
    source: 'delivery_price_threshold',
    label:
      'stores.warehouses.pages.tabs.deliveryArea.labels.deliveryPriceThreshold',
    type: 'text',
  },
];
