import { ListColumn } from '@Widgets/ResourceList/interface';

export const LIST_COLUMNS: ListColumn<CoreShippingMethod>[] = [
  {
    source: 'name',
    label: 'settings.pages.shippingMethods.labels.name',
    type: 'text',
  },
  {
    source: 'code',
    label: 'settings.pages.shippingMethods.labels.code',
    type: 'text',
  },
  {
    source: 'is_active',
    label: 'settings.pages.shippingMethods.labels.isActive',
    type: 'boolean',
  },
];
