import { TranslationNamespace } from '@Helpers';
import { TRANSLATION_SCHEMA } from './schemas/translationSchema';
import { RaRecord } from 'react-admin';

export const loyaltyTranslationNamespace = new TranslationNamespace<
  typeof TRANSLATION_SCHEMA
>('promotions.pages.loyalty', TRANSLATION_SCHEMA);

export type CoreLoyaltyData = CoreLoyalty & RaRecord;

export const DEFAULT_FORM_VALUE: CoreLoyaltyData = {
  id: '',
  cashback_amount: 0,
  first_client_cashback_amount: 0,
  percents_of_price_can_be_paid_amount: 0,
};
