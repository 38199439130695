import { useState } from 'react';
import { useNotify, useTranslate } from 'react-admin';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Button, CircularProgress, Snackbar, IconButton } from '@mui/material';
import {
  Download as DownloadIcon,
  Close as CloseIcon,
} from '@mui/icons-material';

import { exportsApiUrl } from '@Plugins/Catalogue/resources/OfferOperations/constants';
import { RequestAPI } from '@RestApi';

const RequestExportFileButton = () => {
  const [isSuccessSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const notify = useNotify();
  const translate = useTranslate();
  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation({
    mutationKey: 'requestExportFile',
    mutationFn: () =>
      RequestAPI.post(exportsApiUrl.create, {
        format: 'xlsx',
      }),
    onSuccess: () => {
      setSuccessSnackbarOpen(true);
    },
    onError: () => {
      notify('Something went wrong', { type: 'error' });
    },
  });

  const handleSuccessSnackbarClose = () => {
    setSuccessSnackbarOpen(false);
  };

  const handleRedirectToOfferExports = () => {
    navigate(
      { pathname: '/offer-operations', search: 'tab=exports' },
      { replace: true }
    );
  };

  return (
    <>
      <Button
        color="primary"
        disabled={isLoading}
        onClick={() => mutate()}
        startIcon={
          isLoading ? <CircularProgress size={16} /> : <DownloadIcon />
        }
        size="small"
      >
        {translate('ra.action.export')}
      </Button>

      <Snackbar
        open={isSuccessSnackbarOpen}
        autoHideDuration={16000}
        onClose={handleSuccessSnackbarClose}
        message={translate(
          'catalogue.pages.offerOperations.offerExports.labels.exportRequestSuccess'
        )}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        ContentProps={{
          sx: {
            bgcolor: 'success.dark',
            color: 'white',
          },
        }}
        action={
          <>
            <Button
              color="info"
              size="small"
              onClick={handleRedirectToOfferExports}
              sx={{ color: 'white' }}
            >
              {translate(
                'catalogue.pages.offerOperations.offerExports.labels.download'
              )}
            </Button>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleSuccessSnackbarClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </>
  );
};

export default RequestExportFileButton;
