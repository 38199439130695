import {
  ResourceSwitcher,
  ResourceTabbedForm,
  RowInfoBlock,
  ResourceInputNumber,
  ResourceTab,
  Flex,
} from '@UI';
import {
  FormTab,
  TextInput,
  DateTimeInput,
  useRecordContext,
  required,
} from 'react-admin';
import {
  storiesTranslationNamespace,
  DEFAULT_FORM_VALUE,
} from '../../constants';
import { RESOURCES_SCHEMA } from '../../schemas/resourcesSchema';
import { Schedule } from './Schedule/Schedule';
import { MainImage } from './MainImage/MainImage';
import { StoryPages } from './StoryPages/StoryPages';
import {
  isEmptyAfterTrimmed,
  onlyLatin,
  maxLengthExt,
  isInteger,
  minNum,
} from '@Helpers';
import isAfter from 'date-fns/isAfter';
import { useIsMobile } from '@Hooks/useIsMobile';

const validateName = [
  required(),
  isEmptyAfterTrimmed(),
  onlyLatin(),
  maxLengthExt(50),
];
const validateDuration = [isInteger(), minNum(0)];
const validateRangeEnd = (
  value: string,
  { dateStart }: { dateStart: string }
) => {
  if (!value) return 'ra.validation.required';

  if (value && isAfter(new Date(dateStart), new Date(value))) {
    return storiesTranslationNamespace.errors.wrongEndDate;
  }

  return undefined;
};

export const Form = () => {
  const isMobile = useIsMobile();
  const story = useRecordContext();

  return (
    <ResourceTabbedForm record={story} defaultValues={DEFAULT_FORM_VALUE}>
      <FormTab label={storiesTranslationNamespace.lists.tabs.general}>
        <Flex gap={2}>
          <RowInfoBlock>
            <ResourceSwitcher
              name={RESOURCES_SCHEMA.active}
              labelKey={storiesTranslationNamespace.labels.status}
            />
          </RowInfoBlock>
          <RowInfoBlock>
            <ResourceSwitcher
              name={RESOURCES_SCHEMA.isStart}
              labelKey={storiesTranslationNamespace.labels.isStart}
            />
          </RowInfoBlock>
        </Flex>

        <Flex asColumn={isMobile} gap={1} fullWidth>
          <RowInfoBlock asColumn flex={1} justifyContent="flex-start">
            <TextInput
              source={RESOURCES_SCHEMA.clientName}
              name={RESOURCES_SCHEMA.clientName}
              label={storiesTranslationNamespace.labels.name}
              validate={validateName}
              fullWidth
            />

            <TextInput
              source={RESOURCES_SCHEMA.deepLink}
              name={RESOURCES_SCHEMA.deepLink}
              label={storiesTranslationNamespace.labels.deepLink}
              fullWidth
              disabled
            />

            <TextInput
              source={RESOURCES_SCHEMA.endStory}
              name={RESOURCES_SCHEMA.endStory}
              label={storiesTranslationNamespace.labels.endStoryLink}
              fullWidth
            />

            {/* <ResourceAutocompleteArrayInput
            choices={data}
            name={RESOURCES_SCHEMA.blacklistedWarehouses}
            source={RESOURCES_SCHEMA.blacklistedWarehouses}
            label={storiesTranslationNamespace.labels.warehouseBlackList}
            fullWidth
          />*/}
          </RowInfoBlock>

          <RowInfoBlock asColumn flex={1} justifyContent="flex-start">
            <ResourceInputNumber
              source={RESOURCES_SCHEMA.duration}
              name={RESOURCES_SCHEMA.duration}
              label={storiesTranslationNamespace.labels.duration}
              postfixSymbol="sec"
              validate={validateDuration}
              fullWidth
            />

            <DateTimeInput
              label={storiesTranslationNamespace.labels.startDate}
              name={RESOURCES_SCHEMA.dateStart}
              source={RESOURCES_SCHEMA.dateStart}
              validate={required()}
              fullWidth
            />

            <DateTimeInput
              isRequired
              label={storiesTranslationNamespace.labels.endDate}
              name={RESOURCES_SCHEMA.dateEnd}
              source={RESOURCES_SCHEMA.dateEnd}
              validate={validateRangeEnd}
              fullWidth
            />
          </RowInfoBlock>
        </Flex>
      </FormTab>

      <ResourceTab
        label={storiesTranslationNamespace.lists.tabs.schedule}
        subscribedFields={[RESOURCES_SCHEMA.schedules.all]}
      >
        <Schedule intervalsQuantity={1} />
      </ResourceTab>

      <ResourceTab
        label={storiesTranslationNamespace.lists.tabs.mainImage}
        subscribedFields={[RESOURCES_SCHEMA.mainImage]}
      >
        <MainImage />
      </ResourceTab>

      <ResourceTab
        label={storiesTranslationNamespace.lists.tabs.gallery}
        subscribedFields={[RESOURCES_SCHEMA.items.all]}
      >
        <StoryPages />
      </ResourceTab>
    </ResourceTabbedForm>
  );
};
