import { useState } from 'react';
import { useTranslate } from 'react-admin';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Close from '@mui/icons-material/Close';

import { HeaderProps } from './interfaces';

export const Header = (props: HeaderProps): JSX.Element => {
  const { index = 0, id, onRemove } = props;
  const translate = useTranslate();

  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const handleRemove = async (): Promise<void> => {
    if (!onRemove) {
      return;
    }

    setIsDeleting(true);

    await onRemove();

    setIsDeleting(false);
  };

  return (
    <>
      <Box sx={{ px: 2, pt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs width="100%">
            <Typography variant="h5">Place #{id}</Typography>
          </Grid>

          {index === 0 && (
            <>
              <Grid item xs={2}>
                <Typography variant="subtitle1">
                  {translate(
                    'catalogue.pages.bundles.labels.originalOfferPrice'
                  )}
                </Typography>
                <Typography variant="caption">
                  {translate('catalogue.pages.bundles.labels.perItem')}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="subtitle1">
                  {translate('catalogue.pages.bundles.labels.discount')}
                </Typography>
                <Typography variant="caption">
                  {translate('catalogue.pages.bundles.labels.perItem')}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="subtitle1">
                  {translate('catalogue.pages.bundles.labels.bundleOfferPrice')}
                </Typography>
                <Typography variant="caption">
                  {translate('catalogue.pages.bundles.labels.perItem')}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="subtitle1">
                  {translate('catalogue.pages.bundles.labels.quantity')}
                </Typography>
              </Grid>
            </>
          )}

          <Grid
            item
            xs={1}
            sx={{
              display: 'flex',
              flexFlow: 'column nowrap',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Tooltip title="Delete place" placement="top">
              <Button
                variant="text"
                color="error"
                onClick={handleRemove}
                disabled={isDeleting}
              >
                <Close />
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
