import { RequestAPI } from '@RestApi';
import { DCCrud } from '../../share/DCCrud';
import { GetListParams, GetListResult } from 'react-admin';

export class PickingOrdersCrud extends DCCrud {
  async list(params: GetListParams): Promise<GetListResult> {
    const subPath = params.meta?.subPath;

    if (subPath) {
      try {
        const result = await RequestAPI.get(`${this.apiUrl}${subPath}`);

        result.total = result.total || result.data.length;

        return result;
      } catch (error) {
        return Promise.reject(error);
      }
    }

    return super.list(params);
  }
}
