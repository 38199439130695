import { Filter, ListColumn } from '@Widgets/ResourceList/interface';
import { ActivityRange } from './components/ActivityRange';
import { PromocodeFormProps } from './interface';
import { TranslationNamespace } from '@Helpers';
import { TRANSLATION_SCHEMA } from './schemas/translationSchema';
import { RESOURCES_SCHEMA } from './schemas/resourcesSchema';

export const DEFAULT_USAGE_LIMIT_CUSTOMER_VAL = 1;
export const DEFAULT_USAGE_LIMIT_ALL_VAL = 1;

export const promocodesTranslationNamespace = new TranslationNamespace<
  typeof TRANSLATION_SCHEMA
>('promotions.pages.promocodes', TRANSLATION_SCHEMA);

export const PROMOCODE_USAGE_LIMIT_CHOICES: SelectOption[] = [
  {
    name: promocodesTranslationNamespace.lists.usageLimit.forAll,
    id: 'forAll',
  },
  {
    name: promocodesTranslationNamespace.lists.usageLimit.perUser,
    id: 'perUser',
  },
];

export const PROMOCODE_MIN_REQUIREMENTS_LIMIT_CHOICES: SelectOption[] = [
  {
    name: promocodesTranslationNamespace.lists.minRequirements.none,
    id: 'none',
  },
  {
    name: promocodesTranslationNamespace.lists.minRequirements
      .minimumPurchaseAmount,
    id: 'minimumPurchaseAmount',
  },
  /* { name: promocodesTranslationNamespace.lists.minRequirements.minimumQuantityItems, id: 'minimumQuantityItems' }, */
];

export const PROMOCODE_CUSTOMERS_ELIGIBILITY_LIMIT_CHOICES: SelectOption[] = [
  {
    name: promocodesTranslationNamespace.lists.customerEligibility.everyone,
    id: 'everyone',
  },
  {
    name: promocodesTranslationNamespace.lists.customerEligibility
      .specificCustomerSegment,
    id: 'specificCustomerSegment',
  },
  {
    name: promocodesTranslationNamespace.lists.customerEligibility
      .specificCustomers,
    id: 'specificCustomers',
  },
];

export const PROMOCODE_APPLY_CHOICES: SelectOption[] = [
  { name: promocodesTranslationNamespace.lists.applyTo.all, id: 'all' },
  {
    name: promocodesTranslationNamespace.lists.applyTo.specificCollection,
    id: 'specificCollection',
  },
  // { name: promocodesTranslationNamespace.lists.applyTo.specificProducts, id: 'specificProducts' },
];

export const PROMOCODE_TYPES_CHOICES: SelectOptionTyped<PromocodeType>[] = [
  { name: promocodesTranslationNamespace.lists.type.percent, id: 'PERCENTAGE' },
  { name: promocodesTranslationNamespace.lists.type.fixed, id: 'FIXED' },
  // { name: promocodesTranslationNamespace.lists.type.freeShipping, id: 'FREE_SHIPPING' },
  // { name: promocodesTranslationNamespace.lists.type.buyXGetY, id: 'BUY_X_GET_Y' },
];

export const PROMOCODE_STATUSES_CHOICES: SelectOption[] = [
  { name: promocodesTranslationNamespace.lists.status.active, id: 'true' },
  { name: promocodesTranslationNamespace.lists.status.inactive, id: 'false' },
];

export const DEFAULT_FORM_VALUE: PromocodeFormProps = {
  customerBuysAnyItemsFrom: 'specificProducts',
  customerGetAnyItemsFrom: 'all',
  discountTargets: {
    inCategory: [],
    inProduct: [],
  },
  productDiscountQuantity: 0,
  maximumUsageNumberPerOrder: 0,
  code: '',
  value: '' as unknown as number,
  dateRange: [
    new Date().toISOString().replace(/:\d{2}\.\d{3}Z$/, ':00.000Z'),
    new Date().toISOString().replace(/:\d{2}\.\d{3}Z$/, ':00.000Z'),
  ],
  isActive: false,
  minimumPurchase: 0,
  minimumQuantity: 0,
  orderNumberRange: [0, 0],
  filters: {
    inCategory: [],
    inProduct: [],
  },
  type: 'PERCENTAGE',
  usageCurrent: 0,
  usageLimitAll: DEFAULT_USAGE_LIMIT_ALL_VAL,
  usageLimitCustomer: DEFAULT_USAGE_LIMIT_CUSTOMER_VAL,
  minRequirementsChoice: 'none',
  applyTo: 'all',
  customerEligibility: 'everyone',
  applyToDiscountedItems: true,
};

export const FILTERS: Filter[] = [
  { source: 'code', type: 'search' },
  {
    source: 'isActive',
    type: 'choose',
    label: promocodesTranslationNamespace.labels.status,
    choices: PROMOCODE_STATUSES_CHOICES,
    emptyText: promocodesTranslationNamespace.lists.status.all,
  },
  {
    source: 'type',
    type: 'choose',
    label: promocodesTranslationNamespace.labels.type,
    choices: PROMOCODE_TYPES_CHOICES,
    emptyText: promocodesTranslationNamespace.lists.type.all,
  },
];

// TODO: remove "any" from generic
export const LIST_COLUMNS: ListColumn<typeof RESOURCES_SCHEMA & any>[] = [
  {
    source: RESOURCES_SCHEMA.id,
    type: 'text',
    label: promocodesTranslationNamespace.labels.id,
  },
  {
    source: RESOURCES_SCHEMA.code,
    type: 'text',
    label: promocodesTranslationNamespace.labels.name,
  },
  {
    source: RESOURCES_SCHEMA.usageCurrent,
    type: 'text',
    label: promocodesTranslationNamespace.labels.used,
  },
  {
    source: RESOURCES_SCHEMA.usageLimitAll,
    type: 'text',
    label: promocodesTranslationNamespace.labels.maxUsed,
  },
  {
    source: 'activityRange',
    type: 'custom',
    label: promocodesTranslationNamespace.labels.activityRange,
    customComponent: <ActivityRange key="activity" />,
    sortable: false,
  },
  {
    source: RESOURCES_SCHEMA.isActive,
    type: 'boolean',
    label: promocodesTranslationNamespace.labels.active,
  },
  {
    source: RESOURCES_SCHEMA.createdAt,
    label: promocodesTranslationNamespace.labels.createdAt,
    type: 'date',
    showTime: true,
  },
  {
    source: RESOURCES_SCHEMA.updatedAt,
    label: promocodesTranslationNamespace.labels.updatedAt,
    type: 'date',
    showTime: true,
  },
];
