import { generatePromocodeInput } from '@Plugins/Promotions/resources/Promocodes/utils';

export type SanitizeDataArg = ReturnType<typeof generatePromocodeInput> & {
  name: string;
  email: string;
  default: number | boolean;
  referral_link: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  refereeId?: string;
  customerId?: string;
  parentId?: string;
  validationRules?: unknown;
  usageLimit?: unknown;
};

export const sanitizeAffiliatePromocodeData = (
  value: SanitizeDataArg
): Omit<
  ReturnType<typeof generatePromocodeInput>,
  'usageCurrent' | 'filters'
> => {
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    usageCurrent,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    createdAt,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updatedAt,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    deletedAt,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    refereeId,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    customerId,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    parentId,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    validationRules,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    usageLimit,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    filters,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    name,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    email,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    referral_link,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    default: asDefault,
    ...promocode
  } = value;

  return promocode;
};

export const sanitizeAffiliatePromocodeCreationData = (
  value: SanitizeDataArg
): Omit<
  ReturnType<typeof generatePromocodeInput>,
  | 'usageCurrent'
  | 'filters'
  | 'maximumUsageNumberPerOrder'
  | 'applyTo'
  | 'customerEligibility'
> => {
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    maximumUsageNumberPerOrder,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    applyTo,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    customerEligibility,
    ...data
  } = sanitizeAffiliatePromocodeData(value);

  return data;
};

export const getIsDefault = (item: CoreAffiliatePromocode): boolean => {
  if (typeof item.default === 'boolean') {
    return item.default;
  }

  if (typeof (item.default as unknown) === 'string') {
    return `${item.default}` === '1' || `${item.default}` === 'true';
  }

  return item.default === 1;
};
