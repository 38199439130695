import { useContext, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { ErrorBoundaryProvider } from '@ROOT/helpers';
import { useNotify } from 'react-admin';
import { ValidationError } from '@PluginManager/base/AbstractApi/ValidationError';

export const useErrorBoundary = () => useContext(ErrorBoundaryProvider);

export function useErrorBoundaryFormContext() {
  const { errors, updateContextErrors } = useErrorBoundary();
  const {
    setError,
    control: { _fields },
  } = useFormContext();
  const notify = useNotify();

  useEffect(() => {
    if (errors) {
      const errorEntries = Object.entries(errors);

      errorEntries.forEach(([fieldName, fieldData]) => {
        _fields[fieldName] &&
          setError(fieldName, { message: fieldData.join('. ') });
      });

      if (errors._errorList) {
        setTimeout(() => {
          notify(errors._errorList.join('\n'), {
            type: 'error',
            multiLine: true,
            autoHideDuration: 10000,
          });
        }, 1000); // TODO: move number to a config
      }

      if (errorEntries.length > 1) {
        updateContextErrors?.(new ValidationError([]));
      }
    }
  }, [JSON.stringify(errors)]);
}
