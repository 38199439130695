import { FormControlLabel } from '@mui/material';
import styled from 'styled-components';

export const FormControlLabelStyled = styled(FormControlLabel)<{
  $dir: 'ltr' | 'rtl';
}>`
  ${({ $dir }) =>
    $dir === 'ltr'
      ? `
            margin-left: -11px;
            margin-right: 16px;
          `
      : `
            margin-right: -11px;
            margin-left: 16px;
          `}
`;
