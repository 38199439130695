import { TranslationNamespace } from '@Helpers';
import { PickerStatus, PickerEmployment } from './interface';
import { TRANSLATION_SCHEMA } from './schemas/translationSchema';

export const pickersTranslationSchema = new TranslationNamespace<typeof TRANSLATION_SCHEMA>(
  'wms-picking.pages.pickers',
  TRANSLATION_SCHEMA,
);

export const PICKER_STATUS_FILTER: SelectOptionTyped<PickerStatus>[] = [
  { id: 'offline', name: pickersTranslationSchema.lists.statuses.offline },
  { id: 'waiting', name: pickersTranslationSchema.lists.statuses.waiting },
  { id: 'picking', name: pickersTranslationSchema.lists.statuses.picking },
  { id: 'idle', name: pickersTranslationSchema.lists.statuses.idle },
];

export const PICKER_EMPLOYMENT_FILTER: SelectOptionTyped<PickerEmployment>[] = [
  { id: 'full_time', name: pickersTranslationSchema.lists.employment.full_time },
  { id: 'hybrid', name: pickersTranslationSchema.lists.employment.hybrid },
];
