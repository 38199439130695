import {
  Filter,
  ListColumn,
  ResourceListPropsSort,
} from '@Widgets/ResourceList/interface';
import { formatEntityName } from '@ROOT/helpers/FormatEntityName/FormatEntityName';
import { StatusBadgeResource } from '../share/StatusBadgeResource/StatusBadgeResource';

export type ColWithWarehouse = CoreDCOrder & Record<any, any>;

export type WarehousesQuantities = Record<
  string,
  { quantity: number | string; oldQuantity?: number | string }
>;

export interface CoreOutboundDemandOrderModified {
  [key: string]: unknown;
  id: number;
  productName: string;
  isSyncFailed: boolean;
  totalQuantity: string | number;
  available: string | number;
  availableQuantity: number;
  disabledWhList: string[];
}

export const apiUrl = '/dc/v1/orders';

export enum ResourceMeta {
  DEMAND_QUANTITIES = 'DEMAND_QUANTITIES',
  DEMAND_QUANTITIES_RECOUNT = 'DEMAND_QUANTITIES_RECOUNT',
}

export const DEFAULT_SORTING: ResourceListPropsSort = {
  field: 'createdAt',
  order: 'DESC',
};

export const PER_PAGE = 10;

export const FILTERS: Filter[] = [
  {
    source: 'displayId',
    label: 'distributionCenter.pages.outboundOrders.labels.id',
    type: 'text',
  },
  {
    source: 'status',
    type: 'choose-multi',
    choices: [
      { id: 'created', name: 'distributionCenter.statuses.created' },
      { id: 'in_progress', name: 'distributionCenter.statuses.in_progress' },
      { id: 'finished', name: 'distributionCenter.statuses.finished' },
      { id: 'canceled', name: 'distributionCenter.statuses.canceled' },
    ],
    label: 'distributionCenter.pages.outboundOrders.labels.status',
  },
  {
    source: 'destinationWarehouse',
    type: 'text',
    label:
      'distributionCenter.pages.outboundOrders.labels.destinationWarehouses',
  },
  {
    source: 'description',
    label: 'distributionCenter.pages.outboundOrders.labels.description',
    type: 'text',
  },
  {
    source: 'createdAtFrom',
    type: 'date',
    label: 'distributionCenter.pages.outboundOrders.labels.createdAtFrom',
  },
  {
    source: 'createdAtTo',
    type: 'date',
    label: 'distributionCenter.pages.outboundOrders.labels.createdAtTo',
  },
];

export const OUTBOUND_ORDER_LIST_COLUMNS: ListColumn<CoreOutboundOrder>[] = [
  {
    source: 'displayId',
    label: 'distributionCenter.pages.outboundOrders.labels.id',
    type: 'function',
    function: (record) => formatEntityName('DC', 4, record.displayId),
    sortable: false,
  },
  {
    source: 'orderItems',
    label: 'distributionCenter.pages.outboundOrders.labels.destinations',
    type: 'function',
    function: (record) =>
      Array.from(
        new Set(
          (record.orderItems || []).map(
            ({ destinationWarehouse }) => destinationWarehouse
          )
        )
      ).join(', '),
    sortable: false,
  },
  {
    source: 'createdAt',
    label: 'distributionCenter.pages.outboundOrders.labels.createdAt',
    type: 'date',
    showTime: true,
  },
  {
    source: 'status',
    label: 'distributionCenter.pages.outboundOrders.labels.status',
    type: 'custom',
    customComponent: <StatusBadgeResource key="status" />,
    sortable: false,
  },
  {
    source: 'description',
    label: 'distributionCenter.pages.outboundOrders.labels.description',
    type: 'text',
    sortable: false,
  },
];
