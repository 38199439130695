import {
  ActionPayload,
  ChangeHandler,
  ChangeHandlerMap,
  ReducerFn,
} from './interfaces';
import combineChanges from './combineChanges';

export const applyHandler = <State, Payload = never>(
  handlers: ChangeHandlerMap<State, Payload>,
  action: ActionPayload<Payload>,
  state: State
): State => {
  if (!Object.keys(handlers).includes(action.type)) {
    return state;
  }

  if (!Array.isArray(handlers[action.type])) {
    const reducerFn = handlers[action.type] as ChangeHandler<State, Payload>;

    return reducerFn(state, action);
  }

  const reducerChain = handlers[action.type] as ChangeHandler<State, Payload>[];

  return combineChanges<State, Payload>(reducerChain, state, action);
};

export default <State, Payload = never>(
    initialState: State,
    handlers: ChangeHandlerMap<State, Payload>
  ): ReducerFn<State, Payload> =>
  (state = initialState, action: ActionPayload<Payload>): State =>
    applyHandler<State, Payload>(handlers, action, state);
