import { AxiosError } from 'axios';
import { FC } from 'react';

export class ConfigurableError extends Error {
  readonly status?: number;

  constructor(
    error: AxiosError,
    errorCode: string,
    contentEl: FC,
  ) {
    super(errorCode);
    this.status = error.response?.status;

    if (contentEl) {
      this.contentEl = contentEl;
    }
  }

  readonly contentEl: FC = () => null;
}
