/**
 * Formats display id to display name with prefix
 *
 * @param prefix Display name prefix
 * @param displayId Entity display id
 * @param zeros Leading zeros count
 */
export const formatEntityName = (
  prefix: string,
  zeros: number,
  displayId?: number
): string => {
  return displayId
    ? `${prefix}${displayId.toString().padStart(zeros, '0')}`
    : '-';
};
