import {
  CustomRoute,
  PluginImplementation,
  PluginMenu,
  PluginMenuRootItem,
  PluginName,
  PluginProps,
} from '../../interface';
import { ResourceProps } from 'ra-core';
import { DataProvider } from 'react-admin';

export class Plugin implements PluginImplementation {
  readonly dataProviders: Record<string, Nullable<Partial<DataProvider>>> = {};
  readonly pluginName: PluginName;
  readonly i18n: PluginImplementation['i18n'];
  readonly rootMenu: Nullable<PluginMenuRootItem>;
  private readonly _customRoutes: CustomRoute[] = [];
  private readonly _resourceRoutes: ResourceProps[] = [];
  private readonly _menuItems: PluginMenu['items'] = [];

  constructor({ name, i18n, resources, rootMenu }: PluginProps) {
    this.pluginName = name;
    this.i18n = i18n;
    this.rootMenu = rootMenu ?? null;

    resources.forEach((page) => {
      if (page.menuItem) {
        this._menuItems.push(page.menuItem);
      }

      if (page.customRoutes?.length) {
        this._customRoutes.push(...page.customRoutes);
      }

      if (page.resourceRoute) {
        this._resourceRoutes.push(page.resourceRoute);

        if (page.dataProvider) {
          this.dataProviders[page.resourceRoute.name] = page.dataProvider;
        }
      }
    });
  }

  get menu(): PluginMenu {
    return {
      rootMenu: this.rootMenu ?? undefined,
      items: this._menuItems,
    };
  }

  get customRoutes(): CustomRoute[] {
    return this._customRoutes;
  }

  get resourceRoutes(): ResourceProps[] {
    return this._resourceRoutes;
  }
}
