import { Icon, UIDialog } from '@UI';
import { Button, Typography } from '@mui/material';
import { useTranslate } from 'react-admin';
import { useState, PropsWithChildren } from 'react';
import { UIDeleteButtonWithConfirmProps } from './intrefaces';

export const UIDeleteButtonWithConfirm = ({
  children,
  onConfirm,
  label,
}: Partial<PropsWithChildren<UIDeleteButtonWithConfirmProps>>) => {
  const translate = useTranslate();
  const [open, setOpen] = useState(false);

  function onDialogOpenHandler() {
    setOpen(true);
  }

  function onDialogCloseHandler() {
    setOpen(false);
  }

  function onDialogConfirmHandler() {
    onDialogCloseHandler();
    onConfirm?.();
  }

  return (
    <>
      <Button size='small' color='error' startIcon={<Icon type='delete' size='small' />} onClick={onDialogOpenHandler}>
        {translate(label ?? 'ra.action.delete')}
      </Button>

      <UIDialog
        open={open}
        title={translate('ra.message.delete_title')}
        onClose={onDialogCloseHandler}
        controls={
          <>
            <Button color='secondary' onClick={onDialogCloseHandler}>
              {translate('ra.action.cancel')}
            </Button>

            <Button onClick={onDialogConfirmHandler}>{translate('ra.action.confirm')}</Button>
          </>
        }
      >
        {children ?? <Typography>{translate('ra.message.delete_content')}</Typography>}
      </UIDialog>
    </>
  );
};
