import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { Toolbar, useTranslate } from 'react-admin';
import { Box, Button } from '@mui/material';
import { Save } from '@mui/icons-material';
import { ReactNode } from 'react';

import { PromocodeForm } from '@Plugins/Promotions/resources/Promocodes/pages/PromocodeForm/PromocodeForm';
import { generatePromocodeFromValues } from '@Plugins/Promotions/resources/Promocodes/utils';
import { PromocodeFormProps } from '@Plugins/Promotions/resources/Promocodes/interface';

interface AffiliatePromocodeFormProps {
  record?: ReturnType<typeof generatePromocodeFromValues> | PromocodeFormProps;
  affiliate: CoreAffiliateDetail;
  onSubmit?: (fieldValues: FieldValues) => void;
  showGenerateCode?: boolean;
  isLoading?: boolean;
  buttons?: ReactNode;
}

const AffiliatePromocodeForm = (props: AffiliatePromocodeFormProps) => {
  const {
    record,
    affiliate,
    onSubmit,
    showGenerateCode,
    isLoading = false,
    buttons,
  } = props;

  const translate = useTranslate();

  const formMethods = useForm({
    defaultValues: {
      ...record,
      name: [affiliate.data.firstname, affiliate.data.lastname]
        .filter(Boolean)
        .join(' '),
      email: affiliate.data.email,
    },
    reValidateMode: 'onChange',
  });

  const onValid = (fieldValues: FieldValues) => {
    onSubmit?.(fieldValues);
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onValid)}>
        <Box sx={{ width: 1200, p: 1 }}>
          <PromocodeForm hasAffiliate showGenerateCode={showGenerateCode} />
        </Box>

        <Toolbar sx={{ gap: 2 }}>
          <Button
            variant="contained"
            color="success"
            type="submit"
            startIcon={<Save />}
            disabled={!formMethods.formState.isDirty || isLoading}
          >
            {translate('save')}
          </Button>
          {buttons}
        </Toolbar>
      </form>
    </FormProvider>
  );
};

export default AffiliatePromocodeForm;
