import styled from 'styled-components';
import { List, ListItemIcon, Typography } from '@mui/material';
import { MenuBodyListStyledProps, IconWrapperProps } from '../interface';

export const ListItemIconStyled = styled(ListItemIcon)`
  min-width: 40px !important;
`;

export const MenuBodyListStyled = styled(List)<MenuBodyListStyledProps>`
  box-shadow: 0 -1px 0 0 rgba(
      255,
      255,
      255,
      ${({ $sidebarIsOpen }) => ($sidebarIsOpen ? 0 : 0.2)}
    ) inset;

  a {
    transition: padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    padding-left: ${({ $sidebarIsOpen }) => ($sidebarIsOpen ? 32 : 16)}px;
  }
`;

export const IconWrapper = styled(Typography)<IconWrapperProps>`
  font-size: 0;
  ${({ $sidebarIsOpen, $dir }) =>
    !$sidebarIsOpen &&
    `
    position: absolute;
    ${$dir === 'ltr' ? 'left' : 'right'}: -4px;
    scale: 0.6;
  `}
`;
