import { useTranslate } from 'react-admin';
import { ResourceList } from '@Widgets/ResourceList/ResourceList';
import { ShowFromContainerStyled } from '@Widgets/styles';
import { ListItemDrawer } from '@Widgets/ListItemDrawer/ListItemDrawer';
import { ResourceRoutes } from '@Plugins/resourceRoutes';
import { pickingOrdersTranslationSchema } from '../../constants';
import { PickingOrderDetails } from '../ItemPage/PickingOrderDetails';
import {
  DEFAULT_PER_PAGE,
  DEFAULT_SORTING,
  FILTERS,
  LIST_COLUMNS,
} from './constants';

export const ListPage = () => {
  const translate = useTranslate();

  return (
    <>
      <ResourceList<CorePickingOrderDto>
        columns={LIST_COLUMNS}
        filters={FILTERS}
        defaultSort={DEFAULT_SORTING}
        showDeleteButton={false}
        perPage={DEFAULT_PER_PAGE}
        getItemCardCaption={(record) =>
          translate(pickingOrdersTranslationSchema.captions.mobile, {
            orderId: record.orderId,
          })
        }
      />

      <ListItemDrawer route={ResourceRoutes.wmsPickingOrders.resourcePath}>
        {({ entity }) =>
          entity ? (
            <ShowFromContainerStyled id={entity}>
              <PickingOrderDetails />
            </ShowFromContainerStyled>
          ) : (
            <div />
          )
        }
      </ListItemDrawer>
    </>
  );
};
