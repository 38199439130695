import {
  Box,
  Typography,
  FormControl,
  Button,
  TextField,
  InputAdornment,
  IconButton,
  FormHelperText,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useState, ChangeEvent, FormEvent } from 'react';
import { useTranslate, useNotify, useLogin } from 'react-admin';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import { AxiosError } from 'axios';
import { UIEmailInput } from '@UI/UIEmailInput/UIEmailInput';
import { EventParams } from '@UI/UIEmailInput/interface';

const EmailFlow = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [emailValue, setEmailValue] = useState('');
  const [passwordValue, setPasswordValue] = useState('');
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isShowError, setIsShowError] = useState(false);

  const translate = useTranslate();
  const notify = useNotify();
  const login = useLogin();

  const handleChangeEmail = ({ email, isValid }: EventParams) => {
    setEmailValue(email);
    setIsValid(isValid);
    setIsShowError(false);
  };

  const handleBlurEmail = () => {
    if (!isValid && emailValue.length) {
      setIsShowError(true);
    } else {
      setIsShowError(false);
    }
  };

  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>) =>
    setPasswordValue(e.currentTarget.value);

  const handleToggleShowPassword = () => setIsShowPassword(!isShowPassword);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    login({
      email: emailValue.trim(),
      password: passwordValue.trim(),
    })
      .finally(() => setIsLoading(false))
      .catch((error: AxiosError<CoreRestApiErrorResponse>) => {
        const message =
          error.response?.data?.message &&
          typeof error.response?.data?.message === 'string'
            ? error.response?.data?.message
            : error.response?.data?.error || 'ra.auth.sign_in_error';

        notify(message, {
          type: 'error',
          messageArgs: {
            _: message,
          },
        });
      });
  };

  const checkIsValid = () => isValid && passwordValue.trim().length > 0;

  return (
    <Box>
      <Typography textAlign="center" fontSize="16px" mb={1}>
        {translate('pages.login.enterEmail')}
      </Typography>

      <form onSubmit={handleSubmit} autoComplete="off">
        <FormControl fullWidth>
          <UIEmailInput
            value={emailValue}
            onChange={handleChangeEmail}
            onBlur={handleBlurEmail}
            type="text"
            autoComplete="off"
            label={translate('pages.login.email')}
            variant="outlined"
            error={isShowError}
            inputProps={{
              autoFocus: true,
            }}
          />

          {isShowError && (
            <FormHelperText error sx={{ m: 0 }}>
              {translate('pages.login.error.invalidEmail')}
            </FormHelperText>
          )}
        </FormControl>

        <FormControl fullWidth>
          <TextField
            value={passwordValue}
            onChange={handleChangePassword}
            type={isShowPassword ? 'text' : 'password'}
            autoComplete="off"
            label={translate('pages.login.password')}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleToggleShowPassword}
                    edge="end"
                  >
                    {isShowPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>

        <Button
          variant="contained"
          type="submit"
          color="primary"
          sx={{ mt: 2 }}
          fullWidth
          disabled={!checkIsValid() || isLoading}
        >
          {translate('pages.login.signin')}

          {isLoading ? (
            <CircularProgress
              color="primary"
              size="16px"
              sx={{ ml: 1 }}
              thickness={6}
            />
          ) : (
            <ArrowForwardIosIcon fontSize="small" sx={{ ml: '5px' }} />
          )}
        </Button>
      </form>
    </Box>
  );
};

export default EmailFlow;
