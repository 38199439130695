import {
  DateField,
  TabbedShowLayout,
  useLocaleState,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import React from 'react';
import { Typography } from '@mui/material';
import { Flex } from '@UI';
import { DemandTab } from './DemandTab';
import { formatEntityName } from '@ROOT/helpers/FormatEntityName/FormatEntityName';
import { StatusBadgeResource } from '../share/StatusBadgeResource/StatusBadgeResource';
import { PickingOrdersTab } from './PickingOrdersTab';

export const OutboundOrderPage = () => {
  const translate = useTranslate();
  const [locale] = useLocaleState();
  const record = useRecordContext<CoreOutboundOrder>();

  const infoBlock = (
    <Flex p={2} gap={2} justifyContent="space-between" flexWrap="wrap">
      <Flex asColumn>
        <Typography variant="h5">
          {formatEntityName('DC', 4, record.displayId)}
        </Typography>
        <Typography variant="subtitle2">
          {`${translate(
            'distributionCenter.pages.outboundOrders.labels.createdAt'
          )}: `}

          <DateField source="createdAt" locales={locale} showTime />
        </Typography>
      </Flex>
      <Flex asColumn>
        <StatusBadgeResource />
      </Flex>
    </Flex>
  );

  return (
    <>
      {infoBlock}
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="distributionCenter.pages.outboundOrders.tabs.demand">
          <DemandTab />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab
          label="distributionCenter.pages.outboundOrders.tabs.pickingOrders"
          disabled={record.status === 'created'}
        >
          <PickingOrdersTab />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </>
  );
};
