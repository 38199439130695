export class OfferPropertiesValidationError extends Error {
  public static readonly PROPERTIES_REGEX =
    /^(?<index>properties\.\d)\.(?<field>.*?)\s(?<message>.+?)$/gi;

  private readonly _errors: string[];
  private readonly _offer: string;
  private readonly _translationKey: string;

  constructor(errors: string[], offer?: string, translationKey?: string) {
    super();
    this._errors = errors;
    this._offer = offer || '{}';
    this._translationKey = translationKey || '';
  }

  get errors(): Record<string, string[]> {
    return this._errors.reduce<Record<string, string[]>>((memo, item) => {
      item = item.trim();
      const match = item.match(OfferPropertiesValidationError.PROPERTIES_REGEX);

      if (!match) {
        return memo;
      }

      const [field, ...messages] = item.split(/\s/);
      const errorMessage = messages.join(' ');

      const value = memo[field] || [];

      return { ...memo, [field]: [...value, errorMessage] };
    }, {});
  }

  get properties(): CoreProductAdditionalField[] {
    try {
      const { properties = [] } = JSON.parse(this._offer) as CoreOffer;

      return properties;
    } catch (e: unknown) {
      return [];
    }
  }

  get namedErrors(): Record<string, string[]> {
    return Object.entries(this.errors).reduce<Record<string, string[]>>(
      (memo, [field, messages]) => {
        const index = Number(field.replace(/properties\.(\d)\.value/, '$1'));

        if (isNaN(index)) {
          return memo;
        }

        const property = this.properties[index];

        if (!property) {
          return memo;
        }

        return {
          ...memo,
          [`offerProperty:${property.code}`]: messages,
        };
      },
      {}
    );
  }

  get translationKey(): string {
    return this._translationKey;
  }
}
