import {
  AutocompleteInput,
  AutocompleteArrayInput,
  CheckboxGroupInput,
  RadioButtonGroupInput,
  required,
} from 'react-admin';
import { AdditionalListPropertiesProps } from '../interface';
import { Typography } from '@mui/material';
import { Flex } from '@UI';

const validateListField = [required()];

export const ListProperty = ({
  source,
  choices,
  label,
  type,
}: AdditionalListPropertiesProps) => {
  const options = {
    name: source,
    source,
    label,
    choices,
    translate: 'no' as const,
    fullWidth: true,
    validate: validateListField,
  };

  switch (type) {
    case 'tagList':
      return <AutocompleteArrayInput {...options} defaultValue={[]} />;
    case 'colorList':
      return (
        <AutocompleteInput
          {...options}
          defaultValue={[]}
          inputText={(option) => `${option.name} (${option.id})`}
          optionText={(option: SelectOption) => (
            <Flex alignItems="center" gap={1}>
              <Flex
                bgcolor={option.id.toString()}
                padding={1}
                borderRadius={100}
              />

              <Typography>{option.name}</Typography>
            </Flex>
          )}
        />
      );
    case 'sizeList':
    case 'selector':
      return <AutocompleteInput {...options} defaultValue={[]} />;
    case 'checkboxGroup':
      return <CheckboxGroupInput {...options} defaultValue={[]} />;
    case 'radioGroup':
      return <RadioButtonGroupInput {...options} defaultValue={[]} />;
    default:
      return null;
  }
};
