import { useListContext, RecordContextProvider } from 'react-admin';
import { Box } from '@mui/material';
import { MobileGridCard, Loader } from '@UI';
import { SortingButton } from './SortingButton/SortingButton';
import { ResourceMobileGridProps } from './interface';

export function ResourceMobileGrid<Type>({
  children,
  getItemCardCaption,
  fields,
  defaultField,
  showDeleteButton = false,
  isInteractive = true,
  rowClick,
  resource,
}: ResourceMobileGridProps<Type>) {
  const { data, isLoading } = useListContext();

  if (isLoading) return <Loader />;

  if (data.length === 0) return <></>;

  return (
    <>
      {fields.length > 0 && <SortingButton fields={fields} defaultField={defaultField} />}

      <Box>
        {data.map((record) => (
          <RecordContextProvider key={record.id} value={record}>
            <MobileGridCard
              resource={resource}
              record={record}
              showDeleteButton={showDeleteButton}
              caption={getItemCardCaption(record)}
              isInteractive={isInteractive}
              rowClick={rowClick}
            >
              {children(record)}
            </MobileGridCard>
          </RecordContextProvider>
        ))}
      </Box>
    </>
  );
}
