export enum PackageDeliveryType {
  Grocery,
  Alcohol,
  Tobacco,
  Pharma,
  Chemical,
  Frozen,
  OwnKitchen,
}

export enum PackageHandOverStatus {
  RejectedOrMissing,
  ConfirmHandOver,
}
