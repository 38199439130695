import { Flex } from '@UI';
import { Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { MultiplierElementProps } from '../interface';

export const MultiplierItemElement = (props: MultiplierElementProps) => {
  const { id, onRemoveBlockItem, element, blockId } = props;

  function onDeleteIntervalHandler() {
    onRemoveBlockItem?.(blockId, id);
  }

  return (
    <Flex alignItems='center' mb={1}>
      {element}

      {onRemoveBlockItem && (
        <Flex mt={1}>
          <Button onClick={onDeleteIntervalHandler} data-testid='delete-interval-button'>
            <DeleteIcon color='secondary' />
          </Button>
        </Flex>
      )}
    </Flex>
  );
};
