import { PropsWithChildren } from 'react';
import { Box, Modal } from '@mui/material';
import { ModalUnstyledOwnProps } from '@mui/base/ModalUnstyled/ModalUnstyled.types';

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  py: 3,
  px: 4,
  color: 'white',
};

interface BasicModalProps extends PropsWithChildren {
  isOpen: boolean;
  onClose: ModalUnstyledOwnProps['onClose'];
  width?: number;
}

export const BasicModal = ({
  isOpen,
  onClose,
  children,
  width = 400,
}: BasicModalProps) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} width={width}>
        {children}
      </Box>
    </Modal>
  );
};
