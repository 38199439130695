import { CommonCrud, PluginResourceController } from '@PluginBase';
import { ResourceRoutes } from '@Plugins/resourceRoutes';
import { exportsApiUrl } from '@Plugins/Catalogue/resources/OfferOperations/constants';

const offerExportCrud = new CommonCrud(exportsApiUrl.list, {
  isNewSorting: true,
});

const offerExportController = new PluginResourceController({
  menuItem: null,
  resourceRoute: {
    name: ResourceRoutes.offerExports.resourcePath,
  },
  dataProvider: {
    getList: (resource, params) => offerExportCrud.list(params),
  },
});

export default offerExportController;
