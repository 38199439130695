export const DefaultRefundCase: SelectOption = {
  name: 'orders.pages.labels.payments.refund.problemType.otherItem',
  id: 'item_other_item',
};

export const RefundCases: SelectOption[] = [
  {
    name: 'orders.pages.labels.payments.refund.problemType.notDelivered',
    id: 'item_not_delivered',
  },
  {
    name: 'orders.pages.labels.payments.refund.problemType.damaged',
    id: 'item_damaged',
  },
];

export const PartialRefundCases: SelectOption[] = [
  ...RefundCases,
  /* { name: 'orders.pages.labels.payments.refund.problemType.incorrectPrice', id: 'incorrect_price' },*/
];

export const DEFAULT_FULL_REFUND_STATE = {
  problem: '',
  comment: '',
};
