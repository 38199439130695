import { useState, useEffect } from 'react';
import {
  useTranslate,
  DashboardMenuItem,
  MenuItemLink,
  MenuProps,
  useSidebarState,
} from 'react-admin';
import SubMenu from './SubMenu/SubMenu';
import { DEFAULT_SCROLL_NAVIGATION } from './constant';
import { MenuContainerStyled } from './styles';
import pluginManager from '@PluginManager/PluginManager';

const Menu = ({ dense = false }: MenuProps) => {
  const translate = useTranslate();
  const [open] = useSidebarState();
  const [expandedMenuObj, setExpandedMenuObj] = useState<Record<
    string,
    boolean
  > | null>(null);
  const handleExpanded = (translationKey: string, state: boolean) => {
    window.localStorage.setItem(
      'expandedMenuTranslationKeys',
      JSON.stringify({
        ...JSON.parse(
          window.localStorage.getItem('expandedMenuTranslationKeys') || '{}'
        ),
        [translationKey]: state,
      })
    );
  };

  useEffect(() => {
    const expandedMenuTranslationKeys = window.localStorage.getItem(
      'expandedMenuTranslationKeys'
    );

    if (expandedMenuTranslationKeys) {
      setExpandedMenuObj(JSON.parse(expandedMenuTranslationKeys));
    } else {
      const expandedMenuObj = pluginManager.pluginsMenu.reduce<
        Record<string, boolean>
      >((acc, { rootMenu }) => {
        if (!rootMenu) return acc;
        acc[rootMenu.caption.translationKey] = false;

        return acc;
      }, {});

      window.localStorage.setItem(
        'expandedMenuTranslationKeys',
        JSON.stringify(expandedMenuObj)
      );
      setExpandedMenuObj(expandedMenuObj);
    }
  }, []);

  if (expandedMenuObj === null) return <></>;

  return (
    <MenuContainerStyled open={open}>
      <DashboardMenuItem />

      {pluginManager.pluginsMenu.map((menuConfig) => {
        const { rootMenu, items } = menuConfig;

        if (!items?.length) {
          return null;
        }

        const menuItems = items.map((props) => {
          const route = typeof props.route === 'function' ? props.route() : props.route;

          return (
            <MenuItemLink
              id={route}
              key={`${props.caption.translationKey}-${props.route}`}
              to={route}
              state={DEFAULT_SCROLL_NAVIGATION}
              primaryText={translate(
                props.caption.translationKey,
                props.caption.options
              )}
              leftIcon={props.icon}
              dense={dense}
              target={route.includes('http') ? '_blank' : undefined}
            />
          );
        });

        if (rootMenu) {
          return (
            <SubMenu
              key={rootMenu.caption.translationKey}
              name={translate(
                rootMenu.caption.translationKey,
                rootMenu.caption.options
              )}
              icon={rootMenu.icon}
              dense={dense}
              isExpanded={
                expandedMenuObj[rootMenu.caption.translationKey] || false
              }
              handleExpanded={(state) =>
                handleExpanded(rootMenu.caption.translationKey, state)
              }
            >
              {menuItems}
            </SubMenu>
          );
        }

        return menuItems;
      })}
    </MenuContainerStyled>
  );
};

export default Menu;
