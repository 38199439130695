import { Children, cloneElement, isValidElement, PropsWithChildren } from "react";
import {
  StyleProps,
} from "@Plugins/Stores/resources/Warehouses/pages/ItemPage/PageForm/tabs/DeliveryAreas/DeliveryAreaForm/interface";

export const TabContent = <T,>(
  props: PropsWithChildren<{ value: T }>
): JSX.Element => {
  const { value, children } = props;

  return (
    <>
      {Children.map(children, (child) => {
        if (
          isValidElement<StyleProps>(child) &&
          child.key !== `${value}`
        ) {
          return cloneElement<StyleProps>(child, {
            style: { display: 'none' },
          });
        }

        return child;
      })}
    </>
  );
};
