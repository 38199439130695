import { ReactElement } from 'react';
import {
  useRecordContext,
  FormTab,
  SaveButton,
  DeleteWithConfirmButton,
  useTranslate,
} from 'react-admin';
import { General } from './tabs/General/General';
import { WarehouseLocation } from './tabs/WarehouseLocation/WarehouseLocation';
import { Schedule } from './tabs/Schedule/Schedule';
import { DeliveryAreas } from './tabs/DeliveryAreas/DeliveryAreas';
import { useTabs } from '@Hooks/useTabs';
import { Loader, ResourceTabbedForm, ResourceTab } from '@UI';
import { FormProps } from '../../../../hooks/interface';
import { LinkedList } from '@Widgets/LinkedList/LinkedList';
import { ResourceMeta, USER_LOGGER_COLUMNS } from '../../../constants';
import { accessPermission } from '@Helpers';
import { ResourceRoutes } from '@Plugins/resourceRoutes';

import { StyledToolbar } from './styled';

export const PageEditForm = ({ errors }: FormProps): ReactElement => {
  const record = useRecordContext<CoreWarehouse>();
  const { selectedTab } = useTabs();
  const t = useTranslate();

  if (!record) return <Loader />;

  return (
    <ResourceTabbedForm
      record={record}
      toolbar={
        <StyledToolbar>
          <SaveButton />
          {accessPermission.checkPermission('Stores', 'D') && (
            <DeleteWithConfirmButton
              size="medium"
              label={t('stores.warehouses.pages.labels.deleteWarehouse')}
              confirmContent={t(
                'stores.warehouses.pages.phrases.deleteConfirmation'
              )}
            />
          )}
        </StyledToolbar>
      }
    >
      <FormTab label="stores.warehouses.pages.tabs.general.caption">
        <General errors={errors} />
      </FormTab>

      <FormTab label="stores.warehouses.pages.tabs.warehouseLocation.caption">
        <WarehouseLocation />
      </FormTab>

      <ResourceTab
        label="stores.warehouses.pages.tabs.deliveryArea.caption"
        subscribedFields={['isActive']}
      >
        {selectedTab === 2 && <DeliveryAreas />}
      </ResourceTab>

      <ResourceTab
        label="stores.warehouses.pages.tabs.schedule.caption"
        subscribedFields={['schedule']}
      >
        {selectedTab === 3 && <Schedule />}
      </ResourceTab>

      <FormTab label="stores.warehouses.pages.tabs.log.caption">
        {selectedTab === 4 && (
          <LinkedList
            target={ResourceMeta.WarehouseLog}
            cols={USER_LOGGER_COLUMNS}
            rowClick={(id, res, record) =>
              record.userId
                ? `${ResourceRoutes.users.routePath}/${record.userId}`
                : false
            }
          />
        )}
      </FormTab>
    </ResourceTabbedForm>
  );
};
