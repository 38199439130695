import { ReactNode, ReactElement } from 'react';
import {
  Datagrid,
  Identifier,
  Pagination,
  RaRecord,
  RecordContextProvider,
  ReferenceManyField,
  SimpleList,
  useListContext,
  useRecordContext,
  useResourceContext,
  FilterPayload,
  DatagridProps,
} from 'react-admin';
import { useIsMobile } from '@Hooks/useIsMobile';
import { ComponentFactory } from '@Widgets/ResourceList/ComponentFactory/ComponentFactory';
import { ReferenceManyFieldProps } from 'ra-ui-materialui';
import { ListColumn } from '@Widgets/ResourceList/interface';
import { DEFAULT_ITEMS_PER_PAGE } from '@ROOT/constants';

export interface LinkedListProps<RecordType extends RaRecord = RaRecord> {
  resource?: string;
  target: string;
  cols: ListColumn<RecordType>[];
  perPage?: ReferenceManyFieldProps['perPage'];
  sort?: ReferenceManyFieldProps['sort'];
  filter?: FilterPayload;
  rowClick?: (
    id: Identifier,
    resource: string,
    record: RaRecord
  ) => string | false;
  bulkActionButtons?: ReactElement | false;
  isRowSelectable?: DatagridProps<RecordType>['isRowSelectable'];
  identifier?: keyof RecordType; // field to pass to the getManyReference instead of "id"
  generateMobileTableContent?:
    | ((props: LinkedListProps<RecordType>) => ReactNode)
    | false;
}

export const LinkedList = <RecordType extends RaRecord = RaRecord>(
  props: LinkedListProps<RecordType>
) => {
  const {
    sort = {
      field: 'createdAt',
      order: 'DESC',
    },
    filter,
    perPage = DEFAULT_ITEMS_PER_PAGE,
    identifier = 'id',
    generateMobileTableContent,
    rowClick,
    bulkActionButtons = false,
    isRowSelectable,
  } = props;
  const isMobile = useIsMobile();
  const record = useRecordContext<RecordType>();
  const currentResource = useResourceContext();
  const identifierToPassAsId = record[identifier];
  let content: ReactNode;

  if (isMobile && generateMobileTableContent !== false) {
    content =
      generateMobileTableContent !== undefined ? (
        generateMobileTableContent(props)
      ) : (
        <SimpleList
          sx={{ padding: 0 }}
          primaryText={(record) => record.client}
          secondaryText={(record) => record.event}
          linkType={
            rowClick
              ? (record, id) => rowClick(id, '', record.userId) as string
              : undefined
          }
        />
      );
  } else {
    content = (
      <Datagrid
        sx={{ width: '100%' }}
        rowClick={rowClick}
        bulkActionButtons={bulkActionButtons}
        isRowSelectable={isRowSelectable}
      >
        {props.cols.map((column) => (
          <ComponentFactory
            sortBy={column.sortable ?? true ? column.source : undefined}
            key={column.source}
            column={column}
            label={column.label}
          />
        ))}
      </Datagrid>
    );
  }

  const PaginationExt = () => {
    const listContext = useListContext();

    return listContext.data?.length ? <Pagination /> : null;
  };

  return (
    <RecordContextProvider value={{ ...record, id: identifierToPassAsId }}>
      <ReferenceManyField
        reference={props.resource || currentResource}
        target={props.target}
        pagination={<PaginationExt />}
        sort={sort}
        perPage={perPage}
        filter={filter}
      >
        {content}
      </ReferenceManyField>
    </RecordContextProvider>
  );
};
