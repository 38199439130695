import { RowInfoBlock } from '@UI';
import { ComponentFactory } from '../../ComponentFactory/ComponentFactory';
import { MobileLayoutProps } from '../../interface';
import { RaRecord } from "react-admin";

export const MobileLayout = <RecordType extends RaRecord = RaRecord>({
  columns,
}: MobileLayoutProps<RecordType>) => {
  if (!columns.length) {
    return <></>;
  }

  return (
    <>
      {columns.map((col) => {
        const column = { ...col };

        if (column.type === 'text') {
          column.truncate = '100%';
        }

        return (
          <RowInfoBlock
            key={column.source}
            isInline={column.showInLine ?? true}
            titleKey={column.label as string}
          >
            <ComponentFactory column={column} />
          </RowInfoBlock>
        );
      })}
    </>
  );
};
