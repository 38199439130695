import { RequestAPI } from '@RestApi';
import { AbstractApi } from '@PluginBase';
import { CancelApiParams, OrderApiResponse } from './interface';

export class CancelOrderApi extends AbstractApi<
  CancelApiParams,
  OrderApiResponse
> {
  constructor(order: CoreOrder, reason: string) {
    super('/orders/v1/orders/cancel', {
      orderId: order.id,
      userCancelReason: reason,
      previousOrderData: order,
    });
  }

  async execute(): Promise<OrderApiResponse> {
    await RequestAPI.post(this.apiUrl, {
      id: this.params.orderId,
      user_cancel_reason: this.params.userCancelReason,
    });

    return {
      data: {
        ...this.params.previousOrderData,
        status: 'cancelled',
      },
    };
  }
}
