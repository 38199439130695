import { A, G, N, O } from '@mobily/ts-belt';
import { Array } from '@mobily/ts-belt/dist/types/types';

export const pluck = <A, K extends keyof A = keyof A>(
  fieldName: K
): ((xs: Array<A>) => Array<NonNullable<A[K]>>) =>
  A.filterMap((obj: A) =>
    G.isNotNullable<any>(obj[fieldName]) ? O.Some<any>(obj[fieldName]) : O.None
  );

export const sum = (arr: Array<number>) =>
  A.reduce<number, number>(arr, 0, N.add);
