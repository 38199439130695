import { SvgIcon } from '@mui/material';

const SideMenuIcon = () => {
  return (
    <SvgIcon viewBox="0 0 13 14">
      <path
        d="M7 7H12V12.5C12 13.0523 11.5523 13.5 11 13.5H7V7Z"
        fill="currentColor"
      />
      <path
        d="M6 7H1V12.5C1 13.0523 1.44772 13.5 2 13.5H6V7Z"
        fill="currentColor"
      />
      <rect
        width="13"
        height="2"
        rx="0.4"
        transform="matrix(1 0 0 -1 0 6)"
        fill="currentColor"
      />
      <path
        d="M7.00631 3C7.09142 1.88126 8.02613 1 9.16667 1C9.6269 1 10 1.3731 10 1.83333V2C10 2.55228 9.55228 3 9 3H7.00631Z"
        stroke="currentColor"
      />
      <path
        d="M5.99369 3C5.90858 1.88126 4.97387 1 3.83333 1C3.3731 1 3 1.3731 3 1.83333V2C3 2.55228 3.44772 3 4 3H5.99369Z"
        stroke="currentColor"
      />
    </SvgIcon>
  );
};

export default SideMenuIcon;
