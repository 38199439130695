import { useTranslate } from 'react-admin';
import { SyntheticEvent, useState } from 'react';
import { Identifier, RaRecord } from 'ra-core';
import { useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { Tabs, Tab } from '@mui/material';

import { UIDrawer } from '@UI';
import { TabContent } from '@Plugins/Settings/resources/Properties/pages/ListPage/tabs/TabContent';

import { OfferImports } from './tabs/OfferImports';
import { OfferExports } from './tabs/OfferExports';
import { DetailsPage } from './DetailsPage';
import { ListPageTab } from './interfaces';

export const ListPage = () => {
  const translate = useTranslate();
  const location = useLocation();
  const [searchParams, setParams] = useSearchParams();
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [rowRecord, setRowRecord] = useState<CoreOfferImport>();
  const onCloseDrawerHandle = () => setIsOpenDrawer(false);
  const onRowClickHandler = (
    id: Identifier,
    resource: string,
    record: RaRecord
  ) => {
    setRowRecord(record as CoreOfferImport);
    setIsOpenDrawer(true);

    return location.pathname;
  };

  const getCurrentTab = (): ListPageTab => {
    const tabValue = searchParams.get('tab');

    return (tabValue as ListPageTab) || ListPageTab.IMPORTS;
  };

  const handleChangeTab = (e: SyntheticEvent, tab: string) => {
    setParams([['tab', tab]]);
  };

  // TODO: realize mobile version too
  return (
    <>
      <Tabs value={getCurrentTab()} onChange={handleChangeTab}>
        <Tab
          label={translate('catalogue.pages.offerOperations.tabs.imports')}
          value={ListPageTab.IMPORTS}
        />
        <Tab
          label={translate('catalogue.pages.offerOperations.tabs.exports')}
          value={ListPageTab.EXPORTS}
        />
      </Tabs>
      <TabContent currentTab={getCurrentTab()}>
        <div key={ListPageTab.IMPORTS}>
          <OfferImports onRowClick={onRowClickHandler} />
        </div>
        <div key={ListPageTab.EXPORTS}>
          <OfferExports />
        </div>
      </TabContent>

      <UIDrawer isOpen={isOpenDrawer} onClose={onCloseDrawerHandle}>
        <DetailsPage record={rowRecord as CoreOfferImport} />
      </UIDrawer>
    </>
  );
};
