export const getDeliveryScheduleLocalization = () => {
  const deliveryScheduleFormLocalePath =
    'stores.warehouses.pages.tabs.deliveryArea.drawer.deliverySlots.labels';
  const deliveryScheduleCreationLocalePath =
    'stores.warehouses.pages.tabs.deliveryArea.drawer.deliverySlots.creation';
  const deliveryScheduleDeletionLocalePath =
    'stores.warehouses.pages.tabs.deliveryArea.drawer.deliverySlots.deletion';

  return {
    name: `${deliveryScheduleFormLocalePath}.name`,
    leadTime: `${deliveryScheduleFormLocalePath}.leadTime`,
    startTime: `${deliveryScheduleFormLocalePath}.startTime`,
    deliveryPrice: `${deliveryScheduleFormLocalePath}.deliveryPrice`,
    maxFutureDate: `${deliveryScheduleFormLocalePath}.maxFutureDate`,
    cutOffTime: `${deliveryScheduleFormLocalePath}.cutOffTime`,
    whProcessingDuration: `${deliveryScheduleFormLocalePath}.whProcessingDuration`,
    endTime: `${deliveryScheduleFormLocalePath}.endTime`,
    deliveryCapacity: `${deliveryScheduleFormLocalePath}.deliveryCapacity`,
    freeDeliveryThreshold: `${deliveryScheduleFormLocalePath}.freeDeliveryThreshold`,

    addNewSchedule: `${deliveryScheduleCreationLocalePath}.addNewSchedule`,
    addScheduleButton: `${deliveryScheduleCreationLocalePath}.addScheduleButton`,

    confirmActionModalTitle: `${deliveryScheduleDeletionLocalePath}.confirmActionModalTitle`,
    confirmActionModalContent: `${deliveryScheduleDeletionLocalePath}.confirmActionModalContent`,
    confirmButton: 'ra.action.confirm',
    declinedConfirmationButton: 'ra.action.cancel',
    emptySchedule: `${deliveryScheduleFormLocalePath}.emptySchedule`,
    slotDeliveryMinOrderValue:
      'stores.warehouses.pages.labels.slotDeliveryMinOrderValue',
    slotDeliveryMinOrderHint:
      'stores.warehouses.pages.hints.slotDeliveryMinOrderValue',
  };
};
