import { LIST_COLUMNS } from './constants';
import { ResourceNestedList } from '@Widgets/ResourceNestedList/ResourceNestedList';
import { ResourceRoutes } from '../../../resourceRoutes';
import { useGetList, CreateButton } from 'react-admin';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { Flex } from '@UI';
import { PER_PAGE_VALUE } from '@Widgets/ResourceNestedList/constants';

export const ListPage = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);

  const { data = [] } = useGetList(ResourceRoutes.catalog.resourcePath, {
    pagination: {
      page,
      perPage: PER_PAGE_VALUE,
    },
    sort: {
      field: 'sorting',
      order: 'ASC',
    },
  });

  function onRowClickHandler(recordId: number) {
    navigate(`${ResourceRoutes.catalog.resourcePath}/${recordId}`);
  }

  return (
    <Flex asColumn>
      <Flex pt={3} justifyContent="flex-end">
        <CreateButton />
      </Flex>

      <ResourceNestedList
        page={page}
        setPage={setPage}
        columns={LIST_COLUMNS}
        onRowClick={onRowClickHandler}
        data={data}
      />
    </Flex>
  );
};
