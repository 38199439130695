import styled from 'styled-components';
import { TableCell } from '@mui/material';

export const ProductNameCellStyled = styled(TableCell)`
  width: 350px;
`;

export const ActionCellStyled = styled(TableCell)`
  width: 50px;
`;

export const CellWithHelpIconStyled = styled.span`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
`;
