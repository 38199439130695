import {
  Button,
  useListContext,
  useTranslate,
  useUpdateMany,
} from 'react-admin';
import { ResourceRoutes } from "@Plugins/resourceRoutes";
import { pickersTranslationSchema } from '../../../constants';

export const GenerateBadgesButton = () => {
  const { selectedIds } = useListContext();
  const translate = useTranslate();
  const [mutate, { isLoading }] = useUpdateMany(ResourceRoutes.wmsPickers.resourcePath, {
    ids: selectedIds,
  });

  return (
    <>
      <Button
        onClick={() => mutate()}
        disabled={isLoading}
        label={translate(pickersTranslationSchema.actions.generateBadges)}
      />
    </>
  );
};
