import {
  TextInput,
  DateTimeInput,
  DateInput,
  FileInput,
  FileField,
  FormDataConsumer,
  TimeInput,
  required,
  Validator,
  number,
} from 'react-admin';
import { AdditionalListPropertiesProps } from '../interface';
import {
  ResourceSwitcher,
  ResourceColorInput,
  ResourceImageInput,
  ResourceInputNumber,
} from '@UI';
import { Link } from '@mui/material';
import { get, minNum, isInteger } from '@Helpers';

const validate = (type: CorePropertyType, code: string) => {
  if (type === 'toggle') return undefined;

  const validationList: Validator[] = [required()];

  if (type === 'number') {
    validationList.push(number(), minNum(0));
  }

  if (code === 'items_per_outer_retail_pack') {
    validationList.push(isInteger());
  }

  return validationList;
};

export const SingleProperty = ({
  source,
  label,
  type,
  code,
}: Omit<AdditionalListPropertiesProps, 'choices'>) => {
  const options = {
    name: source,
    source,
    label,
    translate: 'no' as const,
    fullWidth: true,
    validate: validate(type, code),
  };

  switch (type) {
    case 'text':
      return <TextInput {...options} type="text" />;
    case 'textarea':
      return <TextInput multiline rows={3} {...options} />;
    case 'toggle':
      return <ResourceSwitcher labelKey={options.label} {...options} />;
    case 'badge':
      return <ResourceSwitcher labelKey={options.label} {...options} />;
    case 'dateTime':
      return <DateTimeInput {...options} />;
    case 'time':
      return <TimeInput {...options} />;
    case 'date':
      return <DateInput {...options} />;
    case 'color':
      return <ResourceColorInput labelKey={label} {...options} />;
    case 'file':
      return (
        <FileInput {...options}>
          <FormDataConsumer>
            {({ formData }) => {
              const data = get(formData, source);

              if (typeof data === 'string') {
                return (
                  <Link href={data} target="_blank" download variant="body2">
                    {data}
                  </Link>
                );
              }

              return (
                <FileField
                  download
                  target="_blank"
                  source="src"
                  title="title"
                />
              );
            }}
          </FormDataConsumer>
        </FileInput>
      );
    case 'image':
      return <ResourceImageInput {...options} />;
    case 'number':
      return <ResourceInputNumber {...options} />;
    default:
      return null;
  }
};
