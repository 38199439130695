import MapIcon from '@mui/icons-material/Map';

import { PluginResourceController } from '@PluginBase';
import { accessPermission } from '@Helpers/AccessPermissions/AccessPermissions';
import { CompanyName } from '@Helpers/AccessPermissions/interface';

const getRemoteUrl = (): string => {
  if (process.env.REACT_APP_DELIVERY_DISPATCHER_URL) {
    return process.env.REACT_APP_DELIVERY_DISPATCHER_URL;
  }

  if (!accessPermission.accessToken) {
    accessPermission.parseAccessToken();
  }

  if (accessPermission.companyName === CompanyName.CityDrinks) {
    return 'https://frontend-delivery.citydrinks.com/';
  }

  if (
    process.env.NODE_ENV === 'development' ||
    process.env.REACT_APP_ENV === 'development'
  ) {
    return 'https://frontend-delivery.jiffygrocery.co.uk/';
  }

  return window.location.origin.replace('cms', 'delivery');
};

const DeliveryDispatcherPages = new PluginResourceController({
  resourceRoute: null,
  menuItem: {
    route: getRemoteUrl,
    caption: {
      translationKey: 'deliveryDispatcher.caption',
    },
    icon: <MapIcon />,
  },
});

export default DeliveryDispatcherPages;
