import { PluginResourceController } from '@PluginBase';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { ListPage } from './pages/ListPage/ListPage';
import { ResourceRoutes } from '../../../resourceRoutes';

const ReportResourceController = new PluginResourceController({
  menuItem: {
    caption: {
      translationKey: 'reports.caption',
    },
    icon: <SummarizeIcon />,
    route: ResourceRoutes.reports.routePath,
  },
  resourceRoute: {
    name: ResourceRoutes.reports.resourcePath,
    list: ListPage,
  },
  dataProvider: {},
});

export default ReportResourceController;
