import {
  useRecordContext,
  Confirm,
  useTranslate,
  useRefresh,
} from 'react-admin';
import { useMemo, useState } from 'react';
import {
  MAX_SHIPMENT_PROCESSING_MINUTES,
  apiUrl,
} from '../Shipments/constants';
import WarningIcon from '@mui/icons-material/Warning';
import Tooltip from '@mui/material/Tooltip';
import ReplayIcon from '@mui/icons-material/Replay';
import { Flex } from '@UI';
import { formatDuration, intervalToDuration } from 'date-fns';
import { formatEntityName } from '@ROOT/helpers/FormatEntityName/FormatEntityName';
import { RequestAPI } from '@RestApi';

export const ShipmentProcessingToolbar = () => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const record = useRecordContext<CoreShipments>();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleConfirm = async () => {
    setIsOpen(false);
    setIsLoading(true);

    try {
      await RequestAPI.put(`${apiUrl}/${record.id}/process`);

      refresh();
    } catch (error) {
      //
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Checks if WMS processing time excessed
   */
  const isProcessingTimeExcess = useMemo(() => {
    const diff = formatDuration(
      intervalToDuration({
        start: new Date(record.wmsProcessedAt),
        end: Date.now(),
      }),
      {
        format: ['minutes'],
      }
    );

    return parseInt(diff, 10) > MAX_SHIPMENT_PROCESSING_MINUTES;
  }, [record.wmsProcessedAt]);

  return (
    <Flex justifyContent="center" gap={1} mt={1}>
      {record.wmsProcessingErrors && (
        <Tooltip title={record.wmsProcessingErrors.join(', ')}>
          <WarningIcon color="error" />
        </Tooltip>
      )}

      {(record.wmsProcessingErrors || isProcessingTimeExcess) && (
        <ReplayIcon
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen(true);
          }}
        />
      )}

      <Confirm
        isOpen={isOpen}
        loading={isLoading}
        title={translate(
          'distributionCenter.pages.shipments.messages.startProcess'
        )}
        content={formatEntityName('OUT', 8, record.displayId)}
        onConfirm={handleConfirm}
        onClose={() => setIsOpen(false)}
      />
    </Flex>
  );
};
