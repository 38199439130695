import { ReactElement } from 'react';
import { ResourceList } from '@Widgets/ResourceList/ResourceList';
import { useTranslate, useUpdate } from 'react-admin';
import { Flex } from '@UI';
import { Button } from '@mui/material';
import { useCheckMutationAnswer } from '@Hooks/useCheckMutationAnswer';
import { ResourceRoutes } from '@PluginManager/plugins/resourceRoutes';
import { LIST_COLUMNS } from './constants';
import { Predict } from './interface';
import { ExportXLSXButton } from '@Helpers';
import { ReplenishmentApiResource } from '@Plugins/Replenishment/apiRoutes';
import { FILTERS } from '@Plugins/Replenishment/resources/Calculate/constants';

export const ListPage = (): ReactElement => {
  const translate = useTranslate();
  const [update, { error, isSuccess }] = useUpdate();

  useCheckMutationAnswer({
    successMessage: 'replenishment.pages.calculate.messages.successImport',
    errorMessage: 'replenishment.pages.calculate.errors.errorImport',
    error: error as Error,
    isSuccess,
    shouldUpdateList: true,
  });

  async function onHandler() {
    await update(ResourceRoutes.replenishment.calculate.resourcePath, {
      meta: 'calculate',
    });
  }

  return (
    <ResourceList<Predict>
      empty={false}
      exporter={false}
      bulkActionButtons={false}
      filters={FILTERS}
      columns={LIST_COLUMNS}
      showDeleteButton={false}
      actions={
        <Flex alignItems="center">
          <Button onClick={onHandler}>
            {translate('replenishment.pages.calculate.actions.calculate')}
          </Button>
          <ExportXLSXButton
            url={ReplenishmentApiResource.replenishmentExport}
            filename="replenishment"
          />
        </Flex>
      }
      getItemCardCaption={(record) =>
        translate('replenishment.pages.calculate.labels.mobileCardCaption', {
          id: record.id,
        })
      }
    />
  );
};
