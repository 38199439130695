import type { PickingMetrics, PickingOrderItemsCount } from './interface';

export function countPickingMetrics(record: CorePickingOrderDto): PickingMetrics {
  if (!record.items?.length) {
    return {
      itemsRequested: 0,
      itemsPicked: 0,
      fulfillmentRate: 0,
      pickingTimePerItemInSeconds: 0,
    };
  }

  const { itemsRequested, itemsPicked } = record.items?.reduce<PickingOrderItemsCount>((count, item) => {
    count.itemsPicked += item.quantityPicked;
    count.itemsRequested += item.quantityToPick;

    return count;
  }, {
    itemsPicked: 0,
    itemsRequested: 0,
  });

  const completedAt = record.completedAt
    ? new Date(record.completedAt)
    : new Date();

  const pickingTime = record.startedAt
    ? completedAt.getTime() - new Date(record.startedAt).getTime()
    : 0;

  const pickingTimeInSeconds = Math.ceil(pickingTime / 1000);

  return {
    itemsPicked,
    itemsRequested,
    fulfillmentRate: Math.round(itemsPicked / itemsRequested * 100),
    pickingTimePerItemInSeconds: pickingTimeInSeconds ? Number(
      (pickingTimeInSeconds / itemsPicked).toFixed(2)) : undefined,
  };
}
