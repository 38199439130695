import { CreateButton, FilterButton, useTranslate } from 'react-admin';

import { Flex } from '@UI';

import { accessPermission } from '@Helpers';

import { ResourceList } from '@Widgets/ResourceList/ResourceList';

import { LIST_FILTERS } from '../share/constants';

import { BUNDLE_LIST_COLUMNS } from './constants';

export const ListPage = () => {
  const translate = useTranslate();

  return (
    <ResourceList<CoreBundle>
      columns={BUNDLE_LIST_COLUMNS}
      showDeleteButton={accessPermission.checkPermission('Catalogue', 'D')}
      actions={
        <Flex alignItems="center">
          <FilterButton />
          <CreateButton />
        </Flex>
      }
      filters={LIST_FILTERS}
      defaultSort={{
        field: 'createdAt',
        order: 'DESC',
      }}
      getItemCardCaption={record =>
        translate('catalogue.pages.bundles.labels.mobileCardCaption', {
          id: record.id,
        })
      }
    />
  );
};
