import { Filter, ListColumn } from '@Widgets/ResourceList/interface';
import {
  PICKING_ORDER_STATUS_FILTER,
  pickingOrdersTranslationSchema,
} from '../../constants';
import { RESOURCES_SCHEMA } from '../../schemas/resourcesSchema';
import { ItemsCount } from '../components/ItemsCount/ItemsCount';
import { Picker } from '../components/Picker/Picker';
import { StatusBadgeResource } from '../components/StatusBadgeResource/StatusBadgeResource';

export const DEFAULT_SORTING = {
  field: RESOURCES_SCHEMA.createdAt,
  order: 'DESC',
};

export const DEFAULT_PER_PAGE = 10;

export const FILTERS: Filter[] = [
  {
    source: RESOURCES_SCHEMA.search,
    type: 'search',
  },
  {
    source: 'status',
    type: 'choose-multi',
    choices: PICKING_ORDER_STATUS_FILTER,
    label: pickingOrdersTranslationSchema.labels.status,
  },
  {
    source: RESOURCES_SCHEMA.warehouse,
    label: pickingOrdersTranslationSchema.labels.warehouse,
    type: 'text',
  },
];

// TODO: remove "any" from generic
export const LIST_COLUMNS: ListColumn<CorePickingOrderDto & any>[] = [
  {
    source: RESOURCES_SCHEMA.orderId,
    label: pickingOrdersTranslationSchema.labels.orderId,
    type: 'text',
  },
  {
    source: RESOURCES_SCHEMA.warehouse,
    label: pickingOrdersTranslationSchema.labels.warehouse,
    type: 'text',
  },
  {
    source: RESOURCES_SCHEMA.picker,
    label: pickingOrdersTranslationSchema.labels.picker,
    type: 'custom',
    customComponent: (
      <Picker key={pickingOrdersTranslationSchema.labels.picker} />
    ),
    sortable: false,
  },
  {
    source: RESOURCES_SCHEMA.status,
    label: pickingOrdersTranslationSchema.labels.status,
    type: 'custom',
    customComponent: (
      <StatusBadgeResource key={pickingOrdersTranslationSchema.labels.status} />
    ),
  },
  {
    source: RESOURCES_SCHEMA.createdAt,
    label: pickingOrdersTranslationSchema.labels.createdAt,
    type: 'date',
    showTime: true,
  },
  {
    source: RESOURCES_SCHEMA.startedAt,
    label: pickingOrdersTranslationSchema.labels.startedAt,
    type: 'date',
    showTime: true,
  },
  {
    source: RESOURCES_SCHEMA.startAfter,
    label: pickingOrdersTranslationSchema.labels.startAfter,
    type: 'date',
    sortable: true,
    showTime: true,
  },
  {
    source: RESOURCES_SCHEMA.completedAt,
    label: pickingOrdersTranslationSchema.labels.completedAt,
    type: 'date',
    showTime: true,
  },
  {
    source: RESOURCES_SCHEMA.shipBy,
    label: pickingOrdersTranslationSchema.labels.shipBy,
    type: 'date',
    showTime: true,
  },
  {
    source: RESOURCES_SCHEMA.items,
    label: pickingOrdersTranslationSchema.labels.items,
    type: 'custom',
    customComponent: (
      <ItemsCount key={pickingOrdersTranslationSchema.labels.items} />
    ),
    sortable: false,
  },
];
