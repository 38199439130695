import { useRecordContext, useTranslate } from 'react-admin';
import { Chip, ChipProps } from '@mui/material';

import { OfferExportStatus } from '../../interfaces';

const colors: Record<OfferExportStatus, ChipProps['color']> = {
  [OfferExportStatus.CREATED]: 'default',
  [OfferExportStatus.IN_PROGRESS]: 'info',
  [OfferExportStatus.FAILED]: 'error',
  [OfferExportStatus.FINISHED]: 'success',
};

const StatusColumn = () => {
  const { status } = useRecordContext<CoreOfferExport>();

  const translate = useTranslate();

  return (
    <Chip
      label={translate(
        `catalogue.pages.offerOperations.offerExports.statuses.${status}`
      )}
      color={status in colors ? colors[status as OfferExportStatus] : 'primary'}
      size="small"
    />
  );
};

export default StatusColumn;
