import { ScheduleDay } from '@Widgets/Schedule/interface';
import { pointsToUnits, unitsToPoints } from '@Helpers';

export const checkIntervals = (schedule: ScheduleDay[]): ScheduleDay[] => {
  if (!schedule.length) return schedule;

  schedule = schedule.map((item) => {
    if (
      item.intervals.some(
        ({ start, end }) =>
          start === end ||
          (start === '00:00:00' &&
            (end === '00:00:00' || end.startsWith('23:59')))
      )
    ) {
      item.intervals = [{ start: '00:00:00', end: '23:59:59' }];
    }

    return item;
  });

  return schedule;
};

export const convertDeliveryAreaValues = (
  data: CoreDeliveryAreaEntity,
  direction: 'input' | 'output'
): CoreDeliveryAreaEntity => {
  if (direction === 'output') {
    data.delivery_price = unitsToPoints(data.delivery_price);
    data.delivery_price_max = unitsToPoints(data.delivery_price_max);
    data.delivery_price_threshold = unitsToPoints(
      data.delivery_price_threshold
    );
    data.service_fee = unitsToPoints(data.service_fee);
    data.service_fee_threshold = unitsToPoints(data.service_fee_threshold);

    if (!data.eta_fixed) {
      data.eta_fixed_max = 0;
      data.eta_fixed_min = 0;
    } else {
      data.eta_fixed_max = parseInt(
        (data.eta_fixed_max as unknown as string) || '0',
        10
      );
      data.eta_fixed_min = parseInt(
        (data.eta_fixed_min as unknown as string) || '0',
        10
      );
    }

    data.priority = parseInt(data.priority as unknown as string, 10);
  } else {
    data.delivery_price = pointsToUnits(
      data.delivery_price
    ) as unknown as number;
    data.delivery_price_max = pointsToUnits(
      data.delivery_price_max
    ) as unknown as number;
    data.delivery_price_threshold = pointsToUnits(
      data.delivery_price_threshold
    ) as unknown as number;
    data.service_fee = pointsToUnits(data.service_fee) as unknown as number;
    data.service_fee_threshold = pointsToUnits(
      data.service_fee_threshold
    ) as unknown as number;
    data.eta_fixed_max = data.eta_fixed_max || ('' as unknown as number);
    data.eta_fixed_min = data.eta_fixed_min || ('' as unknown as number);
  }

  return data;
};
