import styled from 'styled-components';
import { styled as styledMaterial } from '@mui/material/styles';
import {
  List,
  SelectInput,
  DateInput,
  TextField,
  UrlField,
  SelectArrayInput,
  TextInput,
} from 'react-admin';
import { Alert, AlertProps } from '@mui/material';
import lightenDarkenColor from 'src/helpers/Colors/LightenDarkenColor';

import { getResourceFieldTruncateStyles } from '../../commonStyles';

const errorBgColor = '#f44336';

const filterDefaultStyles = `
  width: 100% !important;
  min-width: 200px !important;
`;

export const ResourceListStyled = styled(List)<{ $isMobile?: boolean }>`
  .RaList-main {
    margin-top: 15px;

    .MuiToolbar-root .filter-field {
      width: ${({ $isMobile }) => ($isMobile ? '100%' : '215px')};
    }
  }
`;

export const MuiAlertStyled = styledMaterial(Alert)<AlertProps>(() => {
  const buttonRootClass = '.MuiButtonBase-root';

  return {
    backgroundColor: errorBgColor,
    color: 'white',
    [`& ${buttonRootClass}`]: {
      backgroundColor: lightenDarkenColor(errorBgColor, -30),
    },
    [`& ${buttonRootClass}:hover`]: {
      backgroundColor: lightenDarkenColor(errorBgColor, -50),
    },
  };
});

export const FilterSearchInput = styled(TextInput)`
  ${filterDefaultStyles};
`;

export const FilterSelectInput = styled(SelectInput)`
  ${filterDefaultStyles}
`;

export const FilterMultiSelectInput = styled(SelectArrayInput)`
  ${filterDefaultStyles}
  .RaSelectArrayInput-chip {
    font-size: 12px;
    height: 19px;

    span {
      padding: 0 4px;
    }
  }

  .MuiInputLabel-root {
    transform: translate(9px, 9px) scale(1);
    background: #19222d;
    padding: 0 6px;
  }

  .MuiInputLabel-shrink {
    transform: translate(9px, -9px) scale(0.75);
  }
`;

export const FilterDateInput = styled(DateInput)`
  ${filterDefaultStyles}
`;

export const FilterTextInput = styled(TextInput)`
  ${filterDefaultStyles}
`;

export const TextFieldStyled = styled(TextField)<{
  truncate?: number | string;
}>`
  ${({ truncate }) =>
    !!truncate &&
    `
      max-width: ${truncate}${
      (truncate as string).length === undefined ? 'px' : ''
    };
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: middle;
  `}
`;

export const UrlFieldStyled = styled(UrlField)<{ truncate?: number }>`
  ${({ truncate }) => getResourceFieldTruncateStyles(truncate)}
`;
