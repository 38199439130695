import { useTranslate } from 'react-admin';
import { useController } from 'react-hook-form';
import { useQuery } from 'react-query';
import { Tooltip } from '@mui/material';
import { Help } from '@mui/icons-material';

import { ResourceMoneyInput, RowInfoBlock, Flex } from '@UI';
import {
  DeliveryAreaDetailedEntity,
  ShippingMechanicCode,
} from '@Plugins/Stores/resources/Warehouses/interface';
import { minNum, pointsToUnits } from '@Helpers';

const SlotDeliveryMechanicSettings = () => {
  const t = useTranslate();
  const {
    field: { value: zoneShippingMethods = [] },
  } = useController<DeliveryAreaDetailedEntity, 'zoneShippingMethods'>({
    name: 'zoneShippingMethods',
  });

  // const slotShippingMethodIndex = zoneShippingMethods

  const { data: deliveryMechanicsData = {} } = useQuery<
    Record<string, CoreShippingMechanic>
  >({
    queryKey: 'deliveryMechanics',
  });

  const slotDeliveryMechanic = deliveryMechanicsData[ShippingMechanicCode.SLOT];

  const slotDeliverySettingIndex = zoneShippingMethods.findIndex(
    (item) =>
      slotDeliveryMechanic &&
      item.deliveryMechanicId === slotDeliveryMechanic.id
  );

  if (slotDeliverySettingIndex < 0) {
    return <></>;
  }

  return (
    <RowInfoBlock
      asColumn
      titleKey="stores.warehouses.pages.labels.slotDeliverySettings"
    >
      <ResourceMoneyInput
        defaultValue="0"
        fullWidth
        name={`zoneShippingMethods.${slotDeliverySettingIndex}.order_amount_threshold`}
        source={`zoneShippingMethods.${slotDeliverySettingIndex}.order_amount_threshold`}
        label={
          <Flex asColumn={false} alignItems="center" gap={1}>
            <span>
              {t('stores.warehouses.pages.labels.slotDeliveryMinOrderValue')}
            </span>
            <Tooltip
              placement="top"
              title={t(
                'stores.warehouses.pages.hints.slotDeliveryMinOrderValue'
              )}
            >
              <Help />
            </Tooltip>
          </Flex>
        }
        validate={validateAmountThreshold}
      />
    </RowInfoBlock>
  );
};

const validateAmountThreshold = [minNum(parseFloat(pointsToUnits(0)))];

export default SlotDeliveryMechanicSettings;
