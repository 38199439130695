import { number, minValue, maxValue, maxLength } from 'react-admin';
import { Draggable } from 'react-beautiful-dnd';
import { TableBody, TableCell, TableRow } from '@mui/material';
import { DragIndicator } from '@mui/icons-material';

import { Flex } from '@UI';
import { isInteger } from '@Helpers';

import { PickingPriorityRemoteItem } from '../ReorderResourceTable';
import { PickingPriorityEditingTableCell } from '../PickingPriorityEditingTableCell';
import { PickingPriorityTableBodyProps } from './interfaces';

const pickingPriorityValidators = [
  number('wms-picking.pages.picking-priority.errors.mustBeNumber'),
  isInteger(),
  minValue(
    1,
    'wms-picking.pages.picking-priority.errors.mustBeGreaterThenZero'
  ),
];

const pickingCodeValidators = [maxLength(10)];

const PickingPriorityTableBody = (props: PickingPriorityTableBodyProps) => {
  const {
    rows,
    total,
    draggingRowWidth,
    droppableProvided,
    onRowDataEdit,
    loading,
  } = props;

  const pickingCodeSaveHandlerFactory =
    (item: PickingPriorityRemoteItem) =>
    (value: string): void => {
      onRowDataEdit?.(item.id, { pickingCode: value.trim() });
    };

  const pickingPrioritySaveHandlerFactory =
    (item: PickingPriorityRemoteItem) =>
    (value: string): void => {
      if (!value) {
        return;
      }

      const numericVal = Number(value);

      if (isNaN(numericVal) || numericVal <= 0) {
        return;
      }

      onRowDataEdit?.(item.id, { pickingPriority: numericVal });
    };

  return (
    <TableBody>
      {rows.map((row, index) => {
        const item = row as PickingPriorityRemoteItem;

        return (
          <Draggable
            key={item.id}
            draggableId={item.id.toString()}
            index={index}
          >
            {(draggableProvided, snapshot) => {
              return (
                <TableRow
                  ref={draggableProvided.innerRef}
                  {...draggableProvided.draggableProps}
                  {...draggableProvided.dragHandleProps}
                  className={snapshot.isDragging ? 'draggingRow' : undefined}
                  style={{
                    ...draggableProvided.draggableProps.style,
                    ...(snapshot.isDragging &&
                      draggingRowWidth && {
                        width: draggingRowWidth,
                        display: 'table',
                      }),
                  }}
                >
                  <TableCell>
                    <Flex
                      asColumn={false}
                      justifyContent="flex-start"
                      alignItems="center"
                      gap={2}
                    >
                      <DragIndicator color="disabled" />
                      <span>{item.name}</span>
                    </Flex>
                  </TableCell>
                  <TableCell>{item.path}</TableCell>
                  <PickingPriorityEditingTableCell
                    label="wms-picking.pages.picking-priority.labels.pickingCode"
                    fieldValue={item.pickingCode ?? ''}
                    onSave={pickingCodeSaveHandlerFactory(item)}
                    disabled={loading}
                    validators={pickingCodeValidators}
                  />
                  <PickingPriorityEditingTableCell
                    label="wms-picking.pages.picking-priority.labels.pickingPriority"
                    fieldValue={`${item.pickingPriority ?? 0}`}
                    onSave={pickingPrioritySaveHandlerFactory(item)}
                    disabled={loading}
                    type="number"
                    validators={
                      !total
                        ? pickingPriorityValidators
                        : [...pickingPriorityValidators, maxValue(total)]
                    }
                  />
                </TableRow>
              );
            }}
          </Draggable>
        );
      })}
      {droppableProvided.placeholder}
    </TableBody>
  );
};

export default PickingPriorityTableBody;
