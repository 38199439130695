import { Flex } from '@UI';
import { useRecordContext } from 'react-admin';
import { Typography } from '@mui/material';
import { DateFormatter } from '@Helpers';

export const ActivityRange = () => {
  const { dateRange } = useRecordContext<CorePromocode>();
  const [startDate, endDate] = dateRange;

  return (
    <Flex alignItems='center' flexWrap='wrap'>
      <Typography fontSize={14} whiteSpace='nowrap'>{new DateFormatter(startDate).relativeNow()}&nbsp;-&nbsp;</Typography>

      <Typography fontSize={14} whiteSpace='nowrap'>{new DateFormatter(endDate).relativeNow()}</Typography>
    </Flex>
  );
};

ActivityRange.defaultProps = {
  label: 'promotions.pages.promocodes.labels.activityRange',
};
