import {
  Box,
  Typography,
  FormControl,
  Button,
  TextField,
  InputAdornment,
  IconButton,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useState, ChangeEvent, FormEvent } from 'react';
import { useTranslate, useNotify, useLogin } from 'react-admin';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import { AxiosError } from 'axios';

const LoginFlow = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [loginValue, setLoginValue] = useState('');
  const [passwordValue, setPasswordValue] = useState('');
  const [isShowPassword, setIsShowPassword] = useState(false);

  const translate = useTranslate();
  const notify = useNotify();
  const login = useLogin();

  const handleChangeLogin = (e: ChangeEvent<HTMLInputElement>) =>
    setLoginValue(e.currentTarget.value);

  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>) =>
    setPasswordValue(e.currentTarget.value);

  const handleToggleShowPassword = () => setIsShowPassword(!isShowPassword);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    login({
      login: loginValue.trim(),
      password: passwordValue.trim(),
    })
      .finally(() => setIsLoading(false))
      .catch((error: AxiosError<CoreRestApiErrorResponse>) => {
        let message = error.response?.data?.error || 'ra.auth.sign_in_error';

        if (
          error.response?.data?.message &&
          typeof error.response?.data?.message === 'string'
        ) {
          message = error.response?.data?.message;
        }

        notify(message, {
          type: 'error',
          messageArgs: {
            _: message,
          },
        });
      });
  };

  const isValid = () =>
    loginValue.trim().length > 0 && passwordValue.trim().length > 0;

  return (
    <Box>
      <Typography textAlign="center" fontSize="16px" mb={1}>
        {translate('pages.login.enterLogin')}
      </Typography>

      <form onSubmit={handleSubmit} autoComplete="off">
        <FormControl fullWidth>
          <TextField
            value={loginValue}
            onChange={handleChangeLogin}
            type="text"
            autoComplete="off"
            label={translate('pages.login.login')}
            variant="outlined"
            inputProps={{
              autoFocus: true,
            }}
          />
        </FormControl>

        <FormControl fullWidth>
          <TextField
            value={passwordValue}
            onChange={handleChangePassword}
            type={isShowPassword ? 'text' : 'password'}
            autoComplete="off"
            label={translate('pages.login.password')}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleToggleShowPassword}
                    edge="end"
                  >
                    {isShowPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormControl>

        <Button
          variant="contained"
          type="submit"
          color="primary"
          sx={{ mt: 2 }}
          fullWidth
          disabled={!isValid() || isLoading}
        >
          {translate('pages.login.signin')}

          {isLoading ? (
            <CircularProgress
              color="primary"
              size="16px"
              sx={{ ml: 1 }}
              thickness={6}
            />
          ) : (
            <ArrowForwardIosIcon fontSize="small" sx={{ ml: '5px' }} />
          )}
        </Button>
      </form>
    </Box>
  );
};

export default LoginFlow;
