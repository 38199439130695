import { FieldGroupProps } from './interface';
import { Typography } from '@mui/material';
import { Flex } from '@UI';
import { PropsWithChildren } from 'react';
import { useTranslate } from 'react-admin';
import { useTheme } from '@mui/material/styles';
import { FlexProps } from '../Flex/interface';

export const RowInfoBlock = ({
  titleKey,
  title,
  children,
  isInline,
  titleTranslateOptions,
  ...rest
}: PropsWithChildren<Partial<FieldGroupProps> & FlexProps>) => {
  const translate = useTranslate();
  const theme = useTheme();
  let caption = title;

  if (!caption && titleKey) {
    caption = `${translate(titleKey, titleTranslateOptions)} `;
  }

  return (
    <Flex
      padding={2}
      mb={1}
      alignItems={isInline ? 'center' : 'start'}
      justifyContent={isInline ? 'space-between' : 'flex-start'}
      bgcolor={theme.palette.background.default}
      asColumn={!isInline}
      borderRadius={2}
      flexWrap="wrap"
      {...rest}
    >
      {caption && (
        <Typography variant="caption" mb={1}>
          {caption}
        </Typography>
      )}

      {children}
    </Flex>
  );
};
