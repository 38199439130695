import { Button, Menu, MenuItem, Typography, Divider } from '@mui/material';
import {
  SimpleForm,
  useTranslate,
  useRecordContext,
  TextInput,
  SelectInput,
  required,
  useNotify,
  useUpdate,
  RaRecord,
  useRefresh,
} from 'react-admin';
import { DefaultRefundCase, PartialRefundCases } from './constants';
import { useRef, useState, useEffect, MouseEvent, ChangeEvent } from 'react';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Flex,
  Loader,
  UIDialog,
  Preview,
  ResourceInputNumber,
  ResourceMoneyInput,
} from '@UI';
import {
  MoneyFormatter,
  pointsToUnits,
  unitsToPoints,
  minNum,
  maxNum,
} from '@Helpers';
import { useTheme } from '@mui/material/styles';
import { ApiActionCall } from '../../../../routes';
import { PartialRefundItem } from '../../../interface';
import { ResourceRoutes } from '@PluginManager/plugins/resourceRoutes';

export const PartialRefund = () => {
  const refresh = useRefresh();
  const record = useRecordContext<CoreOrder>();
  const orderCurrency = record.currency;
  const theme = useTheme();
  const [partialRefundFields, setPartialRefundFields] = useState<{
    parts: PartialRefundItem[];
  }>({ parts: [] });
  const buttonRef = useRef(null);
  const translate = useTranslate();
  const [showDropDown, setShowDropDown] = useState(false);
  const [selectableItems, setSelectableAdded] = useState<SelectOption[]>([]);
  const notify = useNotify();
  const [updateRefund, { isLoading, error, isSuccess }] = useUpdate();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const currencySymbol = MoneyFormatter.getCurrencySymbol(orderCurrency);
  const onCloseConfirmDialogHandler = () => setIsConfirmDialogOpen(false);
  const onOpenConfirmDialogHandler = () => setIsConfirmDialogOpen(true);
  const onToggleDropdownHandler = () =>
    setShowDropDown((prevState) => !prevState);
  const onCloseDropdownHandler = () => setShowDropDown(false);
  const onMakeRefundHandler = () => {
    setIsConfirmDialogOpen(false);
    updateRefund(ResourceRoutes.orders.resourcePath, {
      meta: ApiActionCall.partialRefund,
      previousData: record,
      data: partialRefundFields.parts,
    }).catch(() => undefined);
  };
  const onRemoveItemHandler = (event: MouseEvent<HTMLButtonElement>) => {
    const selectedId = event.currentTarget.id;
    const item = record.items.find(({ sku }) => sku === selectedId);

    if (!item) return;

    setSelectableAdded((prevState) => [
      ...prevState,
      {
        name: item.name,
        id: item.sku,
      },
    ]);

    setPartialRefundFields({
      parts: partialRefundFields.parts.filter(({ sku }) => sku !== selectedId),
    });
  };
  const onAddItemHandler = (event: MouseEvent<HTMLElement>) => {
    const selectedId = event.currentTarget.id;

    setSelectableAdded((prevState) =>
      prevState.filter(({ id }) => id !== selectedId)
    );
    const item = record.items.find(({ sku }) => sku === selectedId);

    if (!item) {
      return;
    }

    const { actual_quantity, name, image, refunded_quantity, paid_price } =
      item;
    const itemPrice = new MoneyFormatter(paid_price, orderCurrency);
    const actualQuantity = actual_quantity - (refunded_quantity ?? 0);

    setPartialRefundFields((prevState) => ({
      parts: [
        ...prevState.parts,
        {
          sku: selectedId,
          selectedProblemId: '',
          selectedQuantity: actualQuantity,
          orderCurrentQuantity: actualQuantity,
          typedComment: '',
          name,
          resultRefund: itemPrice.amount * actualQuantity,
          image,
          formattedUnitsRefund: pointsToUnits(
            itemPrice.amount * actualQuantity,
            false
          ),
          paidPricePerUnit: itemPrice.amount,
        },
      ],
    }));

    setShowDropDown(false);
  };
  const onFormChanged = (e: RaRecord | ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    const [recordWithIndex, fieldName] = name.split('.');
    const index = parseInt(recordWithIndex.replace(/\D/gi, ''));
    const record = partialRefundFields.parts[index];

    if (fieldName === 'typedComment') {
      record.typedComment = value;

      setPartialRefundFields({
        parts: [...partialRefundFields.parts],
      });

      return;
    }

    if (fieldName === 'formattedUnitsRefund') {
      record.formattedUnitsRefund = value;
      record.resultRefund = unitsToPoints(value);

      setPartialRefundFields({
        parts: [...partialRefundFields.parts],
      });

      return;
    }

    if (fieldName === 'selectedProblemId') {
      record.selectedProblemId = value;

      setPartialRefundFields({
        parts: [...partialRefundFields.parts],
      });

      return;
    }

    const newRefundQuantity = parseInt(value, 10);

    if (
      isNaN(newRefundQuantity) ||
      newRefundQuantity < 0 ||
      newRefundQuantity > record.orderCurrentQuantity
    )
      return;

    record.selectedQuantity = newRefundQuantity;
    record.formattedUnitsRefund = pointsToUnits(
      record.paidPricePerUnit * newRefundQuantity,
      false
    );
    record.resultRefund = record.paidPricePerUnit * newRefundQuantity;

    setPartialRefundFields({
      parts: [...partialRefundFields.parts],
    });
  };

  useEffect(() => {
    if (error) {
      notify('orders.pages.actions.makeRefund.errorMessage', { type: 'error' });

      return;
    }

    if (isSuccess) {
      notify('orders.pages.actions.makeRefund.successPartialMessage', {
        type: 'success',
      });
      refresh();
      setPartialRefundFields({ parts: [] });
    }
  }, [error, isSuccess]);

  useEffect(() => {
    if (record.items.length) {
      const addedSku = partialRefundFields.parts.map(({ sku }) => sku);

      setSelectableAdded(
        record.items
          .filter(
            ({ sku, actual_quantity, refunded_quantity }) =>
              !addedSku.includes(sku) &&
              actual_quantity > 0 &&
              actual_quantity > (refunded_quantity ?? 0)
          )
          .map(({ sku, name }) => ({
            name,
            id: sku,
          }))
      );
    }

    return () => {
      setPartialRefundFields({ parts: [] });
    };
  }, [record.items.length]);

  if (!record?.payment_id) return <></>;

  if (isLoading) return <Loader />;

  return (
    <>
      <SimpleForm
        record={partialRefundFields}
        onSubmit={onOpenConfirmDialogHandler}
        toolbar={
          !!partialRefundFields.parts.length && (
            <Button type="submit" variant="contained" sx={{ ml: 1 }}>
              {translate('orders.pages.actions.makeRefund.caption')}
            </Button>
          )
        }
      >
        {partialRefundFields.parts.map(
          (
            {
              selectedQuantity,
              paidPricePerUnit,
              resultRefund,
              sku,
              name,
              image,
              orderCurrentQuantity,
              selectedProblemId,
            },
            index
          ) => (
            <Flex
              key={sku}
              fullWidth
              asColumn
              bgcolor={theme.palette.background.default}
              padding={1}
              mb={1}
              ml={-1}
              borderRadius={2}
            >
              <Flex alignItems="center" justifyContent="space-between">
                <Flex>
                  <Preview src={image} size={40} />

                  <Flex asColumn ml={1}>
                    <Typography
                      variant="subtitle1"
                      sx={{ lineHeight: '1.5', mb: 1 }}
                    >{`${name} (${sku})`}</Typography>

                    <Typography variant="subtitle2">
                      {translate(
                        'orders.pages.labels.payments.refund.partialRefund.labels.price'
                      )}
                      :
                      {` ${currencySymbol}${pointsToUnits(
                        paidPricePerUnit * orderCurrentQuantity,
                        false
                      )}`}
                    </Typography>

                    {selectedProblemId !== 'incorrect_price' && (
                      <Typography variant="subtitle2">
                        {translate(
                          'orders.pages.labels.payments.refund.partialRefund.labels.refunded'
                        )}
                        :
                        {` ${currencySymbol}${pointsToUnits(
                          resultRefund,
                          false
                        )}`}
                      </Typography>
                    )}
                  </Flex>
                </Flex>

                <Button id={sku} onClick={onRemoveItemHandler}>
                  <DeleteIcon />
                </Button>
              </Flex>

              <Divider sx={{ my: 2 }} />

              <Flex>
                <SelectInput
                  onChange={onFormChanged}
                  name={`parts[${index}].selectedProblemId`}
                  emptyText={DefaultRefundCase.name}
                  emptyValue={DefaultRefundCase.id}
                  label="orders.pages.labels.payments.refund.problemType.caption"
                  source={`parts[${index}].selectedProblemId`}
                  choices={PartialRefundCases}
                  fullWidth
                  validate={required()}
                  resettable={false}
                  isRequired
                />

                <Flex mx={1} />

                <ResourceInputNumber
                  onChange={onFormChanged}
                  fullWidth
                  validate={[
                    required(),
                    minNum(1),
                    maxNum(orderCurrentQuantity),
                  ]}
                  isInteger
                  name={`parts[${index}].selectedQuantity`}
                  source={`parts[${index}].selectedQuantity`}
                  label="orders.pages.labels.payments.refund.partialRefund.labels.quantity"
                />
              </Flex>

              {selectedProblemId === 'incorrect_price' && (
                <ResourceMoneyInput
                  onChange={onFormChanged}
                  fullWidth
                  validate={[
                    required(),
                    minNum(parseFloat(pointsToUnits(50))),
                    maxNum(
                      parseFloat(
                        pointsToUnits(paidPricePerUnit * selectedQuantity)
                      )
                    ),
                  ]}
                  name={`parts[${index}].formattedUnitsRefund`}
                  source={`parts[${index}].formattedUnitsRefund`}
                  label="orders.pages.labels.payments.refund.partialRefund.labels.refunded"
                />
              )}

              <TextInput
                onChange={onFormChanged}
                fullWidth
                multiline
                name={`parts[${index}].typedComment`}
                source={`parts[${index}].typedComment`}
                label="orders.pages.labels.payments.comment"
              />
            </Flex>
          )
        )}

        {!!selectableItems.length && (
          <Flex alignItems="center" justifyContent="center" fullWidth mt={1}>
            <Button
              ref={buttonRef}
              id="basic-button"
              aria-controls={showDropDown ? 'basic-menu' : undefined}
              aria-haspopup="true"
              variant="outlined"
              aria-expanded={showDropDown ? 'true' : undefined}
              onClick={onToggleDropdownHandler}
            >
              <AddIcon />

              {translate(
                'orders.pages.labels.payments.refund.partialRefund.addItem'
              )}
            </Button>

            <Menu
              id="basic-menu"
              anchorEl={buttonRef.current}
              open={showDropDown}
              onClose={onCloseDropdownHandler}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              {selectableItems.map(({ name, id }) => (
                <MenuItem
                  id={id.toString()}
                  key={id}
                  onClick={onAddItemHandler}
                >
                  {`${name} (${id})`}
                </MenuItem>
              ))}
            </Menu>
          </Flex>
        )}

        {!partialRefundFields.parts.length && !selectableItems.length && (
          <Typography>
            {translate(
              'orders.pages.labels.payments.refund.partialRefund.errors.nothingToRefund'
            )}
          </Typography>
        )}
      </SimpleForm>

      <UIDialog
        open={isConfirmDialogOpen}
        onClose={onCloseConfirmDialogHandler}
        title={translate('orders.pages.actions.makeRefund.dialog.caption')}
        controls={
          <>
            <Button onClick={onCloseConfirmDialogHandler}>
              {translate('ra.action.cancel')}
            </Button>

            <Button onClick={onMakeRefundHandler}>
              {translate('orders.pages.actions.makeRefund.caption')}
            </Button>
          </>
        }
      >
        {translate('orders.pages.actions.makeRefund.dialog.description')}
      </UIDialog>
    </>
  );
};
