export const getResourceFieldTruncateStyles = (truncate?: number) =>
  truncate
    ? `
      max-width: ${truncate}px;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  `
    : '';
