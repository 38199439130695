import { ResourceProps } from '../interface';
import { useController } from 'react-hook-form';
import { ColorInput } from './ColorInput';
import { useTranslate } from 'react-admin';
import { UIErrorTypography } from '../../UIErrorTypography/UIErrorTypography';

export const ResourceColorInput = ({
  name,
  labelKey,
  translate = 'yes',
  isRequired,
  defaultColors,
}: ResourceProps) => {
  const translation = useTranslate();
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name,
    rules: {
      required: isRequired ? translation('ra.validation.required') : undefined,
    },
  });

  return (
    <>
      <ColorInput
        caption={translate === 'yes' ? translation(labelKey) : labelKey}
        name={name}
        value={value}
        onChange={onChange}
        isRequired={isRequired}
        isError={!!error?.message?.length}
        defaultColors={defaultColors}
      />

      {!!error?.message?.length && (
        <UIErrorTypography>{error?.message}</UIErrorTypography>
      )}
    </>
  );
};

ResourceColorInput.defaultProps = {
  label: 'Color',
};
