import {
  GetListResult,
  GetManyReferenceResult,
  GetOneResult,
} from 'react-admin';
import { PluginResourceController } from '@PluginBase';
import { ResourceRoutes } from '../../../resourceRoutes';
import { ListPage } from './ListPage';
import { Icon } from '@UI';
import { apiUrl, ResourceMeta } from './constants';
import { DCCrud } from '../share/DCCrud';
import { ResourceItemPage } from '@Widgets/ResourceItemPage/ResourceItemPage';
import { OutboundOrderPage } from './OutboundOrderPage';
import { UPDATE_LIST_BY_FILE_UPLOAD } from '@MainConstants';
import { calcQuantities } from './dataGenerators';

const dcCrud = new DCCrud(apiUrl);

const OutboundOrdersController = new PluginResourceController({
  menuItem: {
    caption: {
      translationKey: 'distributionCenter.pages.outboundOrders.caption',
    },
    route: ResourceRoutes.outboundOrders.routePath,
    icon: <Icon type="outboundOrders" />,
  },
  resourceRoute: {
    name: ResourceRoutes.outboundOrders.resourcePath,
    list: ListPage,
    edit: (
      <ResourceItemPage type="edit" includeForm={false}>
        <OutboundOrderPage />
      </ResourceItemPage>
    ),
  },

  dataProvider: {
    getList: async (resource, params): Promise<GetListResult> => {
      return dcCrud.list(params);
    },
    getOne: async (resource, params): Promise<GetOneResult> => {
      if (params.id === undefined || params.id === null)
        return { data: { id: null } };

      const result = await dcCrud.getOne<{ data: CoreDCOrder }>(params);

      return {
        data: result?.data,
      };
    },
    updateMany: async (resource, params) => {
      if (params.meta === UPDATE_LIST_BY_FILE_UPLOAD) {
        await dcCrud.uploadFile(params);

        return {
          data: [],
        };
      }

      await dcCrud.cancelMany(params, `${apiUrl}/canceled`);

      return {
        data: params.ids,
      };
    },
    getManyReference: async (
      resource,
      params
    ): Promise<GetManyReferenceResult> => {
      const { target, id, meta, pagination } = params;

      if (
        target === ResourceMeta.DEMAND_QUANTITIES ||
        target === ResourceMeta.DEMAND_QUANTITIES_RECOUNT
      ) {
        const { data, rawData } = await dcCrud.getArbitraryList(
          {
            subPath: `/${id}/quantities${
              target === ResourceMeta.DEMAND_QUANTITIES_RECOUNT
                ? '/recount'
                : ''
            }`,
            pagination,
          },
          undefined,
          'post'
        );

        return {
          data: calcQuantities(
            data as unknown as CoreOutboundDemandOrder[],
            rawData.isSyncFailed as boolean,
            meta as string[]
          ),
          total: (rawData.pagination as { total: number })?.total || 0,
        };
      }

      return {
        data: [],
        total: 0,
      };
    },
  },
});

export default OutboundOrdersController;
