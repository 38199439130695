import { ResourceList } from '@Widgets/ResourceList/ResourceList';
import { useTranslate } from 'react-admin';
import {
  PICKING_ORDER_LIST_COLUMNS,
  DEFAULT_SORTING,
  FILTERS,
} from './constants';
import { Flex } from '@UI';

const isCancellableItem = (record: CorePickingOrders) =>
  !['canceled', 'finished'].includes(record.status);

export const ListPage = () => {
  const translate = useTranslate();

  return (
    <>
      <ResourceList<CorePickingOrders>
        columns={PICKING_ORDER_LIST_COLUMNS}
        filters={FILTERS}
        disableSyncWithLocation
        isRowSelectable={isCancellableItem}
        bulkActionButtons={false}
        /* bulkActionButtons={
          <BulkUpdateWithConfirmButton
            label={translate('distributionCenter.labels.cancel')}
            confirmContent=""
            confirmTitle={translate('distributionCenter.bulkCancelTitle')}
            mutationMode="pessimistic"
          />
        }*/
        actions={<Flex />}
        defaultSort={DEFAULT_SORTING}
        showDeleteButton={false}
        getItemCardCaption={(record) =>
          translate(
            'distributionCenter.pages.pickingOrders.labels.mobileCardCaption',
            {
              id: record.id,
            }
          )
        }
      />
    </>
  );
};
