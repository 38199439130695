import WarehouseIcon from '@mui/icons-material/Warehouse';
import {
  CreateResult,
  DeleteManyResult,
  DeleteResult,
  GetListResult,
  GetManyReferenceResult,
  GetOneResult,
  UpdateResult,
} from 'react-admin';
import { D } from '@mobily/ts-belt';

import {
  accessPermission,
  MoneyFormatter,
  pointsToUnits,
  unitsToPoints,
  isNil,
} from '@Helpers';
import { PluginResourceController } from '@PluginBase';

import { ResourceRoutes } from '../../../resourceRoutes';

import { DeliveryAreaCRUD } from './restApi/DeliveryArea/DeliveryAreaCRUD';
import { ListPage } from './pages/ListPage/ListPage';
import { CreatePage } from './pages/ItemPage/CreatePage';
import { EditPage } from './pages/ItemPage/EditPage';
import { WarehouseCRUD } from './restApi/Warehouse/WarehouseCRUD';
import { ResourceMeta } from './constants';
import { DELIVERY_AREA_API, WAREHOUSE_API } from './apiRoutes';
import { checkIntervals, convertDeliveryAreaValues } from './utils';
import { RequestAPI } from '@RestApi';
import { DeliveryAreaDetailedEntity } from './interface';

const warehousesCRUD = new WarehouseCRUD(WAREHOUSE_API);
const deliveryAreaCRUD = new DeliveryAreaCRUD(DELIVERY_AREA_API);

const mutateZoneShippingMethods = (id: string, data: any) =>
  RequestAPI.post(`/delivery/v1/zone-shipping-methods/zone/${id}`, data);

deliveryAreaCRUD.isNewSorting = true;

const WarehousePages = new PluginResourceController({
  resourceRoute: {
    name: ResourceRoutes.warehouse.resourcePath,
    list: ListPage,
    create: CreatePage,
    edit: EditPage,
  },
  menuItem: {
    route: ResourceRoutes.warehouse.routePath,
    caption: {
      translationKey: 'stores.warehouses.menuItem',
    },
    icon: <WarehouseIcon />,
  },
  dataProvider: {
    getList: async (resource, params): Promise<GetListResult> => {
      const { filter, ...rest } = params;
      const { categoryBlacklist, caller, ...restFilters } = filter;

      const response = await warehousesCRUD.list({
        filter: restFilters,
        ...rest,
      });
      const data =
        caller === 'blacklist'
          ? response.data.map((item) => ({
              ...item,
              id: item.code,
            }))
          : response.data;

      const preparedData =
        categoryBlacklist !== undefined
          ? data.filter((item) => categoryBlacklist.includes(item.id))
          : data;

      return {
        ...response,
        data: preparedData,
        total: categoryBlacklist !== undefined ? data.length : response.total,
      };
    },
    getOne: async (resource, params): Promise<GetOneResult> => {
      if (params.meta === ResourceMeta.DeliveryArea) {
        params.id = params.id.id;
        const response = await deliveryAreaCRUD.getOne<{
          data: CoreDeliveryAreaEntity;
        }>(params);

        if (!response) {
          return { data: response };
        }

        const data: Partial<DeliveryAreaDetailedEntity> = {
          ...convertDeliveryAreaValues(response.data, 'input'),
          schedule: checkIntervals(response.data.schedule) as CoreScheduleDay[],
          service_fee: 0,
          service_fee_threshold: 0,
        };

        if (accessPermission.company?.hasExpressDeliverySettings) {
          const zoneShippingMethods = await RequestAPI.get(
            `/delivery/v1/zone-shipping-methods/zone/${params.id}`
          );

          data.zoneShippingMethods = (zoneShippingMethods?.data || []).map(
            (item: CoreZoneShippingMethod): CoreZoneShippingMethod => ({
              ...item,
              order_amount_threshold: !isNil(item.order_amount_threshold)
                ? pointsToUnits(item.order_amount_threshold)
                : '0',
            })
          );
        }

        return { data };
      }

      const warehouse = await warehousesCRUD.getOne<{ data: CoreWarehouse }>(
        params
      );

      if (warehouse?.data?.schedule) {
        warehouse.data.schedule = checkIntervals(
          warehouse.data.schedule
        ) as CoreScheduleDay[];
      }

      return {
        data: warehouse?.data,
      };
    },
    delete: async (resource, params): Promise<DeleteResult> => {
      const { id, previousData, meta } = params;

      if (meta === ResourceMeta.DeliveryArea) {
        await deliveryAreaCRUD.delete(params);

        return {
          data: previousData,
        };
      }

      await warehousesCRUD.deleteMany_([id]);

      return {
        data: previousData,
      };
    },
    deleteMany: async (resource, params): Promise<DeleteManyResult> => {
      const { ids } = params;

      await warehousesCRUD.deleteMany_(ids);

      return {
        data: ids,
      };
    },
    create: async (resource, params): Promise<CreateResult> => {
      params.data.schedule = checkIntervals(params.data.schedule);

      if (params.meta === ResourceMeta.DeliveryArea) {
        delete params.data.timezone;

        const { zoneShippingMethods = [], ...restData } = params.data;
        const zone = {
          ...convertDeliveryAreaValues(restData, 'output'),
          service_fee: 0,
          service_fee_threshold: 0,
        };

        const response = await deliveryAreaCRUD.create<{
          data: CoreDeliveryAreaEntity;
        }>({ ...params, data: { zone } });

        if (
          response?.data.id &&
          accessPermission.company?.hasExpressDeliverySettings
        ) {
          await mutateZoneShippingMethods(
            response.data.id,
            zoneShippingMethods.map(
              (item: CoreZoneShippingMethod): CoreZoneShippingMethod => ({
                ...item,
                order_amount_threshold: !isNil(item.order_amount_threshold)
                  ? unitsToPoints(item.order_amount_threshold)
                  : item.order_amount_threshold,
              })
            ) as unknown as Record<string, unknown>
          );
        }

        return {
          data: response?.data,
        };
      }

      const response = await warehousesCRUD.create<{ data: [CoreWarehouse] }>({
        ...params,
        data: {
          warehouses: [params.data],
        },
      });

      return {
        data: response?.data[0],
      };
    },
    update: async (resource, params): Promise<UpdateResult> => {
      const { meta } = params;
      const previousData = Object.assign(
        params.previousData || {},
        params.data || {}
      );

      if (meta === ResourceMeta.DeliveryArea) {
        const { zoneShippingMethods = [], ...restData } = params.data;
        const data = {
          ...convertDeliveryAreaValues(
            D.deleteKey(restData, 'timezone') as CoreDeliveryAreaEntity,
            'output'
          ),
          service_fee: 0,
          service_fee_threshold: 0,
        };

        await deliveryAreaCRUD.update({
          ...params,
          data: { zone: data },
        });

        if (accessPermission.company?.hasExpressDeliverySettings) {
          try {
            await mutateZoneShippingMethods(
              params.id,
              zoneShippingMethods.map(
                (item: CoreZoneShippingMethod): CoreZoneShippingMethod => ({
                  ...item,
                  order_amount_threshold: !isNil(item.order_amount_threshold)
                    ? unitsToPoints(item.order_amount_threshold)
                    : item.order_amount_threshold,
                })
              ) as unknown as Record<string, unknown>
            );
          } catch (e) {
            // eslint-disable-next-line
            console.error(e);
          }
        }

        return {
          data: previousData,
        };
      }

      await warehousesCRUD.update(params);

      return {
        data: previousData,
      };
    },
    getManyReference: async (
      resource,
      params
    ): Promise<GetManyReferenceResult> => {
      const { meta, target } = params;

      if (meta === ResourceMeta.DeliveryArea) {
        const deliveryAreaList = await deliveryAreaCRUD.list({
          ...params,
          filter: {},
        });

        deliveryAreaList.data = (deliveryAreaList.data || []).map((area) => {
          if (
            area.delivery_price !== undefined &&
            area.delivery_price !== null
          ) {
            area.delivery_price =
              MoneyFormatter.getCurrencySymbol() +
              pointsToUnits(area.delivery_price);
          }

          if (
            area.delivery_price_threshold !== undefined &&
            area.delivery_price_threshold !== null
          ) {
            area.delivery_price_threshold =
              MoneyFormatter.getCurrencySymbol() +
              pointsToUnits(area.delivery_price_threshold);
          }

          if (
            area.delivery_price_max !== undefined &&
            area.delivery_price_max !== null
          ) {
            area.delivery_price_max =
              MoneyFormatter.getCurrencySymbol() +
              pointsToUnits(area.delivery_price_max);
          }

          return area;
        });

        return deliveryAreaList;
      }

      if (target === ResourceMeta.WarehouseLog) {
        return warehousesCRUD.getLog(params);
      }

      return Promise.reject(new Error());
    },
  },
});

export default WarehousePages;
