import { CreateAction } from './interfaces';

const createAction = <T = undefined>(actionType: string): CreateAction<T> => {
  return (payload) => ({
    type: actionType,
    payload,
  });
};

export default createAction;
