interface BackendValidationError {
  constraints: Record<string, string>;
  property: string;
}

interface BackendValidationSpecErrorPayload {
  code: string;
  data?: Record<string, string> | string;
}

interface BackendValidationSpecErrorSet {
  fields: BackendValidationSpecErrorPayload[] | Record<string, BackendValidationSpecErrorPayload | BackendValidationSpecErrorPayload[]>;
}

export class ValidationError extends Error {
  static readonly ERROR_CODE = 'BACKEND_VALIDATION_ERROR';
  fields: Record<string, string[]> = {};
  specFields: Record<string, BackendValidationSpecErrorPayload[]> = {};

  constructor(errors: BackendValidationError[] | BackendValidationSpecErrorSet | string[]) {
    super(ValidationError.ERROR_CODE);

    if ('fields' in errors) {
      this.decodeSpecError(errors);
    } else {
      this.decodeError(errors);
    }

    this.fields._timeStamp = [Date.now().toString()];
  }

  private decodeError(errors: BackendValidationError[] | string[]) {
    const errorList: string[] = [];

    errors.forEach((error) => {
      if (typeof error !== 'string') {
        this.fields[error.property] = Object.values(error.constraints);
      } else {
        let field = error.split(' ')[0];

        if (field) field = field[0].toLowerCase() + field.slice(1);

        error = error[0].toUpperCase() + error.slice(1);
        errorList.push(error);

        if (field) {
          this.fields[field] = (this.fields[field] || []).concat(error);
        }
      }
    });

    if (errorList.length) this.fields._errorList = errorList;
  }

  private decodeSpecError({ fields }: BackendValidationSpecErrorSet) {
    if (Array.isArray(fields)) {
      const errorList: string[] = [];

      fields.forEach(({ code }) => code && errorList.push(code[0].toUpperCase() + code.slice(1)));

      if (errorList.length) this.fields._errorList = errorList;
    } else {
      Object.entries(fields).forEach(([key, value]) => {
        this.specFields[key] = Array.isArray(value) ? value : [value];
      });
    }
  }
}
