import { TextInput } from 'react-admin';

export const PageForm = () => {
  return (
    <>
      <TextInput source="sku" required />

      <TextInput source="name" required />
    </>
  );
};
