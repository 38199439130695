export interface SelectChoice {
  id: string;
  name: string;
}

export const apiUrl = '/orders/v1/orders';

export const OrderStatuses: SelectOption[] = [
  { id: 'created', name: 'orders.statuses.created' },
  { id: 'reserved', name: 'orders.statuses.reserved' },
  { id: 'confirmed', name: 'orders.statuses.confirmed' },
  { id: 'delivery_created', name: 'orders.statuses.delivery_created' },
  { id: 'wh_created', name: 'orders.statuses.wh_created' },
  { id: 'picking', name: 'orders.statuses.picking' },
  { id: 'ready_to_ship', name: 'orders.statuses.ready_to_ship' },
  { id: 'in_delivery', name: 'orders.statuses.in_delivery' },
  { id: 'delivered', name: 'orders.statuses.delivered' },
  { id: 'delivered_partially', name: 'orders.statuses.delivered_partially' },
  { id: 'finished', name: 'orders.statuses.finished' },
  { id: 'cancelled', name: 'orders.statuses.cancelled' },
  { id: 'delivery_failed', name: 'orders.statuses.delivery_failed' },
];

export const UnCancellableStatuses: CoreOrderStatus[] = [
  'cancelled',
  'finished',
  'delivery_failed',
];
export const RefundableStatuses: CoreOrderStatus[] = ['finished'];
export const PartialPickingStatuses: CoreOrderStatus[] = [
  'ready_to_ship',
  'picking',
  'finished',
  'delivered',
  'delivery_failed',
  'delivered_partially',
  'in_delivery',
];
export const UnRefundablePaymentStatuses: PaymentStatus[] = [
  'refunded',
  'created',
  'unknown',
];
export const UnRefundableFullPaymentStatuses: PaymentStatus[] = [
  'partially_refund_processing',
  'refunded_partially',
];
export const TemporaryDisabledPaymentStatuses: PaymentStatus[] = [
  'cancel_processing',
  'refund_processing',
];

export enum OrderTab {
  General,
  Delivery,
  OrderItems,
  Feed,
  Payments,
  Notes,
}

export const CANCEL_REASONS = {
  itemNotAvailable:
    'orders.pages.actions.cancelOrder.dialog.cancelReasons.itemNotAvailable',
  customerCancelledDueToOrderDelay:
    'orders.pages.actions.cancelOrder.dialog.cancelReasons.customerCancelledDueToOrderDelay',
  fakeOrder: 'orders.pages.actions.cancelOrder.dialog.cancelReasons.fakeOrder',
  fraudulentCustomer:
    'orders.pages.actions.cancelOrder.dialog.cancelReasons.fraudulentCustomer',
  testOrder: 'orders.pages.actions.cancelOrder.dialog.cancelReasons.testOrder',
  unableToReachCustomerAddress:
    'orders.pages.actions.cancelOrder.dialog.cancelReasons.unableToReachCustomerAddress',
  customerNotAnsweringMobileOff:
    'orders.pages.actions.cancelOrder.dialog.cancelReasons.customerNotAnsweringMobileOff',
  customerReceivedWrongOrder:
    'orders.pages.actions.cancelOrder.dialog.cancelReasons.customerReceivedWrongOrder',
  duplicateOrderByCustomer:
    'orders.pages.actions.cancelOrder.dialog.cancelReasons.duplicateOrderByCustomer',
  other: 'orders.pages.actions.cancelOrder.dialog.cancelReasons.other',
};

export const CANCEL_REASON_CHOICES: SelectChoice[] = [
  {
    id: CANCEL_REASONS.itemNotAvailable,
    name: CANCEL_REASONS.itemNotAvailable,
  },
  {
    id: CANCEL_REASONS.customerCancelledDueToOrderDelay,
    name: CANCEL_REASONS.customerCancelledDueToOrderDelay,
  },
  {
    id: CANCEL_REASONS.fakeOrder,
    name: CANCEL_REASONS.fakeOrder,
  },
  {
    id: CANCEL_REASONS.fraudulentCustomer,
    name: CANCEL_REASONS.fraudulentCustomer,
  },
  {
    id: CANCEL_REASONS.testOrder,
    name: CANCEL_REASONS.testOrder,
  },
  {
    id: CANCEL_REASONS.unableToReachCustomerAddress,
    name: CANCEL_REASONS.unableToReachCustomerAddress,
  },
  {
    id: CANCEL_REASONS.customerNotAnsweringMobileOff,
    name: CANCEL_REASONS.customerNotAnsweringMobileOff,
  },
  {
    id: CANCEL_REASONS.customerReceivedWrongOrder,
    name: CANCEL_REASONS.customerReceivedWrongOrder,
  },
  {
    id: CANCEL_REASONS.duplicateOrderByCustomer,
    name: CANCEL_REASONS.duplicateOrderByCustomer,
  },
  {
    id: CANCEL_REASONS.other,
    name: CANCEL_REASONS.other,
  },
];
