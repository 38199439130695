import { PropsWithChildren, useContext } from 'react';
import {
  CreateFromContainerStyled,
  EditFromContainerStyled,
  ShowFromContainerStyled,
} from './styles';
import { ResourceGoToListButton } from './ResourceGoToListButton/ResourceGoToListButton';
import { ResourceItemPageProps } from './interface';
import {
  Create,
  Edit,
  SimpleForm,
  useResourceContext,
  useTranslate,
  WithRecord,
} from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { FormErrorBoundary } from '@UI';
import { ErrorBoundaryProvider, useSetErrorToErrorsContext } from '@Helpers';
import { RaRecord } from 'ra-core';

export function ResourceItemPage<TRecord extends RaRecord = RaRecord>({
  children,
  mutationOptions,
  type,
  defaultFormValues,
  modifyRecord = (record: TRecord) => record,
  includeForm = true,
  showBackToListBtn = true,
  id,
  Validator,
  redirect = 'edit',
}: PropsWithChildren<ResourceItemPageProps<TRecord>>) {
  const translate = useTranslate();
  const { updateContextErrors } = useContext(ErrorBoundaryProvider);
  const { onErrorHandler } = useSetErrorToErrorsContext();
  const resource = useResourceContext();

  const onError = (error: unknown) => {
    onErrorHandler(error);
    updateContextErrors?.(error);
  };

  function validation(formValues: FieldValues) {
    if (Validator) {
      const validatorInstance = new Validator(translate);

      return validatorInstance.validate(formValues);
    }

    return {};
  }

  if (type === 'show') {
    return (
      <>
        {showBackToListBtn && <ResourceGoToListButton resource={resource} />}

        <ShowFromContainerStyled>{children}</ShowFromContainerStyled>
      </>
    );
  }

  if (type === 'create') {
    return (
      <>
        {showBackToListBtn && <ResourceGoToListButton resource={resource} />}

        <CreateFromContainerStyled<typeof Create<TRecord>>
          redirect={redirect}
          mutationOptions={mutationOptions}
        >
          {includeForm ? (
            <SimpleForm
              validate={Validator ? validation : undefined}
              defaultValues={defaultFormValues}
            >
              {children}

              <FormErrorBoundary />
            </SimpleForm>
          ) : (
            <>{children}</>
          )}
        </CreateFromContainerStyled>
      </>
    );
  }

  return (
    <>
      {showBackToListBtn && <ResourceGoToListButton resource={resource} />}

      <EditFromContainerStyled<typeof Edit<TRecord>>
        redirect={redirect}
        id={id}
        mutationMode="pessimistic"
        mutationOptions={{ ...mutationOptions, onError }}
      >
        <WithRecord<TRecord>
          render={(record) =>
            includeForm ? (
              <SimpleForm
                validate={Validator ? validation : undefined}
                record={modifyRecord(record)}
              >
                {children}

                <FormErrorBoundary />
              </SimpleForm>
            ) : (
              <>{children}</>
            )
          }
        />
      </EditFromContainerStyled>
    </>
  );
}
