import { GetManyReferenceResult, GetManyReferenceParams } from 'react-admin';
import { RequestAPI } from '@RestApi';
import { CommonCrud } from '@PluginBase';

export class DeliveryAreaCRUD extends CommonCrud {
  async list(params: GetManyReferenceParams): Promise<GetManyReferenceResult> {
    const { sort, filter, pagination, id } = params;

    this.currentFiltersQuery = this.convertToGetQuery(filter);

    this._addSort(sort);
    this._addPagination(pagination);

    const response = await RequestAPI.get(
      `/delivery/v1/warehouses/${id}/zones${this.currentFiltersQuery}`
    );

    return {
      total: response.pagination.total,
      data: response.data,
    };
  }
}

export const getDeliveryMechanics = async (): Promise<
  Record<string, CoreShippingMechanic>
> => {
  const response = await RequestAPI.get(
    '/delivery/v1/zone-shipping-methods/delivery_mechanics'
  );

  const { data = [] } = response as { data: CoreShippingMechanic[] };

  return data.reduce(
    (memo, curr) => ({
      ...memo,
      [curr.code]: curr,
    }),
    {}
  );
};
