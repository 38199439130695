import {
  GetListResult,
  GetOneResult,
  UpdateParams,
  UpdateResult,
} from 'react-admin';

import { PluginResourceController, CommonCrud } from '@PluginBase';
import { ResourceRoutes } from '@Plugins/resourceRoutes';
import { AxiosRequestConfig } from 'axios';
import { RequestAPI } from '@RestApi';

export class FiltersCrud extends CommonCrud {
  async updateWithResponse(
    params: Omit<UpdateParams, 'previousData'>,
    headers?: AxiosRequestConfig['headers']
  ): Promise<{ data: Nullable<CoreFilterProperty> }> {
    headers = { ...headers, ...this.additionalHeaders };

    try {
      const { data } = await RequestAPI.patch(
        `${this.apiUrl}/${params.id ?? params.data.id}`,
        {
          ...params.data,
          id: undefined,
        },
        {
          headers,
        }
      );

      return { data: data as CoreFilterProperty };
    } catch (e) {
      this.handleError(e as Error);

      return { data: null };
    }
  }
}

const filtersCrud = new FiltersCrud('/catalog/v1/filters', {
  isNewSorting: true,
});

const FiltersController = new PluginResourceController({
  menuItem: null,
  resourceRoute: {
    name: ResourceRoutes.filters.resourcePath,
  },
  dataProvider: {
    getList: async (resource, params): Promise<GetListResult> =>
      filtersCrud.list(params),
    getOne: async (resource, params): Promise<GetOneResult> => {
      const result = await filtersCrud.getOne<{ data: CoreProperty }>(params);

      return {
        data: result?.data,
      };
    },
    update: async (resource, params): Promise<UpdateResult> => {
      const { data } = await filtersCrud.updateWithResponse(params);

      if (!data) {
        return {
          data: params.previousData,
        };
      }

      return {
        data: {
          ...params.previousData,
          categories: data.categories,
        },
      };
    },
  },
});

export default FiltersController;
