import { ResourceInputNumber, Flex, RowInfoBlock } from '@UI';

export const PageForm = () => {
  return (
    <>
      <Flex gap={1} fullWidth>
        <RowInfoBlock asColumn flex={1} justifyContent="flex-start">
          <ResourceInputNumber
            source="min_quantity"
            label="min_quantity"
            required
          />

          <ResourceInputNumber
            source="max_quantity"
            label="max_quantity"
            required
          />
        </RowInfoBlock>
      </Flex>
    </>
  );
};
