import { RESOURCES_SCHEMA } from '../../../schemas/resourcesSchema';
import { ImageGalleryWidget } from '@Widgets/ImageGalleryWidget/ImageGalleryWidget';
import { useController } from 'react-hook-form';
import { useTranslate } from 'react-admin';
import { storiesTranslationNamespace } from '../../../constants';
import { UIErrorTypography } from '@UI';

export const MainImage = () => {
  const translate = useTranslate();
  const mainImageSourceValidator = (value: string) => {
    return !value
      ? translate(storiesTranslationNamespace.errors.mainImageIsRequired)
      : undefined;
  };
  const image = useController({
    name: RESOURCES_SCHEMA.mainImage,
    rules: { validate: mainImageSourceValidator },
  });

  function onUpdateImageHandler(url?: string) {
    image.field.onChange(url);
  }

  return (
    <>
      {!!image.fieldState.error?.message && (
        <UIErrorTypography>{image.fieldState.error.message}</UIErrorTypography>
      )}

      <ImageGalleryWidget
        type="offers"
        mainImageUrl={image.field.value}
        hideAdditionalImage
        updateMainImage={onUpdateImageHandler}
      />
    </>
  );
};
