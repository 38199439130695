import { CommonCrud } from '@PluginBase';
import { AxiosError } from 'axios';
import { ConfigurableError } from '@PluginManager/base/AbstractApi/ConfigurableError';
import { ERROR_CODES } from '@MainConstants';
import { Button } from '@mui/material';
import { FC } from 'react';
import { useRedirect, useTranslate } from 'react-admin';
import { ResourceRoutes } from '@PluginManager/plugins/resourceRoutes';
import { MuiAlertStyled } from '@Widgets/ResourceList/styles';
import { OfferPropertiesValidationError } from '@PluginManager/base/AbstractApi/OfferPropertiesValidationError';

export interface PropertiesValidationError {
  error: string;
  message: string[];
  statusCode: number;
}

const Btn = () => {
  const redirect = useRedirect();
  const translate = useTranslate();
  const msg = translate('ra.action.redirectToLogs');

  const onClick = () => {
    redirect('list', ResourceRoutes.offerOperations.resourcePath);
  };

  return (
    <Button color="inherit" size="small" onClick={onClick}>
      {msg}
    </Button>
  );
};

const Message = () => {
  const translate = useTranslate();
  const msg = translate('backendFileErrors.UNPROCESSABLE_FILE_ERROR');

  return <>{msg}</>;
};

const notificationContent: FC = () => (
  <MuiAlertStyled severity="error" icon={false} action={<Btn />}>
    <Message />
  </MuiAlertStyled>
);

export class OfferCrud extends CommonCrud {
  protected handleError(e: AxiosError) {
    const { status, config } = e.response || {};

    if (status === 422 && config?.url?.includes('import')) {
      throw new ConfigurableError(
        e,
        ERROR_CODES.UNPROCESSABLE_FILE_ERROR,
        notificationContent
      );
    }

    if (status === 422 && config?.url?.includes('offers')) {
      this._handlePropertiesError(e as AxiosError<PropertiesValidationError>);
    }

    super.handleError(e);
  }

  private _handlePropertiesError(error: AxiosError<PropertiesValidationError>) {
    const { data } = error.config || { data: '' };
    const { message = [] } = error.response?.data || {};

    if (
      message.some((line) =>
        line.match(OfferPropertiesValidationError.PROPERTIES_REGEX)
      )
    ) {
      throw new OfferPropertiesValidationError(
        message,
        data,
        'catalogue.pages.offers.errors.propertiesValidationError'
      );
    }
  }
}
