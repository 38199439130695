export interface Localization {
  addPlace?: string;
  addOffer?: string;
  confirmDialogTitle?: string;
  originalOfferPrice?: string;
  discount?: string;
  bundleOfferPrice?: string;
  offerRemoveTooltip?: string;
  addingPlaceMessage?: string;
  deletingPlaceMessage?: string;
  offerQuantity?: string;
}

export interface PlacesProps {
  value: CoreBundlePlace[];
  onAddPlace?: () => Promise<void>;
  onRemoveOffer?: (offers: number, selectedPlace: number) => Promise<void>;
  onRemovePlace?: (place: number) => Promise<void>;
  onAddOfferToPlace?: (
    offers: number[],
    selectedPlace: number
  ) => Promise<void>;
  onOfferFieldUpdate?: (
    place: number,
    offer: number,
    field: string,
    value: string
  ) => Promise<void>;
  localization?: Localization;
  offersTotalPrice?: number;
  bundlesTotalPrice?: number;
}

export enum PlaceLoadingType {
  CREATION = 'creation',
  DELETION = 'deletion',
}
