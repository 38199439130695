import { RequestAPI, refreshTokens } from '@RestApi';
import { AuthProviderExtended, AuthResponse } from './interface';
import { REFRESH_TOKEN_URL, AUTH_API } from './constants';
import { AxiosError } from 'axios';
import { ROUTES } from '@CoreRoutes';
import { accessPermission } from '@Helpers';

export const authProvider: AuthProviderExtended = {
  login: async ({ phone, code, login, email, password }) => {
    let accessToken = '';
    let refreshToken = '';

    if (phone && code) {
      const {
        data: { access_token, refresh_token },
      } = await authProvider.otpCodeConfirm({
        phone,
        code,
      });

      accessToken = access_token || '';
      refreshToken = refresh_token || '';
    }

    if (email && password) {
      const {
        data: { access_token, refresh_token },
      } = await authProvider.authByEmail({
        email,
        password,
      });

      accessToken = access_token || '';
      refreshToken = refresh_token || '';
    }

    if (login && password) {
      const {
        data: { access_token, refresh_token },
      } = await authProvider.authByLogin({
        login,
        password,
      });

      accessToken = access_token || '';
      refreshToken = refresh_token || '';
    }

    if (accessToken) {
      window.localStorage.setItem('auth.accessToken', accessToken);
    } else {
      window.localStorage.removeItem('auth.accessToken');
    }

    if (refreshToken) {
      window.localStorage.setItem('auth.refreshToken', refreshToken);
    } else {
      window.localStorage.removeItem('auth.refreshToken');
    }

    if (
      window.localStorage.getItem('auth.accessToken') &&
      window.localStorage.getItem('auth.refreshToken')
    ) {
      accessPermission.parseAccessToken();

      return Promise.resolve();
    } else {
      return Promise.reject(new Error('ra.auth.sign_in_error'));
    }
  },
  logout: async () => {
    window.localStorage.removeItem('auth.accessToken');
    window.localStorage.removeItem('auth.refreshToken');
    refreshTokens.reset();

    return Promise.resolve();
  },
  checkError: () => {
    if (
      !window.localStorage.getItem('auth.accessToken') ||
      !window.localStorage.getItem('auth.refreshToken')
    ) {
      return Promise.reject(new Error('ra.auth.sign_in_error'));
    }

    return Promise.resolve();
  },
  checkAuth: () => {
    if (
      !window.localStorage.getItem('auth.accessToken') ||
      !window.localStorage.getItem('auth.refreshToken')
    ) {
      return Promise.reject(new Error());
    }

    return Promise.resolve();
  },
  getPermissions: () => {
    return Promise.resolve({});
  },
  getIdentity: async () => {
    authProvider
      .requestCurrency()
      .then(({ data: { currency = 'gbp', digits_after_coma = 2 } }) => {
        window.localStorage.setItem('RaStore.currency', currency);
        window.localStorage.setItem(
          'RaStore.digitsAfterComma',
          digits_after_coma.toString()
        );
      })
      .catch(() => undefined);

    try {
      const {
        data: { id, firstName, lastName },
      } = await authProvider.requestAuthUserData();

      return {
        id,
        fullName: `${firstName || ''}${lastName ? ' ' : ''}${lastName || ''}`,
      };
    } catch (error) {
      const {
        status,
        data: { message },
      } = (error as AxiosError<CoreRestApiErrorResponse>)?.response || {
        data: {},
      };

      if (status === 401 && message === 'Unauthorized') {
        authProvider
          .logout({})
          .then(() => {
            window.history.go(-1);
            window.history.pushState(null, '', `/#${ROUTES.login.route}`);
            window.history.go(1);
          })
          .catch(console.error);
      }

      return Promise.reject(error);
    }
  },
  requestOtpCode: ({ phone }) =>
    RequestAPI.post(`${AUTH_API}/auth/otp/send`, { phone }),
  otpCodeConfirm: ({ phone, code }) =>
    RequestAPI.post(`${AUTH_API}/auth/otp/confirm`, {
      phone,
      code,
    }),
  authByEmail: ({ email, password }) =>
    RequestAPI.post(`${AUTH_API}/auth/sign-in/email`, {
      email,
      password,
    }),
  authByLogin: ({ login, password }) =>
    RequestAPI.post(`${AUTH_API}/auth/sign-in/login`, {
      login,
      password,
    }),
  requestAuthUserData: () => RequestAPI.post(`${AUTH_API}/me`),
  requestCurrency: () => RequestAPI.get('/orders/v1/settings/currency'),
  refreshTokens: async () => {
    if (
      !window.localStorage.getItem('auth.accessToken') ||
      !window.localStorage.getItem('auth.refreshToken')
    ) {
      return Promise.reject(new Error('ra.auth.sign_in_error'));
    }

    try {
      const {
        data: { access_token, refresh_token },
      } = (await RequestAPI.post(`${AUTH_API}${REFRESH_TOKEN_URL}`, {
        refresh_token: window.localStorage.getItem('auth.refreshToken'),
      })) as AuthResponse;

      if (access_token) {
        window.localStorage.setItem('auth.accessToken', access_token);
      } else {
        window.localStorage.removeItem('auth.accessToken');
      }

      if (refresh_token) {
        window.localStorage.setItem('auth.refreshToken', refresh_token);
      } else {
        window.localStorage.removeItem('auth.refreshToken');
      }
    } catch (error) {
      authProvider
        .logout({})
        .then(() => {
          window.history.go(-1);
          window.history.pushState(null, '', `/#${ROUTES.login.route}`);
          window.history.go(1);
        })
        .catch(console.error);

      return Promise.reject(error);
    }
  },
};

export default authProvider;
