import { useController } from 'react-hook-form';
import 'react-quill/dist/quill.snow.css';
import { TextInput } from 'react-admin';
import { ResourceInputTransformationProps } from './interface';
import { useEffect } from 'react';

export const ResourceInputTransformation = (
  props: ResourceInputTransformationProps
) => {
  const {
    source,
    prefix = '',
    parentSource,
    label,
    disabled = true,
    spaceReplacement,
    isRequired,
  } = props;
  const property = useController({ name: source });
  const parentProperty = useController({ name: parentSource });

  useEffect(() => {
    property.field.onChange(
      `${parentProperty.field.value ? prefix : ''}${(
        parentProperty.field.value || ''
      )
        .trim()
        ?.replace(/\s/g, spaceReplacement)}`.toLowerCase()
    );
  }, [parentProperty.field.value]);

  return (
    <TextInput
      {...property}
      name={source}
      source={source}
      label={label}
      disabled={disabled}
      isRequired={isRequired}
      fullWidth
    />
  );
};
