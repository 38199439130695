import {
  CAGES_DATA_LIST_COLUMN,
  CAGES_PO_LIST_COLUMN,
  PICKING_CAGE_STATUS,
} from './constants';
import { formatEntityName } from '@ROOT/helpers/FormatEntityName/FormatEntityName';

export const getStatus = (
  item: CorePickingOrderCageActual
): PICKING_CAGE_STATUS =>
  item.pickingOrders.every(({ status }) => status === 'finished')
    ? PICKING_CAGE_STATUS.READY_TO_SHIP
    : PICKING_CAGE_STATUS.PICKING;

export const getPreparedCagesDataList = (
  warehouseName: string,
  cages: CorePickingOrderCageActual[]
): CAGES_DATA_LIST_COLUMN[] =>
  cages.map((item) => ({
    id: `${warehouseName}-${item.cageId}`,
    cageBarcode: item.cageBarcode,
    rawItem: item,
    status: getStatus(item),
    warehouseName,
  }));

export const getCagesPODataList = (
  items: CoreCagePOActual[]
): CAGES_PO_LIST_COLUMN[] => {
  const getTitle = (item: CoreCagePOActual) => ({
    outboudOrderName: formatEntityName('DC', 4, item.order.displayId),
    outboudOrderId: item.order.id,
    pickingOrderName: formatEntityName('PICK', 8, item.displayId),
    pickingOrderId: item.id,
  });

  return items.map((item) => ({
    id: item.id,
    displayTitle: getTitle(item),
    finishedAt: item.finishedAt,
  }));
};
