import { SimpleList, Datagrid, ArrayField } from 'react-admin';
import { ComponentFactory } from '@Widgets/ResourceList/ComponentFactory/ComponentFactory';
import { useIsMobile } from '@ROOT/hooks';
import { LIST_COLUMNS } from '../constants';

export const Items = () => {
  const isMobile = useIsMobile();

  return (
    <ArrayField source="items">
      {isMobile ? (
        <SimpleList
          sx={{ padding: 0 }}
          primaryText={(record) =>
            `${record.productSku} - ${record.productName}`
          }
          secondaryText={(record) =>
            `${record.quantityToPick} / ${record.quantityPicked}`
          }
          linkType={false}
        />
      ) : (
        <Datagrid sx={{ width: '100%' }} bulkActionButtons={false}>
          {LIST_COLUMNS.map((column) => {
            const sortable = column.sortable ?? true;

            return (
              <ComponentFactory
                sortBy={sortable ? column.source : undefined}
                key={column.source}
                column={column}
                label={column.label}
              />
            );
          })}
        </Datagrid>
      )}
    </ArrayField>
  );

  /* return (
    <>
      {record.items?.map((row: CorePickingItemDto) => {
        const {
          id,
          image,
          productName,
          productSku,
          quantityToPick,
          quantityPicked,
          productBarcodes,
        } = row;

        return (
          <Accordion
            TransitionProps={{ unmountOnExit: true }}
            key={id}
            sx={{ boxShadow: 'none' }}
          >
            <AccordionActions>
              <Flex fullWidth justifyContent='space-between'>
                <Flex alignItems='center'>
                  <Preview src={image || ''} size={40} />

                  <Flex asColumn ml={1}>
                    <TruncatedText variant='subtitle2'>{productName}</TruncatedText>

                    <Typography variant='subtitle2'>
                      {`${translate(pickingOrdersTranslationSchema.labels.productSku)}: ${productSku}`}
                    </Typography>
                  </Flex>
                </Flex>

                <AccordionSummary
                  aria-controls='panel1a-content'
                  id='panel1a-header'
                  expandIcon={<Icon type='expand' />}
                />
              </Flex>
            </AccordionActions>

            <AccordionDetails>
              <RowInfoBlock isInline titleKey={pickingOrdersTranslationSchema.labels.quantityToPick}>
                {quantityToPick}
              </RowInfoBlock>

              <RowInfoBlock isInline titleKey={pickingOrdersTranslationSchema.labels.quantityPicked}>
                {quantityPicked}
              </RowInfoBlock>

              <RowInfoBlock isInline titleKey={pickingOrdersTranslationSchema.labels.productBarcodes}>
                {productBarcodes.join(', ')}
              </RowInfoBlock>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  ); */
};
