import { memo } from 'react';
import { Box, Typography } from '@mui/material';
import format from 'date-fns/format';

import MessageMeta from './MessageMeta';
import { GROUP_DATE_FORMAT } from './constants';
import { MessageItemProps } from './interfaces';

const MessageItem = ({
  comment,
  onDelete,
  onEdit,
  isActionsEnabled,
}: MessageItemProps) => {
  const today = format(new Date(), GROUP_DATE_FORMAT);
  const isToday =
    today === format(new Date(comment.createdAt), GROUP_DATE_FORMAT);
  const dateFormat = isToday ? 'HH:MM' : `${GROUP_DATE_FORMAT} HH:MM`;
  const time = format(new Date(comment.createdAt), dateFormat);

  return (
    <Box
      py={0}
      px={2}
      mb={2}
      bgcolor="background.paper"
      borderRadius={2}
      boxShadow={1}
    >
      <MessageMeta
        comment={comment}
        onClose={onDelete}
        onEdit={onEdit}
        isActionsEnabled={isActionsEnabled}
      />
      <Typography
        variant="body2"
        gutterBottom
        py={1.5}
        mb={0}
        sx={{ wordBreak: 'break-all' }}
      >
        {comment.content}
      </Typography>
      <Box
        pt={0.5}
        pb={1}
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Typography variant="caption" color="textSecondary" p={0}>
          {time}
        </Typography>
      </Box>
    </Box>
  );
};

export default memo(MessageItem);
