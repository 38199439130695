import { createAction } from '@Helpers/Reducer';

import {
  CreateScheduleInterval,
  RemoveScheduleDayPayload,
  UpdateScheduleDayPayload,
} from './interfaces';

// Not in interfaces 'cuz those actions can't be exported as type
export enum scheduleActions {
  SET_SCHEDULE_DAYS = 'SET_SCHEDULE_DAYS',
  CREATE_SCHEDULE_INTERVAL = 'CREATE_SCHEDULE_INTERVAL',
  UPDATE_SCHEDULE_INTERVAL = 'UPDATE_SCHEDULE_INTERVAL',
  REMOVE_SCHEDULE_INTERVAL = 'REMOVE_SCHEDULE_INTERVAL',
  SET_AROUND_CLOCK = 'SET_AROUND_CLOCK',
}

export const setScheduleDays =
  createAction<CoreScheduleDay[]>(scheduleActions.SET_SCHEDULE_DAYS);

export const createScheduleDay = createAction<CreateScheduleInterval>(
  scheduleActions.CREATE_SCHEDULE_INTERVAL
);

export const updateScheduleDay = createAction<UpdateScheduleDayPayload>(
  scheduleActions.UPDATE_SCHEDULE_INTERVAL
);

export const removeScheduleDay = createAction<RemoveScheduleDayPayload>(
  scheduleActions.REMOVE_SCHEDULE_INTERVAL
);

export const setAroundClock =
  createAction<CreateScheduleInterval>(scheduleActions.SET_AROUND_CLOCK);
