import { ReactElement } from 'react';
import { FileImport, Flex, SUPPORTED_FORMATS } from '@UI';
import { useTranslate, useUpdate } from 'react-admin';
import { useCheckMutationAnswer } from '@Hooks/useCheckMutationAnswer';
import { ProductMatrix } from './interface';
import { LIST_COLUMNS } from './constants';
import { ResourceList } from '@Widgets/ResourceList/ResourceList';

import { ResourceRoutes } from '@PluginManager/plugins/resourceRoutes';

// function transformProduct(matrix: any) {
//   const { id, sku, name, chilled_frozen_fresh, supplier, category } =
//     matrix.product;
//
//   return {
//     id,
//     sku,
//     name,
//     chilledFrozenFresh: chilled_frozen_fresh,
//     supplier: supplier.name,
//     category: category.name,
//   };
// }
//
// function transformStore(matrix: any) {
//   return {
//     name: matrix.store.name,
//     cluster: matrix.cluster,
//     matrix: matrix.matrix,
//     salesIncrease: matrix.sales_increase,
//   };
// }

// const tabsValues = ['matrix', 'cluster', 'salesIncrease'];

export const ListPage = (): ReactElement => {
  // const [tabValue, setTabValue] = useState(0);
  // const { data, isLoading } = useGetList(
  //   ResourceRoutes.replenishment.productMatrix.resourcePath,
  //   {}
  // );
  //
  // const normalizeData = data?.reduce(
  //   (result, matrix) => {
  //     const product = transformProduct(matrix);
  //     const store = transformStore(matrix);
  //
  //     if (!(store.name in result.storeByName)) {
  //       result.storeNames.push(store.name);
  //       result.storeByName[store.name] = store;
  //     }
  //
  //     if (!(product.sku in result.productBySku)) {
  //       result.productSkus.push(product.sku);
  //       result.productBySku[product.sku] = product;
  //     }
  //
  //     result.productBySku[product.sku][store.name] = store;
  //
  //     return result;
  //   },
  //   {
  //     storeByName: {},
  //     storeNames: [],
  //     productBySku: {},
  //     productSkus: [],
  //   }
  // );
  //
  // const rows = data
  //   ? normalizeData.productSkus.map(
  //       (sku: string) => normalizeData.productBySku[sku]
  //     )
  //   : [];
  // const storeColumns = data
  //   ? normalizeData.storeNames.map((field: string) => ({
  //       field,
  //       renderCell: ({ value }: any) => {
  //         if (value === undefined) return 'Empty';
  //
  //         return String(value[tabsValues[tabValue]]);
  //       },
  //     }))
  //   : [];
  //
  // const columns: GridColDef[] = [
  //   { field: 'sku' },
  //   { field: 'name' },
  //   { field: 'chilledFrozenFresh', type: 'boolean' },
  //   { field: 'supplier' },
  //   { field: 'category' },
  //   ...storeColumns,
  // ];
  const translate = useTranslate();
  const [update, { isLoading, error, isSuccess }] = useUpdate();

  useCheckMutationAnswer({
    successMessage: 'replenishment.pages.lots.messages.successImport',
    errorMessage: 'replenishment.pages.lots.errors.errorImport',
    error: error as Error,
    isSuccess,
    shouldUpdateList: true,
  });

  async function onFileSelectHandler(file: File) {
    await update(ResourceRoutes.replenishment.productMatrix.resourcePath, {
      data: { file },
      meta: 'load',
    });
  }

  return (
    // <>
    //   <Flex alignItems="center">
    //     <Import />
    //   </Flex>
    //
    //   <Tabs
    //     value={tabValue}
    //     onChange={(event: SyntheticEvent, newValue: number) =>
    //       setTabValue(newValue)
    //     }
    //     centered
    //   >
    //     {tabsValues.map((tabName) => (
    //       <Tab key={tabName} label={tabName} />
    //     ))}
    //   </Tabs>
    //
    //   <DataGrid
    //     rows={rows}
    //     columns={columns}
    //     loading={isLoading}
    //     density="compact"
    //   />
    // </>

    <ResourceList<ProductMatrix>
      empty={false}
      exporter={false}
      bulkActionButtons={false}
      columns={LIST_COLUMNS}
      showDeleteButton={false}
      actions={
        <Flex alignItems="center">
          <FileImport
            isLoading={isLoading}
            isSuccess={isSuccess}
            accept={SUPPORTED_FORMATS.csv.toString()}
            buttonCaptionKey="replenishment.pages.productMatrix.actions.import"
            onFileSubmitted={onFileSelectHandler}
          />
        </Flex>
      }
      getItemCardCaption={(record) =>
        translate('replenishment.pages.lots.labels.mobileCardCaption', {
          id: record.id,
        })
      }
    />
  );
};
