import { GetOneResult, UpdateParams } from 'react-admin';
import { RequestAPI } from '@RestApi';
import { CoreLoyaltyData } from './constants';

const loyaltyCrudApiUrl = '/orders/v1/loyalty';

export class LoyaltyCRUD {
  async update(params: UpdateParams): Promise<boolean> {
    await RequestAPI.post(`${loyaltyCrudApiUrl}/update`, params.data);

    return true;
  }

  async get(): Promise<GetOneResult<CoreLoyaltyData>> {
    const loyalty: CoreLoyalty = await RequestAPI.get(
      `${loyaltyCrudApiUrl}/settings`
    );

    return {
      data: {
        ...loyalty,
        id: '',
      },
    };
  }
}
