import { sum } from '@Helpers/ts-belt/ts-belt';

export const aggregateTotals = (bundlePlaces: BundlePlaceResponseDto[]) => {
  const digitsAfterComma = Number(
    window.localStorage.getItem('RaStore.digitsAfterComma') || '2'
  );

  const { original, bundle } = bundlePlaces.reduce<{
    original: number;
    bundle: number;
  }>(
    (memo, curr) => {
      memo.original += sum(
        curr.bundlePlaceOffers.map((offer) => {
          const costField = Number(offer.offer?.price ?? '0');
          const quantity = Number(offer.quantity ?? '1');

          return costField * quantity;
        })
      );
      memo.bundle += sum(
        curr.bundlePlaceOffers.map((offer) => {
          const costField = Number(offer.price);

          return costField * Number(offer.quantity ?? '1');
        })
      );

      return memo;
    },
    { original: 0, bundle: 0 }
  );

  return {
    original: original.toFixed(digitsAfterComma),
    bundle: bundle.toFixed(digitsAfterComma),
  };
};
