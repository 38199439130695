import { Plugin } from '@PluginBase';
import Translation from './i18n.json';

import customers from './resources/Customers/controller';

const Customers = new Plugin({
  name: 'Customers',
  resources: [customers],
  i18n: Translation,
});

export default Customers;
