import { CSSProperties } from 'react';

import { DeliveryScheduleProps } from '@Widgets/DeliverySchedule/interfaces';

export enum TabName {
  General,
  DeliveryArea,
  DeliverySchedule,
  // TODO: `Schedule` should be moved to the relative place
  Schedule,
}

export interface DeliveryAreaFormProps
  extends Pick<DeliveryScheduleProps, 'zoneId' | 'timezone'> {
  location?: CoreWarehouseLocation;
  onTabChange?: (tab: TabName) => void;
  excludedTabs?: TabName[];
}

export interface DeliveryAreaMapProps {
  location?: CoreWarehouseLocation;
  isTabActive: boolean;
}

export interface PaymentMethodChoice {
  id: CorePaymentMethod;
  name: CorePaymentMethod;
}

export interface TabItem {
  label: string;
  value: TabName;
}

export interface StyleProps {
  style: CSSProperties;
}
