import { useNavigate } from 'react-router-dom';
import { Box, IconButton } from '@mui/material';
import Delete from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { CommentAuthor, OrderIdLink } from './styled';
import { MessageMetaProps } from './interfaces';

const MessageMeta = ({
  comment,
  onClose,
  onEdit,
  DeleteIcon = Delete,
  isActionsEnabled,
}: MessageMetaProps) => {
  const navigate = useNavigate();

  const [orderId, orderUUID] = comment.metadata?.orderId || [];
  const author = [
    comment.customer.firstName,
    comment.customer.middleName,
    comment.customer.lastName,
  ]
    .filter(Boolean)
    .join(' ');

  const handleOpenOrderDrawer = () => {
    if (!orderUUID) {
      return;
    }

    navigate('/orders/status'); // Close current drawer

    // Wait until drawer closed and then navigate to the new drawer
    setTimeout(() => {
      navigate(`/orders/status/${orderUUID}`);
    });
  };

  return (
    <Box py={0.5}>
      <Box
        display="flex"
        justifyContent="space-between"
        borderBottom="1px solid #464D56"
        py={1}
      >
        {orderId ? (
          <OrderIdLink component="span" onClick={handleOpenOrderDrawer}>
            {orderId}
          </OrderIdLink>
        ) : (
          <span style={{ width: 50 }} />
        )}
        <CommentAuthor component="span">{author}</CommentAuthor>
        {isActionsEnabled ? (
          <Box>
            {onEdit && (
              <IconButton color="secondary" size="small" onClick={onEdit}>
                <EditIcon fontSize="small" />
              </IconButton>
            )}
            {onClose && (
              <IconButton color="secondary" size="small" onClick={onClose}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            )}
          </Box>
        ) : (
          <span style={{ width: 60 }} />
        )}
      </Box>
    </Box>
  );
};

export default MessageMeta;
