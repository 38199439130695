export const MIN_LENGTH_OF_TIME_INPUT = 3;

export const DEFAULT_VALUE = new Array(7).fill(null).map((_, idx) => ({
  dayOfWeek: idx + 1,
  intervals: [
    {
      id: 0,
      start: '00:00:00',
      end: '23:59:59',
    },
  ],
}));
