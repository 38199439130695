import { ResourceInputNumber, Flex, RowInfoBlock } from '@UI';
import { BooleanInput, TextInput } from 'react-admin';

export const PageForm = () => {
  return (
    <>
      <Flex gap={1} fullWidth>
        <RowInfoBlock asColumn flex={1} justifyContent="flex-start">
          <TextInput source="store_name" label="Store" required />

          <TextInput source="sku" label="SKU" required />

          <TextInput source="product_name" label="Name" required />

          <TextInput source="supplier_name" label="Supplier" required />

          <TextInput source="cat1" label="Category" required />

          <TextInput source="company_name" label="Company" />

          <ResourceInputNumber
            source="packages"
            label="Package size"
            required
          />
        </RowInfoBlock>
        <RowInfoBlock asColumn flex={1} justifyContent="flex-start">
          <TextInput source="final_cluster" label="Cluster" required />

          <ResourceInputNumber
            source="shelf_life"
            label="shelf life"
            required
          />

          <BooleanInput source="matrix" label="product matrix" />

          <ResourceInputNumber source="sales_llw" label="sales LLW" required />

          <ResourceInputNumber source="sales_lw" label="sales LW" required />

          <ResourceInputNumber source="sales_tw" label="sales TW" required />

          <ResourceInputNumber
            source="corrected_sales_llw"
            label="corrected sales LLW"
            required
          />
        </RowInfoBlock>
      </Flex>
    </>
  );
};
