import { ResourceList } from '@Widgets/ResourceList/ResourceList';
import { LIST_FILTERS, OFFER_LIST_COLUMNS } from '../share/constants';
import { useTranslate, TopToolbar } from 'react-admin';
import { offersApiUrl } from './constants';
import { accessPermission } from '@Helpers';
import { UpdateListByFileButton } from '@UI';
import { RequestExportFileButton } from './components/RequestExportFileButton';

const ListActions = () => (
  <TopToolbar>
    <RequestExportFileButton />

    {accessPermission.checkPermission('Catalogue', 'C') && (
      <UpdateListByFileButton
        successMessage="catalogue.pages.offers.messages.successImport"
        errorMessage="catalogue.pages.offers.errors.errorImport"
        urlOfXLSXExample={`${offersApiUrl}/import/example`}
      />
    )}
  </TopToolbar>
);

export const ListPage = () => {
  const translate = useTranslate();

  return (
    <ResourceList<CoreOffer>
      columns={OFFER_LIST_COLUMNS}
      actions={<ListActions />}
      showDeleteButton={false}
      bulkActionButtons={false}
      disableSyncWithLocation={false}
      filters={LIST_FILTERS}
      getItemCardCaption={(record) =>
        translate('catalogue.pages.offers.labels.mobileCardCaption', {
          id: record.id,
        })
      }
    />
  );
};
