import styled from 'styled-components';

import { ResourceList } from '@Widgets/ResourceList/ResourceList';

export const ResourceListStyled = styled(ResourceList)<{
  heightOffset: number;
}>`
  .RaList-content {
    overflow: auto;
    height: ${({ heightOffset }) => `calc(100vh - ${heightOffset}px)`};
  }
` as typeof ResourceList;
