import { TranslationNamespace } from '@Helpers';
import { TRANSLATION_SCHEMA } from './schemas/translationSchema';

export const monitorTranslationSchema = new TranslationNamespace<
  typeof TRANSLATION_SCHEMA
>('wms-picking.pages.monitor', TRANSLATION_SCHEMA);

export const ORDERS_UPDATE_TIMEOUT = 2500;

export const PUSH_NOTIFICATION_TIMEOUT = 30 * 60 * 1000;
