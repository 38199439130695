import { useState, useEffect } from 'react';
import { ResourceNestedListProps, NestedListRecord } from './interface';
import { ResourceNestedListView } from './ResourceNestedView/ResourceNestedListView';
import { Flex } from '@UI';
import { Pagination } from 'react-admin';
import { PER_PAGE_VALUE } from './constants';

export const ResourceNestedList = (props: ResourceNestedListProps) => {
  const {
    columns,
    data,
    loadingRows,
    onRowExpanded,
    onRowClick,
    setPage,
    page,
    perPage = PER_PAGE_VALUE,
  } = props;
  const [groupedList, setGroupedList] = useState<NestedListRecord[]>([]);
  const [currentTotal, setCurrentTotal] = useState(0);

  function groupChildrenByParentId(
    list: NestedListRecord[],
    id: number,
    nextLevel: number
  ): NestedListRecord[] {
    const result: NestedListRecord[] = [];
    const currentLevelRecords = list.filter(({ parentId }) => parentId === id);
    const otherLevelRecords = list.filter(({ parentId }) => parentId !== id);

    currentLevelRecords.forEach((record) => {
      const children = groupChildrenByParentId(
        otherLevelRecords,
        record.id,
        nextLevel + 1
      );

      record.isExpandable = children.length > 0;
      record.level = nextLevel;
      record.childrenIds = children.map(({ id }) => id);
      result.push(record, ...children);
    });

    return result;
  }

  useEffect(() => {
    if (!data.length) return;

    const result: NestedListRecord[] = [];
    const rootLevelRecords = data.filter(
      ({ parentId }) => parentId === undefined || parentId === null
    );
    const otherLevelRecords = data.filter(({ parentId }) => parentId);

    rootLevelRecords.forEach((record) => {
      const children = groupChildrenByParentId(otherLevelRecords, record.id, 1);

      record.isExpandable = children.length > 0;
      record.level = 0;
      record.childrenIds = children.map(({ id }) => id);
      result.push(record, ...children);
    });

    setCurrentTotal(rootLevelRecords.length);
    setGroupedList(result);
  }, [JSON.stringify(data)]);

  return (
    <Flex pt={1} asColumn>
      <ResourceNestedListView
        columns={columns}
        data={groupedList}
        loadingRows={loadingRows}
        onRowExpanded={onRowExpanded}
        onRowClick={onRowClick}
      />

      <Pagination
        rowsPerPageOptions={[]}
        perPage={perPage}
        setPage={setPage}
        page={page}
        total={currentTotal}
      />
    </Flex>
  );
};
