import { PER_PAGE } from './constants';
import { LinkedList } from '@Widgets/LinkedList/LinkedList';
import { ResourceRoutes } from '@Plugins/resourceRoutes';
import { useRecordContext } from 'react-admin';
import {
  ResourceMeta,
  PICKING_ORDER_LIST_COLUMNS,
} from '../PickingOrders/constants';

const isCancellableItem = (record: CorePickingOrders) =>
  !['canceled', 'finished'].includes(record.status);

export const PickingOrdersTab = () => {
  // const translate = useTranslate();
  const { id } = useRecordContext<CoreOutboundOrder>();

  return (
    <LinkedList
      target={ResourceMeta.PICKING_ORDERS}
      resource={ResourceRoutes.pickingOrders.resourcePath}
      cols={PICKING_ORDER_LIST_COLUMNS}
      perPage={PER_PAGE}
      filter={{ orderId: id }}
      rowClick={(id) => `${ResourceRoutes.pickingOrders.resourcePath}/${id}`}
      isRowSelectable={isCancellableItem}
      bulkActionButtons={false}
      /* bulkActionButtons={
        <BulkUpdateWithConfirmButton
          label={translate('distributionCenter.labels.cancel')}
          confirmContent=""
          confirmTitle={translate('distributionCenter.bulkCancelTitle')}
          mutationMode="pessimistic"
        />
      }*/
    />
  );
};
