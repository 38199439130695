import { useState, useEffect } from 'react';
import { useListFilterContext, Button } from 'react-admin';
import { RequestAPI } from '@RestApi';
import { saveAs } from '@Helpers';
import DownloadIcon from '@mui/icons-material/GetApp';
import { CircularProgress } from '@mui/material';
import { AuthorizationError } from '@PluginManager/base/AbstractApi/AuthorizationError';
import authProvider from '../../providers/authProvider/authProvider';
import { ROUTES } from '@CoreRoutes';
import { ExportXLSXButtonProps } from './interface';

export const ExportXLSXButton = ({
  url,
  filename,
  filterFields = [],
}: ExportXLSXButtonProps) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const filterContextData = useListFilterContext();
  const filterValues = filterContextData?.filterValues;

  const filteredVals = filterFields
    ?.map((filterName) => filterValues?.[filterName])
    .join(',');

  useEffect(() => {
    setIsSuccess(false);
  }, [filteredVals]);

  if (filteredVals) {
    url = filterFields
      ?.filter((filterName) => filterValues?.[filterName])
      .reduce((memoUrl, filterName) => {
        const delimiter = memoUrl.includes('?') ? '&' : '?';

        return `${memoUrl}${delimiter}${filterName}=${filterValues?.[filterName]}`;
      }, url);
  }

  const labelText = isDownloading
    ? 'ra.action.exporting'
    : isSuccess
    ? 'ra.action.exported'
    : 'ra.action.export';

  return (
    <Button
      onClick={async () => {
        setIsDownloading(true);

        try {
          const blob = await RequestAPI.post(url, {}, { responseType: 'blob' });

          setIsSuccess(true);
          saveAs(blob, {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            filename: `${filename}.xlsx`,
          });
        } catch (error) {
          setIsSuccess(false);

          if (error instanceof AuthorizationError) {
            authProvider
              .logout({})
              .then(() => {
                window.history.go(-1);
                window.history.pushState(null, '', `/#${ROUTES.login.route}`);
                window.history.go(1);
              })
              .catch(console.error);
          }
        } finally {
          setIsDownloading(false);
        }
      }}
      label={labelText}
      disabled={isDownloading}
    >
      {isDownloading ? <CircularProgress size={16} /> : <DownloadIcon />}
    </Button>
  );
};
