import { Link } from '@mui/material';
import { useRecordContext } from 'react-admin';
import { ResourceRoutes } from '@PluginManager/plugins/resourceRoutes';

export const ResourceProductId = () => {
  const record = useRecordContext<CoreOffer>();

  return (
    <Link href={`#${ResourceRoutes.product.resourcePath}/${record.productId}`}>
      {record.productId}
    </Link>
  );
};
