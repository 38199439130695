import {
  FormDataConsumer,
  ImageField,
  ImageInput,
  useTranslate,
  useNotify,
} from 'react-admin';
import { Preview, Flex } from '@UI';
import { Typography } from '@mui/material';
import { S3Uploader, get } from '@Helpers';
import { ACCEPTED_IMAGE_TYPES, MB } from '@ROOT/constants';
import { useTheme } from '@mui/material/styles';

import { ResourceImageInputProps } from './interfaces';

export const ResourceImageInput = (props: ResourceImageInputProps) => {
  const { source, onDropAccepted, targetAspectRatio, ...rest } = props;
  const translate = useTranslate();
  const theme = useTheme();
  const { types, sizeMB = 2 } = ACCEPTED_IMAGE_TYPES;
  const notify = useNotify();

  return (
    <Flex asColumn>
      <ImageInput
        source={source}
        {...rest}
        accept={`${S3Uploader.getAllowedTypes(ACCEPTED_IMAGE_TYPES.types)}`}
        maxSize={sizeMB * MB}
        options={{
          onDropRejected: () => {
            notify('imageGallery.allowedImage', {
              type: 'error',
              messageArgs: {
                types,
                size: sizeMB,
              },
            });
          },
          onDropAccepted,
        }}
      >
        <FormDataConsumer>
          {({ formData }) => {
            const data = get(formData, source);

            if (typeof data === 'string') {
              return <Preview src={data} size={200} />;
            }

            return <ImageField source="src" title="title" />;
          }}
        </FormDataConsumer>
      </ImageInput>

      {!!types?.length && (
        <Typography variant="caption" color={theme.palette.text.disabled}>
          {translate('imageGallery.allowedImage', {
            types,
            size: sizeMB,
          })}
          {targetAspectRatio && ` Aspect ratio: ${targetAspectRatio}`}
        </Typography>
      )}
    </Flex>
  );
};
