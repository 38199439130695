import { Link } from '@mui/material';
import { ReferenceIdColumn } from '../interface';
import { useRecordContext } from 'react-admin';
import { get } from '@Helpers';

export const ResourceReferenceLinkField = (props: Omit<ReferenceIdColumn, 'type'>) => {
  const { source, resource } = props;
  const record = useRecordContext();
  const id = get(record, source);

  return <Link href={`#${resource}/${id}`}>{id}</Link>;
};
