import { Button } from '@mui/material';
import { Icon, Flex } from '@UI';
import { useNavigate } from 'react-router';
import { useTranslate } from 'react-admin';
import { useTheme } from '@mui/material/styles';

export const ResourceGoToListButton = ({ resource }: { resource: string }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const translate = useTranslate();

  function onBackHandler() {
    navigate(resource);
  }

  return (
    <Flex py={1} bgcolor={theme.palette.background.default}>
      <Button color='secondary' size='small' startIcon={<Icon type='arrowDown' rotate={90} />} onClick={onBackHandler}>
        {translate('goToList')}
      </Button>
    </Flex>
  );
};
