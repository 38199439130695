import { PluginResourceController } from '@PluginBase';
import { ResourceRoutes } from '../../../resourceRoutes';
import { GetListResult, GetManyResult } from 'react-admin';
import { ListPage } from './ListPage';
import { Icon } from '@UI';
import { apiUrl, CagesCrudGetManyResponseDataItem } from './constants';
import { CagesCrud } from './restApi/CagesCrud';

const cagesCrud = new CagesCrud(apiUrl);

const CagesController = new PluginResourceController({
  menuItem: {
    caption: {
      translationKey: 'distributionCenter.pages.cages.caption',
    },
    route: ResourceRoutes.cages.routePath,
    icon: <Icon type="cages" />,
  },
  resourceRoute: {
    name: ResourceRoutes.cages.resourcePath,
    list: ListPage,
  },

  dataProvider: {
    getList: async (): Promise<GetListResult> => {
      const res: GetManyResult<CagesCrudGetManyResponseDataItem> =
        await cagesCrud.getMany();

      return { data: res.data, total: res.data.length };
    },
  },
});

export default CagesController;
