import { Flex } from '@UI';
import { Button } from '@mui/material';
import { MultiplierProps } from './interface';
import { MultiplierItemContainer } from './MultiplierItemContainer/MultiplierItemContainer';
import { MultiplierItemElement } from './MultiplierItemElement/MultiplierItemElement';
import { MouseEvent } from 'react';

export const Multiplier = (props: MultiplierProps) => {
  const {
    onAddBlock,
    addBlockTitle = 'add',
    addBlockItemTitle = 'add',
    onAddBlockItem,
    onRemoveBlockItem,
    onRemoveBlock,
    value,
  } = props;

  function onAddItemHandler(event: MouseEvent<HTMLButtonElement>) {
    const blockId = event.currentTarget?.id;

    if (!blockId) {
      return;
    }

    onAddBlockItem?.(blockId);
  }

  return (
    <Flex asColumn fullWidth>
      {value?.map(({ id, element, elements }) => (
        <MultiplierItemContainer id={id} key={id} element={element} onRemoveBlock={onRemoveBlock}>
          {elements?.map((element) => (
            <MultiplierItemElement key={element.id} blockId={id} {...element} onRemoveBlockItem={onRemoveBlockItem} />
          ))}

          {onAddBlockItem && (
            <Button fullWidth variant='contained' color='secondary' id={id} onClick={onAddItemHandler}>
              {addBlockItemTitle}
            </Button>
          )}
        </MultiplierItemContainer>
      ))}

      {onAddBlock && (
        <Button variant='contained' fullWidth color='secondary' onClick={onAddBlock}>
          {addBlockTitle}
        </Button>
      )}
    </Flex>
  );
};
