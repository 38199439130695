import { ResourceMoneyInputProps } from '../interface';
import { ResourceInputNumber } from '@UI';
import { MoneyFormatter } from '@Helpers';
import { BaseSyntheticEvent } from 'react';

export const ResourceMoneyInput = (props: ResourceMoneyInputProps) => {
  const handleInput = (e: BaseSyntheticEvent) => {
    if (e.target.value.includes('.')) {
      const digitsAfterComma =
        parseInt(
          window.localStorage.getItem('RaStore.digitsAfterComma') || '2',
          10
        ) + 1;

      e.target.value = e.target.value.substring(
        0,
        e.target.value.indexOf('.') + digitsAfterComma
      );
    }
  };

  return (
    <ResourceInputNumber
      {...props}
      prefixSymbol={MoneyFormatter.getCurrencySymbol()}
      InputProps={{
        onInput: handleInput,
      }}
    />
  );
};
