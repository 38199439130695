import { useTranslate, useListSortContext, useLoading } from 'react-admin';
import { SortingButtonProps } from './interface';
import { useState, useEffect, useRef, MouseEvent } from 'react';
import { Flex, Icon } from '@UI';
import { Button, Menu, MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const SortingButton = (props: SortingButtonProps) => {
  const { fields } = props;
  const { setSort, sort } = useListSortContext();
  const isLoading = useLoading();
  const theme = useTheme();
  const [sortMenuOpen, setSortMenuOpen] = useState(false);
  const [sortingList, setSortingList] = useState<SelectOption[]>([]);
  const [currentSortField, setCurrentSortField] = useState('');
  const translate = useTranslate();
  const buttonRef = useRef(null);

  useEffect(() => {
    const newFields = fields.filter(
      ({ fieldName }) => fieldName !== sort.field
    );

    setSortingList(
      newFields.map(({ fieldName, fieldTranslateKey }) => ({
        id: fieldName,
        name: translate(fieldTranslateKey),
      }))
    );
  }, [sort.field]);

  useEffect(() => {
    const option = fields.find(({ fieldName }) => fieldName === sort.field);

    if (!option) {
      return;
    }

    setCurrentSortField(translate(option.fieldTranslateKey));
  }, [sort.field]);

  function onToggleSortHandler() {
    setSort({
      ...sort,
      order: sort.order === 'DESC' ? 'ASC' : 'DESC',
    });
  }

  function onOpenSortMenuHandler() {
    setSortMenuOpen(true);
  }

  function onCloseSortMenuHandler() {
    setSortMenuOpen(false);
  }

  function onChangeSortFieldHandler(e: MouseEvent<HTMLLIElement>) {
    const field = e.currentTarget.id;

    setSort({
      field,
      order: 'DESC',
    });

    setSortMenuOpen(false);
  }

  return (
    <Flex alignItems="center" bgcolor={theme.palette.background.default}>
      {sortingList.length > 0 && (
        <Button
          variant="outlined"
          onClick={onOpenSortMenuHandler}
          ref={buttonRef}
          disabled={isLoading}
        >
          <Icon type="sort" />
        </Button>
      )}

      {buttonRef.current && (
        <Menu
          id="basic-menu"
          anchorEl={buttonRef.current}
          open={sortMenuOpen}
          onClose={onCloseSortMenuHandler}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {sortingList.map(({ name, id }) => (
            <MenuItem
              key={id}
              id={id.toString()}
              onClick={onChangeSortFieldHandler}
            >
              {name}
            </MenuItem>
          ))}
        </Menu>
      )}

      {currentSortField && (
        <Button
          variant="outlined"
          disabled={isLoading}
          onClick={onToggleSortHandler}
          endIcon={
            <Icon type="arrowDown" rotate={sort.order === 'DESC' ? 0 : 180} />
          }
        >
          {currentSortField}
        </Button>
      )}
    </Flex>
  );
};
