/**
 * Clears the phone number from special characters and spaces
 * */
export const cleanPhone = (phone: string): string => {
  if (!phone) return '';
  phone = phone.replace(/\D/g, '');

  return phone;
};

/**
 * Formats the phone number into a human-readable format, like that +7 (123) 456-78-90
 * */
// TODO: need to discuss about rules for phone which start with 1-3 digits after "+" sign
export const formatPhone = (phone: string): string => {
  if (!phone) return '';

  if (phone.startsWith('44')) {
    return phone
      .replace(/\D/g, '')
      .slice(-10)
      .replace(/(\d{4})(\d{3})(\d{3})/, '+44 ($1) $2-$3');
  }

  return phone
    .replace(/\D/g, '')
    .replace(/(\d{1,2})?(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 ($2) $3-$4-$5');
};
