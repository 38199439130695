export const removeTrailingSlashes = (str: string) =>
  str.replace(/(^\/|\/$)/g, '');

export const uniqueId = (() => {
  let num = 0;

  return (prefix = '') => `${prefix}${(num += 1)}`;
})();

export const slugify = (input: string): string => {
  if (!input)
    return '';

  // make lower case and trim
  let slug = input.toLowerCase().trim();

  // remove accents from charaters
  slug = slug.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

  // replace invalid chars with spaces
  slug = slug.replace(/[^a-z0-9\s-]/g, ' ').trim();

  // replace multiple spaces or hyphens with a single hyphen
  slug = slug.replace(/[\s-]+/g, '-');

  return slug;
}
