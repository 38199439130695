import { DEFAULT_FORM_VALUE, loyaltyTranslationNamespace } from '../constants';
import { RESOURCES_SCHEMA } from '../schemas/resourcesSchema';
import { Flex, Loader, ResourceInputNumber } from '@UI';
import {
  RecordContextProvider,
  SimpleForm,
  useGetOne,
  useUpdate,
  required,
  Toolbar,
  SaveButton,
} from 'react-admin';
import { ResourceRoutes } from '../../../../resourceRoutes';
import { useCheckMutationAnswer } from '@Hooks/useCheckMutationAnswer';
import { FieldValues } from 'react-hook-form';
import { minNum, maxNum } from '@Helpers';

const validateField = [required(), minNum(0), maxNum(100)];

export const LoyaltyPage = () => {
  const { data, isLoading } = useGetOne(
    ResourceRoutes.loyalty.resourcePath,
    {
      id: '',
    },
    {}
  );
  const [updateLoyalty, { error, isSuccess }] = useUpdate();

  useCheckMutationAnswer({
    error: error as Error,
    isSuccess,
    errorMessage: loyaltyTranslationNamespace.errors.cantUpdateLoyaltyProgram,
    successMessage: loyaltyTranslationNamespace.messages.loyaltyProgramUpdated,
  });

  function onUpdateLoyaltyProgramHandler(values: FieldValues) {
    updateLoyalty(ResourceRoutes.loyalty.resourcePath, {
      data: values,
      previousData: values,
    }).catch((error) => console.error(error));
  }

  if (isLoading) {
    return (
      <Flex asColumn fullWidth>
        <Loader />
      </Flex>
    );
  }

  return (
    <RecordContextProvider value={data}>
      <SimpleForm
        onSubmit={onUpdateLoyaltyProgramHandler}
        defaultValues={DEFAULT_FORM_VALUE}
        toolbar={
          <Toolbar>
            <SaveButton />
          </Toolbar>
        }
      >
        <ResourceInputNumber
          isInteger
          source={RESOURCES_SCHEMA.firstClientCashbackAmount}
          name={RESOURCES_SCHEMA.firstClientCashbackAmount}
          label={loyaltyTranslationNamespace.labels.firstClientCashbackAmount}
          postfixSymbol="%"
          validate={validateField}
        />

        <ResourceInputNumber
          isInteger
          source={RESOURCES_SCHEMA.cashbackAmount}
          name={RESOURCES_SCHEMA.cashbackAmount}
          label={loyaltyTranslationNamespace.labels.cashbackAmount}
          postfixSymbol="%"
          validate={validateField}
        />

        <ResourceInputNumber
          isInteger
          source={RESOURCES_SCHEMA.percentsPriceCanBePaidAmount}
          name={RESOURCES_SCHEMA.percentsPriceCanBePaidAmount}
          label={
            loyaltyTranslationNamespace.labels.percentsPriceCanBePaidAmount
          }
          postfixSymbol="%"
          validate={validateField}
        />
      </SimpleForm>
    </RecordContextProvider>
  );
};
