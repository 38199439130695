export const ROUTES = {
  configuration: {
    route: '/configuration',
  },
  dashboard: {
    route: '/',
  },
  login: {
    route: '/login',
  },
};
