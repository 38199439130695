import { useRecordContext } from 'react-admin';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import {
  DataGridPro,
  GridActionsCellItem,
  GridColumns,
  GridRowModesModel,
  GridRowModes,
  GridRowsProp,
} from '@mui/x-data-grid-pro';
import { Supplier } from './interface';
import { useEffect, useState } from 'react';
import { ReplenishmentApiResource } from '../../apiRoutes';

const WEEK_DAYS = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

interface IStore {
  id: string;
  name: string;
}

interface ISchedule {
  id: string;
  order_day: number;
  supplier_id: string;
  store_id: string;
  supply_gap: number;
}

interface ResponseData {
  data: {
    stores: IStore[];
    schedules: ISchedule[];
  };
}

interface SchedulersByStores {
  [key: string]: (ISchedule | null)[];
}

export const PageForm = () => {
  const supplier = useRecordContext<Supplier>();
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  useEffect(() => {
    (async () => {
      const result = await fetch(
        `${ReplenishmentApiResource.schedules}/${supplier.id}`
      );
      const { data }: ResponseData = await result.json();

      const schedulersByStores = data.schedules.reduce<SchedulersByStores>(
        (acc, schedule) => {
          const { store_id, order_day } = schedule;

          if (!(store_id in acc)) acc[store_id] = new Array(7).fill(null);
          acc[store_id][order_day - 1] = schedule;

          return acc;
        },
        {}
      );

      const newRows = data.stores.map((store) => {
        const schedules = schedulersByStores[store.id]
          ? schedulersByStores[store.id].map((schedule) => schedule?.supply_gap)
          : new Array(7).fill(null);

        return {
          id: store.id,
          store: store.name,
          ...schedules,
        };
      });

      setRows(newRows);
    })();
  }, []);

  const columns: GridColumns = [
    { field: 'store', headerName: 'Store', sortable: false },
    ...WEEK_DAYS.map((day, index) => {
      return {
        field: String(index),
        headerName: day,
        sortable: false,
        editable: true,
        type: 'number',
      };
    }),
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              key="saveAction"
              icon={<SaveIcon />}
              label="Save"
              onClick={() => {
                setRowModesModel({
                  ...rowModesModel,
                  [id]: { mode: GridRowModes.View },
                });
              }}
            />,
            <GridActionsCellItem
              key="cancelAction"
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={() => {
                setRowModesModel({
                  ...rowModesModel,
                  [id]: { mode: GridRowModes.View, ignoreModifications: true },
                });
              }}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            key="editAction"
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={() => {
              setRowModesModel({
                ...rowModesModel,
                [id]: { mode: GridRowModes.Edit },
              });
            }}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <>
      <h1>{supplier.name}</h1>

      <div style={{ height: 300, width: '100%' }}>
        <DataGridPro
          experimentalFeatures={{ newEditingApi: true }}
          loading={false}
          editMode="row"
          rows={rows}
          columns={columns}
          hideFooter
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          disableSelectionOnClick
          disableDensitySelector
          disableVirtualization
          rowModesModel={rowModesModel}
          processRowUpdate={(newRow) => {
            setRows(rows.map((row) => (row.id === newRow.id ? newRow : row)));

            return newRow;
          }}
        />
      </div>
    </>
  );
};
