import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonIcon from '@mui/icons-material/Person';
import PeopleIcon from '@mui/icons-material/People';
import BadgeIcon from '@mui/icons-material/Badge';
import KeyIcon from '@mui/icons-material/Key';
import MediationIcon from '@mui/icons-material/Mediation';
import CategoryIcon from '@mui/icons-material/Category';
import BallotIcon from '@mui/icons-material/Ballot';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import SaveIcon from '@mui/icons-material/Save';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import SortIcon from '@mui/icons-material/Sort';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import RecommendIcon from '@mui/icons-material/Recommend';
import AddIcon from '@mui/icons-material/Add';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import SearchIcon from '@mui/icons-material/Search';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import InventoryIcon from '@mui/icons-material/Inventory';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import StocksIcon from '@mui/icons-material/Timeline';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import ShopIcon from '@mui/icons-material/Shop';
import MonitorIcon from '@mui/icons-material/Dvr';
import OfferIcon from '@mui/icons-material/ViewInAr';
import OfferImportIcon from '@mui/icons-material/ReceiptLong';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import StockImportsIcon from '@mui/icons-material/ImportExport';
import BundleIcon from '@mui/icons-material/Margin';
import PlaceIcon from '@mui/icons-material/Place';
import CheckIcon from '@mui/icons-material/TaskAlt';
import HubIcon from '@mui/icons-material/Hub';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import { IconProps, IconType } from './interface';
import { IconContainerStyled } from './styles';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon/SvgIcon';

const icons: Record<
  IconType,
  OverridableComponent<SvgIconTypeMap> & { muiName: string }
> = {
  arrowDown: ArrowDownwardIcon,
  chevronRight: ChevronRightIcon,
  expand: ExpandMoreIcon,
  sort: SortIcon,
  chart: StackedLineChartIcon,
  delete: DeleteIcon,
  settings: SettingsIcon,
  person: PersonIcon,
  people: PeopleIcon,
  badge: BadgeIcon,
  key: KeyIcon,
  property: MediationIcon,
  category: CategoryIcon,
  distributionCenter: HubIcon,
  catalog: BallotIcon,
  product: ProductionQuantityLimitsIcon,
  upload: FileUploadIcon,
  save: SaveIcon,
  offer: OfferIcon,
  offerImport: OfferImportIcon,
  outboundOrders: ShoppingCartCheckoutIcon,
  pickingOrders: ShoppingBasketIcon,
  cages: ShoppingCartIcon,
  shipments: LocalShippingIcon,
  attention: PriorityHighIcon,
  group: WorkspacesIcon,
  addCircle: AddCircleIcon,
  removeCircle: RemoveCircleIcon,
  story: AutoStoriesIcon,
  promotion: RecommendIcon,
  add: AddIcon,
  loyalty: LoyaltyIcon,
  search: SearchIcon,
  viewCarousel: ViewCarouselIcon,
  inventory: InventoryIcon,
  shoppingBasket: ShoppingBasketIcon,
  shop: ShopIcon,
  monitor: MonitorIcon,
  stocks: StocksIcon,
  stockImports: StockImportsIcon,
  bundle: BundleIcon,
  factCheck: FactCheckIcon,
  pin: PlaceIcon,
  check: CheckIcon,
};

export const Icon = ({ type, rotate = 0, size, sx }: IconProps) => {
  const Component = icons[type];

  if (!Component) return null;

  return (
    <IconContainerStyled alignItems="center" $rotate={rotate}>
      <Component fontSize={size} sx={sx} />
    </IconContainerStyled>
  );
};
