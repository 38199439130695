import {
  TabbedShowLayout,
  useRecordContext,
  useTranslate,
  DateField,
  useLocaleState,
} from 'react-admin';
import { Flex } from '@UI';
import { Typography } from '@mui/material';
import React from 'react';
import { ProductsTab } from './ProductsTab';
import { CagesTab } from './CagesTab';
import { formatEntityName } from '@ROOT/helpers/FormatEntityName/FormatEntityName';
import { StatusBadgeResource } from '../share/StatusBadgeResource/StatusBadgeResource';

export const PickingOrderPage = () => {
  const translate = useTranslate();
  const [locale] = useLocaleState();
  const record = useRecordContext<CorePickingOrder>();

  const infoBlock = (
    <Flex p={2} gap={2} justifyContent="space-between" flexWrap="wrap">
      <Flex asColumn>
        <Flex gap={4}>
          <Typography variant="h5">
            {formatEntityName('PICK', 8, record.displayId)}
          </Typography>
          <Typography variant="h5" sx={{ opacity: 0.5 }}>
            {formatEntityName('DC', 4, record.order?.displayId)}
          </Typography>
        </Flex>
        <Typography variant="subtitle1" my={2}>
          {`${record.sourceWarehouse} ➔ ${record.destinationWarehouse}`}
        </Typography>
        <Typography variant="subtitle2">
          {`${translate(
            'distributionCenter.pages.pickingOrders.labels.createdAt'
          )}: `}

          {record.createdAt?.length > 0 ? (
            <DateField source="createdAt" locales={locale} showTime />
          ) : (
            'N/A'
          )}
        </Typography>
        <Typography variant="subtitle2">
          {`${translate(
            'distributionCenter.pages.pickingOrders.labels.startedAt'
          )}: `}

          {record.startedAt?.length > 0 ? (
            <DateField source="startedAt" locales={locale} showTime />
          ) : (
            'N/A'
          )}
        </Typography>
        <Typography variant="subtitle2">
          {`${translate(
            'distributionCenter.pages.pickingOrders.labels.finishedAt'
          )}: `}

          {record.finishedAt?.length > 0 ? (
            <DateField source="finishedAt" locales={locale} showTime />
          ) : (
            'N/A'
          )}
        </Typography>
      </Flex>
      <Flex asColumn>
        <StatusBadgeResource />
      </Flex>
    </Flex>
  );

  return (
    <>
      {infoBlock}
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="distributionCenter.pages.pickingOrders.tabs.products">
          <ProductsTab />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="distributionCenter.pages.pickingOrders.tabs.cages">
          <CagesTab />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </>
  );
};
