import { useRecordContext } from 'react-admin';
import { Typography } from '@mui/material';
import { Flex } from '@UI';
import { PickerProps } from '../../interface';

export const Picker = ({ withLogin, asColumn }: PickerProps) => {
  const record = useRecordContext<CorePickingOrderDto>();

  return (
    <Flex asColumn={asColumn}>
      <Typography fontSize={14}>{record.picker?.lastName ?? ''}</Typography>

      <Typography fontSize={14} marginLeft={asColumn ? 0 : 1}>{record.picker?.firstName ?? ''}</Typography>

      {withLogin && (
        <Typography fontSize={14} marginLeft={asColumn ? 0 : 1}>({record.picker?.login ?? ''})</Typography>
      )}
    </Flex>
  );
};
