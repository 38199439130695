import { ValueOf } from 'type-fest';

export const get = (
  object: Record<any, any>,
  path: string | string[],
  defaultValue?: any
) => {
  const pathSegments =
    typeof path === 'string' ? path.split(/[[\].]/).filter(Boolean) : path;

  let currVal: any = object;

  for (const pathSegment of pathSegments) {
    currVal = currVal[pathSegment];

    if (!currVal) {
      return defaultValue;
    }
  }

  return currVal;
};

export const isObjectOrArray = (val: any) => val instanceof Object;

export const isObject = (val: any) =>
  isObjectOrArray(val) && !Array.isArray(val);

export const findEntryInObject = <TRecord extends Record<any, any>>(
  obj: TRecord,
  predicate: (value: ValueOf<TRecord>, name: keyof TRecord) => boolean
): [keyof TRecord, ValueOf<TRecord>] | undefined =>
  Object.entries(obj).find(([fieldName, fieldVal]) =>
    predicate(fieldVal, fieldName)
  );

export const findValInObject = <TRecord extends Record<any, any>>(
  obj: TRecord,
  predicate: (value: ValueOf<TRecord>, name: keyof TRecord) => boolean
): ValueOf<TRecord> | undefined =>
  findEntryInObject<TRecord>(obj, predicate)?.[1];

export const findFieldNameInObject = <TRecord extends Record<any, any>>(
  obj: TRecord,
  predicate: (value: ValueOf<TRecord>, name: keyof TRecord) => boolean
): keyof TRecord | undefined => findEntryInObject<TRecord>(obj, predicate)?.[0];
