import { AnyMasked } from 'react-imask';

export const maskDispatch = (
  appended: string,
  dynamicMasked: AnyMasked
): AnyMasked => {
  const number = (dynamicMasked.value + appended).replace(/\D/g, '');

  return dynamicMasked.compiledMasks.find((m: AnyMasked) =>
    number.startsWith(m.startsWith)
  );
};

export const validatePhoneValue = (
  ASCIICode: number,
  value: string
): boolean => {
  // 43 - is a `+` key
  if (ASCIICode === 43) {
    return !value.includes('+');
  }

  // If the ASCII code of the pressed key is not a number
  // Preventing event.
  return !(ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57));
};

export const validateCountryCodePlus = (value: string): string => {
  if (!value || value === '+') {
    return value;
  }

  if (!value.startsWith('+')) {
    return `+${value}`;
  }

  const plusRemoved = value.replace(/\+/gi, '');

  return `+${plusRemoved}`;
};
