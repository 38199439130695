import { GetListResult } from 'react-admin';
import { FieldValues } from 'react-hook-form';

import { CommonCrud } from '@PluginBase';
import { DELIVERY_SLOT_API } from '@Plugins/Stores/resources/Warehouses/apiRoutes';
import { unitsToPoints, pointsToUnits } from '@Helpers';

import { CreateSchedulePayload } from './interfaces';

export const deliveryScheduleCRUD = new CommonCrud(DELIVERY_SLOT_API);

export const getScheduleByZoneFactory =
  (areaId: string) =>
  (): Promise<GetListResult<CoreDeliveryScheduleSlotEntity>> =>
    deliveryScheduleCRUD.list({
      pagination: { page: 1, perPage: 1000 },
      filter: { zone_id: areaId },
      sort: {
        field: 'day_of_week',
        order: 'asc',
      },
    });

export const submitScheduleValues = (
  values: FieldValues
): CreateSchedulePayload => {
  const formatInteger = (value?: string): number => {
    if (!value) return 0;

    return Number(value);
  };

  return {
    name: values.name,
    day_of_week: values.day_of_week,
    cut_off_time: values.cut_off_time,
    delivery_capacity: formatInteger(values.delivery_capacity),
    delivery_end_time: values.delivery_end_time,
    delivery_price: unitsToPoints(formatInteger(values.delivery_price)),
    delivery_start_time: values.delivery_start_time,
    free_delivery_threshold: unitsToPoints(
      formatInteger(values.free_delivery_threshold)
    ),
    order_amount_threshold: values.order_amount_threshold
      ? unitsToPoints(values.order_amount_threshold)
      : null,
    max_future_date: values.max_future_date
      ? `${values.max_future_date}`
      : '14',
    delivery_lead_duration_sec:
      formatInteger(values.delivery_lead_duration_sec) * 60,
    wh_processing_duration_sec: Math.ceil(
      formatInteger(values.wh_processing_duration_sec) * 60
    ),
  };
};

export const normalizeDeliveryScheduleInstance = (
  instance: CoreDeliveryScheduleSlotEntity
): CoreDeliveryScheduleSlotEntity => {
  const deliveryLeadDurationSec =
    instance.delivery_lead_duration_sec > 0
      ? instance.delivery_lead_duration_sec / 60
      : 0;
  const whProcessingDurationSec =
    instance.wh_processing_duration_sec > 0
      ? instance.wh_processing_duration_sec / 60
      : 0;

  return {
    ...instance,
    order_amount_threshold: instance.order_amount_threshold
      ? (pointsToUnits(instance.order_amount_threshold) as unknown as number)
      : null,
    delivery_price: Number(pointsToUnits(instance.delivery_price)),
    free_delivery_threshold: Number(
      pointsToUnits(instance.free_delivery_threshold)
    ),
    delivery_lead_duration_sec: Number(
      deliveryLeadDurationSec.toString().includes('.')
        ? deliveryLeadDurationSec.toFixed(2)
        : deliveryLeadDurationSec
    ),
    wh_processing_duration_sec: Number(
      whProcessingDurationSec.toString().includes('.')
        ? whProcessingDurationSec.toFixed(2)
        : whProcessingDurationSec
    ),
  };
};
