import {
  TextInput,
  NumberInput,
  useTranslate,
  DateTimeInput,
  required,
  number,
} from 'react-admin';
import { FormHelperText, useMediaQuery } from '@mui/material';

import { Flex, ResourceInputNumber, ResourceSwitcher, RowInfoBlock } from '@UI';
import { useIsMobile } from '@ROOT/hooks';
import {
  isEmptyAfterTrimmed,
  maxLengthExt,
  minLengthExt,
  minNum,
  MoneyFormatter,
  isInteger,
} from '@Helpers';
import { maxDigits } from '@Plugins/Promotions/resources/Promocodes/pages/PromocodeForm/components/MinRequirementsBlock';
import {
  validateEndOrderNumber,
  validateStartOrderNumber,
} from '@Plugins/Promotions/resources/Promocodes/pages/PromocodeForm/components/UsageLimits';
import { validateRangeEnd } from '@Plugins/Promotions/resources/Promocodes/pages/PromocodeForm/components/ActiveDates';

import { ProductListAttachedToGift } from '../ProductListAttachedToGift';

const validateName = [
  required(),
  isEmptyAfterTrimmed(),
  minLengthExt(5, 'promotions.pages.gifting.errors.tooShort'),
  maxLengthExt(255, 'promotions.pages.gifting.errors.tooLong'),
];
const validateStartDate = [required()];
const validateEndDate = [validateRangeEnd()];
const validateMinimumPurchaseAmount = [
  number(),
  isInteger(),
  minNum(0),
  maxDigits(7),
];
const validatePriority = [required(), number(), isInteger()];
const validateUsagePerUser = [number(), minNum(0), isInteger()];
const validateOrderNumberRangeStart = [
  number(),
  isInteger(),
  validateStartOrderNumber(),
];
const validateOrderNumberRangeEnd = [
  number(),
  isInteger(),
  validateEndOrderNumber(),
];

const GiftingForm = () => {
  const isMobile = useIsMobile();
  const translate = useTranslate();
  const isDatesInARow = useMediaQuery('(max-width: 1400px)');

  return (
    <>
      <Flex asColumn={isMobile} gap={2} fullWidth>
        <Flex asColumn gap={1} flex="1 1 0">
          <Flex asColumn={false}>
            <RowInfoBlock fullWidth={false}>
              <ResourceSwitcher
                name="isActive"
                labelKey="promotions.pages.gifting.labels.isActive"
              />
            </RowInfoBlock>
          </Flex>

          <RowInfoBlock titleKey="promotions.pages.gifting.labels.giftName">
            <TextInput
              name="name"
              source="name"
              multiline
              rows={3}
              fullWidth
              placeholder={translate(
                'promotions.pages.gifting.phrases.namePlaceholder'
              )}
              validate={validateName}
            />
          </RowInfoBlock>

          <Flex asColumn={false} fullWidth gap={2}>
            <Flex asColumn flex="1 1 0" gap={2}>
              <RowInfoBlock titleKey="promotions.pages.gifting.labels.activeDate">
                <Flex asColumn={isDatesInARow} gap={2} fullWidth>
                  <Flex flex="1 1 0">
                    <DateTimeInput
                      label="promotions.pages.gifting.labels.startDate"
                      name="dateRange.0"
                      source="dateRange.0"
                      parse={(val) => (val ? new Date(val).toISOString() : '')}
                      validate={validateStartDate}
                      fullWidth
                    />
                  </Flex>

                  <Flex flex="1 1 0">
                    <DateTimeInput
                      label="promotions.pages.gifting.labels.endDate"
                      name="dateRange.1"
                      source="dateRange.1"
                      parse={(val) => (val ? new Date(val).toISOString() : '')}
                      fullWidth
                      validate={validateEndDate}
                    />
                  </Flex>
                </Flex>
              </RowInfoBlock>

              <RowInfoBlock titleKey="promotions.pages.gifting.labels.limitation">
                <ResourceInputNumber
                  fullWidth
                  source="minimumPurchaseAmount"
                  label="promotions.pages.gifting.labels.minimumPurchaseAmount"
                  prefixSymbol={MoneyFormatter.getCurrencySymbol()}
                  validate={validateMinimumPurchaseAmount}
                  sx={HELPER_TEXT_WRAPPER_STYLES}
                  helperText="promotions.pages.gifting.phrases.minimumPurchaseHelp"
                />
                <Flex asColumn={false} gap={1} sx={{ mt: 1 }}>
                  <ResourceInputNumber
                    fullWidth
                    isInteger
                    source="usageLimitAll"
                    label="promotions.pages.gifting.labels.usageLimitAll"
                    validate={validateUsagePerUser}
                  />
                  <ResourceInputNumber
                    fullWidth
                    isInteger
                    source="usageLimitCustomer"
                    label="promotions.pages.gifting.labels.usageLimitCustomer"
                    validate={validateUsagePerUser}
                  />
                </Flex>
              </RowInfoBlock>
            </Flex>

            <Flex asColumn flex="1 1 0" gap={2}>
              <RowInfoBlock titleKey="promotions.pages.gifting.labels.giftPriority">
                <NumberInput
                  name="priority"
                  source="priority"
                  helperText={translate(
                    'promotions.pages.gifting.phrases.priorityHelp'
                  )}
                  sx={HELPER_TEXT_WRAPPER_STYLES}
                  validate={validatePriority}
                  fullWidth
                />
              </RowInfoBlock>

              <RowInfoBlock titleKey="promotions.pages.gifting.labels.orderNumber">
                <Flex asColumn={isMobile} gap={5} fullWidth>
                  <Flex flex={1} fullWidth>
                    <ResourceInputNumber
                      source="orderNumberRange.0"
                      label="promotions.pages.gifting.labels.startOrderNumber"
                      isInteger
                      fullWidth
                      validate={validateOrderNumberRangeStart}
                    />
                  </Flex>

                  <Flex flex={1}>
                    <ResourceInputNumber
                      source="orderNumberRange.1"
                      label="promotions.pages.gifting.labels.endOrderNumber"
                      isInteger
                      fullWidth
                      validate={validateOrderNumberRangeEnd}
                    />
                  </Flex>
                </Flex>
                <FormHelperText>
                  {translate('promotions.pages.gifting.phrases.orderNumberHelp')
                    .split('\n')
                    .map((line) => (
                      <p key={line} style={{ margin: 0 }}>
                        {line}
                      </p>
                    ))}
                </FormHelperText>
              </RowInfoBlock>
            </Flex>
          </Flex>
        </Flex>
        <Flex asColumn flex="1 1 0">
          <RowInfoBlock titleKey="promotions.pages.gifting.labels.listOfGifts">
            <ProductListAttachedToGift />
          </RowInfoBlock>
        </Flex>
      </Flex>
    </>
  );
};

const HELPER_TEXT_WRAPPER_STYLES = {
  '& .MuiFormHelperText-root': { marginInline: 0, marginTop: 1 },
};

export default GiftingForm;
