import { useEffect, useState, ReactNode } from 'react';
import { useTheme } from 'react-admin';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Theme } from '@mui/material';

import { darkTheme, getTheme } from './themes';
import { useLocale } from './localeProvider';

export const RTL_SUPPORT_LANGUAGES = ['ar', 'he'];

export const adjustTheme = (locale: string) => {
  if (RTL_SUPPORT_LANGUAGES.includes(locale)) {
    return getTheme(darkTheme, 'rtl');
  }

  return getTheme(darkTheme, 'ltr');
};

const LocaleAndThemeProvider = ({
  children,
}: {
  children?: ((theme: Theme) => ReactNode) | ReactNode;
}) => {
  const { locale } = useLocale();
  const [theme, setTheme] = useState(() => adjustTheme(locale));
  const [themeOptions] = useTheme();

  useEffect(() => {
    const direction = RTL_SUPPORT_LANGUAGES.includes(locale) ? 'rtl' : 'ltr';

    setTheme(getTheme(themeOptions, direction));
    document.body.dir = direction;
    document.documentElement.lang = locale;
  }, [locale]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {typeof children === 'function' ? children(theme) : children}
    </ThemeProvider>
  );
};

export default LocaleAndThemeProvider;
