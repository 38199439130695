import { Typography } from '@mui/material';
import { useRecordContext } from 'react-admin';

export const ResourcePaymentMethods = () => {
  const record = useRecordContext<CoreDeliveryAreaEntity>();

  return (
    <Typography fontSize={14}>{record.payment_methods.join(', ')}</Typography>
  );
};
