import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormDataConsumer, useTranslate } from 'react-admin';
import { Tooltip, Typography } from '@mui/material';
import { InfoRounded } from '@mui/icons-material';

import { Flex, ResourceInputNumber, ResourceSwitcher } from '@UI';

export const OFFER_VARIANT_TYPES = ['colorList', 'sizeList', 'selector'];

const OfferVariantOptions = () => {
  const translate = useTranslate();

  const { watch, getValues, setValue } = useFormContext();

  const isOfferVariants = watch('isOfferVariants');

  useEffect(() => {
    if (isOfferVariants) {
      const { type } = getValues();

      if (!OFFER_VARIANT_TYPES.includes(type)) {
        setValue('type', OFFER_VARIANT_TYPES[0], { shouldDirty: true });
      }
    }
  }, [isOfferVariants]);

  return (
    <>
      <ResourceSwitcher
        name="isOfferVariants"
        labelKey={
          <Flex gap={1} alignItems="center">
            <Typography>
              {translate('settings.pages.properties.labels.isOfferVariants')}
            </Typography>
            <Tooltip
              title={translate(
                'settings.pages.properties.hints.isOfferVariants'
              )}
              placement="top"
            >
              <InfoRounded fontSize="small" />
            </Tooltip>
          </Flex>
        }
      />

      <FormDataConsumer>
        {({ formData }) =>
          formData.isOfferVariants && (
            <ResourceInputNumber
              min={0}
              source="sorting"
              name="sorting"
              defaultValue={0}
              label={
                <Flex gap={1} alignItems="center">
                  <span>
                    {translate(
                      'settings.pages.properties.labels.sortingOnProductPage'
                    )}
                  </span>
                  <Tooltip
                    title={translate(
                      'settings.pages.properties.hints.sortingOnProductPage'
                    )}
                    placement="top"
                  >
                    <InfoRounded fontSize="small" />
                  </Tooltip>
                </Flex>
              }
              isInteger
              fullWidth
            />
          )
        }
      </FormDataConsumer>
    </>
  );
};

export default OfferVariantOptions;
