import { ListColumn, Filter } from '@Widgets/ResourceList/interface';
import { ResourceProductStatus } from './components/ResourceProductStatus';
import { ResourceProductId } from './components/ResourceProductId';
import { OfferName } from '../Offers/components/columns/OfferName';
import FileLink from '../OfferImports/components/FileLink/FileLink';

export const OFFER_LIST_COLUMNS: ListColumn<CoreOffer>[] = [
  {
    source: 'id',
    label: 'catalogue.pages.products.labels.id',
    type: 'text',
  },
  {
    source: 'productId',
    label: 'catalogue.pages.offers.labels.productId',
    type: 'custom',
    customComponent: <ResourceProductId key="productId" />,
    sortable: false,
  },
  {
    // TODO: Use "function" type for getting data from deep paths
    // @ts-ignore
    source: 'images[0].url',
    label: 'catalogue.pages.products.labels.firstImage',
    type: 'image',
    showInLine: false,
    sortable: false,
  },
  {
    source: 'name',
    label: 'catalogue.pages.products.labels.name',
    type: 'custom',
    customComponent: <OfferName key="name" />,
  },
  {
    source: 'status',
    label: 'catalogue.pages.products.labels.status',
    type: 'custom',
    customComponent: <ResourceProductStatus key="status" />,
    sortable: false,
  },
  {
    source: 'code',
    label: 'catalogue.pages.products.labels.code',
    type: 'text',
    sortable: false,
  },
  {
    source: 'price',
    label: 'catalogue.pages.products.labels.price',
    type: 'text',
    sortable: false,
  },
  {
    source: 'discountPrice',
    label: 'catalogue.pages.products.labels.discountPrice',
    type: 'text',
    sortable: false,
  },
];

export const OFFER_IMPORT_LIST_COLUMNS: ListColumn<CoreOfferImport>[] = [
  {
    source: 'id',
    label: 'catalogue.pages.offerOperations.labels.id',
    type: 'text',
    sortable: false,
  },
  {
    source: 'file',
    label: 'catalogue.pages.offerImports.labels.file',
    type: 'custom',
    customComponent: <FileLink />,
    sortable: false,
  },
  {
    source: 'createdAt',
    type: 'date',
    label: 'catalogue.pages.offerOperations.labels.createdAt',
  },
  {
    source: 'rowsSuccessCount',
    type: 'number',
    label: 'catalogue.pages.offerOperations.labels.rowsTotalCount',
    sortable: false,
  },
  {
    source: 'errors',
    label: 'catalogue.pages.offerOperations.labels.errors',
    type: 'function',
    function: (record) => record.errors?.length,
    sortable: false,
  },
];

export const STATUS_CHOICES: SelectOptionTyped<CoreProductStatus>[] = [
  {
    id: 'draft',
    name: 'catalogue.pages.products.filters.statuses.draft',
  },
  {
    id: 'content_ready',
    name: 'catalogue.pages.products.filters.statuses.content_ready',
  },
  {
    id: 'active',
    name: 'catalogue.pages.products.filters.statuses.active',
  },
];

export const SUPPLIER_CHOICES: SelectOptionTyped<string>[] = [
  {
    id: 'JIFFY',
    name: 'JIFFY',
  },
];

export const LIST_FILTERS: Filter[] = [
  { source: 'search', type: 'search' },
  {
    source: 'status',
    label: 'catalogue.pages.products.labels.status',
    type: 'choose',
    choices: STATUS_CHOICES,
    emptyText: 'catalogue.pages.products.labels.allStatuses',
  },
];

export const DEFAULT_OFFER_FORM_VALUES: Partial<CoreOffer> = {
  description: '',
  images: [],
  offerGeneratorProperties: {},
  barcodes: [],
  properties: [],
  deepLink: '',
  code: '',
  stock: [],
  status: 'draft',
  supplierPartnerCode: 'JIFFY',
  vatAmount: 0,
};
