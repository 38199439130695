import { useEffect, useRef } from 'react';
import { useTranslate } from 'react-admin';
import { Accordion, AccordionSummary, Tooltip } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';

import { WEEK_OPTIONS } from '@PluginManager/constants';
import { minNum, pointsToUnits } from '@Helpers';

import { DeliveryMechanicAccordionBody } from '../DeliveryMechanicAccordionBody';

import {
  DeliveryMechanicAccordionProps,
  DeliveryMechanicFormValues,
} from './interfaces';
import { normalizeScheduleDefaultValues } from './normalize';
import { submitZoneShippingMechanic } from './submit';
import { Flex, ResourceMoneyInput } from '@UI';
import { Help } from '@mui/icons-material';

export const DeliveryMechanicAccordion = (
  props: DeliveryMechanicAccordionProps
) => {
  const {
    targetZoneShippingMethod,
    deliveryMechanicId,
    shippingMethodId,
    onChange,
    formRef,
  } = props;
  const onChangeDebounceRef = useRef<ReturnType<typeof setTimeout> | null>(
    null
  );

  const t = useTranslate();

  const orderAmountThreshold =
    targetZoneShippingMethod?.order_amount_threshold ?? '0';

  const formMethods = useForm<DeliveryMechanicFormValues>({
    defaultValues: {
      order_amount_threshold:
        typeof orderAmountThreshold === 'number'
          ? orderAmountThreshold.toString()
          : orderAmountThreshold,
      schedule: normalizeScheduleDefaultValues(targetZoneShippingMethod),
    },
    mode: 'all',
  });

  const formValues = formMethods.watch();

  useEffect(() => {
    if (onChangeDebounceRef.current) {
      clearTimeout(onChangeDebounceRef.current);
      onChangeDebounceRef.current = null;
    }

    onChangeDebounceRef.current = setTimeout(() => {
      onChange?.(
        submitZoneShippingMechanic({
          values: formValues,
          shippingMethodId:
            targetZoneShippingMethod?.shippingMethodId || shippingMethodId,
          deliveryMechanicId,
        })
      );
    }, 300);
  }, [JSON.stringify(formValues)]);

  useEffect(() => {
    if (!formRef || !!formRef.current) {
      return;
    }

    formRef.current = formMethods;
  }, [formRef]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={(e) => e.preventDefault()} style={{ width: '100%' }}>
        {WEEK_OPTIONS.map((item) => (
          <Accordion key={item.id} sx={{ width: '100%' }}>
            <AccordionSummary>{t(item.name)}</AccordionSummary>
            <DeliveryMechanicAccordionBody source={`schedule.${item.id}`} />
          </Accordion>
        ))}

        <ResourceMoneyInput
          defaultValue="0"
          sx={{ mt: 4 }}
          fullWidth
          name="order_amount_threshold"
          source="order_amount_threshold"
          label={
            <Flex asColumn={false} alignItems="center" gap={1}>
              <span>
                {t(
                  'stores.warehouses.pages.labels.expressDeliveryMinOrderValue'
                )}
              </span>
              <Tooltip
                placement="top"
                title={t(
                  'stores.warehouses.pages.hints.expressDeliveryMinOrderValue'
                )}
              >
                <Help />
              </Tooltip>
            </Flex>
          }
          validate={validateAmountThreshold}
        />
      </form>
    </FormProvider>
  );
};

const validateAmountThreshold = [minNum(parseFloat(pointsToUnits(0)))];
