import { useEffect } from 'react';
import { useTranslate } from 'react-admin';
import { useController } from 'react-hook-form';
import { Tooltip } from '@mui/material';
import { InfoRounded } from '@mui/icons-material';

import { ResourceSwitcher, Flex } from '@UI';

const FilterOptions = () => {
  const translate = useTranslate();
  const { field: isFilterableField } = useController({ name: 'isFilterable' });
  const { field: showExpandedField } = useController({ name: 'showExpanded' });

  useEffect(() => {
    if (!isFilterableField.value) {
      showExpandedField.onChange(false);
    }
  }, [isFilterableField.value]);

  return (
    <>
      <ResourceSwitcher
        name="isFilterable"
        labelKey={
          <Flex gap={1} alignItems="center">
            <span>
              {translate('settings.pages.properties.labels.isFilterable')}
            </span>
            <Tooltip
              title={translate('settings.pages.properties.hints.isFilter')}
              placement="top"
            >
              <InfoRounded fontSize="small" />
            </Tooltip>
          </Flex>
        }
      />

      <ResourceSwitcher
        name="showExpanded"
        disabled={!isFilterableField.value}
        labelKey={
          <Flex gap={1} alignItems="center">
            <span>
              {translate('settings.pages.properties.labels.showExpanded')}
            </span>
            <Tooltip
              title={translate('settings.pages.properties.hints.showExpanded')}
              placement="top"
            >
              <InfoRounded fontSize="small" />
            </Tooltip>
          </Flex>
        }
      />
    </>
  );
};

export default FilterOptions;
