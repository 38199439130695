import { PluginResourceController, CommonCrud } from '@PluginBase';
import { ResourceRoutes } from '../../../resourceRoutes';
import { GetListResult } from 'react-admin';
import { ListPage } from './ListPage';
import { Icon } from '@UI';
import { apiUrl } from './constants';

const offerImportCrud = new CommonCrud(apiUrl);

offerImportCrud.isNewSorting = true;

const OfferImportsController = new PluginResourceController({
  menuItem: {
    caption: {
      translationKey: 'catalogue.pages.offerOperations.caption',
    },
    route: ResourceRoutes.offerOperations.routePath,
    icon: <Icon type="offerImport" />,
  },
  resourceRoute: {
    name: ResourceRoutes.offerOperations.resourcePath,
    list: ListPage,
  },

  dataProvider: {
    getList: (resource, params): Promise<GetListResult> =>
      offerImportCrud.list(params),
  },
});

export default OfferImportsController;
