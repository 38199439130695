export const parseTimeToMinutes = (time: string): number => {
  const [hours, minutes] = time.split(':');
  const hoursInMinutes = Number(hours) * 60;

  return hoursInMinutes + Number(minutes);
};

export const sortScheduleSlotsByTime = (
  a: CoreDeliveryScheduleSlotEntity,
  b: CoreDeliveryScheduleSlotEntity
): number => {
  if (a.delivery_start_time === b.delivery_start_time) {
    if (a.delivery_end_time === b.delivery_end_time) {
      return 0;
    }

    const a_end = parseTimeToMinutes(a.delivery_end_time);
    const b_end = parseTimeToMinutes(b.delivery_end_time);

    return a_end > b_end ? 1 : -1;
  }

  const a_start = parseTimeToMinutes(a.delivery_start_time);
  const b_start = parseTimeToMinutes(b.delivery_start_time);

  return a_start > b_start ? 1 : -1;
};
