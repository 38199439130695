import { Plugin } from '@PluginBase';
import Translation from './i18n.json';
import ReportResourceController from './resources/Reports/controller';

const Reports = new Plugin({
  name: 'Reports',
  resources: [ReportResourceController],
  i18n: Translation,
});

export default Reports;
