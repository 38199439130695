export const TRANSLATION_SCHEMA = {
  captions: {
    desktop: 'desktop',
    mobile: 'mobileCaption',
  },
  labels: {
    orderId: 'orderId',
    order: 'order',
    warehouse: 'warehouse',
    editOrder: 'editOrder',
    picker: 'picker',
    status: 'status',
    createdAt: 'createdAt',
    startedAt: 'startedAt',
    startAfter: 'startAfter',
    completedAt: 'completedAt',
    shipBy: 'shipBy',
    items: 'items',
    searchPlaceholder: 'searchPlaceholder',
    image: 'image',
    productName: 'productName',
    productSku: 'productSku',
    productBarcodes: 'productBarcodes',
    quantityToPick: 'quantityToPick',
    quantityPicked: 'quantityPicked',
    details: 'details',
    detailsGeneral: 'detailsGeneral',
    detailsSla: 'detailsSla',
    detailsOther: 'detailsOther',
    itemsRequested: 'itemsRequested', // should we use quantityToPick maybe?
    itemsPicked: 'itemsPicked', // should we use quantityPicked maybe?
    pickingTime: 'pickingTime',
    waitingForPicking: 'waitingForPicking',
    pickingTimePerItem: 'pickingTimePerItem',
    fulfillmentRate: 'fulfillmentRate',
    statusEmpty: 'statusEmpty',
    warehouseValue: 'warehouseValue',
    pickerValue: 'pickerValue',
    createdAtValue: 'createdAtValue',
    startedAtValue: 'startedAtValue',
    startAfterValue: 'startAfterValue',
    completedAtValue: 'completedAtValue',
    shipByValue: 'shipByValue',
    itemsRequestedValue: 'itemsRequestedValue',
    itemsPickedValue: 'itemsPickedValue',
    pickingTimeValue: 'pickingTimeValue',
    waitingForPickingValue: 'waitingForPickingValue',
    pickingTimePerItemValue: 'pickingTimePerItemValue',
    fulfillmentRateValue: 'fulfillmentRateValue',
  },
  actions: {
    finishOrderButton: 'finishOrderButton',
    finishOrderDescription: 'finishOrderDescription',
    editOrder: 'editOrder',
    finishOrder: 'finishOrder',
  },
  errors: {
    finishOrderError: 'finishOrderError',
    pickerChangeError: 'pickerChangeError',
  },
  messages: {
    finishOrderSuccess: 'finishOrderSuccess',
    pickerChangeSuccess: 'pickerChangeSuccess',
  },
  lists: {
    tabs: {
      general: 'general',
      items: 'items',
    },
    statuses: {
      created: 'created',
      in_progress: 'in_progress',
      packing: 'packing',
      finished: 'finished',
      assigned: 'assigned',
    },
  },
};
