export const TRANSLATION_SCHEMA = {
  labels: {
    name: 'name',
    status: 'status',
    sorting: 'sorting',
    image: 'image',
    startDate: 'startDate',
    endDate: 'endDate',
    duration: 'duration',
    deepLink: 'deepLink',
    endStoryLink: 'endStoryLink',
    warehouseBlackList: 'warehouseBlackList',
    storyTitle: 'storyTitle',
    navigationLink: 'navigationLink',
    buttonFontColor: 'buttonFontColor',
    buttonFontSize: 'buttonFontSize',
    buttonBackgroundColor: 'buttonBackgroundColor',
    buttonColor: 'buttonColor',
    buttonBorderColor: 'buttonBorderColor',
    buttonPadding: 'buttonPadding',
    borderRadius: 'borderRadius',
    buttonCoordinate: 'buttonCoordinate',
    story: 'story',
    active: 'active',
    inactive: 'inactive',
    allowedImage: 'allowedImage',
    isStart: 'isStart',
  },
  actions: {
    addStoryButton: 'addStoryButton',
    removeStoryButton: 'removeStoryButton',
    removeStory: 'removeStory',
  },
  errors: {
    wrongEndDate: 'wrongEndDate',
    notEnoughStories: 'notEnoughStories',
    imageIsRequired: 'imageIsRequired',
    mainImageIsRequired: 'mainImageIsRequired',
    notEnoughIntervals: 'notEnoughIntervals',
  },
  captions: {
    desktop: 'desktop',
    mobile: 'mobileCaption',
  },
  lists: {
    tabs: {
      general: 'general',
      schedule: 'schedule',
      mainImage: 'mainImage',
      gallery: 'gallery',
    },
  },
};
