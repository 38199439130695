import { useState } from 'react';
import { Collapse } from '@mui/material';
import { useSidebarState } from 'react-admin';
import { SubMenuHeader } from './SubMenuHeader/SubMenuHeader';
import { MenuBodyListStyled } from './styles';
import { SubMenuProps } from '../interface';

const SubMenu = (props: SubMenuProps) => {
  const { isExpanded, name, icon, children, dense, handleExpanded } = props;
  const [isOpen, setIsOpen] = useState(isExpanded);
  const [sidebarIsOpen] = useSidebarState();
  const toggleSubMenu = () => setIsOpen((prevState) => {
    handleExpanded(!prevState);

    return !prevState;
  });

  return (
    <div>
      <SubMenuHeader onClick={toggleSubMenu} isOpen={isOpen} name={name} icon={icon} dense={dense} />

      <Collapse in={isOpen} timeout='auto' unmountOnExit>
        <MenuBodyListStyled dense={dense} $sidebarIsOpen={sidebarIsOpen} disablePadding>
          {children}
        </MenuBodyListStyled>
      </Collapse>
    </div>
  );
};

export default SubMenu;
