import React from 'react';
import {
  BooleanField,
  DateField,
  EmailField,
  NumberField,
  FunctionField,
  FunctionFieldProps,
  RaRecord,
  useRecordContext,
  useLocaleState,
} from 'react-admin';
import { ResourceReferenceLinkField } from '../ResourceReferenceLinkField/ResourceReferenceLinkField';
import { ResourcePreview } from '../ResourcePreview/ResourcePreview';
import { ComponentFactoryProps } from '../interface';
import { useIsMobile } from '@Hooks/useIsMobile';
import { TextFieldStyled, UrlFieldStyled } from '../styles';
import { get } from '@Helpers';
import { Typography } from '@mui/material';
import { ResourceColorField } from '@UI';

export const ComponentFactory = <RecordType extends RaRecord = RaRecord>({
  column,
  record,
}: ComponentFactoryProps<RecordType>) => {
  const { type, label, source, sortable } = column;
  const isMobile = useIsMobile();
  const options = {
    label: isMobile ? '' : label,
    key: source,
    source,
    sortable,
    record,
  };
  const rec = useRecordContext(options);
  const [locale] = useLocaleState();

  if (type === 'custom') {
    return React.cloneElement(column.customComponent, { record: rec });
  }

  const title = get(rec, source) as string | undefined | null;

  switch (column.type) {
    case 'boolean':
      return <BooleanField {...options} />;
    case 'date':
      return (
        <DateField {...options} locales={locale} showTime={column.showTime} />
      );
    case 'text':
      return (
        <Typography title={title || undefined} maxWidth="100%">
          <TextFieldStyled {...options} truncate={column.truncate ?? 350} />
        </Typography>
      );
    case 'email':
      return <EmailField {...options} />;
    case 'referenceId':
      return (
        <ResourceReferenceLinkField resource={column.resource} {...options} />
      );
    case 'url':
      return <UrlFieldStyled {...options} truncate={column.truncate ?? 350} />;
    case 'image':
      return <ResourcePreview {...options} size={column.width} />;
    case 'number':
      return <NumberField {...options} />;
    case 'color':
      return <ResourceColorField {...options} />;
    case 'function':
      return (
        <FunctionField
          {...options}
          render={column.function as FunctionFieldProps['render']}
        />
      );
    default:
      return null;
  }
};
