import { ReactElement } from 'react';
import { ResourceList } from '@Widgets/ResourceList/ResourceList';
import { useTranslate, CreateButton, useUpdate } from 'react-admin';
import { ListColumn } from '@Widgets/ResourceList/interface';
import { Flex, FileImport, SUPPORTED_FORMATS } from '@UI';
import { useCheckMutationAnswer } from '@Hooks/useCheckMutationAnswer';
import { ResourceRoutes } from '@PluginManager/plugins/resourceRoutes';

interface Store {
  id: string;
  name: string;
  profiles: number[];
}

const STORE_LIST_COLUMNS: ListColumn<Store>[] = [
  {
    source: 'name',
    label: 'replenishment.pages.stores.labels.name',
    type: 'text',
  },
  {
    source: 'profiles',
    label: 'replenishment.pages.stores.labels.profiles',
    type: 'text',
  },
];

export const ListPage = (): ReactElement => {
  const translate = useTranslate();
  const [update, { isLoading, error, isSuccess }] = useUpdate();

  useCheckMutationAnswer({
    successMessage: 'replenishment.pages.stores.messages.successImport',
    errorMessage: 'replenishment.pages.stores.errors.errorImport',
    error: error as Error,
    isSuccess,
    shouldUpdateList: true,
  });

  async function onFileSelectHandler(file: File) {
    await update(ResourceRoutes.replenishment.stores.resourcePath, {
      data: { file },
      meta: 'load',
    });
  }

  return (
    <ResourceList<Store>
      empty={false}
      exporter={false}
      columns={STORE_LIST_COLUMNS}
      showDeleteButton={false}
      actions={
        <Flex alignItems="center">
          <FileImport
            isLoading={isLoading}
            isSuccess={isSuccess}
            accept={SUPPORTED_FORMATS.csv.toString()}
            onFileSubmitted={onFileSelectHandler}
          />

          <CreateButton />
        </Flex>
      }
      getItemCardCaption={(record) =>
        translate('replenishment.pages.stores.labels.mobileCardCaption', {
          id: record.id,
        })
      }
    />
  );
};
