import { ReactElement } from 'react';
import { ResourceList } from '@Widgets/ResourceList/ResourceList';
import { useTranslate, useUpdate } from 'react-admin';
import { FileImport, Flex, SUPPORTED_FORMATS } from '@UI';
import { useCheckMutationAnswer } from '@Hooks/useCheckMutationAnswer';
import { ResourceRoutes } from '@PluginManager/plugins/resourceRoutes';
import { Stock } from './interface';
import { LIST_COLUMNS } from './constants';

export const ListPage = (): ReactElement => {
  const translate = useTranslate();
  const [update, { isLoading, error, isSuccess }] = useUpdate();

  useCheckMutationAnswer({
    successMessage: 'replenishment.pages.stocks.messages.successImport',
    errorMessage: 'replenishment.pages.stocks.errors.errorImport',
    error: error as Error,
    isSuccess,
    shouldUpdateList: true,
  });

  async function onFileSelectHandler(file: File) {
    await update(ResourceRoutes.replenishment.stocks.resourcePath, {
      data: { file },
      meta: 'load',
    });
  }

  return (
    <ResourceList<Stock>
      empty={false}
      exporter={false}
      columns={LIST_COLUMNS}
      showDeleteButton={false}
      actions={
        <Flex alignItems="center">
          <FileImport
            isLoading={isLoading}
            isSuccess={isSuccess}
            accept={SUPPORTED_FORMATS.csv.toString()}
            onFileSubmitted={onFileSelectHandler}
          />
        </Flex>
      }
      getItemCardCaption={(record) =>
        translate('replenishment.pages.stocks.labels.mobileCardCaption', {
          id: record.id,
        })
      }
    />
  );
};
