import { useGetList, useRecordContext, useGetOne } from 'react-admin';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { RowInfoBlock, Flex } from '@UI';
import { ResourceRoutes } from '@Plugins/resourceRoutes';
import { useIsMobile } from '@Hooks/useIsMobile';

import { AdditionalPropertiesProps } from './interface';
import { PropertySelector } from './PropertySelector/PropertySelector';
import { InheritedPropertyList } from './ProductPropertyList/InheritedPropertyList';
import { PropertiesFields } from './PropertiesFields/PropertiesFields';
import { getDefaultValueByType } from './PropertiesFields/utils';
import { AdditionalFieldWithId } from './PropertiesFields/interfaces';

export const AdditionalProperties = ({ source }: AdditionalPropertiesProps) => {
  const isMobile = useIsMobile();
  const { control } = useFormContext();
  const {
    fields: additionalProperties,
    append,
    remove,
  } = useFieldArray({
    name: source,
    control,
  });
  const { productId } = useRecordContext<CoreOffer>();

  const { data: propertiesList = [] } = useGetList(
    ResourceRoutes.properties.resourcePath,
    {
      pagination: {
        page: 1,
        perPage: 200,
      },
    }
  );

  const { data: { offerProperties = [] } = {} } = useGetOne<CoreProduct>(
    ResourceRoutes.product.resourcePath,
    {
      id: productId,
    }
  );

  const onAdditionalPropertyChanged = (name: string, props: CoreProperty[]) => {
    const propertiesToDelete = additionalProperties
      .map((field, idx) => {
        const isAbsent = !props.some(
          (prop) =>
            prop.id.toString() ===
            (
              field as unknown as CoreProductAdditionalField
            ).additionalPropertyId.toString()
        );

        if (isAbsent) {
          return idx;
        }

        return -1;
      })
      .filter((item) => item >= 0);

    if (propertiesToDelete.length) {
      remove(propertiesToDelete);
    }

    const propertiesToAdd = props.filter(
      (prop) =>
        !additionalProperties.some(
          (field) =>
            (
              field as unknown as CoreProductAdditionalField
            ).additionalPropertyId.toString() === prop.id.toString()
        )
    );

    if (propertiesToAdd.length) {
      for (const prop of propertiesToAdd) {
        append({
          name: prop.name,
          code: prop.code,
          additionalPropertyId: prop.id,
          values: [],
          value: getDefaultValueByType(prop.type),
          isOfferGenerator: prop.isOfferGenerator,
          type: prop.type,
          choices: prop.choices,
          referenceId: (prop as unknown as AdditionalFieldWithId).referenceId,
        });
      }
    }
  };

  return (
    <Flex asColumn fullWidth>
      <RowInfoBlock>
        <PropertySelector
          values={additionalProperties.map(
            (item) =>
              (item as unknown as CoreProductAdditionalField)
                .additionalPropertyId
          )}
          properties={propertiesList}
          label="catalogue.pages.products.labels.additionalProperties"
          name="additionalProperties"
          isMultiChoice
          onSelect={onAdditionalPropertyChanged}
        />
      </RowInfoBlock>

      <Flex asColumn={isMobile} gap={1} fullWidth>
        <RowInfoBlock
          titleKey="catalogue.pages.products.labels.selectedAdditionalProperties"
          fullWidth={!isMobile}
        >
          <PropertiesFields
            additionalProperties={
              additionalProperties as Array<AdditionalFieldWithId>
            }
            source={source}
            propertyList={propertiesList}
          />
        </RowInfoBlock>

        {offerProperties.length > 0 && (
          <RowInfoBlock
            titleKey="catalogue.pages.products.labels.inheritedAdditionalProperties"
            fullWidth={!isMobile}
          >
            <InheritedPropertyList
              propertiesList={propertiesList}
              selectedFields={offerProperties}
            />
          </RowInfoBlock>
        )}
      </Flex>
    </Flex>
  );
};
