import { ListColumn } from '@Widgets/ResourceList/interface';
import { Supplier } from './interface';

export const LIST_COLUMNS: ListColumn<Supplier>[] = [
  {
    source: 'name',
    label: 'replenishment.pages.suppliers.labels.name',
    type: 'text',
  },
];
