import { ResourceItemPage } from '@Widgets/ResourceItemPage/ResourceItemPage';

import { GiftingForm } from '../../components/GiftingForm';
import { GIFT_DEFAULT_VALUES } from '../../constants';

const CreatePage = () => {
  return (
    <ResourceItemPage type="create" defaultFormValues={GIFT_DEFAULT_VALUES}>
      <GiftingForm />
    </ResourceItemPage>
  );
};

export default CreatePage;
