import { CommonCrud, PluginResourceController } from '@PluginBase';
import { Icon } from '@UI';
import { ListPage } from './pages/ListPage';
import {
  CreateResult,
  DeleteResult,
  GetListResult,
  GetOneResult,
  UpdateResult,
  UpdateParams,
} from 'react-admin';
import { ResourceRoutes } from '../../../resourceRoutes';
import { ResourceItemPage } from '@Widgets/ResourceItemPage/ResourceItemPage';
import { Form } from './pages/Form/Form';
import { DEFAULT_FORM_VALUE, storiesTranslationNamespace } from './constants';
import { StoriesFormProps, StoryScheduleDay } from './interface';

const storiesCrud = new CommonCrud('/stories/v1/stories');

storiesCrud.isNewSorting = true;

const reformatSchedules = (
  schedules: CoreStoryScheduleDay[] | StoryScheduleDay[]
): CoreStoryScheduleDay[] => {
  if (!schedules.length) return DEFAULT_FORM_VALUE.schedules;

  const newSchedules: CoreStoryScheduleDay[] = [];

  for (let i = 0; i < 7; i++) {
    let newScheduleDay;

    if ('dayOfWeek' in schedules[0]) {
      const scheduleDay = (schedules as StoryScheduleDay[]).find(
        (schedule) => schedule.dayOfWeek === i + 1
      );

      newScheduleDay = {
        day: (scheduleDay?.dayOfWeek || i + 1).toString(),
        timeFrom: scheduleDay?.intervals[0].start || '00:00:00',
        timeTo: scheduleDay?.intervals[0].end || '00:00:00',
      };
    } else {
      const scheduleDay = (schedules as CoreStoryScheduleDay[]).find(
        (schedule) => schedule.day === (i + 1).toString()
      );

      newScheduleDay = {
        dayOfWeek: parseInt(scheduleDay?.day || '0', 10) || i + 1,
        intervals: [
          {
            id: 0,
            start: scheduleDay?.timeFrom || '00:00:00',
            end: scheduleDay?.timeTo || '00:00:00',
          },
        ],
      } as unknown as CoreStoryScheduleDay;
    }

    newSchedules.push(newScheduleDay as CoreStoryScheduleDay);
  }

  return newSchedules;
};

const reformatData = (data: StoriesFormProps): CoreStoryInput => ({
  ...data,
  items: data.items.map((item) => {
    return {
      ...item,
      id: undefined,
      buttons: item.buttons.map((button) => ({
        ...button,
        id: undefined,
      })),
    };
  }),
  schedules: reformatSchedules(data.schedules),
  deepLink: (data as StoriesFormProps & { id: string }).id
    ? `story_${(data as StoriesFormProps & { id: string }).id}`
    : '',
});

const Stories = new PluginResourceController({
  resourceRoute: {
    name: ResourceRoutes.stories.resourcePath,
    list: ListPage,
    create: (
      <ResourceItemPage includeForm={false} type="create">
        <Form />
      </ResourceItemPage>
    ),
    edit: (
      <ResourceItemPage includeForm={false} type="edit">
        <Form />
      </ResourceItemPage>
    ),
  },
  menuItem: {
    route: ResourceRoutes.stories.routePath,
    caption: {
      translationKey: storiesTranslationNamespace.captions.desktop,
    },
    icon: <Icon type="story" />,
  },
  dataProvider: {
    getList: async (resource, params): Promise<GetListResult> => {
      const response: GetListResult<CoreStory> = await storiesCrud.list(params);

      response.data = response.data.map((story) => ({
        ...story,
        schedules: reformatSchedules(story.schedules),
      }));

      return response;
    },
    getOne: async (resource, params): Promise<GetOneResult> => {
      const story = await storiesCrud.getOne<CoreStory>(params);

      if (story) {
        story.schedules = reformatSchedules(story.schedules);
      }

      return {
        data: story,
      };
    },
    create: async (resource, params): Promise<CreateResult> => {
      let story = await storiesCrud.create<CoreStory>({
        ...params,
        data: reformatData(params.data),
      });

      if (story) {
        story = { ...story, deepLink: `story_${story.id}` };

        await storiesCrud.update({
          data: story,
        } as unknown as UpdateParams);
      }

      return {
        data: story,
      };
    },
    update: async (resource, params): Promise<UpdateResult> => {
      await storiesCrud.update({
        ...params,
        data: reformatData(params.data as StoriesFormProps),
      });

      return {
        data: { ...params.data, deepLink: `story_${params.data.id}` },
      };
    },
    delete: async (resource, params): Promise<DeleteResult> => {
      await storiesCrud.delete(params);

      return {
        data: params.previousData,
      };
    },
  },
});

export default Stories;
