import { useState, useMemo, ChangeEvent } from 'react';
import { TextInput, useRecordContext, useNotify, required } from 'react-admin';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import { useController } from 'react-hook-form';
import { MarkerProps, OnDrawingCompleteProps } from '@Widgets/Gmap/interface';
import { Geocoder } from '@Widgets/Gmap/Geocoder/Geocoder';
import { MAP_CENTER } from '@Widgets/Gmap/constants';
import { Gmap } from '@Widgets/Gmap/Gmap';
import { Box } from '@mui/material';
import { ResourceInputNumber } from '@UI';
import { isNotZero } from '@Helpers';

const validateLocation = [required(), isNotZero()];

export const WarehouseLocation = () => {
  const notify = useNotify();
  const record = useRecordContext<CoreWarehouse>();
  const address = useController<CoreWarehouse>({ name: 'location.address' });
  const googlePlaceId = useController<CoreWarehouse>({
    name: 'location.googlePlaceId',
  });
  const latitude = useController<CoreWarehouse>({ name: 'location.latitude' });
  const longitude = useController<CoreWarehouse>({
    name: 'location.longitude',
  });
  const [newMarkerMap, setNewMarkerMap] = useState<Nullable<MarkerProps>>(null);
  const markers = useMemo(() => {
    const markers = [];

    if (record?.location) {
      markers.push({
        icon: <LocationOnIcon color="primary" />,
        lat: record.location.latitude,
        lng: record.location.longitude,
        key: 'oldMarker',
      });
    }

    if (newMarkerMap) markers.push(newMarkerMap);

    return markers;
  }, [JSON.stringify(record), JSON.stringify(newMarkerMap)]);
  const mapCenter = useMemo(() => {
    let center = MAP_CENTER;

    if (record?.location?.latitude && record?.location?.longitude) {
      center = {
        lat: record.location.latitude,
        lng: record.location.longitude,
      };
    }

    if (latitude.field.value && longitude.field.value) {
      center = {
        lat: parseFloat(latitude.field.value as string),
        lng: parseFloat(longitude.field.value as string),
      };
    }

    return center;
  }, [latitude.field.value, longitude.field.value]);
  const onDrawingCompleteHandler = ({ coords }: OnDrawingCompleteProps) => {
    const [position] = coords;
    const decoder = new Geocoder(position);

    decoder
      .decode()
      .finally(() => {
        if (decoder.error) {
          notify(
            'stores.warehouses.pages.tabs.warehouseLocation.errors.decodeError',
            { type: 'error' }
          );

          return;
        }

        const marker = {
          ...position,
          key: 'newMarker',
          icon: <AddLocationIcon color="error" />,
        };

        address.field.onChange(decoder.address);
        googlePlaceId.field.onChange(decoder.googlePlaceId);
        latitude.field.onChange(decoder.location.lat);
        longitude.field.onChange(decoder.location.lng);

        setNewMarkerMap(marker);
      })
      .catch(() => undefined);
  };
  const onLngChangedHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value || !latitude.field.value) return;

    const lat = parseFloat(latitude.field.value as string);
    const lng = parseFloat(e.target.value);

    if (isNaN(lat) || isNaN(lng)) return;

    onDrawingCompleteHandler({
      coords: [{ lat, lng }],
      type: 'marker',
    });
  };
  const onLatChangedHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value || !longitude.field.value) return;

    const lat = parseFloat(e.target.value);
    const lng = parseFloat(longitude.field.value as string);

    if (isNaN(lat) || isNaN(lng)) return;

    onDrawingCompleteHandler({
      coords: [{ lat, lng }],
      type: 'marker',
    });
  };

  return (
    <>
      <TextInput
        source="location.address"
        name="location.address"
        label="stores.warehouses.pages.tabs.warehouseLocation.address"
        disabled
        fullWidth
        validate={required()}
      />

      <TextInput
        source="location.googlePlaceId"
        name="location.googlePlaceId"
        label="stores.warehouses.pages.tabs.warehouseLocation.googlePlaceId"
        validate={required()}
        disabled
        fullWidth
      />

      <Box my={2} width="100%">
        <Gmap
          showZones
          center={mapCenter}
          markers={markers}
          onDrawingComplete={onDrawingCompleteHandler}
          drawOptions={{
            strokeColor: '',
            types: ['marker'],
          }}
        />
      </Box>

      <ResourceInputNumber
        source="location.latitude"
        name="location.latitude"
        label="stores.warehouses.pages.tabs.warehouseLocation.locationLat"
        onChange={onLatChangedHandler}
        fullWidth
        validate={validateLocation}
      />

      <ResourceInputNumber
        source="location.longitude"
        name="location.longitude"
        label="stores.warehouses.pages.tabs.warehouseLocation.locationLng"
        onChange={onLngChangedHandler}
        fullWidth
        validate={validateLocation}
      />
    </>
  );
};
