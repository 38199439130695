import { useState } from 'react';
import { ResourceNestedViewProps } from '../interface';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
} from '@mui/material';
import { NestedListRow } from './NestedListRow/NestedListRow';
import { useTranslate } from 'react-admin';

export const ResourceNestedListView = (props: ResourceNestedViewProps) => {
  const translate = useTranslate();
  const [expandedRecords, setExpandedRecords] = useState<
    Record<string, boolean>
  >({});
  const { columns, data, onRowExpanded, loadingRows, onRowClick } = props;
  const unShiftedColumns = columns.filter(({ shifted }) => !shifted);
  const shiftedColumns = columns.filter(({ shifted }) => shifted);
  let indexInGroup: Record<string, number> = {};

  function onRowExpandedHandler(recordId: number) {
    const newValue = !expandedRecords[recordId];
    const newExpandedSet = { ...expandedRecords };

    newExpandedSet[recordId] = newValue;

    if (newValue) {
      onRowExpanded?.(recordId);
    } else {
      const record = data.find(({ id }) => id === recordId);

      if (record?.childrenIds?.length) {
        record.childrenIds.forEach((id) => {
          if (newExpandedSet[id]) {
            newExpandedSet[id] = false;
          }
        });
      }
    }

    setExpandedRecords(newExpandedSet);
  }

  return (
    <Box sx={{ overflow: 'auto' }}>
      <Table sx={{ padding: 2 }} size="small">
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              {shiftedColumns.map((column) => (
                <Box key={column.source} py="6px !important">
                  {translate(column.label)}
                </Box>
              ))}
            </TableCell>

            {unShiftedColumns.map(({ source, label }) => (
              <TableCell key={source}>{translate(label)}</TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {data.map((record, index) => {
            const isHidden =
              !!record.parentId && !expandedRecords[record.parentId];

            if (!record.level) {
              indexInGroup = {};
              record.indexInGroup = 0;
            } else {
              if (
                indexInGroup[record.level] === undefined ||
                (!isHidden && record.level > data[index - 1].level)
              ) {
                indexInGroup[record.level] = 0;
              } else {
                indexInGroup[record.level] += 1;
              }

              if (!isHidden) {
                for (let i = record.level - 1; i > 0; i--) {
                  indexInGroup[i] += 1;
                }
              }

              record.indexInGroup = indexInGroup[record.level];
            }

            return (
              <NestedListRow
                isHidden={isHidden}
                isLoading={!!loadingRows && !!loadingRows[record.id]}
                onRowClick={onRowClick}
                onRowExpanded={onRowExpandedHandler}
                isRowExpanded={expandedRecords[record.id]}
                key={record.id}
                record={record}
                unShiftedColumns={unShiftedColumns}
                shiftedColumns={shiftedColumns}
              />
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
};
