import { ClickAndCollectIcon } from './ClickAndCollectIcon';
import { Order } from '../pages/styles';
import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { ResourceRoutes } from '@PluginManager/plugins/resourceRoutes';

const picker = (picker?: CorePickerDto | null) =>
  picker ? `${picker.firstName || ''} ${picker.lastName || ''}` : 'NO PICKER';

export const MonitorOrder = ({ order }: { order: CorePickingOrderDto }) => {
  const { pathname } = useLocation();
  const isSinglePage = pathname === ResourceRoutes.wmsMonitor.customPath;
  const shipBy = new Date(order.slaShipBy || '').getTime();

  return (
    <Order $isSinglePage={isSinglePage}>
      <ClickAndCollectIcon order={order} />
      <span className={Date.now() > shipBy ? 'shipDelaying' : ''}>
        {order.orderId || 'null'}
      </span>
      <Box fontSize="80%" display="inline">
        {order.status !== 'created' ? ` - ${picker(order.picker)}` : ''}
      </Box>
    </Order>
  );
};
