import { useListSortContext } from 'react-admin';
import { useState, useRef } from 'react';
import { SortPayload } from 'ra-core';
import { DesktopGridSorterProps } from './interface';

export function useSort(defaultSort: SortPayload) {
  const context = useListSortContext();
  const [sort, setSort] = useState(context?.sort || defaultSort);
  const sortCountOptions = useRef<DesktopGridSorterProps>({
    field: defaultSort.field,
    count: 1,
  });

  function onSortHandler(sort: SortPayload) {
    const { field, count } = sortCountOptions.current;

    if (sort.field !== field) {
      context.setSort(sort);
      setSort(sort);
      sortCountOptions.current = {
        field: sort.field,
        count: 1,
      };

      return;
    }

    if (sort.field === field && count < 2) {
      sortCountOptions.current.count += 1;
      context.setSort(sort);
      setSort(sort);

      return;
    }

    if (sort.field === defaultSort.field) {
      sortCountOptions.current.count += 1;
      context.setSort(sort);
      setSort(sort);

      return;
    }

    sortCountOptions.current = {
      field: defaultSort.field,
      count: 1,
    };
    context.setSort(defaultSort);
    setSort(defaultSort);
  }

  return {
    sort,
    onSortHandler,
  };
}
