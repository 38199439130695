import { useSearchParams } from 'react-router-dom';
import { SyntheticEvent } from 'react';
import { useTranslate } from 'react-admin';
import { Tabs, Tab } from '@mui/material';

import { Properties } from './tabs/Properties';
import { Filters } from './tabs/Filters';
import { TabContent } from './tabs/TabContent';

export const ListPage = () => {
  const [params, setParams] = useSearchParams();
  const translate = useTranslate();

  const getCurrentTab = () => {
    const tab = params.get('tab');

    return tab || 'all';
  };

  const handleChangeTab = (e: SyntheticEvent, tab: string) => {
    setParams([['tab', tab]]);
  };

  return (
    <>
      <Tabs value={getCurrentTab()} onChange={handleChangeTab}>
        <Tab
          value="all"
          label={translate('settings.pages.properties.tabs.properties')}
        />
        <Tab
          value="filters"
          label={translate('settings.pages.properties.tabs.filters')}
        />
      </Tabs>

      <TabContent currentTab={getCurrentTab()}>
        <div key="all">
          <Properties />
        </div>
        <div key="filters">
          <Filters />
        </div>
      </TabContent>
    </>
  );
};
