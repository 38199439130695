import { memo, useState } from 'react';
import { required, minValue } from 'react-admin';
import { Link } from 'react-router-dom';
import { FieldValue, FieldValues } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import MuiLink from '@mui/material/Link';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Close from '@mui/icons-material/Close';

import { ResourceMoneyInput, ResourceInputNumber } from '@UI';

import { OfferProps } from './interfaces';

const moreThenZero = (value: FieldValue<FieldValues>): string | undefined => {
  if (isNaN(Number(value))) {
    return 'ra.validation.number';
  }

  if (value <= 0) {
    return 'catalogue.pages.bundles.validation.moreThanZero';
  }
};

const Offer = (props: OfferProps): JSX.Element => {
  const {
    placeIndex,
    index,
    offerId,
    name,
    onRemove,
    localization = {},
  } = props;
  const {
    originalOfferPrice: originalOfferPriceTitle = '',
    bundleOfferPrice: bundleOfferPriceTitle = '',
    discount: discountTitle = '',
    offerRemoveTooltip = '',
    offerQuantity = '',
  } = localization;

  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const handleRemoveClick = async (): Promise<void> => {
    if (!onRemove) return;

    setIsDeleting(true);

    await onRemove(offerId);

    setIsDeleting(false);
  };

  const sourceBase = `bundlePlaces[${placeIndex}].bundlePlaceOffers[${index}]`;

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs
        width="100%"
        sx={{
          display: 'flex',
          flexFlow: 'column nowrap',
          justifyContent: 'center',
        }}
      >
        <MuiLink component={Link} to={`/offers/${offerId}`} target="_blank">
          {name}
        </MuiLink>
      </Grid>
      <Grid item xs={2}>
        <ResourceMoneyInput
          source={`${sourceBase}.offer.price`}
          name={`${sourceBase}.offer.price`}
          label={originalOfferPriceTitle}
          disabled
        />
      </Grid>
      <Grid item xs={2}>
        <ResourceMoneyInput
          source={`${sourceBase}.offer.discountPrice`}
          name={`${sourceBase}.offer.discountPrice`}
          label={discountTitle}
          disabled
        />
      </Grid>
      <Grid item xs={2}>
        <ResourceMoneyInput
          source={`${sourceBase}.price`}
          name={`${sourceBase}.price`}
          label={bundleOfferPriceTitle}
          validate={[required(), moreThenZero]}
        />
      </Grid>
      <Grid item xs={2}>
        <ResourceInputNumber
          source={`${sourceBase}.quantity`}
          name={`${sourceBase}.quantity`}
          label={offerQuantity}
          validate={[required(), minValue(1)]}
        />
      </Grid>
      <Grid
        item
        xs={1}
        sx={{
          display: 'flex',
          flexFlow: 'column nowrap',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Tooltip
          title={offerRemoveTooltip.replace('{id}', String(offerId))}
          placement="top"
        >
          <Button
            variant="text"
            color="primary"
            onClick={handleRemoveClick}
            disabled={isDeleting}
          >
            <Close />
          </Button>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export default memo(Offer) as typeof Offer;
