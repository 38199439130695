import { useFormContext } from 'react-hook-form';
import { PropsWithChildren, useEffect } from 'react';
import { ResourceFormErrorBoundaryProps } from './interface';

export const ResourceFormErrorBoundary = (
  props: PropsWithChildren<ResourceFormErrorBoundaryProps>
) => {
  const { errors, source, children } = props;
  const form = useFormContext();

  useEffect(() => {
    if (errors?.[source]) {
      form.setError(source, {
        message: errors[source],
      });
    }
  }, [errors]);

  return <>{children}</>;
};
