import { RaRecord, useRecordContext, useTranslate } from 'react-admin';
import { Tooltip } from '@mui/material';

import { COLUMN_CONTENT_MAX_LENGTH } from '../../constants';

import { TruncatedContentColumnProps, TruncateValueResult } from './interfaces';

const TruncatedContentColumn = <T extends RaRecord>(
  props: TruncatedContentColumnProps<T>
) => {
  const {
    source,
    maxLength = COLUMN_CONTENT_MAX_LENGTH,
    displayTooltip = true,
    renderContent,
    renderEmptyContent,
  } = props;
  const record = useRecordContext<T>();
  const translate = useTranslate();

  const getSourceValue = () => {
    if (typeof source === 'function') {
      return source(record);
    }

    return record[source as keyof T];
  };

  const fieldValue = getSourceValue();

  const truncateFieldValue = (): TruncateValueResult => {
    const normalized = translate(`${fieldValue}`);

    if (!['string', 'number'].includes(typeof fieldValue)) {
      // eslint-disable-next-line no-console
      console.warn(
        'Trying to render non string or number value. Use `source={(record: T) => string}` instead',
        fieldValue
      );

      return {
        value: '',
        isTooltipNeeded: false,
      };
    }

    if (normalized.length > maxLength) {
      return {
        value: `${normalized.slice(0, maxLength - 3)}...`,
        isTooltipNeeded: true,
      };
    }

    return {
      value: normalized,
      isTooltipNeeded: false,
    };
  };

  const { value, isTooltipNeeded } = truncateFieldValue();

  if (!value) {
    return renderEmptyContent ? renderEmptyContent(record) : null;
  }

  const rendered = renderContent ? (
    renderContent(value, fieldValue)
  ) : (
    <span>{value}</span>
  );

  return (
    <>
      {isTooltipNeeded && displayTooltip ? (
        <Tooltip title={<>{fieldValue}</>} placement="top">
          {rendered}
        </Tooltip>
      ) : (
        rendered
      )}
    </>
  );
};

export default TruncatedContentColumn;
