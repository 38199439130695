import { useState } from 'react';
import { useNotify, useTranslate } from 'react-admin';
import { AuthorizationError } from '@PluginManager/base/AbstractApi/AuthorizationError';
import authProvider from '../../providers/authProvider/authProvider';
import { ROUTES } from '@CoreRoutes';
import { ValidationError } from '@PluginManager/base/AbstractApi/ValidationError';
import { BackendCodeError } from '@PluginManager/base/AbstractApi/BackendCodeError';
import { AxiosError } from 'axios';
import { ConfigurableError } from '@PluginManager/base/AbstractApi/ConfigurableError';
import { UploadedContentError } from '@PluginManager/base/AbstractApi/UploadedContentError';
import { OfferPropertiesValidationError } from '@PluginManager/base/AbstractApi/OfferPropertiesValidationError';

export function useSetErrorToErrorsContext() {
  const [errors, setErrors] = useState<Record<string, string[]>>({});
  const notify = useNotify();
  const translate = useTranslate();

  function decodeAuthError() {
    if (!errors.auth) {
      errors.auth = [];
    }

    setErrors({
      auth: [...errors.auth, translate('ra.auth.sign_in_error')],
    });

    authProvider
      .logout({})
      .then(() => {
        window.history.go(-1);
        window.history.pushState(null, '', `/#${ROUTES.login.route}`);
        window.history.go(1);
      })
      .catch(console.error);
  }

  function decodeValidationError(error: ValidationError) {
    const errorSpecFields = Object.entries(error.specFields);

    if (errorSpecFields.length) {
      const errorsSet: Record<string, string[]> = errorSpecFields.reduce(
        (errorsSetMemo: Record<string, string[]>, [specName, specVal]) => {
          errorsSetMemo[specName] = specVal.map(({ code, data }) =>
            typeof data === 'string'
              ? `${specName}: ${data}`
              : data?.message ||
                translate(`backendValidationErrors.${code}`, {
                  specField: specName,
                  ...data,
                })
          );

          return errorsSetMemo;
        },
        {}
      );

      setErrors(errorsSet);
    } else if (Object.keys(error.fields).length) {
      if (error.fields._errorList) {
        error.fields._errorList = error.fields._errorList.map((error: string) =>
          translate(`backendValidationErrors.${error}`)
        );
      }

      setErrors(error.fields);
    }
  }

  function decodeBackendCodeError(error: BackendCodeError) {
    if (error.field && error.translationKey) {
      setErrors({
        [error.field]: [translate(error.translationKey)],
        [Date.now().toString()]: [''],
      });
    } else {
      setErrors({
        error: [translate(error.originalMessage)],
        [Date.now().toString()]: [''],
      });
    }
  }

  function decodePropertiesValidationError(
    error: OfferPropertiesValidationError
  ) {
    setErrors(error.namedErrors);
  }

  function onErrorHandler(error: unknown) {
    if (error instanceof AuthorizationError) {
      decodeAuthError();

      return;
    }

    if (error instanceof ValidationError) {
      decodeValidationError(error);

      return;
    }

    if (error instanceof BackendCodeError) {
      decodeBackendCodeError(error);

      return;
    }

    if (error instanceof OfferPropertiesValidationError) {
      decodePropertiesValidationError(error);

      return;
    }

    if (error instanceof ConfigurableError) {
      const el = error.contentEl({});

      notify(el, { type: 'error' });

      return;
    }

    if (error instanceof AxiosError) {
      if (error.response?.data?.error) {
        setErrors({ error: [error.response.data.error] });

        return;
      }

      if (error.response?.data?.errors?.length) {
        setErrors({ errors: error.response.data.errors });

        return;
      }
    }

    if (error instanceof UploadedContentError) {
      notify(translate(`uploadedContentErrors.${error.message}`), {
        type: 'error',
      });

      return;
    }

    if (error instanceof Error) {
      notify(error.message, { type: 'error' });
    }
  }

  return { errors, onErrorHandler };
}
