import jokeSound from '@ROOT/assets/sounds/joke_sound.mp3';
import mainSound from '@ROOT/assets/sounds/sound.mp3';

export const getSound = () => {
  const today = new Date();

  // 1 April sound
  if (today.getMonth() === 3 && today.getDate() === 1) {
    return jokeSound;
  }

  return mainSound;
};
