import { AxiosError } from 'axios';
import { GetOneResult, UpdateResult } from 'react-admin';
import { PluginResourceController, CommonCrud } from '@PluginBase';
import { Icon } from '@UI';
import { ResourceRoutes } from '../../../resourceRoutes';
import { InputPage } from './pages/InputPage';
import { clickAndCollectTranslationSchema } from './constants';
import { RequestAPI } from '@RestApi';
import { ClickCollectOrder } from './interfaces';
import { BackendCodeError } from '@PluginManager/base/AbstractApi/BackendCodeError';

const UUID_REGEX =
  /[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}(?:\/.*)?/i;

const isUUID = (val: string): boolean => {
  const group = val.match(UUID_REGEX);

  return !!group;
};

const ordersCrud = new CommonCrud('/orders/v1/orders');

const WMSClickAndCollectPages = new PluginResourceController({
  menuItem: {
    route: ResourceRoutes.wmsClickAndCollect.routePath,
    caption: {
      translationKey: clickAndCollectTranslationSchema.captions.desktop,
    },
    icon: <Icon type="shop" />,
  },
  resourceRoute: {
    name: ResourceRoutes.wmsClickAndCollect.resourcePath,
    list: InputPage,
  },
  dataProvider: {
    getOne: async (resource, params): Promise<GetOneResult> => {
      const result = await ordersCrud.getOne<{ order: ClickCollectOrder }>(
        params
      );

      if (!result) {
        return { data: null };
      }

      if (!isUUID(params.id)) {
        const uuid = result.order.id;

        result.order.id = result.order.order_id;
        result.order.uuid = uuid;
      }

      return {
        data: result?.order,
      };
    },
    update: async (resource, params): Promise<UpdateResult> => {
      try {
        await RequestAPI.post(`/delivery/update-status/${params.id}`, {
          delivered: true,
          delivered_types: params.data,
        });
      } catch (error) {
        if (error instanceof AxiosError && error.response?.status === 400) {
          throw new BackendCodeError({
            payload: {
              message: 'wms-picking.pages.click-collect.errors.failedToUpdate',
              status: 400,
            },
          });
        }
      }

      return {
        data: params,
      };
    },
  },
});

export default WMSClickAndCollectPages;
