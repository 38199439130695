import {
  DateField,
  useLocaleState,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import { Flex } from '@UI';
import { Typography } from '@mui/material';
import React from 'react';
import { CAGES_LIST_COLUMNS, ShipmentTableItem } from './constants';
import { StaticDataGrid } from '@Widgets/StaticDataGrid/StaticDataGrid';
import { generateShipmentTableData } from '@Plugins/DistributionCenter/resources/Shipments/dataGenerators';
import { formatEntityName } from '@ROOT/helpers/FormatEntityName/FormatEntityName';
import { StatusBadgeResource } from '../share/StatusBadgeResource/StatusBadgeResource';

export const ShipmentPage = () => {
  const translate = useTranslate();
  const [locale] = useLocaleState();
  const record = useRecordContext<CoreShipment>();
  const shipmentTableData = generateShipmentTableData(record);

  const infoBlock = (
    <Flex p={2} gap={2} justifyContent="space-between" flexWrap="wrap">
      <Flex asColumn>
        <Typography variant="h5">
          {formatEntityName('OUT', 8, record.displayId)}
        </Typography>
        <Typography variant="subtitle2">
          {`${translate(
            'distributionCenter.pages.shipments.labels.createdAt'
          )}: `}

          <DateField source="createdAt" emptyText="N/A" showTime />
        </Typography>
        <Typography variant="subtitle2">
          {`${translate(
            'distributionCenter.pages.shipments.labels.shippedAt'
          )}: `}

          <DateField source="shippedAt" emptyText="N/A" showTime />
        </Typography>
        <Typography variant="subtitle2">
          {`${translate(
            'distributionCenter.pages.shipments.labels.wmsProcessedAt'
          )}: `}

          <DateField
            source="wmsProcessedAt"
            locales={locale}
            emptyText="N/A"
            showTime
          />
        </Typography>
      </Flex>
      <Flex asColumn>
        <StatusBadgeResource />
      </Flex>
    </Flex>
  );

  return (
    <>
      {infoBlock}
      <StaticDataGrid<ShipmentTableItem>
        cols={CAGES_LIST_COLUMNS}
        data={shipmentTableData}
        bulkActionButtons={false}
      />
    </>
  );
};
