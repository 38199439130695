import { get } from '@Helpers';

export interface TranslationSchema {
  errors?: Record<string, string>;
  messages?: Record<string, string>;
  actions?: Record<string, string>;
  labels?: Record<string, string>;
  captions: {
    mobile: string;
    desktop: string;
  };
  lists?: Record<string, Record<string, string>>;
}

export class TranslationNamespace<Type extends TranslationSchema> {
  readonly translationNamespace: string;
  private schema: Type;

  constructor(translationNamespace: string, schema: Type) {
    this.translationNamespace = translationNamespace;
    this.schema = schema;
    this.mergeNamespaceWithSchemaRecords();
  }

  get labels(): Type['labels'] {
    return this.schema.labels ?? {};
  }

  get errors(): Type['errors'] {
    return this.schema.errors ?? {};
  }

  get lists(): Type['lists'] {
    return this.schema.lists ?? {};
  }

  get actions(): Type['actions'] {
    return this.schema.actions ?? {};
  }

  get messages(): Type['messages'] {
    return this.schema.messages ?? {};
  }

  get captions(): Type['captions'] {
    return this.schema.captions;
  }

  getTranslationKey(source: string): string {
    return get(this.schema, source);
  }

  private mergeNamespaceWithSchemaRecords() {
    const convertedSchema: Record<string, Record<string, string>> = {};

    Object.keys(this.schema).forEach((schemaBlockKey) => {
      const translationBlock = this.schema[schemaBlockKey as keyof TranslationSchema];

      if (schemaBlockKey === 'lists') {
        // @ts-ignore
        convertedSchema.lists = this.mergeLists(schemaBlockKey, translationBlock as Type['lists']);

        return;
      }

      convertedSchema[schemaBlockKey] = this.addNamespaceToTranslationBlocks(
        schemaBlockKey,
        translationBlock as Record<string, string>,
      );
    });

    this.schema = convertedSchema as unknown as Type;
  }

  private mergeLists(
    key: string,
    blockValues?: Record<string, Record<string, string>>,
  ): Record<string, Record<string, string>> {
    if (!blockValues) {
      return {};
    }

    Object.keys(blockValues).forEach((translationKey) => {
      blockValues[translationKey] = this.addNamespaceToTranslationBlocks(
        `${key}.${translationKey}`,
        blockValues[translationKey],
      );
    });

    return blockValues;
  }

  private addNamespaceToTranslationBlocks(key: string, blockValues?: Record<string, string>): Record<string, string> {
    if (!blockValues) {
      return {};
    }

    Object.keys(blockValues).forEach((translationKey) => {
      blockValues[translationKey] = this.addNamespace(`${key}.${blockValues[translationKey]}`);
    });

    return blockValues;
  }

  private addNamespace(translationKey: string): string {
    return `${this.translationNamespace}.${translationKey}`;
  }
}
