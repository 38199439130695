import {
  GetManyReferenceParams,
  GetManyReferenceResult,
  UpdateParams,
  UpdateResult,
} from 'react-admin';
import { CommonCrud } from '@PluginManager/base/AbstractApi/CommonCrud';
import { RequestAPI } from '@RestApi';

export class PickingOrdersCRUD extends CommonCrud {
  constructor(url: string) {
    super(url, {
      arrayFormatter: (key: string, values: string[]) => {
        return values.map((value) => `${key}=${value}`).join('&');
      },
      isNewSorting: true,
    });
  }

  async finish(params: UpdateParams): Promise<UpdateResult> {
    const { id } = params;

    await RequestAPI.put(`${this.apiUrl}/${id}/finish?changedBy=admin`);

    return {
      data: params.previousData,
    };
  }

  async getItems(
    params: GetManyReferenceParams
  ): Promise<GetManyReferenceResult> {
    this.currentFiltersQuery = '';
    const { sort, pagination, id } = params;

    this._addSort(sort);
    this._addPagination(pagination);

    const response = await RequestAPI.get(
      `${this.apiUrl}/${id}/items${this.currentFiltersQuery}`
    );

    return {
      data: response.data,
      total: response.pagination.total,
    };
  }

  async getPickers(
    params: GetManyReferenceParams
  ): Promise<GetManyReferenceResult<PickerDto>> {
    const {
      pagination: { perPage },
    } = params;

    this._addSingleParamToQuery('page[size]', perPage);

    const response = await RequestAPI.get(
      `${this.apiUrl}${this.currentFiltersQuery}`
    );

    return {
      data: response.data,
      total: response.pagination.total,
    };
  }
}
