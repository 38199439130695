import { useLocaleState, useRecordContext } from 'react-admin';

const OfferName = () => {
  const { name, nameAr } = useRecordContext<CoreOffer>();
  const [locale] = useLocaleState();

  return <>{locale === 'ar' && nameAr ? nameAr : name}</>;
};

export default OfferName;
