import { Filter, ListColumn } from '@Widgets/ResourceList/interface';
import { StoriesFormProps } from './interface';
import { TranslationNamespace } from '@Helpers';
import { RESOURCES_SCHEMA } from './schemas/resourcesSchema';
import { TRANSLATION_SCHEMA } from './schemas/translationSchema';

export const storiesTranslationNamespace = new TranslationNamespace<
  typeof TRANSLATION_SCHEMA
>('promotions.pages.stories', TRANSLATION_SCHEMA);

export const DEFAULT_FORM_VALUE: StoriesFormProps = {
  active: false,
  blacklistedWarehouses: [],
  name: '',
  createdAt: '',
  dateEnd: '',
  dateStart: '',
  deepLink: '',
  duration: 0,
  endStory: '',
  isStart: false,
  items: [],
  mainImage: '',
  order: 0,
  schedules: [
    {
      dayOfWeek: 1,
      intervals: [
        {
          id: 0,
          start: '00:00:00',
          end: '00:00:00',
        },
      ],
    },
    {
      dayOfWeek: 2,
      intervals: [
        {
          id: 0,
          start: '00:00:00',
          end: '00:00:00',
        },
      ],
    },
    {
      dayOfWeek: 3,
      intervals: [
        {
          id: 0,
          start: '00:00:00',
          end: '00:00:00',
        },
      ],
    },
    {
      dayOfWeek: 4,
      intervals: [
        {
          id: 0,
          start: '00:00:00',
          end: '00:00:00',
        },
      ],
    },
    {
      dayOfWeek: 5,
      intervals: [
        {
          id: 0,
          start: '00:00:00',
          end: '00:00:00',
        },
      ],
    },
    {
      dayOfWeek: 6,
      intervals: [
        {
          id: 0,
          start: '00:00:00',
          end: '00:00:00',
        },
      ],
    },
    {
      dayOfWeek: 7,
      intervals: [
        {
          id: 0,
          start: '00:00:00',
          end: '00:00:00',
        },
      ],
    },
  ] as unknown as CoreStoryScheduleDay[],
  updatedAt: '',
};

export const FILTERS: Filter[] = [
  { source: 'search', type: 'search' },
  {
    source: RESOURCES_SCHEMA.active,
    type: 'choose',
    choices: [
      { id: 'active', name: storiesTranslationNamespace.labels.active },
      { id: 'inactive', name: storiesTranslationNamespace.labels.inactive },
    ],
  },
];

// TODO: remove "any" from generic
export const LIST_COLUMNS: ListColumn<typeof RESOURCES_SCHEMA & any>[] = [
  {
    source: RESOURCES_SCHEMA.clientName,
    type: 'text',
    label: storiesTranslationNamespace.labels.name,
  },
  {
    source: RESOURCES_SCHEMA.mainImage,
    type: 'image',
    label: storiesTranslationNamespace.labels.image,
  },
  {
    source: RESOURCES_SCHEMA.active,
    type: 'boolean',
    label: storiesTranslationNamespace.labels.status,
  },
  {
    source: RESOURCES_SCHEMA.dateStart,
    type: 'date',
    label: storiesTranslationNamespace.labels.startDate,
    showTime: true,
  },
  {
    source: RESOURCES_SCHEMA.dateEnd,
    type: 'date',
    label: storiesTranslationNamespace.labels.endDate,
    showTime: true,
  },
];
