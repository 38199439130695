import { ResourceItemPage } from '@Widgets/ResourceItemPage/ResourceItemPage';

import { GiftingForm } from '../../components/GiftingForm';

const UpdatePage = () => {
  return (
    <ResourceItemPage type="edit">
      <GiftingForm />
    </ResourceItemPage>
  );
};

export default UpdatePage;
