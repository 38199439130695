import styled from 'styled-components';

export const AffiliatesContainer = styled.div`
  padding-inline: 16px;
  padding-top: 8px;
  padding-bottom: 32px;

  & .MuiToolbar-root {
    display: none;
  }
`;
