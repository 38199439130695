import { PaymentMethodChoice, TabItem, TabName } from './interface';

export enum DeliveryAreaTabName {
  Area,
  DeliverySlots,
}

export const DELIVERY_AREA_DEFAULT_FIELDS: Omit<CoreDeliveryAreaEntity, 'id'> =
  {
    warehouse_id: '',
    active: false,
    color: '',
    delivery_price: 0,
    delivery_price_fixed: true,
    delivery_price_max: 0,
    delivery_price_multipliers: [],
    delivery_price_threshold: 0,
    delivery_price_threshold_fixed: true,
    delivery_price_threshold_multipliers: [],
    eta_fixed: false,
    eta_fixed_max: '' as unknown as number,
    eta_fixed_min: '' as unknown as number,
    name: '',
    points: [],
    priority: 1,
    schedule: [],
    service_fee: 0,
    service_fee_threshold: 0,
    shipping_method_id: '',
    use_custom_schedule: false,
    payment_methods: [],
  };

export const PAYMENT_METHODS_CHOICES: PaymentMethodChoice[] = [
  {
    id: 'CARD' as CorePaymentMethod,
    name: 'CARD' as CorePaymentMethod,
  },
  {
    id: 'CASH' as CorePaymentMethod,
    name: 'CASH' as CorePaymentMethod,
  },
];

export const DELIVERY_AREA_TABS: TabItem[] = [
  {
    label: 'stores.warehouses.pages.tabs.deliveryArea.drawer.caption.general',
    value: TabName.General,
  },
  {
    label:
      'stores.warehouses.pages.tabs.deliveryArea.drawer.caption.deliveryArea',
    value: TabName.DeliveryArea,
  },
  {
    label:
      'stores.warehouses.pages.tabs.deliveryArea.drawer.caption.deliverySchedule',
    value: TabName.DeliverySchedule,
  },
];
