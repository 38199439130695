import { ListColumn } from '@Widgets/ResourceList/interface';
import { pickingOrdersTranslationSchema } from '../../constants';
import { RESOURCES_SCHEMA } from '../../schemas/resourcesSchema';
import { PickingItemBarcodes } from '../components/PickingItemBarcodes/PickingItemBarcodes';

export const LIST_COLUMNS: ListColumn[] = [
  {
    source: RESOURCES_SCHEMA.pickingItem.image,
    label: pickingOrdersTranslationSchema.labels.image,
    type: 'image',
    sortable: false,
  },
  {
    source: RESOURCES_SCHEMA.pickingItem.name,
    label: pickingOrdersTranslationSchema.labels.productName,
    type: 'text',
  },
  {
    source: RESOURCES_SCHEMA.pickingItem.sku,
    label: pickingOrdersTranslationSchema.labels.productSku,
    type: 'text',
  },
  {
    source: RESOURCES_SCHEMA.pickingItem.quantityToPick,
    label: pickingOrdersTranslationSchema.labels.quantityToPick,
    type: 'number',
  },
  {
    source: RESOURCES_SCHEMA.pickingItem.quantityPicked,
    label: pickingOrdersTranslationSchema.labels.quantityPicked,
    type: 'number',
  },
  {
    source: RESOURCES_SCHEMA.pickingItem.barcodes,
    label: pickingOrdersTranslationSchema.labels.productBarcodes,
    type: 'custom',
    customComponent: (
      <PickingItemBarcodes
        key={pickingOrdersTranslationSchema.labels.productBarcodes}
      />
    ),
    sortable: false,
  },
];

export enum PickingOrderTab {
  General,
  Items,
}

export enum ApiActionCall {
  FinishOrder = 'finishOrder',
  ChangePicker = 'changePicker',
}
