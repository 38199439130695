import { PluginResourceController } from '@PluginBase';
import { ResourceRoutes } from '../../../resourceRoutes';
import {
  GetListResult,
  GetManyReferenceResult,
  GetOneResult,
} from 'react-admin';
import { ListPage } from './ListPage';
import { Icon } from '@UI';
import { apiUrl, ResourceMeta } from './constants';
import { ResourceItemPage } from '@Widgets/ResourceItemPage/ResourceItemPage';
import { PickingOrderPage } from './PickingOrderPage';
import { PickingOrdersCrud } from './restApi/PickingOrdersCrud';

const crud = new PickingOrdersCrud(apiUrl);

const PickingOrdersController = new PluginResourceController({
  menuItem: {
    caption: {
      translationKey: 'distributionCenter.pages.pickingOrders.caption',
    },
    route: ResourceRoutes.pickingOrders.routePath,
    icon: <Icon type="pickingOrders" />,
  },
  resourceRoute: {
    name: ResourceRoutes.pickingOrders.resourcePath,
    list: ListPage,
    edit: (
      <ResourceItemPage type="edit" includeForm={false}>
        <PickingOrderPage />
      </ResourceItemPage>
    ),
  },

  dataProvider: {
    getList: async (resource, params): Promise<GetListResult> => {
      return crud.list(params);
    },
    getOne: async (resource, params): Promise<GetOneResult> => {
      if (params.id === undefined || params.id === null)
        return { data: { id: null } };

      const result = await crud.getOne<{ data: CorePickingOrder }>(params);

      return {
        data: result?.data,
      };
    },
    updateMany: async (resource, params) => {
      await crud.cancelMany(params, `${apiUrl}/canceled`);

      return {
        data: params.ids,
      };
    },
    getManyReference: async (
      resource,
      params
    ): Promise<GetManyReferenceResult> => {
      const { target, id } = params;

      if (target === ResourceMeta.PICKING_ORDERS) return crud.list(params);

      if (target === ResourceMeta.PRODUCTS) {
        const getListResult = await crud.list({
          ...params,
          meta: {
            subPath: `/${id}/products`,
          },
        });

        getListResult.total = getListResult.total || getListResult.data.length;

        return getListResult;
      }

      return {
        data: [],
        total: 0,
      };
    },
  },
});

export default PickingOrdersController;
