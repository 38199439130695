import {
  DeleteWithConfirmButton,
  SaveButton,
  Toolbar,
  useSaveContext,
  setSubmissionErrors,
  useRefresh,
} from 'react-admin';
import { SyntheticEvent } from 'react';
import { Flex } from '@UI';
import { accessPermission } from '@Helpers';
import { FieldValues, useFormContext } from 'react-hook-form';

const OfferFormToolbar = () => {
  const saveContext = useSaveContext();
  const form = useFormContext();
  const refresh = useRefresh();

  const handleSaveClick = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    form.handleSubmit(async (values: FieldValues) => {
      let errors;

      if (saveContext?.save) {
        errors = await saveContext.save(values);
      }

      if (errors) {
        setSubmissionErrors(errors, form.setError);
      } else {
        refresh();
      }
    })(e);
  };

  return (
    <Toolbar sx={{ justifyContent: 'space-between' }}>
      {accessPermission.checkPermission('Catalogue', 'U') && (
        <Flex gap={2}>
          <SaveButton onClick={handleSaveClick} />
        </Flex>
      )}

      {accessPermission.checkPermission('Catalogue', 'D') && (
        <DeleteWithConfirmButton />
      )}
    </Toolbar>
  );
};

export default OfferFormToolbar;
