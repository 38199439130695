import { PluginResourceController } from '@PluginBase';
import { ListPage } from './ListPage';
import { ResourceRoutes } from '../../../resourceRoutes';
import {
  GetListResult,
  GetListParams,
  UpdateParams,
  UpdateResult,
  GetOneResult,
  DeleteParams,
  DeleteResult,
} from 'react-admin';
import { ReplenishmentApiResource } from '../../apiRoutes';
import { ReplenishmentCrud } from '../../crud';
import { SimplyReplenish } from '@Plugins/Replenishment/resources/Simply_Replenish/interface';
import { ResourceItemPage } from '@Widgets/ResourceItemPage/ResourceItemPage';
import { PageForm } from '@Plugins/Replenishment/resources/Simply_Replenish/PageForm';

const SimplyReplenishCrud = new ReplenishmentCrud(
  ReplenishmentApiResource.simply_replenish
);

const SimplyReplenishController = new PluginResourceController({
  resourceRoute: {
    name: ResourceRoutes.replenishment.simply_replenish.resourcePath,
    list: ListPage,
    edit: (
      <ResourceItemPage redirect="list" type="edit">
        <PageForm />
      </ResourceItemPage>
    ),
  },
  menuItem: {
    route: ResourceRoutes.replenishment.simply_replenish.routePath,
    caption: {
      translationKey: 'replenishment.pages.simply_replenish.caption',
    },
  },
  dataProvider: {
    getList: (
      resource: string,
      params: GetListParams
    ): Promise<GetListResult> => {
      return SimplyReplenishCrud.list(params);
    },
    getOne: async (resource, params): Promise<GetOneResult> => {
      const result = await SimplyReplenishCrud.getOne<{
        data: SimplyReplenish;
      }>(params);

      return {
        data: result?.data,
      };
    },
    delete: async (resource, params: DeleteParams): Promise<DeleteResult> => {
      await SimplyReplenishCrud.delete(params);

      return { data: params.previousData };
    },
    update: async (
      resource: string,
      params: UpdateParams
    ): Promise<UpdateResult> => {
      if (params.meta === 'load') {
        await SimplyReplenishCrud.uploadFile_(
          ReplenishmentApiResource.simply_replenishLoad,
          params.data.file
        );

        return { data: { id: 1 } };
      } else {
        params.data.min_quantity = parseInt(params.data.min_quantity);
        params.data.max_quantity = parseInt(params.data.max_quantity);
        await SimplyReplenishCrud.update(params);

        return {
          data: params.data,
        };
      }
    },
  },
});

export default SimplyReplenishController;
