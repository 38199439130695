import { useController } from 'react-hook-form';
import 'react-quill/dist/quill.snow.css';
import { MODULES, FORMATS } from './constants';
import { ReactQuillStyled } from './styles';
import { useTheme } from '@mui/material/styles';
import { useTranslate } from 'react-admin';
import { ReachTextEditorProps } from './interface';
import { UIErrorTypography } from '@ROOT/layout/UI';

export const ReachTextEditor = ({
  name,
  isRequired = false,
  ref,
  onKeyDown,
  validationRules = {},
}: ReachTextEditorProps) => {
  const theme = useTheme();
  const translate = useTranslate();
  const rules = {
    required: isRequired ? translate('ra.validation.required') : undefined,
    ...validationRules,
  };
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({ name, rules });

  const handleChange = (val: string) => {
    onChange(val);
  };

  return (
    <div style={{ width: '100%' }}>
      <ReactQuillStyled
        onKeyDown={onKeyDown}
        ref={ref}
        preserveWhitespace
        $toolbarTextColor={theme.palette.text.primary}
        $dir={theme.direction}
        value={value ?? ''}
        onChange={handleChange}
        modules={MODULES}
        formats={FORMATS}
      />

      {!!error?.message?.length && (
        <UIErrorTypography>{error?.message}</UIErrorTypography>
      )}
    </div>
  );
};
