import { OptionTextStyled, AutocompleteArrayInputStyled } from './styles';
import { useRef } from 'react';
import { ResourceAutocompleteArrayInputProps } from './interface';
import { useTruncate } from './useTruncate';
import { Box } from '@mui/material';

export const ResourceAutocompleteArrayInput = (props: ResourceAutocompleteArrayInputProps) => {
  const AutocompleteInputWrapperRef = useRef<HTMLDivElement>(null);
  const width = useTruncate(AutocompleteInputWrapperRef?.current?.getBoundingClientRect().width);

  function matchSuggestionHandler(filter: string, choice: SelectOption) {
    return choice.name.toLowerCase().includes(filter.toLowerCase());
  }

  function optionTextHandler(choice: SelectOption) {
    return (
      <OptionTextStyled truncate={width ?? 350} key={choice.id}>
        {choice.name}
      </OptionTextStyled>
    );
  }

  function inputTextHandler(choice: SelectOption) {
    return choice.name;
  }

  return (
    <Box ref={AutocompleteInputWrapperRef} width='100%'>
      {/** @ts-ignore */}

      <AutocompleteArrayInputStyled
        {...props}
        truncate={width ?? 350}
        optionText={optionTextHandler}
        inputText={inputTextHandler}
        matchSuggestion={matchSuggestionHandler}
      />
    </Box>
  );
};
