import { useRecordContext, useTranslate } from 'react-admin';
import { Typography } from '@mui/material';
import { pickersTranslationSchema } from '../../constants';

export const EmploymentField = () => {
  const record = useRecordContext<CorePickerDto>();
  const translate = useTranslate();

  if (!record?.employment) return null;

  return (
    <Typography fontSize={14} noWrap>
      {translate(pickersTranslationSchema.lists.employment[record.employment])}
    </Typography>
  );
};

EmploymentField.defaultProps = {
  label: pickersTranslationSchema.labels.employment,
};
