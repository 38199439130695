import { RowInfoBlock, Flex, ReachTextEditor } from '@UI';
import { TextInput } from 'react-admin';
import { maxLengthExt } from '@Helpers';

export const AdditionalLanguage = () => {
  return (
    <Flex asColumn fullWidth>
      <RowInfoBlock
        flex={1}
        titleKey="catalogue.pages.categories.labels.nameAr"
        fullWidth
      >
        <TextInput
          source="nameAr"
          name="nameAr"
          label="catalogue.pages.categories.labels.nameAr"
          fullWidth
          validate={maxLengthExt(246)}
        />
      </RowInfoBlock>

      <RowInfoBlock
        flex={1}
        titleKey="catalogue.pages.categories.labels.descriptionAr"
        fullWidth
      >
        <ReachTextEditor name="descriptionAr" isRequired={false} />
      </RowInfoBlock>
    </Flex>
  );
};
