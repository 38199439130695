import { ArrayField, Datagrid, SimpleList } from 'react-admin';
import { useIsMobile } from '@Hooks/useIsMobile';
import { Identifier } from 'ra-core';
import { ComponentFactory } from '@Widgets/ResourceList/ComponentFactory/ComponentFactory';
import { ResourceRoutes } from '@PluginManager/plugins/resourceRoutes';
import { OFFER_LIST_IN_PRODUCT_COLUMNS } from './constants';

export const ProductOffers = () => {
  const isMobile = useIsMobile();
  const onRowClickHandler = (id: Identifier) =>
    `${ResourceRoutes.offer.routePath}/${id}`;

  return (
    <ArrayField source="offers">
      {isMobile ? (
        <SimpleList
          sx={{ padding: 0 }}
          primaryText={(record) => `ID ${record.id} - ` + record.name}
          secondaryText={(record) => record.code}
          linkType={(record, id) => onRowClickHandler(id)}
        />
      ) : (
        <Datagrid
          sx={{ width: '100%' }}
          rowClick={onRowClickHandler}
          bulkActionButtons={false}
        >
          {OFFER_LIST_IN_PRODUCT_COLUMNS?.map((column) => {
            const sortable = column.sortable ?? true;

            return (
              <ComponentFactory
                sortBy={sortable ? column.source : undefined}
                key={column.source}
                column={column}
                label={column.label}
              />
            );
          })}
        </Datagrid>
      )}
    </ArrayField>
  );
};
