export const getPickerSelectOption = (
  id: string,
  firstName: string | null,
  lastName: string | null
): SelectOption => ({
  id,
  name: (firstName ? `${firstName} ` : '') + (lastName ?? ''),
});

export const getPickerSelectOptions = (pickers: PickerDto[]): SelectOption[] =>
  pickers.map(({ firstName, id, lastName }) =>
    getPickerSelectOption(id, firstName, lastName)
  );

export const getUniqueOptions = (options: SelectOption[]): SelectOption[] =>
  Array.from(new Map(options.map((item) => [item.id, item])).values());
