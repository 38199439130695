import styled from 'styled-components';
import { Flex } from '@UI';

const orderStatusColors: Record<CoreOrderStatus, string> = {
  cancelled: '#555',
  confirmed: '#876c99',
  created: '#363d47',
  delivered: '#008000',
  delivered_partially: '#ff3434',
  delivery_created: '#6cb9e9',
  delivery_failed: '#ff3434',
  finished: '#013220',
  in_delivery: '#3ea13e',
  picking: '#007cad',
  ready_to_ship: '#787112',
  reserved: '#535353',
  wh_created: '#42aaff',
};

export const StatusBadgeStyled = styled(Flex)<{ orderStatus: CoreOrderStatus }>`
  padding: 6px 6px 4px;
  background-color: ${({ orderStatus }) => orderStatusColors[orderStatus]};
  border-radius: 6px;
  line-height: 1;
`;
