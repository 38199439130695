import { TextField, Typography } from '@mui/material';
import { useTranslate } from 'react-admin';
import { ColorInput, RowInfoBlock } from '@UI';
import { ChoiceValueFieldProps } from './interface';
import { ChangeEvent } from 'react';

export const ChoiceValueField = (props: ChoiceValueFieldProps) => {
  const {
    label,
    id = '',
    value = '',
    onChange,
    fieldType,
    error = '',
    disabled,
    helperText,
  } = props;
  const translate = useTranslate();
  const caption = translate(label);

  function onChangePropertyField(e: ChangeEvent<HTMLInputElement>) {
    const { id, value = '' } = e.currentTarget;

    if (id === 'sorting') {
      const newValue = parseInt(value, 10);

      onChange(
        (Number.isNaN(newValue) ? 0 : newValue) as unknown as string,
        id
      );

      return;
    }

    onChange(value, id);
  }

  if (id === 'value' && fieldType === 'colorList') {
    return (
      <RowInfoBlock>
        <ColorInput
          caption={caption}
          key={id}
          name={id}
          value={value as string}
          onChange={onChange}
        />

        {!!error && (
          <Typography variant="caption" color="error">
            {error}
          </Typography>
        )}
      </RowInfoBlock>
    );
  }

  return (
    <RowInfoBlock>
      <TextField
        type={id === 'sorting' ? 'number' : 'text'}
        key={id}
        id={id}
        onChange={onChangePropertyField}
        value={value}
        label={caption}
        disabled={disabled}
        helperText={helperText}
        sx={{ width: '100%' }}
      />

      {!!error && (
        <Typography variant="caption" color="error">
          {error}
        </Typography>
      )}
    </RowInfoBlock>
  );
};
