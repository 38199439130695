import { ReactElement } from 'react';

import { ResourceList } from '@Widgets/ResourceList/ResourceList';
import { useTranslate, useUpdate } from 'react-admin';
import { Supplier } from './interface';
import { LIST_COLUMNS } from './constants';
import { Flex, FileImport, SUPPORTED_FORMATS } from '@UI';
import { ResourceRoutes } from '@PluginManager/plugins/resourceRoutes';

export const ListPage = (): ReactElement => {
  const translate = useTranslate();
  const [update, { isLoading, isSuccess }] = useUpdate();

  async function onFileSelectHandler(file: File) {
    await update(ResourceRoutes.replenishment.supplier_schedules.resourcePath, {
      data: { file },
      meta: 'load',
    });
  }

  return (
    <>
      <ResourceList<Supplier>
        empty={false}
        exporter={false}
        bulkActionButtons={false}
        columns={LIST_COLUMNS}
        showDeleteButton={false}
        actions={
          <Flex alignItems="center">
            <FileImport
              isLoading={isLoading}
              isSuccess={isSuccess}
              accept={SUPPORTED_FORMATS.csv.toString()}
              buttonCaptionKey="replenishment.pages.suppliers.actions.import"
              onFileSubmitted={onFileSelectHandler}
            />
          </Flex>
        }
        getItemCardCaption={(record) =>
          translate('replenishment.pages.suppliers.labels.mobileCardCaption', {
            id: record.id,
          })
        }
      />
    </>
  );
};
