import { useGetList } from 'react-admin';
import { useController } from 'react-hook-form';
import { useEffect } from 'react';
import { useTheme } from '@mui/material';

import { ResourceRoutes } from '@Plugins/resourceRoutes';
import { RowInfoBlock, ResourceAutocompleteArrayInput } from '@UI';
import { getPrefixedSource } from '@Plugins/Promotions/resources/Promocodes/utils';

import {
  promocodesTranslationNamespace,
  PROMOCODE_APPLY_CHOICES,
} from '../../../constants';
import { RESOURCES_SCHEMA } from '../../../schemas/resourcesSchema';

import { RadioButtonGroupStyled } from '../styled';

interface Props {
  sourcePrefix?: string;
}

export const CustomerBuys = ({ sourcePrefix }: Props) => {
  const theme = useTheme();

  const customerBuysAnyItemsFrom = useController({
    name: getPrefixedSource(
      RESOURCES_SCHEMA.customerBuysAnyItemsFrom,
      sourcePrefix
    ),
  });
  const filters = useController({
    name: getPrefixedSource(RESOURCES_SCHEMA.filters.all, sourcePrefix),
  });
  const isForProducts =
    customerBuysAnyItemsFrom.field.value === 'specificProducts';
  const isForCategories =
    customerBuysAnyItemsFrom.field.value === 'specificCollection';

  const { data: products, isLoading: isLoadingProduct } = useGetList(
    ResourceRoutes.product.resourcePath,
    {},
    { enabled: isForProducts }
  );
  const { data: catalogs, isLoading: isLoadingCatalog } = useGetList(
    ResourceRoutes.catalog.resourcePath,
    {},
    { enabled: isForCategories }
  );

  useEffect(() => {
    filters.field.onChange({});
  }, [customerBuysAnyItemsFrom.field.value]);

  return (
    <RowInfoBlock
      asColumn
      titleKey={promocodesTranslationNamespace.labels.customerBuys}
    >
      <RadioButtonGroupStyled
        $dir={theme.direction}
        label={promocodesTranslationNamespace.labels.anyItemsFrom}
        name={getPrefixedSource(
          RESOURCES_SCHEMA.customerBuysAnyItemsFrom,
          sourcePrefix
        )}
        source={getPrefixedSource(
          RESOURCES_SCHEMA.customerBuysAnyItemsFrom,
          sourcePrefix
        )}
        choices={PROMOCODE_APPLY_CHOICES}
      />

      {(isForProducts || isForCategories) && (
        <ResourceAutocompleteArrayInput
          fullWidth
          choices={isForProducts ? products : catalogs}
          source={getPrefixedSource(
            RESOURCES_SCHEMA.filters[
              isForProducts ? 'inProduct' : 'inCategory'
            ],
            sourcePrefix
          )}
          isLoading={isLoadingProduct || isLoadingCatalog}
        />
      )}
    </RowInfoBlock>
  );
};
