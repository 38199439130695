import { FILTERS, LIST_COLUMNS } from '../constants';
import { ResourceList } from '@Widgets/ResourceList/ResourceList';

export const ListPage = () => {
  return (
    <ResourceList<CoreStory>
      filters={FILTERS}
      columns={LIST_COLUMNS}
      showDeleteButton
      getItemCardCaption={(record) => record.name}
    />
  );
};
