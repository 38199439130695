import styled from 'styled-components';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

export const TableCellStyled = styled(TableCell)`
  &.${tableCellClasses.head} {
    background-color: #19222d;
    color: white;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.5rem;
    letter-spacing: 0.01071em;
    display: table-cell;
    vertical-align: inherit;
    border-bottom: 1px solid rgba(81, 81, 81, 1);
    text-align: left;
    padding: 6px 16px;

    &:first-of-type {
      border-top-left-radius: 4px;
    }

    &:last-child {
      border-top-right-radius: 4px;
    }
  }

  &.${tableCellClasses.body} {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    display: table-cell;
    vertical-align: inherit;
    border-bottom: 1px solid rgba(81, 81, 81, 1);
    text-align: left;
    padding: 6px 16px;
    color: #fff;
  }
`;
