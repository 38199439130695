import { useState } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Button,
  Tooltip,
} from '@mui/material';
import {
  Add as AddIcon,
  InfoOutlined as InfoIcon,
  DeleteOutlineOutlined as DeleteIcon,
} from '@mui/icons-material';
import { useFieldArray, useFormContext } from 'react-hook-form';
import {
  Confirm,
  useTranslate,
  NumberInput,
  number,
  minValue,
  useLocaleState,
} from 'react-admin';

import { Flex } from '@UI';
import { isInteger } from '@Helpers';
import { SelectOffersPopupContent } from '@Widgets/Bundles/SelectOffersPopupContent';

import {
  CellWithHelpIconStyled,
  ProductNameCellStyled,
  ActionCellStyled,
} from './styled';

const TARGET_SOURCE = 'offersList';

const validateQtyField = [number(), isInteger(), minValue(0)];

const validatePriorityField = [number(), isInteger()];

const ProductListAttachedToGift = () => {
  const translate = useTranslate();
  const [lang] = useLocaleState();

  const { control } = useFormContext<CoreRemoteGifting>();
  const { fields, insert, remove } = useFieldArray({
    control,
    name: TARGET_SOURCE,
  });

  const offerList = fields as unknown as CoreGiftingOffer[];

  const [isOffersOpen, setIsOffersOpen] = useState<boolean>(false);
  const [selectedOffers, setSelectedOffers] = useState<CoreOffer[]>([]);

  const handleCloseOffersPopup = () => {
    setIsOffersOpen(false);
  };

  const handleOpenOffersModal = () => {
    setIsOffersOpen(true);
  };

  const onSelect = (selectedIds: Record<number, CoreOffer>) => {
    setSelectedOffers(Object.values(selectedIds));
  };

  const onConfirm = async () => {
    const lastPriority = offerList.length
      ? offerList[offerList.length - 1].priority || 0
      : 0;

    const submitData = selectedOffers.map((offer, idx) => ({
      id: offer.id,
      name: offer.name,
      locales: (offer as unknown as CoreGiftingOffer).locales,
      offerId: offer.id,
      priority: lastPriority + idx + 1,
      quantity: 1,
    }));

    insert(offerList.length, submitData);
    handleCloseOffersPopup();
    setSelectedOffers([]);
  };

  const getIsRowSelectable = (offerId: number): boolean => {
    return !offerList.some((item) => item.offerId === offerId);
  };

  const getLocalizedName = (item: CoreGiftingOffer) => {
    if (lang === 'en') {
      return item.name;
    }

    const arabicLocale = item.locales?.find((item) => item.language === 'ar');

    return arabicLocale?.name || item.name;
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <ProductNameCellStyled>
              {translate('promotions.pages.gifting.columns.product')}
            </ProductNameCellStyled>
            <TableCell>
              <CellWithHelpIconStyled>
                {translate('promotions.pages.gifting.columns.quantity')}
                <Tooltip
                  placement="top"
                  title={translate(
                    'promotions.pages.gifting.phrases.qtyTooltip'
                  )}
                >
                  <InfoIcon color="disabled" />
                </Tooltip>
              </CellWithHelpIconStyled>
            </TableCell>
            <TableCell>
              <CellWithHelpIconStyled>
                {translate('promotions.pages.gifting.columns.productPriority')}
                <Tooltip
                  placement="top"
                  title={translate(
                    'promotions.pages.gifting.phrases.priorityTooltip'
                  )}
                >
                  <InfoIcon color="disabled" />
                </Tooltip>
              </CellWithHelpIconStyled>
            </TableCell>
            <ActionCellStyled />
          </TableRow>
        </TableHead>
        <TableBody>
          {!offerList.length && (
            <TableRow>
              <TableCell colSpan={4}>Not found.</TableCell>
            </TableRow>
          )}
          {offerList.map((item, idx) => (
            <TableRow key={item.id}>
              <TableCell>{getLocalizedName(item)}</TableCell>
              <TableCell>
                <NumberInput
                  name={`${TARGET_SOURCE}.${idx}.quantity`}
                  source={`${TARGET_SOURCE}.${idx}.quantity`}
                  validate={validateQtyField}
                />
              </TableCell>
              <TableCell>
                <NumberInput
                  name={`${TARGET_SOURCE}.${idx}.priority`}
                  source={`${TARGET_SOURCE}.${idx}.priority`}
                  validate={validatePriorityField}
                />
              </TableCell>
              <ActionCellStyled>
                <IconButton size="small" onClick={() => remove(idx)}>
                  <DeleteIcon color="disabled" />
                </IconButton>
              </ActionCellStyled>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Flex justifyContent="flex-end" fullWidth sx={{ mt: 1 }}>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<AddIcon />}
          onClick={handleOpenOffersModal}
        >
          {translate('promotions.pages.gifting.buttons.addProduct')}
        </Button>
      </Flex>

      <Confirm
        isOpen={isOffersOpen}
        title={translate('promotions.pages.gifting.offersModal.title')}
        fullWidth
        maxWidth="xl"
        keepMounted={false}
        content={
          <SelectOffersPopupContent
            selectedOffers={selectedOffers}
            onSelect={onSelect}
            isRowSelectable={getIsRowSelectable}
          />
        }
        onConfirm={onConfirm}
        onClose={handleCloseOffersPopup}
      />
    </>
  );
};

export default ProductListAttachedToGift;
