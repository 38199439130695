import {
  SelectInput,
  TextInput,
  SimpleForm,
  required,
  useTranslate,
  useUpdate,
  useRecordContext,
  useNotify,
  useRefresh,
} from 'react-admin';
import {
  DefaultRefundCase,
  RefundCases,
  DEFAULT_FULL_REFUND_STATE,
} from './constants';
import { useRef, useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { Loader, UIDialog } from '@UI';
import { ApiActionCall } from '../../../../routes';
import { FieldValues } from 'react-hook-form';
import { ResourceRoutes } from '@PluginManager/plugins/resourceRoutes';

export const FullRefund = () => {
  const refresh = useRefresh();
  const record = useRecordContext<CoreOrder>();
  const fullRefundFields = useRef(DEFAULT_FULL_REFUND_STATE);
  const translate = useTranslate();
  const notify = useNotify();
  const [updateRefund, { isLoading, error, isSuccess }] = useUpdate();
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const formRef = useRef<FieldValues>();
  const onCloseConfirmDialogHandler = () => setIsConfirmDialogOpen(false);
  const onOpenConfirmDialogHandler = (values: FieldValues) => {
    formRef.current = values;
    setIsConfirmDialogOpen(true);
  };
  const onMakeRefundHandler = () => {
    setIsConfirmDialogOpen(false);
    updateRefund(ResourceRoutes.orders.resourcePath, {
      meta: ApiActionCall.fullRefund,
      previousData: record,
      data: formRef.current ?? undefined,
    }).catch(() => undefined);
  };

  useEffect(() => {
    if (error) {
      notify('orders.pages.actions.makeRefund.errorMessage', { type: 'error' });

      return;
    }

    if (isSuccess) {
      notify('orders.pages.actions.makeRefund.successFullMessage', {
        type: 'success',
      });
      refresh();
    }
  }, [error, isSuccess]);

  if (!record?.payment_id) return <></>;

  if (isLoading) return <Loader />;

  return (
    <>
      <SimpleForm
        onSubmit={onOpenConfirmDialogHandler}
        record={fullRefundFields.current}
        toolbar={
          <Button type="submit" variant="contained" sx={{ ml: 2 }}>
            {translate('orders.pages.actions.makeRefund.caption')}
          </Button>
        }
      >
        <SelectInput
          name="problem"
          emptyText={DefaultRefundCase.name}
          emptyValue={DefaultRefundCase.id}
          label="orders.pages.labels.payments.refund.problemType.caption"
          source="problem"
          choices={RefundCases}
          validate={required()}
          fullWidth
          resettable={false}
          isRequired
        />

        <TextInput
          fullWidth
          multiline
          minRows={4}
          name="comment"
          source="comment"
          label="orders.pages.labels.payments.comment"
        />
      </SimpleForm>

      <UIDialog
        open={isConfirmDialogOpen}
        onClose={onCloseConfirmDialogHandler}
        title={translate('orders.pages.actions.makeRefund.dialog.caption')}
        controls={
          <>
            <Button onClick={onCloseConfirmDialogHandler}>
              {translate('ra.action.cancel')}
            </Button>

            <Button onClick={onMakeRefundHandler}>
              {translate('orders.pages.actions.makeRefund.caption')}
            </Button>
          </>
        }
      >
        {translate('orders.pages.actions.makeRefund.dialog.description')}
      </UIDialog>
    </>
  );
};
