import { useResourceContext, useUpdateMany } from 'react-admin';
import { useCheckMutationAnswer } from '@Hooks/useCheckMutationAnswer';
import { FileImport, SUPPORTED_FORMATS } from '@UI';
import { FileImportProps } from '../FileImport/interface';
import { UPDATE_LIST_BY_FILE_UPLOAD } from '@ROOT/constants';
import { DownloadButton, removeTrailingSlashes } from '@Helpers';
import { ReactNode } from 'react';

export interface UpdateListByFileButtonProps {
  successMessage: string;
  errorMessage: string;
  meta?: unknown;
  accept?: FileImportProps['accept'];
  maxSize?: FileImportProps['maxSize'];
  urlOfXLSXExample?: string;
  children?: ReactNode;
}

export const UpdateListByFileButton = ({
  accept = SUPPORTED_FORMATS.excel.join(','),
  errorMessage,
  maxSize = 512000,
  meta = UPDATE_LIST_BY_FILE_UPLOAD,
  successMessage,
  urlOfXLSXExample,
  children,
}: UpdateListByFileButtonProps) => {
  const [updateMany, { isLoading, error, isSuccess }] = useUpdateMany();
  const resource = useResourceContext();
  const resourceNameTrimmed = removeTrailingSlashes(resource);

  const onFileSelectHandler = (file: File) => {
    updateMany(resource, {
      data: file,
      meta: meta,
    }).catch(() => undefined);
  };

  useCheckMutationAnswer({
    successMessage: successMessage,
    errorMessage: errorMessage,
    error: error as Error,
    isSuccess,
    shouldUpdateList: true,
  });

  const downloadButton = urlOfXLSXExample ? (
    <DownloadButton
      url={urlOfXLSXExample}
      filename={`${resourceNameTrimmed}_example`}
      extension="xlsx"
    />
  ) : null;

  const childComponents = children ?? downloadButton;

  return (
    <FileImport
      isLoading={isLoading}
      isSuccess={isSuccess}
      accept={accept}
      maxSize={maxSize}
      onFileSubmitted={onFileSelectHandler}
    >
      {childComponents}
    </FileImport>
  );
};
