import { Tab } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { UITabWithErrorProps } from './interface';

export const UITabWithError = (props: UITabWithErrorProps) => {
  const { isError, ...rest } = props;
  const theme = useTheme();

  return <Tab sx={isError ? { color: theme.palette.error.main } : undefined} {...rest} />;
};
