import { Typography } from '@mui/material';
import styled from 'styled-components';

export const TypographyStyled = styled(Typography)<{
  $truncate?: number | string;
}>`
  ${({ $truncate }) =>
    !!$truncate &&
    `
      max-width: ${$truncate}${
      ($truncate as string).length === undefined ? 'px' : ''
    };
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: middle;
  `}
`;
