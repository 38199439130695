import { WEEK_OPTIONS } from '@PluginManager/constants';

import { NormalizedScheduleValue } from './interfaces';

const removeMilliseconds = (value: string): string => {
  const [hours, minutes] = value.split(':');

  return `${hours}:${minutes}`;
};

const getDefaultIntervalInstance = () => ({
  start: '00:00',
  end: '23:59',
});

export const normalizeScheduleDefaultValues = (
  shippingMethod?: CoreZoneShippingMethod
): NormalizedScheduleValue => {
  if (!shippingMethod) {
    return WEEK_OPTIONS.reduce<NormalizedScheduleValue>(
      (memo, curr) => ({
        ...memo,
        [curr.id]: getDefaultIntervalInstance(),
      }),
      {}
    );
  }

  return shippingMethod.schedule.reduce<NormalizedScheduleValue>(
    (memo, curr) => {
      const first = curr.intervals[0];

      if (!first) {
        return {
          ...memo,
          [curr.dayOfWeek]: getDefaultIntervalInstance(),
        };
      }

      return {
        ...memo,
        [curr.dayOfWeek]: {
          start: removeMilliseconds(first.start),
          end: removeMilliseconds(first.end),
        },
      };
    },
    {}
  );
};
