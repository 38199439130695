import { useEffect, useRef } from 'react';
import { MAP_ZOOM, MAP_CENTER } from './constants';
import { GmapProps, onApiLoadedProps } from './interface';
import { Flex } from '@UI';
import { MarkerStyled } from './styles';
import { ETACoder } from './ETACoder/ETACoder';
import GoogleMapReact from 'google-map-react';
import { GOOGLE_API_KEY } from '@ROOT/providers/dataProvider/rest/config';

export const Gmap = ({
  handleMapChange,
  center,
  zoom,
  onApiLoaded,
  onMapClick,
  markers,
  showZones,
  drawOptions,
  onDrawingComplete,
  isError,
}: GmapProps) => {
  const etaCoder = useRef<Nullable<ETACoder>>(null);
  const onApiLoadedHandler = ({ maps, map, ref }: onApiLoadedProps) => {
    onApiLoaded?.({ maps, map, ref });
    etaCoder.current = new ETACoder(maps, map);

    if (showZones) etaCoder.current.drawZones().catch(() => undefined);

    if (drawOptions && onDrawingComplete)
      etaCoder.current.drawControls(drawOptions, onDrawingComplete);
  };

  useEffect(() => {
    if (drawOptions && onDrawingComplete && etaCoder.current) {
      etaCoder.current.drawControls(drawOptions, onDrawingComplete);
    }
  }, [JSON.stringify(drawOptions)]);

  useEffect(() => {
    if (!etaCoder.current) return;
    etaCoder.current.setCenter(center);
  }, [JSON.stringify(center)]);

  if (center === null) return <></>;

  return (
    <Flex
      height={500}
      width="100%"
      sx={{ border: isError ? '2px solid #f44336' : undefined }}
    >
      <GoogleMapReact
        yesIWantToUseGoogleMapApiInternals
        bootstrapURLKeys={{
          key: GOOGLE_API_KEY,
          language: 'en-GB',
          region: 'GB',
          libraries: ['drawing', 'geometry'],
        }}
        defaultCenter={center || MAP_CENTER}
        defaultZoom={zoom ?? MAP_ZOOM}
        options={{
          clickableIcons: false,
          gestureHandling: 'cooperative',
        }}
        onGoogleApiLoaded={onApiLoadedHandler}
        shouldUnregisterMapOnUnmount
        onChange={handleMapChange}
        onClick={onMapClick}
      >
        {markers?.map(({ icon, lat, lng, key }) => (
          <MarkerStyled key={key} lng={lng} lat={lat} icon={icon}>
            {icon}
          </MarkerStyled>
        ))}
      </GoogleMapReact>
    </Flex>
  );
};
