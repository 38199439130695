import { useTranslate } from 'react-admin';
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import { forwardRef, Ref } from 'react';

export const ConfigurationMenu = forwardRef((props, ref: Ref<HTMLAnchorElement>) => {
  const translate = useTranslate();

  return (
    <MenuItem component={Link} ref={ref} {...props} to='/configuration'>
      <ListItemIcon>
        <SettingsIcon />
      </ListItemIcon>

      <ListItemText>{translate('pos.configuration')}</ListItemText>
    </MenuItem>
  );
});
