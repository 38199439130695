import { PluginResourceController } from '@PluginBase';
import { ListPage } from './ListPage';
import {
  GetListParams,
  GetListResult,
  UpdateParams,
  UpdateResult,
} from 'react-admin';
import { ResourceRoutes } from '../../../resourceRoutes';
import { ReplenishmentApiResource } from '../../apiRoutes';
import { ReplenishmentCrud } from '../../crud';

const alertsCrud = new ReplenishmentCrud(ReplenishmentApiResource.alert);

const AlertsController = new PluginResourceController({
  resourceRoute: {
    name: ResourceRoutes.replenishment.alert.resourcePath,
    list: ListPage,
  },
  menuItem: {
    route: ResourceRoutes.replenishment.alert.routePath,
    caption: {
      translationKey: 'replenishment.pages.alerts.caption',
    },
  },
  dataProvider: {
    getList: (
      resource: string,
      params: GetListParams
    ): Promise<GetListResult> => {
      return alertsCrud.list(params);
    },
    update: async (
      resource: string,
      params: UpdateParams
    ): Promise<UpdateResult> => {
      params.data.final_order = parseInt(params.data.final_order);
      alertsCrud.update(params);

      return { data: { id: params.id } };
    },
  },
});

export default AlertsController;
