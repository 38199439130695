import { useRecordContext, useTranslate } from 'react-admin';
import { Link } from '@mui/material';

import { TruncatedContentColumn } from '../TruncatedContentColumn';

const EmailColumn = () => {
  const { email } = useRecordContext<CoreCustomer>();
  const translate = useTranslate();

  if (!email) {
    return <>{translate('customers.pages.customers.labels.notSpecified')}</>;
  }

  return (
    <TruncatedContentColumn
      source="email"
      renderContent={(truncatedValue, originalValue) => (
        <Link
          href={`mailto:${originalValue}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {truncatedValue}
        </Link>
      )}
    />
  );
};

export default EmailColumn;
