import { useRecordContext } from 'react-admin';
import { Link } from '@mui/material';
import { ResourceRoutes } from "@Plugins/resourceRoutes";

export const ResourceProductMainCategory = () => {
  const record = useRecordContext<CoreProduct>();

  return (
    <Link href={`#${ResourceRoutes.catalog.resourcePath}/${record.categoryId}`}>
      {record.categoryName ?? record.categoryId}
    </Link>
  );
};

ResourceProductMainCategory.defaultProps = {
  sortBy: 'categoryId',
};
