import {
  Button,
  CheckboxGroupInput,
  Datagrid,
  Form,
  ListContextProvider,
  Pagination,
  required,
  SaveButton,
  useGetManyReference,
  useList,
  useRecordContext,
  useRefresh,
  useTranslate,
} from 'react-admin';
import {
  apiUrl,
  CoreOutboundDemandOrderModified,
  PER_PAGE,
  ResourceMeta,
} from './constants';
import {
  getAllDestinationWarehouseNames,
  getColsWithWarehouses,
} from './dataGenerators';
import { ResourceRoutes } from '@Plugins/resourceRoutes';
import { useEffect, useState } from 'react';
import { ComponentFactory } from '@Widgets/ResourceList/ComponentFactory/ComponentFactory';
import { Alert, CircularProgress } from '@mui/material';
import { Flex, Loader } from '@UI';
import { BasicModal } from '@Widgets/BasicModal/BasicModal';
import { AlertColor } from '@mui/material/Alert/Alert';
import { RequestAPI } from '@RestApi';

interface CreateResult {
  isSuccess: boolean;
  result: CoreStartPickingResponseDto[];
}

interface StartPickingResultAlertProps {
  createResult: CreateResult;
}

interface CheckboxesFormProps {
  warehouseList: string[];
  onSubmitResult: (val: { warehouses: string[] }) => void;
}

const StartPickingResultAlert = ({
  createResult,
}: StartPickingResultAlertProps) => {
  const translate = useTranslate();
  const Message = () => {
    if (!createResult?.isSuccess) {
      return (
        <div>
          {translate(
            'distributionCenter.pages.outboundOrders.errors.unableCreate'
          )}
          <ul style={{ paddingLeft: 2 }}>
            {createResult?.result
              .filter((r) => !r.isSuccess)
              .map((r, i) => {
                return (
                  <li key={i}>
                    {r.sourceWarehouse}
                    {' ➔ '}
                    {r.destinationWarehouse}:{' '}
                    {!r.isSuccess ? r.reason : 'success'}
                  </li>
                );
              })}
          </ul>
        </div>
      );
    }

    return createResult.result.length > 0 ? (
      <div>
        {translate(
          'distributionCenter.pages.outboundOrders.messages.createdSuccessful'
        )}
      </div>
    ) : (
      <div>
        {translate(
          'distributionCenter.pages.outboundOrders.messages.createdAlready'
        )}
      </div>
    );
  };

  const Type = (): AlertColor => {
    if (!createResult?.isSuccess) {
      return 'error';
    }

    if (createResult.result.length === 0) {
      return 'warning';
    }

    return 'success';
  };

  return (
    <Alert severity={Type()} sx={{ marginBottom: 2 }}>
      <Message />
    </Alert>
  );
};

const CheckboxesForm = ({
  warehouseList,
  onSubmitResult,
}: CheckboxesFormProps) => {
  const translate = useTranslate();
  const onSubmit = (val: unknown) =>
    onSubmitResult(val as { warehouses: string[] });

  return (
    <Form record={{ warehouses: warehouseList }} onSubmit={onSubmit}>
      <CheckboxGroupInput
        source="warehouses"
        row={false}
        choices={warehouseList.map((name) => ({
          name,
          id: name,
        }))}
        validate={required()}
      />
      <Flex justifyContent="center" mt={2}>
        <SaveButton
          label={translate(
            'distributionCenter.pages.outboundOrders.labels.startPicking'
          )}
          icon={<></>}
          alwaysEnable
        />
      </Flex>
    </Form>
  );
};

export const DemandTab = () => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const { id, orderItems } = useRecordContext<CoreOutboundOrder>();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(PER_PAGE);
  const [isSyncFailed, setIsSyncFailed] = useState(false);
  const [target, setTarget] = useState(ResourceMeta.DEMAND_QUANTITIES);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isCreatingPicking, setIsCreatingPicking] = useState(false);
  const [isCreatingError, setIsCreatingError] = useState(false);
  const [disabledWarehouseList, setDisabledWarehouseList] = useState<string[]>(
    []
  );
  const [createResult, setCreateResult] = useState<CreateResult | null>(null);
  const warehouseList = getAllDestinationWarehouseNames(orderItems);
  const colsWithWarehouses = getColsWithWarehouses(orderItems);
  const {
    data = [],
    total = 0,
    isFetching,
    isLoading: isListLoading,
    refetch,
  } = useGetManyReference<CoreOutboundDemandOrderModified>(
    ResourceRoutes.outboundOrders.resourcePath,
    {
      target,
      id,
      meta: warehouseList,
      pagination: {
        page,
        perPage,
      },
    }
  );
  const listContext = useList({
    isFetching,
    isLoading: isListLoading,
    data,
    page,
    perPage,
  });
  const closeModal = () => setModalOpen(false);
  const onSubmitResult = async (result: { warehouses: string[] }) => {
    closeModal();
    setIsCreatingError(false);
    setIsCreatingPicking(true);

    try {
      const { data } = await RequestAPI.post(`${apiUrl}/${id}/start`, result);

      setCreateResult({
        result: data || [],
        isSuccess:
          !(data as CoreStartPickingResponseDto[])?.some(
            (el) => !el.isSuccess
          ) ?? true,
      });

      refresh();
    } catch (error: any) {
      if (error?.data?.title === 'ORDER_ITEMS_AVAILABLE_QUANTITIES_UNSYNCED') {
        setIsCreatingError(true);
      }
    } finally {
      setIsCreatingPicking(false);
    }
  };
  const ModalWarehouses = () => (
    <BasicModal isOpen={isModalOpen} onClose={closeModal}>
      <CheckboxesForm
        warehouseList={warehouseList.filter(
          (wh) => !disabledWarehouseList.includes(wh)
        )}
        onSubmitResult={onSubmitResult}
      />
    </BasicModal>
  );
  const handleCheckAvailability = () => {
    setTarget(ResourceMeta.DEMAND_QUANTITIES_RECOUNT);
    refetch().catch(() => undefined);
  };
  const handleStartPicking = () => setModalOpen(true);

  useEffect(() => {
    if (data.length) {
      setIsSyncFailed(data[0].isSyncFailed);

      if (target === ResourceMeta.DEMAND_QUANTITIES) {
        setDisabledWarehouseList(
          Array.from(
            new Set(
              data.reduce<string[]>(
                (list, item) => [...list, ...item.disabledWhList],
                []
              )
            )
          )
        );
      }
    }
  }, [JSON.stringify(data)]);

  const getRowStyle = ({ available }: CoreOutboundDemandOrderModified) =>
    +available < 100
      ? {
          border: '2px solid red',
        }
      : {};

  return (
    <>
      {isSyncFailed && (
        <Alert severity="warning" sx={{ marginBottom: 2 }}>
          {translate(
            'distributionCenter.pages.outboundOrders.errors.isSyncFailed'
          )}
        </Alert>
      )}

      {isCreatingError && (
        <Alert severity="error" sx={{ marginBottom: 2 }}>
          {translate(
            'distributionCenter.pages.outboundOrders.errors.quantitiesChanged'
          )}
        </Alert>
      )}

      {createResult !== null && (
        <StartPickingResultAlert createResult={createResult} />
      )}

      {isListLoading ? (
        <Flex sx={{ height: '80px' }}>
          <Loader fullHeight={false} />
        </Flex>
      ) : createResult !== null && data.length === 0 ? (
        <></>
      ) : (
        <ListContextProvider
          value={{ ...listContext, total, setPage, data, setPerPage }}
        >
          <Datagrid
            sx={{ width: '100%' }}
            rowStyle={getRowStyle}
            bulkActionButtons={false}
          >
            {colsWithWarehouses.map((column) => {
              const sortable = column.sortable ?? true;

              return (
                <ComponentFactory
                  sortBy={sortable ? column.source : undefined}
                  key={column.source}
                  column={column}
                  label={column.label}
                />
              );
            })}
          </Datagrid>

          {listContext.data?.length > 0 ? <Pagination page={page} /> : null}
        </ListContextProvider>
      )}

      <Flex alignItems="flex-start" justifyContent="flex-end">
        <Button
          label={translate(
            'distributionCenter.pages.outboundOrders.labels.checkAvailability'
          )}
          variant="contained"
          color="secondary"
          disabled={isSyncFailed || isCreatingPicking}
          size="medium"
          onClick={handleCheckAvailability}
        />

        <Button
          label={translate(
            'distributionCenter.pages.outboundOrders.labels.startPicking'
          )}
          variant="contained"
          color="primary"
          disabled={
            target === ResourceMeta.DEMAND_QUANTITIES ||
            isSyncFailed ||
            isCreatingPicking ||
            !data.length
          }
          size="medium"
          onClick={handleStartPicking}
          sx={{ ml: 2 }}
          startIcon={isCreatingPicking ? <CircularProgress size={16} /> : <></>}
        />
      </Flex>

      <ModalWarehouses />
    </>
  );
};
