import { Flex } from '@UI';
import { PropsWithChildren } from 'react';
import { Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Close';
import { MultiplierContainerProps } from '../interface';
import { useTheme } from '@mui/material/styles';

export const MultiplierItemContainer = (props: PropsWithChildren<MultiplierContainerProps>) => {
  const { id, element, children, onRemoveBlock } = props;
  const theme = useTheme();

  function onDeleteDayHandler() {
    onRemoveBlock(id);
  }

  return (
    <Flex data-testid='schedule-day-container' asColumn mb={1} padding={1} bgcolor={theme.palette.background.default}>
      <Flex justifyContent='right'>
        <Button data-testid='delete-day-button' onClick={onDeleteDayHandler}>
          <DeleteIcon color='secondary' />
        </Button>
      </Flex>

      <Flex mb={1} alignItems='center'>
        {element}
      </Flex>

      <Flex asColumn>{children}</Flex>
    </Flex>
  );
};
