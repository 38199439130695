import { Filter, ListColumn } from '@Widgets/ResourceList/interface';
import { SimplyReplenish } from './interface';

export const LIST_COLUMNS: ListColumn<SimplyReplenish>[] = [
  {
    source: 'sku',
    type: 'text',
    label: 'replenishment.pages.simply_replenish.labels.sku',
  },
  {
    source: 'offer_name',
    type: 'text',
    label: 'replenishment.pages.simply_replenish.labels.offer_name',
  },
  {
    source: 'min_quantity',
    type: 'number',
    label: 'replenishment.pages.simply_replenish.labels.min_quantity',
  },
  {
    source: 'max_quantity',
    type: 'number',
    label: 'replenishment.pages.simply_replenish.labels.max_quantity',
  },
  {
    source: 'current_stock',
    type: 'number',
    label: 'replenishment.pages.simply_replenish.labels.current_stock',
  },
  {
    source: 'to_supply',
    type: 'number',
    label: 'replenishment.pages.simply_replenish.labels.to_supply',
  },
];

const TO_SUPPLY_CHOICES: SelectOption[] = [
  { name: 'True', id: 'true' },
  { name: 'False', id: 'false' },
];

export const FILTERS: Filter[] = [
  { source: 'offer_name', type: 'search' },
  { source: 'sku', type: 'search' },
  {
    source: 'to_supply',
    type: 'choose',
    label: 'Show to supply only',
    choices: TO_SUPPLY_CHOICES,
    emptyText: '---',
  },
];
