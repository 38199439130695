import { Flex, ResourceSwitcher, RowInfoBlock } from '@UI';
import { useController } from 'react-hook-form';

import { ActiveDates } from '@Plugins/Promotions/resources/Promocodes/pages/PromocodeForm/components/ActiveDates';
import { Type } from '@Plugins/Promotions/resources/Promocodes/pages/PromocodeForm/components/Type';
import { CustomerBuys } from '@Plugins/Promotions/resources/Promocodes/pages/PromocodeForm/components/CustomerBuys';
import { CustomerGet } from '@Plugins/Promotions/resources/Promocodes/pages/PromocodeForm/components/CustomerGet';
import { CustomerEligibility } from '@Plugins/Promotions/resources/Promocodes/pages/PromocodeForm/components/CustomerEligibility';
import { UsageLimits } from '@Plugins/Promotions/resources/Promocodes/pages/PromocodeForm/components/UsageLimits';
import { MinRequirementsBlock } from '@Plugins/Promotions/resources/Promocodes/pages/PromocodeForm/components/MinRequirementsBlock';
import { useIsMobile } from '@ROOT/hooks';

export const AffiliateTemplateForm = () => {
  const isMobile = useIsMobile();

  const {
    field: { value: fieldTypeVal = '' },
  } = useController({ name: 'instance.type' });

  return (
    <>
      <Flex gap={1}>
        <RowInfoBlock>
          <ResourceSwitcher
            name="instance.isActive"
            labelKey="promotions.pages.referral.labels.isActive"
          />
        </RowInfoBlock>

        <RowInfoBlock>
          <ResourceSwitcher
            name="instance.applyToDiscountedItems"
            labelKey="promotions.pages.promocodes.labels.applyToDiscountedItems"
          />
        </RowInfoBlock>
      </Flex>

      <Flex asColumn={isMobile} gap={1} fullWidth>
        <ActiveDates sourcePrefix="instance" />
      </Flex>

      <Flex asColumn fullWidth>
        <Type sourcePrefix="instance" />

        {fieldTypeVal === 'BUY_X_GET_Y' && (
          <>
            <CustomerBuys sourcePrefix="instance" />

            <CustomerGet sourcePrefix="instance" />
          </>
        )}

        {['FREE_SHIPPING', 'BUY_X_GET_Y'].includes(fieldTypeVal) && (
          <CustomerEligibility sourcePrefix="instance" />
        )}

        <MinRequirementsBlock sourcePrefix="instance" />

        <UsageLimits sourcePrefix="instance" />
      </Flex>
    </>
  );
};
