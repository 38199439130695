export const TABLEAU_HOST = 'https://bi.thecloudretail.com';

export const TABLEAU_BASE_URL = `${TABLEAU_HOST}/views/bi_servicedashboardsdarkversion/Sales`;

export const TABLEAU_QUERY_PARAMS: Record<string, string> = {
  ':embed_code_version': '3',
  ':embed': 'y',
  ':loadOrderID': '0',
  ':display_spinner': 'no',
  ':showAppBanner': 'false',
  ':display_count': 'n',
  ':showVizHome': 'no',
  ':origin': 'viz_share_link',
  ':tabs': 'yes',
  ':toolbar': 'yes',
};

export const WRAPPER_ID = 'tableau-root';
