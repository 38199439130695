import { Plugin } from '@PluginBase';
import Translation from './i18n.json';
import { Icon } from '@UI';
import CalculateController from './resources/Calculate/controller';
import ProductsController from './resources/Products/controller';
import AlertsController from './resources/Alerts/controller';
import CategoriesController from './resources/Categories/controller';
import StoresController from './resources/Stores/controller';
import ProductMatrixController from './resources/ProductMatrix/controller';
import SuppliersController from './resources/Suppliers/controller';
import SalesController from './resources/Sales/controller';
import ClusterRulesController from './resources/ClusterRules/controller';
import LotsController from './resources/Lots/controller';
import FutureDeliveriesController from './resources/FutureDeliveries/controller';
import StocksController from './resources/Stocks/controller';
import FinalOrderAlertController from '@Plugins/Replenishment/resources/FinalOrderAlerts/controller';
import SimplyReplenishController from './resources/Simply_Replenish/controller';

const Replenishment = new Plugin({
  name: 'Replenishment',
  i18n: Translation,
  resources: [
    ProductsController,
    StoresController,
    ClusterRulesController,
    ProductMatrixController,
    CategoriesController,
    SuppliersController,
    StocksController,
    SalesController,
    LotsController,
    FutureDeliveriesController,
    CalculateController,
    FinalOrderAlertController,
    AlertsController,
    SimplyReplenishController
  ],
  rootMenu: {
    caption: {
      translationKey: 'replenishment.caption',
    },
    icon: <Icon type="catalog" />,
  },
});

export default Replenishment;
