import styled from 'styled-components';
import { Typography } from '@mui/material';

export const TruncatedTextContainer = styled(Typography)<{ $lines: number; $width?: number }>`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${({ $lines }) => $lines};
  -webkit-box-orient: vertical;
  overflow-wrap: anywhere;
  line-height: 1.5;
  ${({ $width }) => $width !== undefined && `width: ${$width}px;`}
`;
