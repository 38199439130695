import { AxiosError } from 'axios';
import {
  GetManyReferenceResult,
  GetListResult,
  GetManyReferenceParams,
} from 'react-admin';
import { GetListParams } from 'ra-core';
import { CommonCrud } from '@PluginManager/base/AbstractApi/CommonCrud';
import { RequestAPI } from '@RestApi';

export class WarehouseCRUD extends CommonCrud {
  constructor(url: string) {
    super(url, {
      isNewSorting: true,
      arrayFormatter: (key: string, values: string[]) => {
        return values.map((value) => `${key}=${value}`).join('&');
      },
      errorHandler: (e) => {
        if (e instanceof AxiosError) {
          const code = e?.response?.data?.code;

          if (code) throw new Error(code);
        }

        throw e;
      },
    });
  }

  async getLog(
    params: GetManyReferenceParams
  ): Promise<GetManyReferenceResult> {
    this.currentFiltersQuery = '';
    const { sort, pagination, id } = params;

    this._addToQuery(this.convertToGetQuery({ warehouseId: id }));
    this._addSort(sort);
    this._addPagination(pagination);

    const response = await RequestAPI.get(
      `${this.apiUrl}-logs${this.currentFiltersQuery}`
    );

    return {
      data: response.data,
      total: response.pagination.total,
    };
  }

  async list(params?: Partial<GetListParams>): Promise<GetListResult> {
    this.currentFiltersQuery = '';
    const { filter, sort, pagination } = params || {};

    const search = filter?.search;

    if (search?.length > 0) {
      this._addToQuery(this.convertToGetQuery({ search }));
    }

    sort && this._addSort(sort);
    pagination && this._addPagination(pagination);

    const response = await RequestAPI.get(
      `${this.apiUrl}${this.currentFiltersQuery}`
    );

    return {
      data: response.data,
      total: response.pagination.total,
    };
  }

  async deleteMany_(ids: (string | number)[]): Promise<boolean> {
    if (ids.length === 1) {
      await RequestAPI.delete(`${this.apiUrl}/${ids[0]}`);
    } else {
      await RequestAPI.put(`${this.apiUrl}/delete`, { ids });
    }

    return true;
  }
}
