import { Flex } from '@UI';
import { useIsMobile } from '@Hooks/useIsMobile';
import { PropsWithChildren } from 'react';
import { UIDrawerProps } from './interface';
import { DrawerStyled } from './styles';

export const UIDrawer = ({ isOpen, onClose, children, minWidth }: PropsWithChildren<UIDrawerProps>) => {
  const isMobile = useIsMobile();

  return (
    <DrawerStyled
      $isMobile={isMobile}
      $minWidth={minWidth}
      open={isOpen}
      onClose={onClose}
      anchor={isMobile ? 'bottom' : 'right'}
    >
      <Flex padding={3}>{children}</Flex>
    </DrawerStyled>
  );
};
