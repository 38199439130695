export type PickingOrderStatus = `${CorePickingOrderStatus}`;

export enum ResourceMeta {
  Item = 'Item',
}

interface GetPickersMeta {
  type: 'getPickers';
  currentPickerOption: Nullable<SelectOption>;
}

export type GetManyReferenceMeta = ResourceMeta | GetPickersMeta;
