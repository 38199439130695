import { useRecordContext } from 'react-admin';
import { Typography } from '@mui/material';
import { formatPhone } from '@Helpers';

export const PhoneField = () => {
  const record = useRecordContext<CoreUser>();

  if (!record) return null;

  return (
    <Typography fontSize={14} noWrap>
      {formatPhone(record.phone || '')}
    </Typography>
  );
};

PhoneField.defaultProps = {
  label: 'settings.pages.users.labels.phone',
  sortBy: 'phone',
};
