import { Schema } from 'type-fest';

// TODO: remove all @ts-ignore
export const RESOURCES_SCHEMA = {
  id: 'id',
  // @ts-ignore
  items: {
    all: 'items',
    id: 'id',
    order: 'order',
    image: 'image',
    buttons: {
      all: 'buttons',
      id: 'id',
      action: 'action',
      x: 'x',
      y: 'y',
      text: 'text',
      paddingX: 'paddingX',
      paddingY: 'paddingY',
      fontSize: 'fontSize',
      borderColor: 'borderColor',
      borderRadius: 'borderRadius',
      backgroundColor: 'backgroundColor',
      color: 'color',
    },
  },
  // @ts-ignore
  schedules: {
    all: 'schedules',
    id: 'id',
    day: 'day',
    timeFrom: 'timeFrom',
    timeTo: 'timeTo',
  },
  blacklistedWarehouses: 'blacklistedWarehouses',
  duration: 'duration',
  clientName: 'name',
  deepLink: 'deepLink',
  isStart: 'isStart',
  active: 'active',
  dateStart: 'dateStart',
  dateEnd: 'dateEnd',
  endStory: 'endStory',
  mainImage: 'mainImage',
  order: 'order',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
} satisfies Schema<CoreStory, keyof CoreStory>;
