const lightenDarkenColor = (hexColor: string, magnitude: number) => {
  hexColor = hexColor.replace('#', '');

  if (hexColor.length !== 6) {
    return hexColor;
  }

  const decimalColor = parseInt(hexColor, 16);

  let r = (decimalColor >> 16) + magnitude;
  let g = (decimalColor & 0x0000ff) + magnitude;
  let b = ((decimalColor >> 8) & 0x00ff) + magnitude;

  r > 255 && (r = 255);
  r < 0 && (r = 0);
  g > 255 && (g = 255);
  g < 0 && (g = 0);
  b > 255 && (b = 255);
  b < 0 && (b = 0);

  return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
};

export default lightenDarkenColor;
