import { Plugin } from '@PluginBase';
import Translation from './i18n.json';
import DeliveryDispatcherPages from './controller';

const DeliveryDispatcher = new Plugin({
  name: 'DeliveryDispatcher',
  resources: [DeliveryDispatcherPages],
  i18n: Translation,
});

export default DeliveryDispatcher;
