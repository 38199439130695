import { RequestAPI } from '@RestApi';

export const loadPromocode = async (
  promocode: string
): Promise<Nullable<CorePromocode>> => {
  if (!promocode) {
    return null;
  }

  try {
    return await RequestAPI.get(
      `/promocodes-service/v1/promocodes/code/${promocode}`
    );
  } catch (error) {
    return null;
  }
};
