import { List, RaRecord, useNotify } from 'react-admin';
import { CircularProgress } from '@mui/material';

import { DEFAULT_ITEMS_PER_PAGE } from '@ROOT/constants';

import { Flex } from '@UI';

import { useIsMobile } from '@Hooks/useIsMobile';

import { ResourceListContent } from './ResourceListContent';
import { ResourceListProps } from './interface';
import { ResourceListStyled } from './styles';
import { DEFAULT_SORTING } from './constants';
import { useFilterFactory } from './hooks/useFilterFactory';

export function ResourceList<RecordType extends RaRecord = RaRecord>(
  props: ResourceListProps<RecordType>
) {
  const {
    columns,
    filters,
    showDeleteButton,
    getItemCardCaption,
    bulkActionButtons = false,
    confirmDeleteDialogContent,
    isInteractive = true,
    defaultSort = DEFAULT_SORTING,
    perPage = DEFAULT_ITEMS_PER_PAGE,
    disableSyncWithLocation = true,
    rowClick,
    isRowSelectable,
    selectedIds,
    onSelect,
    onSelectedInstanceChange,
    onToggleItem,
    emptyContent = (
      <Flex justifyContent="center" height={200} alignItems="center">
        <CircularProgress size={40} />
      </Flex>
    ),
    ...rest
  } = props;
  const isMobile = useIsMobile();
  const notify = useNotify();
  const filterComponents = useFilterFactory(filters);

  const handleSuccess = ({
    data,
    total = 0,
  }: {
    data: RaRecord[];
    total?: number;
  }) => {
    if (data.length && !total) {
      const message: string =
        (data[0].fields?._errorList || []).join('\n') || data[0].message || '';

      if (!message) return;
      notify(message, {
        type: 'error',
        multiLine: true,
        autoHideDuration: 10000,
      });
    }
  };

  return (
    <ResourceListStyled<typeof List<RecordType>>
      filters={filterComponents}
      disableSyncWithLocation={disableSyncWithLocation}
      {...rest}
      perPage={perPage}
      sort={defaultSort}
      queryOptions={{
        ...rest.queryOptions,
        onSuccess: handleSuccess,
      }}
      $isMobile={isMobile}
    >
      <ResourceListContent<RecordType>
        columns={columns}
        showDeleteButton={showDeleteButton}
        getItemCardCaption={getItemCardCaption}
        defaultSort={defaultSort}
        isInteractive={isInteractive}
        resource={rest.resource}
        rowClick={rowClick}
        onSelect={onSelect}
        selectedIds={selectedIds}
        onToggleItem={onToggleItem}
        emptyContent={emptyContent}
        isRowSelectable={isRowSelectable}
        bulkActionButtons={bulkActionButtons}
        confirmDeleteDialogContent={confirmDeleteDialogContent}
        onSelectedInstanceChange={onSelectedInstanceChange}
      />
    </ResourceListStyled>
  );
}
