import { TabbedFormTabs, TabbedFormProps, TabbedForm } from 'react-admin';
import { FormErrorBoundary } from './FormErrorBoundary';

export const ResourceTabbedForm = ({ children, ...rest }: TabbedFormProps) => {
  return (
    <TabbedForm
      {...rest}
      tabs={<TabbedFormTabs variant="scrollable" scrollButtons="auto" />}
    >
      {children}

      <FormErrorBoundary />
    </TabbedForm>
  );
};
