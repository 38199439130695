import { RequestAPI } from '@RestApi';
import { AbstractApi } from '@PluginBase';
import {
  FullOrderRefundApiProps,
  OrderApiResponse,
  FullRefundRequest,
} from './interface';

export class FullOrderRefundApi extends AbstractApi<
  FullOrderRefundApiProps,
  OrderApiResponse
> {
  static fullRefundApiUrl = '/orders/v1/orders/refund/full';

  constructor(order: CoreOrder, problem: string, comment: string) {
    super(FullOrderRefundApi.fullRefundApiUrl, {
      id: order.id,
      data: {
        problem,
        comment,
      },
      previousData: order,
    });
  }

  async execute(): Promise<OrderApiResponse> {
    const request: FullRefundRequest = {
      order_id: this.params.id,
      problem: 'other',
      problem_subtype: this.params.data.problem,
      comment: this.params.data.comment ?? '',
    };

    const response = await RequestAPI.post(this.apiUrl, { ...request });

    return {
      data: {
        ...this.params.previousData,
        payment: {
          ...this.params.previousData.payment,
          status: response.payment_status,
        },
      },
    };
  }
}
