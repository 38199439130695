import {
  Form,
  SaveButton,
  useRecordContext,
  useTranslate,
  ValidationErrorMessage,
} from 'react-admin';
import { Box, Button } from '@mui/material';

import { Flex, ResourceInputNumber, TruncatedText } from '@UI';
import { maxNum, minNum } from '@Helpers';

import { ItemsListProps } from './interface';

const validateActualQuantity = (maxValue: string) => (value: string) => {
  if (!value) return 'ra.validation.required';

  const error: ValidationErrorMessage | undefined = minNum(0)(value);

  if (error) return error;

  return maxNum(parseInt(maxValue, 10))(value);
};

const ItemsList = ({ onConfirm, onCancel }: ItemsListProps) => {
  const translate = useTranslate();
  const { items } = useRecordContext<CoreOrder>();
  const onSubmit = (value: any) => {
    const { actual_quantity } = value as { actual_quantity: string[] };

    onConfirm(
      items.map(({ id }, i) => ({
        id,
        actual_quantity: parseInt(actual_quantity[i], 10),
      }))
    );
  };
  const actualQuantity = items.map(
    ({ requested_quantity }) => requested_quantity
  );

  return (
    <Form record={{}} onSubmit={onSubmit}>
      <Box maxHeight="80vh" overflow="auto">
        <Flex
          gap={1}
          fullWidth
          py={1}
          sx={{ borderBottom: '1px solid #515151' }}
          fontSize={14}
          fontWeight={500}
          alignItems="flex-end"
        >
          <Box width="25%">{translate('orders.pages.labels.sku')}</Box>
          <Box width="45%">{translate('orders.pages.labels.name')}</Box>
          <Box width="15%">
            {translate('orders.pages.labels.item.requestedQuantity')}
          </Box>
          <Box width="15%">
            {translate('orders.pages.labels.item.deliveredQuantity')}
          </Box>
        </Flex>
        {items.map(({ sku, name, requested_quantity }, index) => (
          <Flex
            gap={1}
            key={index}
            alignItems="center"
            py={1}
            sx={{ borderBottom: '1px solid #515151' }}
            fontSize={14}
            fullWidth
          >
            <Box width="25%">
              <TruncatedText lines={1} variant="body2" title={sku}>
                {sku}
              </TruncatedText>
            </Box>
            <Box width="45%">
              <TruncatedText lines={1} variant="body2" title={name}>
                {name}
              </TruncatedText>
            </Box>
            <Box width="15%">{requested_quantity}</Box>
            <Box width="15%">
              <ResourceInputNumber
                name={`actual_quantity[${index}]`}
                source={`actual_quantity[${index}]`}
                defaultValue={actualQuantity[index]}
                label=""
                isInteger
                validate={validateActualQuantity(actualQuantity[index])}
                fullWidth
              />
            </Box>
          </Flex>
        ))}
      </Box>

      <Flex justifyContent="center" mt={2} gap={2}>
        <Button color="secondary" variant="outlined" onClick={onCancel}>
          {translate('orders.pages.actions.cancel')}
        </Button>

        <SaveButton
          label={translate('orders.pages.actions.confirm')}
          icon={<></>}
          alwaysEnable
        />
      </Flex>
    </Form>
  );
};

export default ItemsList;
