import { Link as MuiLink } from '@mui/material';
import { Link } from '@mui/icons-material';
import { useRecordContext } from 'react-admin';
import { Flex } from '@UI';

const FileLink = () => {
  const record = useRecordContext<CoreOfferImport>();

  if (!record.file) {
    return (
      <Flex asColumn={false} alignItems="center" gap={1}>
        <div style={{ minWidth: 60 }}>-</div>
      </Flex>
    );
  }

  return (
    <MuiLink href={record.file} rel="noopener nofollow" target="_blank">
      <Flex asColumn={false} alignItems="center" gap={1}>
        <div style={{ minWidth: 60 }}>
          {record.file.split('/').splice(-1)?.[0] ?? '-'}
        </div>
        <Link />
      </Flex>
    </MuiLink>
  );
};

export default FileLink;
