import {
  AutocompleteInput,
  AutocompleteArrayInput,
  useLocaleState,
} from 'react-admin';
import { useMemo, useEffect } from 'react';
import { PropertySelectorSelector } from '../interface';
import { useForm, FormProvider } from 'react-hook-form';

export const PropertySelector = ({
  type,
  properties,
  isMultiChoice,
  onSelect,
  name,
  label,
  values,
}: PropertySelectorSelector) => {
  const [lang] = useLocaleState();
  const methods = useForm({ defaultValues: { [name]: values } });
  const list = useMemo(() => {
    if (!type) return properties;

    return properties?.filter((property) => property.type === type);
  }, [JSON.stringify(properties)]);
  const onGenerationPropertySelected = (id: number) => {
    const prop = properties.find((item) => item.id === id);

    onSelect(name, prop ? [prop] : []);
  };
  const onAdditionalPropertySelected = (ids: number[]) => {
    const props = properties.filter((item) => ids.includes(item.id));

    onSelect(name, props);
  };

  const inputProps = {
    fullWidth: true,
    source: name,
    choices: list.map((item) => {
      if (lang === 'en') {
        return {
          id: item.id,
          name: item.name,
        };
      }

      const arabicLocale = item.locales?.find(
        (locale) => locale.language === 'ar'
      );

      return {
        id: item.id,
        name: arabicLocale?.name || item.name,
      };
    }),
    name,
    label,
  };

  useEffect(() => {
    methods.setValue(name, values);
  }, [JSON.stringify(values)]);

  return (
    <FormProvider {...methods}>
      {!isMultiChoice ? (
        <AutocompleteInput
          {...inputProps}
          onChange={onGenerationPropertySelected}
        />
      ) : (
        <AutocompleteArrayInput
          {...inputProps}
          onChange={onAdditionalPropertySelected}
        />
      )}
    </FormProvider>
  );
};
