import { FormDataConsumer, ValidationErrorMessage } from 'react-admin';
import { useTheme } from '@mui/material/styles';
import { useController } from 'react-hook-form';
import { RowInfoBlock, Flex, ResourceInputNumber } from '@UI';
import {
  promocodesTranslationNamespace,
  PROMOCODE_TYPES_CHOICES,
} from '../../../constants';
import { RESOURCES_SCHEMA } from '../../../schemas/resourcesSchema';
import { useIsMobile } from '@Hooks/useIsMobile';
import { MoneyFormatter, maxNum, minNum } from '@Helpers';
import {
  getPrefixedSource,
  getValueBySource,
} from '@Plugins/Promotions/resources/Promocodes/utils';

import { RadioButtonGroupStyled } from '../styled';

export const validateValue = (
  value: string,
  { type }: { type: PromocodeType }
) => {
  if (!(type === 'FIXED' || type === 'PERCENTAGE')) {
    return undefined;
  }

  if (!value) return 'ra.validation.required';

  let error: ValidationErrorMessage | undefined = minNum(1)(value);

  if (error) return error;

  if (type === 'PERCENTAGE') {
    error = maxNum(100)(value);

    if (error) return error;
  }
};

interface Props {
  sourcePrefix?: string;
}

export const Type = ({ sourcePrefix }: Props) => {
  const isMobile = useIsMobile();
  const theme = useTheme();
  const { field: fieldValue } = useController({
    name: getPrefixedSource(RESOURCES_SCHEMA.value, sourcePrefix),
  });
  const handleChange = () => fieldValue.onChange('');

  return (
    <RowInfoBlock asColumn={isMobile} gap={5}>
      <Flex flex={1}>
        <RadioButtonGroupStyled
          $dir={theme.direction}
          onChange={handleChange}
          label={promocodesTranslationNamespace.labels.type}
          name={getPrefixedSource(RESOURCES_SCHEMA.type, sourcePrefix)}
          source={getPrefixedSource(RESOURCES_SCHEMA.type, sourcePrefix)}
          choices={PROMOCODE_TYPES_CHOICES}
          row={false}
          margin="none"
        />
      </Flex>

      <Flex flex={1}>
        <FormDataConsumer>
          {({ formData }) => {
            const type = getValueBySource(formData, 'type', sourcePrefix);

            return (
              ['PERCENTAGE', 'FIXED'].includes(type) && (
                <ResourceInputNumber
                  source={getPrefixedSource('value', sourcePrefix)}
                  name={getPrefixedSource('value', sourcePrefix)}
                  label={promocodesTranslationNamespace.labels.discountValue}
                  prefixSymbol={
                    type === 'FIXED'
                      ? MoneyFormatter.getCurrencySymbol()
                      : undefined
                  }
                  postfixSymbol={type === 'PERCENTAGE' ? '%' : undefined}
                  fullWidth
                  isRequired
                  validate={validateValue}
                />
              )
            );
          }}
        </FormDataConsumer>
      </Flex>
    </RowInfoBlock>
  );
};
