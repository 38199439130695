import { OrdersByStatus } from '../interface';

export const filters = {
  filterNewOrders: function (orders: CorePickingOrderDto[]) {
    return orders.filter((o) => o.status === 'created');
  },

  filterInProgressOrders: function (orders: CorePickingOrderDto[]) {
    return orders.filter((o) => o.status === 'in_progress');
  },

  filterPickingOrders: function (orders: CorePickingOrderDto[]) {
    return orders.filter(
      (o) => o.status === 'in_progress' || o.status === 'packing'
    );
  },

  filterInDeliveryOrders: function (orders: CoreOrder[]) {
    // exclude C&C orders from "in delivery" column
    return orders.filter(
      (order: CoreOrder) =>
        order.status === 'in_delivery' &&
        order.delivery_method !== 'CLICK_AND_COLLECT'
    );
  },

  filterReadyToShipOrders: function (orders: CoreOrder[]) {
    // sort orders by created_at asc
    const readyToShip = orders
      .filter((order: CoreOrder) => order.status === 'ready_to_ship')
      .sort(
        (a: CoreOrder, b: CoreOrder) =>
          new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
      );

    // include C&C orders from in delivery status to show in "ready to ship" column
    const clickAndCollect = orders
      .filter(
        (order: CoreOrder) =>
          order.status === 'in_delivery' &&
          order.delivery_method === 'CLICK_AND_COLLECT'
      )
      .sort(
        (a: CoreOrder, b: CoreOrder) =>
          new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
      );

    // click and collect orders must be last
    return [...readyToShip, ...clickAndCollect];
  },

  splitByStatus: function (
    pickingOrders: CorePickingOrderDto[],
    omsOrders: CoreOrder[]
  ): OrdersByStatus {
    return {
      newOrders: this.filterNewOrders(pickingOrders),
      inProgressOrders: this.filterInProgressOrders(pickingOrders),
      pickingOrders: this.filterPickingOrders(pickingOrders),
      readyToShip: this.filterReadyToShipOrders(omsOrders),
      inDelivery: this.filterInDeliveryOrders(omsOrders),
    };
  },
};
