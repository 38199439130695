import { ReactElement } from 'react';

import { ResourceList } from '@Widgets/ResourceList/ResourceList';
import { useTranslate, CreateButton, useUpdate } from 'react-admin';
import { ListColumn } from '@Widgets/ResourceList/interface';
import { Flex, FileImport, SUPPORTED_FORMATS } from '@UI';
import { useCheckMutationAnswer } from '@Hooks/useCheckMutationAnswer';
import { ResourceRoutes } from '@PluginManager/plugins/resourceRoutes';

interface ProductSupplier {
  id: string;
  name: string;
}

interface ProductCategory {
  id: string;
  name: string;
}

interface Product {
  id: string;
  sku: string;
  name: string;
  shelf_life: number;
  chilled_frozen_fresh: boolean;
  packages: number;
  supplier: ProductSupplier;
  category: ProductCategory;
}

const PRODUCT_LIST_COLUMNS: ListColumn<Product>[] = [
  {
    source: 'sku',
    label: 'replenishment.pages.products.labels.sku',
    type: 'text',
  },
  {
    source: 'name',
    label: 'replenishment.pages.products.labels.name',
    type: 'text',
  },
  {
    // TODO: Use "function" type for getting data from deep paths
    // @ts-ignore
    source: 'supplier.name',
    label: 'replenishment.pages.products.labels.supplier',
    type: 'text',
  },
  {
    // TODO: Use "function" type for getting data from deep paths
    // @ts-ignore
    source: 'category.name',
    label: 'replenishment.pages.products.labels.category',
    type: 'text',
  },
  {
    source: 'shelf_life',
    label: 'replenishment.pages.products.labels.shelf_life',
    type: 'text',
  },
  {
    source: 'chilled_frozen_fresh',
    label: 'replenishment.pages.products.labels.chilled_frozen_fresh',
    type: 'boolean',
  },
  {
    source: 'packages',
    label: 'replenishment.pages.products.labels.packages',
    type: 'text',
  },
  {
    // TODO: fix this "source" value in type definition
    // @ts-ignore
    source: 'writeoff_rule',
    label: 'replenishment.pages.products.labels.writeoff_rule',
    type: 'text',
  },
];

export const ListPage = (): ReactElement => {
  const translate = useTranslate();
  const [update, { isLoading, error, isSuccess }] = useUpdate();

  useCheckMutationAnswer({
    successMessage: 'replenishment.pages.products.messages.successImport',
    errorMessage: 'replenishment.pages.products.errors.errorImport',
    error: error as Error,
    isSuccess,
    shouldUpdateList: true,
  });

  async function onFileSelectHandler(file: File) {
    await update(ResourceRoutes.replenishment.products.resourcePath, {
      data: { file },
      meta: 'load',
    });
  }

  return (
    <ResourceList<Product>
      columns={PRODUCT_LIST_COLUMNS}
      showDeleteButton={false}
      empty={false}
      exporter={false}
      actions={
        <Flex alignItems="center">
          <FileImport
            isLoading={isLoading}
            isSuccess={isSuccess}
            accept={SUPPORTED_FORMATS.csv.toString()}
            onFileSubmitted={onFileSelectHandler}
          />

          <CreateButton />
        </Flex>
      }
      getItemCardCaption={(record) =>
        translate('replenishment.pages.products.labels.mobileCardCaption', {
          id: record.id,
        })
      }
    />
  );
};
