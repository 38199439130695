import { useState, ReactNode, createContext, useContext } from 'react';

import { loadLocales } from '@Helpers';

const noop = () => {};

export interface ILocaleContext {
  locale: string;
  setLocale: (locale: string) => void;
}

export const LocaleContext = createContext<ILocaleContext>({
  locale: loadLocales(),
  setLocale: noop,
});

export const useLocale = () => useContext(LocaleContext);

const LocaleProvider = ({ children }: { children?: ReactNode }) => {
  const [locale, setLocale] = useState<string>(() => loadLocales());

  return (
    <LocaleContext.Provider value={{ locale, setLocale }}>
      {children}
    </LocaleContext.Provider>
  );
};

export default LocaleProvider;
