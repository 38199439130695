import { useState, KeyboardEvent, useRef, useEffect } from 'react';
import { useNotify, useRecordContext, useTranslate } from 'react-admin';
import { useMutation } from 'react-query';
import { Box, Fab } from '@mui/material';
import SendIcon from '@mui/icons-material/ArrowUpward';
import Close from '@mui/icons-material/Close';

import { BackendCodeError } from '@PluginManager/base/AbstractApi/BackendCodeError';

import { commentsCRUD } from './api';
import MessageMeta from './MessageMeta';
import { MessageInputMetaWrapper, MessageInputStyled } from './styled';
import { MessageInputProps } from './interfaces';

const MessageInput = ({
  onNewMessageAdded,
  onMessageUpdated,
  nominatedToEdit,
  onEditClose,
}: MessageInputProps) => {
  const notify = useNotify();
  const translate = useTranslate();

  const [message, setMessage] = useState('');
  const record = useRecordContext<CoreOrder>();

  const inputRef = useRef<HTMLInputElement | null>(null);

  const { mutate: createComment, isLoading: isCreating } = useMutation({
    mutationKey: 'createCommentMutation',
    mutationFn: async (data: any): Promise<Nullable<{ data: CoreComment }>> =>
      commentsCRUD.create({ data }),
    onSuccess: (response: Nullable<{ data: CoreComment }>) => {
      if (!response) {
        return;
      }

      onNewMessageAdded?.(response.data);

      setMessage('');
      inputRef.current?.focus();
    },
    retry: false,
  });
  const { mutate: updateComment, isLoading: isUpdating } = useMutation({
    mutationKey: 'updateCommentMutation',
    mutationFn: async (updateData: { id: string; data: any }) => {
      await commentsCRUD.update({
        id: updateData.id,
        data: updateData.data,
      });

      return updateData;
    },
    onSuccess: ({ id, data }) => {
      onMessageUpdated?.(id, data.content);
      inputRef.current?.focus();
      handleEditClose();
      notify('orders.pages.alerts.updateSuccess', { type: 'success' });
    },
    onError: (error) => {
      if (error instanceof BackendCodeError) {
        notify(`orders.pages.errors.${error.originalMessage}`, {
          type: 'error',
        });
      }
    },
    retry: false,
  });

  const handleSend = () => {
    const content = message.trim();

    if (!content) {
      return;
    }

    if (nominatedToEdit) {
      updateComment({
        id: nominatedToEdit.id,
        data: {
          content,
        },
      });

      return;
    }

    createComment({
      customerId: record.customer_id,
      content,
      metadata: { orderId: [record.order_id, record.id] },
    });
  };

  const handleKyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSend();
    }
  };

  const handleEditClose = () => {
    setMessage('');
    onEditClose?.();
  };

  useEffect(() => {
    if (nominatedToEdit) {
      setMessage(nominatedToEdit.content);
    }
  }, [nominatedToEdit]);

  return (
    <Box pt={1} gap={1} display="flex" alignItems="flex-end">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="stretch"
        flex="1 1 0"
        sx={{ backgroundColor: '#19222D', borderRadius: 1 }}
      >
        {nominatedToEdit && (
          <MessageInputMetaWrapper>
            <MessageMeta
              isActionsEnabled
              comment={nominatedToEdit}
              onClose={handleEditClose}
              DeleteIcon={Close}
            />
          </MessageInputMetaWrapper>
        )}
        <MessageInputStyled
          value={message}
          onKeyUp={handleKyUp}
          onChange={(e) => setMessage(e.target.value)}
          placeholder={translate('orders.pages.alerts.enterCommentPlaceholder')}
          disabled={isCreating || isUpdating}
          ref={inputRef}
          variant="standard"
        />
      </Box>
      <Fab
        size="small"
        color="primary"
        onClick={handleSend}
        disabled={isCreating}
        sx={{ mb: 0.5 }}
      >
        <SendIcon />
      </Fab>
    </Box>
  );
};

export default MessageInput;
