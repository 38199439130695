import { useRecordContext } from 'react-admin';
import { Typography } from '@mui/material';
import { Flex } from '@UI';
import { useIsMobile } from '@Hooks/useIsMobile';

export const PickingItemBarcodes = () => {
  const record = useRecordContext<CorePickingItemDto>();
  const isMobile = useIsMobile();

  const component = (
    <Typography>{record.productBarcodes.join(', ')}</Typography>
  );

  return isMobile ? <Flex asColumn>{component}</Flex> : component;
};
