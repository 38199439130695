import type { SaveAsOptions } from './interface';

export function saveAs(result: BlobPart | BlobPart[], options?: SaveAsOptions) {
  const blob = new Blob(Array.isArray(result) ? result : [result], {
    type: options?.type,
  });

  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');

  link.href = url;
  link.download = options?.filename ?? 'file';
  link.click();
}
