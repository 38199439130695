import { PluginResourceController, CommonCrud } from '@PluginBase';
import { Icon } from '@UI';
import uniqBy from 'lodash/uniqBy';
import { ResourceRoutes } from '../../../resourceRoutes';
import {
  GetListResult,
  GetOneResult,
  CreateResult,
  UpdateResult,
  DeleteResult,
} from 'react-admin';
import { ResourceItemPage } from '@Widgets/ResourceItemPage/ResourceItemPage';

import { ListPage } from './pages/ListPage/ListPage';
import { PropertyFormFields } from './pages/PropertyFormFields/PropertyFormFields';
import { PropertyLanguageTabs } from './pages/PropertyFormFields/interfaces';
import { DEFAULT_FORM_VALUES } from './pages/constants';

const propertiesCrud = new CommonCrud('/catalog/v1/additional_properties');

propertiesCrud.isNewSorting = true;

const ARABIC_LOCALE_INSTANCE: CorePropertyLocale = {
  name: '',
  description: '',
  language: PropertyLanguageTabs.ARABIC,
  choices: [],
};

function formatInputData<T extends Partial<CoreProperty>>(data: T): T {
  const mapChoices = ({ value, label, sorting }: CoreChoiceValueItemProps) => ({
    value: value.trim(),
    label: label.trim(),
    sorting,
  });

  return {
    ...data,
    description: data.description?.trim()?.length
      ? data.description?.trim()
      : undefined,
    sorting: parseInt((data.sorting as unknown as string) || '0', 10) || 0,
    choices: data.choices?.map(mapChoices) ?? [],
    locales:
      data.locales?.map((locale) => ({
        ...locale,
        name: locale.name.trim(),
        description: locale.description.trim(),
        choices: locale.choices.map(mapChoices),
      })) || [],
  };
}

const PropertiesController = new PluginResourceController({
  menuItem: {
    caption: {
      translationKey: 'settings.pages.properties.caption',
    },
    route: ResourceRoutes.properties.routePath,
    icon: <Icon type="property" />,
  },
  resourceRoute: {
    name: ResourceRoutes.properties.resourcePath,
    list: ListPage,
    create: (
      <ResourceItemPage
        type="create"
        defaultFormValues={DEFAULT_FORM_VALUES}
        includeForm={false}
      >
        <PropertyFormFields />
      </ResourceItemPage>
    ),
    edit: (
      <ResourceItemPage type="edit" includeForm={false}>
        <PropertyFormFields />
      </ResourceItemPage>
    ),
  },
  dataProvider: {
    getList: (resource, params): Promise<GetListResult> =>
      propertiesCrud.list(params),
    getOne: async (resource, params): Promise<GetOneResult> => {
      const result = await propertiesCrud.getOne<{ data: CoreProperty }>(
        params
      );

      if (!result?.data) {
        return { data: null };
      }

      const { data } = result;

      data.choices = uniqBy(data.choices, 'value');

      const arabicLocale = {
        ...ARABIC_LOCALE_INSTANCE,
        choices: data.choices,
      };

      if (
        !(
          data.locales?.length === 1 &&
          data.locales[0].language === PropertyLanguageTabs.ARABIC
        )
      ) {
        data.locales = [arabicLocale];
      }

      data.locales = data.locales.map((locale) => ({
        ...locale,
        choices: uniqBy(locale.choices, 'value'),
      }));

      return { data };
    },
    create: async (resource, params): Promise<CreateResult> => {
      const dataToSend = {
        ...params,
        data: formatInputData(params.data),
      };
      const result = await propertiesCrud.create<{ data: CoreProperty }>(
        dataToSend
      );

      const { data } = result || {};

      if (!data) {
        return { data };
      }

      if (!data.locales) {
        data.locales = dataToSend.data.locales;
      }

      return { data };
    },
    update: async (resource, params): Promise<UpdateResult> => {
      const dataToSend = {
        ...params,
        data: formatInputData(params.data),
      };

      await propertiesCrud.update(dataToSend);

      return {
        data: dataToSend.data,
      };
    },
    delete: async (resource, params): Promise<DeleteResult> => {
      await propertiesCrud.delete(params);

      return {
        data: params.previousData,
      };
    },
  },
});

export default PropertiesController;
