import { Plugin } from '@PluginBase';
import Translation from './i18n.json';
import OrderResourceController from './resources/Orders/controller';

const Orders = new Plugin({
  name: 'Orders',
  resources: [OrderResourceController],
  i18n: Translation,
});

export default Orders;
