import { accessPermission } from '@Helpers';
import { CreateButton, useTranslate } from 'react-admin';
import { LIST_COLUMNS } from '@Plugins/Settings/resources/Properties/pages/constants';
import { ResourceList } from '@Widgets/ResourceList/ResourceList';

import { ResourceListStyled } from '../styles';

export const Properties = () => {
  const translate = useTranslate();

  return (
    <ResourceListStyled<typeof ResourceList<CoreProperty>>
      actions={
        accessPermission.company?.isPropertyCreationAvailable && (
          <CreateButton />
        )
      }
      bulkActionButtons={false}
      columns={LIST_COLUMNS}
      getItemCardCaption={(record) =>
        translate('settings.pages.properties.labels.mobileCardCaption', {
          id: record.id,
        })
      }
      showDeleteButton
    />
  );
};
