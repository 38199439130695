import { Drawer, DrawerProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';

type ThemedDrawerProps = DrawerProps;

const ThemedDrawer = (props: ThemedDrawerProps) => {
  const { open, onClose, children } = props;

  const theme = useTheme();

  const anchor = theme.direction === 'rtl' ? 'left' : 'right';

  return (
    <Drawer
      dir={theme.direction}
      open={open}
      anchor={anchor}
      onClose={onClose}
      PaperProps={
        theme.direction === 'rtl'
          ? {
              style: {
                borderRadius: 0,
                left: anchor === 'right' ? 0 : 'unset',
                right: anchor === 'right' ? 'unset' : 0,
              },
            }
          : undefined
      }
    >
      {children}
    </Drawer>
  );
};

export default ThemedDrawer;
