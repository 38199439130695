import {
  CreateResult,
  GetListResult,
  GetManyReferenceResult,
  GetOneResult,
  UpdateResult,
} from 'react-admin';
import { PluginResourceController } from '@PluginBase';
import { Icon } from '@UI';
import { ResourceRoutes } from '../../../resourceRoutes';
import { ListPage } from './pages/ListPage/ListPage';
import { pickingOrdersTranslationSchema } from './constants';
import { PickingOrdersCRUD } from './restApi/PickingOrdersCRUD';
import { ApiActionCall } from './pages/ItemPage/constants';
import { GetManyReferenceMeta, ResourceMeta } from './interface';
import { getPickerSelectOptions, getUniqueOptions } from './dataGenerator';

const pickingOrdersCrud = new PickingOrdersCRUD('/picking/v2/picking-orders');
const pickersCrud = new PickingOrdersCRUD('/picking/v2/pickers');

const WMSPickingOrdersPages = new PluginResourceController({
  menuItem: {
    route: ResourceRoutes.wmsPickingOrders.routePath,
    caption: {
      translationKey: pickingOrdersTranslationSchema.captions.desktop,
    },
    icon: <Icon type="shoppingBasket" />,
  },
  resourceRoute: {
    name: ResourceRoutes.wmsPickingOrders.resourcePath,
    list: ListPage,
  },
  dataProvider: {
    getList: (resource, params): Promise<GetListResult> =>
      pickingOrdersCrud.list(params),
    getOne: async (resource, params): Promise<GetOneResult> => {
      const result = await pickingOrdersCrud.getOne<{
        data: CorePickingOrderDto;
      }>(params);

      return {
        data: result?.data,
      };
    },
    create: async (resource, params): Promise<CreateResult> => {
      const result = await pickingOrdersCrud.create<{
        data: CorePickingOrderDto;
      }>(params);

      return {
        data: result?.data,
      };
    },
    update: async (resource, params): Promise<UpdateResult> => {
      if (params.meta === ApiActionCall.FinishOrder) {
        return pickingOrdersCrud.finish(params);
      }

      await pickingOrdersCrud.update(params);

      return {
        data: { ...params.data, id: params.id },
      };
    },
    getManyReference: async (
      resource,
      params
    ): Promise<GetManyReferenceResult> => {
      const meta: GetManyReferenceMeta = params.meta;

      if (meta === ResourceMeta.Item) {
        return pickingOrdersCrud.getItems({
          ...params,
          filter: {},
        });
      } else if (meta.type === 'getPickers') {
        const result = await pickersCrud.getPickers({
          ...params,
          pagination: {
            page: 1,
            perPage: 1000,
          },
          filter: {},
        });
        const pickerOptions: SelectOption[] = getPickerSelectOptions(
          result.data
        );

        const pickerChoicesWithCurrent = getUniqueOptions(
          pickerOptions.concat(meta.currentPickerOption ?? [])
        );

        return {
          data: pickerChoicesWithCurrent,
          total: pickerChoicesWithCurrent.length,
        };
      }

      return Promise.reject(new Error());
    },
  },
});

export default WMSPickingOrdersPages;
