import { Coords } from 'google-map-react';
import pluginManager from '@PluginManager/PluginManager';

export class Geocoder {
  private _error: Nullable<Error> = null;
  private _address = '';
  private _placeId = '';
  private readonly _coordinates: Coords;

  constructor(position: Coords) {
    this._coordinates = position;
  }

  get error(): Nullable<Error> {
    return this._error;
  }

  get address(): string {
    return this._address;
  }

  get location(): Coords {
    return this._coordinates;
  }

  get googlePlaceId(): string {
    return this._placeId;
  }

  async decode(): Promise<void> {
    const { lng, lat } = this._coordinates;
    const latlng = `${lat},${lng}`;

    try {
      const addressInformation = await pluginManager.dataProvider?.geocodingByCoordinates({ latlng });

      if (addressInformation?.results.length) {
        const { formatted_address, place_id } = addressInformation.results[0];

        this._address = formatted_address;
        this._placeId = place_id;
      }

      this._error = null;
    } catch (e) {
      this._error = e as Error;
    }
  }
}
