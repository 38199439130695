import { createContext, Dispatch, useReducer } from 'react';

import { AnyAction } from '@Helpers/Reducer';

import { reducer, initialValue } from './reducer';
import { ContextProviderProps, ReducerState } from './interfaces';
import { DEFAULT_VALUE } from './constants';

const dummyDispatch = () => {
  // eslint-disable-next-line no-console
  console.warn(
    'There is no dispatch function at `ScheduleStateDispatchContext`'
  );
};

export const StateContext = createContext<ReducerState>({
  state: initialValue,
  dispatch: dummyDispatch,
});

export const ScheduleProvider = (props: ContextProviderProps): JSX.Element => {
  const { initialValue: initial = [], children } = props;

  const computedInitial = initial.length ? initial : DEFAULT_VALUE;

  const [state, dispatch] = useReducer(reducer, computedInitial, (arg) =>
    arg.map((schedule) => ({
      dayOfWeek: schedule.dayOfWeek,
      intervals: schedule.intervals.map((interval, id) => ({
        ...interval,
        id,
      })),
    }))
  );

  return (
    <StateContext.Provider
      value={{ state, dispatch: dispatch as Dispatch<AnyAction> }}
    >
      {children}
    </StateContext.Provider>
  );
};
