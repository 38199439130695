import { UIEmailInputProps } from './interface';
import { useEffect, useState } from 'react';
import { TextField, InputAdornment } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { emailValidator } from '@Helpers';

export const UIEmailInput = (props: UIEmailInputProps) => {
  const { onChange, value, ...other } = props;

  const [isValid, setIsValid] = useState(false);

  const handleChange = (email: string) => {
    const isValid = emailValidator(email);

    setIsValid(isValid);
    onChange({
      email,
      isValid,
    });
  };

  useEffect(() => {
    handleChange(value);
  }, []);

  return (
    <TextField
      value={value}
      type="email"
      onChange={(e) => handleChange(e.currentTarget.value || '')}
      InputProps={{
        endAdornment: isValid && (
          <InputAdornment position="end">
            <CheckIcon color="primary" />
          </InputAdornment>
        ),
      }}
      {...other}
    />
  );
};
