import { AutocompleteInput, useTranslate } from 'react-admin';
import { OptionTextStyled } from './styles';
import { ResourceAutocompleteInputProps } from './interface';
import { useRef } from 'react';
import { useTruncate } from './useTruncate';
import { Box } from '@mui/material';

export const ResourceAutocompleteInput = ({
  truncate,
  translateChoice,
  optionText = 'name',
  optionValue = 'id',
  ...rest
}: ResourceAutocompleteInputProps) => {
  const AutocompleteInputWrapperRef = useRef<HTMLDivElement>(null);
  const translate = useTranslate();
  const width = useTruncate(AutocompleteInputWrapperRef?.current?.getBoundingClientRect().width, truncate);
  const matchSuggestionHandler = (filter: string, suggestion: SelectOption) => {
    const choice = translateChoice ? translate(suggestion[optionText as 'name']) : suggestion[optionText as 'name'];

    return choice?.toLowerCase().includes(filter.toLowerCase());
  };
  const optionTextHandler = (choice: SelectOption) => {
    return (
      <OptionTextStyled truncate={truncate ?? width ?? 350} key={choice[optionValue as 'id']}>
        {translateChoice ? translate(choice[optionText as 'name']) : choice[optionText as 'name']}
      </OptionTextStyled>
    );
  };
  const inputTextHandler = (choice: SelectOption) => {
    return translateChoice ? translate(choice[optionText as 'name']) : choice[optionText as 'name'];
  };

  return (
    <Box ref={AutocompleteInputWrapperRef} width='100%'>
      <AutocompleteInput
        {...rest}
        optionValue={optionValue}
        optionText={optionTextHandler}
        inputText={inputTextHandler}
        matchSuggestion={matchSuggestionHandler}
      />
    </Box>
  );
};
