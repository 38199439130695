import styled from 'styled-components';

export const FeedsPointStyled = styled.div<{
  color: string;
  $dir: 'ltr' | 'rtl';
}>`
  min-width: 20px;
  width: 20px;
  height: 20px;
  background: ${({ color }) => color};
  border-radius: 100px;
  margin-${({ $dir }) => ($dir === 'ltr' ? 'right' : 'left')}: 8px;
  z-index: 2;
`;

export const FeedsContainedStyled = styled.div<{
  color: string;
  $dir: 'ltr' | 'rtl';
}>`
  position: relative;
  ::before {
    background: ${({ color }) => color};
    bottom: 0;
    content: '';
    height: 100%;
    position: absolute;
    top: 0;
    width: 2px;
    ${({ $dir }) => ($dir === 'ltr' ? 'left' : 'right')}: 25px;
  }
`;
