import { required, number, Validator } from 'react-admin';
import { FieldValues } from 'react-hook-form';
import format from 'date-fns/format';
import subDate from 'date-fns/sub';

import { isInteger, minNum, pointsToUnits } from '@Helpers';
import { TIME_DISPLAY_FORMAT } from '@Widgets/Schedule/DayWorkInterval/constants';

const formatTime = (date: Date): string => {
  return format(date, TIME_DISPLAY_FORMAT);
};

export const convertTimeToSeconds = (
  time: Date | string,
  message = 'ra.validation.required'
): number => {
  if (typeof time !== 'string') {
    time = formatTime(time);
  }

  const [hours, minutes] = time.split(':');

  if (!hours || !minutes) {
    throw new Error(message);
  }

  const hoursInSeconds = Number(hours) * 60 * 60;
  const minutesInSeconds = Number(minutes) * 60;

  return hoursInSeconds + minutesInSeconds;
};

export const comparePeriod = (
  message: string,
  deliveryStartTime: string,
  deliveryEndTime: string
): ReturnType<Validator> => {
  if (!deliveryEndTime || !deliveryStartTime) {
    return;
  }

  try {
    const startTime = convertTimeToSeconds(deliveryStartTime);
    const endTime = convertTimeToSeconds(deliveryEndTime);

    if (startTime >= endTime) {
      return {
        message,
        args: {
          startTime: 'Delivery start time',
          endTime: 'Delivery end time',
        },
      };
    }
  } catch (e: unknown) {
    const error = e as Error;

    return error.message;
  }
};

export const validateDeliveryStartTime =
  (message = 'ra.validation.validTimeRange') =>
  (deliveryStartTime: string, values: FieldValues): ReturnType<Validator> => {
    const { delivery_end_time: deliveryEndTime } = values;

    return comparePeriod(message, deliveryStartTime, deliveryEndTime);
  };

export const validateDeliveryEndTime =
  (message = 'ra.validation.validTimeRange') =>
  (deliveryEndTime: string, values: FieldValues): ReturnType<Validator> => {
    const { delivery_start_time: deliveryStartTime } = values;

    return comparePeriod(message, deliveryStartTime, deliveryEndTime);
  };

export const validateCutOffTime =
  (message = 'ra.validation.invalidCutOffTime') =>
  (cutOffTime: string, values: FieldValues): ReturnType<Validator> => {
    if (!cutOffTime) {
      return;
    }

    const {
      delivery_lead_duration_sec,
      wh_processing_duration_sec,
      delivery_start_time,
    } = values;

    if (!delivery_start_time) {
      return;
    }

    const duration =
      typeof wh_processing_duration_sec === 'number'
        ? wh_processing_duration_sec
        : Number(wh_processing_duration_sec || '0');
    const leadTime =
      typeof delivery_lead_duration_sec === 'number'
        ? delivery_lead_duration_sec
        : Number(delivery_lead_duration_sec || '0');

    const processingDuration = duration + leadTime;
    const [hours, minutes] = delivery_start_time.split(':');

    const startDate = new Date();

    startDate.setHours(Number(hours));
    startDate.setMinutes(Number(minutes));

    const cutOffDate = subDate(startDate, { minutes: processingDuration });

    const cutOffTimeLimit = convertTimeToSeconds(cutOffDate);
    const actualCutOffTime = convertTimeToSeconds(cutOffTime);

    if (actualCutOffTime > cutOffTimeLimit) {
      return {
        message,
        args: {
          time: formatTime(cutOffDate),
        },
      };
    }
  };

export const validationSchema = {
  name: [required()],
  delivery_lead_duration_sec: [required(), number(), isInteger(), minNum(0)],
  cut_off_time: [required(), validateCutOffTime()],
  wh_processing_duration_sec: [required(), number(), isInteger(), minNum(0)],
  delivery_start_time: [required(), validateDeliveryStartTime()],
  delivery_end_time: [required(), validateDeliveryEndTime()],
  delivery_price: [required(), number(), minNum(0)],
  delivery_capacity: [required(), number(), isInteger(), minNum(1)],
  free_delivery_threshold: [required(), number(), minNum(0)],
  order_amount_threshold: [minNum(parseFloat(pointsToUnits(0)))],
};
