import {
  Filter,
  ListColumn,
  ResourceListPropsSort,
} from '@Widgets/ResourceList/interface';
import { formatEntityName } from '@ROOT/helpers/FormatEntityName/FormatEntityName';
import { StatusBadgeResource } from '../share/StatusBadgeResource/StatusBadgeResource';

export const apiUrl = '/dc/v1/picking-orders';

export enum ResourceMeta {
  PICKING_ORDERS = 'PICKING_ORDERS',
  PRODUCTS = 'PRODUCTS',
}

export const DEFAULT_SORTING: ResourceListPropsSort = {
  field: 'createdAt',
  order: 'DESC',
};

export const PER_PAGE = 10;

export const FILTERS: Filter[] = [
  {
    source: 'displayId',
    label: 'distributionCenter.pages.pickingOrders.labels.pickingOrder',
    type: 'text',
  },
  {
    source: 'status',
    type: 'choose-multi',
    choices: [
      { id: 'not_started', name: 'distributionCenter.statuses.not_started' },
      { id: 'picking', name: 'distributionCenter.statuses.picking' },
      { id: 'finished', name: 'distributionCenter.statuses.finished' },
      { id: 'canceled', name: 'distributionCenter.statuses.canceled' },
    ],
    label: 'distributionCenter.pages.pickingOrders.labels.status',
  },
  {
    source: 'sourceWarehouse',
    label: 'distributionCenter.pages.pickingOrders.labels.sourceWarehouse',
    type: 'text',
  },
  {
    source: 'destinationWarehouse',
    label: 'distributionCenter.pages.pickingOrders.labels.destinationWarehouse',
    type: 'text',
  },
];

export const PICKING_ORDER_LIST_COLUMNS: ListColumn<CorePickingOrders>[] = [
  {
    source: 'displayId',
    label: 'distributionCenter.pages.pickingOrders.labels.pickingOrder',
    type: 'function',
    function: (record) => formatEntityName('PICK', 8, record.displayId),
    sortable: false,
  },
  {
    source: 'sourceWarehouse',
    label: 'distributionCenter.pages.pickingOrders.labels.sourceWarehouse',
    type: 'text',
    sortable: false,
  },
  {
    source: 'destinationWarehouse',
    label: 'distributionCenter.pages.pickingOrders.labels.destinationWarehouse',
    type: 'text',
    sortable: false,
  },
  {
    source: 'status',
    label: 'distributionCenter.pages.pickingOrders.labels.status',
    type: 'custom',
    customComponent: <StatusBadgeResource key="status" />,
    sortable: false,
  },
  {
    source: 'demandQuantity',
    label: 'distributionCenter.pages.pickingOrders.labels.demandQuantity',
    type: 'text',
    sortable: false,
  },
  {
    source: 'pickedQuantity',
    label: 'distributionCenter.pages.pickingOrders.labels.pickedQuantity',
    type: 'text',
    sortable: false,
  },
  {
    source: 'cagesQuantity',
    label: 'distributionCenter.pages.pickingOrders.labels.cagesQuantity',
    type: 'text',
    sortable: false,
  },
  {
    source: 'createdAt',
    label: 'distributionCenter.pages.pickingOrders.labels.createdAt',
    type: 'date',
    showTime: true,
  },
  {
    source: 'startedAt',
    label: 'distributionCenter.pages.pickingOrders.labels.startedAt',
    type: 'date',
    sortable: false,
    showTime: true,
  },
  {
    source: 'finishedAt',
    label: 'distributionCenter.pages.pickingOrders.labels.finishedAt',
    type: 'date',
    sortable: false,
    showTime: true,
  },
];

export const PRODUCT_LINE_LIST_COLUMNS: ListColumn<CorePickingOrderProductLine>[] =
  [
    {
      source: 'productName',
      label: 'distributionCenter.pages.pickingOrders.labels.product',
      type: 'function',
      function: (record) => {
        return `[${record.productSku}] ${record.productName}`;
      },
      sortable: false,
    },
    {
      source: 'demandQuantity',
      label: 'distributionCenter.pages.pickingOrders.labels.requested',
      type: 'text',
      sortable: false,
    },
    {
      source: 'pickedQuantity',
      label: 'distributionCenter.pages.pickingOrders.labels.picked',
      type: 'text',
      sortable: false,
    },
  ];

export const CAGES_LIST_COLUMNS: ListColumn<CorePickingOrderCagesEl>[] = [
  {
    source: 'cage',
    label: 'distributionCenter.pages.pickingOrders.labels.product',
    type: 'function',
    function: (record) => {
      return `${record.cage.barcode}${record.isExcluded ? ' - Excluded' : ''}`;
    },
    sortable: false,
  },
  {
    source: 'productLines',
    label: 'distributionCenter.pages.pickingOrders.labels.picked',
    type: 'function',
    function: (record) => {
      return (
        record.productLines?.reduce((s, l) => (s += l.pickedQuantity), 0) ?? 0
      );
    },
    sortable: false,
  },
];

export const PRODUCT_LIST_COLUMNS: ListColumn<CorePickingOrderCagesProductLinesEl>[] =
  [
    {
      source: 'productName',
      label: 'distributionCenter.pages.pickingOrders.labels.product',
      type: 'function',
      function: (record) => {
        return `[${record.productSku}] ${record.productName}`;
      },
      sortable: false,
    },
    {
      source: 'pickedQuantity',
      label: 'distributionCenter.pages.pickingOrders.labels.picked',
      type: 'text',
      sortable: false,
    },
  ];
