import axios, { AxiosResponse } from 'axios';
import { REQUEST_TIMEOUT, GOOGLE_MAP_API_ROOT, GOOGLE_API_KEY } from './config';
import { GoogleMapGeocodeReguests } from './interface';

const axiosInstance = axios.create({
  baseURL: GOOGLE_MAP_API_ROOT,
  timeout: REQUEST_TIMEOUT,
});

const responseBody = ({ data }: AxiosResponse) => data;

export const GoogleMapGeocode: GoogleMapGeocodeReguests = {
  geocodingByCoordinates: ({ latlng }) =>
    axiosInstance
      .get(`geocode/json?language=en-GB&key=${GOOGLE_API_KEY}&latlng=${latlng}`)
      .then(responseBody),
};
