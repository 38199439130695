import { ListColumn } from '@Widgets/ResourceList/interface';
import { Delivery } from '@Plugins/Replenishment/resources/FutureDeliveries/interface';

export const LIST_COLUMNS: ListColumn<Delivery & any>[] = [
  {
    source: 'store.name',
    label: 'replenishment.pages.futureDeliveries.labels.store',
    type: 'text',
  },
  {
    source: 'product.sku',
    label: 'replenishment.pages.futureDeliveries.labels.product',
    type: 'text',
  },
  {
    source: 'date',
    label: 'replenishment.pages.futureDeliveries.labels.date',
    type: 'date',
  },
  {
    source: 'unit',
    label: 'replenishment.pages.futureDeliveries.labels.unit',
    type: 'text',
  },
];
