import { useEffect, useState } from 'react';
import { FormTabStyled } from '@Widgets/styles';
import { ResourceTabProps } from '../interface';
import { useFormContext } from 'react-hook-form';
import { useTheme } from '@mui/material/styles';

export const ResourceTab = ({
  children,
  subscribedFields,
  value,
  onSubscribedFieldError,
  ...rest
}: ResourceTabProps) => {
  const {
    formState: { isValid, errors },
  } = useFormContext();
  const theme = useTheme();
  const [errorColor, setErrorColor] = useState<string | undefined>();

  useEffect(() => {
    if (isValid || !subscribedFields.length) {
      setErrorColor(undefined);

      return;
    }

    let color: string | undefined;
    const properties = Object.keys(errors);

    for (const fieldName of subscribedFields) {
      if (properties.includes(fieldName)) {
        color = theme.palette.error.main;

        break;
      }
    }

    setErrorColor(color);

    if (color && onSubscribedFieldError && value) {
      onSubscribedFieldError(`${value}`);
    }
  }, [isValid, JSON.stringify(errors)]);

  return (
    <FormTabStyled {...rest} value={value} $errorColor={errorColor}>
      {children}
    </FormTabStyled>
  );
};
