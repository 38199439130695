import { AxiosError } from "axios";

import { ValidationError } from "@PluginManager/base/AbstractApi/ValidationError";
import { BackendCodeError } from "@PluginManager/base/AbstractApi/BackendCodeError";
import translationKeys from "@ROOT/i18n/en";
import { UploadedContentError } from "@PluginManager/base/AbstractApi/UploadedContentError";
import { AuthorizationError } from "@PluginManager/base/AbstractApi/AuthorizationError";

const errorHandler = (error: unknown) => {
  if (error instanceof AxiosError && error.response) {
    const { data, status } = error.response;

    if (data.fields || data.error?.fields) {
      throw new ValidationError({
        fields: data.fields || data.error?.fields,
      });
    }

    if (status === 422 && (data.message || data.error)) {
      const messages = data.message || data.error;

      if (Array.isArray(messages)) {
        throw new ValidationError(messages);
      } else {
        throw new BackendCodeError({
          payload: { message: messages, status },
        });
      }
    }

    if (data.error?.payload?.message || data.code) {
      throw new BackendCodeError({
        payload: {
          message: data.error?.payload?.message || data.code,
          status,
        },
      });
    }

    if (
      Object.keys(translationKeys.uploadedContentErrors).includes(data.code)
    ) {
      throw new UploadedContentError(data.code);
    }

    if (data.message) {
      throw new Error(data.message);
    }
  }

  if (error instanceof AuthorizationError) {
    throw error;
  }

  throw new Error((error as Error).message);
}

export default errorHandler;
