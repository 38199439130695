import {
  GetListResult,
  GetOneResult,
  CreateResult,
  UpdateResult,
  UpdateManyResult,
} from 'react-admin';
import { PluginResourceController } from '@PluginBase';
import { Icon } from '@UI';
import { saveAs } from '@Helpers';
import { ResourceRoutes } from '../../../resourceRoutes';
import { ListPage } from './pages/ListPage';
import { pickersTranslationSchema } from './constants';
import { PickersCRUD } from './restApi/PickersCRUD';
import { ResourceItemPage } from '@Widgets/ResourceItemPage/ResourceItemPage';
import { PageForm } from './pages/PageForm';
import { AuthorizationError } from '@PluginManager/base/AbstractApi/AuthorizationError';
import authProvider from '../../../../../providers/authProvider/authProvider';
import { ROUTES } from '@CoreRoutes';

const pickersCrud = new PickersCRUD('/picking/v2/pickers');

const WMSPickingOrdersPages = new PluginResourceController({
  menuItem: {
    route: ResourceRoutes.wmsPickers.routePath,
    caption: {
      translationKey: pickersTranslationSchema.captions.desktop,
    },
    icon: <Icon type="people" />,
  },
  resourceRoute: {
    name: ResourceRoutes.wmsPickers.resourcePath,
    list: ListPage,
    edit: (
      <ResourceItemPage type="edit" includeForm={false}>
        <PageForm />
      </ResourceItemPage>
    ),
  },
  dataProvider: {
    getList: (resource, params): Promise<GetListResult> =>
      pickersCrud.list(params),
    getOne: async (resource, params): Promise<GetOneResult> => {
      const result = await pickersCrud.getOne<{ data: CorePickerDto }>(params);

      return {
        data: result?.data,
      };
    },
    create: async (resource, params): Promise<CreateResult> => {
      const result = await pickersCrud.create<{ data: CorePickerDto }>(params);

      return {
        data: result?.data,
      };
    },
    update: async (resource, params): Promise<UpdateResult> => {
      await pickersCrud.update(params);

      return {
        data: params.data,
      };
    },
    updateMany: async (resource, params): Promise<UpdateManyResult> => {
      try {
        const result = await pickersCrud.generateBadges(params.ids as string[]);

        saveAs(result, {
          type: 'application/pdf',
          filename: 'badges.pdf',
        });
      } catch (error) {
        if (error instanceof AuthorizationError) {
          authProvider
            .logout({})
            .then(() => {
              window.history.go(-1);
              window.history.pushState(null, '', `/#${ROUTES.login.route}`);
              window.history.go(1);
            })
            .catch(console.error);
        }
      }

      return {
        data: params.ids,
      };
    },
  },
});

export default WMSPickingOrdersPages;
