import { PluginResourceController } from '@PluginBase';
import { Icon } from '@UI';
import { ResourceRoutes } from '../../../resourceRoutes';
import { MonitorPage } from './pages/MonitorPage';
import { monitorTranslationSchema } from './constants';
import { GetListResult } from 'react-admin';
import pluginManager from '@PluginManager/PluginManager';
import { filters } from './helpers/filters';

const WMSMonitorPages = new PluginResourceController({
  menuItem: {
    route: ResourceRoutes.wmsMonitor.routePath,
    caption: {
      translationKey: monitorTranslationSchema.captions.desktop,
    },
    icon: <Icon type="monitor" />,
  },
  resourceRoute: {
    name: ResourceRoutes.wmsMonitor.resourcePath,
    list: MonitorPage,
  },
  customRoutes: [
    {
      noLayout: true,
      path: ResourceRoutes.wmsMonitor.customPath,
      element: <MonitorPage />,
    },
  ],
  dataProvider: {
    getList: async (resource, params): Promise<GetListResult> => {
      const {
        filter: { warehouseCode },
        pagination,
        sort,
      } = params;

      try {
        const [{ data: pickingOrders = [] }, { data: omsOrders = [] }] =
          await Promise.all([
            pluginManager.dataProvider.getList<CorePickingOrderDto>(
              ResourceRoutes.wmsPickingOrders.resourcePath,
              {
                filter: {
                  status: ['created', 'in_progress', 'packing'],
                  warehouseCode,
                },
                pagination,
                sort,
              }
            ),
            pluginManager.dataProvider.getList<CoreOrder>(
              ResourceRoutes.orders.resourcePath,
              {
                filter: {
                  status: ['in_delivery', 'ready_to_ship'],
                  warehouse_code: warehouseCode,
                },
                pagination,
                sort,
              }
            ),
          ]);

        const pickingOrdersOmsIds = pickingOrders.map((po) => po.orderId || '');
        const additionalOrdersOmsIds = omsOrders
          .map((order) => order.order_id)
          .filter((c) => !pickingOrdersOmsIds.includes(c));

        const { data: additionalOrders = [] } =
          await pluginManager.dataProvider.getList<CorePickingOrderDto>(
            ResourceRoutes.wmsPickingOrders.resourcePath,
            {
              filter: {
                warehouseCode,
                orderId: additionalOrdersOmsIds.length
                  ? additionalOrdersOmsIds
                  : null,
              },
              pagination,
              sort,
            }
          );

        const orders = filters.splitByStatus(
          [...pickingOrders, ...additionalOrders],
          omsOrders.map((order) => {
            const pickingOrder = additionalOrders.find(
              (c) => c.orderId === order.order_id
            );

            return {
              ...order,
              outboundShelf:
                pickingOrder?.outboundShelf?.name ??
                pickingOrder?.outboundShelf?.barcode ??
                null,
            };
          })
        );

        return {
          data: [{ ...orders, id: 1 }],
          total: 1,
        };
      } catch (error) {
        return {
          data: [],
          total: 0,
        };
      }
    },
  },
});

export default WMSMonitorPages;
