import { Schema } from "type-fest";

// TODO: remove all @ts-ignore
export const RESOURCES_SCHEMA = {
  // @ts-ignore
  name: 'name',
  orderId: 'orderId',
  warehouse: 'warehouseCode',
  picker: 'pickerId',
  status: 'status',
  createdAt: 'createdAt',
  startedAt: 'startedAt',
  startAfter: 'slaStartAfter',
  completedAt: 'completedAt',
  shipBy: 'slaShipBy',
  items: 'items',
  search: 'search',
  pickingItem: {
    image: 'image',
    sku: 'productSku',
    name: 'productName',
    quantityToPick: 'quantityToPick',
    quantityPicked: 'quantityPicked',
    barcodes: 'productBarcodes',
  },
} satisfies Schema<CorePickingOrderDto, keyof CorePickingOrderDto>;
