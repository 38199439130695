import { FunctionColumn, ListColumn } from '@Widgets/ResourceList/interface';
import {
  ColWithWarehouse,
  CoreOutboundDemandOrderModified,
  WarehousesQuantities,
} from './constants';

export const getAllDestinationWarehouseNames = (orderItems: CoreDCOrder[]) =>
  Array.from(
    new Set(orderItems.map(({ destinationWarehouse }) => destinationWarehouse))
  );

interface CurrentAndOldQuantitiesBlockProps {
  oldQuantity: number;
  quantity: number;
}

const CurrentAndOldQuantitiesBlock = ({
  oldQuantity = 0,
  quantity = 0,
}: CurrentAndOldQuantitiesBlockProps) => {
  const oldQuantityBlock =
    oldQuantity && oldQuantity !== quantity ? (
      <>
        <span>&nbsp;/&nbsp;</span>
        <span style={{ color: 'red' }}>
          <s>{oldQuantity}</s>
        </span>
      </>
    ) : null;

  return (
    <>
      <span>
        <b>{quantity}</b>
      </span>
      {oldQuantityBlock}
    </>
  );
};

export const getColsWithWarehouses = (
  orderItems: CoreDCOrder[]
): ListColumn<ColWithWarehouse>[] => {
  const allDestinationWarehouseNames: string[] =
    getAllDestinationWarehouseNames(orderItems);
  const destinationWarehouseCols: FunctionColumn[] =
    allDestinationWarehouseNames.map((warehouseName) => ({
      source: warehouseName,
      label: warehouseName,
      type: 'function',
      function: (record: WarehousesQuantities) => {
        const { oldQuantity = 0, quantity = 0 } = record[warehouseName];

        return (
          <CurrentAndOldQuantitiesBlock
            quantity={+quantity}
            oldQuantity={+oldQuantity}
          />
        );
      },
      sortable: false,
    }));

  return [
    {
      source: 'productName',
      label: 'distributionCenter.pages.outboundOrders.labels.product',
      type: 'text',
      sortable: false,
    },
    ...destinationWarehouseCols,
    {
      source: 'totalQuantity',
      label: 'distributionCenter.pages.outboundOrders.labels.total',
      type: 'function',
      function: (record: WarehousesQuantities) => {
        const totalQuantity: number = record.totalQuantity as any as number;
        const totalOldQuantities = allDestinationWarehouseNames.reduce(
          (memo, warehouseName) =>
            memo + Number(record?.[warehouseName]?.oldQuantity || 0),
          0
        );

        return (
          <CurrentAndOldQuantitiesBlock
            quantity={totalQuantity}
            oldQuantity={totalOldQuantities}
          />
        );
      },
      sortable: false,
    },
    {
      source: 'availableQuantity',
      label: 'distributionCenter.pages.outboundOrders.labels.availableQuantity',
      type: 'text',
      sortable: false,
    },
    {
      source: 'available',
      label: 'distributionCenter.pages.outboundOrders.labels.available',
      type: 'text',
      sortable: false,
    },
  ];
};

const getAvailablePercent = (count: number, totalAvailableQty: number) => {
  const wrongValStr = '-';

  if (count < 0) {
    return wrongValStr;
  }

  const percent = ((count * 100) / totalAvailableQty).toFixed(1);

  const formatted = Math.min(Number(percent), 100);

  return Number.isNaN(formatted) ? wrongValStr : formatted;
};

export const calcQuantities = (
  orders: CoreOutboundDemandOrder[],
  isSyncFailed: boolean,
  warehouseNames: string[]
): CoreOutboundDemandOrderModified[] =>
  orders.map((currOrder, orderIdx) => {
    const totalQuantity =
      currOrder.totalQuantity - currOrder.totalQuantityReserved;
    const disabledWarehouses = Array.from(
      new Set(
        currOrder.quantities
          .filter(({ isCreated }) => isCreated)
          .map(({ destinationWarehouse }) => destinationWarehouse)
      )
    );
    const warehousesQuantities: WarehousesQuantities = warehouseNames.reduce(
      (memoWHQuantities: WarehousesQuantities, warehouseName) => {
        const relatedQuantities = currOrder.quantities.filter(
          ({ destinationWarehouse }) => destinationWarehouse === warehouseName
        );
        const goalQuantity: number = relatedQuantities
          .map(({ quantity }) => quantity)
          .reduce((memo, quantity = 0) => memo + quantity, 0);
        const goalQuantityReserved: number = relatedQuantities
          .map(({ quantityReserved }) => quantityReserved)
          .reduce((memo, quantity = 0) => memo + quantity, 0);
        const goalQuantityOld: number = relatedQuantities
          .map(({ oldQuantity }) => oldQuantity)
          .reduce((memo, quantity = 0) => memo + quantity, 0);

        return {
          ...memoWHQuantities,
          [warehouseName]: {
            quantity: goalQuantity - goalQuantityReserved,
            oldQuantity: goalQuantityOld,
          },
        };
      },
      {}
    );

    return {
      ...warehousesQuantities,
      id: orderIdx,
      productName: `[${currOrder.productSku}] ${currOrder.productName}`,
      isSyncFailed,
      totalQuantity: totalQuantity || '-',
      disabledWhList: disabledWarehouses,
      available: getAvailablePercent(
        currOrder.availableQuantity,
        currOrder.totalQuantityOriginal - currOrder.totalQuantityReserved
      ),
      availableQuantity: currOrder.availableQuantity,
    };
  });
