export function isStandalone() {
  if (
    typeof process !== 'undefined' &&
    typeof process.versions === 'object' &&
    'electron' in process.versions
  ) {
    return true;
  }

  if (
    typeof navigator === 'object' &&
    typeof navigator.userAgent === 'string' &&
    navigator.userAgent.includes('Electron')
  ) {
    return true;
  }

  if (
    typeof navigator === 'object' &&
    typeof navigator.userAgent === 'string' &&
    navigator.userAgent.includes('wv')
  ) {
    return true;
  }

  return false;
}
