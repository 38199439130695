import { required, useTranslate } from 'react-admin';
import { useState, useEffect, useMemo } from 'react';
import { Gmap } from '@Widgets/Gmap/Gmap';
import { OnDrawingCompleteProps } from '@Widgets/Gmap/interface';
import { useController } from 'react-hook-form';
import { Coords } from 'google-map-react';
import { MAP_CENTER } from '@Widgets/Gmap/constants';
import {
  Flex,
  ResourceColorInput,
  ResourceInputNumber,
  UIErrorTypography,
} from '@UI';
import { minNum } from '@Helpers';
import { Box } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { DeliveryAreaMapProps } from './interface';

const checkDuplicates =
  () =>
  (
    value: string,
    {
      existingAreas,
      shipping_method_id,
    }: { existingAreas: CoreDeliveryAreaEntity[]; shipping_method_id: string }
  ) => {
    return existingAreas.some(
      (area) =>
        area.shipping_method_id === shipping_method_id &&
        area.priority === parseInt(value, 10)
    )
      ? 'stores.warehouses.pages.tabs.deliveryArea.errors.priorityDuplicate'
      : undefined;
  };
const validatePriority = [required(), minNum(1), checkDuplicates()];

export const DeliveryAreaMap = ({
  location,
  isTabActive,
}: DeliveryAreaMapProps) => {
  const { latitude, longitude } = location || {};
  const translate = useTranslate();
  const {
    field: { value: fieldColorVal },
  } = useController({ name: 'color' });
  const {
    field: { value: fieldPriorityVal },
  } = useController({ name: 'priority' });
  const {
    field: { value: fieldPointsVal, onChange: fieldPointsChange },
    fieldState: { error: fieldPointsError },
  } = useController({
    name: 'points',
    rules: {
      required: translate(
        'stores.warehouses.pages.tabs.deliveryArea.errors.isAreaRequired'
      ),
    },
  });
  const [currentPoints, setCurrentPoints] = useState<Coords[]>();
  const decodePolygon = (points: CorePolygonPoint[]) => {
    if (!points?.length) return undefined;

    return points.map(({ latitude, longitude }) => ({
      lat: parseFloat(latitude),
      lng: parseFloat(longitude),
    }));
  };
  const onPolygonChangeHandler = ({ coords, type }: OnDrawingCompleteProps) => {
    const result =
      type === 'polygon'
        ? coords.map(({ lng, lat }) => ({ latitude: lat, longitude: lng }))
        : undefined;

    fieldPointsChange(result);
  };
  const mapCenter = useMemo(() => {
    let center = MAP_CENTER;

    if (latitude !== undefined && longitude !== undefined)
      center = { lat: latitude, lng: longitude };

    return center;
  }, [latitude, longitude]);
  const markers = useMemo(() => {
    const markers = [];

    if (latitude !== undefined && longitude !== undefined) {
      markers.push({
        icon: <LocationOnIcon color="primary" />,
        lat: latitude,
        lng: longitude,
        key: 'oldMarker',
      });
    }

    return markers;
  }, [latitude, longitude]);

  useEffect(() => {
    setCurrentPoints(decodePolygon(fieldPointsVal));
  }, [JSON.stringify(fieldPointsVal)]);

  return (
    <>
      <Flex asColumn mt={2}>
        <ResourceColorInput
          name="color"
          labelKey="stores.warehouses.pages.tabs.deliveryArea.drawer.label.color"
          isRequired
        />
      </Flex>

      <ResourceInputNumber
        source="priority"
        name="priority"
        label="stores.warehouses.pages.tabs.deliveryArea.drawer.label.priority"
        isInteger
        fullWidth
        validate={validatePriority}
        sx={{ my: 2 }}
      />

      {isTabActive && (
        <Gmap
          showZones
          center={mapCenter}
          onDrawingComplete={onPolygonChangeHandler}
          markers={markers}
          drawOptions={{
            strokeColor: fieldColorVal,
            types: ['polygon'],
            priority: fieldPriorityVal,
            polygonPath: currentPoints,
          }}
          isError={!!fieldPointsError?.message?.length}
        />
      )}

      {!!fieldPointsError?.message?.length && (
        <Box mt={1}>
          <UIErrorTypography>{fieldPointsError?.message}</UIErrorTypography>
        </Box>
      )}
    </>
  );
};
