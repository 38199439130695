import { useController } from 'react-hook-form';
import { useTranslate } from 'react-admin';
import { ScheduleProps, ScheduleDay } from '@Widgets/Schedule/interface';
import { ScheduleView } from '@Widgets/Schedule/ScheduleView';
import { Flex, UIErrorTypography } from '@UI';
import { storiesTranslationNamespace } from '../../../constants';
import { RESOURCES_SCHEMA } from '../../../schemas/resourcesSchema';

export const Schedule = ({
  alwaysMobile,
  intervalsQuantity,
}: ScheduleProps) => {
  const translate = useTranslate();
  const scheduleValidator = (value: CoreScheduleDay[]) => {
    return value.some(({ intervals }) => !intervals?.length)
      ? translate(storiesTranslationNamespace.errors.notEnoughIntervals)
      : undefined;
  };
  const scheduleController = useController({
    name: RESOURCES_SCHEMA.schedules.all,
    rules: { validate: scheduleValidator },
  });
  const onUpdateScheduleHandler = (values: ScheduleDay[]) =>
    scheduleController.field.onChange(values);

  return (
    <Flex asColumn fullWidth>
      <ScheduleView
        value={scheduleController.field.value}
        onUpdateValue={onUpdateScheduleHandler}
        intervalsQuantity={intervalsQuantity}
        alwaysMobile={alwaysMobile}
      />

      {!!scheduleController.fieldState.error?.message && (
        <UIErrorTypography>
          {scheduleController.fieldState.error.message}
        </UIErrorTypography>
      )}
    </Flex>
  );
};
