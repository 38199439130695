import {
  TextInput,
  useRecordContext,
  useGetManyReference,
  useTranslate,
} from 'react-admin';
import {
  ResourceSwitcher,
  ResourceAutocompleteInput,
  UIErrorTypography,
} from '@UI';
import { FormProps } from '../../../../../../hooks/interface';
import { ResourceFormErrorBoundary } from '@Widgets/ResourceFormErrorBoundary/ResourceFormErrorBoundary';
import { getAllTimezones } from 'countries-and-timezones';
import { accessPermission } from '@Helpers';
import { useController } from 'react-hook-form';
import { Box } from '@mui/material';
import { ResourceRoutes } from '@PluginManager/plugins/resourceRoutes';
import { ResourceMeta } from '../../../../../constants';

import {
  validateName,
  validateNameWithoutOdoo,
  validateTimezone,
  validateCode,
  validateCodeWithoutOdoo,
} from './validations';

const timezoneList: (SelectOption & { utcOffset: number; value: string })[] =
  Object.values(getAllTimezones())
    .filter(({ countries }) => countries.length)
    .map(({ name, utcOffset, utcOffsetStr }) => ({
      id: '',
      value: name,
      name: `(UTC ${utcOffsetStr}) ${name}`,
      utcOffset,
    }));

timezoneList.sort(({ utcOffset: a }, { utcOffset: b }) =>
  a < b ? -1 : a > b ? 1 : 0
);

export const General = ({ errors }: FormProps) => {
  const record = useRecordContext<CoreUser>();
  const translate = useTranslate();
  const isEditForm = record?.id !== undefined;
  const { data: deliveryAreasData = [], refetch } = useGetManyReference(
    ResourceRoutes.warehouse.resourcePath,
    {
      meta: ResourceMeta.DeliveryArea,
      target: 'deliveryArea',
      id: record?.id,
    }
  );
  const validateDeliveryAreas = async (value: boolean) => {
    if (accessPermission.company?.isWarehouseWithoutOdoo) {
      return undefined;
    }

    if (!isEditForm) return undefined;

    if (!value) return undefined;

    await refetch().catch(() => undefined);

    if (deliveryAreasData.length) return undefined;

    return translate(
      'stores.warehouses.pages.tabs.general.errors.deliveryAreasRequired'
    );
  };
  const {
    fieldState: { error: fieldIsActiveError },
  } = useController({
    name: 'isActive',
    rules: { validate: validateDeliveryAreas },
  });

  return (
    <>
      <ResourceSwitcher
        name="isActive"
        labelKey="stores.warehouses.pages.tabs.general.isActive"
        disabled={!isEditForm}
        sx={{ mb: 2 }}
      />

      {!!fieldIsActiveError?.message?.length && (
        <Box mt={-2} mb={2}>
          <UIErrorTypography>{fieldIsActiveError?.message}</UIErrorTypography>
        </Box>
      )}

      <TextInput
        source="name"
        name="name"
        validate={
          accessPermission.company?.isWarehouseWithoutOdoo
            ? validateNameWithoutOdoo
            : validateName
        }
        fullWidth
      />

      <ResourceAutocompleteInput
        name="timezone"
        source="timezone"
        label="stores.warehouses.pages.tabs.general.timezone"
        optionValue="value"
        choices={timezoneList}
        validate={validateTimezone}
        fullWidth
      />

      <ResourceFormErrorBoundary errors={errors} source="code">
        <TextInput
          source="code"
          name="code"
          label="stores.warehouses.pages.tabs.general.code"
          aria-errormessage={errors?.code}
          validate={
            accessPermission.company?.isWarehouseWithoutOdoo
              ? validateCodeWithoutOdoo
              : validateCode
          }
          fullWidth
          disabled={isEditForm}
        />
      </ResourceFormErrorBoundary>
    </>
  );
};
