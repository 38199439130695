import { SyntheticEvent, useMemo, useState, useEffect } from 'react';
import { useTranslate } from 'react-admin';
import { useController } from 'react-hook-form';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useTheme } from '@mui/material/styles';

import { Multiplier } from '@ROOT/layout';
import { useIsMobile } from '@ROOT/hooks';
import { WEEK_OPTIONS } from '@PluginManager/constants';
import { DEFAULT_DAY_OF_WEEK } from '@Plugins/Stores/resources/Warehouses/constants';
import { DayWorkInterval } from '@Widgets/Schedule/DayWorkInterval/DayWorkInterval';

import { useScheduleState, useScheduleDispatch } from '../context/hooks';
import {
  updateScheduleDay,
  removeScheduleDay,
  createScheduleDay,
  setAroundClock,
} from '../context/actions';
import { ScheduleDay } from '../interface';
import { ScheduleProps } from './interfaces';

export const ScheduleMultiplier = (props: ScheduleProps): JSX.Element => {
  const { schedulesPath, maxIntervalLength, alwaysMobile, onUpdateValue } =
    props;

  const t = useTranslate();
  const theme = useTheme();

  const [isAroundClock, setIsAroundClock] = useState(false);
  const [activeDay, setActiveDay] = useState(DEFAULT_DAY_OF_WEEK);

  const { fieldState } = useController({ name: 'schedule' });
  const schedule = useScheduleState();
  const dispatch = useScheduleDispatch();
  const isXSmall = useIsMobile() || alwaysMobile;

  const currentDaySet = useMemo(
    () => schedule.find((item) => item.dayOfWeek === activeDay),
    [activeDay, schedule]
  );

  const handleChangeAroundClock = () => {
    setIsAroundClock((prevState) => {
      if (!prevState && currentDaySet) {
        dispatch(setAroundClock({ dayId: currentDaySet.dayOfWeek }));
      }

      return !prevState;
    });
  };

  const onChangeDaySelector = (event: SelectChangeEvent) =>
    setActiveDay(Number(event.target.value));

  const onChangeTabHandler = (event: SyntheticEvent, tabIndex: number) =>
    setActiveDay(tabIndex + 1);

  const checkValidation = (index: number): string | undefined => {
    if (!fieldState.error?.message || schedule[index]?.intervals.length) {
      return;
    }

    return theme.palette.error.main + ' !important';
  };

  const onAddIntervalHandler = (): void => {
    if (!currentDaySet) {
      return;
    }

    dispatch(createScheduleDay({ dayId: currentDaySet.dayOfWeek }));
  };

  const onDayIntervalChangedHandler = (
    dayId: number,
    intervalId: number,
    fieldName: string,
    value: string
  ): void => {
    dispatch(updateScheduleDay({ dayId, intervalId, fieldName, value }));
  };

  const onDeleteDayIntervalHandler = (id: string): void => {
    if (!currentDaySet) {
      return;
    }

    dispatch(
      removeScheduleDay({
        dayId: currentDaySet.dayOfWeek,
        intervalId: Number(id),
      })
    );
  };

  useEffect(() => {
    onUpdateValue(
      schedule.map(
        (scheduleItem): ScheduleDay => ({
          dayOfWeek: scheduleItem.dayOfWeek,
          intervals: scheduleItem.intervals.map((interval) => ({
            start: interval.start,
            end: interval.end,
          })),
        })
      )
    );
  }, [schedule]);

  useEffect(() => {
    if (!currentDaySet) return;

    setIsAroundClock(
      currentDaySet.intervals.length === 1 &&
        currentDaySet.intervals.some(
          ({ start, end }) =>
            start === end ||
            (start === '00:00:00' &&
              (end === '00:00:00' || end.startsWith('23:59')))
        )
    );
  }, [activeDay]);

  return (
    <>
      {isXSmall ? (
        <>
          <InputLabel id="day-selector">
            {t('stores.warehouses.pages.tabs.schedule.selectADay')}
          </InputLabel>

          <Select
            id="day-selector"
            value={`${activeDay}`}
            onChange={onChangeDaySelector}
          >
            {WEEK_OPTIONS.map(({ id, name }) => (
              <MenuItem
                key={id}
                value={id}
                sx={{ color: checkValidation(id - 1) }}
              >
                {t(name)}
              </MenuItem>
            ))}
          </Select>
        </>
      ) : (
        <Tabs
          value={activeDay - 1}
          onChange={onChangeTabHandler}
          variant="scrollable"
        >
          {WEEK_OPTIONS.map(({ id, name }) => (
            <Tab
              key={id}
              label={t(name)}
              value={id - 1}
              sx={{ color: checkValidation(id - 1) }}
            />
          ))}
        </Tabs>
      )}

      <FormControlLabel
        control={<Switch checked={isAroundClock} />}
        label={t('stores.warehouses.pages.tabs.schedule.labels.aroundClock')}
        sx={{ mt: 2, mb: !isAroundClock ? 2 : 0 }}
        onChange={handleChangeAroundClock}
      />

      {!isAroundClock && currentDaySet && (
        <Multiplier
          value={currentDaySet.intervals.map((interval, idx) => ({
            id: interval.id.toString(),
            element: (
              <DayWorkInterval
                dayId={currentDaySet.dayOfWeek}
                onDayIntervalChanged={onDayIntervalChangedHandler}
                intervalIndex={idx}
                key={interval.id}
                timeInputFormat="HH:mm:ss"
                schedulesPath={schedulesPath}
              />
            ),
          }))}
          addBlockTitle={t(
            'stores.warehouses.pages.tabs.schedule.buttons.addWorkInterval'
          )}
          onRemoveBlock={onDeleteDayIntervalHandler}
          onAddBlock={
            !maxIntervalLength ||
            maxIntervalLength > currentDaySet.intervals.length
              ? onAddIntervalHandler
              : undefined
          }
        />
      )}
    </>
  );
};
