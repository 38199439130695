import { Identifier } from 'react-admin';

import {
  FILTERS,
  LIST_COLUMNS,
} from '@Plugins/Stores/resources/Warehouses/constants';
import { ResourceList } from '@Widgets/ResourceList/ResourceList';
import { ResourceRoutes } from '@Plugins/resourceRoutes';
import { ResourceListProps } from '@Widgets/ResourceList/interface';

import { ResourceListStyled } from '../styled';

interface WarehousesProps
  extends Pick<
    ResourceListProps,
    | 'bulkActionButtons'
    | 'actions'
    | 'empty'
    | 'rowClick'
    | 'isRowSelectable'
    | 'perPage'
    | 'pagination'
  > {
  showFilters?: boolean;
  blacklist?: string[];
  selectedIds?: Identifier[];
  onSelect?: (ids: Identifier[]) => void;
}

const Warehouses = (props: WarehousesProps): JSX.Element => {
  const {
    showFilters = false,
    bulkActionButtons = false,
    actions = false,
    blacklist,
    empty,
    rowClick,
    selectedIds,
    onSelect,
    isRowSelectable,
    perPage,
    pagination,
  } = props;

  const filter: Record<string, unknown> = {
    caller: 'blacklist',
  };

  if (blacklist !== undefined) {
    filter.categoryBlacklist = blacklist;
  }

  return (
    <ResourceListStyled<typeof ResourceList<CoreWarehouse>>
      resource={ResourceRoutes.warehouse.resourcePath}
      columns={LIST_COLUMNS}
      getItemCardCaption={(item) => item.name}
      defaultSort={{
        // FIXME: 'createdAt' doesn't exist in the CoreWarehouse
        // @ts-ignore
        field: 'createdAt',
        order: 'DESC',
      }}
      perPage={perPage}
      pagination={pagination}
      rowClick={rowClick}
      actions={actions}
      showDeleteButton={false}
      bulkActionButtons={bulkActionButtons}
      filters={showFilters ? FILTERS : undefined}
      disableSyncWithLocation
      empty={empty}
      emptyWhileLoading
      selectedIds={selectedIds as unknown as string[]}
      onSelect={onSelect}
      filter={filter}
      isRowSelectable={isRowSelectable}
    />
  );
};

export default Warehouses;
