import { Plugin } from '@PluginBase';
import Translations from './i18n.json';
import { Icon } from '@UI';
import StocksController from './resources/Stocks/controller';
import StockImportsController from './resources/StockImports/controller';

const Inventory = new Plugin({
  name: 'Inventory',
  i18n: Translations,
  resources: [StocksController, StockImportsController],
  rootMenu: {
    caption: {
      translationKey: 'inventory.caption',
    },
    icon: <Icon type="factCheck" />,
  },
});

export default Inventory;
