import { StatusBadgeStyled } from './styles';
import { useRecordContext, useTranslate } from 'react-admin';
import { pickersTranslationSchema } from '../../../constants';

export const StatusBadgeResource = () => {
  const record = useRecordContext<CorePickerDto>();
  const translate = useTranslate();

  return (
    <StatusBadgeStyled status={record.status} justifyContent='center' fontSize={14}>
      {translate(pickersTranslationSchema.lists.statuses[record.status])}
    </StatusBadgeStyled>
  );
};

StatusBadgeResource.defaultProps = {
  label: pickersTranslationSchema.labels.status,
};
