import { Currency } from './interface';
import { CurrencySymbol } from './constants';

type NumberInt = number;

export const isZero = (number: number | string): boolean => {
  return !parseFloat(number?.toString());
};

export const addZeroFraction = (
  number: number | string,
  trimDoubleZero = true
): string => {
  number = number.toString().replace(',', '.');
  const splitNumber = number.split('.');
  const digitsAfterComma = parseInt(
    window.localStorage.getItem('RaStore.digitsAfterComma') || '2',
    10
  );

  if (!splitNumber[1]) {
    return trimDoubleZero
      ? splitNumber[0]
      : `${splitNumber[0]}.${''.padEnd(digitsAfterComma, '0')}`;
  }

  if (trimDoubleZero) {
    return parseFloat(number).toString();
  } else {
    return `${splitNumber[0]}.${splitNumber[1].padEnd(digitsAfterComma, '0')}`;
  }
};

export const unitsToPoints = (units: number | string): NumberInt => {
  if (isZero(units)) return 0;

  return parseInt(addZeroFraction(units, false).replace('.', ''), 10) || 0;
};

export const pointsToUnits = (
  points: NumberInt | string,
  trimDoubleZero = true
): string => {
  if (isZero(points)) return '0';
  points = points.toString();
  const isNegative = points.startsWith('-');
  const digitsAfterComma = parseInt(
    window.localStorage.getItem('RaStore.digitsAfterComma') || '2',
    10
  );

  if (isNegative) points = points.slice(1);

  points = points.padStart(digitsAfterComma + 1, '0');

  const letters = points.split('');

  letters.splice(digitsAfterComma * -1, 0, '.');

  return (
    (isNegative ? '-' : '') + addZeroFraction(letters.join(''), trimDoubleZero)
  );
};

export class MoneyFormatter {
  readonly currencySymbol: string;

  private _amount: number;

  constructor(amount: number, currency: string) {
    this._amount = amount;
    this.currencySymbol = MoneyFormatter.getCurrencySymbol(currency);
  }

  get amount(): number {
    return this._amount;
  }

  get unitsAmount(): string {
    return this.convertToUnit();
  }

  get withCurrency(): string {
    return `${this.currencySymbol}${this.convertToUnit()}`;
  }

  static getCurrencySymbol(currency?: string) {
    return (
      CurrencySymbol[
        (currency as Currency) ||
          (
            (window.localStorage.getItem('RaStore.currency') as Currency) ||
            'gbp'
          ).toLowerCase()
      ] || ''
    );
  }

  static addCurrencySymbol(val: string | number) {
    const currency = MoneyFormatter.getCurrencySymbol();

    if (currency === CurrencySymbol.aed) {
      return `${val} ${currency}`;
    }

    return `${currency}${val}`;
  }

  static removeCurrencySymbol(val: string | number) {
    return val.toString().replace(/^\D+/, '');
  }

  subDiscount(discountAmount: number) {
    this._amount -= discountAmount;

    return this;
  }

  private convertToUnit(): string {
    return pointsToUnits(this.amount, false);
  }
}
