import { SyntheticEvent, useState } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  LinearProgress,
} from '@mui/material';
import { Check, Cancel, Delete, Add } from '@mui/icons-material';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { RecordContextProvider, useTranslate, useNotify } from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { AxiosError } from 'axios';

import { RequestAPI } from '@RestApi';
import {
  DEFAULT_FORM_VALUE,
  LIST_COLUMNS,
} from '@Plugins/Promotions/resources/Promocodes/constants';
import { ListColumn } from '@Widgets/ResourceList/interface';
import { DateFormatter } from '@Helpers';
import { Flex } from '@UI';
import { generatePromocodeInput } from '@Plugins/Promotions/resources/Promocodes/utils';
import { PromocodeFormProps } from '@Plugins/Promotions/resources/Promocodes/interface';

import { API_URL, PROMOCODE_API } from '../../controller';
import { AffiliatePromocodeEdit } from '../AffiliatePromocodeEdit';
import { AffiliatePromocodeForm } from '../AffiliatePromocodeForm';
import {
  sanitizeAffiliatePromocodeCreationData,
  getIsDefault,
  SanitizeDataArg,
} from '../AffiliatePromocodeEdit/utils';

import { TableCellStyled } from './styled';
import ThemedDrawer from '@UI/ThemedDrawer/ThemedDrawer';

export interface AffiliatePromocodeListProps {
  affiliate: CoreAffiliateDetail;
}

const AFFILIATE_PROMOCODE_LIST_COLUMNS: ListColumn<CoreAffiliatePromocode>[] = [
  ...LIST_COLUMNS,
  {
    source: 'default',
    type: 'boolean',
    label: 'default',
  },
];

const AffiliatePromocodeList = (props: AffiliatePromocodeListProps) => {
  const { affiliate } = props;

  const [selectedPromocode, setSelectedPromocode] =
    useState<CoreAffiliatePromocode | null>(null);
  const [nominatedToDelete, setNominatedToDelete] =
    useState<CoreAffiliatePromocode | null>(null);
  const [isCreationFormOpen, setIsCreationFormOpen] = useState<boolean>(false);

  const translate = useTranslate();
  const notify = useNotify();
  const queryClient = useQueryClient();

  const { data: promocodes = [] } = useQuery({
    queryKey: 'affiliatePromocodesList',
    queryFn: async (): Promise<CoreAffiliatePromocode[]> =>
      RequestAPI.get(`${API_URL}/list/${affiliate.data.id}/promocodes`),
    cacheTime: 0,
    retry: false,
    refetchOnMount: false,
    retryOnMount: false,
  });

  const { mutate: createAffiliatePromocode, isLoading: isCreating } =
    useMutation({
      mutationKey: 'affiliatePromocodesCreate',
      mutationFn: async (values: FieldValues) =>
        RequestAPI.post(PROMOCODE_API, values),
      onSuccess: () => {
        notify('Successfully added promocode', { type: 'success' });
        handleCloseCreationForm();
        queryClient.invalidateQueries('affiliatePromocodesList');
      },
      onError: (error: AxiosError) => {
        notify(error.message, { type: 'error' });
      },
    });

  const { mutate: deleteAffiliatePromocode, isLoading: isDeleting } =
    useMutation({
      mutationKey: 'affiliatePromocodeDelete',
      mutationFn: async (id: string) =>
        RequestAPI.delete(`${PROMOCODE_API}/${id}`),
      onSuccess: () => {
        notify('Successfully deleted promocode', { type: 'success' });
        setNominatedToDelete(null);
        queryClient.invalidateQueries('affiliatePromocodesList');
      },
      onError: (error: AxiosError) => {
        notify(error.message, { type: 'error' });
      },
    });

  const handleNavigateToPromocodeEdit =
    (item: CoreAffiliatePromocode) => () => {
      setSelectedPromocode(item);
    };

  const handleCloseAffiliateEditDrawer = () => {
    setSelectedPromocode(null);
  };

  const handleOpenCreationForm = () => {
    setIsCreationFormOpen(true);
  };

  const handleCloseCreationForm = () => {
    setIsCreationFormOpen(false);
  };

  const handleCreatePromocode = (fieldValues: FieldValues) => {
    const promocodeInput = generatePromocodeInput(
      fieldValues as PromocodeFormProps
    );
    const dataToSend = sanitizeAffiliatePromocodeCreationData(
      promocodeInput as SanitizeDataArg
    );

    dataToSend.partnerAffiliateId = affiliate.data.id;
    createAffiliatePromocode(dataToSend);
  };

  const handleNominateToDelete =
    (item: CoreAffiliatePromocode | null) => (e: SyntheticEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setNominatedToDelete(item);
    };

  const handleDeleteAffiliatePromocode = () => {
    if (!nominatedToDelete) {
      return;
    }

    deleteAffiliatePromocode(`${nominatedToDelete.id}`);
  };

  return (
    <>
      <Flex
        flexDirection="row"
        justifyContent="flex-end"
        alignItems="center"
        sx={{ mb: 2 }}
      >
        <Button
          variant="contained"
          color="primary"
          type="button"
          startIcon={<Add />}
          onClick={handleOpenCreationForm}
        >
          {translate(
            'promotions.pages.tapfilliate.labels.addNewPromoCodeButton'
          )}
        </Button>
      </Flex>
      <Table>
        <TableHead>
          <TableRow>
            {AFFILIATE_PROMOCODE_LIST_COLUMNS.map((item) => (
              <TableCellStyled key={item.source}>
                {translate(item.label)}
              </TableCellStyled>
            ))}
            <TableCellStyled />
          </TableRow>
        </TableHead>
        <TableBody>
          {promocodes.map((item) => (
            <TableRow
              key={item.id}
              hover
              role="link"
              onClick={handleNavigateToPromocodeEdit(item)}
              sx={{ cursor: 'pointer' }}
            >
              {AFFILIATE_PROMOCODE_LIST_COLUMNS.map((column) => {
                const key = `${item.id}_${column.source}`;

                switch (column.type) {
                  case 'boolean': {
                    const value = item[
                      column.source as keyof CorePromocode
                    ] as boolean;

                    return (
                      <TableCellStyled key={key}>
                        {value ? <Check /> : <Cancel />}
                      </TableCellStyled>
                    );
                  }
                  case 'date': {
                    const dateValue =
                      item[column.source as keyof CorePromocode];
                    const dateFormatter = new DateFormatter(`${dateValue}`);

                    return (
                      <TableCellStyled key={key}>
                        {dateValue && dateFormatter.fullDateWithTime()}
                      </TableCellStyled>
                    );
                  }
                  case 'custom': {
                    return (
                      <TableCellStyled key={key}>
                        <RecordContextProvider value={item}>
                          {column.customComponent}
                        </RecordContextProvider>
                      </TableCellStyled>
                    );
                  }

                  default:
                  case 'text': {
                    return (
                      <TableCellStyled key={key}>
                        {`${item[column.source as keyof CorePromocode]}`}
                      </TableCellStyled>
                    );
                  }
                }
              })}
              <TableCellStyled>
                {!getIsDefault(item) && (
                  <Button
                    variant="text"
                    color="error"
                    startIcon={<Delete />}
                    onClick={handleNominateToDelete(item)}
                  >
                    {translate('promotions.pages.tapfilliate.labels.delete')}
                  </Button>
                )}
              </TableCellStyled>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <ThemedDrawer
        open={isCreationFormOpen}
        onClose={handleCloseCreationForm}
        anchor="right"
      >
        <AffiliatePromocodeForm
          affiliate={affiliate}
          record={DEFAULT_FORM_VALUE}
          onSubmit={handleCreatePromocode}
          isLoading={isCreating}
        />
      </ThemedDrawer>

      <ThemedDrawer
        open={Boolean(selectedPromocode)}
        onClose={handleCloseAffiliateEditDrawer}
        anchor="right"
      >
        {selectedPromocode && (
          <AffiliatePromocodeEdit
            id={selectedPromocode.id}
            affiliate={affiliate}
            onNominateToDelete={handleNominateToDelete}
            onClose={handleCloseAffiliateEditDrawer}
          />
        )}
      </ThemedDrawer>

      <Dialog
        open={Boolean(nominatedToDelete)}
        onClose={handleNominateToDelete(null)}
        disableEscapeKeyDown={isDeleting}
      >
        <DialogTitle>
          {translate('promotions.pages.tapfilliate.confirmDialog.title')}
        </DialogTitle>
        <DialogContent>
          {translate('promotions.pages.tapfilliate.confirmDialog.content')}
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={handleNominateToDelete(null)}
            disabled={isDeleting}
          >
            {translate(
              'promotions.pages.tapfilliate.confirmDialog.buttons.close'
            )}
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleDeleteAffiliatePromocode}
            disabled={isDeleting}
          >
            {translate(
              'promotions.pages.tapfilliate.confirmDialog.buttons.delete'
            )}
          </Button>
        </DialogActions>
        {isDeleting && <LinearProgress />}
      </Dialog>
    </>
  );
};

export default AffiliatePromocodeList;
