import { Box } from '@mui/material';

import { TABLEAU_BASE_URL, TABLEAU_QUERY_PARAMS } from './constants';

export const ListPage = () => {
  return (
    <Box mr={-2}>
      {/* eslint-disable-next-line */}
      {/* @ts-ignore */}
      <tableau-viz
        id="myViz"
        src={`${TABLEAU_BASE_URL}?${new URLSearchParams(
          TABLEAU_QUERY_PARAMS
        ).toString()}`}
        toolbar="bottom"
        style={{ width: '100%', height: 'calc(100vh - 48px)' }}
      />
    </Box>
  );
};
