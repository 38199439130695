import { Icon } from '@UI';
import { CommonCrud, PluginResourceController } from '@PluginBase';
import { ResourceRoutes } from '@Plugins/resourceRoutes';

import { ListPage } from './pages/ListPage';
import { API_ENDPOINT } from './constants';

const customerCrud = new CommonCrud(API_ENDPOINT, { isNewSorting: true });

customerCrud.isNewSorting = true;

const CustomerController = new PluginResourceController({
  menuItem: {
    route: ResourceRoutes.customer.routePath,
    caption: {
      translationKey: 'customers.pages.customers.caption',
    },
    icon: <Icon type="people" />,
  },
  resourceRoute: {
    name: ResourceRoutes.customer.resourcePath,
    list: <ListPage />,
  },
  dataProvider: {
    getList: (src, params) => customerCrud.list(params),
  },
});

export default CustomerController;
