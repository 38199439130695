import { useGetList, SimpleForm, useTranslate } from 'react-admin';
import { ResourceAutocompleteInput, Flex } from '@UI';
import { ResourceRoutes } from '@PluginManager/plugins/resourceRoutes';
import { Box, Grid, CircularProgress } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useState, useCallback, useEffect } from 'react';
import push from 'push.js';
import useSound from 'use-sound';
import { getSound } from '../helpers/getSound';
import { usePrevious } from '../helpers/usePrevious';
import { isStandalone } from '../helpers/isStandalone';
import { ORDERS_UPDATE_TIMEOUT, PUSH_NOTIFICATION_TIMEOUT } from '../constants';
import { OrdersByStatus } from '../interface';
import { TRANSLATION_SCHEMA } from '../schemas/translationSchema';
import { MonitorOrder } from '../components/MonitorOrder';
import { ReadyToShipOrder } from '../components/ReadyToShipOrder';
import { Identifier } from 'ra-core/dist/cjs/types';
import {
  Count,
  Title,
  OrderList,
  Order,
  HeadTitle,
  AutocompleteWrapper,
} from './styles';

export const MonitorPage = () => {
  const translate = useTranslate();
  const { pathname } = useLocation();
  const isSinglePage = pathname === ResourceRoutes.wmsMonitor.customPath;
  const { data: warehouseList = [], isLoading: isWHLoading } =
    useGetList<CoreWarehouse>(ResourceRoutes.warehouse.resourcePath, {
      pagination: {
        page: 1,
        perPage: 100,
      },
      sort: {
        field: 'createdAt',
        order: 'DESC',
      },
    });
  const [orders, setOrders] = useState<OrdersByStatus | null>(null);
  const [warehousesCodes, setWarehousesCodes] = useState<any>([]);
  const [warehouseCode, setWarehouseCode] = useState<string | null>(
    localStorage.getItem('warehouseCode')
  );
  const [warehouseId, setWarehouseId] = useState<string | null>(null);
  const prevOrders = usePrevious(orders);
  const [play] = useSound(getSound());
  const { data: [filteredOrders] = [], isLoading: isFilteredOrdersLoading } =
    useGetList<OrdersByStatus & { id: Identifier }>(
      ResourceRoutes.wmsMonitor.resourcePath,
      {
        filter: {
          warehouseCode: warehouseCode,
        },
        pagination: {
          page: 1,
          perPage: 100,
        },
        sort: {
          field: 'createdAt',
          order: 'DESC',
        },
      },
      {
        enabled: warehousesCodes.includes(warehouseCode),
        refetchInterval: ORDERS_UPDATE_TIMEOUT,
      }
    );
  const notify = useCallback(() => {
    if (!orders) return;

    const { newOrders, inProgressOrders } = orders;

    const {
      newOrders: prevNewOrders = [],
      inProgressOrders: prevInProgressOrders = [],
    } = prevOrders ?? {};

    const shouldNotify = newOrders.some(
      (order) => !prevNewOrders.find((prevOrder) => order.id === prevOrder.id)
    );

    const shouldNotifyNewInProgress = inProgressOrders.some(
      (inProgressOrder) =>
        !prevInProgressOrders.find(
          (prevInProgressOrder) => inProgressOrder.id === prevInProgressOrder.id
        ) &&
        !prevNewOrders.find((prevOrder) => prevOrder.id === inProgressOrder.id)
    );

    if (shouldNotify && push.Permission.has()) {
      push
        .create('NEW ORDERS', {
          requireInteraction: false,
          timeout: PUSH_NOTIFICATION_TIMEOUT,
          vibrate: true,
          silent: false,
        })
        .catch(() => undefined);
    }

    if (shouldNotify || shouldNotifyNewInProgress) play();
  }, [play, orders, prevOrders]);
  const handleChangeWhId = (warehouseId: string) => {
    setWarehouseId(warehouseId);
    const whCode =
      warehouseList.find((wh) => wh.id === warehouseId)?.code || '';

    setWarehouseCode(whCode);
    localStorage.setItem('warehouseCode', whCode);
  };

  const columns = [
    {
      title: translate(TRANSLATION_SCHEMA.labels.newOrders),
      data: orders?.newOrders,
    },
    {
      title: translate(TRANSLATION_SCHEMA.labels.pickingOrders),
      data: orders?.pickingOrders,
    },
  ];

  useEffect(() => {
    if (isStandalone()) {
      const meta = document.createElement('meta');

      meta.setAttribute(
        'content',
        'width=device-width, initial-scale=1, maximum-scale=0.9, user-scalable=2.0'
      );
      meta.setAttribute('name', 'viewport');
      document.getElementsByTagName('head')[0].appendChild(meta);
    }

    push.Permission.request(
      () => {},
      () => alert('ENABLE PUSH NOTIFICATIONS IN BROWSER SETTINGS')
    );
  }, []);

  useEffect(() => {
    setWarehousesCodes(warehouseList.map((wh) => wh.code));
    setWarehouseId(
      warehouseList.find((wh) => wh.code === warehouseCode)?.id || ''
    );
  }, [JSON.stringify(warehouseList), warehouseCode]);

  useEffect(() => {
    if (!filteredOrders) setOrders(null);
    else setOrders(filteredOrders);
  }, [JSON.stringify(filteredOrders)]);

  useEffect(() => {
    notify();
  }, [notify]);

  return (
    <Box bgcolor={isSinglePage ? '#fff' : undefined}>
      <SimpleForm
        toolbar={false}
        record={{ warehouseId: warehouseId }}
        sx={
          isSinglePage
            ? {
                backgroundColor: '#212529',
                paddingBottom: '16px !important',
                marginBottom: '36px',
              }
            : undefined
        }
      >
        {isSinglePage ? (
          <Flex alignItems="center" fullWidth justifyContent="space-between">
            <HeadTitle>
              {translate(TRANSLATION_SCHEMA.captions.desktop)}
            </HeadTitle>

            <AutocompleteWrapper>
              <ResourceAutocompleteInput
                name="warehouseId"
                source="warehouseId"
                optionText="code"
                optionValue="id"
                choices={warehouseList}
                translateChoice={false}
                onChange={handleChangeWhId}
                color="primary"
                disabled={isWHLoading}
              />
            </AutocompleteWrapper>
          </Flex>
        ) : (
          <ResourceAutocompleteInput
            name="warehouseId"
            source="warehouseId"
            optionText="code"
            optionValue="id"
            choices={warehouseList}
            translateChoice={false}
            onChange={handleChangeWhId}
          />
        )}
      </SimpleForm>

      <Grid container spacing={4} px={2} sx={{ textAlign: 'center' }}>
        {columns.map(({ title, data }, column) => (
          <Grid item sm={12} md={6} lg={3} key={column}>
            <Count $isSinglePage={isSinglePage}>
              {isFilteredOrdersLoading ? (
                <CircularProgress />
              ) : (
                data?.length ?? 0
              )}
            </Count>
            <Title $isSinglePage={isSinglePage}>{title}</Title>
            <OrderList>
              {data?.map((order) => (
                <MonitorOrder key={order.id} order={order} />
              ))}
            </OrderList>
          </Grid>
        ))}

        <Grid item sm={12} md={6} lg={3}>
          <Count $isSinglePage={isSinglePage}>
            {isFilteredOrdersLoading ? (
              <CircularProgress />
            ) : (
              orders?.readyToShip?.length ?? 0
            )}
          </Count>
          <Title $isSinglePage={isSinglePage}>
            {translate(TRANSLATION_SCHEMA.labels.readyToShip)}
          </Title>
          {orders?.readyToShip.map((order, idx) => (
            <Flex alignItems="center" key={order.id}>
              <Box width={60} fontSize={28}>
                {order.delivery_method !== 'CLICK_AND_COLLECT'
                  ? `${idx + 1}.`
                  : ' '}
              </Box>
              <ReadyToShipOrder
                order={order as CoreOrder & { outboundShelf: string | null }}
              />
            </Flex>
          ))}
        </Grid>

        <Grid item sm={12} md={6} lg={3}>
          <Count $isSinglePage={isSinglePage}>
            {isFilteredOrdersLoading ? (
              <CircularProgress />
            ) : (
              orders?.inDelivery?.length ?? 0
            )}
          </Count>
          <Title $isSinglePage={isSinglePage}>
            {translate(TRANSLATION_SCHEMA.labels.inDelivery)}
          </Title>
          <OrderList>
            {orders?.inDelivery.map((order) => {
              return (
                <Order key={order.id} $isSinglePage={isSinglePage}>
                  {order.order_id ?? 'null'}
                </Order>
              );
            })}
          </OrderList>
        </Grid>
      </Grid>
    </Box>
  );
};
