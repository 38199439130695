import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  HttpError,
  useDataProvider,
  useNotify,
  useRecordContext,
  useRefresh,
  useResourceContext,
  useTranslate,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { DeleteManyParams } from 'ra-core/dist/cjs/types';

import { Bundles } from '@Widgets/Bundles';
import { ResourceRoutes } from '@Plugins/resourceRoutes';

import {
  BUNDLE_TABS_SUBSCRIBED_FIELDS,
  BundlePageData,
  UpdateBundleParams,
} from './constants';

const handleError = (
  error: unknown,
  notify: ReturnType<typeof useNotify>,
  setErrors: (errors: Record<string, string[]>) => void
) => {
  if (!(error instanceof HttpError)) {
    return;
  }

  notify(error.message);
  setErrors(error.body?.errors || {});
};

export const BundleSettingsTab = () => {
  const refresh = useRefresh();
  const translate = useTranslate();
  const resource = useResourceContext();
  const dataProvider = useDataProvider();
  const recordFromRequest = useRecordContext<BundlePageData>();

  const { getValues, setError, setValue, watch } =
    useFormContext<BundlePageData>();
  const bundlePlacesField = watch('bundlePlaces');
  const notify = useNotify();
  const navigate = useNavigate();
  const location = useLocation();

  const makeUpdateRequest = async (data: UpdateBundleParams) => {
    try {
      await dataProvider.update(resource, data);

      refresh();
    } catch (error) {
      handleError(error, notify, setErrors);
    }
  };

  const makeDeleteManyRequest = async (data: DeleteManyParams) => {
    try {
      await dataProvider.deleteMany(resource, data);

      refresh();
    } catch (error) {
      handleError(error, notify, setErrors);
    }
  };

  const setErrors = (errors: Record<string, string[]>) => {
    const values = getValues();
    let firstOpeningTab: number | undefined;

    for (const [field, messages] of Object.entries<string[]>(errors)) {
      const castedFieldName = field as keyof BundleResponseDto;

      if (field.startsWith('_')) {
        continue;
      }

      const value = values[castedFieldName];

      setValue(castedFieldName, value, { shouldTouch: true });

      for (const msg of messages) {
        setError(castedFieldName, { message: msg, type: 'validate' });
      }

      const tab: number | undefined = Object.values(
        BUNDLE_TABS_SUBSCRIBED_FIELDS
      )
        .filter((fields) => fields.includes(field))
        .map((_, idx) => idx)[0];

      if (tab === undefined || firstOpeningTab !== undefined) {
        continue;
      }

      firstOpeningTab = tab;
    }

    if (firstOpeningTab !== undefined) {
      openTab(firstOpeningTab);
    }
  };

  const openTab = (tab: number): void => {
    const isEdit = !!recordFromRequest?.id;
    const tabValue = tab === 0 ? '' : `/${tab}`;
    const url = isEdit
      ? `${ResourceRoutes.bundles.routePath}/${recordFromRequest.id}`
      : `${ResourceRoutes.bundles.routePath}/create`;

    if (url === location.pathname) {
      return;
    }

    navigate(`${url}${tabValue}`);
  };

  const handleAddOffersToPlace = async (
    offers: number[],
    selectedPlace: number
  ) => {
    const values = getValues();

    const data: UpdateBundleParams = {
      id: recordFromRequest.id,
      data: values,
      previousData: recordFromRequest,
      meta: {
        offerIdsToAdd: offers,
        editedPlaceId: selectedPlace,
      },
    };

    await makeUpdateRequest(data);
  };

  const handleAddPlace = async () => {
    const values = getValues();

    const data: UpdateBundleParams = {
      id: recordFromRequest.id,
      data: values,
      previousData: recordFromRequest,
      meta: {
        addPlace: true,
      },
    };

    await makeUpdateRequest(data);
  };

  const handleRemoveOffer = async (offers: number, selectedPlace: number) => {
    const values = getValues();

    await makeDeleteManyRequest({
      ids: [`${selectedPlace}_${offers}`],
      meta: {
        pageData: values,
      },
    });
  };

  const handleRemovePlace = async (place: number) => {
    const values = getValues();

    await makeDeleteManyRequest({
      ids: [`${place}`],
      meta: {
        pageData: values,
      },
    });
  };

  return (
    <Bundles
      value={bundlePlacesField}
      onAddPlace={handleAddPlace}
      onRemoveOffer={handleRemoveOffer}
      onRemovePlace={handleRemovePlace}
      onAddOfferToPlace={handleAddOffersToPlace}
      localization={{
        addPlace: translate('catalogue.pages.bundles.buttons.addPlace'),
        addOffer: translate('catalogue.pages.bundles.buttons.addOffers'),
        confirmDialogTitle: translate(
          'catalogue.pages.bundles.labels.chooseOffer'
        ),
        originalOfferPrice: translate(
          'catalogue.pages.bundles.labels.originalOfferPrice'
        ),
        discount: translate('catalogue.pages.bundles.labels.discount'),
        bundleOfferPrice: translate(
          'catalogue.pages.bundles.labels.bundleOfferPrice'
        ),
        addingPlaceMessage: translate(
          'catalogue.pages.bundles.messages.addingPlace'
        ),
        deletingPlaceMessage: translate(
          'catalogue.pages.bundles.messages.deletingPlace'
        ),
        offerRemoveTooltip: translate(
          'catalogue.pages.bundles.messages.deleteOfferTooltip'
        ),
        offerQuantity: translate('catalogue.pages.bundles.labels.quantity'),
      }}
    />
  );
};
