import { SimpleFormIterator } from 'react-admin';
import styled from 'styled-components';

export const SimpleFormIteratorStyled = styled(SimpleFormIterator)`
  & .RaSimpleFormIterator-line {
    width: 100%;
    justify-content: stretch;
  }

  & .RaSimpleFormIterator-index {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 260px;
  }
`;
