import { useTranslate } from 'react-admin';

import { OFFER_IMPORT_LIST_COLUMNS } from '@Plugins/Catalogue/resources/share/constants';
import { ResourceList } from '@Widgets/ResourceList/ResourceList';

import { OfferImportsProps } from './interfaces';

const OfferImports = (props: OfferImportsProps) => {
  const { onRowClick } = props;

  const translate = useTranslate();

  return (
    <ResourceList<CoreOfferImport>
      columns={OFFER_IMPORT_LIST_COLUMNS}
      actions={false}
      showDeleteButton={false}
      getItemCardCaption={(record) =>
        translate('catalogue.pages.offerOperations.labels.mobileCardCaption', {
          id: record.id,
        })
      }
      defaultSort={{
        field: 'createdAt',
        order: 'DESC',
      }}
      rowClick={onRowClick}
      perPage={10}
    />
  );
};

export default OfferImports;
