import { useLocaleState, useRecordContext } from 'react-admin';

const ProductName = () => {
  const { name, nameAr } = useRecordContext<CoreProduct>();
  const [locale] = useLocaleState();

  return <>{locale === 'ar' && nameAr ? nameAr : name}</>;
};

export default ProductName;
