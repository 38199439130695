import { ReactText } from 'react';

export interface WarehouseFormProps {
  formProps: CoreWarehouse;
  title: string;
}

export interface ScheduleDayProps {
  options: {
    name: string;
    id: ReactText;
    disabled?: boolean;
  }[];
  onDayChanged: (oldDayId: string, newDayId: string) => void;
  dayId: string;
}

export interface ScheduleDayWorkIntervalProps {
  onDayIntervalChanged: (
    dayId: number,
    intervalIndex: number,
    intervalFieldName: string,
    intervalValue: string
  ) => void;
  dayId: number;
  intervalIndex: number;
  timeInputFormat?: string;
  schedulesPath?: string;
}

export interface ShippingMultiplierProps {
  name: string;
  titleKey: string;
  addButtonTitleKey: string;
}

export interface WarehouseFiltersProps {
  filters: {
    search: string;
    isActive?: boolean;
    ids?: string[];
    updatedAt?: {
      from: string;
      to: string;
    };
    createdAt?: {
      from: string;
      to: string;
    };
  };
  sort: {
    updatedAt?: 'ASC' | 'DESC';
    createdAt?: 'ASC' | 'DESC';
    code?: 'ASC' | 'DESC';
    name?: 'ASC' | 'DESC';
    isActive?: 'ASC' | 'DESC';
  };
  pagination: {
    pageSize: number;
    page: number;
  };
}

export interface WarehouseListResponse {
  pagination: {
    page: number;
    pageSize: number;
    total: number;
  };
  data: CoreWarehouse[];
}

export interface WarehouseApiProps {
  warehouseId: string;
}

export interface WarehouseDeleteApiProps {
  warehouseIds: (string | number)[];
}

export interface WarehouseApiResponse {
  data: CoreWarehouse;
}

export interface CreateApiError extends Error {
  message: 'WAREHOUSE_CODE_SHOULD_BE_UNIQUE';
}

export interface DeliveryAreaDetailedEntity extends CoreDeliveryAreaEntity {
  zoneShippingMethods: CoreZoneShippingMethod[];
}

export enum ShippingMechanicCode {
  ON_DEMAND = 'ON_DEMAND',
  SLOT = 'SLOT',
  CLICK_AND_COLLECT = 'CLICK_AND_COLLECT',
}
