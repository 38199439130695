import { Plugin } from '@PluginBase';
import { Icon } from '@UI';
import Translation from './i18n.json';
import Promocodes from './resources/Promocodes/controller';
import Stories from './resources/Stories/controller';
import Loyalty from './resources/Loyalty/controller';
import Referral from './resources/Referral/controller';
import Tapfilliate from './resources/Tapfilliate/controller';
import Gifting from './resources/Gifting/controller';

const Promotions = new Plugin({
  name: 'Promotions',
  i18n: Translation,
  resources: [Promocodes, Gifting, Stories, Loyalty, Referral, Tapfilliate],
  rootMenu: {
    caption: {
      translationKey: 'promotions.caption',
    },
    icon: <Icon type="promotion" />,
  },
});

export default Promotions;
