import { Add, Inbox } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { Button, useTranslate } from 'react-admin';
import { Flex } from '@UI';

interface EmptyBlacklistProps {
  onAdd?: () => void;
}

const EmptyBlacklist = ({ onAdd }: EmptyBlacklistProps): JSX.Element => {
  const t = useTranslate();

  return (
    <Flex asColumn alignItems="center" sx={{ width: '100%', py: 3 }} gap={4}>
      <Inbox sx={{ width: '9em', height: '9em' }} />
      <Typography variant="h3">
        {t('catalogue.pages.categories.blacklist.empty.title')}
      </Typography>
      <Typography variant="caption">
        {t('catalogue.pages.categories.blacklist.empty.caption')}
      </Typography>
      <Button
        label={t('catalogue.pages.categories.blacklist.labels.addToBlacklist')}
        size="medium"
        variant="contained"
        onClick={onAdd}
      >
        <Add />
      </Button>
    </Flex>
  );
};

export default EmptyBlacklist;
