import React, { SyntheticEvent } from 'react';
import { TimeInput, useTranslate } from 'react-admin';
import { FormControlLabel, Switch, AccordionDetails } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';
import moment from 'moment/moment';

import { Flex } from '@UI';
import { TIME_DISPLAY_FORMAT } from '@Widgets/Schedule/DayWorkInterval/constants';

import { DeliveryMechanicAccordionBodyProps } from './interfaces';

export const DeliveryMechanicAccordionBody = (
  props: DeliveryMechanicAccordionBodyProps
) => {
  const { source, timeInputFormat = TIME_DISPLAY_FORMAT } = props;

  const translate = useTranslate();

  const { setValue } = useFormContext();
  const {
    field: { value: timeInstance },
  } = useController({ name: source });

  const isAroundTheClock = () => {
    if (!timeInstance) {
      return false;
    }

    const { start, end } = timeInstance as Omit<IntervalInstance, 'id'>;

    return start === '00:00' && end === '23:59';
  };

  const handleChangeAroundTheClock = (e: SyntheticEvent) => {
    const { checked } = e.target as HTMLInputElement;

    if (checked) {
      setValue(source, {
        start: '00:00',
        end: '23:59',
      });

      return;
    }

    setValue(source, {
      start: '00:00',
      end: '00:00',
    });
  };

  const handleFormat = (value: string): string =>
    moment(value, timeInputFormat, true).format(TIME_DISPLAY_FORMAT);

  const handleParse = (value: string): string =>
    moment(value, TIME_DISPLAY_FORMAT, true).format(timeInputFormat);

  return (
    <AccordionDetails>
      <FormControlLabel
        checked={isAroundTheClock()}
        control={<Switch />}
        onChange={handleChangeAroundTheClock}
        label="Around the clock"
      />

      {!isAroundTheClock() && (
        <Flex
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Flex mr={2} flexGrow={1}>
            <TimeInput
              source={`${source}.start`}
              label={translate(
                'stores.warehouses.pages.tabs.schedule.labels.startTime'
              )}
              format={handleFormat}
              parse={handleParse}
              fullWidth
              inputProps={{
                pattern: '[0-2][0-9]:[0-5][0-9]',
              }}
              required
            />
          </Flex>

          <Flex flexGrow={1}>
            <TimeInput
              source={`${source}.end`}
              label={translate(
                'stores.warehouses.pages.tabs.schedule.labels.endTime'
              )}
              format={handleFormat}
              parse={handleParse}
              fullWidth
              inputProps={{
                pattern: '[0-2][0-9]:[0-5][0-9]',
              }}
              required
            />
          </Flex>
        </Flex>
      )}
    </AccordionDetails>
  );
};
