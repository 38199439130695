import { isClickAndCollect } from '../helpers/isClickAndCollect';
import { Order } from '../pages/styles';
import { ClickAndCollectIcon } from './ClickAndCollectIcon';
import { useTranslate } from 'react-admin';
import { TRANSLATION_SCHEMA } from '@PluginManager/plugins/WMSPicking/resources/Monitor/schemas/translationSchema';
import { useLocation } from 'react-router-dom';
import { ResourceRoutes } from '@PluginManager/plugins/resourceRoutes';

export const ReadyToShipOrder = ({
  order,
}: {
  order: CoreOrder & { outboundShelf: string | null };
}) => {
  const { order_id, sla } = order;
  const translate = useTranslate();
  const { pathname } = useLocation();
  const isSinglePage = pathname === ResourceRoutes.wmsMonitor.customPath;

  if (isClickAndCollect(order)) {
    return (
      <Order $isSinglePage={isSinglePage}>
        <ClickAndCollectIcon order={order} />
        {order_id || 'null'} {order?.outboundShelf ?? ''}
      </Order>
    );
  }

  const now = Date.now();
  const shipBy = new Date(sla.ship_by).getTime();
  const deliverBy = new Date(sla.deliver_by_min).getTime();
  const deliverByMinutes = Math.floor((deliverBy - now) / 60000);
  const classes = {
    shipDelaying: now > shipBy ? 'shipDelaying' : '',
    deliverByMinutes:
      deliverByMinutes >= 5
        ? 'green'
        : deliverByMinutes >= 0
        ? 'yellow'
        : 'red',
  };

  return (
    <Order $isSinglePage={isSinglePage}>
      <span className={classes.shipDelaying}>{order_id || 'null'}</span>
      <span>{order?.outboundShelf ?? ''}</span>{' '}
      <span>
        (<span className={classes.deliverByMinutes}>{deliverByMinutes}</span>{' '}
        {translate(TRANSLATION_SCHEMA.labels.min)})
      </span>
    </Order>
  );
};
