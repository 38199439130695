import { Flex, RowInfoBlock } from '@UI';
import { ImageUploaderDndWrapper } from './ImageUploader/ImageUploaderDndWrapper';
import { useState } from 'react';
import { DndProvider } from 'react-dnd';
import MultiBackend from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch';
import { ImageDropItem } from './ImageUploader/interface';
import { S3Uploader, useSetErrorToErrorsContext } from '@Helpers';
import { ImageGalleryProps } from './interface';
import { ACCEPTED_IMAGE_TYPES } from '@ROOT/constants';
import { BackendFactory } from 'dnd-core';

export const ImageGalleryWidget = ({
  type,
  mainImageUrl,
  mainImageKey = 'imageGallery.image',
  additionalImagesUrls = [],
  updateMainImage,
  updateAdditionalImages,
  hideAdditionalImage,
  hideMainImage,
  allowedTypes = ACCEPTED_IMAGE_TYPES,
}: ImageGalleryProps) => {
  const { onErrorHandler } = useSetErrorToErrorsContext();
  const [fieldLoading, setFieldLoading] = useState<'main' | 'additional'>();
  const { types, sizeMB } = allowedTypes;

  async function onAddMainImage(file: File) {
    if (!file) {
      return;
    }

    try {
      setFieldLoading('main');
      const uploader = new S3Uploader([file], type);
      const [fileUrl] = await uploader.send();

      updateMainImage?.(fileUrl);
    } catch (e) {
      onErrorHandler(e);
    }

    setFieldLoading(undefined);
  }

  function onRemoveMainImage() {
    updateMainImage?.('');
  }

  async function onAdditionalImageUploaded(file: File) {
    try {
      setFieldLoading('additional');
      const uploader = new S3Uploader([file], type);
      const [fileUrl] = await uploader.send();

      updateAdditionalImages?.([...additionalImagesUrls, fileUrl]);
    } catch (e) {
      onErrorHandler(e);
    }

    setFieldLoading(undefined);
  }

  function onRemoveAdditionalImage(value: string) {
    updateAdditionalImages?.(
      additionalImagesUrls.filter((image) => image !== value)
    );
  }

  function updateImageField(source: string, value?: string) {
    const [field, index] = source.split('-');

    if (field === 'image') {
      updateMainImage?.(value);
    } else {
      additionalImagesUrls[parseInt(index ?? '0')] = value ?? '';
      updateAdditionalImages?.([...additionalImagesUrls]);
    }
  }

  function onSortImagesHandler(
    currentImage: ImageDropItem,
    droppedImage: ImageDropItem
  ) {
    const { source: currentSource, value: currentValue } = currentImage;
    const { source: droppedSource, value: droppedValue } = droppedImage;

    updateImageField(currentSource, droppedValue);
    updateImageField(droppedSource, currentValue);
  }

  return (
    <DndProvider
      backend={MultiBackend as any as BackendFactory}
      options={HTML5toTouch}
    >
      <Flex gap={2} flexWrap="wrap">
        {!hideMainImage && (
          <RowInfoBlock gap={2} titleKey={mainImageKey}>
            <ImageUploaderDndWrapper
              maxSizeMb={sizeMB}
              accept={types}
              isLoading={fieldLoading === 'main'}
              isDraggable
              onDrop={onSortImagesHandler}
              value={mainImageUrl}
              onUpload={onAddMainImage}
              onRemove={onRemoveMainImage}
              source="image"
            />
          </RowInfoBlock>
        )}

        {!hideAdditionalImage &&
          additionalImagesUrls?.map((image, index: number) => (
            <RowInfoBlock
              key={image}
              gap={2}
              titleKey="imageGallery.additionalImage"
            >
              <ImageUploaderDndWrapper
                maxSizeMb={sizeMB}
                accept={types}
                onDrop={onSortImagesHandler}
                isDraggable
                value={image}
                onUpload={onAdditionalImageUploaded}
                onRemove={onRemoveAdditionalImage}
                source={`images-${index}`}
              />
            </RowInfoBlock>
          ))}

        {!hideAdditionalImage && (
          <RowInfoBlock gap={2} titleKey="imageGallery.additionalImage">
            <ImageUploaderDndWrapper
              maxSizeMb={sizeMB}
              accept={types}
              isLoading={fieldLoading === 'additional'}
              onUpload={onAdditionalImageUploaded}
              source={`images-${mainImageUrl?.length ?? 0}`}
            />
          </RowInfoBlock>
        )}
      </Flex>
    </DndProvider>
  );
};
