import { ShipmentTableItem } from './constants';

export const generateShipmentTableData = (
  record: CoreShipment
): ShipmentTableItem[] => {
  if (!record.cages?.length) {
    return [];
  }

  return record.cages.map((cage, i) => ({
    id: i,
    origin: record.origin,
    destination: cage.destinationWarehouse,
    cageName: cage.barcode,
    isShipped: record.status === 'shipped',
  }));
};
