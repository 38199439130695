import { FieldValues } from 'react-hook-form';
import { PromocodeFormProps } from '@Plugins/Promotions/resources/Promocodes/interface';
import { pointsToUnits, unitsToPoints } from '@Helpers';

export const getPrefixedSource = (source: string, prefix?: string): string => {
  if (!prefix) {
    return source;
  }

  return `${prefix}.${source}`;
};

export const getValueBySource = (
  values: FieldValues,
  source: string,
  sourcePrefix?: string
) => {
  if (!sourcePrefix) {
    return values[source];
  }

  return values[sourcePrefix][source];
};

export function generatePromocodeInput(
  data: PromocodeFormProps
): Omit<CorePromocode, 'id'> & { id?: string } {
  const {
    minimumQuantity,
    minimumPurchase,
    minRequirementsChoice,
    customerGetAnyItemsFrom,
    customerBuysAnyItemsFrom,
    filters,
    discountTargets,
    productDiscountQuantity,
    value,
    usageLimitCustomer,
    usageLimitAll,
    ...rest
  } = data;

  return {
    ...rest,
    discountTargets:
      data.type === 'BUY_X_GET_Y'
        ? {
            inProduct:
              customerGetAnyItemsFrom === 'specificProducts'
                ? discountTargets?.inProduct
                : undefined,
            inCategory:
              customerGetAnyItemsFrom === 'specificCollection'
                ? discountTargets?.inCategory
                : undefined,
          }
        : undefined,
    filters: {
      inProduct:
        customerBuysAnyItemsFrom === 'specificProducts'
          ? filters?.inProduct
          : undefined,
      inCategory:
        customerBuysAnyItemsFrom === 'specificCollection'
          ? filters?.inCategory
          : undefined,
    },
    minimumQuantity:
      minRequirementsChoice === 'minimumQuantityItems'
        ? parseInt(minimumQuantity as unknown as string, 10) || 0
        : 0,
    minimumPurchase:
      minRequirementsChoice === 'minimumPurchaseAmount'
        ? unitsToPoints(minimumPurchase)
        : 0,
    productDiscountQuantity:
      data.type === 'BUY_X_GET_Y' ? productDiscountQuantity : undefined,
    value:
      data.type === 'FIXED'
        ? unitsToPoints(value)
        : parseInt(value as unknown as string, 10) || 0,
    usageLimitCustomer:
      parseInt(usageLimitCustomer as unknown as string, 10) || 0,
    usageLimitAll: parseInt(usageLimitAll as unknown as string, 10) || 0,
    orderNumberRange: [
      parseInt(data.orderNumberRange[0] as unknown as string, 10) || 0,
      parseInt(data.orderNumberRange[1] as unknown as string, 10) || 0,
    ],
  };
}

export function generatePromocodeFromValues(
  data: Omit<CorePromocode, 'id'>
): Nullable<
  PromocodeFormProps & {
    usageLimit: string[];
  }
> {
  if (!data) return null;

  const {
    filters,
    discountTargets,
    minimumQuantity,
    minimumPurchase,
    usageLimitCustomer,
    usageLimitAll,
    type,
    value,
  } = data;
  let minRequirementsChoice: PromocodeFormProps['minRequirementsChoice'] =
    'none';

  if (minimumQuantity) {
    minRequirementsChoice = 'minimumQuantityItems';
  }

  if (minRequirementsChoice === 'none' && minimumPurchase) {
    minRequirementsChoice = 'minimumPurchaseAmount';
  }

  const usageLimit = [];

  if (usageLimitCustomer) usageLimit.push('perUser');

  if (usageLimitAll) usageLimit.push('forAll');

  return {
    ...data,
    customerGetAnyItemsFrom: discountTargets?.inCategory?.length
      ? 'specificCollection'
      : 'specificProducts',
    customerBuysAnyItemsFrom: filters?.inCategory?.length
      ? 'specificCollection'
      : 'specificProducts',
    minRequirementsChoice,
    usageLimit,
    minimumPurchase: pointsToUnits(minimumPurchase) as unknown as number,
    value:
      type === 'FIXED' ? (pointsToUnits(value) as unknown as number) : value,
  };
}
