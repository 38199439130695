import {
  FormDataConsumer,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import { Tabs, Tooltip, Typography } from '@mui/material';
import { InfoRounded } from '@mui/icons-material';
import { useSearchParams } from 'react-router-dom';

import {
  RowInfoBlock,
  FormErrorBoundary,
  Flex,
  ResourceInputTransformation,
  ResourceInputNumber,
  ResourceSwitcher,
} from '@UI';
import {
  isEmptyAfterTrimmed,
  maxLengthExt,
  onlyLatin,
  accessPermission,
} from '@Helpers';
import FilterOptions from '@Plugins/Settings/resources/Properties/pages/PropertyFormFields/FilterOptions/FilterOptions';
import { useIsMobile } from '@ROOT/hooks';
import { TabContent } from '@Plugins/Settings/resources/Properties/pages/ListPage/tabs/TabContent';

import {
  DEFAULT_FORM_VALUES,
  LISTED_TYPES,
  PROPERTY_TYPE_CHOICES,
} from '../constants';

import { ChoicesTable } from './ChoicesTable/ChoicesTable';
import { LocalizationTab } from './LocalizationTab';
import { PropertyLanguageTabs } from './interfaces';
import {
  OfferVariantOptions,
  OFFER_VARIANT_TYPES,
} from './OfferVariantOptions';

const validateName = [
  required(),
  isEmptyAfterTrimmed(),
  onlyLatin(),
  maxLengthExt(128),
];
const validateArabicName = [maxLengthExt(128)];
const validateDescription = [maxLengthExt(128)];

export const TAB_SEARCH_QUERY_KEY = 'langTab';

export const PropertyFormFields = () => {
  const record = useRecordContext<CoreProperty>();
  const isMobile = useIsMobile();
  const isEditForm = record?.id !== undefined;
  const [searchParams, setSearchParams] = useSearchParams();
  const currentTab: PropertyLanguageTabs =
    (searchParams.get(
      TAB_SEARCH_QUERY_KEY
    ) as Nullable<PropertyLanguageTabs>) || PropertyLanguageTabs.ENGLISH;
  const translate = useTranslate();

  const changeTab = (tab: PropertyLanguageTabs) => {
    searchParams.set(TAB_SEARCH_QUERY_KEY, tab);
    setSearchParams(searchParams);
  };

  const getArabicSource = (
    source: string,
    locales: CorePropertyLocale[]
  ): string => {
    const arabicLocaleIndex = locales.findIndex(
      (locale) => locale.language === PropertyLanguageTabs.ARABIC
    );

    if (arabicLocaleIndex < 0) {
      return source;
    }

    return `locales.${arabicLocaleIndex}.${source}`;
  };

  return (
    <SimpleForm record={record} defaultValues={DEFAULT_FORM_VALUES}>
      <RowInfoBlock sx={{ paddingBlock: 0 }}>
        <Tabs
          value={currentTab}
          onChange={(e) => changeTab(e as unknown as PropertyLanguageTabs)}
        >
          <LocalizationTab
            label={translate('settings.pages.properties.tabs.en')}
            value={PropertyLanguageTabs.ENGLISH}
            onChange={changeTab}
            fields={['name', 'description', 'choices']}
          />
          <LocalizationTab
            label={translate('settings.pages.properties.tabs.ar')}
            value={PropertyLanguageTabs.ARABIC}
            onChange={changeTab}
            fields={['locales']}
          />
        </Tabs>
      </RowInfoBlock>

      <TabContent currentTab={currentTab}>
        <Flex
          key={PropertyLanguageTabs.ENGLISH}
          asColumn={isMobile}
          fullWidth
          gap={1}
        >
          <Flex asColumn justifyContent="flex-start" flex={1} fullWidth>
            <RowInfoBlock>
              <TextInput
                source="name"
                name="name"
                label="settings.pages.properties.labels.name"
                validate={validateName}
                fullWidth
              />

              <ResourceInputTransformation
                label="settings.pages.properties.labels.code"
                source="code"
                parentSource="name"
                spaceReplacement="_"
                isRequired
              />

              <FormDataConsumer>
                {({ formData }) =>
                  !formData.isOfferVariants && (
                    <ResourceInputNumber
                      min={0}
                      source="sorting"
                      name="sorting"
                      label="settings.pages.properties.labels.sorting"
                      isInteger
                      fullWidth
                    />
                  )
                }
              </FormDataConsumer>
            </RowInfoBlock>

            <RowInfoBlock>
              <TextInput
                key={PropertyLanguageTabs.ENGLISH}
                multiline
                source="description"
                name="description"
                label="settings.pages.properties.labels.description"
                validate={validateDescription}
                fullWidth
              />
            </RowInfoBlock>
          </Flex>

          <Flex asColumn justifyContent="flex-start" flex={1} fullWidth>
            <RowInfoBlock>
              <ResourceSwitcher
                name="isActive"
                labelKey={
                  <Flex gap={1} alignItems="center">
                    <Typography>
                      {translate('settings.pages.properties.labels.isActive')}
                    </Typography>
                    <Tooltip
                      title={translate(
                        'settings.pages.properties.hints.isActive'
                      )}
                      placement="top"
                    >
                      <InfoRounded fontSize="small" />
                    </Tooltip>
                  </Flex>
                }
                translate="no"
              />

              <OfferVariantOptions />

              <FilterOptions />
            </RowInfoBlock>

            <RowInfoBlock>
              <FormDataConsumer>
                {({ formData: { isOfferVariants } }) => {
                  const options = accessPermission.company?.hasBadgePropertyType
                    ? PROPERTY_TYPE_CHOICES
                    : PROPERTY_TYPE_CHOICES.filter(
                        (item) => item.id !== 'badge'
                      );
                  const validatedList = isOfferVariants
                    ? options.filter((item) =>
                        OFFER_VARIANT_TYPES.includes(item.id)
                      )
                    : options;

                  return (
                    <SelectInput
                      disabled={isEditForm}
                      source="type"
                      name="type"
                      choices={validatedList}
                      label="settings.pages.properties.labels.type"
                      validate={required()}
                      fullWidth
                    />
                  );
                }}
              </FormDataConsumer>
            </RowInfoBlock>

            <FormDataConsumer>
              {({ formData }) =>
                formData &&
                LISTED_TYPES.includes(formData.type) &&
                currentTab === PropertyLanguageTabs.ENGLISH ? (
                  <ChoicesTable source="choices" />
                ) : null
              }
            </FormDataConsumer>
          </Flex>
        </Flex>
        <Flex
          key={PropertyLanguageTabs.ARABIC}
          asColumn={isMobile}
          fullWidth
          gap={1}
        >
          <Flex asColumn justifyContent="flex-start" flex={1} fullWidth>
            <RowInfoBlock>
              <FormDataConsumer<CoreProperty>>
                {({ formData: { locales = [] }, ...rest }) => (
                  <TextInput
                    {...rest}
                    source={getArabicSource('name', locales)}
                    name={getArabicSource('name', locales)}
                    label="settings.pages.properties.labels.name"
                    validate={validateArabicName}
                    fullWidth
                  />
                )}
              </FormDataConsumer>
            </RowInfoBlock>

            <RowInfoBlock>
              <FormDataConsumer<CoreProperty>>
                {({ formData: { locales = [] }, ...rest }) => (
                  <TextInput
                    {...rest}
                    multiline
                    source={getArabicSource('description', locales)}
                    name={getArabicSource('description', locales)}
                    label="settings.pages.properties.labels.description"
                    validate={validateDescription}
                    fullWidth
                  />
                )}
              </FormDataConsumer>
            </RowInfoBlock>
          </Flex>

          <Flex asColumn justifyContent="flex-start" flex={1} fullWidth>
            <RowInfoBlock>
              <FormDataConsumer>
                {() => (
                  <SelectInput
                    disabled
                    source="type"
                    name="type"
                    choices={PROPERTY_TYPE_CHOICES}
                    label="settings.pages.properties.labels.type"
                    validate={required()}
                    fullWidth
                  />
                )}
              </FormDataConsumer>
            </RowInfoBlock>

            <FormDataConsumer>
              {({ formData }) =>
                formData &&
                LISTED_TYPES.includes(formData.type) &&
                currentTab === PropertyLanguageTabs.ARABIC ? (
                  <ChoicesTable
                    source={getArabicSource('choices', formData.locales || [])}
                  />
                ) : null
              }
            </FormDataConsumer>
          </Flex>
        </Flex>
      </TabContent>

      <FormErrorBoundary />
    </SimpleForm>
  );
};
