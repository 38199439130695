import { ReactElement, useState, useRef, useEffect } from 'react';

import {
  useGetList,
  SimpleForm,
  useNotify,
  useUpdate,
  useTranslate,
  NumberInput,
} from 'react-admin';
import { ResourceRoutes } from '@PluginManager/plugins/resourceRoutes';
import Button from '@mui/material/Button';
import { FieldValues } from 'react-hook-form';
import { logError } from '@Helpers';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

export const ListPage = (): ReactElement => {
  const [page, setPage] = useState(1);
  const [updateAlert] = useUpdate();
  const notify = useNotify();
  const translate = useTranslate();
  const formRef = useRef<FieldValues>();

  const { data, isLoading, error, isSuccess } = useGetList(
    ResourceRoutes.replenishment.alert.resourcePath,
    {
      pagination: { page, perPage: 1 },
      filter: { alerts: 1 },
    }
  );
  const _data: any = data;
  const alert = data ? _data[0] : {};

  useEffect(() => {
    if (error) {
      notify('orders.pages.actions.makeRefund.errorMessage', {
        type: 'error',
      });

      logError.log(
        translate('orders.pages.actions.makeRefund.errorMessage'),
        error
      );
    }
  }, [error, isSuccess]);

  function onUpdateFinalOrder(values: FieldValues) {
    formRef.current = values;
    updateAlert(ResourceRoutes.replenishment.alert.resourcePath, {
      id: alert?.id,
      previousData: alert,
      data: formRef.current ?? undefined,
    }).catch(console.error);
  }

  if (isLoading) {
    return <h1>Loading...</h1>;
  }

  if (error) {
    return <p>ERROR</p>;
  }

  const getNextPage = () => setPage(page + 1);
  const getPrevPage = () => setPage(page - 1);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  }));

  return (
    <>
      <h1>
        High order value alert. Store {alert.store_name} - item {alert.sku}
      </h1>

      <SimpleForm
        onSubmit={onUpdateFinalOrder}
        record={alert}
        toolbar={<Button type="submit">Save</Button>}
      >
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={2}
          style={{ marginBottom: '2em' }}
        >
          <Item>
            <img src={alert.img_url} alt="Product image" />
            <ul>
              <li style={{ margin: '10px 0' }}>Category - {alert.cat1}</li>
              <li style={{ margin: '10px 0' }}>
                Cluster - {alert.final_cluster}
              </li>
              <li style={{ margin: '10px 0' }}>
                Supplier - {alert.supplier_name}
              </li>
              <li style={{ margin: '10px 0' }}>Retail price - None</li>
            </ul>
          </Item>
          <Item>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow
                    key="Package size"
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Package size
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {alert.packages}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    key="Shelflife, days"
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Shelflife, days
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {alert.shelf_life}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    key="Purchase price"
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Purchase price
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {alert.cost_per_item}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    key="Package price"
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Package price
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {alert.cost_per_package}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Item>
          <Item>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow
                    key="closest delivery"
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Closest delivery
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {alert.closest_delivery_date}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    key="next delivery"
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Next delivery
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {alert.next_closest_delivery_date}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    key="actual sales"
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Days between deliveries
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {Math.ceil(
                        (Date.parse(alert.next_closest_delivery_date) -
                          Date.parse(alert.closest_delivery_date)) /
                          (1000 * 60 * 60 * 24)
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Item>
        </Stack>

        <Stack direction="row" spacing={2} style={{ marginBottom: '2em' }}>
          <Item>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell align="right">LLW</TableCell>
                    <TableCell align="right">LW</TableCell>
                    <TableCell align="right">TW</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    key="actual sales"
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Actual sales
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {alert.sales_llw}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {alert.sales_lw}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {alert.sales_tw}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    key="corrected sales"
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Corrected sales
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {alert.corrected_sales_llw}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {alert.corrected_sales_lw}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {alert.corrected_sales_tw}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    key="write-offs"
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Write-offs
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {alert.corrected_sales_llw}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {alert.corrected_sales_lw}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {alert.corrected_sales_tw}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    key="deliveries"
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Deliveries
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {alert.corrected_sales_llw}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {alert.corrected_sales_lw}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {alert.corrected_sales_tw}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Item>
          <Item>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>SALES FORECAST</TableCell>
                    <TableCell align="right" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    key="actual sales"
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Till closest delivery
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {alert.delivery_next}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    key="corrected sales"
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Closest to next delivery
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {alert.nearest_delivery}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    key="write-offs"
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Insurance stock
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {alert.insurance_stock}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    key="deliveries"
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Write-off forecast
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {alert.wo_forecast_agg}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Item>
          <Item>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>DATA</TableCell>
                    <TableCell align="right" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    key="actual sales"
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Actual stock
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {alert.stock_act}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    key="corrected sales"
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Stock in transit
                    </TableCell>
                    <TableCell component="th" scope="row">
                      None
                    </TableCell>
                  </TableRow>
                  <TableRow
                    key="write-offs"
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Calculated order
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {alert.final_order}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    key="deliveries"
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Order by package
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {alert.final_order}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Item>
        </Stack>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <div>
            <Button onClick={getPrevPage}>Previous Alert</Button>
            <Button onClick={getNextPage}>Next Alert</Button>
          </div>
          <div>
            <NumberInput
              name="final_order"
              source="final_order"
              label="Final Order"
            />
          </div>
        </Stack>
      </SimpleForm>
    </>
  );
};
