import { CommonCrud } from '@PluginBase';
import { RequestAPI } from '@RestApi';
import {
  GetListParams,
  GetListResult,
  RaRecord,
  UpdateManyParams,
} from 'react-admin';

export class DCCrud extends CommonCrud {
  constructor(url: string) {
    super(url, {
      arrayFormatter: (key: string, values: string[]) => {
        return values.map((value) => `${key}=${value}`).join('&');
      },
      isNewSorting: true,
    });
  }

  async list<RecordType extends RaRecord = RaRecord>(
    params: GetListParams,
    headers?: Record<string, string>
  ): Promise<GetListResult> {
    const { filter } = params;
    const newFilter: Record<string, string | string[]> = {};

    Object.keys(filter).forEach((key) => {
      let value: string | string[] = filter[key] as string;

      if (
        key === 'destinationWarehouse' ||
        key === 'origin' ||
        key === 'destination'
      ) {
        value = value
          .split(',')
          .map((val) => val.toUpperCase().trim())
          .filter((val) => val);
      }

      if (key.includes('From')) {
        value = new Date(value + 'T00:00:00.000').toISOString();
      }

      if (key.includes('To')) {
        value = new Date(value + 'T23:59:59.999').toISOString();
      }

      newFilter[key.replace(/(From|To)$/, (str) => `[${str.toLowerCase()}]`)] =
        value;
    });
    params.filter = newFilter;

    return super.list<RecordType>(params, headers);
  }

  async cancelMany(params: UpdateManyParams, url: string): Promise<boolean> {
    try {
      if (params.ids.length) {
        await RequestAPI.put(url, {
          ids: params.ids,
        });
      }

      return true;
    } catch (e) {
      this.handleError(e as Error);

      return false;
    }
  }
}
