import { createReducer } from '@Helpers/Reducer';

import { scheduleActions } from './actions';
import { MIN_LENGTH_OF_TIME_INPUT } from './constants';
import {
  RemoveScheduleDayPayload,
  ScheduleChangeHandler,
  UpdateScheduleDayPayload,
  CreateScheduleInterval,
} from './interfaces';

export const initialValue: CoreScheduleDay[] = [];

export const setScheduleDays: ScheduleChangeHandler<CoreScheduleDay[]> = (
  state,
  action
) => {
  const { payload } = action;

  if (!payload) {
    return state;
  }

  return payload.map((schedule) => ({
    dayOfWeek: schedule.dayOfWeek,
    intervals: schedule.intervals.map((interval, id) => ({
      ...interval,
      id,
    })),
  }));
};

export const createScheduleInterval: ScheduleChangeHandler<
  CreateScheduleInterval
> = (state, action) => {
  const { dayId } = action.payload || {};

  if (dayId === undefined) {
    return state;
  }

  return state.map((item) => {
    if (item.dayOfWeek !== dayId) {
      return item;
    }

    const length = item.intervals.length;
    const lastId = length > 0 ? item.intervals[length - 1].id + 1 : 1;

    return {
      ...item,
      intervals: [
        ...item.intervals,
        { id: lastId, end: '00:00:00', start: '00:00:00' },
      ],
    };
  });
};

export const updateScheduleDay: ScheduleChangeHandler<
  UpdateScheduleDayPayload
> = (state, action) => {
  const { payload } = action;

  if (!payload) {
    return state;
  }

  return state.map((day) => {
    if (day.dayOfWeek !== payload.dayId) {
      return day;
    }

    const intervals = day.intervals.map((interval) => {
      if (interval.id !== payload.intervalId) {
        return interval;
      }

      const valueChunks = payload.value.split(':');

      if (valueChunks.length < MIN_LENGTH_OF_TIME_INPUT) {
        valueChunks.push(
          ...new Array(MIN_LENGTH_OF_TIME_INPUT - valueChunks.length)
            .fill(null)
            .map(() => '00')
        );
      }

      return Object.assign({}, interval, {
        [payload.fieldName]: valueChunks.join(':'),
      });
    });

    return {
      ...day,
      intervals,
    };
  });
};

export const removeScheduleDay: ScheduleChangeHandler<
  RemoveScheduleDayPayload
> = (state, action) => {
  const { dayId, intervalId } = action.payload || {};

  if (dayId === undefined || intervalId === undefined) {
    return state;
  }

  return state.map((day) => {
    if (day.dayOfWeek !== dayId) {
      return day;
    }

    return {
      dayOfWeek: day.dayOfWeek,
      intervals: day.intervals.filter((item) => item.id !== intervalId),
    };
  });
};

export const setAroundClock: ScheduleChangeHandler<CreateScheduleInterval> = (
  state,
  action
) => {
  const { dayId } = action.payload || {};

  if (dayId === undefined) {
    return state;
  }

  return state.map((schedule) => {
    if (schedule.dayOfWeek !== dayId) {
      return schedule;
    }

    return {
      dayOfWeek: dayId,
      intervals: [{ id: 1, start: '00:00:00', end: '23:59:00' }],
    };
  });
};

export const reducer = createReducer<CoreScheduleDay[]>(initialValue, {
  [scheduleActions.SET_SCHEDULE_DAYS]: setScheduleDays,
  [scheduleActions.UPDATE_SCHEDULE_INTERVAL]: updateScheduleDay,
  [scheduleActions.REMOVE_SCHEDULE_INTERVAL]: removeScheduleDay,
  [scheduleActions.CREATE_SCHEDULE_INTERVAL]: createScheduleInterval,
  [scheduleActions.SET_AROUND_CLOCK]: setAroundClock,
});
