import styled from 'styled-components';
import { Box } from '@mui/material';
import { MenuContainerStyledProps } from './interface';

export const MenuContainerStyled = styled(Box)<MenuContainerStyledProps>`
  ${({ open }) => `
    width: ${open ? '100%' : '50px'};
    margin-top: 8px;
    margin-bottom: 8px;
  `}
`;
