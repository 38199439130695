import { ResourceList } from '@Widgets/ResourceList/ResourceList';
import { STOCK_IMPORTS_LIST_COLUMNS } from '../share/constants';
import { UIDrawer } from '@UI';
import { useState } from 'react';
import { DetailsPage } from './DetailsPage';
import { Identifier, RaRecord } from 'ra-core';
import { useTranslate } from 'react-admin';

export const ListPage = () => {
  const translate = useTranslate();
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const onCloseDrawerHandle = () => setIsOpenDrawer(false);
  const [rowRecord, setRowRecord] = useState<CoreStockImport>();
  const onRowClickHandler = (
    id: Identifier,
    resource: string,
    record: RaRecord
  ) => {
    setRowRecord(record as CoreStockImport);
    setIsOpenDrawer(true);

    return false;
  };

  return (
    <>
      <ResourceList<CoreStockImport>
        columns={STOCK_IMPORTS_LIST_COLUMNS}
        actions={false}
        showDeleteButton={false}
        getItemCardCaption={(record) =>
          translate('inventory.pages.stockImports.labels.mobileCardCaption', {
            id: record.id,
          })
        }
        defaultSort={{
          field: 'createdAt',
          order: 'DESC',
        }}
        rowClick={onRowClickHandler}
        perPage={25}
      />

      <UIDrawer isOpen={isOpenDrawer} onClose={onCloseDrawerHandle}>
        <DetailsPage record={rowRecord as CoreStockImport} />
      </UIDrawer>
    </>
  );
};
