import { useGetList } from 'react-admin';
import {
  promocodesTranslationNamespace,
  PROMOCODE_APPLY_CHOICES,
} from '../../../constants';
import {
  Flex,
  RowInfoBlock,
  ResourceAutocompleteArrayInput,
  ResourceInputNumber,
  UIErrorTypography,
} from '@UI';
import { RESOURCES_SCHEMA } from '../../../schemas/resourcesSchema';
import { ResourceRoutes } from '@Plugins/resourceRoutes';
import { useController } from 'react-hook-form';
import { useEffect } from 'react';
import { InputAdornment, useTheme } from '@mui/material';
import { getPrefixedSource } from '@Plugins/Promotions/resources/Promocodes/utils';
import { RadioButtonGroupStyled } from '../styled';

interface Props {
  sourcePrefix?: string;
}

export const CustomerGet = ({ sourcePrefix }: Props) => {
  const theme = useTheme();

  const customerGetsAnyItemsFrom = useController({
    name: getPrefixedSource(
      RESOURCES_SCHEMA.customerGetAnyItemsFrom,
      sourcePrefix
    ),
  });
  const filters = useController({
    name: getPrefixedSource(RESOURCES_SCHEMA.discountTargets.all),
  });
  const isForProducts =
    customerGetsAnyItemsFrom.field.value === 'specificProducts';

  const { data: products, isLoading: isLoadingProduct } = useGetList(
    ResourceRoutes.product.resourcePath,
    {},
    { enabled: isForProducts }
  );
  const { data: catalogs, isLoading: isLoadingCatalog } = useGetList(
    ResourceRoutes.catalog.resourcePath,
    {},
    { enabled: !isForProducts }
  );

  useEffect(() => {
    filters.field.onChange({});
  }, [customerGetsAnyItemsFrom.field.value]);

  return (
    <RowInfoBlock asColumn justifyContent="center" gap={1}>
      <Flex flex={1} asColumn>
        <RadioButtonGroupStyled
          $dir={theme.direction}
          label={promocodesTranslationNamespace.labels.customerGets}
          name={getPrefixedSource(
            RESOURCES_SCHEMA.customerGetAnyItemsFrom,
            sourcePrefix
          )}
          source={getPrefixedSource(
            RESOURCES_SCHEMA.customerGetAnyItemsFrom,
            sourcePrefix
          )}
          choices={[PROMOCODE_APPLY_CHOICES[1], PROMOCODE_APPLY_CHOICES[2]]}
        />

        <ResourceAutocompleteArrayInput
          fullWidth
          choices={isForProducts ? products : catalogs}
          source={getPrefixedSource(
            RESOURCES_SCHEMA.discountTargets[
              isForProducts ? 'inProduct' : 'inCategory'
            ],
            sourcePrefix
          )}
          isLoading={isLoadingProduct || isLoadingCatalog}
        />

        {filters.formState.errors[RESOURCES_SCHEMA.discountTargets.all] && (
          <UIErrorTypography>
            {
              filters.formState.errors[RESOURCES_SCHEMA.discountTargets.all]
                ?.message as string
            }
          </UIErrorTypography>
        )}
      </Flex>

      <Flex flex={1} asColumn>
        <ResourceInputNumber
          min={0}
          label={promocodesTranslationNamespace.labels.quantity}
          source={getPrefixedSource(
            RESOURCES_SCHEMA.productDiscountQuantity,
            sourcePrefix
          )}
          isRequired
        />

        <ResourceInputNumber
          min={0}
          max={100}
          isRequired
          label={promocodesTranslationNamespace.labels.atDiscountedValue}
          source={getPrefixedSource(RESOURCES_SCHEMA.value, sourcePrefix)}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />

        <ResourceInputNumber
          min={0}
          label={
            promocodesTranslationNamespace.labels.maximumUsageNumberPerOrder
          }
          source={getPrefixedSource(
            RESOURCES_SCHEMA.maximumUsageNumberPerOrder,
            sourcePrefix
          )}
        />
      </Flex>
    </RowInfoBlock>
  );
};
