import { PluginResourceController } from '@PluginBase';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { ApiActionCall } from './routes';
import { ListPage } from './pages/ListPage/ListPage';
import { GetListResult, GetOneResult, UpdateResult } from 'react-admin';
import { OrderApi, OrdersCRUD } from './restApi/OrderApi';
import { CancelOrderApi } from './restApi/CancelOrderApi';
import { FullOrderRefundApi } from './restApi/FullOrderRefundApi';
import { PartialOrderRefundApi } from './restApi/PartialOrderRefundApi';
import { PartialRefundItem } from './pages/interface';
import { ResourceRoutes } from '../../../resourceRoutes';
import { apiUrl } from './constants';

const ordersCrud = new OrdersCRUD(apiUrl);

const OrderResourceController = new PluginResourceController({
  menuItem: {
    caption: {
      translationKey: 'orders.caption',
    },
    icon: <MonetizationOnIcon />,
    route: ResourceRoutes.orders.routePath,
  },
  resourceRoute: {
    name: ResourceRoutes.orders.resourcePath,
    list: ListPage,
  },
  dataProvider: {
    getList: (resource, params): Promise<GetListResult> =>
      ordersCrud.list(params),
    getOne: async (resource, params): Promise<GetOneResult> => {
      const orderApi = new OrderApi(apiUrl, params.id);

      return orderApi.execute();
    },
    update: async (resource, params): Promise<UpdateResult> => {
      if (params.meta === ApiActionCall.cancelOrder) {
        const cancelReason = `${params.data.cancelReason}`.trim();

        const cancelOrderApi = new CancelOrderApi(
          params.previousData,
          cancelReason
        );

        return cancelOrderApi.execute();
      }

      if (params.meta === ApiActionCall.fullRefund) {
        const fullRefundApi = new FullOrderRefundApi(
          params.previousData,
          params.data.problem,
          params.data.comment
        );

        return fullRefundApi.execute();
      }

      if (params.meta === ApiActionCall.partialRefund) {
        const partialOrderRefund = new PartialOrderRefundApi(
          params.previousData,
          params.data as PartialRefundItem[]
        );

        return partialOrderRefund.execute();
      }

      return {
        data: 'error',
      };
    },
  },
});

export default OrderResourceController;
