import { TextInput, required, SimpleForm, SaveButton, Toolbar, useRecordContext } from 'react-admin';
import { ResourceSwitcher } from '@UI';
import { isEmptyAfterTrimmed, onlyLatin, maxLengthExt, minLengthExt } from '@Helpers';

const validateName = [required(), isEmptyAfterTrimmed(), onlyLatin(), maxLengthExt(128)];
const validateCode = [required(), isEmptyAfterTrimmed(), onlyLatin(), minLengthExt(4), maxLengthExt(20)];

export const FormPage = () => {
  const record = useRecordContext();
  const isEditForm = record?.id !== undefined;

  return (
    <SimpleForm
      maxWidth={500}
      width='95vw'
      toolbar={<Toolbar><SaveButton /></Toolbar>}
    >
      <ResourceSwitcher name='is_active' labelKey='settings.pages.shippingMethods.labels.isActive' />

      <TextInput
        source='name'
        name='name'
        fullWidth
        disabled={isEditForm}
        validate={validateName}
      />

      <TextInput
        source='code'
        name='code'
        fullWidth
        disabled={isEditForm}
        validate={validateCode}
      />
    </SimpleForm>
  );
};
