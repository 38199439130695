import { Typography, TypographyProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const UIErrorTypography = ({ children }: TypographyProps) => {
  const theme = useTheme();

  return (
    <Typography variant='caption' color={theme.palette.error.main}>
      {children}
    </Typography>
  );
};
