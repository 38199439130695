import { PluginResourceController, CommonCrud } from '@PluginBase';
import { ResourceRoutes } from '../../../resourceRoutes';
import { GetListResult, UpdateManyResult } from 'react-admin';
import { ListPage } from './ListPage';
import { Icon } from '@UI';
import { apiUrl, FILE_UPLOAD_STOCK_IMPORTS } from './constants';

const stockImportsCrud = new CommonCrud(apiUrl);

stockImportsCrud.isNewSorting = true;

const StockImportsController = new PluginResourceController({
  menuItem: {
    caption: {
      translationKey: 'inventory.pages.stockImports.caption',
    },
    route: ResourceRoutes.stockImports.routePath,
    icon: <Icon type="offerImport" />,
  },
  resourceRoute: {
    name: ResourceRoutes.stockImports.resourcePath,
    list: ListPage,
  },

  dataProvider: {
    getList: (resource, params): Promise<GetListResult> =>
      stockImportsCrud.list(params),
    updateMany: async (resource, params): Promise<UpdateManyResult> => {
      if (params.meta === FILE_UPLOAD_STOCK_IMPORTS) {
        await stockImportsCrud.uploadFile(params);

        return {
          data: [],
        };
      }

      return {
        data: [],
      };
    },
  },
});

export default StockImportsController;
