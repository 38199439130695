import { LowPriority } from '@mui/icons-material';

import { CommonCrud, PluginResourceController } from '@PluginBase';
import { ResourceRoutes } from '@Plugins/resourceRoutes';

import { PickingPriority } from './pages/PickingPriority';

import { WMS_PICKING_PRIORITY_LIST_API } from './constants';

const wmsPickingPriorityCRUD = new CommonCrud(WMS_PICKING_PRIORITY_LIST_API, {
  isNewSorting: true,
});

const controller = new PluginResourceController({
  resourceRoute: {
    name: ResourceRoutes.wmsPickingPriority.resourcePath,
    list: PickingPriority,
  },
  menuItem: {
    route: ResourceRoutes.wmsPickingPriority.routePath,
    caption: {
      translationKey: 'wms-picking.pages.picking-priority.caption',
    },
    icon: <LowPriority />,
  },
  dataProvider: {
    getList: async (resource, params) => wmsPickingPriorityCRUD.list(params),
    update: async (resource, params) => {
      // DEPRECATED. updating value in the PickingPriorityPage
      await wmsPickingPriorityCRUD.update(params);

      return { data: params.previousData };
    },
  },
});

export default controller;
