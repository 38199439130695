import { RaRecord, useLocaleState } from 'react-admin';
import { Typography } from '@mui/material';

import { TypographyStyled } from './styled';

const CategoryName = <T extends RaRecord = CoreCategory>({
  truncate = 250,
  record,
}: {
  truncate?: number;
  record?: T;
}) => {
  const { name, nameAr } = (record as CoreCategory | undefined) || {};
  const [locale] = useLocaleState();

  const localizedName = locale === 'ar' && nameAr ? nameAr : name;

  return (
    <Typography title={localizedName} maxWidth="100%">
      <TypographyStyled
        // component doesn't exist in the props definition, but it actually exists
        // @ts-ignore
        component="span"
        variant="body2"
        key="name"
        $truncate={truncate}
      >
        {localizedName}
      </TypographyStyled>
    </Typography>
  );
};

export default CategoryName;
