import { SubmitDeliveryMechanicArg } from './interfaces';

const sortEntriesByKey = (
  a: [string, Omit<IntervalInstance, 'id'>],
  b: [string, Omit<IntervalInstance, 'id'>]
): number => {
  const [keyA] = a;
  const [keyB] = b;

  if (Number(keyA) === Number(keyB)) {
    return 0;
  }

  return keyA < keyB ? -1 : 1;
};

export const submitZoneShippingMechanic = ({
  values,
  shippingMethodId,
  deliveryMechanicId,
}: SubmitDeliveryMechanicArg): CoreZoneShippingMethod => {
  const { schedule = {}, order_amount_threshold } = values;

  return {
    shippingMethodId,
    deliveryMechanicId,
    order_amount_threshold,
    schedule: Object.entries(schedule)
      .sort(sortEntriesByKey)
      .map(([dayOfWeek, timeInstance]) => {
        const end =
          timeInstance.end === '23:59'
            ? `${timeInstance.end}:59`
            : `${timeInstance.end}:00`;

        return {
          dayOfWeek: Number(dayOfWeek),
          intervals: [
            {
              start: `${timeInstance.start}:00`,
              end,
            },
          ],
        };
      }),
  };
};
