import { RequestAPI } from '@RestApi';
import { DCCrud } from '../../share/DCCrud';
import { GetManyResult } from 'react-admin';
import { CagesCrudGetManyResponseDataItem } from '../constants';

export class CagesCrud extends DCCrud {
  async getMany(): Promise<GetManyResult<CagesCrudGetManyResponseDataItem>> {
    try {
      const response = await RequestAPI.get(`${this.apiUrl}/not-shipped`);

      return {
        data: Object.entries(response.data || {}).map(
          ([warehouse, cages], idx) => {
            return {
              id: idx,
              warehouse,
              cages: cages as CorePickingOrderCageActual[],
            };
          }
        ),
      };
    } catch (e) {
      this.handleError(e as Error);

      return {
        data: [],
      };
    }
  }
}
