import { FormDataConsumer, useTranslate } from 'react-admin';
import {
  PROMOCODE_USAGE_LIMIT_CHOICES,
  promocodesTranslationNamespace,
} from '../../../constants';
import { Flex, ResourceInputNumber, RowInfoBlock } from '@UI';
import { RESOURCES_SCHEMA } from '../../../schemas/resourcesSchema';
import { useIsMobile } from '@Hooks/useIsMobile';
import { maxNum, minNum } from '@Helpers';
import { FieldTitle } from 'ra-core';
import * as React from 'react';
import { Typography, useTheme } from '@mui/material';
import {
  getPrefixedSource,
  getValueBySource,
} from '@Plugins/Promotions/resources/Promocodes/utils';
import { FieldValues } from 'react-hook-form';

import { CheckboxGroupInputStyled } from '../styled';

const validateUsageLimitCustomer =
  (sourcePrefix?: string) => (value: string, values: FieldValues) => {
    const usageLimit = getValueBySource(values, 'usageLimit', sourcePrefix);

    if (usageLimit.includes('perUser')) {
      if (!value) return 'ra.validation.required';

      return minNum(1)(value);
    }

    return undefined;
  };
const validateUsageLimitAll =
  (sourcePrefix?: string) => (value: string, values: FieldValues) => {
    const usageLimit = getValueBySource(values, 'usageLimit', sourcePrefix);

    if (usageLimit.includes('forAll')) {
      if (!value) return 'ra.validation.required';

      return minNum(1)(value);
    }

    return undefined;
  };

export const validateStartOrderNumber =
  (sourcePrefix?: string) => (value: string, values: FieldValues) => {
    const error = minNum(0)(value);

    if (error) return error;

    const orderNumberRange =
      getValueBySource(values, 'orderNumberRange', sourcePrefix) || [];

    const maxVal = parseInt(orderNumberRange[1], 10);

    return maxNum(maxVal < 0 ? 0 : maxVal)(value);
  };

export const validateEndOrderNumber =
  (sourcePrefix?: string) => (value: string, values: FieldValues) => {
    const orderNumberRange =
      getValueBySource(values, 'orderNumberRange', sourcePrefix) || [];
    const minVal = parseInt(orderNumberRange[0], 10);

    return minNum(minVal < 0 ? 0 : minVal)(value);
  };

interface Props {
  sourcePrefix?: string;
}

export const UsageLimits = ({ sourcePrefix }: Props) => {
  const isMobile = useIsMobile();
  const translate = useTranslate();
  const theme = useTheme();

  return (
    <>
      <RowInfoBlock asColumn={isMobile} gap={5}>
        <Flex flex={1}>
          <CheckboxGroupInputStyled
            $dir={theme.direction}
            name={getPrefixedSource('usageLimit', sourcePrefix)}
            source={getPrefixedSource('usageLimit', sourcePrefix)}
            label={
              <>
                <FieldTitle
                  label={promocodesTranslationNamespace.labels.usageLimits}
                />
                <Typography variant="body1">
                  {`(${translate(
                    'promotions.pages.promocodes.labels.defaultIs1IfNoSelected'
                  )})`}
                </Typography>
              </>
            }
            choices={PROMOCODE_USAGE_LIMIT_CHOICES}
            row={false}
            margin="none"
          />
        </Flex>

        <Flex flex={1} asColumn>
          <FormDataConsumer>
            {({ formData }) => {
              const usageLimit = getValueBySource(
                formData,
                'usageLimit',
                sourcePrefix
              );

              return (
                <>
                  {usageLimit?.includes('forAll') && (
                    <ResourceInputNumber
                      isRequired
                      fullWidth
                      isInteger
                      source={getPrefixedSource(
                        RESOURCES_SCHEMA.usageLimitAll,
                        sourcePrefix
                      )}
                      label={
                        promocodesTranslationNamespace.lists.usageLimit
                          .forAllCount
                      }
                      validate={validateUsageLimitAll(sourcePrefix)}
                    />
                  )}

                  {usageLimit?.includes('perUser') && (
                    <ResourceInputNumber
                      isRequired
                      fullWidth
                      isInteger
                      source={getPrefixedSource(
                        RESOURCES_SCHEMA.usageLimitCustomer,
                        sourcePrefix
                      )}
                      label={
                        promocodesTranslationNamespace.lists.usageLimit
                          .perUserCount
                      }
                      validate={validateUsageLimitCustomer(sourcePrefix)}
                    />
                  )}
                </>
              );
            }}
          </FormDataConsumer>
        </Flex>
      </RowInfoBlock>

      <RowInfoBlock
        titleKey={promocodesTranslationNamespace.labels.orderRange}
        fullWidth
      >
        <Flex asColumn={isMobile} gap={5} fullWidth>
          <Flex flex={1} fullWidth>
            <ResourceInputNumber
              source={getPrefixedSource(
                RESOURCES_SCHEMA.orderNumberRange.rangeStart,
                sourcePrefix
              )}
              label={
                promocodesTranslationNamespace.lists.orderNumberRange
                  .startOrderNumber
              }
              isInteger
              fullWidth
              validate={validateStartOrderNumber(sourcePrefix)}
            />
          </Flex>

          <Flex flex={1}>
            <ResourceInputNumber
              source={getPrefixedSource(
                RESOURCES_SCHEMA.orderNumberRange.rangeEnd,
                sourcePrefix
              )}
              label={
                promocodesTranslationNamespace.lists.orderNumberRange
                  .endOrderNumber
              }
              isInteger
              fullWidth
              validate={validateEndOrderNumber(sourcePrefix)}
            />
          </Flex>
        </Flex>
      </RowInfoBlock>
    </>
  );
};
