import { Flex, Icon, Preview, Loader } from '@UI';
import { ImageInputStyled } from './styles';
import { ImageUploaderViewProps } from './interface';
import { Button } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { useNotify } from 'react-admin';

export const ImageUploaderView = ({
  source,
  onUpload,
  value,
  onRemove,
  isLoading,
  accept = ['image/*'],
  maxSizeMb,
  rejectFileMessage,
}: ImageUploaderViewProps) => {
  const notify = useNotify();
  const methods = useForm({
    defaultValues: {
      [source]: value,
    },
  });

  function onRemoveImageHandler() {
    onRemove?.(value ?? '', source);
  }

  return (
    <FormProvider {...methods}>
      <Flex maxWidth={200} minHeight='236px' asColumn position='relative' justifyContent='space-between'>
        {isLoading && <Loader fullHeight={false} />}

        {!value && !isLoading && (
          <Flex alignItems='center' flex={1}>
            <ImageInputStyled
              source={source}
              name={source}
              label={' '}
              onChange={onUpload}
              accept={accept?.join(', ')}
              maxSize={maxSizeMb}
              options={{
                onDropRejected: () => {
                  notify(rejectFileMessage, {
                    type: 'error',
                  });

                  return false;
                },
              }}
            />
          </Flex>
        )}

        {!!value && !isLoading && (
          <>
            <Flex flex={1}>
              <Preview src={value} size={200} />
            </Flex>

            <Button onClick={onRemoveImageHandler} color='error'><Icon type='delete' /></Button>
          </>
        )}
      </Flex>
    </FormProvider>
  );
};
