import { PRODUCT_LINE_LIST_COLUMNS, ResourceMeta, PER_PAGE } from './constants';
import { ResourceRoutes } from '@Plugins/resourceRoutes';
import { LinkedList } from '@Widgets/LinkedList/LinkedList';

export const ProductsTab = () => (
  <LinkedList
    target={ResourceMeta.PRODUCTS}
    resource={ResourceRoutes.pickingOrders.resourcePath}
    cols={PRODUCT_LINE_LIST_COLUMNS}
    perPage={PER_PAGE}
  />
);
