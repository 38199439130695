import { Icon } from '@UI';
import { Box } from '@mui/material';
import { isClickAndCollect } from '../helpers/isClickAndCollect';

export const ClickAndCollectIcon = ({
  order,
}: {
  order: CoreOrder | CorePickingOrderDto;
}) => {
  return isClickAndCollect(order) ? (
    <Box display="inline-block" sx={{ verticalAlign: 'middle', marginTop: -1 }}>
      <Icon type="pin" sx={{ marginRight: '5px' }} size="large" />
    </Box>
  ) : null;
};
