import { useState, useContext, useEffect, SyntheticEvent } from 'react';
import { ReactComponent as Logo } from '../../assets/jiffy-logo-white.svg';
import { ReactComponent as LogoDark } from '../../assets/jiffy-logo.svg';
import { PageLayout, LoginCard } from './styles';
import LoginFlow from './LoginFlow/LoginFlow';
import { Tabs, Tab, Box } from '@mui/material';
import { useTranslate, useNotify } from 'react-admin';
import { Flex } from '@UI';
import { TabContent } from '@Widgets/../../layout/UI/TabContent/TabContent';
import PhoneFlow from './PhoneFlow/PhoneFlow';
import EmailFlow from './EmailFlow/EmailFlow';
import { useTheme } from '@mui/material/styles';
import { ErrorBoundaryProvider } from '@Widgets/../../helpers/ErrorBoundaryContext/ErrorBoundaryContext';

const Login = () => {
  const { errors } = useContext(ErrorBoundaryProvider);
  const notify = useNotify();

  useEffect(() => {
    if (errors?.auth?.length) {
      notify(errors.auth.join('\n'), { type: 'error' });
    }
  }, [errors?.auth]);

  const translate = useTranslate();
  const theme = useTheme();
  const [curTabIndex, setCurTabIndex] = useState(0);
  const tabNames = [
    translate('pages.login.byPhone'),
    translate('pages.login.byEmail'),
    // translate('pages.login.byLogin'),
  ];

  const handleTabsChange = (e: SyntheticEvent, index: number) =>
    setCurTabIndex(index);

  return (
    <PageLayout>
      <LoginCard>
        <Flex justifyContent="center" py={2}>
          {theme.palette.mode === 'dark' ? (
            <Logo height="60" />
          ) : (
            <LogoDark height="60" />
          )}
        </Flex>

        <Box
          sx={{
            paddingTop: 2,
            marginBottom: 3,
            borderBottom: 2,
            borderColor: 'primary.main',
          }}
        >
          <Tabs
            value={curTabIndex}
            onChange={handleTabsChange}
            variant="fullWidth"
          >
            {tabNames.map((tabName) => (
              <Tab sx={{ py: 0 }} label={tabName} key={tabName} />
            ))}
          </Tabs>
        </Box>

        <TabContent tabIndex={0} curTabIndex={curTabIndex}>
          <PhoneFlow />
        </TabContent>

        <TabContent tabIndex={1} curTabIndex={curTabIndex}>
          <EmailFlow />
        </TabContent>

        <TabContent tabIndex={2} curTabIndex={curTabIndex}>
          <LoginFlow />
        </TabContent>
      </LoginCard>
    </PageLayout>
  );
};

export default Login;
