import {
  RaRecord,
  TextInput,
  useRecordContext,
  TextInputProps,
} from 'react-admin';
import { MoneyFormatter, pointsToUnits } from '@Helpers';
import React from 'react';

interface NumInputWithCurrencyProps<TRecord> {
  source: string;
  record?: TRecord;
  id?: string;
  name?: string;
  label?: string;
  disabled?: boolean;
  validate?: TextInputProps['validate'];
}

const getFixedVal = (val?: string) => {
  if (!val) {
    return MoneyFormatter.addCurrencySymbol('');
  }

  return MoneyFormatter.addCurrencySymbol(pointsToUnits(val));
};

const parse = (value: string) => Number(String(value).replace(/[^\d.]+/g, ''));

export const NumInputWithCurrency = <TRecord extends RaRecord = RaRecord>({
  record,
  source,
  id,
  name,
  label,
  disabled,
  validate,
}: NumInputWithCurrencyProps<TRecord>) => {
  const recordFromContext = useRecordContext<TRecord>();

  record = record ?? recordFromContext;
  const fieldId = `${source}_${record.id}`;

  return (
    <TextInput
      disabled={disabled}
      label={label}
      source={source}
      fullWidth
      id={id ?? fieldId}
      name={name ?? fieldId}
      parse={parse}
      format={getFixedVal}
      validate={validate}
    />
  );
};
