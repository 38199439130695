import {
  GetListResult,
  GetOneResult,
  CreateResult,
  DeleteResult,
  UpdateResult,
  HttpError,
} from 'react-admin';

import { Icon } from '@UI';
import { PluginResourceController, CommonCrud } from '@PluginBase';
import { ResourceRoutes } from '@PluginManager/plugins/resourceRoutes';
import { BackendCodeError } from '@PluginManager/base/AbstractApi/BackendCodeError';
import { cleanPhone } from '@Helpers';
import { ResourceItemPage } from '@Widgets/ResourceItemPage/ResourceItemPage';

import { UserForm } from './pages/UserForm';
import { ListPage } from './pages/ListPage';

const usersCrud = new CommonCrud('/auth/v1/users');

usersCrud.isNewSorting = true;

const UsersController = new PluginResourceController({
  menuItem: {
    caption: {
      translationKey: 'settings.pages.users.caption',
    },
    route: ResourceRoutes.users.routePath,
    icon: <Icon type="people" />,
  },
  resourceRoute: {
    name: ResourceRoutes.users.resourcePath,
    list: ListPage,
    create: (
      <ResourceItemPage type="create" includeForm={false}>
        <UserForm />
      </ResourceItemPage>
    ),
    edit: (
      <ResourceItemPage type="edit" includeForm={false}>
        <UserForm />
      </ResourceItemPage>
    ),
  },
  dataProvider: {
    getList: async (resource, params): Promise<GetListResult> => {
      const result = (await usersCrud.list(params)) as GetListResult<
        CoreUser & { rolesFlat: string }
      >;

      if (result?.data) {
        result.data = result.data.map((item) => {
          item.rolesFlat = item.roles.map((el) => el.name).join(', ');

          return item;
        });
      }

      return result;
    },
    getOne: async (resource, params): Promise<GetOneResult> => {
      const result = (await usersCrud.getOne<{ data: CoreUser }>(
        params
      )) as GetOneResult<CoreUser>;

      if (result?.data) {
        result.data.roles = result.data.roles.map(
          (el) => el.id
        ) as unknown as CoreRole[];
        result.data.permissions = result.data.permissions.map(
          (el) => el.id
        ) as unknown as CorePermission[];
      }

      return {
        data: result?.data,
      };
    },
    create: async (resource, params): Promise<CreateResult> => {
      delete params.data.confirmPassword;
      params.data = {
        ...params.data,
        roles: {
          ids: params.data.roles || [],
        },
        permissions: {
          ids: params.data.permissions || [],
        },
      };
      params.data.phone = cleanPhone(params.data.phone || '');
      params.data.firstName = params.data.firstName?.trim();
      params.data.lastName = params.data.lastName?.trim();

      try {
        const result = await usersCrud.create<{ data: CoreUser }>(params);

        return {
          data: result?.data,
        };
      } catch (e: unknown) {
        const knownErrors: Record<string, string> = {
          'Email already in use': 'email',
          'Phone number already in use': 'phone',
        };

        if (e instanceof BackendCodeError && e.originalMessage in knownErrors) {
          const field = knownErrors[e.originalMessage];

          throw new HttpError(e.originalMessage, e.status, {
            errors: {
              [field]: e.originalMessage,
            },
          });
        }

        throw e;
      }
    },
    delete: async (resource, params): Promise<DeleteResult> => {
      await usersCrud.delete(params);

      return {
        data: params.previousData,
      };
    },
    update: async (resource, params): Promise<UpdateResult> => {
      const previousData = Object.assign(
        params.previousData || {},
        params.data || {}
      );

      delete params.data.id;
      delete params.data.email;
      delete params.data.createdAt;
      delete params.data.confirmPassword;
      params.data = {
        ...params.data,
        roles: {
          ids: params.data.roles || [],
        },
        permissions: {
          ids: params.data.permissions || [],
        },
      };
      params.data.phone = cleanPhone(params.data.phone || '');
      params.data.firstName = params.data.firstName?.trim();
      params.data.lastName = params.data.lastName?.trim();
      params.data.login = params.data.login?.trim();

      if (!params.data.login) delete params.data.login;

      if (!params.data.password) delete params.data.password;

      await usersCrud.update(params);

      return {
        data: previousData,
      };
    },
  },
});

export default UsersController;
