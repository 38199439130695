import Stores from './Stores/Stores';
import Orders from './Orders/Orders';
import Promotions from './Promotions/Promotions';
import Settings from './Settings/Settings';
import Catalogue from './Catalogue/Catalogue';
import DistributionCenter from './DistributionCenter/DistributionCenter';
import Inventory from './Inventory/Inventory';
import WMSPicking from './WMSPicking/WMSPicking';
import Replenishment from './Replenishment/Replenishment';
import Customers from './Customers/Customers';
import DeliveryDispatcher from './DeliveryDispatcher/DeliveryDispatcher';
import Reports from './Reports/Reports';

const plugins = {
  Stores,
  Reports,
  Orders,
  Promotions,
  Settings,
  Customers,
  Catalogue,
  DistributionCenter,
  Inventory,
  WMSPicking,
  DeliveryDispatcher,
  Replenishment,
};

export default plugins;
