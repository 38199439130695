import {
  RadioButtonGroupInput,
  required,
  SelectInput,
  Toolbar,
  useTranslate,
  number,
} from 'react-admin';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { Button } from '@mui/material';

import {
  Flex,
  ResourceInputNumber,
  ResourceMoneyInput,
  RowInfoBlock,
} from '@UI';
import { maxNum, minNum, pointsToUnits, isInteger } from '@Helpers';

export enum CoreCommissionLimitType {
  SPEND_CAP = 'SPEND_CAP',
  ORDER_COUNT = 'ORDER_COUNT',
}

export interface AffiliateFormProps {
  data: CoreAffiliateDetail;
  isLoading?: boolean;
  onSubmit?: (values: FieldValues) => void;
}

const AffiliateForm = ({
  data,
  onSubmit,
  isLoading = false,
}: AffiliateFormProps) => {
  const translate = useTranslate();
  const formMethods = useForm({
    defaultValues: {
      commisionLimitType: data.data.commisionLimitType,
      commisionLimit:
        data.data.commisionLimitType === CoreCommissionLimitType.SPEND_CAP
          ? pointsToUnits(data.data.commisionLimit)
          : data.data.commisionLimit,
      externalCommisionId: data.data.externalCommisionId,
    },
    reValidateMode: 'onChange',
  });

  const [commisionLimitType] = formMethods.watch(['commisionLimitType']);

  const handleChangeType = (...args: any) => {
    const value = args[1];

    switch (value) {
      case CoreCommissionLimitType.SPEND_CAP: {
        formMethods.setValue('commisionLimit', '0.00');

        break;
      }
      case CoreCommissionLimitType.ORDER_COUNT: {
        formMethods.setValue('commisionLimit', '0');

        break;
      }

      default:
        break;
    }
  };

  const onValid = (formValues: FieldValues) => {
    onSubmit?.(formValues);
  };

  const renderCommissionLimitField = () => {
    switch (commisionLimitType) {
      default:
      case CoreCommissionLimitType.SPEND_CAP:
        return (
          <ResourceMoneyInput
            name="commisionLimit"
            source="commisionLimit"
            label="Commission limit"
            fullWidth
            disabled={isLoading}
            validate={minNum(Number(pointsToUnits(1)))}
          />
        );
      case CoreCommissionLimitType.ORDER_COUNT:
        return (
          <ResourceInputNumber
            name="commisionLimit"
            source="commisionLimit"
            label="Commission limit"
            fullWidth
            disabled={isLoading}
            validate={[number(), isInteger(), minNum(0), maxNum(1000)]}
          />
        );
    }
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onValid)}>
        <RowInfoBlock asColumn={false}>
          <Flex flex={1}>
            <RadioButtonGroupInput
              onChange={handleChangeType}
              name="commisionLimitType"
              source="commisionLimitType"
              label="Commission limit type"
              row={false}
              choices={[
                { id: CoreCommissionLimitType.SPEND_CAP, name: 'Spend cap' },
                {
                  id: CoreCommissionLimitType.ORDER_COUNT,
                  name: 'Order count',
                },
              ]}
              disabled={isLoading}
            />
          </Flex>
          <Flex flex={1}>{renderCommissionLimitField()}</Flex>
        </RowInfoBlock>
        <RowInfoBlock>
          <SelectInput
            name="externalCommisionId"
            source="externalCommisionId"
            choices={data.conversions.map((item) => ({
              id: item.identifier,
              name: item.title,
            }))}
            resettable={false}
            disabled={isLoading}
            validate={required()}
          />
        </RowInfoBlock>

        <Toolbar>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isLoading}
          >
            {translate('save')}
          </Button>
        </Toolbar>
      </form>
    </FormProvider>
  );
};

export default AffiliateForm;
