import { ReactElement } from 'react';
import { ResourceList } from '@Widgets/ResourceList/ResourceList';
import { useTranslate } from 'react-admin';
import { LIST_COLUMNS } from './constants';
import { ClusterRule } from './interface';

export const ListPage = (): ReactElement => {
  const translate = useTranslate();

  return (
    <ResourceList<ClusterRule>
      empty={false}
      exporter={false}
      columns={LIST_COLUMNS}
      showDeleteButton={false}
      getItemCardCaption={(record) =>
        translate('replenishment.pages.clusterRules.labels.mobileCardCaption', {
          id: record.id,
        })
      }
    />
  );
};
