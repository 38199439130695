import { ListColumn } from '@Widgets/ResourceList/interface';
import { ProductMatrix } from '@Plugins/Replenishment/resources/ProductMatrix/interface';

export const LIST_COLUMNS: ListColumn<ProductMatrix & any>[] = [
  {
    source: 'store.name',
    label: 'replenishment.pages.lots.labels.store',
    type: 'text',
  },
  {
    source: 'product.sku',
    label: 'replenishment.pages.productMatrix.labels.product',
    type: 'text',
  },
  {
    source: 'cluster',
    label: 'replenishment.pages.productMatrix.labels.cluster',
    type: 'text',
  },
  {
    source: 'sales_increase',
    label: 'replenishment.pages.productMatrix.labels.sales_increase',
    type: 'number',
  },
  {
    source: 'matrix',
    label: 'replenishment.pages.productMatrix.labels.matrix',
    type: 'boolean',
  },
];
