import { EditFromContainerStyled } from '@Widgets/styles';
import { PageEditForm } from './PageForm/PageEditForm';
import { useBackendValidation } from '../../../hooks/useBackendValidation';
import { ResourceGoToListButton } from '@Widgets/ResourceItemPage/ResourceGoToListButton/ResourceGoToListButton';
import { ResourceRoutes } from "@Plugins/resourceRoutes";

export const EditPage = ({ id }: Pick<CoreWarehouse, 'id'>) => {
  const { errors, onErrorHandler } = useBackendValidation();

  return (
    <>
      <ResourceGoToListButton resource={ResourceRoutes.warehouse.routePath} />

      <EditFromContainerStyled
        id={id}
        mutationMode="pessimistic"
        mutationOptions={{ onError: onErrorHandler }}
        redirect={false}
      >
        <PageEditForm errors={errors} />
      </EditFromContainerStyled>
    </>
  );
};
