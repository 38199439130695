import { TranslationMessages } from 'ra-core';

const arabicMessages = {
  goToList: 'العودة إلى القائمة',
  BACKEND_VALIDATION_ERROR: 'النموذج غير صالح',
  CAN_NOT_SIGN_FILE_FOR_UPLOADING_ERROR: 'فشل تحميل الملف',
  CAN_NOT_GET_ACCESS_TO_S3_SERVICE_ERROR: 'فشل تحميل الملف',
  backendValidationErrors: {
    range: 'قيمة %{property} يجب أن تكون أكثر من %{min} ولكن أقل من %{max}',
    'value_error.missing': 'قيمة %{property} مطلوبة',
    PROMOCODE_SHOULD_HAVE_DIFFERENT_CODE:
      'يجب أن يكون اسم الرمز الترويجي فريدًا',
  },
  backendFileErrors: {
    UNPROCESSABLE_FILE_ERROR: 'الملف غير قابل للمعالجة',
  },
  uploadedContentErrors: {
    PRODUCT_EXTERNAL_NOT_FOUND:
      'المنتج غير موجود. يرجى التحقق من توفر المنتج والمحاولة مرة أخرى',
  },
  ra: {
    action: {
      add_filter: 'إضافة فلتر',
      add: 'إضافة',
      back: 'العودة',
      bulk_actions: '%{smart_count} العناصر المحددة',
      cancel: 'إلغاء',
      clear_input_value: 'إفراغ المدخلات',
      clone: 'استنساخ',
      confirm: 'تأكيد',
      create: 'إنشاء',
      delete: 'حذف',
      edit: 'تعديل',
      export: 'تصدير',
      list: 'قائمة',
      refresh: 'إعادة تحميل',
      remove_filter: 'إزالة هذا الفلتر',
      remove: 'إزالة',
      save: 'حفظ',
      search: 'بحث',
      show: 'عرض التفاصيل',
      sort: 'فرز',
      undo: 'تراجع',
      unselect: 'الغاء التحديد',
      expand: 'فرد',
      close: 'اغلاق',
      open_menu: 'افتح القائمة',
      close_menu: 'اغلق القائمة',
      clear_array_input: '',
      create_item: '',
      remove_all_filters: '',
      select_all: '',
      select_row: '',
      update: '',
      move_up: '',
      move_down: '',
      open: '',
      toggle_theme: '',
      select_columns: '',
      checkUncheck: 'تحقق / إلغاء تحديد الكل',
      exporting: 'جار التصدير...',
      exported: 'تم التصدير',
      import: 'استيراد',
      importing: 'جار الاستيراد...',
      imported: 'تم الاستيراد',
      upload: 'تحميل',
      exampleDownload: 'تحميل مثال',
      exampleLoaded: 'تم تحميل المثال',
      exampleIsLoading: 'جار تحميل المثال',
      redirectToLogs: 'الانتقال إلى السجلات',
    },
    auth: {
      auth_check_error: 'الرجاء تسجيل الدخول للاستمرار',
      user_menu: 'الملف الشخصي',
      username: 'اسم المستخدم',
      password: 'كلمة السر',
      sign_in: 'تسجيل الدخول',
      sign_in_error: 'أخفقت المصادقة، يرجى إعادة المحاولة',
      logout: 'الخروج',
    },
    boolean: {
      true: 'نعم',
      false: 'لا',
      null: '',
    },
    input: {
      file: {
        upload_several: 'إسقاط بعض الملفات للتحميل، أو انقر لتحديد واحد.',
        upload_single: 'إسقاط ملف للتحميل، أو انقر لتحديده.',
      },
      image: {
        upload_several: 'قم بإسقاط بعض الصور للتحميل، أو انقر لتحديد واحدة.',
        upload_single: 'إسقاط صورة للتحميل، أو انقر لتحديدها.',
      },
      references: {
        all_missing: 'غير قادر على العثور على بيانات المراجع.',
        many_missing: 'واحد على الأقل من المراجع المرتبطة لم تعد متوفرة.',
        single_missing: 'المرجع المرتبط لم يعد يبدو متاحًا.',
      },
      password: {
        toggle_visible: 'اخفاء الرقم السري',
        toggle_hidden: 'اظهار الرقم السري',
      },
    },
    message: {
      about: 'حول',
      are_you_sure: 'هل أنت واثق؟',
      bulk_delete_content:
        'هل أنت متأكد أنك تريد حذف هذا %{name}? |||| هل أنت متأكد من أنك تريد حذف هذه العناصر%{smart_count}?',
      bulk_delete_title: 'حذف %{name} |||| احذف عناصر%{smart_count}%{name}',
      delete_content: 'هل أنت متأكد أنك تريد حذف هذا البند؟',
      delete_title: 'حذف %{name} #%{id}',
      details: 'تفاصيل',
      error: 'حدث خطأ في التطبيق ولم يمكن إكمال طلبك.',
      invalid_form: 'النموذج غير صالح. يرجى التحقق من وجود أخطاء',
      loading: 'يتم تحميل الصفحة، فقط لحظة من فضلك',
      no: 'لا',
      not_found: 'الصفحة غير موجودة',
      yes: 'نعم ',
      unsaved_changes:
        'لم يتم حفظ بعض تغييراتك. هل أنت متأكد أنك تريد تجاهلها؟',
      auth_error: '',
      bulk_update_content: '',
      bulk_update_title: '',
      clear_array_input: '',
      delete_account: 'هل أنت متأكد أنك تريد حذف هذا الحساب؟',
      created_success: 'تم الإنشاء بنجاح',
      updated_success: 'تم التحديث بنجاح',
      deleted_success: 'تم الحذف بنجاح',
      creation_failure: 'فشل الإنشاء',
      update_failure: 'فشل التحديث',
      deleting_failure: 'فشل الحذف',
    },
    navigation: {
      no_results: 'لا توجد نتائج',
      no_more_results: 'رقم الصفحة%{page} خارج الحدود. جرب الصفحة السابقة.',
      page_out_of_boundaries: 'رقم الصفحة%{page} خارج الحدود',
      page_out_from_end: 'لا يمكن الذهاب بعد الصفحة الأخيرة',
      page_out_from_begin: 'لا يمكن الذهاب قبل الصفحة الأولى',
      page_range_info: '%{offsetBegin}-%{offsetEnd} من %{total}',
      page_rows_per_page: 'الصفوف لكل صفحة:',
      next: 'التالى',
      prev: 'السابق',
      skip_nav: 'تخطي الى المحتوي',
      partial_page_range_info: '',
      current_page: '',
      page: '',
      first: '',
      last: '',
      previous: '',
    },
    notification: {
      updated: 'تم تحديث العنصر |||| تم تحديث%{smart_count} من العناصر',
      created: 'تم إنشاء العنصر',
      deleted: 'تم حذف العنصر |||| تم حذف%{smart_count} من العناصر',
      bad_item: 'عنصر غير صحيح',
      item_doesnt_exist: 'العنصر غير موجود',
      http_error: 'خطأ في اتصال الخادم',
      i18n_error: 'لا يمكن تحميل الترجمة لهذه اللغة',
      data_provider_error:
        'خطأ في مزود البيانات. تحقق من وحدة التحكم للحصول على التفاصيل.',
      canceled: 'تم إلغاء الإجراء',
      logged_out: 'انتهت جلستك، يرجى إعادة الاتصال.',
      not_authorized: '',
    },
    page: {
      create: 'إنشاء %{name}',
      dashboard: 'لوحة التحكم',
      edit: '%{name} #%{id}',
      error: 'هناك خطأ ما',
      list: '%{name}',
      loading: 'جار التحميل',
      not_found: 'غير موجود',
      show: '%{name} #%{id}',
      empty: 'لا يوجد %{name} حتي الان',
      invite: 'هل ترغب فى اضافة واحد؟',
    },
    sort: {
      sort_by: 'رتب حسب %{field} %{order}',
      ASC: 'تصاعدي',
      DESC: 'تنازلي',
    },
    validation: {
      required: 'مطلوب',
      minLength: 'يجب أن يكون%{min} حرفًا على الأقل',
      maxLength: 'يجب أن يكون%{max} حرفًا أو أقل',
      minValue: 'يجب أن يكون%{min} على الأقل',
      maxValue: 'يجب أن يكون%{max} أو أقل',
      number: 'يجب أن يكون رقما',
      email: 'يجب أن يكون بريدًا إلكترونيًا صالحًا',
      oneOf: 'يجب أن يكون واحدًا من:%{options}',
      regex: 'يجب أن يتطابق مع تنسيق محدد (regex):%{pattern}',
      password:
        'يجب أن يكون %{min} حرف على الأقل ويجب أن يحتوي على رقم واحد وحرف كبير وحرف صغير وحرف خاص',
      passwordConfirm: 'يجب أن تتطابق مع كلمة المرور',
      phone: 'يجب أن يكون بين 10 و 15 رقمًا، الرموز الخاصة اختيارية',
      onlyLatin: 'الأحرف اللاتينية فقط مسموح بها',
      onlyLettersAndDigits: 'الأحرف والأرقام فقط مسموح بها',
      maxLengthExt: 'يجب أن لا يتجاوز %{max} حرفًا، لقد أدخلت %{cur} حرفًا',
      minLengthExt:
        'يجب أن يكون على الأقل %{min} حرفًا، لقد أدخلت %{cur} حرفًا',
      integer: 'يجب أن يكون عددًا صحيحًا',
      priceTooLow: 'يجب أن يكون السعر على الأقل %{min}',
      notZero: 'يجب ألا يكون صفرًا',
      withoutSpaces: 'يجب ألا يحتوي على مسافات',
      noCourierAndDispatcherSameTime:
        'قد لا يكون المستخدم لديه أدوار "Courier" و"Dispatcher" في نفس الوقت',
      validTimeRange: '%{startTime} يجب أن يكون قبل %{endTime}',
      maxDigits: 'عدد الأرقام لا يجب أن يتجاوز %{max}',
      invalidCutOffTime: 'يجب أن يكون وقت القطع %{time} أو أقدم',
      shouldBeEmptyRelatedToField:
        'لا يمكن استخدام هذا الحقل أثناء استخدام %{targetField}',
      landscapeRequired: 'يُسمح فقط بالصور الأفقية',
      invalidAspectRatio:
        'نسبة العرض إلى الارتفاع للصورة غير صحيحة. عرض الصورة المحددة هو %{width}px. هذه الصورة تتطلب حوالي %{height}px من الارتفاع.',
      imageLoadError: 'فشل تحميل الصورة',
    },
    saved_queries: {
      label: '',
      query_name: '',
      new_label: '',
      new_dialog_title: '',
      remove_label: '',
      remove_label_with_name: '',
      remove_dialog_title: '',
      remove_message: '',
      help: '',
    },
  },
  fileInputRejectedTypes: 'هناك فقط نوع واحد متاح: %{types}',
  fileInputMaxSize: 'الحد الأقصى للحجم 512 كيلوبايت، 250 صف',
  fileInputRejectedBigSize: 'الملف أكبر من %{bytes} بايت',
  week: {
    monday: 'الاثنين',
    tuesday: 'الثلاثاء',
    wednesday: 'الأربعاء',
    thursday: 'الخميس',
    friday: 'الجمعة',
    saturday: 'السبت',
    sunday: 'الأحد',
  },
  weekAbbreviated: {
    monday: 'الاثنين',
    tuesday: 'الثلاثاء',
    wednesday: 'الأربعاء',
    thursday: 'الخميس',
    friday: 'الجمعة',
    saturday: 'السبت',
    sunday: 'الأحد',
  },
  imageGallery: {
    image: 'الصورة الرئيسية',
    additionalImage: 'صورة إضافية',
    allowedImage:
      'الأنواع المسموح بها: %{types}. الحد الأقصى لحجم الملف: %{size} ميجابايت',
  },
  pages: {
    login: {
      byPhone: 'عبر الهاتف',
      byEmail: 'عبر البريد الإلكتروني',
      byLogin: 'عبر اسم المستخدم',
      signin: 'تسجيل الدخول',
      getCode: 'احصل على الرمز',
      username: 'اسم المستخدم',
      login: 'اسم الدخول',
      email: 'البريد الإلكتروني',
      phoneNumber: 'رقم الهاتف',
      password: 'كلمة المرور',
      remember: 'تذكرني',
      forgotPassword: 'هل نسيت كلمة المرور؟',
      enterLogin: 'أدخل اسم المستخدم وكلمة المرور لتسجيل الدخول',
      enterEmail: 'أدخل البريد الإلكتروني وكلمة المرور لتسجيل الدخول',
      enterPhone: 'أدخل رقم هاتفك لتسجيل الدخول',
      enterCode: 'أدخل الرمز المرسل إليك عبر الرسالة القصيرة',
      resendCodeCountdown: 'يمكنك إعادة إرسال الرمز مرة أخرى في %{time} ثانية',
      resendCode: 'إعادة إرسال الرمز',
      changeNumber: 'تغيير الرقم',
      enterCodeDescription1: 'تم إرسال الرمز إلى ',
      enterCodeDescription2: '، انظر إلى الهاتف وأدخل الرمز هنا:',
      noAccount: 'ليس لديك حساب؟',
      prodPhoneValidationError:
        'يجب أن يكون القيمة رقم هاتف صحيح يبدأ برمز الدولة',
      error: {
        invalidPhoneNumber: 'رقم هاتف غير صحيح',
        invalidEmail: 'بريد إلكتروني غير صحيح',
        failedSendOtpCode: 'فشل في إرسال رمز التحقق',
      },
    },
    pos: {
      search: 'البحث',
      configuration: 'التكوين',
      language: 'اللغة',
      theme: {
        name: 'السمة',
        light: 'فاتح',
        dark: 'داكن',
      },
      dashboard: {
        monthly_revenue: 'الإيراد الشهري',
        month_history: 'تاريخ الإيراد لمدة 30 يومًا',
        new_orders: 'طلبات جديدة',
        pending_reviews: 'المراجعات المعلقة',
        all_reviews: 'عرض كل المراجعات',
        new_customers: 'عملاء جدد',
        all_customers: 'عرض كل العملاء',
        pending_orders: 'الطلبات المعلقة',
        order: {
          items:
            'من قبل %{customer_name}، عنصر واحد |||| من قبل %{customer_name}، %{nb_items} عناصر',
        },
        welcome: {
          title: 'مرحبًا بك في الديمو لإدارة التجارة الإلكترونية برياكت-أدمن',
          subtitle:
            'هذا هو لوحة الإدارة لمتجر البوسترات الخيالي. لا تتردد في استكشاف البيانات وتعديلها - فهي محلية على جهازك، وستعيد تعيينها في كل مرة تعيد فيها تحميل الصفحة.',
          ra_button: 'موقع رياكت-أدمن',
          demo_button: 'المصدر لهذا الديمو',
        },
      },
      menu: {
        sales: 'المبيعات',
        catalog: 'الفهرس',
        customers: 'العملاء',
      },
    },
    resources: {
      customers: {
        name: 'عميل |||| عملاء',
        fields: {
          commands: 'الطلبات',
          first_seen: 'الظهور الأول',
          groups: 'الفئات',
          last_seen: 'الظهور الأخير',
          last_seen_gte: 'زيارة منذ',
          name: 'الاسم',
          total_spent: 'الإنفاق الإجمالي',
          password: 'كلمة المرور',
          confirm_password: 'تأكيد كلمة المرور',
          stateAbbr: 'الولاية',
        },
        filters: {
          last_visited: 'آخر زيارة',
          today: 'اليوم',
          this_week: 'هذا الأسبوع',
          last_week: 'الأسبوع الماضي',
          this_month: 'هذا الشهر',
          last_month: 'الشهر الماضي',
          earlier: 'في وقت سابق',
          has_ordered: 'لديه طلبات',
          has_newsletter: 'يتلقى النشرة الإخبارية',
          group: 'الفئة',
        },
        fieldGroups: {
          identity: 'الهوية',
          address: 'العنوان',
          stats: 'الإحصائيات',
          history: 'السجل',
          password: 'كلمة المرور',
          change_password: 'تغيير كلمة المرور',
        },
        page: {
          delete: 'حذف العميل',
        },
        errors: {
          password_mismatch: 'تأكيد كلمة المرور غير متطابق مع كلمة المرور.',
        },
      },
      commands: {
        name: 'طلب |||| طلبات',
        amount: '1 طلب |||| %{smart_count} طلب',
        title: 'الطلب %{reference}',
        fields: {
          basket: {
            delivery: 'التوصيل',
            reference: 'المرجع',
            quantity: 'الكمية',
            sum: 'المجموع',
            tax_rate: 'نسبة الضريبة',
            taxes: 'الضرائب',
            total: 'المجموع الكلي',
            unit_price: 'سعر الوحدة',
          },
          address: 'العنوان',
          customer_id: 'العميل',
          date_gte: 'منذ تاريخ',
          date_lte: 'قبل تاريخ',
          nb_items: 'عدد العناصر',
          total_gte: 'الحد الأدنى للمبلغ',
          status: 'الحالة',
          returned: 'تم الإرجاع',
        },
        section: {
          order: 'الطلب',
          customer: 'العميل',
          shipping_address: 'عنوان الشحن',
          items: 'العناصر',
          total: 'الإجمالي',
        },
      },
      invoices: {
        name: 'فاتورة |||| فواتير',
        fields: {
          date: 'تاريخ الفاتورة',
          customer_id: 'العميل',
          command_id: 'الطلب',
          date_gte: 'منذ تاريخ',
          date_lte: 'قبل تاريخ',
          total_gte: 'الحد الأدنى للمبلغ',
          address: 'العنوان',
        },
      },
      products: {
        name: 'بوستر |||| بوسترات',
        fields: {
          category_id: 'الفئة',
          height_gte: 'الارتفاع الأدنى',
          height_lte: 'الارتفاع الأقصى',
          height: 'الارتفاع',
          image: 'الصورة',
          price: 'السعر',
          reference: 'المرجع',
          sales: 'المبيعات',
          stock_lte: 'المخزون المنخفض',
          stock: 'المخزون',
          thumbnail: 'الصورة المصغرة',
          width_gte: 'العرض الأدنى',
          width_lte: 'العرض الأقصى',
          width: 'العرض',
        },
        tabs: {
          image: 'الصورة',
          details: 'التفاصيل',
          description: 'الوصف',
          reviews: 'المراجعات',
        },
        filters: {
          categories: 'الفئات',
          stock: 'المخزون',
          no_stock: 'إنتهى من المخزن',
          low_stock: '1 - 9 عناصر',
          average_stock: '10 - 49 عنصرًا',
          enough_stock: '50 عنصرًا وأكثر',
          sales: 'المبيعات',
          best_sellers: 'الأكثر مبيعًا',
          average_sellers: 'متوسط',
          low_sellers: 'منخفض',
          never_sold: 'لم يتم بيعه أبدًا',
        },
      },
    },
    categories: {
      name: 'الفئة |||| الفئات',
      fields: {
        products: 'المنتجات',
      },
    },
    reviews: {
      name: 'مراجعة |||| مراجعات',
      amount: '1 مراجعة |||| %{smart_count} مراجعة',
      relative_to_poster: 'مراجعة على البوستر',
      detail: 'تفاصيل المراجعة',
      fields: {
        customer_id: 'العميل',
        command_id: 'الطلب',
        product_id: 'المنتج',
        date_gte: 'نشر منذ',
        date_lte: 'نشر قبل',
        date: 'التاريخ',
        comment: 'التعليق',
        rating: 'التقييم',
      },
      action: {
        accept: 'قبول',
        reject: 'رفض',
      },
      notification: {
        approved_success: 'تمت الموافقة على المراجعة',
        approved_error: 'خطأ: لم تتم الموافقة على المراجعة',
        rejected_success: 'تم رفض المراجعة',
        rejected_error: 'خطأ: لم يتم رفض المراجعة',
      },
    },
    segments: {
      name: 'قسم |||| أقسام',
      fields: {
        customers: 'العملاء',
        name: 'الاسم',
      },
      data: {
        compulsive: 'مدمن الشراء',
        collector: 'مجمع',
        ordered_once: 'طلب مرة واحدة',
        regular: 'منتظم',
        returns: 'يعيد الطلبات',
        reviewer: 'مراجع',
      },
    },
  },
} satisfies TranslationMessages;

export default arabicMessages;
