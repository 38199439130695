import { AffiliatePromocodeEditProps } from './interfaces';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useNotify, useTranslate } from 'react-admin';
import { CircularProgress, Button } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { FieldValues } from 'react-hook-form';
import { AxiosError } from 'axios';
import { SyntheticEvent } from 'react';

import { RequestAPI } from '@RestApi';
import {
  generatePromocodeFromValues,
  generatePromocodeInput,
} from '@Plugins/Promotions/resources/Promocodes/utils';
import { PromocodeFormProps } from '@Plugins/Promotions/resources/Promocodes/interface';

import { API_URL, PROMOCODE_API } from '../../controller';
import { AffiliatePromocodeForm } from '../AffiliatePromocodeForm';

import {
  getIsDefault,
  sanitizeAffiliatePromocodeData,
  SanitizeDataArg,
} from './utils';

const AffiliatePromocodeEdit = (props: AffiliatePromocodeEditProps) => {
  const { id, affiliate, onNominateToDelete, onClose } = props;

  const translate = useTranslate();
  const notify = useNotify();
  const queryClient = useQueryClient();

  const { data, isLoading: isDetailLoading } = useQuery({
    queryKey: 'affiliatePromocodeSingle',
    queryFn: async (): Promise<CoreAffiliatePromocode | undefined> => {
      const response: CoreAffiliatePromocode[] | undefined =
        await RequestAPI.get(
          `${API_URL}/list/${affiliate.data.id}/promocodes/${id}`
        );

      const item: CoreAffiliatePromocode | undefined = response?.[0];

      if (!item) {
        notify('Unable to fetch promocode', { type: 'error' });

        throw new Error('promocode not found');
      }

      return {
        ...item,
        default: Number(item.default) === 1,
      };
    },
    cacheTime: 0,
    retry: false,
    refetchOnMount: false,
    retryOnMount: false,
  });
  const { mutate: updateAffiliatePromocode, isLoading: isUpdating } =
    useMutation({
      mutationKey: 'affiliatePromocodeUpdate',
      mutationFn: async ({ id, ...values }: CorePromocode) =>
        RequestAPI.patch(`${PROMOCODE_API}/${id}`, values),
      onSuccess: () => {
        notify('Successfully updated promocode', { type: 'success' });
        queryClient.invalidateQueries('affiliatePromocodesList');
      },
      onError: (error: AxiosError) => {
        notify(error.message, { type: 'error' });
      },
    });

  const handleSubmit = (fieldValues: FieldValues) => {
    const promocodeInput = generatePromocodeInput(
      fieldValues as PromocodeFormProps
    );
    const dataToSend = sanitizeAffiliatePromocodeData(
      promocodeInput as SanitizeDataArg
    );

    dataToSend.partnerAffiliateId = affiliate.data.id;
    updateAffiliatePromocode(dataToSend as CorePromocode);
  };

  const handleDelete = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (!onNominateToDelete || !data) {
      return;
    }

    onClose?.();
    onNominateToDelete(data)(e);
  };

  return (
    <>
      {isDetailLoading && (
        <div style={{ padding: 150 }}>
          <CircularProgress size="3rem" />
        </div>
      )}

      {data && !isDetailLoading && (
        <AffiliatePromocodeForm
          record={generatePromocodeFromValues(data)}
          affiliate={affiliate}
          onSubmit={handleSubmit}
          showGenerateCode={false}
          isLoading={isUpdating}
          buttons={
            !getIsDefault(data) &&
            onNominateToDelete && (
              <Button
                variant="contained"
                color="error"
                startIcon={<Delete />}
                onClick={handleDelete}
              >
                {translate('promotions.pages.tapfilliate.labels.delete')}
              </Button>
            )
          }
        />
      )}
    </>
  );
};

export default AffiliatePromocodeEdit;
