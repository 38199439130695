import { Flex, ResourceSwitcher, RowInfoBlock } from '@UI';
import { CodeTypeBlock } from './components/CodeTypeBlock';
import { MinRequirementsBlock } from './components/MinRequirementsBlock';
import { UsageLimits } from './components/UsageLimits';
import { promocodesTranslationNamespace } from '../../constants';
import { RESOURCES_SCHEMA } from '../../schemas/resourcesSchema';
import { CustomerEligibility } from './components/CustomerEligibility';
import { CustomerBuys } from './components/CustomerBuys';
import { CustomerGet } from './components/CustomerGet';
import { useController } from 'react-hook-form';
import { useIsMobile } from '@Hooks/useIsMobile';
import { ActiveDates } from './components/ActiveDates';
import { Type } from './components/Type';

interface PromocodeFormProps {
  hasAffiliate?: boolean;
  showGenerateCode?: boolean;
}

export const PromocodeForm = (props: PromocodeFormProps) => {
  const { hasAffiliate, showGenerateCode } = props;

  const isMobile = useIsMobile();
  const {
    field: { value: fieldTypeVal = '' },
  } = useController({ name: RESOURCES_SCHEMA.type });

  return (
    <>
      <Flex asColumn={isMobile} gap={1} fullWidth>
        <RowInfoBlock>
          <ResourceSwitcher
            name={RESOURCES_SCHEMA.isActive}
            labelKey={promocodesTranslationNamespace.labels.isActive}
          />
        </RowInfoBlock>

        <RowInfoBlock>
          <ResourceSwitcher
            name={RESOURCES_SCHEMA.applyToDiscountedItems}
            labelKey={
              promocodesTranslationNamespace.labels.applyToDiscountedItems
            }
          />
        </RowInfoBlock>

        {hasAffiliate && (
          <RowInfoBlock>
            <ResourceSwitcher
              name={RESOURCES_SCHEMA.default}
              labelKey={promocodesTranslationNamespace.labels.default}
              disabled
            />
          </RowInfoBlock>
        )}
      </Flex>

      <Flex asColumn={isMobile} gap={1} fullWidth>
        <CodeTypeBlock
          hasAffiliate={hasAffiliate}
          showGenerateCode={showGenerateCode}
        />

        <ActiveDates />
      </Flex>

      <Flex asColumn fullWidth>
        <Type />

        {/* {['PERCENTAGE', 'FIXED'].includes(fieldTypeVal) && <ApplyToBlock />} */}

        {fieldTypeVal === 'BUY_X_GET_Y' && (
          <>
            <CustomerBuys />

            <CustomerGet />
          </>
        )}

        {['FREE_SHIPPING', 'BUY_X_GET_Y'].includes(fieldTypeVal) && (
          <CustomerEligibility />
        )}

        <MinRequirementsBlock />

        <UsageLimits />
      </Flex>
    </>
  );
};
