import { ListColumn } from '@Widgets/ResourceList/interface';
import { ClusterRule } from './interface';

export const LIST_COLUMNS: ListColumn<ClusterRule>[] = [
  {
    source: 'cluster',
    label: 'replenishment.pages.clusterRules.labels.cluster',
    type: 'text',
  },
  {
    source: 'cluster_zero_stock',
    label: 'replenishment.pages.clusterRules.labels.cluster_zero_stock',
    type: 'boolean',
  },
  {
    source: 'cluster_rule_package',
    label: 'replenishment.pages.clusterRules.labels.cluster_rule_package',
    type: 'text',
  },
];
