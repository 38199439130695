import { ReferralConfigRecord } from '@Plugins/Promotions/resources/Referral/interfaces';

export const REFERRAL_PROGRAM_API =
  '/promocodes-service/v1/promocodes/referral_reward';

const currentYear = new Date().getFullYear();

export const REFERRAL_PROGRAM_DEFAULTS: ReferralConfigRecord = {
  referral: {
    applyToDiscountedItems: true,
    dateRange: [
      `${currentYear}-07-04 16:47:00+00`,
      `${currentYear}-09-01 16:47:00+00`,
    ],
    discountTargets: null,
    filters: {},
    isActive: true,
    maximumUsageNumberPerOrder: 1,
    minimumPurchase: 0,
    minimumQuantity: 0,
    orderNumberRange: [0, 0],
    productDiscountQuantity: 1,
    type: 'PERCENTAGE',
    usageCurrent: 0,
    usageLimitAll: 1,
    usageLimitCustomer: 1,
    validationRules: {
      isRequiredVerifiedCustomer: true,
      requiredFinishedOrdersCount: 0,
    },
    value: 15,
    customerEligibility: '',
    applyTo: '',
    minRequirementsChoice: '',
  },
  referee: {
    applyToDiscountedItems: true,
    dateRange: [
      `${currentYear}-07-04 16:47:00+00`,
      `${currentYear}-09-01 16:47:00+00`,
    ],
    discountTargets: null,
    filters: {},
    isActive: true,
    maximumUsageNumberPerOrder: 1,
    minimumPurchase: 0,
    minimumQuantity: 0,
    orderNumberRange: [0, 0],
    productDiscountQuantity: 1,
    type: 'PERCENTAGE',
    usageCurrent: 0,
    usageLimitAll: 1,
    usageLimitCustomer: 1,
    value: 15,
    customerEligibility: '',
    applyTo: '',
    minRequirementsChoice: '',
  },
};
