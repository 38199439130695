import {
  DEFAULT_USAGE_LIMIT_ALL_VAL,
  DEFAULT_USAGE_LIMIT_CUSTOMER_VAL,
} from '@Plugins/Promotions/resources/Promocodes/constants';

import {
  ReferralConfig,
  ReferralConfigRecord,
  ReferralProgramFormProps,
} from './interfaces';
import { REFERRAL_PROGRAM_DEFAULTS } from './constants';

/**
 * Transform remote data for the update form
 */
export const transformValidationRules = (
  instance?: Nullable<CoreReferralProgramRemoteTemplate>
): Nullable<CoreReferralProgramTemplate> => {
  if (!instance) return null;

  return {
    ...instance,
    validationRules: instance.validationRules
      ? JSON.parse(instance.validationRules)
      : {},
  };
};

/**
 * Prepare form data to the request
 */
export const stringifyValidationRules = (
  instance?: Nullable<ReferralProgramFormProps>
): Nullable<CoreReferralProgramRemoteTemplate> => {
  if (!instance) return null;

  return {
    ...instance,
    validationRules: JSON.stringify(instance.validationRules || {}),
  };
};

export const modifyRecord = (
  record: CoreReferralProgramTemplate
): CoreReferralProgramTemplate => ({
  ...record,
  usageLimitAll: record.usageLimitAll || DEFAULT_USAGE_LIMIT_ALL_VAL,
  usageLimitCustomer:
    record.usageLimitCustomer || DEFAULT_USAGE_LIMIT_CUSTOMER_VAL,
});

export const safeGetRecordValues = (
  remoteResponse?: ReferralConfig<ReferralProgramFormProps>
): ReferralConfigRecord => {
  if (!remoteResponse) {
    return REFERRAL_PROGRAM_DEFAULTS;
  }

  return {
    referral: remoteResponse.referral
      ? {
          ...REFERRAL_PROGRAM_DEFAULTS.referral,
          ...modifyRecord(remoteResponse.referral),
        }
      : REFERRAL_PROGRAM_DEFAULTS.referral,
    referee: remoteResponse.referee
      ? {
          ...REFERRAL_PROGRAM_DEFAULTS.referee,
          ...modifyRecord(remoteResponse.referee),
        }
      : REFERRAL_PROGRAM_DEFAULTS.referee,
  };
};
