import styled from 'styled-components';
import { ImageInput } from 'react-admin';

export const ImageInputStyled = styled(ImageInput)`
  .previews {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    height: 100%;
  }
`;
