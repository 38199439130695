export const TRANSLATION_SCHEMA = {
  captions: {
    desktop: 'desktop',
    mobile: 'mobileCaption',
  },
  labels: {
    id: 'id',
    ids: 'ids',
    firstName: 'firstName',
    lastName: 'lastName',
    email: 'email',
    login: 'login',
    warehouse: 'warehouse',
    warehouses: 'warehouses',
    status: 'status',
    employment: 'employment',
    createdAt: 'createdAt',
    createdAtFrom: 'createdAtFrom',
    createdAtTo: 'createdAtTo',
    updatedAt: 'updatedAt',
    updatedAtFrom: 'updatedAtFrom',
    updatedAtTo: 'updatedAtTo',
    details: 'details',
    detailsGeneral: 'detailsGeneral',
    searchPlaceholder: 'searchPlaceholder',
    statusEmpty: 'statusEmpty',
  },
  actions: {
    generateBadges: 'generateBadges',
  },
  errors: {
    wrongStartDate: 'wrongStartDate',
    notEnoughStories: 'notEnoughStories',
    imageIsRequired: 'imageIsRequired',
  },
  lists: {
    statuses: {
      offline: 'offline',
      idle: 'idle',
      waiting: 'waiting',
      picking: 'picking',
    },
    employment: {
      full_time: 'full_time',
      hybrid: 'hybrid',
    },
  },
};
