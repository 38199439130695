import { useNotify, useTranslate } from 'react-admin';
import { useState } from 'react';
import { FormProps } from './interface';
import { ResourceErrorCode } from './constants';

export function useBackendValidation() {
  const notify = useNotify();
  const translate = useTranslate();
  const [generalErrors, setGeneralErrors] = useState<FormProps['errors']>({});

  function onErrorHandler(error: unknown) {
    const errorMessage = (error as Error).message;
    const sourceError = ResourceErrorCode[errorMessage];

    if (sourceError) {
      const { translationKey, source } = sourceError;

      setGeneralErrors({
        [source]: translate(translationKey),
      });

      notify(translationKey, {
        type: 'error',
      });

      return;
    }

    notify(errorMessage, {
      type: 'error',
    });
  }

  return {
    errors: generalErrors,
    onErrorHandler,
  };
}
