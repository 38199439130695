import { Edit, SimpleForm, WithRecord } from 'react-admin';
import { FieldValues } from 'react-hook-form';

import { ResourceRoutes } from '@Plugins/resourceRoutes';
import { FormErrorBoundary } from '@UI';
import { validateValue } from '@Plugins/Promotions/resources/Promocodes/pages/PromocodeForm/components/Type';

import { ReferralConfig, ReferralProgramFormProps } from '../interfaces';
import { safeGetRecordValues } from '../utils';
import { ReferralProgramForm } from '../components/forms/ReferralProgramForm';

const validateForm = (values: FieldValues) => {
  const errors: Record<string, unknown> = {};
  const { referral, referee } = values;

  const referralTypeError = validateValue(referral.value, referral);
  const refereeTypeError = validateValue(referee.value, referee);

  if (referralTypeError) {
    errors.referral = { value: referralTypeError };
  }

  if (refereeTypeError) {
    errors.referee = { value: refereeTypeError };
  }

  return errors;
};

export const ListPage = (): JSX.Element => {
  return (
    <Edit
      id="unknown"
      sx={{ mt: 1 }}
      resource={ResourceRoutes.promotions.referral.resourcePath}
      mutationMode="pessimistic"
    >
      <WithRecord<ReferralConfig<ReferralProgramFormProps>>
        render={(record) => (
          <SimpleForm
            record={safeGetRecordValues(record)}
            reValidateMode="onChange"
            validate={validateForm}
          >
            <ReferralProgramForm />

            <FormErrorBoundary />
          </SimpleForm>
        )}
      />
    </Edit>
  );
};
